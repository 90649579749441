// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function message() {
  return "Optimizing storages";
}

function deleteIndexes(transaction, storeName, indexes) {
  var store = transaction.objectStore(storeName);
  Curry._2(Prelude.$$Array.forEach, indexes, (function (index) {
          console.log("Migration_096", "Removing index " + index + " from " + storeName);
          store.deleteIndex(index);
        }));
}

async function migrate(_db, transaction) {
  Curry._2(Prelude.$$Array.forEach, [
        "futureAttachments",
        "levelTypes",
        "ratingStylingRules",
        "sireVettingPeriods"
      ], (function (store) {
          deleteIndexes(transaction, store, ["id"]);
        }));
  deleteIndexes(transaction, "components", ["safety_type"]);
  deleteIndexes(transaction, "userProfiles", [
        "rank",
        "user"
      ]);
  deleteIndexes(transaction, "futureAttachments", ["created_at"]);
  deleteIndexes(transaction, "updatedComments", ["author"]);
  deleteIndexes(transaction, "notificationEntries", [
        "timestamp",
        "kind"
      ]);
  deleteIndexes(transaction, "deficiencies", [
        "component_id",
        "procedure_id",
        "section_id"
      ]);
  deleteIndexes(transaction, "sections", [
        "type_",
        "image"
      ]);
  deleteIndexes(transaction, "positions", ["user_id"]);
  deleteIndexes(transaction, "findings", ["level_id"]);
  deleteIndexes(transaction, "answers", ["profile_id"]);
  deleteIndexes(transaction, "levelSubmissions", ["answers"]);
  deleteIndexes(transaction, "newLevelSubmissions", ["answers"]);
  deleteIndexes(transaction, "draftLevelSubmissions", ["answers"]);
  deleteIndexes(transaction, "procedures", ["section_id"]);
  deleteIndexes(transaction, "questions", ["vessel_position"]);
  deleteIndexes(transaction, "inspections", ["section_id"]);
  deleteIndexes(transaction, "plannedProcedures", ["section_id"]);
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */

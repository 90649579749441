// Generated by ReScript, PLEASE EDIT WITH CARE

import Mithril from "mithril";

var processProps = (function(props) {
    let children;
    if (typeof props !== "undefined" && props.hasOwnProperty("children")) {
      children = props.children;
      delete props.children;
    }
    return [props, children];
});

var attachInitFunction = (function(make) {
  if (typeof make.init === "undefined") {
    make.init = (vnode) => make(vnode.attrs);
  }
  return make.init;
});

function jsx(make, props) {
  var match = processProps(props);
  return Mithril(attachInitFunction(make), match[0], match[1]);
}

function jsxs(component, props) {
  return jsx(component, props);
}

function jsxKeyed(tag, props, key, param) {
  props["key"] = key;
  return jsx(tag, props);
}

function jsxsKeyed(tag, props, key, param) {
  props["key"] = key;
  return jsx(tag, props);
}

export {
  processProps ,
  attachInitFunction ,
  jsx ,
  jsxs ,
  jsxKeyed ,
  jsxsKeyed ,
}
/* mithril Not a pure module */

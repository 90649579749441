// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Dialog from "../../../../libs/Dialog.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as DialogNewEdit from "../../../../libs/DialogNewEdit.js";

function DialogNewEditAnswer(props) {
  var onCallback = props.onCallback;
  var id = props.id;
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(DialogNewEdit.make, {
                            id: id,
                            onCallback: onCallback,
                            content: "Do you want to edit your existing answer, or add a new answer for this question?"
                          });
              }));
}

var openDialog = Dialog.openDialog;

var closeDialog = Dialog.closeDialog;

var make = DialogNewEditAnswer;

export {
  openDialog ,
  closeDialog ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"low":"low","medium":"medium","high":"high"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

export {
  tToJs ,
  tFromJs ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Attachment from "../../attachment_chunks/Attachment.js";

var url = "/attachments/basic";

var fields = [
  [
    "id",
    "String"
  ],
  [
    "file",
    "String"
  ],
  [
    "thumbnail",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "role",
    {
      TAG: "OptionalWithDefault",
      _0: Attachment.deserRoleFromJS,
      _1: "EVIDENCE"
    }
  ]
];

var BasicAPIDefinition = {
  url: url,
  fields: fields
};

var Basic = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: url
    });

export {
  BasicAPIDefinition ,
  Basic ,
}
/* Basic Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function make(param) {
  return function (db, _transaction) {
    IDB__Migration__Database.createObjectStore(db, "vessels");
    IDB__Migration__Utils.createStandardStore(db, "sections", [
            "vessel_id",
            "image"
          ]).createIndex("type_", "type");
    var components = IDB__Migration__Utils.createStandardStore(db, "components", ["section_id"]);
    components.createIndex("type_", "type");
    components.createIndex("safety_type", "safety_type");
    IDB__Migration__Utils.createStandardStore(db, "procedures", ["component_id"]);
    IDB__Migration__Utils.createStandardStore(db, "plannedProcedures", [
          "picture_prompt",
          "component_id"
        ]);
    IDB__Migration__Database.createObjectStore(db, "procedureImages");
    IDB__Migration__Utils.createStandardStore(db, "inspections", [
          "section_id",
          "component_id",
          "procedure_id",
          "photo",
          "deficiency_id"
        ]);
    IDB__Migration__Utils.createStandardStore(db, "draftInspections", ["photo"]);
    IDB__Migration__Utils.createStandardStore(db, "newInspections", [
          "photo",
          "component_id",
          "procedure_id"
        ]);
    IDB__Migration__Utils.createStandardStore(db, "deficiencies", ["status"]);
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Utils Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as TreeView from "../../tree/views/TreeView.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as R4SChecklist from "../R4SChecklist.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as ToastNotification from "../../common/components/ToastNotification.js";

async function update(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.r4schecklists = R4SChecklist.ofVessel(vesselId), newrecord));
  var r4schecklists = match.r4schecklists;
  var level_ids = r4schecklists.map(function (c) {
        return c.level_id;
      });
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.levels = {
          TAG: "In",
          _0: level_ids
        }, newrecord$1));
  Mithril.setState(vnode, {
        TAG: "Loaded",
        checklists: Prelude.$$Array.sort(r4schecklists, (function (c) {
                return c.eta.valueOf();
              }), true),
        levels: match$1.levels,
        hideGuidance: true
      });
  return KeepScrollLink.restoreScroll(undefined);
}

function toggleHideGuidance(vnode) {
  var currentState = vnode.state.data;
  if (typeof currentState !== "object") {
    return ;
  } else {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                checklists: currentState.checklists,
                levels: currentState.levels,
                hideGuidance: !currentState.hideGuidance
              });
  }
}

function welcomeMessage(vnode, hideGuidance) {
  return ReactDOM.jsxs("div", {
              children: [
                ReactDOM.jsxs("div", {
                      children: [
                        ReactDOM.jsxs("div", {
                              children: [
                                "Welcome to the Port Checklist",
                                hideGuidance ? ReactDOM.jsx("span", {
                                        children: " ..."
                                      }) : Mithril.empty
                              ]
                            }),
                        ReactDOM.jsx("div", {
                              children: React.jsx(Feather.make, {
                                    icon: hideGuidance ? "help_circle" : "eye_off",
                                    className: "tw-mt-2 tw-opacity-75",
                                    size: 20
                                  }),
                              className: "tw-cursor-pointer",
                              onClick: (function (param) {
                                  toggleHideGuidance(vnode);
                                })
                            })
                      ],
                      className: "tw-flex tw-justify-between tw-items-center"
                    }),
                hideGuidance ? Mithril.empty : ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("div", {
                                children: "Here you will receive checklists to complete for your vessel’s upcoming PSC inspections. An email notification will also be sent when a new checklist is available to complete."
                              }),
                          ReactDOM.jsx("div", {
                                children: "Each checklist:",
                                className: "tw-mt-4 tw-mb-0"
                              }),
                          ReactDOM.jsxs("ul", {
                                children: [
                                  ReactDOM.jsx("li", {
                                        children: "has 80% accuracy in predicting areas that most likely to be inspected."
                                      }),
                                  ReactDOM.jsx("li", {
                                        children: "contains questions tailored to the vessel type, company risk profile and previous inspection history from the port."
                                      })
                                ],
                                className: "tw-mt-0 tw-list-disc tw-list-outside"
                              }),
                          ReactDOM.jsx("div", {
                                children: "Select the ✓ for areas in good condition. If something is not in order, select the ✕ and a finding will be issued. The shore team will be informed automatically and can support your efforts in preparation for the next PSC inspection.",
                                className: ""
                              })
                        ],
                        className: "tw-mt-2 tw-text-xs tw-font-light tw-leading-5"
                      })
              ],
              className: "tw-bg-blue-100 tw-rounded tw-my-2 tw-px-4 tw-py-1"
            });
}

function make() {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), (function (vnode) {
                    return update(vnode);
                  })), (function (vnode) {
                var header = React.jsx(PageHeader.Full.make, {
                      content: {
                        NAME: "Title",
                        VAL: "Port checklists"
                      }
                    });
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(PageLayout.make, {
                              header: Caml_option.some(header)
                            });
                }
                var levels = match.levels;
                var checklists = match.checklists;
                return React.jsxs(PageLayout.make, {
                            header: Caml_option.some(header),
                            children: [
                              React.jsx(ToastNotification.make, {}),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      welcomeMessage(vnode, match.hideGuidance),
                                      Prelude.$$Array.isNotEmpty(checklists) ? checklists.map(function (checklist) {
                                              var url = URLs.r4sCheckListUrl(checklist.id);
                                              var level = Prelude.default(Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                                              return Caml_obj.equal(l.id, checklist.level_id);
                                                            }))), Level.Defaults.$$null);
                                              var eta = Luxon.DateTime.fromJSDate(checklist.eta).setZone("UTC").setLocale("en").toLocaleString(Luxon.DateTime.DATE_HUGE);
                                              return React.jsxKeyed(TileLink.make, {
                                                          title: R4SChecklist.getName(checklist),
                                                          href: url,
                                                          subtitle: "ETA: " + eta,
                                                          action: Caml_option.some(React.jsx(TreeView.Completion.make, {
                                                                    level: level,
                                                                    useColor: true
                                                                  })),
                                                          titleClassName: "h5 tw-m-0"
                                                        }, checklist.id, undefined);
                                            }) : ReactDOM.jsxs("div", {
                                              children: [
                                                React.jsx(Feather.make, {
                                                      icon: "alert_triangle",
                                                      size: 32
                                                    }),
                                                ReactDOM.jsx("br", {}),
                                                "No checklists have been generated for this vessel. Please contact your TSI."
                                              ],
                                              className: "text-gray tw-text-center tw-mt-[200px] tw-w-3/4 tw-mx-auto tw-text-lg tw-h-[100px]"
                                            })
                                    ],
                                    className: "sm:tw-w-10/12 tw-w-full tw-mx-auto tw-px-2 tw-select-none"
                                  })
                            ]
                          });
              }));
}

export {
  update ,
  toggleHideGuidance ,
  welcomeMessage ,
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MixPanel from "../../../libs/MixPanel.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as NewComment from "../NewComment.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as State__Memory from "../../state/State__Memory.js";

function trackNewComment(newComment) {
  var state = State__Memory.Current.get();
  MixPanel.track(newComment.author, "new-comment", {
        source: "mobile-app",
        target: newComment.object_app + "::" + newComment.object_type,
        target_id: newComment.object_id,
        body: newComment.body,
        profile: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, newComment.profile, (function (p) {
                    return UserProfile.nameWithRank(p);
                  })), ""),
        vessel: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, state.vessel, (function (vessel) {
                    return vessel.name;
                  })), "")
      });
}

function submit(vnode, param) {
  var vessel_id = vnode.attrs.vessel_id;
  var replies_to_id = vnode.attrs.replies_to_id;
  var object_app = vnode.attrs.object_app;
  var object_type = vnode.attrs.object_type;
  var object_id = vnode.attrs.object_id;
  var body = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=new_comment]"));
  var action = vnode.attrs.onSave;
  var match = State__Memory.Current.get();
  var user = match.user;
  var tmp;
  if (body !== undefined) {
    var body$1 = body.trim();
    tmp = body$1.length > 0 && user !== undefined ? Prelude.then(NewComment.make(vessel_id, body$1, replies_to_id, object_app, object_type, object_id, user, match.profile), (function (newComment) {
              var newrecord = Caml_obj.obj_dup(Query.makeWrite());
              return Prelude.thenResolve(Query.write((newrecord.newComments = [{
                                  TAG: "Save",
                                  _0: newComment
                                }], newrecord)), (function (param) {
                            Mithril.$$Element.setValue(Mithril.$$Element.select(vnode, "[name=new_comment]"), "");
                            trackNewComment(newComment);
                            return newComment;
                          }));
            })) : Promise.resolve(undefined);
  } else {
    tmp = Promise.resolve(undefined);
  }
  Prelude.thenDo(tmp, action);
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("textarea", {
                                    className: "form-input mb-1 p-2",
                                    name: "new_comment",
                                    placeholder: "New comment..."
                                  }),
                              ReactDOM.jsx("button", {
                                    children: "Post",
                                    className: "btn btn-primary float-right",
                                    onClick: (function (extra) {
                                        return submit(vnode, extra);
                                      })
                                  })
                            ]
                          });
              }));
}

export {
  trackNewComment ,
  submit ,
  make ,
}
/* Query Not a pure module */

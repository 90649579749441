// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Browser from "@sentry/browser";

var Breadcrumb = {};

var Exception = {};

var BrowserTracing = {};

var Transport = {};

var val_stickySession = true;

var val_mutationLimit = 10000;

var val_mutationBreadcrumbLimit = 750;

var val_networkDetailAllowUrls = [
  "https://cloud.kaikosystems.com/api/v20240307/attachments/basic",
  "https://cloud.kaikosystems.com/api/v20240307/attachments_chunks",
  "https://cloud.kaikosystems.com/api/v20240301/attachments/basic",
  "https://cloud.kaikosystems.com/api/v20240301/attachments_chunks"
];

var val_networkRequestHeaders = ["X-Custom-Header"];

var val_networkResponseHeaders = ["X-Custom-Header"];

var val = {
  stickySession: val_stickySession,
  mutationLimit: val_mutationLimit,
  mutationBreadcrumbLimit: val_mutationBreadcrumbLimit,
  networkDetailAllowUrls: val_networkDetailAllowUrls,
  networkRequestHeaders: val_networkRequestHeaders,
  networkResponseHeaders: val_networkResponseHeaders
};

var Defaults = {
  val: val
};

function restart(instance) {
  Belt_Option.forEach(instance, (function (instance) {
          var val;
          try {
            instance.stop();
            val = undefined;
          }
          catch (exn){
            instance.start();
            return ;
          }
          instance.start();
        }));
}

var Replay = {
  Defaults: Defaults,
  restart: restart
};

function setUser(user) {
  var replay = Browser.getReplay();
  Belt_Option.forEach(replay, (function (prim) {
          prim.stop();
        }));
  Browser.setUser({
        id: user.id,
        email: user.email,
        username: user.email,
        name: user.name
      });
  Belt_Option.forEach(replay, (function (prim) {
          prim.start();
        }));
}

export {
  Breadcrumb ,
  Exception ,
  BrowserTracing ,
  Transport ,
  Replay ,
  setUser ,
}
/* @sentry/browser Not a pure module */

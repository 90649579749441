// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as QuestionWidget from "./QuestionWidget.js";

function allowSave(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return false;
  } else {
    return Curry._1(Prelude.OptionExported.$$Option.isSome, match.integer);
  }
}

function isDirty(vnode, param) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return false;
  }
  var integer = match.integer;
  var lastNewAnswer = match.lastNewAnswer;
  if (createNew) {
    return Curry._1(Prelude.OptionExported.$$Option.isSome, integer);
  }
  if (lastNewAnswer === undefined) {
    return false;
  }
  var match$1 = lastNewAnswer.value;
  if (match$1 === undefined) {
    return false;
  }
  if (typeof match$1 !== "object") {
    return false;
  }
  if (match$1.NAME !== "Value") {
    return false;
  }
  var match$2 = match$1.VAL;
  if (typeof match$2 === "object" && match$2.NAME === "Int") {
    return match$2.VAL !== integer;
  } else {
    return false;
  }
}

function getValue(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return {
            attachments: []
          };
  } else {
    return {
            value: {
              NAME: "Value",
              VAL: {
                NAME: "Int",
                VAL: Prelude.default(match.integer, 0)
              }
            },
            attachments: []
          };
  }
}

function updateState(vnode, questionState) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  var newState;
  if (typeof questionState !== "object") {
    newState = "NotReady";
  } else {
    var lastNewAnswer = questionState.lastNewAnswer;
    var match = vnode.state.data;
    var tmp;
    if (typeof match !== "object") {
      if (createNew || lastNewAnswer === undefined) {
        tmp = undefined;
      } else {
        var match$1 = lastNewAnswer.value;
        if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "Value") {
          var match$2 = match$1.VAL;
          tmp = typeof match$2 === "object" && match$2.NAME === "Int" ? match$2.VAL : undefined;
        } else {
          tmp = undefined;
        }
      }
    } else {
      tmp = match.integer;
    }
    newState = {
      TAG: "Loaded",
      lastAnswer: questionState.lastAnswer,
      lastNewAnswer: lastNewAnswer,
      question: questionState.question,
      level: questionState.level,
      integer: tmp
    };
  }
  Mithril.setState(vnode, newState);
}

function IntQuestionWidget$Editor(props) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var level = match.level;
                  var question = match.question;
                  var lastNewAnswer = match.lastNewAnswer;
                  var lastAnswer = match.lastAnswer;
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("label", {
                                children: question.name,
                                className: "form-label",
                                htmlFor: question.id
                              }),
                          ReactDOM.jsx("input", {
                                className: "form-input",
                                id: question.id,
                                style: {
                                  height: "60%",
                                  width: "100%"
                                },
                                name: question.id,
                                type: "number",
                                value: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.integer, (function (prim) {
                                            return String(prim);
                                          })), ""),
                                onKeyUp: (function ($$event) {
                                    Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          lastAnswer: lastAnswer,
                                          lastNewAnswer: lastNewAnswer,
                                          question: question,
                                          level: level,
                                          integer: Curry._1(Prelude.Int.fromString, $$event.target.value)
                                        });
                                  })
                              })
                        ],
                        className: "form-group"
                      });
                }
                return React.jsx(QuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                return getValue(vnode, extra);
                              }),
                            allowSave: (function (extra) {
                                return allowSave(vnode, extra);
                              }),
                            isDirty: (function (extra) {
                                return isDirty(vnode, extra);
                              }),
                            updateChildrenState: (function (extra) {
                                return updateState(vnode, extra);
                              }),
                            children: tmp
                          });
              }));
}

var Editor = {
  allowSave: allowSave,
  isDirty: isDirty,
  getValue: getValue,
  updateState: updateState,
  make: IntQuestionWidget$Editor
};

function IntQuestionWidget(props) {
  var draftSubmission = props.draftSubmission;
  var lastAnswer = props.lastAnswer;
  var lastNewAnswer = props.lastNewAnswer;
  var vessel = props.vessel;
  var level = props.level;
  var question = props.question;
  return Mithril.view(Mithril.component(), (function (vnode) {
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                var submissionId = Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                        return s.id;
                      }));
                var editUrl = function (none, extra, extra$1, extra$2, extra$3) {
                  return URLs.intQuestionWidgetEditor(none, Prelude.default(Mithril__Route.paramBool("isRectification"), false), extra, extra$1, extra$2, extra$3);
                };
                return React.jsx(QuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            draftSubmission: draftSubmission,
                            editUrl: editUrl,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "hash"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active ",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isNone(submissionId)
                                        ],
                                        [
                                          "bg-success",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isSome(submissionId)
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return QuestionWidget.startInspection(vnode, editUrl, extra);
                                    })
                                })
                          });
              }));
}

var make = IntQuestionWidget;

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var steps = Curry._2(Prelude.$$Array.range, 0, vnode.attrs.stepCount);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("ul", {
                                    children: steps.map(function (step) {
                                          return ReactDOM.jsx("li", {
                                                      children: "Step " + String(step + 1 | 0),
                                                      className: Mithril.$$class([
                                                            [
                                                              "text-small label label-rounded",
                                                              true
                                                            ],
                                                            [
                                                              "active label-success",
                                                              step <= vnode.attrs.currentStep
                                                            ],
                                                            [
                                                              "inactive",
                                                              step > vnode.attrs.currentStep
                                                            ]
                                                          ], undefined)
                                                    });
                                        }),
                                    className: "navigation-pills d-flex m-0 p-0"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  })
                            ]
                          });
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

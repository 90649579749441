// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "./Level.js";
import * as Query from "../Query.js";
import * as Answer from "./Answer.js";
import * as Vessel from "../vessel/Vessel.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "./Question.js";
import * as QuestionId from "./QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "../accounts/UserProfile.js";
import * as State__Memory from "../state/State__Memory.js";

function make(question, lastAnswer, lastNewAnswer) {
  return {
          question: question,
          lastAnswer: lastAnswer,
          lastNewAnswer: lastNewAnswer
        };
}

var QuestionStatus = {
  make: make
};

function make$1(questionsCount, answersCount, level) {
  return {
          questionsCount: questionsCount,
          answersCount: answersCount,
          level: level
        };
}

var CompletionStatus = {
  make: make$1
};

function make$2(questions, answers, newAnswers, profiles, level, children, vessel) {
  return {
          questions: questions,
          answers: answers,
          newAnswers: newAnswers,
          profiles: profiles,
          level: level,
          children: children,
          vessel: vessel
        };
}

var LevelLoadedData = {
  make: make$2
};

function make$3(questions, vessel, level, children, allNewAnswers) {
  return {
          questions: questions,
          vessel: vessel,
          level: level,
          children: children,
          allNewAnswers: allNewAnswers
        };
}

var LevelFilteredState = {
  make: make$3
};

function t_default_answerFilter(answers) {
  return answers;
}

function t_default_questionFilter(questions) {
  return questions;
}

function t_default_questionStatusFilter(questionStatus) {
  return questionStatus;
}

function t_default_childrenFilter(children) {
  return children;
}

var t_default = {
  answerFilter: t_default_answerFilter,
  questionFilter: t_default_questionFilter,
  questionStatusFilter: t_default_questionStatusFilter,
  childrenFilter: t_default_childrenFilter
};

function make$4(answerFilter, questionFilter, questionStatusFilter, childrenFilter) {
  return {
          answerFilter: Prelude.default(answerFilter, t_default_answerFilter),
          questionFilter: Prelude.default(questionFilter, t_default_questionFilter),
          questionStatusFilter: Prelude.default(questionStatusFilter, t_default_questionStatusFilter),
          childrenFilter: Prelude.default(childrenFilter, t_default_childrenFilter)
        };
}

var StateFilters = {
  t_default: t_default,
  make: make$4
};

async function load(vesselId, levelId) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.levels = {
          TAG: "Get",
          _0: levelId
        }, newrecord.vessels = {
          TAG: "Get",
          _0: vesselId
        }, newrecord));
  var level = Prelude.default(Prelude.$$Array.first(match.levels), Level.Defaults.$$null);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.levels = Level.childrenOf(levelId), newrecord$1.newUserProfiles = UserProfile.ofVessel(vesselId), newrecord$1.userProfiles = UserProfile.ofVessel(vesselId), newrecord$1));
  var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$2.questions = Question.insideLevel(level.path), newrecord$2));
  var questions = match$2.questions;
  var questionsIds = Question.ids(questions);
  var newrecord$3 = Caml_obj.obj_dup(Query.makeRead());
  var match$3 = await Query.read((newrecord$3.newAnswers = Answer.ofQuestions(questionsIds), newrecord$3.answers = Answer.ofQuestions(questionsIds), newrecord$3));
  return {
          questions: questions,
          answers: match$3.answers,
          newAnswers: match$3.newAnswers,
          profiles: Curry._2(Prelude.$$Array.concat, match$1.userProfiles, match$1.newUserProfiles),
          level: level,
          children: match$1.levels,
          vessel: Prelude.default(Prelude.$$Array.first(match.vessels), Vessel.Defaults.$$null)
        };
}

function hasChildren(vesselId, levelId) {
  return Prelude.thenResolve(load(vesselId, levelId), (function (levelData) {
                return [
                        levelData.level,
                        Prelude.$$Array.isNotEmpty(levelData.children)
                      ];
              }));
}

function getQuestionStatusInsideLevel(levelData, filters) {
  var filters$1 = Prelude.default(filters, t_default);
  var profiles = levelData.profiles;
  var getLastOfMap = function (question, getter, profiles, currentProfile) {
    return Prelude.$$Array.last(filters$1.answerFilter(Curry._2(Prelude.$$Array.keep, Prelude.$$Array.sort(Prelude.default(QuestionId.$$Map.get(getter, question.id), []), (function (a) {
                              return a.timestamp;
                            }), undefined), (function (a) {
                          return Answer.questionVisibilityGetter(question)(currentProfile, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, profiles, (function (p) {
                                                return Caml_obj.equal(Caml_option.some(p.id), a.profile_id);
                                              }))));
                        }))));
  };
  var questions = filters$1.questionFilter(levelData.questions);
  var currentProfile = State__Memory.Current.get().profile;
  var answersByQuestion = QuestionId.$$Array.group(levelData.answers, (function (i) {
          return i.question_id;
        }));
  var newAnswersByQuestion = QuestionId.$$Array.group(levelData.newAnswers, (function (i) {
          return i.question_id;
        }));
  var allQuestionStatus = questions.map(function (q) {
        return {
                question: q,
                lastAnswer: getLastOfMap(q, answersByQuestion, profiles, currentProfile),
                lastNewAnswer: getLastOfMap(q, newAnswersByQuestion, profiles, currentProfile)
              };
      });
  return filters$1.questionStatusFilter(allQuestionStatus);
}

function getLevelCompletionStatus(vesselId, levelId, filters) {
  return Prelude.thenResolve(load(vesselId, levelId), (function (levelData) {
                var filters$1 = Prelude.default(filters, t_default);
                var questions = getQuestionStatusInsideLevel(levelData, filters$1);
                var level = levelData.level;
                var questions$1 = Curry._2(Prelude.$$Array.keep, questions, (function (qs) {
                        return Curry._2(Prelude.$$Array.some, qs.question.level_paths, (function (p) {
                                      if (level.path === p) {
                                        return true;
                                      } else {
                                        return p.startsWith(level.path);
                                      }
                                    }));
                      }));
                var questionsCount = questions$1.length;
                var answersCount = Curry._2(Prelude.$$Array.keep, questions$1, (function (qs) {
                        if (Curry._1(Prelude.OptionExported.$$Option.isSome, qs.lastAnswer)) {
                          return true;
                        } else {
                          return Curry._1(Prelude.OptionExported.$$Option.isSome, qs.lastNewAnswer);
                        }
                      })).length;
                return {
                        questionsCount: questionsCount,
                        answersCount: answersCount,
                        level: level
                      };
              }));
}

async function getState(vesselId, levelId, filters) {
  var filters$1 = Prelude.default(filters, t_default);
  var levelData = await load(vesselId, levelId);
  var questions = getQuestionStatusInsideLevel(levelData, filters$1);
  var level = levelData.level;
  var match = Curry._3(Prelude.$$Array.fold, Curry._1(Prelude.$$Array.concatMany, questions.map(function (q) {
                return Curry._2(Prelude.$$Array.keep, q.question.level_paths, (function (i) {
                              return i.startsWith(level.path);
                            }));
              })), [
        [],
        levelData.children
      ], (function (param, path) {
          var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                  if (extra.path === path) {
                    return true;
                  } else {
                    return path.startsWith(extra.path);
                  }
                }));
          var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
          return [
                  branches,
                  match[1]
                ];
        }));
  var children = Level.NaturalSort.sorted(filters$1.childrenFilter(match[0]));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.newAnswers = Answer.ofVessel(vesselId), newrecord));
  return make$3(questions, levelData.vessel, level, children, match$1.newAnswers);
}

var LevelState = {
  load: load,
  hasChildren: hasChildren,
  getQuestionStatusInsideLevel: getQuestionStatusInsideLevel,
  getLevelCompletionStatus: getLevelCompletionStatus,
  getState: getState
};

function levelHasChildren(vesselId, levelId) {
  return hasChildren(vesselId, levelId);
}

function filterByRiskStatus(level, riskFilters) {
  if (riskFilters === undefined) {
    return true;
  }
  if (riskFilters.length !== 0) {
    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, level.risk_status, (function (status) {
                      return Prelude.$$Array.includes(riskFilters, status);
                    })), false);
  }
  var param = Mithril.route.param("risks");
  if (Caml_obj.equal(param, "")) {
    return true;
  } else {
    return Curry._1(Prelude.OptionExported.$$Option.isNone, param);
  }
}

export {
  QuestionStatus ,
  CompletionStatus ,
  LevelLoadedData ,
  LevelFilteredState ,
  StateFilters ,
  LevelState ,
  levelHasChildren ,
  filterByRiskStatus ,
}
/* Level Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as VesselId from "../VesselId.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CompanyForm from "../../company_forms/CompanyForm.js";
import * as CompanyFormManager from "../../company_forms/CompanyFormManager.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";
import * as CompanyFormSubmission from "../../company_forms/CompanyFormSubmission.js";

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var companyForm = vnode.attrs.companyForm;
                    var submission = vnode.attrs.lastSubmission;
                    var subtitle = submission !== undefined ? "Last submission: " + Luxon.DateTime.fromJSDate(submission.timestamp).toRelative() : "Never submitted";
                    Prelude.thenDo(CompanyFormManager.getCompletion(companyForm), (function (completion) {
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  completion: completion,
                                  subtitle: subtitle
                                });
                          }));
                  })), (function (vnode) {
                var companyForm = vnode.attrs.companyForm;
                var match = vnode.state.data;
                var tmp;
                tmp = typeof match !== "object" ? React.jsx(TileLink.make, {
                        title: companyForm.name,
                        href: URLs.companyFormSubmissions(companyForm.id),
                        subtitle: "Loading..."
                      }) : React.jsx(TileLink.make, {
                        disabled: Prelude.$$Array.isEmpty(companyForm.related_procedures),
                        title: companyForm.name,
                        href: URLs.companyFormSubmissions(companyForm.id),
                        subtitle: match.subtitle,
                        action: Caml_option.some(React.jsx(InspectionCompletion.make, {
                                  completion: match.completion,
                                  counterOverlayClassName: "completion-group"
                                }))
                      });
                return ReactDOM.jsx("div", {
                            children: tmp,
                            className: Mithril.$$class([[
                                    "hidden",
                                    vnode.attrs.hidden
                                  ]], undefined)
                          });
              }));
}

var CompanyFormTile = {
  make: make
};

function loadState(vnode) {
  var x = vnode.state.data;
  var tmp;
  if (typeof x !== "object") {
    var vesselId = VesselId.Route.param("vesselId");
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    tmp = Prelude.thenResolve(Query.read((newrecord.companyFormSubmissions = CompanyFormSubmission.ofVessel(vesselId), newrecord.companyForms = CompanyForm.ofVessel(vesselId), newrecord)), (function (param) {
            var companyForms = param.companyForms;
            if (companyForms.length !== 0) {
              var lastSubmissionByFormId = CompanyFormSubmission.lastSubmissionByFormId(param.companyFormSubmissions);
              var companyForms$1 = Prelude.$$Array.sort(companyForms, CompanyForm.byName, undefined);
              return {
                      TAG: "Loaded",
                      vesselId: vesselId,
                      companyForms: companyForms$1.map(function (companyForm) {
                            return [
                                    companyForm,
                                    lastSubmissionByFormId(companyForm.id)
                                  ];
                          })
                    };
            }
            window.history.back();
            return "NotReady";
          }));
  } else {
    tmp = Promise.resolve(x);
  }
  Prelude.thenDo(tmp, (function (newState) {
          Mithril.setState(vnode, newState);
        }));
}

function getActiveTab() {
  return Prelude.default(Prelude.maybe(Mithril$1.route.param("formType"), CompanyForm.type_FromJs), "recurrent");
}

var Private = {
  loadState: loadState,
  getActiveTab: getActiveTab
};

function make$1(_vnode) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadState), (function (vnode) {
                var activeTab = getActiveTab();
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var vesselId = match.vesselId;
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("div", {
                                children: ReactDOM.jsx("div", {
                                      children: ReactDOM.jsxs("div", {
                                            children: [
                                              React.jsx(Mithril.Link.make, {
                                                    href: URLs.vesselCompanyForms(vesselId, "recurrent", undefined),
                                                    options: {
                                                      replace: true
                                                    },
                                                    className: Mithril.$$class([
                                                          [
                                                            "btn",
                                                            true
                                                          ],
                                                          [
                                                            "active",
                                                            activeTab === "recurrent"
                                                          ]
                                                        ], undefined),
                                                    children: "Periodic"
                                                  }),
                                              React.jsx(Mithril.Link.make, {
                                                    href: URLs.vesselCompanyForms(vesselId, "oneOff", undefined),
                                                    options: {
                                                      replace: true
                                                    },
                                                    className: Mithril.$$class([
                                                          [
                                                            "btn",
                                                            true
                                                          ],
                                                          [
                                                            "active",
                                                            activeTab === "oneOff"
                                                          ]
                                                        ], undefined),
                                                    children: "One-off"
                                                  })
                                            ],
                                            className: "btn-tabs"
                                          }),
                                      className: "col-mx-auto"
                                    }),
                                className: "columns py-2"
                              }),
                          match.companyForms.map(function (param) {
                                var companyForm = param[0];
                                return React.jsxKeyed(make, {
                                            companyForm: companyForm,
                                            lastSubmission: param[1],
                                            hidden: CompanyForm.getType(companyForm) !== activeTab
                                          }, companyForm.id, undefined);
                              })
                        ]
                      });
                }
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: "Company Forms",
                            section: "survey"
                          });
              }));
}

export {
  CompanyFormTile ,
  Private ,
  make$1 as make,
}
/* URLs Not a pure module */

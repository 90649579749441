// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as LevelTypeId from "./LevelTypeId.js";

var _map = {"vessel":"vessel","module":"module","section":"section","structural":"structural","safety":"safety","structural_component":"structural_component","safety_component":"safety_component","sire":"sire","sire_chapter":"sire_chapter","company_form":"company_form","checklist":"checklist","checklist_part":"checklist_part","PIQ":"PIQ","risk4sea_checklist":"risk4sea_checklist","risk4sea_checklist_item":"risk4sea_checklist_item"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var deserFromJS = $$JSON.Field.variadicString("Expected a valid Level Type Kind", (function (x) {
        return _map[x];
      }));

var Kind = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  deserFromJS: deserFromJS,
  fromString: tFromJs,
  toString: tToJs
};

var Definition = {};

var $$null = {
  id: LevelTypeId.$$null,
  path: "",
  name: "",
  code: "",
  kind: "checklist"
};

var Defaults = {
  $$null: $$null
};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofKind(kind) {
  return {
          TAG: "Is",
          _0: "kind",
          _1: kind
        };
}

function anyOfKind(kinds) {
  return {
          TAG: "AnyOf",
          _0: "kind",
          _1: kinds.map(function (x) {
                return x;
              })
        };
}

function ids(__x) {
  return __x.map(function (levelType) {
              return levelType.id;
            });
}

var Identifier;

var steplen = 4;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  steplen ,
  Kind ,
  Definition ,
  Defaults ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofKind ,
  anyOfKind ,
  ids ,
}
/* deserFromJS Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../libs/rescript-mithril/src/ReactDOM.js";
import * as ListLayout from "../common/views/ListLayout.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(ListLayout.make, {
                            children: ReactDOM.jsx("h4", {
                                  children: "Coming soon",
                                  className: "text-center"
                                }),
                            title: "Filter",
                            showBackButton: true,
                            showSettings: true,
                            section: "planner"
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

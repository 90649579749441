// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var id = match.id;
                var className = vnode.attrs.className;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("label", {
                                    children: match.title,
                                    className: "tw-font-medium",
                                    htmlFor: id
                                  }),
                              ReactDOM.jsx("input", {
                                    className: Mithril.classList([
                                          "tw-relative tw-block tw-h-7 tw-w-full tw-max-w-full tw-py-1 tw-px-2",
                                          "tw-bg-white tw-text-sm tw-text-[#303642] tw-appearance-none tw-bg-none",
                                          "tw-border tw-border-solid tw-border-[#adb5c4] tw-outline-none",
                                          "tw-transition-colors tw-duration-200"
                                        ], undefined),
                                    id: id,
                                    name: id,
                                    placeholder: match.placeholder,
                                    required: true,
                                    type: "text",
                                    value: match.value,
                                    onInput: match.onInput
                                  })
                            ],
                            className: className
                          });
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as FeatherIcons from "@kaiko/feather-icons";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var icon = vnode.attrs.icon;
                var className = Prelude.default(vnode.attrs.className, "");
                var style = Prelude.default(vnode.attrs.style, "");
                var size = Prelude.default(vnode.attrs.size, 24);
                var fill = Prelude.default(vnode.attrs.fill, "transparent");
                return Mithril$1.trust(FeatherIcons.icons[icon.replace(/_/g, "-")].toSvg({
                                style: style,
                                class: className,
                                width: size,
                                height: size,
                                fill: fill,
                                "strike-width": 2,
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }));
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../Query.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Network from "../../libs/Network.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as HttpError from "../../libs/HttpError.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as State__Memory from "./State__Memory.js";

function getCurrent() {
  var state = State__Memory.Current.get();
  var user = state.user;
  if (user !== undefined) {
    return Promise.resolve(user);
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.users = "All", newrecord)), (function (param) {
                var users = param.users;
                var user = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, users, (function (u) {
                            return u.token !== "";
                          })));
                if (user !== undefined) {
                  State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setCurrentUser(State__Memory.Transformations.make(state), user)));
                }
                return Prelude.$$Array.first(users);
              }));
}

async function logout() {
  Network.QueueManager.clear();
  await State__Memory.Persisted.save(State__Memory.Transformations.run(State__Memory.Transformations.removeCurrentProfile(State__Memory.Transformations.removeCurrentRole(State__Memory.Transformations.removeCurrentVessel(State__Memory.Transformations.removeCurrentUser(State__Memory.Transformations.make(State__Memory.Current.get())))))));
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.vessels = ["Clear"], newrecord.users = ["Clear"], newrecord));
  Mithril.route.set("/login");
}

function login(user) {
  State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setCurrentUser(State__Memory.Transformations.make(State__Memory.Current.get()), user)));
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  return Prelude.PromisedResult.map(Query.Safe.write((newrecord.users = [
                    "Clear",
                    {
                      TAG: "Save",
                      _0: user
                    }
                  ], newrecord)), (function (param) {
                return user;
              }));
}

function redirectToLogin() {
  var url = Mithril.route.get();
  if (url !== undefined && url !== "/login") {
    return Prelude.thenResolve(logout(), (function () {
                  Mithril.route.set("/login");
                }));
  } else {
    return Promise.resolve();
  }
}

function redirectToIndexIfLoggedIn() {
  return Prelude.then(getCurrent(), (function (user) {
                if (Belt_Option.isSome(user)) {
                  Mithril.route.set("/");
                }
                return Promise.resolve();
              }));
}

function redirectIfUnauthorized(response) {
  return Prelude.then(response, (function (result) {
                if (result.TAG === "Ok") {
                  return Promise.resolve({
                              TAG: "Ok",
                              _0: result._0
                            });
                }
                var error = result._0;
                if (Prelude.$$Array.includes([
                        401,
                        403
                      ], HttpError.safeCode(error))) {
                  return Prelude.thenResolve(redirectToLogin(), (function () {
                                return {
                                        TAG: "Error",
                                        _0: error
                                      };
                              }));
                } else {
                  return Promise.resolve({
                              TAG: "Error",
                              _0: error
                            });
                }
              }));
}

function required(f) {
  return Prelude.then(getCurrent(), (function (user) {
                if (user !== undefined) {
                  return f(user);
                } else {
                  return Prelude.then(redirectToLogin(), (function () {
                                return Promise.reject(Js_exn.raiseError("User not found"));
                              }));
                }
              }));
}

var Auth = {
  redirectToLogin: redirectToLogin,
  redirectToIndexIfLoggedIn: redirectToIndexIfLoggedIn,
  redirectIfUnauthorized: redirectIfUnauthorized,
  required: required
};

export {
  getCurrent ,
  logout ,
  login ,
  Auth ,
}
/* Query Not a pure module */

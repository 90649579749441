// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Image from "../../libs/Image.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AttachmentId from "./AttachmentId.js";

var _map = {"MAIN_EVIDENCE":"MAIN_EVIDENCE","EVIDENCE":"EVIDENCE","SIGNATURE":"SIGNATURE","AVATAR":"AVATAR"};

function roleToJs(param) {
  return param;
}

function roleFromJs(param) {
  return _map[param];
}

var deserRoleFromJS_1 = $$JSON.Field.usingString(function (i) {
      var internalValue = roleFromJs(i);
      if (internalValue !== undefined && internalValue !== "AVATAR") {
        return internalValue;
      }
      throw {
            RE_EXN_ID: $$JSON.$$TypeError,
            _1: "This String(\"" + i + "\") not a valid value here. Hint: role",
            Error: new Error()
          };
    });

var deserRoleFromJS = {
  TAG: "Morphism",
  _0: "String",
  _1: deserRoleFromJS_1
};

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "file",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "thumbnail",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "role",
      deserRoleFromJS
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Queue = ActionQueue.MakeActionQueue({
      name: "CompressionQueue"
    });

var queue = Queue.make(false, 1, undefined);

function compress(resolution, attachment) {
  var uuid = attachment.id;
  Queue.append(queue, (async function () {
          var jitter = Js_math.random_int(300, 600);
          await Prelude.Promises.ellapsed(jitter);
          return await Prelude.catchResolve(Prelude.thenResolve($$Image.Compression.compressLocalImage(uuid, resolution), (function (res) {
                            return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                                          return param.id;
                                        }));
                          })), (function (param) {
                        
                      }));
        }), uuid);
}

var CompressionQueue = {
  Queue: Queue,
  compress: compress
};

function make(file, role) {
  var id = AttachmentId.make();
  var attachment = {
    id: id,
    file: undefined,
    thumbnail: undefined,
    role: role
  };
  return [
          attachment,
          Prelude.thenResolve(LocalFile.store(Caml_option.some(id), undefined, undefined, file), (function (param) {
                  
                }))
        ];
}

function makeImageAndCompress(file, role, resolution) {
  var id = AttachmentId.make();
  var attachment = {
    id: id,
    file: undefined,
    thumbnail: undefined,
    role: role
  };
  return [
          attachment,
          Prelude.thenResolve(LocalFile.store(Caml_option.some(id), undefined, undefined, file), (function (param) {
                  compress(resolution, attachment);
                }))
        ];
}

function updateFileURL(attachment) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, attachment.file, (function (url) {
                    return Prelude.thenResolve(LocalFile.updateFileURL(attachment.id, url), (function (param) {
                                  return attachment;
                                }));
                  })), Promise.resolve(attachment));
}

function size(attachment) {
  return Prelude.thenResolve(LocalFile.getMetadata(attachment.id), (function (metadata) {
                if (metadata !== undefined) {
                  return metadata.size | 0;
                } else {
                  return 0;
                }
              }));
}

function clone(attachment) {
  return Prelude.thenResolve(LocalFile.clone(attachment.id), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (id) {
                              return {
                                      id: id,
                                      file: attachment.file,
                                      thumbnail: attachment.thumbnail,
                                      role: attachment.role
                                    };
                            }));
              }));
}

var Identifier;

export {
  roleToJs ,
  roleFromJs ,
  deserRoleFromJS ,
  Identifier ,
  Deserializer ,
  CompressionQueue ,
  make ,
  makeImageAndCompress ,
  updateFileURL ,
  size ,
  clone ,
}
/* deserRoleFromJS Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Fixing types of procedures";
}

function $$process(procedure, param) {
  var match = procedure.question_type;
  return {
          TAG: "Update",
          _0: {
            id: procedure.id,
            vessel_id: procedure.vessel_id,
            section_id: procedure.section_id,
            component_id: procedure.component_id,
            name: procedure.name,
            order: procedure.order,
            position_diagram_id: procedure.position_diagram_id,
            position_diagram_desc: procedure.position_diagram_desc,
            example_image_id: procedure.example_image_id,
            example_image_desc: procedure.example_image_desc,
            description: procedure.description,
            require_landscape_pictures: procedure.require_landscape_pictures,
            question_type: match === "ComponentWeightMeasurementQuestion" || match === "WeightDeltaQuestion" ? "WeightDeltaQuestion" : (
                match === "OkNotOkQuestion" ? "OkNotOkQuestion" : (
                    match === "DateQuestion" ? "DateQuestion" : "RatingQuestion"
                  )
              ),
            rating_styling_rules_id: procedure.rating_styling_rules_id,
            require_image_conditions: procedure.require_image_conditions,
            recurrence: procedure.recurrence,
            due_date: procedure.due_date,
            overdue_date: procedure.overdue_date,
            ignore_overdue_status: procedure.ignore_overdue_status
          }
        };
}

var ProcedureRewriter = InitFeedback.MakeRewriter({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Fixing types of procedures";
}

function migrate(param, transaction) {
  return ProcedureRewriter.rewrite(transaction, undefined);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* ProcedureRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as DeficiencyId from "./DeficiencyId.js";

var _map = {"active":"active","rectified":"rectified"};

function statusToJs(param) {
  return param;
}

function statusFromJs(param) {
  return _map[param];
}

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

var active = {
  TAG: "Is",
  _0: "status",
  _1: "active"
};

var rectified = {
  TAG: "Is",
  _0: "status",
  _1: "rectified"
};

function byDetectedWith(deficiency) {
  return deficiency.detected_with_id;
}

function byResolvedWith(deficiency) {
  return deficiency.resolved_with_id;
}

function bySection(deficiency) {
  return deficiency.section_id;
}

function byComponent(deficiency) {
  return deficiency.component_id;
}

function byProcedure(deficiency) {
  return deficiency.procedure_id;
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

var Identifier;

var appName = "tasks";

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  statusToJs ,
  statusFromJs ,
  Identifier ,
  appName ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  active ,
  rectified ,
  byDetectedWith ,
  byResolvedWith ,
  bySection ,
  byComponent ,
  byProcedure ,
  ofVessel ,
}
/* include Not a pure module */

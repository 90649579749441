// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Optimizing SIRE module access";
}

function $$process(level) {
  return {
          id: level.id,
          parent_id: level.parent_id,
          vessel_id: level.vessel_id,
          name: level.name,
          path: level.path,
          number: level.number,
          depth: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, level.depth, Prelude.Int.fromString), -1)
        };
}

var LevelsRewriter = InitFeedback.MakeInfallibleTransformator({
      storeName: "levels",
      message: message,
      $$process: $$process
    });

function message$1(param) {
  return "Optimizing SIRE module access";
}

function migrate(_db, transaction) {
  return Prelude.PromisedResult.map(LevelsRewriter.rewrite(transaction), (function () {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* LevelsRewriter Not a pure module */

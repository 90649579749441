// Generated by ReScript, PLEASE EDIT WITH CARE

import * as State__Memory from "../state/State__Memory.js";

var modules = [
  "home",
  "planner",
  "findings",
  "notifications",
  "upload"
];

function getUrl(section) {
  if (section === "planner") {
    return "/inspection-planner";
  } else if (section === "upload") {
    return "/upload";
  } else if (section === "findings") {
    return "/deficiencies";
  } else if (section === "notifications") {
    return "/notifications";
  } else {
    return "/home";
  }
}

function getIcon(section) {
  if (section === "planner") {
    return "filter";
  } else if (section === "upload") {
    return "send";
  } else if (section === "findings") {
    return "alert_triangle";
  } else if (section === "notifications") {
    return "bell";
  } else {
    return "ship";
  }
}

function getCurrentCount(section) {
  if (section === "upload") {
    return State__Memory.Current.get().pendingUploads;
  } else if (section === "findings") {
    return State__Memory.Current.get().activeFindings;
  } else if (section === "notifications") {
    return State__Memory.Current.get().unreadNotifications;
  } else {
    return 0;
  }
}

export {
  modules ,
  getUrl ,
  getIcon ,
  getCurrentCount ,
}
/* State__Memory Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as UUID7 from "../../libs/UUID7.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function photoToAttachment(role, photo) {
  return {
          id: UUID7.make(),
          file: photo,
          role: role
        };
}

function $$process(inspection) {
  return {
          id: inspection.id,
          procedure_id: inspection.procedure_id,
          component_id: inspection.component_id,
          section_id: inspection.section_id,
          vessel_id: inspection.vessel_id,
          rating: inspection.rating,
          timestamp: inspection.timestamp,
          code: inspection.code,
          annotations: inspection.annotations,
          additional_annotation: inspection.additional_annotation,
          picture_prompted: inspection.picture_prompted,
          walked_steps: inspection.walked_steps,
          app_version: inspection.app_version,
          deficiency_id: inspection.deficiency_id,
          attachments: Curry._2(Prelude.$$Array.concat, Prelude.$$Array.keepSome(Prelude.$$Array.append([], Curry._2(Prelude.OptionExported.$$Option.map, inspection.photo, (function (extra) {
                              return photoToAttachment("MAIN_EVIDENCE", extra);
                            })))), Prelude.default(inspection.detail_photos, []).map(function (extra) {
                    return photoToAttachment("EVIDENCE", extra);
                  }))
        };
}

function message(param, param$1) {
  return "Optimizing inspection's attachments";
}

var InspectionTransformer = InitFeedback.MakeInfallibleTransformator({
      storeName: "inspections",
      message: message,
      $$process: $$process
    });

var NewInspectionTransformer = InitFeedback.MakeInfallibleTransformator({
      storeName: "newInspections",
      message: message,
      $$process: $$process
    });

var DraftInspectionTransformer = InitFeedback.MakeInfallibleTransformator({
      storeName: "draftInspections",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Optimizing inspection's attachments";
}

function migrate(_db, transaction) {
  transaction.objectStore("inspections").deleteIndex("photo");
  transaction.objectStore("draftInspections").deleteIndex("photo");
  transaction.objectStore("newInspections").deleteIndex("photo");
  return Prelude.PromisedResult.bind(Prelude.PromisedResult.bind(InspectionTransformer.rewrite(transaction), (function () {
                    return NewInspectionTransformer.rewrite(transaction);
                  })), (function () {
                return DraftInspectionTransformer.rewrite(transaction);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* InspectionTransformer Not a pure module */

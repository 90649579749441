// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Luxon from "luxon";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UploadUtils from "../upload/UploadUtils.js";

function getPercent(completed, total) {
  if (total === 0) {
    return 100.00;
  } else {
    return completed / total * 100.0;
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var percent = vnode.attrs.percent;
                var totalSize = vnode.attrs.totalSize;
                var uploadedSize = Prelude.default(vnode.attrs.uploadedSize, 0.0);
                var percentStr = String(percent | 0) + "%";
                var timeStarted = vnode.attrs.timeStarted;
                var tmp;
                if (totalSize !== undefined) {
                  var tmp$1;
                  if (timeStarted !== undefined) {
                    var started = Caml_option.valFromOption(timeStarted);
                    var now = Date.now();
                    var start = started.valueOf();
                    var elapsed = now - start;
                    var elapsed$1 = elapsed < 10000.0 ? 10000.0 : elapsed;
                    if (uploadedSize > 0.0) {
                      var totalEstimatedDuration = elapsed$1 * totalSize / uploadedSize;
                      var remaining = Js_math.ceil((totalEstimatedDuration - elapsed$1) / 60000.0);
                      var remaining$1 = remaining < 1 ? 1 : remaining;
                      var started$1 = Luxon.DateTime.fromJSDate(started);
                      var end = started$1.plus({
                            minutes: remaining$1
                          });
                      tmp$1 = remaining$1 <= 1 ? " (ending soon)" : " (ending " + end.toRelative() + ")";
                    } else {
                      tmp$1 = "";
                    }
                  } else {
                    tmp$1 = "";
                  }
                  tmp = [
                    UploadUtils.formatBytes(uploadedSize) + " / " + UploadUtils.formatBytes(totalSize),
                    tmp$1
                  ];
                } else {
                  tmp = "estimating...";
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("div", {
                                            children: percentStr,
                                            className: "tw-w-full tw-absolute tw-h-[1.2rem] tw-top-1 tw-left-0 tw-drop-shadow tw-leading-none\n                     tw-text-xs tw-font-medium tw-text-center tw-z-10"
                                          }),
                                      ReactDOM.jsx("div", {
                                            className: "tw-absolute tw-top-0 tw-left-0 tw-h-[1.2rem] tw-z-1 tw-bg-success tw-leading-none",
                                            style: {
                                              width: percentStr
                                            }
                                          })
                                    ],
                                    className: "tw-relative tw-h-[1.2rem] tw-w-full tw-bg-gray-200 tw-rounded-full tw-overflow-hidden tw-"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    style: {
                                      fontSize: "0.8em",
                                      fontWeight: "400",
                                      marginTop: "0.2rem",
                                      justifySelf: "center"
                                    }
                                  })
                            ],
                            className: "tw-grid tw-grid-cols-1"
                          });
              }));
}

export {
  getPercent ,
  make ,
}
/* luxon Not a pure module */

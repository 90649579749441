// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "./Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VirtualpwaRegister from "virtual:pwa-register";

var VitePWARegistration = {};

function UpdateAvailable(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("NoUpdate"), (function (vnode) {
                    VirtualpwaRegister.registerSW({
                          onNeedRefresh: (function () {
                              Mithril.setState(vnode, "UpdateIsReady");
                            }),
                          onOfflineReady: (function () {
                              console.log("onOfflineReady.  Does this happen?");
                            })
                        });
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (match === "NoUpdate") {
                  return Mithril.empty;
                } else {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("div", {
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("div", {
                                                        children: "App update",
                                                        className: "card-title.h5"
                                                      }),
                                                  className: "card-header"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: "There is a new update available. Tap here to apply it",
                                                  className: "card-body"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsxs("button", {
                                                        children: [
                                                          React.jsx(Feather.make, {
                                                                icon: "arrow_up_circle"
                                                              }),
                                                          "Update"
                                                        ],
                                                        className: "btn btn-primary float-right",
                                                        onClick: (function (param) {
                                                            window.stop();
                                                            window.location.reload(true);
                                                          })
                                                      }),
                                                  className: "card-footer"
                                                })
                                          ],
                                          className: "card"
                                        }),
                                    className: "popover-container"
                                  }),
                              className: "popover active",
                              style: {
                                position: "absolute",
                                width: "100%"
                              }
                            });
                }
              }));
}

var make = UpdateAvailable;

export {
  VitePWARegistration ,
  make ,
}
/* React Not a pure module */

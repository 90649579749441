// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";

var _map = {"survey":"survey","checklist":"checklist"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var fromJS = $$JSON.Field.variadicString("Expected section type", tFromJs);

var Deserializer = {
  fromJS: fromJS
};

export {
  tToJs ,
  tFromJs ,
  Deserializer ,
}
/* fromJS Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RRule from "../../libs/RRule.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

function classifyRule(rule) {
  var match = rule.options;
  switch (match.freq) {
    case 0 :
        if (match.interval !== 1.0) {
          return ;
        } else {
          return 365;
        }
    case 1 :
        var match$1 = match.interval;
        if (match$1 !== 1.0) {
          if (match$1 !== 3.0) {
            if (match$1 !== 6.0) {
              return ;
            } else {
              return 168;
            }
          } else {
            return 84;
          }
        } else {
          return 28;
        }
    case 2 :
        if (match.interval !== 1.0) {
          return ;
        } else {
          return 7;
        }
    default:
      return ;
  }
}

function classify(rset) {
  return Prelude.$$Array.first(Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keepMap, RRule.toSet(rset).rrules(), classifyRule), (function (a) {
                    return a;
                  }), true));
}

export {
  classifyRule ,
  classify ,
}
/* RRule Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UploadRequest from "../upload/UploadRequest.js";
import * as ReviewUpload__AnswersReview from "./ReviewUpload__AnswersReview.js";

async function init(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var answers = Curry._2(Prelude.$$Array.keepMap, vnode.attrs.requests, UploadRequest.getAnswer);
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              _0: answers
            });
}

function valid(param) {
  return true;
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (v) {
                    init(v);
                  })), (function (vnode) {
                var answers = vnode.state.data;
                if (typeof answers !== "object") {
                  return Mithril.empty;
                }
                var answers$1 = answers._0;
                if (answers$1.length !== 0) {
                  return React.jsx(ReviewUpload__AnswersReview.make, {
                              answers: answers$1,
                              allowResolve: false
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

export {
  init ,
  valid ,
  make ,
}
/* React Not a pure module */

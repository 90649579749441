// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Completion from "../common/Completion.js";
import * as ProcedureId from "../survey/ProcedureId.js";
import * as CompanyFormId from "./CompanyFormId.js";
import * as CompanyFormSubmissionId from "./CompanyFormSubmissionId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofForm(formId) {
  return {
          TAG: "Is",
          _0: "form_id",
          _1: formId
        };
}

function byTargetDate(submission) {
  return submission.target_date;
}

function lastSubmissionByFormId(submissions) {
  return function (k) {
    return CompanyFormId.$$Map.get(Curry._3(Prelude.$$Array.fold, Prelude.$$Array.sort(submissions, byTargetDate, true), CompanyFormId.$$Map.make(), (function (formIdToSubmission, submission) {
                      if (Curry._1(Prelude.OptionExported.$$Option.isNone, CompanyFormId.$$Map.get(formIdToSubmission, submission.form_id))) {
                        CompanyFormId.$$Map.set(formIdToSubmission, submission.form_id, submission);
                      }
                      return formIdToSubmission;
                    })), k);
  };
}

function getCompletion(inspectedProcedureIds, requiredProcedureIds) {
  var proceduresInspected = ProcedureId.$$Map.fromArray(inspectedProcedureIds.map(function (id) {
            return [
                    id,
                    true
                  ];
          }));
  var requiredProceduresInspected = Curry._2(Prelude.$$Array.keep, requiredProcedureIds, (function (procedure_id) {
          return Curry._2(Prelude.OptionExported.$$Option.getWithDefault, ProcedureId.$$Map.get(proceduresInspected, procedure_id), false);
        }));
  return Completion.Information.make(requiredProcedureIds.length, requiredProceduresInspected.length, false);
}

function isCompleted(inspectedProcedureIds, requiredProcedureIds, allowPartialSubmission) {
  var completion = getCompletion(inspectedProcedureIds, requiredProcedureIds);
  if (Caml_obj.equal(allowPartialSubmission, true)) {
    return completion.done >= 1;
  } else {
    return Completion.Information.isFullyInspected(completion);
  }
}

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "timestamp",
      "Date"
    ],
    [
      "target_date",
      "Date"
    ],
    [
      "inspections",
      {
        TAG: "Array",
        _0: "String"
      }
    ]
  ]
};

var include$1 = $$JSON.MakeDeserializer({
      fields: fields
    });

var Deserializer = include$1;

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  ofForm ,
  byTargetDate ,
  lastSubmissionByFormId ,
  getCompletion ,
  isCompleted ,
  Deserializer ,
}
/* include Not a pure module */

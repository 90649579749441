// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ProfileCard from "../../accounts/components/ProfileCard.js";
import * as UploadRequest from "../upload/UploadRequest.js";
import * as UserProfileAPI from "../../api/endpoints/UserProfileAPI.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var profiles = match.profiles;
                var titleClassName = Prelude.default(vnode.attrs.titleClassName, "");
                if (profiles.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("h3", {
                                      children: match.title,
                                      className: "tw-mt-2 tw-text-center " + titleClassName
                                    }),
                                ReactDOM.jsx("p", {
                                      children: match.message,
                                      className: "tw-mb-2"
                                    }),
                                ReactDOM.jsx("div", {
                                      children: profiles.map(function (profile) {
                                            return React.jsx(ProfileCard.make, {
                                                        profile: profile,
                                                        theme: {
                                                          inactive: {
                                                            cardData: "tw-opacity-100",
                                                            icon: "tw-opacity-100"
                                                          }
                                                        }
                                                      });
                                          }),
                                      className: "t-profile-cards tw-py-2"
                                    })
                              ],
                              className: Mithril.classList([
                                    "tw-flex tw-flex-col",
                                    "tw-bg-white tw-p-2 tw-mt-2"
                                  ], undefined)
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

var ProfileGroup = {
  make: make
};

function valid(requests) {
  var profiles = Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getUserProfile);
  return Curry._1(Prelude.OptionExported.$$Option.isNone, profiles.find(function (p) {
                  return Curry._1(Prelude.Result.isError, UserProfileAPI.Serializer.serialize(p));
                }));
}

function make$1(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var match = vnode.attrs;
                    var profiles = Curry._2(Prelude.$$Array.keepMap, match.requests, UploadRequest.getUserProfile);
                    var match$1 = Curry._2(Prelude.$$Array.partition, profiles, (function (p) {
                            return Curry._1(Prelude.Result.isOk, UserProfileAPI.Serializer.serialize(p));
                          }));
                    Mithril.setState(vnode, {
                          TAG: "Loaded",
                          valid: match$1[0],
                          invalid: match$1[1]
                        });
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var valid = match.valid;
                if (valid.length === 0 && match.invalid.length === 0) {
                  return Mithril.empty;
                }
                var invalid = match.invalid;
                var c = valid.length;
                var validMessage = c !== 0 ? (
                    c !== 1 ? "There are " + String(c) + " user profiles to upload to the server:" : "There is a user profile to upload to the server:"
                  ) : "";
                var c$1 = invalid.length;
                var invalidMessage = c$1 !== 0 ? (
                    c$1 !== 1 ? "There are " + String(c$1) + " invalid user profiles in the list:" : "There is an invalid user profile in the list:"
                  ) : "";
                return [
                        React.jsx(make, {
                              title: "User Profiles",
                              message: validMessage,
                              profiles: valid
                            }),
                        React.jsx(make, {
                              title: "Invalid User Profiles",
                              titleClassName: "tw-text-error",
                              message: invalidMessage,
                              profiles: invalid
                            })
                      ];
              }));
}

export {
  ProfileGroup ,
  valid ,
  make$1 as make,
}
/* React Not a pure module */

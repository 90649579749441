// Generated by ReScript, PLEASE EDIT WITH CARE


var SubtleCrypto = {};

async function fromArrayBuffer(buffer, algorithm) {
  var digest = await window.crypto.subtle.digest(algorithm, buffer);
  return Array.from(new Uint8Array(digest)).map(function (b) {
                return b.toString(16).padStart(2, "0");
              }).join("");
}

async function fromBlob(blob, algorithm) {
  var buffer = await blob.arrayBuffer();
  return await fromArrayBuffer(buffer, algorithm);
}

function HexDigest_toString(prim) {
  return prim;
}

var HexDigest = {
  fromArrayBuffer: fromArrayBuffer,
  fromBlob: fromBlob,
  toString: HexDigest_toString
};

export {
  SubtleCrypto ,
  HexDigest ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

var Id = Utils.MakeOpaqueIdentifier({});

function message(param, param$1) {
  return "Optimizing the file storage pending to upload";
}

function $$process(future) {
  var allChunks = future.allChunks.map(function (id, index) {
        var size = future.chunkSizes[index];
        return {
                id: id,
                sequence: index,
                size: size
              };
      });
  var localIds = Id.$$Set.fromArray(future.localChunks);
  var localChunks = Curry._2(Prelude.$$Array.keep, allChunks, (function (c) {
          return Id.$$Set.has(localIds, c.id);
        }));
  var sentIds = Id.$$Set.fromArray(future.sentChunks);
  var sentChunks = Curry._2(Prelude.$$Array.keep, allChunks, (function (c) {
          return Id.$$Set.has(sentIds, c.id);
        }));
  return {
          id: future.id,
          vessel_id: future.vessel_id,
          allChunks: allChunks,
          localChunks: localChunks,
          sentChunks: sentChunks,
          checksum: future.checksum,
          checksum_algorithm: future.checksum_algorithm,
          size: future.size,
          payload: future.payload,
          mimetype: "image/jpeg"
        };
}

var FutureAttachmentTransformer = InitFeedback.MakeInfallibleTransformator({
      storeName: "futureAttachments",
      message: message,
      $$process: $$process
    });

function message$1(param) {
  return "Optimizing the file storage pending to upload";
}

function migrate(_db, transaction) {
  return FutureAttachmentTransformer.rewrite(transaction);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* FutureAttachmentTransformer Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "./rescript-mithril/src/React.js";
import * as Feather from "../kaiko/common/views/Feather.js";
import * as Mithril from "./rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "./rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__Element from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.js";

function close(vnode, element) {
  if (element !== undefined) {
    Caml_option.valFromOption(element).classList.remove("active");
    return ;
  }
  var element$1 = Webapi__Dom__Element.ofNode(vnode.dom);
  if (element$1 !== undefined) {
    Caml_option.valFromOption(element$1).classList.remove("active");
    return ;
  }
  
}

function openDialog(vnode, dialogId) {
  var element = Mithril.$$Element.select(vnode, "[id='" + dialogId + "']");
  if (element !== undefined) {
    Caml_option.valFromOption(element).classList.add("active");
    return ;
  }
  
}

function closeDialog(vnode, dialogId) {
  var element = Mithril.$$Element.select(vnode, "[id='" + dialogId + "']");
  close(vnode, element);
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var header = vnode.attrs.header;
                var id = vnode.attrs.id;
                var actions = vnode.attrs.actions;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("a", {
                                    className: "modal-overlay",
                                    onClick: (function (param) {
                                        close(vnode, undefined);
                                      })
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("h5", {
                                                    children: header,
                                                    className: "d-inline-block col-11"
                                                  }),
                                              ReactDOM.jsx("a", {
                                                    children: React.jsx(Feather.make, {
                                                          icon: "x"
                                                        }),
                                                    className: "d-inline-block col-1 text-right ml-auto",
                                                    onClick: (function (param) {
                                                        close(vnode, undefined);
                                                      })
                                                  })
                                            ],
                                            className: "modal-header"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: vnode.children,
                                            className: "modal-body"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: actions.map(function (action) {
                                                  return ReactDOM.jsx("button", {
                                                              children: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, action.label, (function (s) {
                                                                          return s;
                                                                        })), Mithril.empty),
                                                              className: Prelude.default(action.className, "btn mx-1"),
                                                              onClick: (function (param) {
                                                                  var onClick = Prelude.default(action.onClick, (function () {
                                                                          
                                                                        }));
                                                                  close(vnode, undefined);
                                                                  onClick();
                                                                })
                                                            });
                                                }),
                                            className: "modal-footer",
                                            style: {
                                              textAlign: "center"
                                            }
                                          })
                                    ],
                                    className: "modal-container"
                                  })
                            ],
                            className: "modal modal-sm",
                            id: id
                          });
              }));
}

export {
  close ,
  openDialog ,
  closeDialog ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

var processProps = (function(props) {
  let children;
  if (typeof props !== "undefined") {
    if (props.hasOwnProperty("children")) {
      children = props.children;
      delete props.children;
    }
    if (props.hasOwnProperty("data")) {
        Object.entries(props.data).forEach((keyValue) => {
          props["data-" + keyValue[0]] = keyValue[1];
        });
        delete props.data;
    }
    Object.entries(props).forEach((entry) => {
      let [key, value] = entry;
      if (key.startsWith("on")) {
        props[key.toLowerCase()] = value;
        delete props[key];
      }
    });
  }
  return [props, children];
});

function jsx(tag, props) {
  var match = processProps(props);
  return Mithril(tag, match[0], match[1]);
}

function jsxKeyed(tag, props, key, param) {
  var newrecord = Caml_obj.obj_dup(props);
  return jsx(tag, (newrecord.key = key, newrecord));
}

function jsxsKeyed(tag, props, key, param) {
  var newrecord = Caml_obj.obj_dup(props);
  return jsx(tag, (newrecord.key = key, newrecord));
}

var jsxs = jsx;

export {
  processProps ,
  jsx ,
  jsxs ,
  jsxKeyed ,
  jsxsKeyed ,
}
/* mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

var choices = [
  {
    value: "not_applicable",
    label: "Not Applicable"
  },
  {
    value: "not_available",
    label: "Not Available"
  }
];

var confirmationChoices = [
  {
    value: "yes",
    label: "Yes"
  },
  {
    value: "no",
    label: "No"
  }
];

function getLabel(value) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, choices.find(function (c) {
                      return c.value === value;
                    }), (function (v) {
                    return v.label;
                  })), "Unknown");
}

export {
  choices ,
  confirmationChoices ,
  getLabel ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UploadPayload from "../upload/UploadPayload.js";
import * as UploadProgressBar from "./UploadProgressBar.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var payloads = vnode.attrs.stage;
                switch (payloads.TAG) {
                  case "Requested" :
                  case "Filtering" :
                      break;
                  case "Preparing" :
                      var size = UploadPayload.size(payloads._0);
                      return React.jsx(UploadProgressBar.make, {
                                  percent: 0.0,
                                  totalSize: size
                                });
                  case "Uploading" :
                      var size$1 = payloads.size;
                      var uploaded = payloads.uploaded;
                      var percent;
                      try {
                        percent = uploaded / size$1 * 100.0;
                      }
                      catch (exn){
                        percent = 0.0;
                      }
                      return React.jsx(UploadProgressBar.make, {
                                  percent: percent,
                                  totalSize: size$1,
                                  uploadedSize: uploaded,
                                  timeStarted: Caml_option.some(payloads.started)
                                });
                  case "Finalizing" :
                      var size$2 = payloads._0;
                      return React.jsx(UploadProgressBar.make, {
                                  percent: 100.0,
                                  totalSize: size$2,
                                  uploadedSize: size$2
                                });
                  case "Done" :
                      return Mithril.empty;
                  
                }
                return React.jsx(UploadProgressBar.make, {
                            percent: 0.0
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "./Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as FutureAttachmentManager from "../../attachment_chunks/FutureAttachmentManager.js";

function swipeRight(vnode) {
  var newVisibleImage = vnode.state.data.visibleImage - 1 | 0;
  if (newVisibleImage < 0) {
    return ;
  }
  var init = vnode.state.data;
  Mithril.setState(vnode, {
        visibleImage: newVisibleImage,
        attachments: init.attachments,
        touchStartsAt: init.touchStartsAt,
        swipeDirection: "right",
        fullscreen: init.fullscreen
      });
}

function swipeLeft(vnode) {
  var newVisibleImage = vnode.state.data.visibleImage + 1 | 0;
  if (newVisibleImage >= vnode.state.data.attachments.length) {
    return ;
  }
  var init = vnode.state.data;
  Mithril.setState(vnode, {
        visibleImage: newVisibleImage,
        attachments: init.attachments,
        touchStartsAt: init.touchStartsAt,
        swipeDirection: "left",
        fullscreen: init.fullscreen
      });
}

function onTouchStart(vnode, $$event) {
  var init = vnode.state.data;
  vnode.state.data = {
    visibleImage: init.visibleImage,
    attachments: init.attachments,
    touchStartsAt: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, $$event.touches.item(0), (function (touch) {
                return touch.pageX;
              })), 0.0),
    swipeDirection: init.swipeDirection,
    fullscreen: init.fullscreen
  };
}

function onTouchEnd(vnode, $$event) {
  var touchStopsAt = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, $$event.changedTouches.item(0), (function (touch) {
              return touch.pageX | 0;
            })), 0);
  var touchStartsAt = vnode.state.data.touchStartsAt | 0;
  var touchDeltaX = touchStopsAt - touchStartsAt | 0;
  if (touchDeltaX > 50) {
    return swipeRight(vnode);
  } else if (touchDeltaX < -50) {
    return swipeLeft(vnode);
  } else {
    return ;
  }
}

function canUserSwipeLeft(_state, index) {
  return index > 0;
}

function canUserSwipeRight(state, index) {
  return index < (state.attachments.length - 1 | 0);
}

function setAttachments(vnode) {
  var attachments = Curry._1(Prelude.$$Array.reverse, vnode.attrs.attachments);
  var state = vnode.state.data;
  if (!Caml_obj.notequal(state.attachments, attachments)) {
    return ;
  }
  var visibleImage = attachments.length <= state.visibleImage ? attachments.length - 1 | 0 : state.visibleImage;
  var fullscreen = Prelude.$$Array.isEmpty(attachments) ? false : state.fullscreen;
  vnode.state.data = {
    visibleImage: visibleImage,
    attachments: attachments,
    touchStartsAt: state.touchStartsAt,
    swipeDirection: state.swipeDirection,
    fullscreen: fullscreen
  };
}

function toggleFullscreen(vnode, value) {
  var state = vnode.state.data;
  vnode.state.data = {
    visibleImage: state.visibleImage,
    attachments: state.attachments,
    touchStartsAt: state.touchStartsAt,
    swipeDirection: state.swipeDirection,
    fullscreen: value
  };
}

function onFullscreenClicked(vnode, value, _event) {
  toggleFullscreen(vnode, value);
}

function make(param) {
  return Mithril.view(Mithril.component({
                  visibleImage: 0,
                  attachments: [],
                  touchStartsAt: 0.0,
                  swipeDirection: undefined,
                  fullscreen: false
                }), (function (vnode) {
                setAttachments(vnode);
                var state = vnode.state.data;
                var deleteAttachment = vnode.attrs.deleteAttachment;
                var deleteAttachment$1 = async function (attachment, $$event) {
                  await FutureAttachmentManager.$$delete([attachment.id]);
                  return await deleteAttachment(attachment, $$event);
                };
                var attachments = state.attachments;
                if (attachments.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("h5", {
                                      children: "Detail Pictures",
                                      className: "text-left"
                                    }),
                                ReactDOM.jsxs("div", {
                                      children: [
                                        attachments.map(function (param, index) {
                                              return ReactDOM.jsx("input", {
                                                          className: "carousel-locator",
                                                          hidden: true,
                                                          id: "slide-" + String(index),
                                                          checked: index === state.visibleImage,
                                                          name: "carousel-radio",
                                                          type: "radio",
                                                          value: String(index)
                                                        });
                                            }),
                                        ReactDOM.jsx("div", {
                                              children: attachments.map(function (attachment, index) {
                                                    var direction = state.swipeDirection;
                                                    return ReactDOM.jsxsKeyed("figure", {
                                                                children: [
                                                                  index > 0 ? ReactDOM.jsx("label", {
                                                                          children: React.jsx(Feather.make, {
                                                                                icon: "chevron_left"
                                                                              }),
                                                                          className: "item-prev btn btn-action",
                                                                          htmlFor: "slide-" + String(index - 1 | 0)
                                                                        }) : Mithril.empty,
                                                                  canUserSwipeRight(state, index) ? ReactDOM.jsx("label", {
                                                                          children: React.jsx(Feather.make, {
                                                                                icon: "chevron_right"
                                                                              }),
                                                                          className: "item-next btn btn-action",
                                                                          htmlFor: "slide-" + String(index + 1 | 0)
                                                                        }) : Mithril.empty,
                                                                  ReactDOM.jsxs("div", {
                                                                        children: [
                                                                          ReactDOM.jsx("button", {
                                                                                children: React.jsx(Feather.make, {
                                                                                      icon: "trash_2"
                                                                                    }),
                                                                                className: "btn btn-sm btn-action delete t-delete m-2",
                                                                                onClick: (function (ev) {
                                                                                    deleteAttachment$1(attachment, ev);
                                                                                  })
                                                                              }),
                                                                          React.jsx(LocalImage.Resource.make, {
                                                                                resource: attachment.id
                                                                              })
                                                                        ],
                                                                        className: Mithril.$$class([[
                                                                                "fullscreen-img",
                                                                                state.fullscreen
                                                                              ]], undefined),
                                                                        onClick: (function (extra) {
                                                                            return toggleFullscreen(vnode, !state.fullscreen);
                                                                          }),
                                                                        onTouchEnd: (function (extra) {
                                                                            return onTouchEnd(vnode, extra);
                                                                          }),
                                                                        onTouchStart: (function (extra) {
                                                                            return onTouchStart(vnode, extra);
                                                                          })
                                                                      }),
                                                                  ReactDOM.jsx("figcaption", {
                                                                        children: String(index + 1 | 0) + "/" + String(state.attachments.length),
                                                                        className: "indicator"
                                                                      })
                                                                ],
                                                                className: "carousel-item slide-" + (
                                                                  direction !== undefined ? direction : ""
                                                                )
                                                              }, attachment.id, undefined);
                                                  }),
                                              className: "carousel-container"
                                            })
                                      ],
                                      className: "carousel detail-images"
                                    })
                              ]
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

export {
  swipeRight ,
  swipeLeft ,
  onTouchStart ,
  onTouchEnd ,
  canUserSwipeLeft ,
  canUserSwipeRight ,
  setAttachments ,
  toggleFullscreen ,
  onFullscreenClicked ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as LevelTypesAPI from "../endpoints/LevelTypesAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var levelTypesIds = AppSchema.Levels.all($$final).map(function (l) {
        return l.kind;
      });
  if (Prelude.$$Array.isEmpty(levelTypesIds)) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  } else {
    return Prelude.PromisedResult.map(LevelTypesAPI.getMany(undefined, undefined, undefined, user, levelTypesIds), (function (levelTypes) {
                  Curry._2(Prelude.$$Array.forEach, levelTypes, (function (extra) {
                          return AppSchema.LevelTypes.update($$final, extra);
                        }));
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final
                        };
                }));
  }
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(Query.Safe.$$do([
                  {
                    TAG: "Read",
                    _0: (function (param) {
                        var newrecord = Caml_obj.obj_dup(Query.makeRead());
                        newrecord.levels = {
                          TAG: "AnyOf",
                          _0: "vessel_id",
                          _1: vessels
                        };
                        return newrecord;
                      })
                  },
                  {
                    TAG: "Read",
                    _0: (function (param) {
                        var ids = param.levels.map(function (l) {
                              return l.kind;
                            });
                        var newrecord = Caml_obj.obj_dup(Query.makeRead());
                        newrecord.levelTypes = {
                          TAG: "In",
                          _0: ids
                        };
                        return newrecord;
                      })
                  }
                ]), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.levelTypes, (function (extra) {
                        return AppSchema.LevelTypes.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.levelTypes = AppSchema.LevelTypes.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncLevelTypes" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [
  AppSchema.Levels,
  AppSchema.LevelSubmissions
];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncLevelTypes",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as MixPanel from "./MixPanel.js";

function MakePermissionRequester(M) {
  var requestPermission = M.canRequestPermission ? (async function () {
        try {
          return await M.requestPermission();
        }
        catch (exn){
          return "default";
        }
      }) : (function () {
        return Promise.resolve("granted");
      });
  return {
          canRequestPermission: M.canRequestPermission,
          requestPermission: requestPermission
        };
}

function MakePermissionListener(M) {
  var requestPermission = M.canRequestPermission ? (async function () {
        try {
          return await M.requestPermission();
        }
        catch (exn){
          return "default";
        }
      }) : (function () {
        return Promise.resolve("granted");
      });
  var addListener = function (f) {
    return Prelude.catchResolve(Prelude.thenResolve(requestPermission(), (function (permission) {
                      if (permission === "granted") {
                        window.addEventListener(M.eventName, f, true);
                        return "granted";
                      } else {
                        return permission;
                      }
                    })), (function (error) {
                  console.warn("Permissions", M.eventName, error);
                  return "default";
                }));
  };
  var removeListener = function (extra) {
    window.removeEventListener(M.eventName, extra, true);
  };
  return {
          canRequestPermission: M.canRequestPermission,
          requestPermission: requestPermission,
          addListener: addListener,
          removeListener: removeListener
        };
}

function MakeRejectedReportEvent(E) {
  var addListener = function (fn) {
    return Prelude.thenResolve(E.addListener(fn), (function (reason) {
                  if (reason === "denied") {
                    MixPanel.track(E.getUser(), E.rejectionMessage, undefined);
                    return "denied";
                  } else {
                    return reason;
                  }
                }));
  };
  return {
          getUser: E.getUser,
          rejectionMessage: E.rejectionMessage,
          addListener: addListener
        };
}

export {
  MakePermissionRequester ,
  MakePermissionListener ,
  MakeRejectedReportEvent ,
}
/* MixPanel Not a pure module */

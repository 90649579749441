// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ComponentTheme from "./ComponentTheme.js";

var include = ComponentTheme.MakeTheme({});

var $$default = include.$$default;

var getProps = include.getProps;

var mergeProps = include.mergeProps;

export {
  $$default as default,
  getProps ,
  mergeProps ,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as InitFeedback from "../InitFeedback.js";

function fromBefore(before) {
  return Curry._2(Prelude.OptionExported.$$Option.map, before, (function (value) {
                var exit = 0;
                var v1;
                var v2;
                var v1$1;
                var v2$1;
                var v1$2;
                var v2$2;
                var v1$3;
                var v2$3;
                var v1$4;
                var v2$4;
                var v1$5;
                var v2$5;
                var v3;
                var variant = value.NAME;
                if (variant === "MultipleDatesTexts") {
                  var match = value.VAL;
                  var match$1 = match[2];
                  var v2$6 = match[1];
                  var v1$6 = match[0];
                  if (match$1 !== undefined) {
                    if (match$1) {
                      return "NotApplicable";
                    }
                    v1$4 = v1$6;
                    v2$4 = v2$6;
                    exit = 5;
                  } else {
                    v1$4 = v1$6;
                    v2$4 = v2$6;
                    exit = 5;
                  }
                } else if (variant === "WeightInfo") {
                  var match$2 = value.VAL;
                  var match$3 = match$2[3];
                  var v3$1 = match$2[2];
                  var v2$7 = match$2[1];
                  var v1$7 = match$2[0];
                  if (match$3 !== undefined) {
                    if (match$3) {
                      return "NotApplicable";
                    }
                    v1$5 = v1$7;
                    v2$5 = v2$7;
                    v3 = v3$1;
                    exit = 6;
                  } else {
                    v1$5 = v1$7;
                    v2$5 = v2$7;
                    v3 = v3$1;
                    exit = 6;
                  }
                } else {
                  if (variant === "MultipleDates") {
                    var match$4 = value.VAL;
                    var match$5 = match$4[1];
                    var v = match$4[0];
                    if (match$5 !== undefined && match$5) {
                      return "NotApplicable";
                    } else {
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDates",
                                VAL: v
                              }
                            };
                    }
                  }
                  if (variant === "MultipleIntegers") {
                    var match$6 = value.VAL;
                    var match$7 = match$6[1];
                    var v$1 = match$6[0];
                    if (match$7 !== undefined && match$7) {
                      return "NotApplicable";
                    } else {
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleIntegers",
                                VAL: v$1
                              }
                            };
                    }
                  }
                  if (variant === "Rating") {
                    var match$8 = value.VAL;
                    var match$9 = match$8[2];
                    var v2$8 = match$8[1];
                    var v1$8 = match$8[0];
                    if (match$9 !== undefined) {
                      if (match$9) {
                        return "NotApplicable";
                      }
                      v1$1 = v1$8;
                      v2$1 = v2$8;
                      exit = 2;
                    } else {
                      v1$1 = v1$8;
                      v2$1 = v2$8;
                      exit = 2;
                    }
                  } else {
                    if (variant === "Int") {
                      var match$10 = value.VAL;
                      var match$11 = match$10[1];
                      var v$2 = match$10[0];
                      if (match$11 !== undefined && match$11) {
                        return "NotApplicable";
                      } else {
                        return {
                                NAME: "Value",
                                VAL: {
                                  NAME: "Int",
                                  VAL: v$2
                                }
                              };
                      }
                    }
                    if (variant === "YesNo") {
                      var match$12 = value.VAL;
                      var match$13 = match$12[1];
                      var v$3 = match$12[0];
                      if (match$13 !== undefined && match$13) {
                        return "NotApplicable";
                      } else {
                        return {
                                NAME: "Value",
                                VAL: {
                                  NAME: "YesNo",
                                  VAL: v$3
                                }
                              };
                      }
                    }
                    if (variant === "OKNotOK") {
                      var match$14 = value.VAL;
                      var match$15 = match$14[2];
                      var v2$9 = match$14[1];
                      var v1$9 = match$14[0];
                      if (match$15 !== undefined) {
                        if (match$15) {
                          return "NotApplicable";
                        }
                        v1 = v1$9;
                        v2 = v2$9;
                        exit = 1;
                      } else {
                        v1 = v1$9;
                        v2 = v2$9;
                        exit = 1;
                      }
                    } else {
                      if (variant === "Pictures") {
                        var match$16 = value.VAL;
                        var match$17 = match$16[1];
                        var v$4 = match$16[0];
                        if (match$17 !== undefined && match$17) {
                          return "NotApplicable";
                        } else {
                          return {
                                  NAME: "Value",
                                  VAL: {
                                    NAME: "Pictures",
                                    VAL: v$4
                                  }
                                };
                        }
                      }
                      if (variant === "MultipleTexts") {
                        var match$18 = value.VAL;
                        var match$19 = match$18[1];
                        var v$5 = match$18[0];
                        if (match$19 !== undefined && match$19) {
                          return "NotApplicable";
                        } else {
                          return {
                                  NAME: "Value",
                                  VAL: {
                                    NAME: "MultipleTexts",
                                    VAL: v$5
                                  }
                                };
                        }
                      }
                      if (variant === "MultipleDatesIntegers") {
                        var match$20 = value.VAL;
                        var match$21 = match$20[2];
                        var v2$10 = match$20[1];
                        var v1$10 = match$20[0];
                        if (match$21 !== undefined) {
                          if (match$21) {
                            return "NotApplicable";
                          }
                          v1$3 = v1$10;
                          v2$3 = v2$10;
                          exit = 4;
                        } else {
                          v1$3 = v1$10;
                          v2$3 = v2$10;
                          exit = 4;
                        }
                      } else {
                        if (variant === "Date") {
                          var match$22 = value.VAL;
                          var match$23 = match$22[1];
                          var v$6 = match$22[0];
                          if (match$23 !== undefined && match$23) {
                            return "NotApplicable";
                          } else {
                            return {
                                    NAME: "Value",
                                    VAL: {
                                      NAME: "Date",
                                      VAL: v$6
                                    }
                                  };
                          }
                        }
                        if (variant === "MultipleDatesYesNo") {
                          var match$24 = value.VAL;
                          var match$25 = match$24[2];
                          var v2$11 = match$24[1];
                          var v1$11 = match$24[0];
                          if (match$25 !== undefined) {
                            if (match$25) {
                              return "NotApplicable";
                            }
                            v1$2 = v1$11;
                            v2$2 = v2$11;
                            exit = 3;
                          } else {
                            v1$2 = v1$11;
                            v2$2 = v2$11;
                            exit = 3;
                          }
                        } else {
                          if (variant === "Quiz") {
                            var match$26 = value.VAL;
                            var match$27 = match$26[1];
                            var v$7 = match$26[0];
                            if (match$27 !== undefined && match$27) {
                              return "NotApplicable";
                            } else {
                              return {
                                      NAME: "Value",
                                      VAL: {
                                        NAME: "Quiz",
                                        VAL: v$7
                                      }
                                    };
                            }
                          }
                          if (variant === "Signature") {
                            var match$28 = value.VAL;
                            var match$29 = match$28[1];
                            var v$8 = match$28[0];
                            if (match$29 !== undefined && match$29) {
                              return "NotApplicable";
                            } else {
                              return {
                                      NAME: "Value",
                                      VAL: {
                                        NAME: "Signature",
                                        VAL: v$8
                                      }
                                    };
                            }
                          }
                          if (variant !== "Text") {
                            return {
                                    NAME: "Value",
                                    VAL: {
                                      NAME: "MultipleSelection",
                                      VAL: value.VAL
                                    }
                                  };
                          }
                          var match$30 = value.VAL;
                          var match$31 = match$30[1];
                          var v$9 = match$30[0];
                          if (match$31 !== undefined && match$31) {
                            return "NotApplicable";
                          } else {
                            return {
                                    NAME: "Value",
                                    VAL: {
                                      NAME: "Text",
                                      VAL: v$9
                                    }
                                  };
                          }
                        }
                      }
                    }
                  }
                }
                switch (exit) {
                  case 1 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "OKNotOK",
                                VAL: [
                                  v1,
                                  v2
                                ]
                              }
                            };
                  case 2 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "Rating",
                                VAL: [
                                  v1$1,
                                  v2$1
                                ]
                              }
                            };
                  case 3 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDatesYesNo",
                                VAL: [
                                  v1$2,
                                  v2$2
                                ]
                              }
                            };
                  case 4 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDatesIntegers",
                                VAL: [
                                  v1$3,
                                  v2$3
                                ]
                              }
                            };
                  case 5 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDatesTexts",
                                VAL: [
                                  v1$4,
                                  v2$4
                                ]
                              }
                            };
                  case 6 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "WeightInfo",
                                VAL: [
                                  v1$5,
                                  v2$5,
                                  v3
                                ]
                              }
                            };
                  
                }
              }));
}

function message(param, param$1) {
  return "Optimizing N/A answers for SIRE 2.0";
}

function $$process(obj) {
  var value = fromBefore(obj.value);
  var newrecord = Caml_obj.obj_dup(obj);
  newrecord.value = value;
  return newrecord;
}

var AnswersRewriter = InitFeedback.MakeInfallibleTransformator({
      storeName: "answers",
      message: message,
      $$process: $$process
    });

function fromBefore$1(before) {
  return Curry._2(Prelude.OptionExported.$$Option.map, before, (function (value) {
                var exit = 0;
                var v1;
                var v2;
                var v1$1;
                var v2$1;
                var v1$2;
                var v2$2;
                var v1$3;
                var v2$3;
                var v1$4;
                var v2$4;
                var v1$5;
                var v2$5;
                var v3;
                var variant = value.NAME;
                if (variant === "MultipleDatesTexts") {
                  var match = value.VAL;
                  var match$1 = match[2];
                  var v2$6 = match[1];
                  var v1$6 = match[0];
                  if (match$1 !== undefined) {
                    if (match$1) {
                      return "NotApplicable";
                    }
                    v1$4 = v1$6;
                    v2$4 = v2$6;
                    exit = 5;
                  } else {
                    v1$4 = v1$6;
                    v2$4 = v2$6;
                    exit = 5;
                  }
                } else if (variant === "WeightInfo") {
                  var match$2 = value.VAL;
                  var match$3 = match$2[3];
                  var v3$1 = match$2[2];
                  var v2$7 = match$2[1];
                  var v1$7 = match$2[0];
                  if (match$3 !== undefined) {
                    if (match$3) {
                      return "NotApplicable";
                    }
                    v1$5 = v1$7;
                    v2$5 = v2$7;
                    v3 = v3$1;
                    exit = 6;
                  } else {
                    v1$5 = v1$7;
                    v2$5 = v2$7;
                    v3 = v3$1;
                    exit = 6;
                  }
                } else {
                  if (variant === "MultipleDates") {
                    var match$4 = value.VAL;
                    var match$5 = match$4[1];
                    var v = match$4[0];
                    if (match$5 !== undefined && match$5) {
                      return "NotApplicable";
                    } else {
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDates",
                                VAL: v
                              }
                            };
                    }
                  }
                  if (variant === "MultipleIntegers") {
                    var match$6 = value.VAL;
                    var match$7 = match$6[1];
                    var v$1 = match$6[0];
                    if (match$7 !== undefined && match$7) {
                      return "NotApplicable";
                    } else {
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleIntegers",
                                VAL: v$1
                              }
                            };
                    }
                  }
                  if (variant === "Rating") {
                    var match$8 = value.VAL;
                    var match$9 = match$8[2];
                    var v2$8 = match$8[1];
                    var v1$8 = match$8[0];
                    if (match$9 !== undefined) {
                      if (match$9) {
                        return "NotApplicable";
                      }
                      v1$1 = v1$8;
                      v2$1 = v2$8;
                      exit = 2;
                    } else {
                      v1$1 = v1$8;
                      v2$1 = v2$8;
                      exit = 2;
                    }
                  } else {
                    if (variant === "Int") {
                      var match$10 = value.VAL;
                      var match$11 = match$10[1];
                      var v$2 = match$10[0];
                      if (match$11 !== undefined && match$11) {
                        return "NotApplicable";
                      } else {
                        return {
                                NAME: "Value",
                                VAL: {
                                  NAME: "Int",
                                  VAL: v$2
                                }
                              };
                      }
                    }
                    if (variant === "YesNo") {
                      var match$12 = value.VAL;
                      var match$13 = match$12[1];
                      var v$3 = match$12[0];
                      if (match$13 !== undefined && match$13) {
                        return "NotApplicable";
                      } else {
                        return {
                                NAME: "Value",
                                VAL: {
                                  NAME: "YesNo",
                                  VAL: v$3
                                }
                              };
                      }
                    }
                    if (variant === "OKNotOK") {
                      var match$14 = value.VAL;
                      var match$15 = match$14[2];
                      var v2$9 = match$14[1];
                      var v1$9 = match$14[0];
                      if (match$15 !== undefined) {
                        if (match$15) {
                          return "NotApplicable";
                        }
                        v1 = v1$9;
                        v2 = v2$9;
                        exit = 1;
                      } else {
                        v1 = v1$9;
                        v2 = v2$9;
                        exit = 1;
                      }
                    } else {
                      if (variant === "Pictures") {
                        var match$16 = value.VAL;
                        var match$17 = match$16[1];
                        var v$4 = match$16[0];
                        if (match$17 !== undefined && match$17) {
                          return "NotApplicable";
                        } else {
                          return {
                                  NAME: "Value",
                                  VAL: {
                                    NAME: "Pictures",
                                    VAL: v$4
                                  }
                                };
                        }
                      }
                      if (variant === "MultipleTexts") {
                        var match$18 = value.VAL;
                        var match$19 = match$18[1];
                        var v$5 = match$18[0];
                        if (match$19 !== undefined && match$19) {
                          return "NotApplicable";
                        } else {
                          return {
                                  NAME: "Value",
                                  VAL: {
                                    NAME: "MultipleTexts",
                                    VAL: v$5
                                  }
                                };
                        }
                      }
                      if (variant === "MultipleDatesIntegers") {
                        var match$20 = value.VAL;
                        var match$21 = match$20[2];
                        var v2$10 = match$20[1];
                        var v1$10 = match$20[0];
                        if (match$21 !== undefined) {
                          if (match$21) {
                            return "NotApplicable";
                          }
                          v1$3 = v1$10;
                          v2$3 = v2$10;
                          exit = 4;
                        } else {
                          v1$3 = v1$10;
                          v2$3 = v2$10;
                          exit = 4;
                        }
                      } else {
                        if (variant === "Date") {
                          var match$22 = value.VAL;
                          var match$23 = match$22[1];
                          var v$6 = match$22[0];
                          if (match$23 !== undefined && match$23) {
                            return "NotApplicable";
                          } else {
                            return {
                                    NAME: "Value",
                                    VAL: {
                                      NAME: "Date",
                                      VAL: v$6
                                    }
                                  };
                          }
                        }
                        if (variant === "MultipleDatesYesNo") {
                          var match$24 = value.VAL;
                          var match$25 = match$24[2];
                          var v2$11 = match$24[1];
                          var v1$11 = match$24[0];
                          if (match$25 !== undefined) {
                            if (match$25) {
                              return "NotApplicable";
                            }
                            v1$2 = v1$11;
                            v2$2 = v2$11;
                            exit = 3;
                          } else {
                            v1$2 = v1$11;
                            v2$2 = v2$11;
                            exit = 3;
                          }
                        } else {
                          if (variant === "Quiz") {
                            var match$26 = value.VAL;
                            var match$27 = match$26[1];
                            var v$7 = match$26[0];
                            if (match$27 !== undefined && match$27) {
                              return "NotApplicable";
                            } else {
                              return {
                                      NAME: "Value",
                                      VAL: {
                                        NAME: "Quiz",
                                        VAL: v$7
                                      }
                                    };
                            }
                          }
                          if (variant === "Signature") {
                            var match$28 = value.VAL;
                            var match$29 = match$28[1];
                            var v$8 = match$28[0];
                            if (match$29 !== undefined && match$29) {
                              return "NotApplicable";
                            } else {
                              return {
                                      NAME: "Value",
                                      VAL: {
                                        NAME: "Signature",
                                        VAL: v$8
                                      }
                                    };
                            }
                          }
                          if (variant !== "Text") {
                            return {
                                    NAME: "Value",
                                    VAL: {
                                      NAME: "MultipleSelection",
                                      VAL: value.VAL
                                    }
                                  };
                          }
                          var match$30 = value.VAL;
                          var match$31 = match$30[1];
                          var v$9 = match$30[0];
                          if (match$31 !== undefined && match$31) {
                            return "NotApplicable";
                          } else {
                            return {
                                    NAME: "Value",
                                    VAL: {
                                      NAME: "Text",
                                      VAL: v$9
                                    }
                                  };
                          }
                        }
                      }
                    }
                  }
                }
                switch (exit) {
                  case 1 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "OKNotOK",
                                VAL: [
                                  v1,
                                  v2
                                ]
                              }
                            };
                  case 2 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "Rating",
                                VAL: [
                                  v1$1,
                                  v2$1
                                ]
                              }
                            };
                  case 3 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDatesYesNo",
                                VAL: [
                                  v1$2,
                                  v2$2
                                ]
                              }
                            };
                  case 4 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDatesIntegers",
                                VAL: [
                                  v1$3,
                                  v2$3
                                ]
                              }
                            };
                  case 5 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "MultipleDatesTexts",
                                VAL: [
                                  v1$4,
                                  v2$4
                                ]
                              }
                            };
                  case 6 :
                      return {
                              NAME: "Value",
                              VAL: {
                                NAME: "WeightInfo",
                                VAL: [
                                  v1$5,
                                  v2$5,
                                  v3
                                ]
                              }
                            };
                  
                }
              }));
}

function message$1(param, param$1) {
  return "Optimizing N/A answers for SIRE 2.0";
}

function $$process$1(obj) {
  var value = fromBefore$1(obj.value);
  var newrecord = Caml_obj.obj_dup(obj);
  newrecord.value = value;
  return newrecord;
}

var NewAnswersRewriter = InitFeedback.MakeInfallibleTransformator({
      storeName: "newAnswers",
      message: message$1,
      $$process: $$process$1
    });

function message$2(param) {
  return "Optimizing N/A answers for SIRE 2.0";
}

function migrate(_db, transaction) {
  return Prelude.PromisedResult.bind(NewAnswersRewriter.rewrite(transaction), (function () {
                return AnswersRewriter.rewrite(transaction);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$2,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* AnswersRewriter Not a pure module */

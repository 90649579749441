// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Answer from "../../tree/Answer.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LocalFile from "../../../libs/LocalFile.js";
import * as Inspection from "../../survey/Inspection.js";
import * as QuestionId from "../../tree/QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CompanyForm from "../../company_forms/CompanyForm.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as InspectionId from "../../survey/InspectionId.js";
import * as CompanyFormId from "../../company_forms/CompanyFormId.js";
import * as InspectionAPI from "../../api/endpoints/InspectionAPI.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as UploadRequest from "./UploadRequest.js";
import * as Browser from "@sentry/browser";
import * as LevelSubmission from "../../tree/LevelSubmission.js";
import * as ProcedureUpdate from "../../survey/ProcedureUpdate.js";
import * as FutureAttachment from "../../attachment_chunks/FutureAttachment.js";
import * as NotificationEntry from "../../notifications/NotificationEntry.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as CompanyFormManager from "../../company_forms/CompanyFormManager.js";
import * as NotificationEntryId from "../../notifications/NotificationEntryId.js";
import * as CompanyFormSubmission from "../../company_forms/CompanyFormSubmission.js";
import * as ReadNotificationEntry from "../../notifications/ReadNotificationEntry.js";
import * as FutureAttachmentChunkId from "../../attachment_chunks/FutureAttachmentChunkId.js";
import * as FutureAttachmentManager from "../../attachment_chunks/FutureAttachmentManager.js";

async function create(vesselId) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.companyForms = CompanyForm.ofVessel(vesselId), newrecord));
  var companyForms = Prelude.$$Array.sort(match.companyForms, CompanyForm.byName, undefined);
  var creationStatus = await Prelude.Promises.iter(companyForms.map(function (cf) {
            return function () {
              return CompanyFormManager.createSubmission(undefined, undefined, cf);
            };
          }));
  var incompleteForms = Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.zip, companyForms, creationStatus), (function (param) {
          var tmp = param[1];
          if (typeof tmp !== "object" && tmp === "Incomplete") {
            return param[0];
          }
          
        }));
  return [
          companyForms,
          incompleteForms
        ];
}

var AutoCompanyFormsSubmissions = {
  create: create
};

async function recover(future) {
  var val;
  try {
    val = await LocalFile.getWithMetadata(future.id);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("UploadPrepare", {
          error: e
        });
    return {
            TAG: "Error",
            _0: future
          };
  }
  var metadata = val[0];
  if (metadata === undefined) {
    return {
            TAG: "Error",
            _0: future
          };
  }
  var resource = val[1];
  if (resource !== undefined && future.size === metadata.size) {
    return await FutureAttachmentManager.rebuild(future, resource.file);
  } else {
    return {
            TAG: "Error",
            _0: future
          };
  }
}

async function $$do(futures, progress) {
  console.log("UploadPrepare", "Checking the liveness of " + String(futures.length) + " future(s).");
  var allChunksIds = Prelude.$$Array.flatMap(futures, (function (f) {
            return f.localChunks;
          })).map(function (c) {
        return c.id;
      });
  var checks = await LocalFile.Liveness.check(allChunksIds, progress);
  if (checks.TAG !== "Ok") {
    return futures;
  }
  var match = Curry._2(Prelude.$$Array.partition, checks._0, (function (param) {
          return param[1];
        }));
  var dead = FutureAttachmentChunkId.$$Set.fromArray(match[1].map(function (param) {
            return param[0];
          }));
  var match$1 = Curry._2(Prelude.$$Array.partition, futures, (function (future) {
          var chunks = future.localChunks.map(function (c) {
                return c.id;
              });
          return Curry._3(Prelude.$$Array.fold, chunks, true, (function (result, id) {
                        if (result) {
                          return !FutureAttachmentChunkId.$$Set.has(dead, id);
                        } else {
                          return false;
                        }
                      }));
        }));
  var recovering = await Prelude.Promises.iter(match$1[1].map(function (f) {
            return function () {
              return recover(f);
            };
          }));
  var removals = Curry._2(Prelude.$$Array.keepMap, recovering, Prelude.Result.err);
  var recovered = Curry._2(Prelude.$$Array.keepMap, recovering, Prelude.Result.ok);
  if (Prelude.$$Array.isNotEmpty(removals)) {
    Browser.captureEvent({
          level: "warning",
          message: "Detected " + String(removals.length) + " file(s) missing on disk",
          logger: "UploadPrepare"
        });
    await FutureAttachmentManager.$$delete(removals.map(function (f) {
              return f.id;
            }));
  }
  return Curry._2(Prelude.$$Array.concat, match$1[0], recovered);
}

var CheckFutureAttachmentLiveness = {
  recover: recover,
  $$do: $$do
};

async function next(vesselId, progress) {
  console.log("UploadPrepare", vesselId);
  await create(vesselId);
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.futureAttachments = FutureAttachment.ofVessel(vesselId), newrecord.readNotificationEntries = ReadNotificationEntry.ofVessel(vesselId), newrecord.notificationEntries = NotificationEntry.ofVessel(vesselId), newrecord.procedureUpdates = ProcedureUpdate.ofVessel(vesselId), newrecord.newAnswers = Answer.ofVessel(vesselId), newrecord.draftLevelSubmissions = LevelSubmission.ofVessel(vesselId), newrecord.draftCompanyFormSubmissions = CompanyFormSubmission.ofVessel(vesselId), newrecord.newCompanyFormSubmissions = CompanyFormSubmission.ofVessel(vesselId), newrecord.newUserProfiles = UserProfile.ofVessel(vesselId), newrecord.updatedComments = "All", newrecord.newComments = {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        }, newrecord.newInspections = {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        }, newrecord.inspections = {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        }, newrecord.vessels = {
          TAG: "Get",
          _0: vesselId
        }, newrecord));
  var procedureUpdates = match.procedureUpdates;
  var newAnswers = match.newAnswers;
  var draftCompanyFormSubmissions = match.draftCompanyFormSubmissions;
  var newCompanyFormSubmissions = match.newCompanyFormSubmissions;
  var newInspections = match.newInspections;
  var inspections = match.inspections;
  var vessels = match.vessels;
  var levelIds = newAnswers.map(function (a) {
        return a.level_id;
      });
  var questionIds = newAnswers.map(function (a) {
        return a.question_id;
      });
  var requiredFormsIds = draftCompanyFormSubmissions.map(function (s) {
        return s.form_id;
      });
  var requiredFormsIds$1 = Curry._2(Prelude.$$Array.concat, requiredFormsIds, newCompanyFormSubmissions.map(function (s) {
            return s.form_id;
          }));
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.questions = {
          TAG: "In",
          _0: questionIds
        }, newrecord$1.levels = {
          TAG: "In",
          _0: levelIds
        }, newrecord$1.companyForms = {
          TAG: "In",
          _0: requiredFormsIds$1
        }, newrecord$1));
  var companyForms = match$1.companyForms;
  var requiredProcedureIds = Curry._2(Prelude.$$Array.concat, newInspections.map(function (i) {
            return i.procedure_id;
          }), Curry._1(Prelude.$$Array.concatMany, companyForms.map(function (cf) {
                return cf.related_procedures.map(function (r) {
                            return r.procedure_id;
                          });
              })));
  var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$2.procedures = {
          TAG: "In",
          _0: requiredProcedureIds
        }, newrecord$2));
  if (vessels.length !== 1) {
    return [];
  }
  var vessel = vessels[0];
  var procedures = ProcedureId.$$Map.fromArray(ProcedureUpdate.apply(procedureUpdates, match$2.procedures).map(function (p) {
            return [
                    p.id,
                    p
                  ];
          }));
  var companyForms$1 = CompanyFormId.$$Map.fromArray(companyForms.map(function (c) {
            return [
                    c.id,
                    c
                  ];
          }));
  var procedureIds = newInspections.map(function (n) {
        return n.procedure_id;
      });
  var isRectificationByInspection = InspectionId.$$Map.fromArray(Curry._1(Prelude.$$Array.concatMany, ProcedureId.$$Map.toArray(ProcedureId.$$Array.group(Curry._2(Prelude.$$Array.concat, newInspections, Curry._1(Prelude.$$Array.concatMany, ProcedureId.$$Map.toArray(ProcedureId.$$Array.group(Curry._2(Prelude.$$Array.keep, inspections, (function (i) {
                                                return Prelude.$$Array.includes(procedureIds, i.procedure_id);
                                              })), NewInspection.byProcedure)).map(function (param) {
                                    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(Prelude.$$Array.sort(param[1], Inspection.byTimestamp, undefined)), (function (i) {
                                                      return [i];
                                                    })), []);
                                  }))), NewInspection.byProcedure)).map(function (param) {
                  return Curry._3(Prelude.$$Array.fold, Prelude.$$Array.sort(param[1], Inspection.byTimestamp, undefined), [], (function (isRectificationByInspection, inspection) {
                                if (Caml_obj.equal(isRectificationByInspection, [])) {
                                  return Prelude.$$Array.append(isRectificationByInspection, [
                                              inspection,
                                              false
                                            ]);
                                }
                                var match = Prelude.$$Array.last(isRectificationByInspection);
                                return Prelude.$$Array.append(isRectificationByInspection, [
                                            inspection,
                                            NewInspection.isDeficient(match[0])
                                          ]);
                              }));
                })).map(function (param) {
            return [
                    param[0].id,
                    param[1]
                  ];
          }));
  var match$3 = Curry._2(Prelude.$$Array.partition, newInspections, (function (i) {
          var error = InspectionAPI.Serializer.serialize(i);
          if (error.TAG === "Ok") {
            return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ProcedureId.$$Map.get(procedures, i.procedure_id), (function (extra) {
                              if (!NewInspection.isRated(i, extra)) {
                                return false;
                              }
                              var isRectification = Prelude.default(InspectionId.$$Map.get(isRectificationByInspection, i.id), false);
                              if (isRectification) {
                                return i.additional_annotation.trim().length > 0;
                              } else {
                                return true;
                              }
                            })), false);
          }
          Browser.captureEvent({
                level: "warning",
                message: "Could not serialize inspection: " + i.id,
                logger: "UploadPrepare",
                fingerprint: ["InspectionAPI.Serializer.serialize"],
                extra: Caml_option.some(Object.assign(i, {
                          error: JSON.stringify(error._0)
                        }))
              });
          return false;
        }));
  var completedNewInspections = match$3[0];
  var procedureByInspectionId = InspectionId.$$Map.fromArray(Curry._2(Prelude.$$Array.concat, inspections.map(function (i) {
                return [
                        i.id,
                        i.procedure_id
                      ];
              }), completedNewInspections.map(function (i) {
                return [
                        i.id,
                        i.procedure_id
                      ];
              })));
  var requests = completedNewInspections.map(function (extra) {
        return UploadRequest.fromInspection(vessel, extra);
      });
  var requests$1 = Curry._2(Prelude.$$Array.concat, requests, match$3[1].map(function (extra) {
            return UploadRequest.fromIncompleteInspection(vessel, extra);
          }));
  var userProfileRequests = match.newUserProfiles.map(function (profile) {
        return UploadRequest.fromUserProfile(vessel, profile);
      });
  var requests$2 = Curry._2(Prelude.$$Array.concat, requests$1, userProfileRequests);
  var levelById = LevelId.$$Set.fromArray(match$1.levels.map(function (l) {
            return l.id;
          }));
  var questionById = QuestionId.$$Set.fromArray(match$1.questions.map(function (q) {
            return q.id;
          }));
  var isValidAnswer = function (answer) {
    if (LevelId.$$Set.has(levelById, answer.level_id)) {
      return QuestionId.$$Set.has(questionById, answer.question_id);
    } else {
      return false;
    }
  };
  var answerRequests = Curry._2(Prelude.$$Array.keep, newAnswers, isValidAnswer).map(function (a) {
        return UploadRequest.fromAnswer(vessel, a);
      });
  var requests$3 = Curry._2(Prelude.$$Array.concat, requests$2, answerRequests);
  var companyFormsSubmissionRequests = Curry._2(Prelude.$$Array.keepMap, newCompanyFormSubmissions, (function (submission) {
            return Curry._2(Prelude.OptionExported.$$Option.map, CompanyFormId.$$Map.get(companyForms$1, submission.form_id), (function (form) {
                          return [
                                  submission,
                                  form
                                ];
                        }));
          })).map(function (param) {
        return UploadRequest.fromCompanyFormSubmission(vessel, param[0], param[1]);
      });
  var requests$4 = Curry._2(Prelude.$$Array.concat, requests$3, companyFormsSubmissionRequests);
  var requiredProceduresByCompanyForm = CompanyFormId.$$Map.fromArray(CompanyFormId.$$Map.valuesToArray(companyForms$1).map(function (form) {
            return [
                    form.id,
                    Curry._2(Prelude.$$Array.keep, form.related_procedures, (function (p) {
                              return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ProcedureId.$$Map.get(procedures, p.procedure_id), (function (p) {
                                                return p.not_applicable_status === undefined;
                                              })), true);
                            })).map(function (p) {
                          return p.procedure_id;
                        })
                  ];
          }));
  var draftCompanyFormSubmissions$1 = Curry._2(Prelude.$$Array.keepMap, draftCompanyFormSubmissions, (function (submission) {
          return Curry._2(Prelude.OptionExported.$$Option.map, CompanyFormId.$$Map.get(companyForms$1, submission.form_id), (function (form) {
                        return [
                                submission,
                                form
                              ];
                      }));
        }));
  var match$4 = Curry._2(Prelude.$$Array.partition, draftCompanyFormSubmissions$1, (function (param) {
          var submission = param[0];
          var inspectedProcedureIds = Prelude.$$Array.keepSome(submission.inspections.map(function (extra) {
                    return Curry._2(InspectionId.$$Map.get, procedureByInspectionId, extra);
                  }));
          var requiredProcedureIds = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, CompanyFormId.$$Map.get(requiredProceduresByCompanyForm, submission.form_id), []);
          return CompanyFormSubmission.isCompleted(inspectedProcedureIds, requiredProcedureIds, undefined);
        }));
  var draftCompleteSubmissionRequests = match$4[0].map(function (param) {
        return UploadRequest.fromCompanyFormSubmission(vessel, param[0], param[1]);
      });
  var requests$5 = Curry._2(Prelude.$$Array.concat, requests$4, draftCompleteSubmissionRequests);
  var draftIncompleteCompanyFormRequests = match$4[1].map(function (param) {
        return UploadRequest.fromIncompleteCompanyForm(vessel, param[1]);
      });
  var requests$6 = Curry._2(Prelude.$$Array.concat, requests$5, draftIncompleteCompanyFormRequests);
  var levelSubmissionRequests = Curry._2(Prelude.$$Array.keep, match.draftLevelSubmissions, (function (s) {
            return s.isCompleted;
          })).map(function (extra) {
        return UploadRequest.fromLevelSubmission(vessel, extra);
      });
  var requests$7 = Curry._2(Prelude.$$Array.concat, requests$6, levelSubmissionRequests);
  var commentRequests = match.newComments.map(function (c) {
        return UploadRequest.fromComment(vessel, c);
      });
  var requests$8 = Curry._2(Prelude.$$Array.concat, requests$7, commentRequests);
  var updatedCommentRequests = match.updatedComments.map(function (c) {
        return UploadRequest.fromUpdatedComment(vessel, c);
      });
  var requests$9 = Curry._2(Prelude.$$Array.concat, requests$8, updatedCommentRequests);
  var procedureUpdateRequests = procedureUpdates.map(function (procedure) {
        return UploadRequest.fromProcedureUpdate(vessel, procedure);
      });
  var requests$10 = Curry._2(Prelude.$$Array.concat, requests$9, procedureUpdateRequests);
  var notificationEntries = NotificationEntryId.$$Map.fromArray(match.notificationEntries.map(function (n) {
            return [
                    n.id,
                    n
                  ];
          }));
  var readNotificationEntryRequests = Curry._2(Prelude.$$Array.keepMap, match.readNotificationEntries, (function (entry) {
          return Curry._2(Prelude.OptionExported.$$Option.map, NotificationEntryId.$$Map.get(notificationEntries, entry.id), (function (notification) {
                        return UploadRequest.fromReadNotificationEntry(vessel, entry, notification);
                      }));
        }));
  var requests$11 = Curry._2(Prelude.$$Array.concat, requests$10, readNotificationEntryRequests);
  var futureAttachments = await $$do(match.futureAttachments, (function (p, m) {
          progress("Checking files", p, m);
        }));
  return Curry._2(Prelude.$$Array.concat, requests$11, futureAttachments.map(function (group) {
                  return UploadRequest.fromAttachmentChunkGroup(vessel, group);
                }));
}

export {
  AutoCompanyFormsSubmissions ,
  CheckFutureAttachmentLiveness ,
  next ,
}
/* Query Not a pure module */

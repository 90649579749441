// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as UploadRequest from "../upload/UploadRequest.js";
import * as ReviewUpload__RatingStatusCard from "./ReviewUpload__RatingStatusCard.js";
import * as ReviewUpload__NotApplicableProceduresCard from "./ReviewUpload__NotApplicableProceduresCard.js";

async function init(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var match$1 = vnode.attrs;
  var requests = match$1.requests;
  var completed = Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getCompleteInspection);
  var incomplete = Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getIncompleteInspection);
  var notApplicableProceduresIds = ProcedureId.$$Set.toArray(ProcedureId.$$Set.fromArray(Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getProcedureUpdate), (function (r) {
                    return Curry._1(Prelude.OptionExported.$$Option.isSome, r.not_applicable_status);
                  })).map(function (update) {
                return update.id;
              })));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord.procedures = {
          TAG: "In",
          _0: notApplicableProceduresIds
        }, newrecord));
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              completed: completed,
              incomplete: incomplete,
              notApplicableProcedures: match$2.procedures
            });
}

function valid(param) {
  return true;
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (v) {
                    init(v);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var completed = match.completed;
                if (completed.length === 0 && match.incomplete.length === 0 && match.notApplicableProcedures.length === 0) {
                  return Mithril.empty;
                }
                var notApplicableProcedures = match.notApplicableProcedures;
                var incomplete = match.incomplete;
                if (notApplicableProcedures.length !== 0) {
                  return [
                          ReactDOM.jsx("h3", {
                                children: "Inspections",
                                className: "mt-3 text-center"
                              }),
                          React.jsx(ReviewUpload__RatingStatusCard.make, {
                                ratingStatus: "Completed",
                                inspections: completed
                              }),
                          React.jsx(ReviewUpload__RatingStatusCard.make, {
                                ratingStatus: "Incomplete",
                                inspections: incomplete
                              }),
                          ReactDOM.jsx("div", {
                                className: "divider blank"
                              }),
                          React.jsx(ReviewUpload__NotApplicableProceduresCard.make, {
                                procedures: notApplicableProcedures
                              })
                        ];
                } else {
                  return [
                          ReactDOM.jsx("h3", {
                                children: "Inspections",
                                className: "mt-3 text-center"
                              }),
                          React.jsx(ReviewUpload__RatingStatusCard.make, {
                                ratingStatus: "Completed",
                                inspections: completed
                              }),
                          React.jsx(ReviewUpload__RatingStatusCard.make, {
                                ratingStatus: "Incomplete",
                                inspections: incomplete
                              })
                        ];
                }
              }));
}

var RatingStatusCard;

var NotApplicableProceduresCard;

export {
  RatingStatusCard ,
  NotApplicableProceduresCard ,
  init ,
  valid ,
  make ,
}
/* Query Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as UploadPayload from "./UploadPayload.js";
import * as FutureAttachment from "../../attachment_chunks/FutureAttachment.js";
import * as UploadActionable from "./UploadActionable.js";

function succeededAttachments(results) {
  return FutureAttachment.deduplicate(Prelude.$$Array.flatMap(results, (function (r) {
                    if (r.TAG === "Ok") {
                      return UploadPayload.getFutureAttachments(UploadActionable.getPayloads(r._0));
                    } else {
                      return [];
                    }
                  })));
}

function size(results) {
  return Curry._3(Prelude.$$Array.fold, succeededAttachments(results), 0.0, (function (r, attachment) {
                return r + attachment.size;
              }));
}

function getVessels(results) {
  return Prelude.$$Array.flatMap(results, (function (r) {
                if (r.TAG === "Ok") {
                  return UploadPayload.getVessels(UploadActionable.getPayloads(r._0));
                } else {
                  return UploadPayload.getVessels(UploadActionable.getPayloads(r._0[0]));
                }
              }));
}

function getVesselIds(results) {
  return VesselId.$$Set.toArray(VesselId.$$Set.fromArray(getVessels(results).map(function (v) {
                      return v.id;
                    })));
}

export {
  succeededAttachments ,
  size ,
  getVessels ,
  getVesselIds ,
}
/* VesselId Not a pure module */

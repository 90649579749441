// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

function make(max) {
  return {
          max: max,
          data: []
        };
}

function isFull(la) {
  return la.data.length === la.max;
}

function push(la, item) {
  if (isFull(la)) {
    la.data.shift();
  }
  la.data.push(item);
  return la;
}

function clear(la) {
  la.data.splice(0);
}

function forEach(ls, f) {
  Curry._2(Prelude.$$Array.forEach, ls.data, f);
}

function map(ls, f) {
  return ls.data.map(f);
}

function toArray(ls) {
  return Curry._2(Prelude.$$Array.concat, ls.data, []);
}

export {
  make ,
  isFull ,
  push ,
  clear ,
  forEach ,
  map ,
  toArray ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message() {
  return "Add new types of inspections";
}

function migrate(_db, _transaction) {
  return Promise.resolve({
              TAG: "Ok",
              _0: undefined
            });
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mutex from "../../../libs/Mutex.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AppSchema from "../../AppSchema.js";
import * as LocalFile from "../../../libs/LocalFile.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureImage from "../../survey/ProcedureImage.js";

var name = "ServerSyncFiles" + ": Downloader";

function action(state) {
  var diff = AppSchema.Files.getActions(state.initial, state.final);
  var requests = Curry._2(Prelude.$$Array.keepMap, LocalFile.UUID.$$Map.toArray(LocalFile.UUID.$$Map.fromArray(Curry._2(Prelude.$$Array.keepMap, diff, (function (action) {
                        if (action.TAG !== "Update") {
                          return ;
                        }
                        var file = action._0;
                        return [
                                file.id,
                                file
                              ];
                      })))), (function (param) {
            var id = param[0];
            return Curry._2(Prelude.OptionExported.$$Option.map, ProcedureImage.request(param[1]), (function (request) {
                          return [
                                  id,
                                  request
                                ];
                        }));
          })).map(function (param) {
        return LocalFile.downloadRequest(param[0], param[1], true);
      });
  var gone = Curry._2(Prelude.$$Array.keepMap, diff, (function (action) {
          if (action.TAG === "Update") {
            return ;
          } else {
            return Caml_option.some(action._0);
          }
        }));
  Prelude.catchResolve(Prelude.thenResolve(Prelude.then(Promise.all(requests), (function (param) {
                  return LocalFile.deleteMany(gone);
                })), (function (res) {
              return {
                      TAG: "Ok",
                      _0: res
                    };
            })), (function (error) {
          console.log(error);
          return {
                  TAG: "Error",
                  _0: error
                };
        }));
  return Promise.resolve({
              TAG: "Ok",
              _0: state
            });
}

var Downloader = Mutex.MakeMutexQueue({
      name: name,
      action: action
    });

function $$do(state) {
  return Prelude.then(Prelude.Promises.ellapsed(500), (function () {
                return Downloader.$$do(state);
              }));
}

export {
  Downloader ,
  $$do ,
}
/* Downloader Not a pure module */

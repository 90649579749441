// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function formatDate(date) {
  var r;
  try {
    r = Prelude.$$Array.first(date.toISOString().split("T"));
  }
  catch (exn){
    return ;
  }
  if (r !== undefined) {
    return r;
  }
  
}

function dateFromString(v) {
  var result = new Date(v);
  if (Number.isNaN(result.getDate())) {
    return ;
  } else {
    return Caml_option.some(result);
  }
}

export {
  formatDate ,
  dateFromString ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE


function make(param) {
  return function (param, transaction) {
    transaction.objectStore("levels").createIndex("kind", "kind");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* No side effect */

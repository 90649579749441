// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as InitFeedback from "../InitFeedback.js";

Utils.MakeOpaqueIdentifier({});

function message(param, param$1) {
  return "Adding new types of SIRE 2.0 forms";
}

function $$process(level) {
  return {
          TAG: "Update",
          _0: {
            id: level.id,
            parent_id: level.parent_id,
            vessel_id: level.vessel_id,
            guidance_document_id: level.guidance_document_id,
            name: level.name,
            path: level.path,
            number: level.number,
            depth: level.depth,
            recurrence: level.recurrence,
            due_date: level.due_date,
            overdue_date: level.overdue_date,
            ignore_overdue_status: level.ignore_overdue_status,
            kind: "018722a3-1b59-7ea0-af21-21b969e170fa"
          }
        };
}

var LevelRewriter = InitFeedback.MakeSimpleRewriter({
      storeName: "levels",
      message: message,
      $$process: $$process
    });

function message$1(param) {
  return "Adding new types of SIRE 2.0 forms";
}

function migrate(_db, transaction) {
  return LevelRewriter.rewrite(transaction);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* LevelRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var options = match.options;
                var id = match.id;
                var required = Core__Option.getOr(vnode.attrs.required, true);
                var errorMessage = Core__Option.getOr(vnode.attrs.errorMessage, "");
                var disabled = Core__Option.getOr(vnode.attrs.disabled, false);
                var className = vnode.attrs.className;
                var option = vnode.attrs.default;
                if (option !== undefined) {
                  options.unshift(option);
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("label", {
                                    children: [
                                      match.title,
                                      React.jsx(Conditional.make, {
                                            cond: errorMessage !== "",
                                            children: ReactDOM.jsx("span", {
                                                  children: errorMessage,
                                                  className: "tw-ml-1 tw-text-error"
                                                })
                                          })
                                    ],
                                    className: "tw-font-medium",
                                    htmlFor: id
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(Feather.make, {
                                            icon: "chevron_down",
                                            className: Mithril.classList([
                                                  "tw-absolute tw-right-[1px] tw-top-1/2 -tw-translate-y-1/2",
                                                  "tw-h-4 tw-w-4 tw-mx-1",
                                                  disabled ? "tw-opacity-70" : ""
                                                ], undefined)
                                          }),
                                      ReactDOM.jsx("select", {
                                            children: options.map(function (x) {
                                                  return ReactDOM.jsx("option", {
                                                              children: Core__Option.getOr(x.displayVal, x.value),
                                                              disabled: Core__Option.getOr(x.disabled, false),
                                                              selected: x.selected,
                                                              value: x.value
                                                            });
                                                }),
                                            className: Mithril.classList([
                                                  "tw-relative tw-z-10 tw-w-full tw-h-7 tw-appearance-none tw-rounded-sm",
                                                  "tw-bg-transparent tw-border-[#adb5c4] invalid:tw-text-[grey]",
                                                  "tw-py-1 tw-pl-2 tw-pr-6 tw-text-inherit tw-text-sm tw-align-middle",
                                                  "tw-outline-none tw-border tw-border-solid"
                                                ], undefined),
                                            id: id,
                                            disabled: disabled,
                                            name: id,
                                            required: required,
                                            onChange: match.onChange
                                          })
                                    ],
                                    className: Mithril.classList([
                                          "tw-relative",
                                          disabled ? "tw-bg-gray-200" : "tw-bg-white"
                                        ], undefined)
                                  })
                            ],
                            className: className
                          });
              }));
}

var Options = {};

export {
  Options ,
  make ,
}
/* React Not a pure module */

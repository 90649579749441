// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../common/Forms.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Utils from "../../../libs/Utils.js";
import * as Browser from "../../../libs/Browser.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as DataList from "../../../libs/DataList.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as QuestionType from "../QuestionType.js";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.js";

var Id = Utils.MakeOpaqueIdentifier({});

function editInspection(vnode, $$event) {
  $$event.stopPropagation();
  var text = $$event.target.value;
  var state = vnode.state.data;
  vnode.state.data = {
    text: text,
    widgetId: state.widgetId
  };
  vnode.attrs.setText(text);
}

function setFlexGrow(viewport, newValue) {
  Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Webapi__Dom__HtmlElement.ofElement(viewport), (function (d) {
              d.style.setProperty("flex-grow", newValue);
            })), undefined);
}

function setPositionToFixed(element, topPosition) {
  Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Webapi__Dom__HtmlElement.ofElement(element), (function (d) {
              var style = d.style;
              style.setProperty("bottom", "unset");
              style.setProperty("position", "fixed");
              style.setProperty("top", String(topPosition) + "px");
            })), undefined);
}

function setPositionToAbsolute(element) {
  Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Webapi__Dom__HtmlElement.ofElement(element), (function (d) {
              var style = d.style;
              style.setProperty("top", "unset");
              style.setProperty("position", "absolute");
              style.setProperty("bottom", "3rem");
            })), undefined);
}

function addListenerOnVisualViewportResize(element, originalHeight, position) {
  var visualViewport = window.visualViewport;
  visualViewport.addEventListener("resize", (function (e) {
          var vp = e.target;
          e.stopPropagation();
          var currentHeight = vp.height;
          if (position !== undefined) {
            if (currentHeight < originalHeight) {
              return setPositionToFixed(element, position + 10.0);
            } else {
              return setPositionToAbsolute(element);
            }
          } else if (currentHeight < originalHeight) {
            return setFlexGrow(element, "0");
          } else {
            return setFlexGrow(element, "1");
          }
        }));
}

function make(param) {
  return Mithril.view(Mithril.oncreate(Mithril.oninit(Mithril.component({
                          text: "",
                          widgetId: Id.make()
                        }), (function (vnode) {
                        var match = vnode.state.data;
                        var inspection = vnode.attrs.inspection;
                        Mithril.setState(vnode, {
                              text: Prelude.default(QuestionType.Value.getText(inspection.value), ""),
                              widgetId: match.widgetId
                            });
                      })), (function (vnode) {
                    var originalHeight = window.visualViewport.height;
                    var waitForViewportReady = function () {
                      var viewport = Caml_option.nullable_to_opt(document.getElementById("main-scroll-viewport"));
                      if (viewport !== undefined) {
                        var viewport$1 = Caml_option.valFromOption(viewport);
                        var input = viewport$1.querySelector("#question-input-text");
                        var overNavbar = viewport$1.querySelector(".btn-group.btn-group-block.btn-group-floating");
                        var position = Caml_option.nullable_to_opt(viewport$1.querySelector("#text-question-widget")).getBoundingClientRect().bottom;
                        if (overNavbar == null) {
                          addListenerOnVisualViewportResize(viewport$1, originalHeight, undefined);
                        } else {
                          addListenerOnVisualViewportResize(overNavbar, originalHeight, position);
                        }
                        return Mithril.$$Element.focus(Caml_option.some((input == null) ? undefined : Caml_option.some(input)));
                      }
                      setTimeout(waitForViewportReady, 100);
                    };
                    if (Browser.runningOnMobile()) {
                      return waitForViewportReady();
                    } else {
                      return Forms.Input.focus(vnode);
                    }
                  })), (function (vnode) {
                var procedure = vnode.attrs.procedure;
                var match = vnode.state.data;
                var widgetId = match.widgetId;
                var text = match.text;
                var options = procedure.suggested_text_values;
                var tmp;
                var exit = 0;
                if (options !== undefined && options.length !== 0) {
                  tmp = [
                    React.jsx(DataList.make, {
                          options: options,
                          id: widgetId
                        }),
                    ReactDOM.jsx("input", {
                          className: "form-input",
                          id: "question-input-text",
                          list: widgetId,
                          name: procedure.id,
                          value: text,
                          onKeyUp: (function (extra) {
                              return editInspection(vnode, extra);
                            })
                        })
                  ];
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp = [ReactDOM.jsx("textarea", {
                          children: text,
                          className: "form-input",
                          id: "question-input-text",
                          name: procedure.id,
                          rows: 8,
                          onKeyUp: (function (extra) {
                              return editInspection(vnode, extra);
                            })
                        })];
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("label", {
                                    children: procedure.name,
                                    className: "form-label",
                                    htmlFor: "question-input-text"
                                  }),
                              tmp
                            ],
                            className: "form-group",
                            id: "text-question-widget"
                          });
              }));
}

export {
  Id ,
  editInspection ,
  setFlexGrow ,
  setPositionToFixed ,
  setPositionToAbsolute ,
  addListenerOnVisualViewportResize ,
  make ,
}
/* Id Not a pure module */

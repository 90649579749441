// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as ProcedureImage from "../../survey/ProcedureImage.js";

var url = "/documents";

var fields = [
  [
    "id",
    "String"
  ],
  [
    "title",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "contents",
    "String"
  ],
  [
    "attachments",
    {
      TAG: "Collection",
      _0: {
        name: ProcedureImage.Deserializer.name,
        fromJSON: ProcedureImage.Deserializer.fromJSON,
        checkFieldsSanity: ProcedureImage.Deserializer.checkFieldsSanity
      }
    }
  ]
];

var Definition = {
  url: url,
  fields: fields
};

var include = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var get = include.get;

var getAll = include.getAll;

var options = include.options;

var postMany = include.postMany;

var postOne = include.postOne;

var postForm = include.postForm;

export {
  Definition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  get ,
  getAll ,
  options ,
  postMany ,
  postOne ,
  postForm ,
}
/* include Not a pure module */

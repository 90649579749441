// Generated by ReScript, PLEASE EDIT WITH CARE


function toRating(answer) {
  switch (answer) {
    case "Yes" :
        return 5;
    case "No" :
        return 1;
    case "Unknown" :
        return 0;
    
  }
}

function description(answer) {
  switch (answer) {
    case "Yes" :
        return {
                text: "Yes. All OK",
                color: "success"
              };
    case "No" :
        return {
                text: "Not OK",
                color: "error"
              };
    case "Unknown" :
        return {
                text: "Unknown",
                color: "gray"
              };
    
  }
}

function fromRating(rating) {
  switch (rating) {
    case 0 :
        return "Unknown";
    case 5 :
        return "Yes";
    default:
      return "No";
  }
}

export {
  toRating ,
  description ,
  fromRating ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as ModalBackdrop from "./ModalBackdrop.js";
import * as ButtonSlimIcon from "./ButtonSlimIcon.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onClose = match.onClose;
                var onBack = Core__Option.getOr(vnode.attrs.onBack, onClose);
                var showModal = Core__Option.getOr(vnode.attrs.showModal, true);
                return ReactDOM.jsxs("div", {
                            children: [
                              React.jsx(ModalBackdrop.make, {
                                    onClick: onClose,
                                    showModal: showModal
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              React.jsx(ButtonSlimIcon.make, {
                                                    icon: "arrow_left",
                                                    onClick: onBack,
                                                    size: 25,
                                                    className: "tw-pl-0 tw-pr-2"
                                                  }),
                                              React.jsx(ButtonSlimIcon.make, {
                                                    icon: "x",
                                                    onClick: onClose,
                                                    size: 18,
                                                    style: "black",
                                                    className: "tw-pl-2 tw-pr-0"
                                                  })
                                            ],
                                            className: "tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-2"
                                          }),
                                      vnode.children
                                    ],
                                    className: Mithril.classList([
                                          showModal ? "tw-block" : "tw-hidden",
                                          "tw-fixed tw-left-0 tw-bottom-0 tw-w-full tw-bg-white tw-z-1501",
                                          "tw-flex tw-flex-col tw-overflow-y-auto tw-max-h-[95vh]",
                                          "tw-pt-1 tw-px-3 tw-pb-4"
                                        ], undefined)
                                  })
                            ],
                            className: "t-modal-template-simple"
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

function title(stage) {
  switch (stage.TAG) {
    case "Requested" :
        return "Initializing the upload...";
    case "Filtering" :
        return "Contacting the server to filter items...";
    case "Preparing" :
        return "Contacting the server to start the upload...";
    case "Uploading" :
        return "Uploading...";
    case "Finalizing" :
        return "Upload is about to be completed...";
    case "Done" :
        return Curry._3(Prelude.Result.mapWithDefault, Prelude.ManyResults.bail(stage._0), "Upload failed!", (function (param) {
                      return "Upload completed";
                    }));
    
  }
}

function describe(stage) {
  switch (stage.TAG) {
    case "Requested" :
        return "Requested " + String(stage._0.length);
    case "Filtering" :
        return "Filtering";
    case "Preparing" :
        return "Preparing";
    case "Uploading" :
        return "Uploading " + String(stage.results.length);
    case "Finalizing" :
        return "Finalizing";
    case "Done" :
        return "Done";
    
  }
}

export {
  title ,
  describe ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing new checklists options";
}

function $$process(section) {
  return {
          id: section.id,
          vessel_id: section.vessel_id,
          name: section.name,
          type: section.type,
          image: section.image,
          picture_prompt_every_n_inspections: section.picture_prompt_every_n_inspections,
          rating_styling_rules_id: section.rating_styling_rules_id,
          require_image_conditions: section.require_image_conditions,
          guidance_document: section.guidance_document,
          archived: false
        };
}

var SectionRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "sections",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing new checklists options";
}

function migrate(param, t) {
  return SectionRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* SectionRewriter Not a pure module */

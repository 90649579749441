// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Component from "../../vessel/Component.js";
import * as Procedure from "../../survey/Procedure.js";
import * as SectionId from "../../vessel/SectionId.js";
import * as Deficiency from "../Deficiency.js";
import * as Inspection from "../../survey/Inspection.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ComponentId from "../../vessel/ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as InspectionId from "../../survey/InspectionId.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as CommentDetails from "../../comments/views/CommentDetails.js";

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var deficiency = vnode.attrs.deficiency;
                    var inspections = Curry._2(Prelude.$$Array.keep, vnode.attrs.inspections, (function (i) {
                            return Caml_obj.equal(i.deficiency_id, Caml_option.some(deficiency.id));
                          }));
                    var newComments = Curry._2(Prelude.$$Array.keep, vnode.attrs.comments, (function (c) {
                            return c.object_id === deficiency.id;
                          }));
                    var match = Belt_List.fromArray(Prelude.$$Array.sort(inspections, Inspection.byTimestamp, undefined));
                    Mithril.setState(vnode, match ? ({
                              TAG: "Found",
                              firstInspection: match.hd,
                              lastInspection: Prelude.$$Array.last(Belt_List.toArray(match.tl)),
                              newComments: newComments
                            }) : "NoInspection");
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var firstInspection = match.firstInspection;
                var resource = Curry._2(Prelude.OptionExported.$$Option.flatMap, match.lastInspection, Inspection.getMainImage);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: resource !== undefined ? React.jsx(LocalImage.Resource.make, {
                                            resource: Caml_option.valFromOption(resource)
                                          }) : Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Inspection.getMainImage(firstInspection), (function (resource) {
                                                  return React.jsx(LocalImage.Resource.make, {
                                                              resource: resource
                                                            });
                                                })), Mithril.empty),
                                    className: "card-image"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("h5", {
                                          children: firstInspection.code
                                        }),
                                    className: "card-header"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("strong", {
                                            children: "New Comments"
                                          }),
                                      ReactDOM.jsx("div", {
                                            className: "divider"
                                          }),
                                      match.newComments.map(function (comment) {
                                            var profile = comment.profile;
                                            return React.jsxKeyed(CommentDetails.make, {
                                                        author: profile !== undefined ? UserProfile.nameWithRank(profile) : comment.author.first_name + " " + comment.author.last_name,
                                                        color: "initial",
                                                        body: comment.body,
                                                        date: comment.timestamp,
                                                        isNew: true
                                                      }, comment.id, undefined);
                                          })
                                    ],
                                    className: "card-body pt-0"
                                  })
                            ],
                            className: "card card-shadow m-2"
                          });
              }));
}

var DeficiencyCard = {
  make: make
};

function toggleState(vnode, _event) {
  var match = vnode.state.data;
  var tmp;
  if (typeof match !== "object") {
    var inspections = vnode.attrs.inspections;
    tmp = Prelude.thenResolve(Query.$$do([
              {
                TAG: "Read",
                _0: (function (param) {
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    newrecord.deficiencies = {
                      TAG: "In",
                      _0: Curry._2(Prelude.$$Array.keepMap, inspections, (function (inspection) {
                              return Inspection.byDeficiency(inspection);
                            }))
                    };
                    return newrecord;
                  })
              },
              {
                TAG: "Read",
                _0: (function (param) {
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    newrecord.procedures = {
                      TAG: "In",
                      _0: inspections.map(NewInspection.byProcedure)
                    };
                    return newrecord;
                  })
              }
            ]), (function (param) {
            return {
                    TAG: "Open",
                    proceduresOfComponent: ComponentId.$$Array.getter(Prelude.$$Array.sort(param.procedures, Procedure.byOrder, undefined), Procedure.byComponent),
                    inspectionsOfProcedure: ProcedureId.$$Array.getter(inspections, NewInspection.byProcedure),
                    deficienciesOfInspection: InspectionId.$$Array.getter(param.deficiencies, Deficiency.byDetectedWith)
                  };
          }));
  } else {
    tmp = Promise.resolve("Closed");
  }
  Prelude.thenDo(tmp, (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function make$1(param) {
  return Mithril.view(Mithril.component("Closed"), (function (vnode) {
                var components = vnode.attrs.components;
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var deficienciesOfInspection = match.deficienciesOfInspection;
                  var inspectionsOfProcedure = match.inspectionsOfProcedure;
                  var proceduresOfComponent = match.proceduresOfComponent;
                  tmp = Prelude.$$Array.flatMap(components, (function (component) {
                          return Curry._2(Prelude.$$Array.concat, component.number === 0 ? [] : [ReactDOM.jsxKeyed("div", {
                                              children: "Number: " + String(component.number),
                                              className: "h6 text-center pt-2"
                                            }, vnode.attrs.id + "_" + component.id, undefined)], Prelude.$$Array.flatMap(proceduresOfComponent(component.id), (function (procedure) {
                                            var inspections = inspectionsOfProcedure(procedure.id);
                                            return Prelude.$$Array.flatMap(inspections, (function (inspection) {
                                                          return deficienciesOfInspection(inspection.id).map(function (deficiency) {
                                                                      return React.jsxKeyed(make, {
                                                                                  deficiency: deficiency,
                                                                                  inspections: inspections,
                                                                                  comments: vnode.attrs.comments
                                                                                }, deficiency.id, undefined);
                                                                    });
                                                        }));
                                          })));
                        }));
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("input", {
                                    hidden: true,
                                    id: vnode.attrs.id,
                                    name: "accordion-checkbox",
                                    type: "checkbox",
                                    onChange: (function (extra) {
                                        return toggleState(vnode, extra);
                                      })
                                  }),
                              ReactDOM.jsxs("label", {
                                    children: [
                                      React.jsx(Feather.make, {
                                            icon: "chevron_right",
                                            className: "icon",
                                            style: "top: 0.25rem; position: relative; float: right; margin-right: 0.2rem;",
                                            size: 18
                                          }),
                                      Prelude.$$Array.firstUnsafe(components).name
                                    ],
                                    className: "accordion-header text-primary h6",
                                    style: {
                                      paddingLeft: "0.6rem"
                                    },
                                    htmlFor: vnode.attrs.id
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "accordion-body"
                                  })
                            ],
                            className: "accordion"
                          });
              }));
}

var ComponentAccordion = {
  toggleState: toggleState,
  make: make$1
};

function toggleState$1(vnode, _event) {
  var match = vnode.state.data;
  var tmp;
  if (typeof match !== "object") {
    var inspections = vnode.attrs.inspections;
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    tmp = Prelude.thenResolve(Query.read((newrecord.components = {
                TAG: "In",
                _0: inspections.map(NewInspection.byComponent)
              }, newrecord)), (function (param) {
            return {
                    TAG: "Open",
                    componentGroups: Component.groupByNameAndNumber(param.components, undefined, undefined),
                    inspectionsOfComponent: ComponentId.$$Array.getter(inspections, NewInspection.byComponent)
                  };
          }));
  } else {
    tmp = Promise.resolve("Closed");
  }
  Prelude.thenDo(tmp, (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function make$2(param) {
  return Mithril.view(Mithril.component("Closed"), (function (vnode) {
                var section = vnode.attrs.section;
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var inspectionsOfComponent = match.inspectionsOfComponent;
                  tmp = match.componentGroups.map(function (components) {
                        var id = vnode.attrs.id + "_" + Prelude.$$Array.firstUnsafe(components).id;
                        return React.jsxKeyed(make$1, {
                                    id: id,
                                    components: components,
                                    inspections: Curry._1(Prelude.$$Array.concatMany, components.map(function (param) {
                                              return inspectionsOfComponent(param.id);
                                            })),
                                    comments: vnode.attrs.comments
                                  }, id, undefined);
                      });
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("input", {
                                    hidden: true,
                                    id: vnode.attrs.id,
                                    name: "accordion-checkbox",
                                    type: "checkbox",
                                    onChange: (function (extra) {
                                        return toggleState$1(vnode, extra);
                                      })
                                  }),
                              ReactDOM.jsxs("label", {
                                    children: [
                                      section.name,
                                      React.jsx(Feather.make, {
                                            icon: "chevron_right",
                                            className: "icon",
                                            style: "top: 0.25rem; position: relative; float: right"
                                          })
                                    ],
                                    className: "accordion-header text-primary h5",
                                    htmlFor: vnode.attrs.id
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "accordion-body"
                                  })
                            ],
                            className: "accordion"
                          });
              }));
}

var SectionAccordion = {
  toggleState: toggleState$1,
  make: make$2
};

function populate(vnode) {
  var finding_ids = vnode.attrs.finding_ids;
  Prelude.thenDo(Query.$$do([
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.deficiencies = {
                    TAG: "In",
                    _0: finding_ids
                  };
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.inspections = {
                    TAG: "In",
                    _0: param.deficiencies.map(Deficiency.byDetectedWith)
                  };
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.sections = {
                    TAG: "In",
                    _0: param.inspections.map(Inspection.bySection)
                  };
                  return newrecord;
                })
            }
          ]), (function (param) {
          var init = vnode.state.data;
          Mithril.setState(vnode, {
                activeTab: init.activeTab,
                sections: param.sections,
                inspectionsOfSection: SectionId.$$Array.getter(param.inspections, Inspection.bySection)
              });
        }));
}

function switchToTab(vnode, deficiencyStatus) {
  vnode.state.data = {
    activeTab: deficiencyStatus,
    sections: [],
    inspectionsOfSection: undefined
  };
  populate(vnode);
}

function make$3(param) {
  var activeTab = Prelude.default(Prelude.maybe(Mithril$1.route.param("activeTab"), Deficiency.statusFromJs), "active");
  return Mithril.view(Mithril.oninit(Mithril.component({
                      activeTab: activeTab,
                      sections: [],
                      inspectionsOfSection: undefined
                    }), populate), (function (vnode) {
                var sections = vnode.state.data.sections;
                var tmp;
                if (sections.length !== 0) {
                  var inspectionsOfSection = Curry._1(Prelude.OptionExported.$$Option.getExn, vnode.state.data.inspectionsOfSection);
                  tmp = sections.map(function (section) {
                        var id = section.id;
                        return React.jsxKeyed(make$2, {
                                    id: id,
                                    section: section,
                                    inspections: inspectionsOfSection(section.id),
                                    comments: vnode.attrs.comments
                                  }, id, undefined);
                      });
                } else {
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsxs("div", {
                                children: [
                                  React.jsx(Feather.make, {
                                        icon: "check",
                                        className: "p-centered",
                                        size: 45
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("h4", {
                                        children: "There are no findings here",
                                        className: "text-gray text-bold"
                                      })
                                ],
                                className: "text-center text-gray"
                              }),
                          ReactDOM.jsx("div", {})
                        ],
                        style: {
                          marginTop: "50%"
                        }
                      });
                }
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsx("div", {
                                  children: tmp,
                                  className: "t-deficiencies"
                                })
                          });
              }));
}

export {
  DeficiencyCard ,
  ComponentAccordion ,
  SectionAccordion ,
  populate ,
  switchToTab ,
  make$3 as make,
}
/* Query Not a pure module */

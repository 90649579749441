// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Webapi__Dom__Window from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Window.js";

var _deviceSupportsTouchEvents = (function() {return "ontouchend" in document});

function _device(param) {
  return (window.navigator.platform + window.navigator.userAgent).toLowerCase();
}

function runningOnAndroid(param) {
  return (window.navigator.platform + window.navigator.userAgent).toLowerCase().includes("android");
}

function runingOnIos(param) {
  if ((window.navigator.platform + window.navigator.userAgent).toLowerCase().includes("iphone") || (window.navigator.platform + window.navigator.userAgent).toLowerCase().includes("ipad")) {
    return true;
  } else if (_deviceSupportsTouchEvents()) {
    if ((window.navigator.platform + window.navigator.userAgent).toLowerCase().includes("macintosh")) {
      return true;
    } else {
      return (window.navigator.platform + window.navigator.userAgent).toLowerCase().includes("macintel");
    }
  } else {
    return false;
  }
}

function runningOnMobile(param) {
  if (runningOnAndroid()) {
    return true;
  } else {
    return runingOnIos();
  }
}

var current = window;

function confirm(msg) {
  return current.confirm(msg);
}

var $$Window = {
  current: current,
  Impl: Webapi__Dom__Window.Impl,
  confirm: confirm
};

export {
  _deviceSupportsTouchEvents ,
  _device ,
  runningOnAndroid ,
  runingOnIos ,
  runningOnMobile ,
  $$Window ,
}
/* current Not a pure module */

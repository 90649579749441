// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

var StepsCurrentCounter = IDB__Migration__Utils.MakeReader({
      storeName: "steps"
    });

var Identifier = Utils.MakeOpaqueIdentifier({});

function message(param) {
  return "Updating effort assessment module";
}

function migrate(db, transaction) {
  return Prelude.then(StepsCurrentCounter.read(transaction), (function (steps) {
                var current_steps = steps.length;
                var data = function () {
                  return [{
                            id: Identifier.zero,
                            current_steps: current_steps,
                            current_cycle: 1,
                            window: {
                              max: 1000,
                              data: []
                            }
                          }];
                };
                var StepsWriter = IDB__Migration__Utils.MakeWriter({
                      storeName: "stepsStates",
                      data: data
                    });
                IDB__Migration__Database.createObjectStore(db, StepsWriter.storeName);
                db.deleteObjectStore(StepsCurrentCounter.storeName);
                return Prelude.PromisedResult.map(StepsWriter.write(transaction, true), (function (param) {
                              
                            }));
              }));
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* StepsCurrentCounter Not a pure module */

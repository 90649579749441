// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Browser from "../../../libs/Browser.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as FileType from "file-type";
import * as Attachment from "../../attachment_chunks/Attachment.js";
import * as WebcamView from "./WebcamView.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ButtonAction from "../components/ButtonAction.js";
import * as PhotoOrientation from "../../../libs/PhotoOrientation.js";

function loadState(vnode) {
  vnode.state.data = "Loaded";
}

function matchLandscapeRequirement(vnode, image) {
  if (Prelude.default(vnode.attrs.requireLandscape, false)) {
    return PhotoOrientation.isLandscape(image);
  } else {
    return Promise.resolve({
                TAG: "Ok",
                _0: true
              });
  }
}

async function validateBlob(file) {
  var mediaType = file.type;
  var mediaType$1;
  if (mediaType.startsWith("image/")) {
    var detected;
    try {
      detected = await FileType.fileTypeFromBlob(file);
    }
    catch (exn){
      detected = undefined;
    }
    console.log("TakePicture", {
          "Blob.type": mediaType,
          detected: detected
        });
    mediaType$1 = detected !== undefined ? detected.mime : mediaType;
  } else {
    console.log("TakePicture", {
          "Blob.type": mediaType
        });
    mediaType$1 = mediaType;
  }
  var match = mediaType$1.split("/", 2);
  if (match.length !== 2) {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  var match$1 = match[0];
  switch (match$1) {
    case "application" :
        var subkind = match[1];
        switch (subkind) {
          case "pdf" :
              return {
                      TAG: "Error",
                      _0: "a PDF document"
                    };
          case "msword" :
          case "vnd.ms-access" :
          case "vnd.ms-excel" :
          case "vnd.ms-excel.addin.macroEnabled.12" :
          case "vnd.ms-excel.sheet.binary.macroEnabled.12" :
          case "vnd.ms-excel.sheet.macroEnabled.12" :
          case "vnd.ms-excel.template.macroEnabled.12" :
          case "vnd.ms-powerpoint" :
          case "vnd.ms-powerpoint.addin.macroEnabled.12" :
          case "vnd.ms-powerpoint.presentation.macroEnabled.12" :
          case "vnd.ms-powerpoint.slideshow.macroEnabled.12" :
          case "vnd.ms-powerpoint.template.macroEnabled.12" :
          case "vnd.ms-word.template.macroEnabled.12" :
          case "vnd.oasis.opendocument.spreadsheet" :
          case "vnd.oasis.opendocument.text" :
          case "vnd.openxmlformats-officedocument.presentationml.presentation" :
          case "vnd.openxmlformats-officedocument.presentationml.slideshow" :
          case "vnd.openxmlformats-officedocument.presentationml.template" :
          case "vnd.openxmlformats-officedocument.spreadsheetml.sheet" :
          case "vnd.openxmlformats-officedocument.spreadsheetml.template" :
          case "vnd.openxmlformats-officedocument.wordprocessingml.document" :
          case "vnd.openxmlformats-officedocument.wordprocessingml.template" :
              return {
                      TAG: "Error",
                      _0: "an Office document"
                    };
          default:
            return {
                    TAG: "Error",
                    _0: undefined
                  };
        }
    case "audio" :
        return {
                TAG: "Error",
                _0: "an audio file"
              };
    case "image" :
        return {
                TAG: "Ok",
                _0: undefined
              };
    case "text" :
        return {
                TAG: "Error",
                _0: "a text file"
              };
    case "video" :
        return {
                TAG: "Error",
                _0: "a video file"
              };
    default:
      return {
              TAG: "Error",
              _0: undefined
            };
  }
}

async function maybeCreateAttachment(vnode, image) {
  var match = await validateBlob(image);
  if (match.TAG === "Ok") {
    var result = await matchLandscapeRequirement(vnode, image);
    if (result.TAG === "Ok") {
      if (result._0) {
        var resolution = Prelude.default(vnode.attrs.resolution, "FHD");
        var onCapture = Prelude.default(vnode.attrs.onCapture, (function (param, param$1, param$2) {
                
              }));
        var match$1 = Attachment.makeImageAndCompress(image, Prelude.default(vnode.attrs.role, "EVIDENCE"), resolution);
        onCapture(image, match$1[0], match$1[1]);
        return "Loaded";
      } else {
        window.alert(Prelude.default(vnode.attrs.landscapeRequiredMessage, "Please, turn the phone and take a landscape picture"));
        return "Loaded";
      }
    }
    window.alert(Prelude.default(vnode.attrs.failureMessage, "Something went wrong. Please, take the picture again"));
    return "Loaded";
  }
  var aSomething = match._0;
  if (aSomething !== undefined) {
    window.alert("The file selected is not an image file but " + aSomething + ".  Please, select an image file.");
    return "Loaded";
  } else {
    window.alert("The file selected is not an image file.  Please select an image file");
    return "Loaded";
  }
}

function selectInputEl(vnode) {
  return Mithril.$$Element.select(vnode, "input[type=file]");
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadState), (function (vnode) {
                var onClick = function (extra) {
                  var match = vnode.state.data;
                  switch (match) {
                    case "Loaded" :
                        var match$1 = vnode.attrs.useEmbeddedCamera;
                        var useNativeCamera = match$1 === "ENABLED" ? false : (
                            match$1 === "CONDITIONAL" ? (console.log("Detecting browser's native/embedded camera", {
                                      platform: window.navigator.platform,
                                      userAgent: window.navigator.userAgent,
                                      isMobile: Browser.runningOnMobile()
                                    }), Browser.runningOnMobile()) : true
                          );
                        if (useNativeCamera) {
                          return Mithril.$$Element.click(selectInputEl(vnode));
                        } else {
                          vnode.state.data = "ShowingEmbeddedWebcam";
                          return ;
                        }
                    case "NotReady" :
                    case "ShowingEmbeddedWebcam" :
                        return ;
                    
                  }
                };
                var customClass = vnode.attrs.className;
                var className = Mithril.classList(["btn tw-w-full tw-h-full"], customClass);
                var style = Prelude.default(vnode.attrs.style, {});
                var match = vnode.state.data;
                var tmp;
                switch (match) {
                  case "NotReady" :
                      tmp = Mithril.empty;
                      break;
                  case "Loaded" :
                      var style$1 = vnode.attrs.buttonStyle;
                      tmp = style$1 !== undefined ? React.jsx(ButtonAction.make, {
                              children: vnode.children,
                              onClick: onClick,
                              style: style$1
                            }) : ReactDOM.jsx("button", {
                              children: vnode.children,
                              className: className,
                              onClick: onClick
                            });
                      break;
                  case "ShowingEmbeddedWebcam" :
                      tmp = React.jsx(WebcamView.make, {
                            className: "tw-flex ",
                            snapCallback: (function (extra) {
                                var match = vnode.state.data;
                                var tmp;
                                var exit = 0;
                                switch (match) {
                                  case "NotReady" :
                                      tmp = Promise.resolve("NotReady");
                                      break;
                                  case "Loaded" :
                                  case "ShowingEmbeddedWebcam" :
                                      exit = 1;
                                      break;
                                  
                                }
                                if (exit === 1) {
                                  if (extra !== undefined) {
                                    tmp = Prelude.thenResolve(maybeCreateAttachment(vnode, Caml_option.valFromOption(extra)), (function (param) {
                                            return "Loaded";
                                          }));
                                  } else {
                                    console.warn("TakePicture", "Embedded camera failure to capture the blob");
                                    var fn = vnode.attrs.onCameraFailure;
                                    if (fn !== undefined) {
                                      fn();
                                    }
                                    tmp = Promise.resolve("Loaded");
                                  }
                                }
                                return Prelude.thenDo(tmp, (function (extra) {
                                              vnode.state.data = extra;
                                            }));
                              })
                          });
                      break;
                  
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              Mithril$1("input", {
                                    name: "file",
                                    type: "file",
                                    accept: "image/*",
                                    capture: "environment",
                                    onchange: (function (extra) {
                                        return Prelude.thenDo(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(Prelude.default(Mithril.$$Element.files(selectInputEl(vnode)), [])), (function (file) {
                                                              return maybeCreateAttachment(vnode, file);
                                                            })), Promise.resolve("Loaded")), (function (extra) {
                                                      return Mithril.setState(vnode, extra);
                                                    }));
                                      })
                                  }, []),
                              tmp
                            ],
                            style: style
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

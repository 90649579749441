// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as State__Memory from "../../state/State__Memory.js";

function setToast(vnode, appState, message) {
  setTimeout((function () {
          State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.removeToast(State__Memory.Transformations.make(appState))));
        }), 500);
  var newState_0 = message;
  var newState = {
    TAG: "Loaded",
    message: newState_0,
    visible: true,
    timerSet: false,
    lowerOpacity: false
  };
  Mithril.setState(vnode, newState);
}

function initialize(vnode) {
  var appState = State__Memory.Current.get();
  var text = appState.toast;
  if (text !== undefined) {
    return setToast(vnode, appState, text);
  } else {
    return Mithril.setState(vnode, "NoToast");
  }
}

function update(vnode) {
  var appState = State__Memory.Current.get();
  var match = appState.toast;
  var match$1 = vnode.state.data;
  if (match !== undefined) {
    if (typeof match$1 !== "object") {
      if (match$1 === "NotReady") {
        return ;
      } else {
        return setToast(vnode, appState, match);
      }
    } else if (!match$1.visible) {
      return setToast(vnode, appState, match);
    } else {
      return ;
    }
  }
  
}

function ToastNotification(props) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), initialize), update), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var lowerOpacity = match.lowerOpacity;
                var visible = match.visible;
                var message = match.message;
                var messageText = Core__Option.getOr(message, "");
                if (!match.timerSet) {
                  setTimeout((function () {
                          Mithril.setState(vnode, {
                                TAG: "Loaded",
                                message: message,
                                visible: true,
                                timerSet: true,
                                lowerOpacity: true
                              });
                        }), 1500);
                  setTimeout((function () {
                          Mithril.setState(vnode, {
                                TAG: "Loaded",
                                message: message,
                                visible: false,
                                timerSet: true,
                                lowerOpacity: true
                              });
                        }), 2100);
                  vnode.state.data = {
                    TAG: "Loaded",
                    message: message,
                    visible: visible,
                    timerSet: true,
                    lowerOpacity: lowerOpacity
                  };
                }
                return React.jsx(Conditional.make, {
                            cond: messageText !== "",
                            children: ReactDOM.jsx("div", {
                                  children: ReactDOM.jsx("div", {
                                        children: messageText,
                                        className: Mithril.classList([
                                              "tw-absolute tw-flex tw-flex-row tw-items-center tw-justify-center",
                                              "tw-top-0 tw-w-full tw-h-12 tw-text-white tw-bg-[#32B643] tw-z-1501"
                                            ], undefined)
                                      }),
                                  className: Mithril.classList([
                                        visible ? "tw-visible" : "tw-hidden",
                                        lowerOpacity ? "tw-opacity-0" : "tw-opacity-100",
                                        "tw-transition-opacity tw-duration-500"
                                      ], undefined),
                                  onClick: (function (_event) {
                                      var curState = vnode.state.data;
                                      if (typeof curState !== "object") {
                                        return ;
                                      } else {
                                        return Mithril.setState(vnode, {
                                                    TAG: "Loaded",
                                                    message: curState.message,
                                                    visible: false,
                                                    timerSet: curState.timerSet,
                                                    lowerOpacity: curState.lowerOpacity
                                                  });
                                      }
                                    })
                                })
                          });
              }));
}

var make = ToastNotification;

export {
  make ,
}
/* React Not a pure module */

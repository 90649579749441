// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../tree/Level.js";
import * as Query from "../Query.js";
import * as RRule from "../../libs/RRule.js";
import * as Answer from "../tree/Answer.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AnswerAPI from "../api/endpoints/Answers/AnswerAPI.js";
import * as AppSchema from "../AppSchema.js";
import * as LevelType from "../tree/LevelType.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__User from "./State__User.js";
import * as StepCounter from "../runtime/StepCounter.js";
import * as Browser from "@sentry/browser";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as State__Inspections from "./State__Inspections.js";
import * as LevelSubmissionManager from "../tree/LevelSubmissionManager.js";
import * as FutureAttachmentManager from "../attachment_chunks/FutureAttachmentManager.js";

var steps = {
  contents: 0
};

function count(info) {
  steps.contents = steps.contents + info.steps | 0;
}

function steps$1() {
  var res = steps.contents;
  steps.contents = 0;
  return res;
}

async function start(user) {
  try {
    await StepCounter.start(user);
    return ;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    Browser.captureException(e);
    return ;
  }
}

StepCounter.addListener(count);

var Steps = {
  steps: steps$1,
  start: start
};

async function _getUpdatedLevelsOnAnswerChanged(answer) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.levels = Level.branch(answer.level_path), newrecord));
  var levels = match.levels;
  var initial = AppSchema.Core.make();
  var initial$1 = AppSchema.Levels.updateMany(initial, levels);
  var levels$1 = levels.map(function (level) {
        var recurrence = level.recurrence;
        if (recurrence === undefined) {
          return level;
        }
        var recurrence$1 = RRule.toSet(Caml_option.valFromOption(recurrence));
        var now = new Date();
        var due_date = RRule.$$Set.after(recurrence$1, now, undefined);
        var overdue_date = RRule.$$Set.after(recurrence$1, Prelude.default(due_date, now), undefined);
        return {
                id: level.id,
                parent_id: level.parent_id,
                vessel_id: level.vessel_id,
                guidance_document_id: level.guidance_document_id,
                name: level.name,
                path: level.path,
                number: level.number,
                depth: level.depth,
                recurrence: level.recurrence,
                due_date: due_date,
                overdue_date: overdue_date,
                ignore_overdue_status: level.ignore_overdue_status,
                kind: level.kind,
                risk_status: level.risk_status
              };
      });
  var $$final = AppSchema.Core.make();
  var $$final$1 = AppSchema.Levels.updateMany($$final, levels$1);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
  newrecord$1.levels = AppSchema.Levels.getActions(initial$1, $$final$1);
  return newrecord$1;
}

async function make(vessel_id, level_id, level_path, question_id, question_type, value, remarks, evidences, picture_prompted) {
  var match = await State__Inspections.getCurrentInspector();
  var profile = await State__Inspections.getCurrentProfile();
  var profileId = profile !== undefined ? Caml_option.some(profile.id) : undefined;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.newAnswers = {
          TAG: "Limit",
          _0: 1,
          _1: "All"
        }, newrecord.levels = {
          TAG: "Get",
          _0: level_id
        }, newrecord));
  var isFirstAnswer = match$1.newAnswers.length === 0;
  if (isFirstAnswer) {
    await start(match.user);
  }
  var walked_steps = isFirstAnswer ? 0 : steps$1();
  var level = Prelude.default(Prelude.$$Array.first(match$1.levels), Level.Defaults.$$null);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$1.levelTypes = {
          TAG: "Get",
          _0: level.kind
        }, newrecord$1));
  var levelType = Prelude.default(Prelude.$$Array.first(match$2.levelTypes), LevelType.Defaults.$$null);
  var sire2_vetting_period_id;
  if (levelType.kind === "sire" || levelType.kind === "sire_chapter") {
    var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
    var match$3 = await Query.read((newrecord$2.sire2VettingPeriods = {
            TAG: "Limit",
            _0: 1,
            _1: {
              TAG: "Is",
              _0: "vessel_id",
              _1: vessel_id
            }
          }, newrecord$2));
    sire2_vetting_period_id = Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match$3.sire2VettingPeriods), (function (v) {
            return v.id;
          }));
  } else {
    sire2_vetting_period_id = undefined;
  }
  var answer = Answer.make(level_id, level_path, vessel_id, question_id, match.inspector_id, profileId, question_type, value, remarks, walked_steps, evidences, picture_prompted, sire2_vetting_period_id);
  var draftSubmissionId = Mithril.route.param("draftSubmissionId");
  LevelSubmissionManager.addAnswerToDraftSubmission(draftSubmissionId, level, answer);
  var actions = await _getUpdatedLevelsOnAnswerChanged(answer);
  var newrecord$3 = Caml_obj.obj_dup(actions);
  await Query.write((newrecord$3.newAnswers = [{
            TAG: "Save",
            _0: answer
          }], newrecord$3));
  var newrecord$4 = Caml_obj.obj_dup(Query.makeResponse());
  newrecord$4.newAnswers = [answer];
  return newrecord$4;
}

async function update(answer) {
  var match = await State__Inspections.getCurrentInspector();
  var profile = await State__Inspections.getCurrentProfile();
  var profileId = profile !== undefined ? Caml_option.some(profile.id) : undefined;
  var walked_steps = steps$1();
  var newrecord = Caml_obj.obj_dup(answer);
  newrecord.walked_steps = walked_steps;
  newrecord.profile_id = profileId;
  newrecord.user_id = match.inspector_id;
  var actions = await _getUpdatedLevelsOnAnswerChanged(newrecord);
  var newrecord$1 = Caml_obj.obj_dup(actions);
  return await Query.write((newrecord$1.newAnswers = [{
                  TAG: "Save",
                  _0: newrecord
                }], newrecord$1));
}

async function $$delete(answer) {
  var query = await _getUpdatedLevelsOnAnswerChanged(answer);
  var evidence_ids = answer.evidences.map(function (a) {
        return a.id;
      });
  await FutureAttachmentManager.$$delete(evidence_ids);
  var newrecord = Caml_obj.obj_dup(query);
  return await Query.write((newrecord.newAnswers = [{
                  TAG: "Delete",
                  _0: answer.id
                }], newrecord));
}

function upload(answers) {
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return AnswerAPI.post(__x, answers);
                }));
}

var User;

var getCurrentInspector = State__Inspections.getCurrentInspector;

var getCurrentProfile = State__Inspections.getCurrentProfile;

var LoggedUser;

export {
  User ,
  getCurrentInspector ,
  getCurrentProfile ,
  Steps ,
  _getUpdatedLevelsOnAnswerChanged ,
  make ,
  update ,
  $$delete ,
  upload ,
  LoggedUser ,
}
/*  Not a pure module */

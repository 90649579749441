// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

function toClassName(style) {
  if (style === "black") {
    return "tw-text-black";
  } else {
    return "tw-text-primary";
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var disabled = vnode.attrs.disabled;
                var customClass = vnode.attrs.className;
                var style = Core__Option.getOr(vnode.attrs.style, "primary");
                return ReactDOM.jsx("button", {
                            children: match.text,
                            className: Mithril.classList([
                                  toClassName(style),
                                  "tw-bg-transparent tw-border-0 tw-rounded-sm disabled:tw-opacity-50",
                                  "tw-inline-flex tw-items-center tw-justify-center tw-text-sm",
                                  "tw-appearance-none tw-h-7 tw-py-1 tw-px-4 tw-cursor-pointer",
                                  "tw-no-underline tw-outline-none tw-select-none",
                                  "tw-whitespace-nowrap tw-transition tw-duration-200"
                                ], customClass),
                            disabled: disabled,
                            onClick: match.onClick
                          });
              }));
}

var ButtonStyle = {};

export {
  ButtonStyle ,
  make ,
}
/* Mithril Not a pure module */

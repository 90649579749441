// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Completion from "../common/Completion.js";
import * as Inspection from "./Inspection.js";
import * as ComponentId from "../vessel/ComponentId.js";
import * as ProcedureId from "./ProcedureId.js";
import * as ProcedureImage from "./ProcedureImage.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function cmp(c1, c2) {
  return Caml_obj.compare(c1.id, c2.id);
}

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

var partial_arg = {};

var $$Set = Utils.MakeSetType(partial_arg, Comparator);

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofComponents(componentIds) {
  return {
          TAG: "AnyOf",
          _0: "component_id",
          _1: componentIds
        };
}

function ofInspection(inspection) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, inspection, (function (inspection) {
                    return {
                            TAG: "Get",
                            _0: inspection.procedure_id
                          };
                  })), "NoOp");
}

function byComponent(procedure) {
  return procedure.component_id;
}

function byName(procedure) {
  return procedure.name;
}

function byOrder(procedure) {
  return procedure.order;
}

function getRatingStyleRules(procedure) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, procedure, (function (p) {
                        return p.rating_styling_rules_id;
                      })), (function (id) {
                    return {
                            TAG: "Get",
                            _0: id
                          };
                  })), "NoOp");
}

function getManyRatingStyleRules(procedures) {
  var ids = Curry._2(Prelude.$$Array.keepMap, procedures, (function (p) {
          return p.rating_styling_rules_id;
        }));
  if (ids.length !== 0) {
    return {
            TAG: "In",
            _0: ids
          };
  } else {
    return "NoOp";
  }
}

function getImages(procedures) {
  return Prelude.$$Array.flatMap(procedures, (function (proc) {
                return Prelude.$$Array.keepSome([
                              proc.position_diagram,
                              proc.example_image
                            ]).map(function (img) {
                            return {
                                    TAG: "Cloud",
                                    _0: img
                                  };
                          });
              }));
}

function selectPositionDiagram(procedure, images) {
  return ProcedureImage.getSelector(procedure.position_diagram, images);
}

function selectExampleImage(procedure, images) {
  return ProcedureImage.getSelector(procedure.example_image, images);
}

function getCurrentCompletionStatus(procedure) {
  return Completion.Status.filterOverdueStatus(procedure.ignore_overdue_status, Completion.Status.fromCurrentDate(procedure.due_date, procedure.overdue_date));
}

function getCurrentDueText(procedure) {
  return Completion.Status.getDueTextFromDates(procedure.ignore_overdue_status, procedure.due_date, procedure.overdue_date);
}

function buildCompletionPredicate(status, procedure) {
  return getCurrentCompletionStatus(procedure) === status;
}

function isDue(extra) {
  return buildCompletionPredicate("due", extra);
}

function isFullyInspected(extra) {
  return buildCompletionPredicate("completed", extra);
}

function isOverdue(extra) {
  return buildCompletionPredicate("overdue", extra);
}

function areOverdue(procedures) {
  return Curry._2(Prelude.$$Array.some, procedures, isOverdue);
}

function filterByCompletion(procedures, filter) {
  return Curry._2(Prelude.$$Array.keep, procedures, (function (procedure) {
                return Completion.Filter.matches(filter, procedure.due_date, procedure.overdue_date);
              }));
}

function isInspectedOnTimeBy(procedure, inspection) {
  if (inspection !== undefined) {
    return Caml_obj.greaterequal(procedure.overdue_date, new Date());
  } else {
    return false;
  }
}

function lastInspection(procedure, inspections) {
  return Inspection.lastInspection(Curry._2(Prelude.$$Array.keep, inspections, (function (inspection) {
                    return Caml_obj.equal(inspection.procedure_id, procedure.id);
                  })));
}

function ids(__x) {
  return __x.map(function (procedure) {
              return procedure.id;
            });
}

function arePlanned(procedures, plannedProcedureById) {
  return Curry._2(Prelude.$$Array.some, procedures, (function (param) {
                return ProcedureId.MutableSet.has(plannedProcedureById, param.id);
              }));
}

function componentIds(procedures) {
  return ComponentId.MutableSet.fromArray(procedures.map(function (param) {
                  return param.component_id;
                }));
}

function getCompletion(procedures) {
  var fullyInspectedProcedures = Curry._2(Prelude.$$Array.keep, procedures, (function (procedure) {
          return isFullyInspected(procedure);
        }));
  return Completion.Information.make(procedures.length, fullyInspectedProcedures.length, Curry._2(Prelude.$$Array.some, procedures, isOverdue));
}

function getGreatestOverdueText(getName, procedures) {
  var getName$1 = Prelude.default(getName, (function (p) {
          return p.name;
        }));
  var dueItems = Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
            return p.not_applicable_status === undefined;
          })).map(function (p) {
        return {
                name: getName$1(p),
                ignore_overdue: p.ignore_overdue_status,
                overdue_date: p.overdue_date,
                due_date: p.due_date
              };
      });
  if (dueItems.length !== 0) {
    return Completion.StatusInformationItem.greatestOverdueText(dueItems);
  } else {
    return "Completed";
  }
}

function canMarkAsNotApplicable(procedure) {
  var component_type = procedure.extra.component_type;
  if (component_type === "checklist") {
    return procedure.allow_not_applicable_status;
  } else {
    return false;
  }
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  Comparator ,
  $$Set ,
  ofVessel ,
  ofComponents ,
  ofInspection ,
  byComponent ,
  byName ,
  byOrder ,
  getRatingStyleRules ,
  getManyRatingStyleRules ,
  getImages ,
  selectPositionDiagram ,
  selectExampleImage ,
  getCurrentCompletionStatus ,
  getCurrentDueText ,
  isDue ,
  isFullyInspected ,
  isOverdue ,
  areOverdue ,
  filterByCompletion ,
  isInspectedOnTimeBy ,
  lastInspection ,
  ids ,
  arePlanned ,
  componentIds ,
  getCompletion ,
  getGreatestOverdueText ,
  canMarkAsNotApplicable ,
}
/* include Not a pure module */

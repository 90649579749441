// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Hashtbl from "rescript/lib/es6/hashtbl.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ProfileId from "../../accounts/ProfileId.js";
import * as AddComment from "./AddComment.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as NewComment from "../NewComment.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as CommentDetails from "./CommentDetails.js";

function colorFromString(str) {
  var availableColors = [
    "darkblue",
    "mediumblue",
    "blue",
    "midnightblue",
    "navy",
    "darkslateblue",
    "slateblue",
    "mediumslateblue",
    "cornflowerblue",
    "royalblue",
    "dodgerblue",
    "darkslategray",
    "slategray",
    "rosybrown",
    "sandybrown",
    "goldenrod",
    "darkgoldenrod",
    "peru",
    "chocolate",
    "saddlebrown",
    "sienna",
    "brown"
  ];
  var index = Math.abs(Caml_int32.mod_(Hashtbl.hash(str), availableColors.length));
  return Prelude.default(Curry._2(Prelude.$$Array.get, availableColors, index), "initial");
}

function loadState(vnode) {
  var object_id = vnode.attrs.object_id;
  var comments = vnode.attrs.comments;
  var profileIds = Prelude.$$Array.keepSome(comments.map(function (c) {
            return c.profile_id;
          }));
  var match = State__Memory.Current.get();
  var profile = match.profile;
  var user = match.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.userProfiles = {
              TAG: "In",
              _0: profileIds
            }, newrecord.newComments = "All", newrecord)), (function (param) {
          var profileById = ProfileId.$$Map.fromArray(param.userProfiles.map(function (u) {
                    return [
                            u.id,
                            u
                          ];
                  }));
          Mithril.setState(vnode, {
                TAG: "Loaded",
                newComments: Curry._2(Prelude.$$Array.keep, param.newComments, (function (extra) {
                        return NewComment.belongingTo(object_id, extra);
                      })),
                currentUser: user,
                currentProfile: profile,
                profileById: profileById
              });
        }));
}

function onNewCommentSaved(vnode, newComment) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var profileById = match.profileById;
  if (newComment === undefined) {
    return ;
  }
  var newComments = Curry._2(Prelude.$$Array.concat, match.newComments, [newComment]);
  var profile = newComment.profile;
  if (profile !== undefined && !ProfileId.$$Map.has(profileById, profile.id)) {
    ProfileId.$$Map.set(profileById, profile.id, profile);
  }
  Mithril.setState(vnode, {
        TAG: "Loaded",
        newComments: newComments,
        currentUser: match.currentUser,
        currentProfile: match.currentProfile,
        profileById: profileById
      });
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadState), (function (vnode) {
                var vessel_id = vnode.attrs.vessel_id;
                var object_app = vnode.attrs.object_app;
                var object_type = vnode.attrs.object_type;
                var object_id = vnode.attrs.object_id;
                var comments = vnode.attrs.comments;
                var replies_to_id = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(comments), (function (c) {
                            return Caml_option.some(c.id);
                          })), undefined);
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var profileById = match.profileById;
                  tmp = ReactDOM.jsx("div", {
                        children: Curry._2(Prelude.$$Array.concat, comments.map(function (comment) {
                                  var profile_id = comment.profile_id;
                                  var author = profile_id !== undefined ? Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ProfileId.$$Map.get(profileById, Caml_option.valFromOption(profile_id)), (function (p) {
                                                return UserProfile.nameWithRank(p);
                                              })), comment.author) : comment.author;
                                  return React.jsxKeyed(CommentDetails.make, {
                                              author: author,
                                              color: colorFromString(author),
                                              body: comment.body,
                                              date: comment.timestamp,
                                              isNew: false,
                                              readers: comment.users_who_read_it
                                            }, comment.id, undefined);
                                }), match.newComments.map(function (comment) {
                                  var profile = comment.profile;
                                  return React.jsxKeyed(CommentDetails.make, {
                                              author: profile !== undefined ? UserProfile.nameWithRank(profile) : comment.author.first_name + " " + comment.author.last_name,
                                              color: "initial",
                                              body: comment.body,
                                              date: comment.timestamp,
                                              isNew: true
                                            }, comment.id, undefined);
                                })),
                        className: "p-0"
                      });
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("strong", {
                                    children: "Comments:"
                                  }),
                              tmp,
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  }),
                              React.jsx(AddComment.make, {
                                    vessel_id: vessel_id,
                                    replies_to_id: replies_to_id,
                                    object_app: object_app,
                                    object_type: object_type,
                                    object_id: object_id,
                                    onSave: (function (extra) {
                                        return onNewCommentSaved(vnode, extra);
                                      })
                                  })
                            ]
                          });
              }));
}

var LoggedUser;

export {
  LoggedUser ,
  colorFromString ,
  loadState ,
  onNewCommentSaved ,
  make ,
}
/* Query Not a pure module */

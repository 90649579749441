// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Browser from "@sentry/browser";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SimpleSerializer from "../common/SimpleSerializer.js";

function convert(comment) {
  return {
          id: comment.id,
          reader: comment.reader
        };
}

var Serializer = SimpleSerializer.MakeSimpleSerializer({
      convert: convert
    });

var fields = [[
    "id",
    "String"
  ]];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/comments/update_reader"
    });

var InvalidLocalCommentData = /* @__PURE__ */Caml_exceptions.create("UpdatedCommentAPI.InvalidLocalCommentData");

function post(user, comments) {
  var comments$1 = Prelude.ManyResults.bail(comments.map(Serializer.serialize));
  if (comments$1.TAG === "Ok") {
    return Impl.postMany(undefined, user, comments$1._0);
  }
  var error = comments$1._0;
  Browser.captureException(error);
  return Prelude.rejectWithError({
              RE_EXN_ID: InvalidLocalCommentData,
              code: 400,
              response: {
                value: Prelude.default(JSON.stringify(error), "Could not serialize")
              },
              message: Prelude.default(JSON.stringify(error), "Could not serialize")
            });
}

export {
  Serializer ,
  Impl ,
  InvalidLocalCommentData ,
  post ,
}
/* Serializer Not a pure module */

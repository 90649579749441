// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as QuestionWidget from "./QuestionWidget.js";

function formatText(texts, index) {
  var value = Curry._2(Prelude.$$Array.get, texts, index);
  if (value === undefined) {
    return "";
  }
  var value$1 = Caml_option.valFromOption(value);
  if (value$1 !== undefined) {
    return value$1;
  } else {
    return "";
  }
}

function MultipleTextsQuestionWidget$Editor(props) {
  return Mithril.view(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var texts = match.texts;
                  var question = match.question;
                  var match$1 = question.kind;
                  tmp = typeof match$1 === "object" && match$1.NAME === "MultipleTextsQuestion" ? ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("div", {
                                  children: question.name,
                                  className: "tile-content text-center text-small"
                                }),
                            ReactDOM.jsx("br", {}),
                            match$1.VAL.labels.map(function (label, index) {
                                  var value = formatText(texts, index);
                                  return ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("br", {}),
                                                ReactDOM.jsx("label", {
                                                      children: label,
                                                      className: "form-label",
                                                      htmlFor: question.id + String(index)
                                                    }),
                                                ReactDOM.jsx("input", {
                                                      className: "form-input",
                                                      id: question.id + String(index),
                                                      type: "text",
                                                      value: value,
                                                      onKeyUp: (function (extra) {
                                                          var match = vnode.state.data;
                                                          if (typeof match !== "object") {
                                                            return ;
                                                          }
                                                          var question = match.question;
                                                          var match$1 = question.kind;
                                                          return Mithril.setState(vnode, {
                                                                      TAG: "Loaded",
                                                                      lastAnswer: match.lastAnswer,
                                                                      lastNewAnswer: match.lastNewAnswer,
                                                                      question: question,
                                                                      level: match.level,
                                                                      texts: typeof match$1 === "object" ? (
                                                                          match$1.NAME === "MultipleTextsQuestion" ? match$1.VAL.labels.map(function (param, index) {
                                                                                  var val = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[id='" + question.id + String(index) + "']"));
                                                                                  if (Caml_obj.notequal(val, "")) {
                                                                                    return val;
                                                                                  }
                                                                                  
                                                                                }) : []
                                                                        ) : []
                                                                    });
                                                        })
                                                    })
                                              ]
                                            });
                                })
                          ],
                          className: "form-group"
                        }) : Mithril.empty;
                }
                return React.jsx(QuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return {
                                          attachments: []
                                        };
                                } else {
                                  return {
                                          value: {
                                            NAME: "Value",
                                            VAL: {
                                              NAME: "MultipleTexts",
                                              VAL: match.texts
                                            }
                                          },
                                          attachments: []
                                        };
                                }
                              }),
                            allowSave: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var match$1 = match.question.kind;
                                if (typeof match$1 === "object" && match$1.NAME === "MultipleTextsQuestion") {
                                  return match$1.VAL.labels.length === Prelude.$$Array.keepSome(match.texts).length;
                                } else {
                                  return false;
                                }
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var texts = match.texts;
                                var lastNewAnswer = match.lastNewAnswer;
                                if (createNew) {
                                  return Curry._2(Prelude.$$Array.some, texts, Prelude.OptionExported.$$Option.isSome);
                                }
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 === "object" && match$2.NAME === "MultipleTexts") {
                                  return Caml_obj.notequal(match$2.VAL, texts);
                                } else {
                                  return false;
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var newState;
                                if (typeof extra !== "object") {
                                  newState = "NotReady";
                                } else {
                                  var lastNewAnswer = extra.lastNewAnswer;
                                  var match = vnode.state.data;
                                  var tmp;
                                  if (typeof match !== "object") {
                                    if (createNew) {
                                      tmp = [];
                                    } else if (lastNewAnswer !== undefined) {
                                      var match$1 = lastNewAnswer.value;
                                      if (match$1 !== undefined) {
                                        if (typeof match$1 === "object") {
                                          if (match$1.NAME === "Value") {
                                            var match$2 = match$1.VAL;
                                            tmp = typeof match$2 === "object" ? (
                                                match$2.NAME === "MultipleTexts" ? match$2.VAL : []
                                              ) : [];
                                          } else {
                                            tmp = [];
                                          }
                                        } else {
                                          tmp = [];
                                        }
                                      } else {
                                        tmp = [];
                                      }
                                    } else {
                                      tmp = [];
                                    }
                                  } else {
                                    tmp = match.texts;
                                  }
                                  newState = {
                                    TAG: "Loaded",
                                    lastAnswer: extra.lastAnswer,
                                    lastNewAnswer: lastNewAnswer,
                                    question: extra.question,
                                    level: extra.level,
                                    texts: tmp
                                  };
                                }
                                return Mithril.setState(vnode, newState);
                              }),
                            children: tmp
                          });
              }));
}

function MultipleTextsQuestionWidget(props) {
  var draftSubmission = props.draftSubmission;
  var lastAnswer = props.lastAnswer;
  var lastNewAnswer = props.lastNewAnswer;
  var vessel = props.vessel;
  var level = props.level;
  var question = props.question;
  return Mithril.view(Mithril.component(), (function (vnode) {
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                var submissionId = Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                        return s.id;
                      }));
                var editUrl = function (none, extra, extra$1, extra$2, extra$3) {
                  return URLs.multipleTextsQuestionWidgetEditor(none, Prelude.default(Mithril__Route.paramBool("isRectification"), false), extra, extra$1, extra$2, extra$3);
                };
                return React.jsx(QuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            draftSubmission: draftSubmission,
                            editUrl: editUrl,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "type"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active ",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isNone(submissionId)
                                        ],
                                        [
                                          "bg-success",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isSome(submissionId)
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return QuestionWidget.startInspection(vnode, editUrl, extra);
                                    })
                                })
                          });
              }));
}

var Editor = {
  make: MultipleTextsQuestionWidget$Editor
};

var make = MultipleTextsQuestionWidget;

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as RRule from "../../libs/RRule.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as VesselId from "../vessel/VesselId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Conditions from "../common/Conditions.js";
import * as QuestionId from "./QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MarkdownDocumentId from "../markdown/MarkdownDocumentId.js";

var OkNotOkType = {};

var MultipleSingleType = {};

var MultipleDatesYesNo = {};

var MultipleDatesIntegers = {};

var MultipleDatesTexts = {};

var Quiz = {};

var Rating = {};

var WeightInfo = {};

var YesNoType = {};

var _map = {"AllAnswers":"all_answers","OnlyRankAnswers":"only_rank_answers","OnlyUserAnswers":"only_user_answers","NoAnswers":"no_answers"};

var _revMap = {"all_answers":"AllAnswers","only_rank_answers":"OnlyRankAnswers","only_user_answers":"OnlyUserAnswers","no_answers":"NoAnswers"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

var deserFromJS = $$JSON.Field.variadicString("Expected an answer visibility", (function (x) {
        return _revMap[x];
      }));

var AnswerVisibility = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  toJs: tToJs,
  fromJs: tFromJs,
  deserFromJS: deserFromJS
};

var _map$1 = {"Human":"HUMAN","Process":"PROCESS","Hardware":"HARDWARE"};

var _revMap$1 = {"HUMAN":"Human","PROCESS":"Process","HARDWARE":"Hardware"};

function tToJs$1(param) {
  return _map$1[param];
}

function tFromJs$1(param) {
  return _revMap$1[param];
}

var deserFromJS$1 = $$JSON.Field.variadicString("Expected a question area", (function (x) {
        return _revMap$1[x];
      }));

function displayName(v) {
  if (v === "Hardware") {
    return "Hardware";
  } else if (v === "Process") {
    return "Process";
  } else {
    return "Human";
  }
}

var QuestionArea = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1,
  toJs: tToJs$1,
  fromJs: tFromJs$1,
  deserFromJS: deserFromJS$1,
  displayName: displayName
};

var _map$2 = {"Answered":"Answered","Unanswered":"Unanswered"};

var _revMap$2 = {"Answered":"Answered","Unanswered":"Unanswered"};

function tToJs$2(param) {
  return _map$2[param];
}

function tFromJs$2(param) {
  return _revMap$2[param];
}

var deserFromJS$2 = $$JSON.Field.variadicString("Expected ", (function (x) {
        return _revMap$2[x];
      }));

function displayName$1(v) {
  if (v === "Answered") {
    return "Answered";
  } else {
    return "Unanswered";
  }
}

var Answered = {
  tToJs: tToJs$2,
  tFromJs: tFromJs$2,
  toJs: tToJs$2,
  fromJs: tFromJs$2,
  deserFromJS: deserFromJS$2,
  displayName: displayName$1
};

var Kind = {};

var fields = {
  TAG: "Object",
  _0: [
    [
      "level_id",
      "String"
    ],
    [
      "level_path",
      "String"
    ],
    [
      "order",
      "Int"
    ],
    [
      "answers_source",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "due_date",
      {
        TAG: "Optional",
        _0: "Date"
      }
    ],
    [
      "overdue_date",
      {
        TAG: "Optional",
        _0: "Date"
      }
    ],
    [
      "recurrence",
      {
        TAG: "Optional",
        _0: {
          TAG: "Deserializer",
          _0: {
            name: RRule.Deserializer.name,
            fromJSON: RRule.Deserializer.fromJSON,
            checkFieldsSanity: RRule.Deserializer.checkFieldsSanity
          }
        }
      }
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var LevelLink = {
  Deserializer: Deserializer
};

var Definition = {};

var null_levels = [];

var null_level_ids = [];

var null_level_paths = [];

var null_guidance_document_id = Caml_option.some(MarkdownDocumentId.$$null);

var null_kind = {
  NAME: "YesNoQuestion",
  VAL: {
    correct_answer: true
  }
};

var null_require_image_conditions = Caml_option.some(Conditions.Defaults.never);

var null_ranks = [];

var $$null = {
  id: QuestionId.$$null,
  levels: null_levels,
  level_ids: null_level_ids,
  level_paths: null_level_paths,
  vessel_id: VesselId.$$null,
  guidance_document_id: null_guidance_document_id,
  name: "",
  kind: null_kind,
  require_image_conditions: null_require_image_conditions,
  require_landscape_pictures: false,
  answer_visibility: "AllAnswers",
  area: undefined,
  ranks: null_ranks
};

var Defaults = {
  $$null: $$null
};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofLevel(levelId) {
  return {
          TAG: "Is",
          _0: "level_ids",
          _1: levelId
        };
}

function insideLevel(path) {
  return {
          TAG: "StartsWith",
          _0: "level_paths",
          _1: path
        };
}

function ids(__x) {
  return __x.map(function (question) {
              return question.id;
            });
}

function visibleForRank(q, rank) {
  if (Prelude.$$Array.isEmpty(q.ranks)) {
    return true;
  } else {
    return Prelude.$$Array.includes(q.ranks, rank);
  }
}

function visibleForProfile(q, profile) {
  return visibleForRank(q, profile.rank);
}

function filterByQuestionRank(q, p) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, p, (function (extra) {
                    return visibleForProfile(q, extra);
                  })), true);
}

function isVisibleForRanks(q, ranks) {
  if (ranks !== undefined && ranks.length !== 0) {
    return Js_array.some((function (x) {
                  return x;
                }), ranks.map(function (r) {
                    return visibleForRank(q, r);
                  }));
  } else {
    return true;
  }
}

function filterByQuestionArea(question, areasFilter) {
  if (areasFilter !== undefined) {
    if (areasFilter.length !== 0) {
      if (Curry._1(Prelude.OptionExported.$$Option.isSome, question.area)) {
        return Prelude.$$Array.includes(areasFilter, Curry._1(Prelude.OptionExported.$$Option.getExn, question.area));
      } else {
        return false;
      }
    } else if (Caml_obj.equal(Mithril.route.param("areas"), "")) {
      return true;
    } else {
      return Curry._1(Prelude.OptionExported.$$Option.isNone, Mithril.route.param("areas"));
    }
  } else {
    return true;
  }
}

function filterByAnswered(question, answeredFilter, answeredQuestionIds) {
  if (answeredFilter !== undefined) {
    if (answeredFilter.length !== 0) {
      if (Caml_obj.equal(answeredFilter, ["Answered"])) {
        return Prelude.$$Array.includes(answeredQuestionIds, question.id);
      } else if (Caml_obj.equal(answeredFilter, ["Unanswered"])) {
        return !Prelude.$$Array.includes(answeredQuestionIds, question.id);
      } else {
        return true;
      }
    } else if (Caml_obj.equal(Mithril.route.param("answered"), "")) {
      return true;
    } else {
      return Curry._1(Prelude.OptionExported.$$Option.isNone, Mithril.route.param("answered"));
    }
  } else {
    return true;
  }
}

var Identifier;

var MultipleDates;

var MultipleIntegers;

var MultipleTexts;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  OkNotOkType ,
  MultipleSingleType ,
  MultipleDates ,
  MultipleIntegers ,
  MultipleTexts ,
  MultipleDatesYesNo ,
  MultipleDatesIntegers ,
  MultipleDatesTexts ,
  Quiz ,
  Rating ,
  WeightInfo ,
  YesNoType ,
  AnswerVisibility ,
  QuestionArea ,
  Answered ,
  Kind ,
  LevelLink ,
  Definition ,
  Defaults ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  ofLevel ,
  insideLevel ,
  ids ,
  visibleForRank ,
  visibleForProfile ,
  filterByQuestionRank ,
  isVisibleForRanks ,
  filterByQuestionArea ,
  filterByAnswered ,
}
/* deserFromJS Not a pure module */

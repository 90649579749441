// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function $$process(submission) {
  return {
          id: submission.id,
          name: submission.target_date.toUTCString(),
          vessel_id: submission.vessel_id,
          form_id: submission.form_id,
          timestamp: submission.timestamp,
          target_date: submission.target_date,
          inspections: submission.inspections
        };
}

function message(param, param$1) {
  return "Adding name to uploaded form submissions";
}

var SubmissionsRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "companyFormSubmissions",
      message: message,
      $$process: $$process
    });

function message$1(param, param$1) {
  return "Adding name to new form submissions";
}

var NewSubmissionsRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "newCompanyFormSubmissions",
      message: message$1,
      $$process: $$process
    });

function message$2(param) {
  return "Adding name field for form submissions";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.map(Prelude.PromisedResult.bail([
                  (function () {
                      return SubmissionsRewriter.rewrite(transaction);
                    }),
                  (function () {
                      return NewSubmissionsRewriter.rewrite(transaction);
                    })
                ]), (function (param) {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$2,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* SubmissionsRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as R4SChecklist from "../../risk4sea/R4SChecklist.js";
import * as ReviewUpload__AnswerCard from "./ReviewUpload__AnswerCard.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var checklists = vnode.attrs.checklists;
                var answers = vnode.attrs.answers;
                var levels = vnode.attrs.levels;
                var questions = vnode.attrs.questions;
                var userProfiles = vnode.attrs.userProfiles;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("h3", {
                                    children: "Port checklist",
                                    className: "mt-3 text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: checklists.map(function (checklist) {
                                          return ReactDOM.jsxs("div", {
                                                      children: [
                                                        ReactDOM.jsx("input", {
                                                              hidden: true,
                                                              id: checklist.level_id,
                                                              name: "accordion-checkbox",
                                                              type: "checkbox"
                                                            }),
                                                        ReactDOM.jsxs("label", {
                                                              children: [
                                                                React.jsx(Feather.make, {
                                                                      icon: "chevron_right",
                                                                      className: "icon",
                                                                      style: "top: 0.25rem; position: relative; float: right;",
                                                                      size: 24
                                                                    }),
                                                                R4SChecklist.getName(checklist)
                                                              ],
                                                              className: "accordion-header text-primary h5",
                                                              htmlFor: checklist.level_id
                                                            }),
                                                        ReactDOM.jsx("div", {
                                                              children: checklist.items.map(function (item) {
                                                                    var itemAnswers = Curry._2(Prelude.$$Array.keep, answers, (function (a) {
                                                                            return Caml_obj.equal(a.level_id, item.level_id);
                                                                          }));
                                                                    if (Prelude.$$Array.isEmpty(itemAnswers)) {
                                                                      return Mithril.empty;
                                                                    } else {
                                                                      return ReactDOM.jsxs("div", {
                                                                                  children: [
                                                                                    ReactDOM.jsx("input", {
                                                                                          hidden: true,
                                                                                          id: item.level_id,
                                                                                          name: "accordion-checkbox",
                                                                                          type: "checkbox"
                                                                                        }),
                                                                                    ReactDOM.jsxs("label", {
                                                                                          children: [
                                                                                            React.jsx(Feather.make, {
                                                                                                  icon: "chevron_right",
                                                                                                  className: "icon",
                                                                                                  style: "top: 0.25rem; position: relative; float: right; margin-right: 0.2rem;",
                                                                                                  size: 18
                                                                                                }),
                                                                                            item.order + " " + item.description
                                                                                          ],
                                                                                          className: "accordion-header text-primary h6",
                                                                                          htmlFor: item.level_id
                                                                                        }),
                                                                                    ReactDOM.jsx("div", {
                                                                                          children: itemAnswers.map(function (answer) {
                                                                                                var level = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                                                                                            return Caml_obj.equal(l.id, item.level_id);
                                                                                                          })));
                                                                                                var question = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                                                                                                            return Caml_obj.equal(q.id, answer.question_id);
                                                                                                          })));
                                                                                                var answerProfile = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, userProfiles, (function (u) {
                                                                                                            return Caml_obj.equal(Caml_option.some(u.id), answer.profile_id);
                                                                                                          })));
                                                                                                return React.jsx(ReviewUpload__AnswerCard.make, {
                                                                                                            answer: answer,
                                                                                                            level: level,
                                                                                                            question: question,
                                                                                                            answerProfile: answerProfile,
                                                                                                            allowResolve: false,
                                                                                                            allowEdit: true,
                                                                                                            vesselId: checklist.vessel_id
                                                                                                          });
                                                                                              }),
                                                                                          className: "accordion-body"
                                                                                        })
                                                                                  ],
                                                                                  className: "accordion"
                                                                                });
                                                                    }
                                                                  }),
                                                              className: "accordion-body"
                                                            })
                                                      ],
                                                      className: "accordion"
                                                    });
                                        }),
                                    className: "ratingState tw-shadow-kaiko-card tw-w-[95vw] tw-p-2"
                                  })
                            ]
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

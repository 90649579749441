// Generated by ReScript, PLEASE EDIT WITH CARE


function areWeOffline(error) {
  var val;
  try {
    val = error.code;
  }
  catch (exn){
    return true;
  }
  if (val !== undefined) {
    return val === 0;
  } else {
    return true;
  }
}

function isServerError(error) {
  var code;
  try {
    code = error.code;
  }
  catch (exn){
    return false;
  }
  if (code !== undefined) {
    return code >= 500;
  } else {
    return false;
  }
}

function isBadRequest(error) {
  var val;
  try {
    val = error.code;
  }
  catch (exn){
    return false;
  }
  if (val !== undefined) {
    if (val !== 400) {
      return val === 422;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function reason(error) {
  if (areWeOffline(error)) {
    return "Offline";
  } else if (isServerError(error)) {
    return {
            TAG: "ServerError",
            _0: error.response
          };
  } else if (isBadRequest(error)) {
    return {
            TAG: "BadRequest",
            _0: error.response
          };
  } else {
    return "Unknown";
  }
}

var GenericError = {
  areWeOffline: areWeOffline,
  isServerError: isServerError,
  isBadRequest: isBadRequest,
  reason: reason
};

export {
  GenericError ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as IDB__Request from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/IDB__Request.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function make(param) {
  return function (db, param) {
    var store = IDB__Migration__Database.createStandardStore(db, "levelTypes", [
          "id",
          "path",
          "depth",
          "kind"
        ]);
    return Prelude.thenResolve(IDB__Request.promise(store.add({
                        id: "018722a3-1b59-7ea0-af21-21b969e170fa",
                        name: "SIRE Chapter",
                        code: "sire",
                        kind: "sire_chapter",
                        depth: 1,
                        path: "0001"
                      })), (function (res) {
                  if (res !== undefined) {
                    return {
                            TAG: "Ok",
                            _0: undefined
                          };
                  } else {
                    return {
                            TAG: "Error",
                            _0: "Could not insert the initial level type record"
                          };
                  }
                }));
  };
}

export {
  make ,
}
/* IDB__Migration__Database Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TakePicture from "../views/TakePicture.js";
import * as ButtonAction from "./ButtonAction.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as ModalTemplateSimple from "./ModalTemplateSimple.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var hasImage = Core__Option.getOr(vnode.attrs.hasImage, true);
                return React.jsx(ModalTemplateSimple.make, {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("label", {
                                          children: hasImage ? "Edit photo" : "Add photo",
                                          className: "tw-text-[x-large] tw-font-medium"
                                        }),
                                    React.jsxs(ButtonAction.make, {
                                          children: [
                                            React.jsx(Feather.make, {
                                                  icon: "image"
                                                }),
                                            "Use rank insignia"
                                          ],
                                          onClick: match.setRankImage
                                        }),
                                    React.jsxs(TakePicture.make, {
                                          role: "AVATAR",
                                          requireLandscape: false,
                                          buttonStyle: "primary",
                                          children: [
                                            React.jsx(Feather.make, {
                                                  icon: "camera"
                                                }),
                                            "Take a photo"
                                          ],
                                          onCapture: match.setImage,
                                          onCameraFailure: (function (x) {
                                              console.warn("TakePictureModal", x);
                                            }),
                                          useEmbeddedCamera: "CONDITIONAL",
                                          resolution: "SD"
                                        })
                                  ],
                                  className: "tw-flex tw-flex-col tw-gap-3"
                                }),
                            onClose: match.onClose,
                            showModal: match.showModal
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

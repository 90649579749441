// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mutex from "../../libs/Mutex.js";
import * as React from "../../libs/rescript-mithril/src/React.js";
import * as State from "../state/State.js";
import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../libs/rescript-mithril/src/ReactDOM.js";
import * as ListLayout from "../common/views/ListLayout.js";
import * as UploadTips from "./views/UploadTips.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as StepCounter from "../runtime/StepCounter.js";
import * as UploadStages from "./upload/UploadStages.js";
import * as UploadStatus from "./upload/UploadStatus.js";
import * as State__Memory from "../state/State__Memory.js";
import * as UploadPrepare from "./upload/UploadPrepare.js";
import * as UploadSummary from "./views/UploadSummary.js";
import * as State__Amounts from "../state/State__Amounts.js";
import * as UploadProgress from "./views/UploadProgress.js";
import * as UploadStages__Base from "./upload/stages/UploadStages__Base.js";
import * as ReviewPendingUpload from "./views/ReviewPendingUpload.js";

async function action(arg) {
  var vnode = arg[0];
  var match = vnode.state.data;
  if (typeof match !== "object" || match.TAG !== "Prepare") {
    return ;
  } else {
    return Mithril.setState(vnode, {
                TAG: "Prepare",
                message: arg[1],
                progress: arg[2],
                max: arg[3]
              });
  }
}

var ProgressUpdaterThrottle = Mutex.MakeThrottle({
      rate: {
        NAME: "Milliseconds",
        VAL: 500
      },
      name: "Progress Report throttle",
      action: action
    });

function describeState(vnode) {
  var requests = vnode.state.data;
  if (typeof requests !== "object") {
    return "Cancelled";
  }
  switch (requests.TAG) {
    case "Prepare" :
        return "Prepare";
    case "Previewing" :
        return "Previewing(" + String(requests._0.length) + ")";
    case "Progressing" :
        return "Progressing(" + UploadStages.describe(requests._0) + ")";
    case "Synchronizing" :
        return "Synchronizing";
    case "Completed" :
        return "Completed";
    
  }
}

function next(vnode) {
  console.log("Upload", "Next", {
        state: describeState(vnode)
      });
  var stage = vnode.state.data;
  if (typeof stage !== "object") {
    return ;
  }
  switch (stage.TAG) {
    case "Prepare" :
        StepCounter.stop();
        var vessel = State__Memory.Current.get().vessel;
        if (vessel !== undefined) {
          return Prelude.thenDo(Prelude.then(UploadPrepare.next(vessel.id, (function (message, progress, max) {
                                ProgressUpdaterThrottle.$$do([
                                      vnode,
                                      message,
                                      progress,
                                      max
                                    ]);
                              })), (async function (requests) {
                            await State__Amounts.refresh();
                            return requests;
                          })), (function (requests) {
                        setStateAndProceed(vnode, {
                              TAG: "Previewing",
                              _0: requests
                            });
                      }));
        } else {
          Mithril$1.route.set("/", {
                replace: false
              });
          return ;
        }
    case "Progressing" :
        var results = stage._0;
        if (results.TAG !== "Done") {
          return Prelude.thenDo(UploadStages.next(results), (function (stage) {
                        setStateAndProceed(vnode, {
                              TAG: "Progressing",
                              _0: stage
                            });
                      }));
        }
        var results$1 = results._0;
        if (UploadSummary.succeeded(UploadSummary.summarize(results$1))) {
          return setStateAndProceed(vnode, {
                      TAG: "Synchronizing",
                      _0: results$1
                    });
        } else {
          return setStateAndProceed(vnode, {
                      TAG: "Completed",
                      _0: results$1
                    });
        }
    case "Synchronizing" :
        var results$2 = stage._0;
        return Prelude.thenDo(State.FetchVessels.$$fetch(UploadStatus.getVesselIds(results$2)), (function (param) {
                      setStateAndProceed(vnode, {
                            TAG: "Completed",
                            _0: results$2
                          });
                    }));
    case "Previewing" :
    case "Completed" :
        return ;
    
  }
}

function setStateAndProceed(vnode, state) {
  Mithril.setState(vnode, state);
  next(vnode);
}

function title(state) {
  if (typeof state !== "object") {
    return "";
  }
  switch (state.TAG) {
    case "Prepare" :
        return "Preparing upload items";
    case "Previewing" :
        return "Upload";
    case "Progressing" :
        return UploadStages__Base.title(state._0);
    default:
      return "";
  }
}

function bottomStatus(state) {
  if (typeof state !== "object") {
    return "Hide";
  }
  switch (state.TAG) {
    case "Prepare" :
    case "Synchronizing" :
        return "ShowDisabled";
    case "Completed" :
        return "ShowEnabled";
    default:
      return "Hide";
  }
}

function navbar(state) {
  if (typeof state !== "object") {
    return Mithril.empty;
  }
  switch (state.TAG) {
    case "Synchronizing" :
        if (UploadSummary.succeeded(UploadSummary.summarize(state._0))) {
          return ReactDOM.jsx("button", {
                      children: "Continue",
                      className: "btn btn-lg",
                      style: {
                        background: "#abaaec",
                        color: "#ececec"
                      }
                    });
        } else {
          return Mithril.empty;
        }
    case "Completed" :
        var results = state._0;
        var success = UploadSummary.succeeded(UploadSummary.summarize(results));
        var vessels = UploadStatus.getVesselIds(results);
        if (!success) {
          return Mithril.empty;
        }
        if (vessels.length !== 1) {
          return React.jsx(Mithril.Link.make, {
                      href: "/home?nosync=1",
                      className: "btn btn-primary btn-lg",
                      children: "Continue"
                    });
        }
        var vessel = vessels[0];
        return React.jsx(Mithril.Link.make, {
                    className: "btn btn-primary btn-lg",
                    children: "Continue",
                    onClick: (function (extra) {
                        var url = "/vessel/" + vessel + "?nosync=1";
                        window.stop();
                        window.location.assign(url);
                      })
                  });
    default:
      return Mithril.empty;
  }
}

function make(_vnode) {
  return Mithril.view(Mithril.onremove(Mithril.oninit(Mithril.component({
                          TAG: "Prepare",
                          message: "Initializing",
                          progress: 0,
                          max: 0
                        }), next), (function (vnode) {
                    vnode.state.data = "Cancelled";
                  })), (function (vnode) {
                var state = vnode.state.data;
                var bottomTaskBarStatus = bottomStatus(state);
                var overNavbar = navbar(state);
                var title$1 = title(state);
                var exit = 0;
                if (typeof state !== "object") {
                  exit = 1;
                } else {
                  switch (state.TAG) {
                    case "Prepare" :
                        var max = state.max;
                        return React.jsx(ListLayout.make, {
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("h4", {
                                                  children: title$1,
                                                  className: "text-center"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  className: "divider blank xl "
                                                }),
                                            ReactDOM.jsx("p", {
                                                  children: state.message,
                                                  className: "text-center"
                                                }),
                                            max > 0 ? ReactDOM.jsx("progress", {
                                                    style: {
                                                      display: "block",
                                                      margin: "auto",
                                                      width: "80%"
                                                    },
                                                    max: String(max),
                                                    value: String(state.progress)
                                                  }) : Mithril.empty
                                          ],
                                          style: {
                                            marginTop: "20%"
                                          }
                                        }),
                                    overNavbar: Caml_option.some(overNavbar),
                                    bottomTaskBarStatus: bottomTaskBarStatus
                                  });
                    case "Previewing" :
                        var requests = state._0;
                        return React.jsx(ReviewPendingUpload.make, {
                                    requests: requests,
                                    onConfirmUpload: (function () {
                                        setStateAndProceed(vnode, {
                                              TAG: "Progressing",
                                              _0: {
                                                TAG: "Requested",
                                                _0: requests
                                              }
                                            });
                                      })
                                  });
                    default:
                      exit = 1;
                  }
                }
                if (exit === 1) {
                  var tmp;
                  var exit$1 = 0;
                  if (typeof state !== "object") {
                    tmp = Mithril.empty;
                  } else {
                    switch (state.TAG) {
                      case "Progressing" :
                          tmp = ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  React.jsx(UploadTips.make, {}),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  React.jsx(UploadProgress.make, {
                                        stage: state._0
                                      })
                                ]
                              });
                          break;
                      case "Synchronizing" :
                      case "Completed" :
                          exit$1 = 2;
                          break;
                      default:
                        tmp = Mithril.empty;
                    }
                  }
                  if (exit$1 === 2) {
                    tmp = React.jsx(UploadSummary.make, {
                          results: state._0,
                          onClickRetry: (function () {
                              setStateAndProceed(vnode, {
                                    TAG: "Prepare",
                                    message: "Initializing",
                                    progress: 0,
                                    max: 0
                                  });
                            })
                        });
                  }
                  return React.jsx(ListLayout.make, {
                              children: ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h4", {
                                            children: title$1,
                                            className: "text-center"
                                          }),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank xl "
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: tmp
                                          })
                                    ],
                                    style: {
                                      marginTop: "20%"
                                    }
                                  }),
                              overNavbar: Caml_option.some(overNavbar),
                              bottomTaskBarStatus: bottomTaskBarStatus
                            });
                }
                
              }));
}

export {
  make ,
}
/* ProgressUpdaterThrottle Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var forms = vnode.attrs.forms;
                if (forms.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("h5", {
                                      children: "Incomplete",
                                      className: "text-error mb-0"
                                    }),
                                ReactDOM.jsx("ul", {
                                      children: forms.map(function (form) {
                                            return ReactDOM.jsxKeyed("li", {
                                                        children: React.jsx(KeepScrollLink.make, {
                                                              href: URLs.companyFormSubmissions(form.id),
                                                              className: "text-primary",
                                                              children: Caml_option.some(form.name)
                                                            }),
                                                        className: "pt-2"
                                                      }, form.id, undefined);
                                          })
                                    })
                              ],
                              className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-mb-2"
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

export {
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UploadPayload from "../UploadPayload.js";
import * as Browser from "@sentry/browser";
import * as UploadActionable from "../UploadActionable.js";

async function next(started, uploaded, size, actionables, results) {
  if (actionables.length === 0) {
    return {
            TAG: "Finalizing",
            _0: size,
            _1: Curry._1(Prelude.$$Array.reverse, results)
          };
  }
  var actionable = actionables[0];
  var actionables$1 = Curry._2(Prelude.$$Array.sliceToEnd, actionables, 1);
  var status = await UploadActionable.execute(actionable);
  if (status.TAG === "Ok") {
    return {
            TAG: "Uploading",
            started: started,
            uploaded: uploaded + UploadActionable.size(actionable),
            size: size,
            actionables: actionables$1,
            results: Prelude.$$Array.append(results, status)
          };
  }
  var error = status._0[1];
  var report = function (level) {
    var payloads = UploadActionable.getPayloads(actionable);
    var vessels = UploadPayload.getVessels(payloads);
    Browser.captureEvent({
          level: level,
          message: "Upload action error in actionable: " + UploadActionable.repr(actionable),
          logger: "UploadStages__Uploading",
          fingerprint: [
            "UploadActionableError",
            UploadActionable.describe(actionable),
            level
          ],
          exception: {
            values: [{
                type: "UploadActionableError (" + UploadActionable.describe(actionable) + ")",
                module: "UploadStages__Uploading"
              }]
          },
          extra: {
            actionable: UploadActionable.repr(actionable),
            vessels: JSON.stringify(vessels.map(function (v) {
                      return v.name;
                    })),
            error: JSON.stringify(error)
          }
        });
  };
  if (UploadActionable.abortable(actionable)) {
    report("error");
    return {
            TAG: "Uploading",
            started: started,
            uploaded: uploaded,
            size: size,
            actionables: [],
            results: Prelude.$$Array.append(results, status)
          };
  }
  var actionable$1 = UploadActionable.retry(actionable);
  if (actionable$1 !== undefined) {
    report("warning");
    return {
            TAG: "Uploading",
            started: started,
            uploaded: uploaded,
            size: size,
            actionables: Prelude.$$Array.append(actionables$1, actionable$1),
            results: results
          };
  } else {
    report("error");
    return {
            TAG: "Uploading",
            started: started,
            uploaded: uploaded,
            size: size,
            actionables: actionables$1,
            results: Prelude.$$Array.append(results, status)
          };
  }
}

export {
  next ,
}
/* UploadPayload Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as QuestionWidget from "./QuestionWidget.js";

function QuizWidget$Editor(props) {
  return Mithril.view(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var choices = match.choices;
                  var question = match.question;
                  var match$1 = question.kind;
                  if (typeof match$1 === "object" && match$1.NAME === "Quiz") {
                    var options = match$1.VAL;
                    var type_ = options.allow_multiple_answers ? "checkbox" : "radio";
                    tmp = ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("div", {
                                  children: question.name,
                                  className: "tile-content text-center text-small"
                                }),
                            ReactDOM.jsx("br", {}),
                            options.choices.map(function (label, index) {
                                  var checked = Prelude.$$Array.includes(choices, label);
                                  if (label !== "") {
                                    return ReactDOM.jsx("div", {
                                                children: ReactDOM.jsx("div", {
                                                      children: ReactDOM.jsxs("label", {
                                                            children: [
                                                              ReactDOM.jsx("input", {
                                                                    className: "form-input",
                                                                    id: question.id + "-" + String(index),
                                                                    style: {
                                                                      height: "60%",
                                                                      width: "100%"
                                                                    },
                                                                    checked: checked,
                                                                    name: question.id,
                                                                    type: type_,
                                                                    value: label,
                                                                    onChange: (function (extra) {
                                                                        var match = vnode.state.data;
                                                                        if (typeof match !== "object") {
                                                                          return ;
                                                                        }
                                                                        var choices = match.choices;
                                                                        var question = match.question;
                                                                        var match$1 = question.kind;
                                                                        var tmp;
                                                                        if (typeof match$1 === "object") {
                                                                          if (match$1.NAME === "Quiz") {
                                                                            var val = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[id='" + question.id + "-" + String(index) + "']")), (function (v) {
                                                                                        return v;
                                                                                      })), "");
                                                                            var match$2 = question.kind;
                                                                            tmp = typeof match$2 === "object" ? (
                                                                                match$2.NAME === "Quiz" ? (
                                                                                    match$2.VAL.allow_multiple_answers ? (
                                                                                        Prelude.$$Array.includes(choices, val) ? Curry._2(Prelude.$$Array.keep, choices, (function (choice) {
                                                                                                  return choice !== val;
                                                                                                })) : Curry._2(Prelude.$$Array.concat, choices, [val])
                                                                                      ) : [val]
                                                                                  ) : []
                                                                              ) : [];
                                                                          } else {
                                                                            tmp = [];
                                                                          }
                                                                        } else {
                                                                          tmp = [];
                                                                        }
                                                                        return Mithril.setState(vnode, {
                                                                                    TAG: "Loaded",
                                                                                    lastAnswer: match.lastAnswer,
                                                                                    lastNewAnswer: match.lastNewAnswer,
                                                                                    question: question,
                                                                                    level: match.level,
                                                                                    choices: tmp
                                                                                  });
                                                                      })
                                                                  }),
                                                              ReactDOM.jsx("i", {
                                                                    className: "form-icon"
                                                                  }),
                                                              label
                                                            ],
                                                            className: "form-" + type_
                                                          }),
                                                      className: "form-group"
                                                    }),
                                                className: "columns m-2"
                                              });
                                  } else {
                                    return Mithril.empty;
                                  }
                                })
                          ],
                          className: "form-group"
                        });
                  } else {
                    tmp = Mithril.empty;
                  }
                }
                return React.jsx(QuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return {
                                          attachments: []
                                        };
                                } else {
                                  return {
                                          value: {
                                            NAME: "Value",
                                            VAL: {
                                              NAME: "Quiz",
                                              VAL: match.choices
                                            }
                                          },
                                          attachments: []
                                        };
                                }
                              }),
                            allowSave: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                } else {
                                  return Caml_obj.notequal(match.choices, []);
                                }
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var choices = match.choices;
                                if (createNew) {
                                  return Caml_obj.notequal(choices, []);
                                }
                                var lastNewAnswer = match.lastNewAnswer;
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 === "object" && match$2.NAME === "Quiz") {
                                  return Caml_obj.notequal(Prelude.$$Array.sort(match$2.VAL, (function (a) {
                                                    return a;
                                                  }), undefined), Prelude.$$Array.sort(choices, (function (a) {
                                                    return a;
                                                  }), undefined));
                                } else {
                                  return false;
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var newState;
                                if (typeof extra !== "object") {
                                  newState = "NotReady";
                                } else {
                                  var lastNewAnswer = extra.lastNewAnswer;
                                  var match = vnode.state.data;
                                  var tmp;
                                  if (typeof match !== "object") {
                                    if (createNew) {
                                      tmp = [];
                                    } else if (lastNewAnswer !== undefined) {
                                      var match$1 = lastNewAnswer.value;
                                      if (match$1 !== undefined) {
                                        if (typeof match$1 === "object") {
                                          if (match$1.NAME === "Value") {
                                            var match$2 = match$1.VAL;
                                            tmp = typeof match$2 === "object" ? (
                                                match$2.NAME === "Quiz" ? match$2.VAL : []
                                              ) : [];
                                          } else {
                                            tmp = [];
                                          }
                                        } else {
                                          tmp = [];
                                        }
                                      } else {
                                        tmp = [];
                                      }
                                    } else {
                                      tmp = [];
                                    }
                                  } else {
                                    tmp = match.choices;
                                  }
                                  newState = {
                                    TAG: "Loaded",
                                    lastAnswer: extra.lastAnswer,
                                    lastNewAnswer: lastNewAnswer,
                                    question: extra.question,
                                    level: extra.level,
                                    choices: tmp
                                  };
                                }
                                return Mithril.setState(vnode, newState);
                              }),
                            children: tmp
                          });
              }));
}

function QuizWidget(props) {
  var draftSubmission = props.draftSubmission;
  var lastAnswer = props.lastAnswer;
  var lastNewAnswer = props.lastNewAnswer;
  var vessel = props.vessel;
  var level = props.level;
  var question = props.question;
  return Mithril.view(Mithril.component(), (function (vnode) {
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                var submissionId = Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                        return s.id;
                      }));
                var editUrl = function (none, extra, extra$1, extra$2, extra$3) {
                  return URLs.quizWidgetEditor(none, Prelude.default(Mithril__Route.paramBool("isRectification"), false), extra, extra$1, extra$2, extra$3);
                };
                var match = question.kind;
                var icon = typeof match === "object" && match.NAME === "Quiz" && match.VAL.allow_multiple_answers ? "square" : "circle";
                return React.jsx(QuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            draftSubmission: draftSubmission,
                            editUrl: editUrl,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: icon
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active ",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isNone(submissionId)
                                        ],
                                        [
                                          "bg-success",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isSome(submissionId)
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return QuestionWidget.startInspection(vnode, editUrl, extra);
                                    })
                                })
                          });
              }));
}

var Editor = {
  make: QuizWidget$Editor
};

var make = QuizWidget;

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing procedures options";
}

function $$process(procedure) {
  var newrecord = Caml_obj.obj_dup(procedure);
  newrecord.always_require_additional_annotation = false;
  return newrecord;
}

var ProcedureRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing procedures options";
}

function migrate(param, t) {
  return ProcedureRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* ProcedureRewriter Not a pure module */

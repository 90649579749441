// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "./Query.js";
import * as Luxon from "luxon";
import * as Routes from "./Routes.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Settings from "./Settings.js";
import * as LocalFile from "../libs/LocalFile.js";
import * as Hyphenated from "../libs/Hyphenated.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InitFeedback from "./InitFeedback.js";
import * as Initializers from "./Initializers.js";
import * as State__Memory from "./state/State__Memory.js";
import * as Mithril__Route from "../libs/rescript-mithril/src/Mithril__Route.js";
import * as Browser from "@sentry/browser";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

Luxon.Settings.defaultLocale = "en";

Hyphenated.init();

var InitializationError = /* @__PURE__ */Caml_exceptions.create("Init.InitializationError");

Prelude.thenDo(Prelude.PromisedResult.bind(Prelude.PromisedResult.bind(Prelude.then(State__Memory.Persisted.init(), (function () {
                    return Query.Database.connect("kaiko_v2");
                  })), (function (db) {
                return Prelude.catchResolve(Prelude.then(Initializers.init(db), (function (r) {
                                  if (r.TAG === "Ok") {
                                    return Promise.resolve({
                                                TAG: "Ok",
                                                _0: r._0
                                              });
                                  }
                                  throw {
                                        RE_EXN_ID: InitializationError,
                                        _1: "Cannot initialize the App",
                                        Error: new Error()
                                      };
                                })), (function (error) {
                              return {
                                      TAG: "Error",
                                      _0: error
                                    };
                            }));
              })), (function () {
            return LocalFile.Database.connect("local-file-storage");
          })), (function (result) {
        if (result.TAG === "Ok") {
          Mithril__Route.setPrefix(Settings.mithrilRoutePrefix);
          InitFeedback.done();
          Curry._2(Prelude.OptionExported.$$Option.map, Caml_option.nullable_to_opt(document.getElementById("app")), (function (root) {
                  Mithril.route(root, "/", Routes.routes);
                }));
          return ;
        }
        Browser.captureException(result._0);
        InitFeedback.updateFeedback([{
                TAG: "ErrorMessage",
                _0: "There was a problem initializing the app.  Please, contact Kaiko Systems."
              }]);
      }));

export {
  InitializationError ,
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function make(param) {
  return function (db, _transaction) {
    var findings = IDB__Migration__Database.createObjectStore(db, "findings");
    IDB__Migration__Store.createSimpleIndex(findings, "vessel_id");
    IDB__Migration__Store.createSimpleIndex(findings, "created_by_id");
    IDB__Migration__Store.createSimpleIndex(findings, "level_id");
    IDB__Migration__Store.createSimpleIndex(findings, "status");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Store Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ConnectionIndicator from "../../common/views/ConnectionIndicator.js";

var UnknownUser = /* @__PURE__ */Caml_exceptions.create("UserAPI.UnknownUser");

var url = "/accounts/me";

var fields = [
  [
    "id",
    "String"
  ],
  [
    "first_name",
    "String"
  ],
  [
    "last_name",
    "String"
  ],
  [
    "email",
    "String"
  ],
  [
    "has_valid_password",
    "Boolean"
  ]
];

var Definition = {
  url: url,
  fields: fields
};

var Endpoint = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields,
      url: url
    });

function read(user) {
  return Prelude.catchResolve(Prelude.thenResolve(Prelude.PromisedResult.map(Endpoint.get(undefined, undefined, user, ""), (function (r) {
                        return Curry._2(Prelude.OptionExported.$$Option.flatMap, r, (function (u) {
                                      if (Caml_obj.equal(u.id, user.id)) {
                                        return {
                                                id: u.id,
                                                first_name: u.first_name,
                                                last_name: u.last_name,
                                                token: user.token,
                                                email: u.email,
                                                has_valid_password: u.has_valid_password
                                              };
                                      }
                                      
                                    }));
                      })), (function (res) {
                    if (res.TAG !== "Ok") {
                      return {
                              TAG: "Error",
                              _0: res._0
                            };
                    }
                    var user$1 = res._0;
                    if (user$1 !== undefined) {
                      return {
                              TAG: "Ok",
                              _0: user$1
                            };
                    }
                    throw {
                          RE_EXN_ID: UnknownUser,
                          message: user.email,
                          code: 404,
                          response: {},
                          Error: new Error()
                        };
                  })), (function (error) {
                return {
                        TAG: "Error",
                        _0: error
                      };
              }));
}

var ReadUser = {
  Definition: Definition,
  Endpoint: Endpoint,
  read: read
};

var url$1 = "/accounts/login/send/token";

var fields$1 = [];

var Definition$1 = {
  url: url$1,
  fields: fields$1
};

var Endpoint$1 = ApiMaker.MakeAPIEndpoint({
      fields: fields$1,
      url: url$1
    });

function send(email) {
  return Endpoint$1.postOne(undefined, undefined, {
              email: email
            });
}

var RequestLoginToken = {
  Definition: Definition$1,
  Endpoint: Endpoint$1,
  send: send
};

var fields$2 = [
  [
    "token",
    "String"
  ],
  [
    "user",
    {
      TAG: "Deserializer",
      _0: Endpoint.Deserializer
    }
  ]
];

var Common = {
  fields: fields$2
};

var TokenDefinition = {
  fields: fields$2,
  url: "/accounts/login/verify/token"
};

var TokenEndpoint = ApiMaker.MakeAPIEndpoint(TokenDefinition);

var PasswordDefinition = {
  fields: fields$2,
  url: "/accounts/login/verify/password"
};

var PasswordEndpoint = ApiMaker.MakeAPIEndpoint(PasswordDefinition);

function verify(email, $$with) {
  var response;
  response = $$with.TAG === "Token" ? TokenEndpoint.postOne(undefined, undefined, {
          email: email,
          token: $$with._0
        }) : PasswordEndpoint.postOne(undefined, undefined, {
          email: email,
          password: $$with._0
        });
  return Prelude.catchResolve(Prelude.PromisedResult.map(response, (function (response) {
                    if (response !== undefined) {
                      return {
                              id: response.user.id,
                              first_name: response.user.first_name,
                              last_name: response.user.last_name,
                              token: response.token,
                              email: response.user.email,
                              has_valid_password: response.user.has_valid_password
                            };
                    }
                    throw {
                          RE_EXN_ID: UnknownUser,
                          message: email,
                          code: 404,
                          response: {},
                          Error: new Error()
                        };
                  })), (function (error) {
                return {
                        TAG: "Error",
                        _0: error
                      };
              }));
}

var VerifyLogin = {
  Common: Common,
  TokenDefinition: TokenDefinition,
  TokenEndpoint: TokenEndpoint,
  PasswordDefinition: PasswordDefinition,
  PasswordEndpoint: PasswordEndpoint,
  verify: verify
};

var url$2 = "/accounts/password/change";

var fields$3 = [];

var Definition$2 = {
  url: url$2,
  fields: fields$3
};

var Endpoint$2 = ApiMaker.MakeAPIEndpoint({
      fields: fields$3,
      url: url$2
    });

function change(user, old, $$new) {
  return ConnectionIndicator.show(function () {
              return Endpoint$2.postOne(undefined, user, {
                          new_password: $$new,
                          old_password: Prelude.default(old, "")
                        });
            });
}

var ChangePassword = {
  Definition: Definition$2,
  Endpoint: Endpoint$2,
  change: change
};

export {
  UnknownUser ,
  ReadUser ,
  RequestLoginToken ,
  VerifyLogin ,
  ChangePassword ,
}
/* Endpoint Not a pure module */

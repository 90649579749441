// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserAPI from "../../api/endpoints/UserAPI.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Settings from "../../Settings.js";
import * as State__User from "../../state/State__User.js";
import * as Accounts_Login from "./Accounts_Login.js";
import FullLogoPng from "/images/full-logo.png";

function handleError(error) {
  var errors = Accounts_Login.errors(error);
  return {
          password: Curry._2(Prelude.Dict.get, errors, "password"),
          email: Curry._2(Prelude.Dict.get, errors, "email"),
          general: Curry._2(Prelude.Dict.get, errors, "__all__")
        };
}

function make() {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.oninit(Mithril.component("Ready"), (function (param) {
                            State__User.Auth.redirectToIndexIfLoggedIn();
                          })), (function (vnode) {
                        var email = Mithril$1.route.param("email");
                        var focusOn;
                        if (email !== undefined && email !== "") {
                          Mithril.$$Element.setValue(Mithril.$$Element.select(vnode, "[name=email]"), email);
                          focusOn = "password";
                        } else {
                          focusOn = "email";
                        }
                        Mithril.$$Element.focus(Mithril.$$Element.select(vnode, "[name=" + focusOn + "]"));
                      })), (function (vnode) {
                    var user = vnode.state.data;
                    if (typeof user !== "object") {
                      Promise.resolve();
                    } else if (user.TAG === "Failed") {
                      Promise.resolve(Mithril.$$Element.focus(Mithril.$$Element.select(vnode, "[name=password]")));
                    } else {
                      Prelude.thenResolve(State__User.login(user._0), (function (param) {
                              Mithril$1.route.set("/");
                            }));
                    }
                  })), (function (vnode) {
                var state = vnode.state.data;
                var resetLink = Settings.apiHost + "/request-password-reset";
                var tmp;
                if (typeof state !== "object" || state.TAG !== "Failed") {
                  tmp = Mithril.empty;
                } else {
                  var error = state._0.email;
                  tmp = error !== undefined ? ReactDOM.jsx("p", {
                          children: error,
                          className: "form-input-hint"
                        }) : Mithril.empty;
                }
                var tmp$1;
                tmp$1 = typeof state !== "object" || state.TAG !== "Failed" ? false : state._0.email !== undefined;
                var tmp$2;
                if (typeof state !== "object" || state.TAG !== "Failed") {
                  tmp$2 = Mithril.empty;
                } else {
                  var error$1 = state._0.password;
                  tmp$2 = error$1 !== undefined ? ReactDOM.jsx("p", {
                          children: error$1,
                          className: "form-input-hint"
                        }) : Mithril.empty;
                }
                var tmp$3;
                tmp$3 = typeof state !== "object" || state.TAG !== "Failed" ? false : state._0.password !== undefined;
                var tmp$4;
                if (typeof state !== "object" || state.TAG !== "Failed") {
                  tmp$4 = Mithril.empty;
                } else {
                  var error$2 = state._0.general;
                  tmp$4 = error$2 !== undefined ? ReactDOM.jsx("p", {
                          children: error$2,
                          className: "text-error"
                        }) : Mithril.empty;
                }
                var tmp$5;
                tmp$5 = typeof state !== "object" && state === "Busy" ? true : false;
                var tmp$6;
                tmp$6 = typeof state !== "object" && state === "Busy" ? true : false;
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsx("div", {
                                  children: ReactDOM.jsx("div", {
                                        children: ReactDOM.jsx("div", {
                                              children: ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsx("img", {
                                                            className: "img-responsive",
                                                            src: FullLogoPng
                                                          }),
                                                      ReactDOM.jsx("div", {
                                                            className: "divider blank"
                                                          }),
                                                      ReactDOM.jsxs("form", {
                                                            children: [
                                                              ReactDOM.jsxs("fieldset", {
                                                                    children: [
                                                                      ReactDOM.jsxs("div", {
                                                                            children: [
                                                                              ReactDOM.jsx("label", {
                                                                                    children: "Email",
                                                                                    className: "form-label",
                                                                                    htmlFor: "login-form-email"
                                                                                  }),
                                                                              ReactDOM.jsx("input", {
                                                                                    className: "form-input",
                                                                                    id: "login-form-email",
                                                                                    name: "email",
                                                                                    placeholder: "your@email.com",
                                                                                    required: true,
                                                                                    type: "email"
                                                                                  }),
                                                                              tmp
                                                                            ],
                                                                            className: Mithril.$$class([
                                                                                  [
                                                                                    "form-group",
                                                                                    true
                                                                                  ],
                                                                                  [
                                                                                    "has-error",
                                                                                    tmp$1
                                                                                  ]
                                                                                ], undefined)
                                                                          }),
                                                                      ReactDOM.jsxs("div", {
                                                                            children: [
                                                                              ReactDOM.jsx("label", {
                                                                                    children: "Password",
                                                                                    className: "form-label",
                                                                                    htmlFor: "login-form-password]"
                                                                                  }),
                                                                              ReactDOM.jsx("input", {
                                                                                    className: "form-input",
                                                                                    id: "login-form-password",
                                                                                    autoComplete: "off",
                                                                                    name: "password",
                                                                                    placeholder: "******",
                                                                                    type: "password"
                                                                                  }),
                                                                              tmp$2
                                                                            ],
                                                                            className: Mithril.$$class([
                                                                                  [
                                                                                    "form-group",
                                                                                    true
                                                                                  ],
                                                                                  [
                                                                                    "has-error",
                                                                                    tmp$3
                                                                                  ]
                                                                                ], undefined)
                                                                          }),
                                                                      tmp$4,
                                                                      ReactDOM.jsx("div", {
                                                                            className: "divider blank"
                                                                          }),
                                                                      ReactDOM.jsx("div", {
                                                                            children: ReactDOM.jsx("button", {
                                                                                  children: "Sign in",
                                                                                  className: Mithril.$$class([
                                                                                        [
                                                                                          "btn btn-primary btn-block",
                                                                                          true
                                                                                        ],
                                                                                        [
                                                                                          "loading",
                                                                                          tmp$5
                                                                                        ]
                                                                                      ], undefined),
                                                                                  type: "submit"
                                                                                }),
                                                                            className: "form-group"
                                                                          })
                                                                    ],
                                                                    disabled: tmp$6
                                                                  }),
                                                              React.jsx(Mithril.Link.make, {
                                                                    href: resetLink,
                                                                    className: "d-block text-primary",
                                                                    children: "Forgot your password?",
                                                                    target: "_blank"
                                                                  }),
                                                              ReactDOM.jsx("button", {
                                                                    children: "Switch to sign in with code",
                                                                    className: "d-block text-primary p-0",
                                                                    style: {
                                                                      background: "none",
                                                                      border: "none",
                                                                      cursor: "pointer"
                                                                    },
                                                                    onClick: (function ($$event) {
                                                                        $$event.preventDefault();
                                                                        Mithril$1.route.set("/login", {
                                                                              email: Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=email]"))
                                                                            });
                                                                      })
                                                                  })
                                                            ],
                                                            onSubmit: (function (extra) {
                                                                extra.preventDefault();
                                                                var email = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=email]"));
                                                                var password = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=password]"));
                                                                if (email !== undefined && password !== undefined) {
                                                                  Mithril.setState(vnode, "Busy");
                                                                  return Prelude.thenDo(UserAPI.VerifyLogin.verify(email, {
                                                                                  TAG: "Password",
                                                                                  _0: password
                                                                                }), (function (result) {
                                                                                var tmp;
                                                                                tmp = result.TAG === "Ok" ? ({
                                                                                      TAG: "Succeed",
                                                                                      _0: result._0
                                                                                    }) : ({
                                                                                      TAG: "Failed",
                                                                                      _0: handleError(result._0)
                                                                                    });
                                                                                Mithril.setState(vnode, tmp);
                                                                              }));
                                                                }
                                                                
                                                              })
                                                          })
                                                    ],
                                                    className: "hero-body"
                                                  }),
                                              className: "hero"
                                            }),
                                        className: "column col-6 col-lg-8 col-md-10 col-sm-12 col-mx-auto"
                                      }),
                                  className: "columns"
                                }),
                            className: "container grid md"
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../libs/URL.js";
import * as Freq from "../../libs/Freq.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../Query.js";
import * as React from "../../libs/rescript-mithril/src/React.js";
import * as Utils from "../../libs/Utils.js";
import * as Luxon from "luxon";
import * as Answer from "../tree/Answer.js";
import * as Vessel from "../vessel/Vessel.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AnswerId from "../tree/AnswerId.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../tree/Question.js";
import * as ReactDOM from "../../libs/rescript-mithril/src/ReactDOM.js";
import * as Settings from "../Settings.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as Procedure from "../survey/Procedure.js";
import * as Attachment from "../attachment_chunks/Attachment.js";
import * as Inspection from "../survey/Inspection.js";
import * as PageLayout from "../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../survey/ProcedureId.js";
import * as State__User from "../state/State__User.js";
import * as StepCounter from "../runtime/StepCounter.js";
import * as InspectionId from "../survey/InspectionId.js";
import * as LimitedArray from "../../libs/LimitedArray.js";
import * as QuestionType from "../survey/QuestionType.js";
import * as State__Memory from "../state/State__Memory.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as State__Amounts from "../state/State__Amounts.js";
import * as TimerProgressBar from "../../libs/TimerProgressBar.js";
import * as DevelopersArenaWrapper from "./DevelopersArenaWrapper.js";

function createInspections(vnode, $$event) {
  var vessel = vnode.state.data;
  if (typeof vessel !== "object") {
    return ;
  }
  if (vessel.TAG === "Idle") {
    var vessel$1 = vessel._0;
    var amount = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=amount]")), Prelude.Int.fromString), 10);
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    return Prelude.thenDo(Promise.all([
                    Query.read((newrecord.inspections = Inspection.ofVessel(vessel$1.id), newrecord.procedures = {
                            TAG: "And",
                            _0: Procedure.ofVessel(vessel$1.id),
                            _1: {
                              TAG: "AnyOf",
                              _0: "question_type",
                              _1: [
                                  "RatingQuestion",
                                  "OkNotOkQuestion"
                                ].map(QuestionType.Serializer.toJSON)
                            }
                          }, newrecord)),
                    State__User.getCurrent()
                  ]), (function (param) {
                  var inspector = param[1];
                  var match = param[0];
                  var inspections = match.inspections;
                  var procedures = match.procedures;
                  var timestamps = inspections.map(function (i) {
                        return i.timestamp;
                      });
                  Belt_SortArray.stableSortInPlaceBy(timestamps, (function (t1, t2) {
                          return Math.sign(t1.valueOf() - t2.valueOf()) | 0;
                        }));
                  var timestamp = Prelude.default(Prelude.$$Array.last(timestamps), new Date());
                  var procedureIds = ProcedureId.$$Set.fromArray(procedures.map(function (p) {
                            return p.id;
                          }));
                  var inspections$1 = Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.keep, inspections, (function (i) {
                                  return Curry._1(Prelude.OptionExported.$$Option.isSome, i.value);
                                })), (function (i) {
                              return ProcedureId.$$Set.has(procedureIds, i.procedure_id);
                            })), (function (i) {
                          return QuestionType.Value.rate(i.value) !== 0;
                        }));
                  if (inspections$1.length === 0) {
                    return Mithril.setState(vnode, "NoVessels");
                  }
                  if (procedures.length === 0) {
                    return Mithril.setState(vnode, "NoVessels");
                  }
                  if (inspector === undefined) {
                    return Mithril.setState(vnode, "NoVessels");
                  }
                  Curry._1(Prelude.$$Array.shuffleInPlace, procedures);
                  var procedure = procedures[0];
                  Mithril.setState(vnode, {
                        TAG: "Creating",
                        vessel: vessel$1,
                        procedure: procedure,
                        inspections: inspections$1,
                        inspector: inspector,
                        timestamp: timestamp,
                        value: 0,
                        max: amount,
                        steps: 0
                      });
                  Utils.$$continue(function () {
                        createInspections(vnode, $$event);
                      });
                }));
  }
  var steps = vessel.steps;
  var max = vessel.max;
  var value = vessel.value;
  var timestamp = vessel.timestamp;
  var inspector = vessel.inspector;
  var inspections = vessel.inspections;
  var procedure = vessel.procedure;
  var vessel$2 = vessel.vessel;
  var inspection = Curry._1(Prelude.$$Array.shuffle, inspections)[0];
  if (value >= max) {
    return Mithril.setState(vnode, {
                TAG: "Idle",
                _0: vessel$2
              });
  } else {
    return Prelude.thenDo(Prelude.then(Prelude.then(Promise.all(inspection.attachments.map(Attachment.clone)), (function (attachments) {
                          var attachments$1 = Prelude.$$Array.keepSome(attachments);
                          var newInspection_id = InspectionId.make();
                          var newInspection_procedure_id = inspection.procedure_id;
                          var newInspection_component_id = inspection.component_id;
                          var newInspection_section_id = inspection.section_id;
                          var newInspection_vessel_id = inspection.vessel_id;
                          var newInspection_inspector_id = Caml_option.some(inspector.id);
                          var newInspection_code = inspection.code;
                          var newInspection_annotations = inspection.annotations;
                          var newInspection_additional_annotation = inspection.additional_annotation;
                          var newInspection_picture_prompted = inspection.picture_prompted;
                          var newInspection_deficiency_id = inspection.deficiency_id;
                          var newInspection_value = inspection.value;
                          var newInspection_deadline = inspection.deadline;
                          var newInspection_profile = inspection.profile;
                          var newInspection = {
                            id: newInspection_id,
                            procedure_id: newInspection_procedure_id,
                            component_id: newInspection_component_id,
                            section_id: newInspection_section_id,
                            vessel_id: newInspection_vessel_id,
                            inspector_id: newInspection_inspector_id,
                            timestamp: timestamp,
                            code: newInspection_code,
                            annotations: newInspection_annotations,
                            additional_annotation: newInspection_additional_annotation,
                            picture_prompted: newInspection_picture_prompted,
                            walked_steps: steps,
                            app_version: Settings.releaseVersion,
                            deficiency_id: newInspection_deficiency_id,
                            attachments: attachments$1,
                            value: newInspection_value,
                            deadline: newInspection_deadline,
                            profile: newInspection_profile
                          };
                          var newrecord = Caml_obj.obj_dup(Query.makeWrite());
                          return Query.write((newrecord.newInspections = [{
                                          TAG: "Save",
                                          _0: newInspection
                                        }], newrecord));
                        })), (function (param) {
                      return State__Amounts.refresh();
                    })), (function (param) {
                  Mithril.setState(vnode, {
                        TAG: "Creating",
                        vessel: vessel$2,
                        procedure: procedure,
                        inspections: inspections,
                        inspector: inspector,
                        timestamp: Luxon.DateTime.fromJSDate(timestamp).plus(Luxon.Duration.fromObject({
                                    minutes: Js_math.random_int(1, 5)
                                  })).toJSDate(),
                        value: value + 1 | 0,
                        max: max,
                        steps: steps + Js_math.random_int(1, 30) | 0
                      });
                  Utils.$$continue(function () {
                        createInspections(vnode, $$event);
                      });
                }));
  }
}

function DevelopersArena$InspectionCreator(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var match = vnode.state.data;
                    if (typeof match === "object") {
                      return ;
                    }
                    if (match !== "NotReady") {
                      return ;
                    }
                    var vessel = State__Memory.Current.get().vessel;
                    if (vessel !== undefined) {
                      return Mithril.setState(vnode, {
                                  TAG: "Idle",
                                  _0: vessel
                                });
                    }
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.vessels = "All", newrecord)), (function (param) {
                            var vessels = param.vessels;
                            if (vessels.length !== 0) {
                              return Mithril.setState(vnode, {
                                          TAG: "Idle",
                                          _0: vessels[0]
                                        });
                            } else {
                              return Mithril.setState(vnode, "NoVessels");
                            }
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                } else if (match.TAG === "Idle") {
                  return [
                          ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("label", {
                                        children: "Amount:",
                                        className: "d-block col-8 text-right pr-2",
                                        htmlFor: "amount"
                                      }),
                                  ReactDOM.jsx("input", {
                                        className: "col-4 text-right",
                                        autoComplete: "false",
                                        inputMode: "numeric",
                                        name: "amount",
                                        pattern: "\\d*",
                                        type: "text",
                                        value: "10"
                                      })
                                ],
                                className: "columns col-12 text-small",
                                style: {
                                  margin: "0 0 0.4rem 0"
                                }
                              }),
                          ReactDOM.jsx("button", {
                                children: "Create random inspections",
                                className: "btn btn-block",
                                style: {
                                  height: "2.5rem"
                                },
                                onClick: (function (extra) {
                                    return createInspections(vnode, extra);
                                  })
                              })
                        ];
                } else {
                  return ReactDOM.jsx("progress", {
                              style: {
                                height: "2.5rem",
                                width: "100%"
                              },
                              max: String(match.max),
                              value: String(match.value)
                            });
                }
              }));
}

var InspectionCreator = {
  createInspections: createInspections,
  make: DevelopersArena$InspectionCreator
};

function createAnswers(vnode, $$event) {
  var vessel = vnode.state.data;
  if (typeof vessel !== "object") {
    return ;
  }
  if (vessel.TAG === "Idle") {
    var vessel$1 = vessel._0;
    var amount = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=amount]")), Prelude.Int.fromString), 10);
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    return Prelude.thenDo(Promise.all([
                    Query.read((newrecord.answers = Answer.ofVessel(vessel$1.id), newrecord.questions = Question.ofVessel(vessel$1.id), newrecord)),
                    State__User.getCurrent()
                  ]), (function (param) {
                  var inspector = param[1];
                  var match = param[0];
                  var answers = match.answers;
                  var questions = match.questions;
                  var timestamps = answers.map(function (i) {
                        return i.timestamp;
                      });
                  Belt_SortArray.stableSortInPlaceBy(timestamps, (function (t1, t2) {
                          return Math.sign(t1.valueOf() - t2.valueOf()) | 0;
                        }));
                  var timestamp = Prelude.default(Prelude.$$Array.last(timestamps), new Date());
                  var answers$1 = Curry._2(Prelude.$$Array.keep, answers, (function (i) {
                          return Curry._1(Prelude.OptionExported.$$Option.isSome, i.value);
                        }));
                  if (answers$1.length === 0) {
                    return Mithril.setState(vnode, "NoVessels");
                  }
                  if (questions.length === 0) {
                    return Mithril.setState(vnode, "NoVessels");
                  }
                  if (inspector === undefined) {
                    return Mithril.setState(vnode, "NoVessels");
                  }
                  Curry._1(Prelude.$$Array.shuffleInPlace, questions);
                  var question = questions[0];
                  Mithril.setState(vnode, {
                        TAG: "Creating",
                        vessel: vessel$1,
                        question: question,
                        answers: answers$1,
                        inspector: inspector,
                        timestamp: timestamp,
                        current: 0,
                        max: amount,
                        steps: 0
                      });
                  Utils.$$continue(function () {
                        createAnswers(vnode, $$event);
                      });
                }));
  }
  var steps = vessel.steps;
  var max = vessel.max;
  var current = vessel.current;
  var timestamp = vessel.timestamp;
  var inspector = vessel.inspector;
  var answers = vessel.answers;
  var question = vessel.question;
  var vessel$2 = vessel.vessel;
  var answer = Curry._1(Prelude.$$Array.shuffle, answers)[0];
  if (current >= max) {
    return Mithril.setState(vnode, {
                TAG: "Idle",
                _0: vessel$2
              });
  } else {
    return Prelude.thenDo(Prelude.then(Prelude.then(Promise.all(answer.evidences.map(Attachment.clone)), (function (evidences) {
                          var evidences$1 = Prelude.$$Array.keepSome(evidences);
                          var newrecord = Caml_obj.obj_dup(answer);
                          newrecord.evidences = evidences$1;
                          newrecord.walked_steps = steps;
                          newrecord.timestamp = timestamp;
                          newrecord.user_id = Caml_option.some(inspector.id);
                          newrecord.id = AnswerId.make();
                          var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
                          return Query.write((newrecord$1.newAnswers = [{
                                          TAG: "Save",
                                          _0: newrecord
                                        }], newrecord$1));
                        })), (function (param) {
                      return State__Amounts.refresh();
                    })), (function (param) {
                  Mithril.setState(vnode, {
                        TAG: "Creating",
                        vessel: vessel$2,
                        question: question,
                        answers: answers,
                        inspector: inspector,
                        timestamp: Luxon.DateTime.fromJSDate(timestamp).plus(Luxon.Duration.fromObject({
                                    minutes: Js_math.random_int(1, 5)
                                  })).toJSDate(),
                        current: current + 1 | 0,
                        max: max,
                        steps: steps + Js_math.random_int(1, 30) | 0
                      });
                  Utils.$$continue(function () {
                        createAnswers(vnode, $$event);
                      });
                }));
  }
}

function DevelopersArena$AnswersCreator(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var match = vnode.state.data;
                    if (typeof match === "object") {
                      return ;
                    }
                    if (match !== "NotReady") {
                      return ;
                    }
                    var vessel = State__Memory.Current.get().vessel;
                    if (vessel !== undefined) {
                      return Mithril.setState(vnode, {
                                  TAG: "Idle",
                                  _0: vessel
                                });
                    }
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.vessels = "All", newrecord)), (function (param) {
                            var vessels = param.vessels;
                            if (vessels.length !== 0) {
                              return Mithril.setState(vnode, {
                                          TAG: "Idle",
                                          _0: vessels[0]
                                        });
                            } else {
                              return Mithril.setState(vnode, "NoVessels");
                            }
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                } else if (match.TAG === "Idle") {
                  return [
                          ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("label", {
                                        children: "Amount:",
                                        className: "d-block col-8 text-right pr-2",
                                        htmlFor: "amount"
                                      }),
                                  ReactDOM.jsx("input", {
                                        className: "col-4 text-right",
                                        autoComplete: "false",
                                        inputMode: "numeric",
                                        name: "amount",
                                        pattern: "\\d*",
                                        type: "text",
                                        value: "10"
                                      })
                                ],
                                className: "columns col-12 text-small",
                                style: {
                                  margin: "0 0 0.4rem 0"
                                }
                              }),
                          ReactDOM.jsx("button", {
                                children: "Create random answers",
                                className: "btn btn-block",
                                style: {
                                  height: "2.5rem"
                                },
                                onClick: (function (extra) {
                                    return createAnswers(vnode, extra);
                                  })
                              })
                        ];
                } else {
                  return ReactDOM.jsx("progress", {
                              style: {
                                height: "2.5rem",
                                width: "100%"
                              },
                              max: String(match.max),
                              value: String(match.current)
                            });
                }
              }));
}

var AnswersCreator = {
  createAnswers: createAnswers,
  make: DevelopersArena$AnswersCreator
};

function timeout(vnode) {
  return Prelude.default(vnode.attrs.timeout, 25000);
}

function action(vnode, param) {
  var match = vnode.state.data;
  switch (match) {
    case "Idle" :
        Mithril.setState(vnode, "Confirming");
        return Prelude.thenDo(Prelude.Promises.ellapsed(timeout(vnode)), (function () {
                      Mithril.setState(vnode, "Idle");
                    }));
    case "Confirming" :
        Mithril.setState(vnode, "Action");
        return Prelude.thenDo(vnode.attrs.action(), (function () {
                      Mithril.setState(vnode, "Idle");
                    }));
    case "Action" :
        return ;
    
  }
}

function make(param) {
  return Mithril.view(Mithril.component("Idle"), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                switch (match) {
                  case "Idle" :
                      tmp = ReactDOM.jsx("button", {
                            children: vnode.attrs.title,
                            className: "btn btn-block",
                            style: {
                              height: "2.5rem"
                            },
                            onClick: (function (extra) {
                                return action(vnode, extra);
                              })
                          });
                      break;
                  case "Confirming" :
                      var timeoutStr = String(timeout(vnode) / 1000 | 0);
                      tmp = ReactDOM.jsxs("button", {
                            children: [
                              "Press to confirm, or wait " + timeoutStr + " seconds",
                              React.jsx(TimerProgressBar.make, {
                                    timeout: timeout(vnode),
                                    style: {
                                      border: "0",
                                      bottom: "0",
                                      height: "0.25rem",
                                      left: "0",
                                      position: "absolute",
                                      width: "100%"
                                    }
                                  })
                            ],
                            className: "btn btn-error btn-block",
                            style: {
                              height: "2.5rem",
                              position: "relative"
                            },
                            onClick: (function (extra) {
                                return action(vnode, extra);
                              })
                          });
                      break;
                  case "Action" :
                      tmp = ReactDOM.jsx("button", {
                            children: "...",
                            className: "btn btn-error btn-block disabled",
                            style: {
                              height: "2.5rem"
                            }
                          });
                      break;
                  
                }
                return React.jsx(DevelopersArenaWrapper.make, {
                            children: tmp
                          });
              }));
}

var TimeProtectedButton = {
  timeout: timeout,
  action: action,
  make: make
};

async function action$1() {
  await Prelude.Promises.ellapsed(800);
  await LocalFile.clean(true, false);
}

function DevelopersArena$LocalFileCleaner(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(DevelopersArenaWrapper.make, {
                            children: React.jsx(make, {
                                  title: "Clean the LocalFile!",
                                  action: action$1
                                })
                          });
              }));
}

var LocalFileCleaner = {
  action: action$1,
  make: DevelopersArena$LocalFileCleaner
};

async function action$2() {
  await Prelude.Promises.ellapsed(800);
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.newAnswers = ["Clear"], newrecord.newInspections = ["Clear"], newrecord));
}

function DevelopersArena$InspectionsCleaner(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(DevelopersArenaWrapper.make, {
                            children: React.jsx(make, {
                                  title: "Remove new inspections!",
                                  action: action$2
                                })
                          });
              }));
}

var InspectionsCleaner = {
  action: action$2,
  make: DevelopersArena$InspectionsCleaner
};

async function action$3() {
  await Prelude.Promises.ellapsed(800);
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.findings = ["Clear"], newrecord.answers = ["Clear"], newrecord.questions = ["Clear"], newrecord.levels = ["Clear"], newrecord.companyForms = ["Clear"], newrecord.deficiencies = ["Clear"], newrecord.inspections = ["Clear"], newrecord.procedures = ["Clear"], newrecord.components = ["Clear"], newrecord.sections = ["Clear"], newrecord.vessels = ["Clear"], newrecord));
  StepCounter.stop();
}

function DevelopersArena$VesselCleaner(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(DevelopersArenaWrapper.make, {
                            children: React.jsx(make, {
                                  title: "Clean vessels!",
                                  action: action$3
                                })
                          });
              }));
}

var VesselCleaner = {
  action: action$3,
  make: DevelopersArena$VesselCleaner
};

function showFloat(v) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, v, (function (v) {
                    var r = v.toFixed(4);
                    if (v < 0.0) {
                      return r;
                    } else {
                      return " " + r;
                    }
                  })), "N/A");
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var data = vnode.attrs.data;
                var thStyle = {
                  borderRight: "1px #444 solid",
                  fontFamily: "monospace",
                  paddingRight: "0.7rem",
                  textAlign: "right",
                  width: "13rem"
                };
                var tdStyle = {
                  fontFamily: "monospace",
                  paddingLeft: "0.7rem",
                  textAlign: "\".\" center"
                };
                return ReactDOM.jsx("table", {
                            children: ReactDOM.jsx("tbody", {
                                  children: data.map(function (param) {
                                        return ReactDOM.jsxs("tr", {
                                                    children: [
                                                      ReactDOM.jsx("th", {
                                                            children: param[0],
                                                            style: thStyle
                                                          }),
                                                      param[1].map(function (n) {
                                                            return ReactDOM.jsx("td", {
                                                                        children: showFloat(n),
                                                                        style: tdStyle
                                                                      });
                                                          })
                                                    ]
                                                  });
                                      })
                                }),
                            style: {
                              borderCollapse: "collapse",
                              margin: "0 auto"
                            }
                          });
              }));
}

var FloatsTable = {
  showFloat: showFloat,
  make: make$1
};

function stopTracking(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  if (match.TAG !== "Showing") {
    return ;
  }
  StepCounter.removeListener(match.handler);
  var data = [[
      "time",
      "x",
      "y",
      "z",
      "steps",
      "cycles",
      "g",
      "param:threshold",
      "param:ratio",
      "param:max",
      "param:rate",
      "param:size",
      "param:window\n"
    ]];
  var ledger = StepCounter.stop();
  var Params = StepCounter.params();
  LimitedArray.forEach(ledger, (function (param) {
          var z = param.z;
          var y = param.y;
          var x = param.x;
          data.push([
                param.timestamp.toFixed(10),
                Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, x, (function (param) {
                            return param.toFixed(4);
                          })), "N/A"),
                Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, y, (function (param) {
                            return param.toFixed(4);
                          })), "N/A"),
                Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, z, (function (param) {
                            return param.toFixed(4);
                          })), "N/A"),
                String(param.counted_steps),
                String(param.counted_cycles),
                Math.sqrt(Prelude.default(x, 0.0) * Prelude.default(x, 0.0) + Prelude.default(y, 0.0) * Prelude.default(y, 0.0) + Prelude.default(z, 0.0) * Prelude.default(z, 0.0)).toFixed(4),
                Params.threshold.toFixed(4),
                Params.ratio.toFixed(4),
                Params.maxG.toFixed(4),
                Freq.freq(Params.rate).toFixed(4),
                String(Params.signalSize),
                String(Params.windowSize) + "\n"
              ]);
        }));
  var blob = new Blob(data, {
        type: "text/csv"
      });
  var url = URL.createObjectURL(blob);
  Mithril.setState(vnode, {
        TAG: "Done",
        blob: blob,
        url: url
      });
}

function trackMotion(vnode, info) {
  var match = vnode.state.data;
  if (typeof match !== "object" || match.TAG !== "Showing") {
    return ;
  } else {
    return Mithril.setState(vnode, {
                TAG: "Showing",
                motion: info.motion,
                counted_steps: info.steps + match.counted_steps | 0,
                counted_cycles: info.cycles,
                handler: match.handler
              });
  }
}

async function _startTracking(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object" && match.TAG !== "Showing") {
    $$URL.revokeObjectURL(match.url);
  }
  var match$1 = vnode.state.data;
  if (typeof match$1 === "object" && match$1.TAG === "Showing") {
    console.log("Tracker request ignored", vnode);
    return ;
  }
  var user = await State__User.getCurrent();
  await StepCounter.start(user);
  var handler = function (extra) {
    return trackMotion(vnode, extra);
  };
  StepCounter.addListener(handler);
  return Mithril.setState(vnode, {
              TAG: "Showing",
              motion: {
                x: undefined,
                y: undefined,
                z: undefined
              },
              counted_steps: 0,
              counted_cycles: 0,
              handler: handler
            });
}

function startTracking(vnode, param) {
  _startTracking(vnode);
}

function make$2(param) {
  return Mithril.view(Mithril.onremove(Mithril.component("Idle"), (function (__x) {
                    stopTracking(__x, undefined);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match === "object" && match.TAG === "Showing") {
                  var motion = match.motion;
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsx("h6", {
                                              children: "Motion ",
                                              style: {
                                                borderBottom: "1px #444 solid"
                                              }
                                            }),
                                        React.jsx(make$1, {
                                              data: [
                                                [
                                                  "Steps",
                                                  [match.counted_steps]
                                                ],
                                                [
                                                  "Cycles",
                                                  [match.counted_cycles]
                                                ],
                                                [
                                                  "X",
                                                  [motion.x]
                                                ],
                                                [
                                                  "Y",
                                                  [motion.y]
                                                ],
                                                [
                                                  "Z",
                                                  [motion.z]
                                                ]
                                              ]
                                            })
                                      ],
                                      style: {
                                        padding: "0.5rem",
                                        borderRadius: "0.5rem"
                                      }
                                    }),
                                ReactDOM.jsx("div", {
                                      className: "divider blank xl"
                                    }),
                                ReactDOM.jsx("button", {
                                      children: "Stop and download",
                                      className: "btn btn-block",
                                      style: {
                                        height: "2.5rem"
                                      },
                                      onClick: (function (extra) {
                                          return stopTracking(vnode, extra);
                                        })
                                    })
                              ]
                            });
                }
                var match$1 = vnode.state.data;
                var tmp;
                tmp = typeof match$1 !== "object" || match$1.TAG === "Showing" ? Mithril.empty : ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("div", {
                                className: "divider blank xl"
                              }),
                          ReactDOM.jsx("a", {
                                children: "Download tracked data",
                                href: match$1.url
                              })
                        ],
                        className: "text-small text-center"
                      });
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h6", {
                                            children: "Motion ",
                                            style: {
                                              borderBottom: "1px #444 solid"
                                            }
                                          }),
                                      React.jsx(make$1, {
                                            data: [
                                              [
                                                "Steps",
                                                [undefined]
                                              ],
                                              [
                                                "Cycles",
                                                [undefined]
                                              ],
                                              [
                                                "X",
                                                [undefined]
                                              ],
                                              [
                                                "Y",
                                                [undefined]
                                              ],
                                              [
                                                "Z",
                                                [undefined]
                                              ]
                                            ]
                                          }),
                                      ReactDOM.jsx("div", {
                                            style: {
                                              backgroundColor: "rgba(90, 90, 90, 0.7)",
                                              bottom: "0",
                                              left: "0",
                                              position: "absolute",
                                              right: "0",
                                              top: "0",
                                              borderRadius: "0.5rem"
                                            }
                                          })
                                    ],
                                    style: {
                                      padding: "0.5rem",
                                      position: "relative",
                                      borderRadius: "0.5rem"
                                    }
                                  }),
                              tmp,
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  }),
                              ReactDOM.jsx("button", {
                                    children: "Track orientation and motion",
                                    className: "btn btn-block",
                                    style: {
                                      height: "2.5rem"
                                    },
                                    onClick: (function (extra) {
                                        _startTracking(vnode);
                                      })
                                  })
                            ]
                          });
              }));
}

var StepCounterView = {
  stopTracking: stopTracking,
  trackMotion: trackMotion,
  _startTracking: _startTracking,
  startTracking: startTracking,
  make: make$2
};

function DevelopersArena$SettingsView(props) {
  var data = [
    [
      "KAIKO_API_HOST",
      Settings.apiHost
    ],
    [
      "API VERSION",
      Settings.apiVersion
    ],
    [
      "KAIKO_RELEASE_TAG",
      Settings.releaseVersion
    ],
    [
      "KAIKO_RELEASE_HASH",
      Settings.releaseLocalHash
    ],
    [
      "MIXPANEL_TOKEN",
      Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, import.meta.env.MIXPANEL_TOKEN, (function (param) {
                  return "[redacted]";
                })), "N/A")
    ],
    [
      "MIXPANEL_API_HOST",
      Prelude.default(import.meta.env.MIXPANEL_API_HOST, "N/A")
    ],
    [
      "KAIKO_MOVEMENT_TIMEOUT_IN_MINUTES",
      String(Settings.movementTimeoutInMs / 60000 | 0)
    ],
    [
      "SENTRY_DSN",
      Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Settings.sentryDSN, (function (param) {
                  return "[redacted]";
                })), "N/A")
    ],
    [
      "SENTRY_ENVIRONMENT",
      Settings.sentryEnvironment
    ],
    [
      "KAIKO_DEV_DEBUG",
      Settings.DeveloperDebug.active ? "Yes" : "No"
    ],
    [
      "KAIKO_DEV_SKIP_THUMBNAILS",
      Settings.DeveloperDebug.skipThumbnails ? "Yes" : "No"
    ],
    [
      "KAIKO_DEV_AUTOMATIC_RELOAD",
      Settings.DeveloperDebug.automaticReload ? "Yes" : "No"
    ]
  ];
  var thStyle = {
    borderRight: "1px #444 solid",
    fontFamily: "monospace",
    paddingRight: "0.7rem",
    textAlign: "right",
    width: "13rem"
  };
  var tdStyle = {
    fontFamily: "monospace",
    paddingLeft: "0.7rem",
    textAlign: "\".\" center"
  };
  return Mithril.view(Mithril.component(), (function (param) {
                return ReactDOM.jsx("table", {
                            children: ReactDOM.jsx("tbody", {
                                  children: data.map(function (param) {
                                        return ReactDOM.jsxs("tr", {
                                                    children: [
                                                      ReactDOM.jsx("th", {
                                                            children: param[0],
                                                            style: thStyle
                                                          }),
                                                      ReactDOM.jsx("td", {
                                                            children: ReactDOM.jsx("span", {
                                                                  children: param[1],
                                                                  style: {
                                                                    maxWidth: "100%",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                  }
                                                                }),
                                                            style: tdStyle
                                                          })
                                                    ]
                                                  });
                                      })
                                }),
                            style: {
                              borderCollapse: "collapse",
                              fontFamily: "monospace",
                              margin: "0 auto"
                            }
                          });
              }));
}

var SettingsView = {
  make: DevelopersArena$SettingsView
};

function DevelopersArena$VesselSettingsView(props) {
  var v = State__Memory.Current.get().vessel;
  var data = v !== undefined ? [
      [
        "Use Embedded Camera",
        Vessel.getCameraConf(v)
      ],
      [
        "Resolution",
        v.max_pic_resolution
      ],
      [
        "Picture Prompts",
        String(v.picture_prompt_every_n_inspections)
      ],
      [
        "Mandatory Finding Deadline",
        v.mandatory_findings_deadline ? "Yes" : "No"
      ],
      [
        "Mandatory Finding Observation",
        v.mandatory_findings_observations ? "Yes" : "No"
      ]
    ] : [];
  var thStyle = {
    borderRight: "1px #444 solid",
    fontFamily: "monospace",
    paddingRight: "0.7rem",
    textAlign: "right",
    width: "13rem"
  };
  var tdStyle = {
    fontFamily: "monospace",
    paddingLeft: "0.7rem",
    textAlign: "\".\" center"
  };
  return Mithril.view(Mithril.component(), (function (param) {
                return ReactDOM.jsx("table", {
                            children: ReactDOM.jsx("tbody", {
                                  children: data.map(function (param) {
                                        return ReactDOM.jsxs("tr", {
                                                    children: [
                                                      ReactDOM.jsx("th", {
                                                            children: param[0],
                                                            style: thStyle
                                                          }),
                                                      ReactDOM.jsx("td", {
                                                            children: ReactDOM.jsx("span", {
                                                                  children: param[1],
                                                                  style: {
                                                                    maxWidth: "100%",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                  }
                                                                }),
                                                            style: tdStyle
                                                          })
                                                    ]
                                                  });
                                      })
                                }),
                            style: {
                              borderCollapse: "collapse",
                              fontFamily: "monospace",
                              margin: "0 auto"
                            }
                          });
              }));
}

var VesselSettingsView = {
  make: DevelopersArena$VesselSettingsView
};

function DevelopersArena(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(PageLayout.make, {
                            children: Caml_option.some(ReactDOM.jsx("div", {
                                      children: React.jsxs(DevelopersArenaWrapper.make, {
                                            children: [
                                              React.jsx(DevelopersArena$VesselCleaner, {}),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              React.jsx(DevelopersArena$InspectionsCleaner, {}),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              React.jsx(DevelopersArena$LocalFileCleaner, {}),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              React.jsx(DevelopersArena$InspectionCreator, {}),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              React.jsx(DevelopersArena$AnswersCreator, {}),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              React.jsx(make$2, {}),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              React.jsx(DevelopersArena$SettingsView, {}),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              React.jsx(DevelopersArena$VesselSettingsView, {})
                                            ]
                                          }),
                                      style: {
                                        margin: "0 auto",
                                        padding: "0.5rem 0",
                                        width: "95%"
                                      }
                                    }))
                          });
              }));
}

var make$3 = DevelopersArena;

export {
  InspectionCreator ,
  AnswersCreator ,
  TimeProtectedButton ,
  LocalFileCleaner ,
  InspectionsCleaner ,
  VesselCleaner ,
  FloatsTable ,
  StepCounterView ,
  SettingsView ,
  VesselSettingsView ,
  make$3 as make,
}
/* URL Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "./rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "./rescript-mithril/src/ReactDOM.js";

function isNotEmpty(str) {
  return str.length > 0;
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var id = vnode.attrs.id;
                var options = Curry._2(Prelude.$$Array.keep, vnode.attrs.options.map(function (x) {
                            return x.trim();
                          }), isNotEmpty).map(function (value) {
                      return ReactDOM.jsx("option", {
                                  value: value
                                });
                    });
                if (options.length !== 0) {
                  return ReactDOM.jsx("datalist", {
                              children: options,
                              id: id
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

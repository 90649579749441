// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "body",
      "String"
    ],
    [
      "author",
      "String"
    ],
    [
      "profile_id",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "timestamp",
      "Date"
    ]
  ]
};

var CommentDeserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var url = "/tree/findings";

var fields$1 = [
  [
    "id",
    "String"
  ],
  [
    "vessel_id",
    "String"
  ],
  [
    "created_by_id",
    "String"
  ],
  [
    "solved_by_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "level_id",
    "String"
  ],
  [
    "deadline",
    {
      TAG: "Optional",
      _0: "Date"
    }
  ],
  [
    "comments",
    {
      TAG: "Collection",
      _0: CommentDeserializer
    }
  ]
];

var Definition = {
  url: url,
  fields: fields$1
};

var include = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields$1,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var get = include.get;

var options = include.options;

export {
  CommentDeserializer ,
  Definition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  get ,
  options ,
}
/* CommentDeserializer Not a pure module */

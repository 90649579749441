// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Luxon from "luxon";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as R4SChecklistId from "./R4SChecklistId.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "code",
      "String"
    ],
    [
      "description",
      "String"
    ],
    [
      "order",
      "String"
    ],
    [
      "detention_type",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "level_id",
      "String"
    ],
    [
      "level_path",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Item = {
  Deserializer: Deserializer
};

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofLevels(checklists, levels) {
  return Curry._2(Prelude.$$Array.keep, checklists, (function (chk) {
                var level_ids = levels.map(function (l) {
                      return l.id;
                    });
                if (Prelude.$$Array.includes(level_ids, chk.level_id)) {
                  return true;
                } else {
                  return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, chk.items, (function (item) {
                                    return Prelude.$$Array.includes(level_ids, item.level_id);
                                  })));
                }
              }));
}

function getName(checklist) {
  return checklist.port + ", " + checklist.country;
}

function getShortName(checklist) {
  return checklist.port + ", " + Prelude.$$Array.firstUnsafe(Luxon.DateTime.fromJSDate(checklist.eta).setZone("UTC").toString().split("T"));
}

function filterItemsByType(checklist, filter) {
  if (filter !== undefined) {
    return Curry._2(Prelude.$$Array.keep, checklist.items, (function (item) {
                  if (Prelude.$$Array.isEmpty(filter.value) || Caml_obj.equal(filter.value, [""])) {
                    return true;
                  } else {
                    return Prelude.$$Array.includes(filter.value, Prelude.default(item.detention_type, "None"));
                  }
                }));
  } else {
    return checklist.items;
  }
}

function getDetentionTypes(checklist) {
  var orderedTypes = [
    "CIC",
    "CSP",
    "None",
    "S10",
    "C10"
  ];
  var allTypes = Belt_SetString.toArray(Belt_SetString.fromArray(checklist.items.map(function (item) {
                return Prelude.default(item.detention_type, "None");
              })));
  var detTypes = Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keep, allTypes, (function (det) {
              return det.startsWith("DET");
            })), (function (a) {
          return a;
        }), true);
  return Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, detTypes, Curry._2(Prelude.$$Array.keep, orderedTypes, (function (t) {
                        return Prelude.$$Array.includes(allTypes, t);
                      }))), Curry._2(Prelude.$$Array.keep, allTypes, (function (t) {
                    return !(Prelude.$$Array.includes(orderedTypes, t) || Prelude.$$Array.includes(detTypes, t));
                  })));
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Item ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  ofLevels ,
  getName ,
  getShortName ,
  filterItemsByType ,
  getDetentionTypes ,
}
/* Deserializer Not a pure module */

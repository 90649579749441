// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Inspection from "../../survey/Inspection.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as Section_Components_Procedure from "./Section_Components_Procedure.js";

function load(vnode) {
  var procedure = vnode.attrs.procedure;
  var oldInspectionsOfProcedure = vnode.attrs.oldInspectionsOfProcedure;
  var newInspectionsOfProcedure = vnode.attrs.newInspectionsOfProcedure;
  var match = vnode.attrs.draftSubmission;
  var tmp;
  if (match !== undefined) {
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    tmp = Query.read((newrecord.newInspections = NewInspection.ofProcedure(procedure.id), newrecord.inspections = Inspection.ofProcedure(procedure.id), newrecord));
  } else {
    var newrecord$1 = Caml_obj.obj_dup(Query.makeResponse());
    tmp = Promise.resolve((newrecord$1.newInspections = newInspectionsOfProcedure(procedure.id), newrecord$1.inspections = oldInspectionsOfProcedure(procedure.id), newrecord$1));
  }
  Prelude.thenDo(tmp, (function (param) {
          Mithril.setState(vnode, {
                TAG: "Loaded",
                oldInspections: param.inspections,
                newInspections: param.newInspections
              });
        }));
}

function onInspectionCreated(vnode, procedure, inspection) {
  var callback = Prelude.default(vnode.attrs.onInspectionCreated, (function (a, b) {
          console.log("Inspection created", a, b);
        }));
  var match = vnode.state.data;
  if (typeof match === "object") {
    var newInspections = match.newInspections;
    Mithril.setState(vnode, {
          TAG: "Loaded",
          oldInspections: match.oldInspections,
          newInspections: inspection !== undefined ? Prelude.$$Array.append(newInspections, inspection) : newInspections
        });
  }
  callback(procedure, inspection);
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), load), (function (vnode) {
                var procedure = vnode.attrs.procedure;
                var draftSubmission = vnode.attrs.draftSubmission;
                var companyFormRel = vnode.attrs.companyFormRel;
                var name = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.number, (function (number) {
                            return "Number " + String(number);
                          })), procedure.name);
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var newInspections = match.newInspections;
                  var oldInspections = match.oldInspections;
                  var match$1 = procedure.question_type;
                  tmp = match$1 === "PhotoAlbumQuestion" || match$1 === "RatingQuestion" ? React.jsxKeyed(Section_Components_Procedure.Picture.make, {
                          procedure: procedure,
                          name: name,
                          oldInspections: oldInspections,
                          newInspections: newInspections,
                          draftSubmission: draftSubmission,
                          companyFormRel: companyFormRel,
                          counterOverlayClassName: counterOverlayClassName
                        }, procedure.id, undefined) : (
                      match$1 === "WeightDeltaQuestion" ? React.jsxKeyed(Section_Components_Procedure.WeightMeasurement.make, {
                              procedure: procedure,
                              name: name,
                              oldInspections: oldInspections,
                              newInspections: newInspections,
                              draftSubmission: draftSubmission,
                              companyFormRel: companyFormRel,
                              counterOverlayClassName: counterOverlayClassName
                            }, procedure.id, undefined) : (
                          match$1 === "YesNoQuestion" || match$1 === "OkNotOkQuestion" ? React.jsxKeyed(Section_Components_Procedure.Question.make, {
                                  procedure: procedure,
                                  name: name,
                                  oldInspections: oldInspections,
                                  newInspections: newInspections,
                                  onInspectionCreated: (function (extra, extra$1) {
                                      return onInspectionCreated(vnode, extra, extra$1);
                                    }),
                                  onChangeProcedure: (function (extra) {
                                      var callback = Prelude.default(vnode.attrs.onChangeProcedure, (function (x) {
                                              console.log("Procedure updated", x);
                                            }));
                                      return callback(extra);
                                    }),
                                  draftSubmission: draftSubmission,
                                  companyFormRel: companyFormRel,
                                  counterOverlayClassName: counterOverlayClassName
                                }, procedure.id, undefined) : (
                              match$1 === "TextQuestion" ? React.jsxKeyed(Section_Components_Procedure.InlineText.make, {
                                      procedure: procedure,
                                      name: name,
                                      oldInspections: oldInspections,
                                      newInspections: newInspections,
                                      onInspectionCreated: (function (extra, extra$1) {
                                          return onInspectionCreated(vnode, extra, extra$1);
                                        }),
                                      draftSubmission: draftSubmission,
                                      companyFormRel: companyFormRel,
                                      counterOverlayClassName: counterOverlayClassName
                                    }, procedure.id, undefined) : (
                                  match$1 === "DateQuestion" ? React.jsxKeyed(Section_Components_Procedure.InlineDate.make, {
                                          procedure: procedure,
                                          name: name,
                                          oldInspections: oldInspections,
                                          newInspections: newInspections,
                                          onInspectionCreated: (function (extra, extra$1) {
                                              return onInspectionCreated(vnode, extra, extra$1);
                                            }),
                                          draftSubmission: draftSubmission,
                                          companyFormRel: companyFormRel,
                                          counterOverlayClassName: counterOverlayClassName
                                        }, procedure.id, undefined) : (
                                      match$1 === "SignatureQuestion" ? React.jsxKeyed(Section_Components_Procedure.Signature.make, {
                                              procedure: procedure,
                                              name: name,
                                              oldInspections: oldInspections,
                                              newInspections: newInspections,
                                              draftSubmission: draftSubmission,
                                              companyFormRel: companyFormRel,
                                              counterOverlayClassName: counterOverlayClassName
                                            }, procedure.id, undefined) : React.jsxKeyed(Section_Components_Procedure.InlineInteger.make, {
                                              procedure: procedure,
                                              name: name,
                                              oldInspections: oldInspections,
                                              newInspections: newInspections,
                                              onInspectionCreated: (function (extra, extra$1) {
                                                  return onInspectionCreated(vnode, extra, extra$1);
                                                }),
                                              draftSubmission: draftSubmission,
                                              companyFormRel: companyFormRel,
                                              counterOverlayClassName: counterOverlayClassName
                                            }, procedure.id, undefined)
                                    )
                                )
                            )
                        )
                    );
                }
                return ReactDOM.jsx("div", {
                            children: tmp,
                            id: ProcedureId.toHTMLID(procedure.id)
                          });
              }));
}

export {
  load ,
  make ,
}
/* Query Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ApiMaker from "../../../libs/ApiMaker.js";

var fields = [[
    "id",
    "String"
  ]];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/survey/inspection-procedures"
    });

function post(user, procedures) {
  return Impl.postMany(undefined, user, procedures.map(function (procedure) {
                  return {
                          id: procedure.id,
                          not_applicable_status: procedure.not_applicable_status
                        };
                }));
}

export {
  Impl ,
  post ,
}
/* Impl Not a pure module */

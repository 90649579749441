// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var _map = {"error":"error","success":"success","warning":"warning"};

function css_classToJs(param) {
  return param;
}

function css_classFromJs(param) {
  return _map[param];
}

var deserCssClassFromJS = $$JSON.Field.variadicString("Expected CSS class (error, success, warning)", (function (x) {
        return _map[x];
      }));

var Identifier = Utils.MakeOpaqueIdentifier({});

var ModelDefinition = {};

var include = ReIndexed.MakeIdModel(ModelDefinition, {});

var $$undefined = {
  label: "Undefined",
  css_class: undefined,
  description: "Not yet set"
};

var rating_1 = {
  label: "Out of order",
  css_class: "error",
  description: "Part needs to be serviced immediately"
};

var rating_2 = {
  label: "Poor",
  css_class: "error",
  description: ">20% corrosion/pitting or bigger cracks"
};

var rating_3 = {
  label: "Fair",
  css_class: "warning",
  description: "<20% corrosion/pitting or minor cracks"
};

var rating_4 = {
  label: "Good",
  css_class: "success",
  description: "Little corrosion/pitting and no cracks"
};

var rating_5 = {
  label: "Excellent",
  css_class: "success",
  description: "No corrosion/pitting, no cracks"
};

var Defaults = {
  $$undefined: $$undefined,
  rating_1: rating_1,
  rating_2: rating_2,
  rating_3: rating_3,
  rating_4: rating_4,
  rating_5: rating_5
};

var defaults = [
  rating_1,
  rating_2,
  rating_3,
  rating_4,
  rating_5
];

var extdefaults = [
  $$undefined,
  rating_1,
  rating_2,
  rating_3,
  rating_4,
  rating_5
];

function extend(rules) {
  return [
          $$undefined,
          rules[0],
          rules[1],
          rules[2],
          rules[3],
          rules[4]
        ];
}

function color(rule) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, rule.css_class, (function (prim) {
                    return prim;
                  })), "secunday");
}

var StylingRule_clear = include.clear;

var StylingRule_save = include.save;

var StylingRule_delete = include.$$delete;

var StylingRule_remove = include.remove;

var StylingRule_IdComparator = include.IdComparator;

var StylingRule_Id = include.Id;

var StylingRule = {
  css_classToJs: css_classToJs,
  css_classFromJs: css_classFromJs,
  deserCssClassFromJS: deserCssClassFromJS,
  Identifier: Identifier,
  ModelDefinition: ModelDefinition,
  clear: StylingRule_clear,
  save: StylingRule_save,
  $$delete: StylingRule_delete,
  remove: StylingRule_remove,
  IdComparator: StylingRule_IdComparator,
  Id: StylingRule_Id,
  Defaults: Defaults,
  defaults: defaults,
  extdefaults: extdefaults,
  extend: extend,
  color: color
};

function fromJs(d) {
  switch (d) {
    case 0 :
        return 0;
    case 1 :
        return 1;
    case 2 :
        return 2;
    case 3 :
        return 3;
    case 4 :
        return 4;
    case 5 :
        return 5;
    default:
      return ;
  }
}

var deserFromJS = $$JSON.Field.variadicInt("Expected rating: 0-5", fromJs);

function describe(rating, rules) {
  var rules$1 = extend(Prelude.default(rules, defaults));
  var rule = Prelude.default(Curry._2(Prelude.$$Array.get, rules$1, rating), $$undefined);
  return {
          number: rating,
          name: rule.label,
          text: Prelude.default(rule.description, ""),
          color: color(rule)
        };
}

var descriptions = [
    1,
    2,
    3,
    4,
    5
  ].map(function (rating) {
      return [
              rating,
              describe(rating, undefined)
            ];
    });

export {
  StylingRule ,
  fromJs ,
  deserFromJS ,
  describe ,
  descriptions ,
}
/* deserCssClassFromJS Not a pure module */

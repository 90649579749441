// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function nullifyFile(attachment) {
  return {
          id: attachment.id,
          file: undefined,
          role: attachment.role
        };
}

function message(param, param$1) {
  return "Optimizing inspections attachments";
}

function $$process(inspection) {
  return {
          id: inspection.id,
          procedure_id: inspection.procedure_id,
          component_id: inspection.component_id,
          section_id: inspection.section_id,
          vessel_id: inspection.vessel_id,
          inspector_id: inspection.inspector_id,
          rating: inspection.rating,
          timestamp: inspection.timestamp,
          code: inspection.code,
          annotations: inspection.annotations,
          additional_annotation: inspection.additional_annotation,
          picture_prompted: inspection.picture_prompted,
          walked_steps: inspection.walked_steps,
          app_version: inspection.app_version,
          deficiency_id: inspection.deficiency_id,
          attachments: inspection.attachments.map(nullifyFile)
        };
}

var NewInspectionsRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "newInspections",
      message: message,
      $$process: $$process
    });

var DraftInspectionsRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "draftInspections",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Optimizing inspections attachments";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.bind(NewInspectionsRewriter.rewrite(transaction), (function () {
                return DraftInspectionsRewriter.rewrite(transaction);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* NewInspectionsRewriter Not a pure module */

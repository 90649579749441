// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Image from "../../../libs/Image.js";
import * as Vessel from "../../vessel/Vessel.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as VesselStaff from "../../vessel/VesselStaff.js";

var url = "/fleet/vessels";

var fields = [
  [
    "id",
    "String"
  ],
  [
    "name",
    "String"
  ],
  [
    "max_pic_resolution",
    {
      TAG: "DefaultWhenInvalid",
      _0: $$Image.Resolution.deserFromJS,
      _1: "FHD"
    }
  ],
  [
    "picture_prompt_every_n_inspections",
    {
      TAG: "OptionalWithDefault",
      _0: "Int",
      _1: 25
    }
  ],
  [
    "company_logo",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "staff",
    {
      TAG: "Collection",
      _0: VesselStaff.Deserializer
    }
  ],
  [
    "migrated",
    {
      TAG: "OptionalWithDefault",
      _0: "Boolean",
      _1: false
    }
  ],
  [
    "mandatory_findings_deadline",
    {
      TAG: "OptionalWithDefault",
      _0: "Boolean",
      _1: false
    }
  ],
  [
    "mandatory_findings_observations",
    {
      TAG: "OptionalWithDefault",
      _0: "Boolean",
      _1: false
    }
  ],
  [
    "hide_observations",
    {
      TAG: "OptionalWithDefault",
      _0: "Boolean",
      _1: false
    }
  ],
  [
    "enable_psc",
    {
      TAG: "OptionalWithDefault",
      _0: "Boolean",
      _1: false
    }
  ],
  [
    "use_embedded_camera",
    {
      TAG: "DefaultWhenInvalid",
      _0: Vessel.EmbeddedCameraConfig.deserFromJS,
      _1: Vessel.EmbeddedCameraConfig.$$default
    }
  ]
];

var FleetVesselAPIDefinition = {
  url: url,
  fields: fields
};

var include = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var get = include.get;

var options = include.options;

export {
  FleetVesselAPIDefinition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  get ,
  options ,
}
/* include Not a pure module */

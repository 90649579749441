// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ApiMaker from "../../../libs/ApiMaker.js";

var fields = [[
    "id",
    "String"
  ]];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/tree/submissions"
    });

function post(params, user, submissions) {
  return Impl.postMany(params, user, submissions.map(function (submission) {
                  return {
                          id: submission.id,
                          name: submission.name,
                          level: submission.level_id,
                          timestamp: submission.timestamp.toISOString(),
                          target_date: submission.target_date.toISOString(),
                          answers: submission.answers
                        };
                }));
}

export {
  Impl ,
  post ,
}
/* Impl Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Sire2LevelStatus from "../Sire2LevelStatus.js";
import * as Sire2NominalFilters from "../Sire2NominalFilters.js";
import * as Sire2CompletionStatusQueue from "./Sire2CompletionStatusQueue.js";

async function load(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var match$1 = vnode.attrs;
  var filters = match$1.filters;
  var level = match$1.level;
  var match$2 = await Sire2CompletionStatusQueue.append(function () {
        return Sire2LevelStatus.SireLevelState.getLevelCompletionStatus(level.vessel_id, level.id, Sire2NominalFilters.getStateFilters(filters));
      });
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              _0: {
                level: level,
                questionsCount: match$2.questionsCount,
                answersCount: match$2.answersCount,
                filters: filters
              }
            });
}

function make(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), load), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return ReactDOM.jsx("div", {
                              className: "tw-min-h-[30px] tw-min-w-[30px]"
                            });
                }
                var match$1 = match._0;
                var answersCount = match$1.answersCount;
                var questionsCount = match$1.questionsCount;
                return ReactDOM.jsx("div", {
                            children: questionsCount !== answersCount ? ReactDOM.jsx("div", {
                                    children: answersCount.toString() + "/" + questionsCount.toString()
                                  }) : React.jsx(Feather.make, {
                                    icon: "check_circle",
                                    className: "text-success",
                                    size: 22
                                  }),
                            className: "tw-text-center tw-min-h-[30px] tw-min-w-[30px]"
                          });
              }));
}

export {
  load ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_math from "rescript/lib/es6/js_math.js";

function _hex(number, targetLength) {
  return number.toString(16).padStart(targetLength, "0");
}

function _random(bits) {
  return Math.floor(Math.random() * Math.pow(2.0, bits));
}

function _chooseFrom(choices) {
  return choices[Js_math.random_int(0, choices.length)];
}

var versionMarker = [
  "8",
  "9",
  "a",
  "b"
];

function make() {
  var ms = new Date().getTime();
  var timestamp = _hex(ms, 12);
  return timestamp.substr(0, 8) + "-" + timestamp.substr(8, 12) + "-7" + _hex(_random(12), 3) + "-" + _chooseFrom(versionMarker) + _hex(_random(12), 3) + "-" + _hex(_random(48), 12);
}

export {
  _hex ,
  _random ,
  _chooseFrom ,
  versionMarker ,
  make ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Sire2VettingPeriodId from "./Sire2VettingPeriodId.js";

var _map = {"all":"all","core":"core","rotational1":"rotational1","rotational2":"rotational2"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var Sire2QuestionSet = {
  tToJs: tToJs,
  tFromJs: tFromJs
};

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Sire2QuestionSet ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
}
/* include Not a pure module */

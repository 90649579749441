// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

var Queue = ActionQueue.MakeActionQueue({
      name: "Completion Update Throttle"
    });

var queue = Queue.make(true, 3, false);

function append(action) {
  return Core__Option.getExn(Queue.append(queue, action, undefined), undefined);
}

function clear() {
  Queue.clear(queue);
}

export {
  Queue ,
  append ,
  clear ,
}
/* Queue Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as State__User from "../../state/State__User.js";
import * as State__Memory from "../../state/State__Memory.js";

function make(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("Checking"), (async function (vnode) {
                    var user = await State__User.getCurrent();
                    var requireNonCrew = Prelude.default(vnode.attrs.requireNonCrew, false);
                    var fallbackRoute = Prelude.default(vnode.attrs.fallbackRoute, "/login");
                    var requireProfile = Prelude.default(vnode.attrs.requireProfile, false);
                    var profileSelectorRoute = Prelude.default(vnode.attrs.profileSelectorRoute, "/landingpage/vessel");
                    if (user === undefined) {
                      return Mithril.setState(vnode, {
                                  TAG: "Disallowed",
                                  _0: fallbackRoute
                                });
                    }
                    if (!requireNonCrew && !requireProfile) {
                      return Mithril.setState(vnode, "Allowed");
                    }
                    var match = State__Memory.Current.get();
                    var is_crew = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.role, (function (r) {
                                return r === "crew";
                              })), true);
                    if (!requireNonCrew || !is_crew) {
                      if (!requireProfile || Curry._1(Prelude.OptionExported.$$Option.isSome, match.profile)) {
                        return Mithril.setState(vnode, "Allowed");
                      } else {
                        return Mithril.setState(vnode, {
                                    TAG: "Disallowed",
                                    _0: profileSelectorRoute
                                  });
                      }
                    } else {
                      return Mithril.setState(vnode, {
                                  TAG: "Disallowed",
                                  _0: fallbackRoute
                                });
                    }
                  })), (function (vnode) {
                var state = vnode.state.data;
                if (typeof state !== "object") {
                  if (state === "Checking") {
                    return Prelude.default(vnode.attrs.alt, Mithril.empty);
                  } else {
                    return vnode.children;
                  }
                }
                Mithril$1.route.set(state._0);
                return Mithril.empty;
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

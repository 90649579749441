// Generated by ReScript, PLEASE EDIT WITH CARE

import * as State__User from "./State__User.js";
import * as UserProfileAPI from "../api/endpoints/UserProfileAPI.js";

function upload(profiles) {
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return UserProfileAPI.post(__x, profiles);
                }));
}

var User;

var LoggedUser;

export {
  User ,
  upload ,
  LoggedUser ,
}
/* State__User Not a pure module */

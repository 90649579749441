// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as StorageUnits from "../../../libs/StorageUnits.js";
import * as UploadRequest from "../upload/UploadRequest.js";
import * as FutureAttachment from "../../attachment_chunks/FutureAttachment.js";

function init(vnode) {
  var match = vnode.attrs;
  var attachments = Curry._2(Prelude.$$Array.keepMap, match.requests, UploadRequest.getFutureAttachment);
  Mithril.setState(vnode, {
        TAG: "Loaded",
        attachments: attachments
      });
}

function valid(param) {
  return true;
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), init), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var attachments = match.attachments;
                var len = attachments.length;
                if (len !== 1) {
                  if (len === 0) {
                    return Mithril.empty;
                  }
                  var size = attachments.reduce((function (s, c) {
                          return s + FutureAttachment.pendingSize(c);
                        }), 0.0);
                  var count = attachments.length;
                  return [
                          ReactDOM.jsx("h4", {
                                children: "Pending files",
                                className: "mt-3 text-center"
                              }),
                          ReactDOM.jsx("div", {
                                children: [
                                  "There are " + String(count) + " pending files from past uploads: ",
                                  StorageUnits.toString(StorageUnits.toBest({
                                            TAG: "Bytes",
                                            _0: size
                                          }))
                                ],
                                className: "text-tiny text-center"
                              })
                        ];
                }
                var attachment = attachments[0];
                var size$1 = FutureAttachment.pendingSize(attachment);
                return [
                        ReactDOM.jsx("h4", {
                              children: "Pending files",
                              className: "mt-3 text-center"
                            }),
                        ReactDOM.jsx("div", {
                              children: [
                                "There is a pending file from past uploads: ",
                                StorageUnits.toString(StorageUnits.toBest({
                                          TAG: "Bytes",
                                          _0: size$1
                                        }))
                              ],
                              className: "text-tiny text-center"
                            })
                      ];
              }));
}

export {
  init ,
  valid ,
  make ,
}
/* Mithril Not a pure module */

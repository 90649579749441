// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing custom compression setting";
}

function $$process(vessel) {
  return {
          id: vessel.id,
          name: vessel.name,
          max_pic_resolution: 1920
        };
}

var VesselRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "vessels",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing custom compression setting";
}

function migrate(param, t) {
  return VesselRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* VesselRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing findings options";
}

function $$process(finding) {
  return {
          id: finding.id,
          vessel_id: finding.vessel_id,
          created_by_id: finding.created_by_id,
          solved_by_id: finding.solved_by_id,
          level_id: finding.level_id,
          question_id: finding.question_id,
          status: finding.status,
          deadline: undefined,
          comments: []
        };
}

var FindingRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "findings",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing findings options";
}

function migrate(param, t) {
  return FindingRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* FindingRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../../Settings.js";
import * as AnswerAPI from "../endpoints/Answers/AnswerAPI.js";
import * as AppSchema from "../../AppSchema.js";
import * as AnswerValue from "../../tree/AnswerValue.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

var InvalidAnswerPayload = /* @__PURE__ */Caml_exceptions.create("ServerSyncAnswers.ReadServerState.InvalidAnswerPayload");

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var questions = AppSchema.Questions.all($$final);
  if (Prelude.$$Array.isEmpty(questions)) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  } else {
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bind(AnswerAPI.getAll(undefined, {
                        vessel__id__in: vessels.join(",")
                      }, user), (function (items) {
                      var parsed = Prelude.ManyResults.bail(Curry._2(Prelude.$$Array.keepMap, items, (function (item) {
                                  return Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, AppSchema.Questions.get($$final, item.question_id), (function (question) {
                                                    return Curry._2(Prelude.OptionExported.$$Option.map, AppSchema.Levels.get($$final, item.level_id), (function (level) {
                                                                  return [
                                                                          level,
                                                                          question
                                                                        ];
                                                                }));
                                                  })), (function (param) {
                                                var question = param[1];
                                                var level = param[0];
                                                return Curry._2(Prelude.Result.map, AnswerValue.Deserializer.fromJSON(question.kind, item.value), (function (parsed) {
                                                              return [
                                                                      item,
                                                                      parsed,
                                                                      level,
                                                                      question
                                                                    ];
                                                            }));
                                              }));
                                })));
                      if (parsed.TAG !== "Ok") {
                        return Prelude.rejectWithError({
                                    RE_EXN_ID: InvalidAnswerPayload,
                                    code: 500,
                                    response: {},
                                    message: parsed._0
                                  });
                      }
                      var answers = parsed._0.map(function (param) {
                            var item = param[0];
                            return {
                                    id: item.id,
                                    level_id: item.level_id,
                                    vessel_id: item.vessel_id,
                                    question_id: item.question_id,
                                    user_id: item.user_id,
                                    profile_id: item.profile_id,
                                    value: param[1],
                                    timestamp: item.timestamp,
                                    question_type: param[3].kind,
                                    level_path: param[2].path,
                                    remarks: item.remarks,
                                    walked_steps: item.walked_steps,
                                    evidences: item.evidences,
                                    picture_prompted: item.picture_prompted,
                                    app_version: Settings.releaseVersion,
                                    sire2_vetting_period_id: item.sire2_vetting_period_id
                                  };
                          });
                      Curry._2(Prelude.$$Array.forEach, answers, (function (extra) {
                              return AppSchema.Answers.update($$final, extra);
                            }));
                      return Promise.resolve({
                                  TAG: "Ok",
                                  _0: $$final
                                });
                    })), (function ($$final) {
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final
                        };
                }));
  }
}

var ReadServerState = {
  InvalidAnswerPayload: InvalidAnswerPayload,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.answers = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.answers, (function (extra) {
                        return AppSchema.Answers.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.answers = AppSchema.Answers.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncAnswers", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.Answers];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncAnswers",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LocalFile from "../../../../libs/LocalFile.js";
import * as State__User from "../../../state/State__User.js";
import * as AttachmentId from "../../../attachment_chunks/AttachmentId.js";
import * as UploadErrors from "../UploadErrors.js";
import * as AttachmentAPI from "../../../api/endpoints/AttachmentAPI.js";
import * as UploadPayload from "../UploadPayload.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Browser from "@sentry/browser";
import * as FutureAttachmentAPI from "../../../api/endpoints/FutureAttachmentAPI.js";
import * as FutureAttachmentChunkId from "../../../attachment_chunks/FutureAttachmentChunkId.js";

async function filterAttachments(payloads) {
  var attachments = UploadPayload.getFutureAttachments(payloads).map(function (attachment) {
        return attachment.id;
      });
  if (attachments.length === 0) {
    return {
            TAG: "Ok",
            _0: payloads
          };
  }
  var user = await State__User.getCurrent();
  var e = await State__User.Auth.redirectIfUnauthorized(AttachmentAPI.Basic.getMany(undefined, undefined, undefined, user, attachments));
  if (e.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: e._0
          };
  }
  var found = Belt_SetString.fromArray(e._0.map(function (a) {
            return a.id;
          }));
  var foundFutures = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG !== "FutureAttachment") {
            return ;
          }
          var attachment = payload._1;
          var match = Belt_SetString.get(found, attachment.id);
          if (match !== undefined) {
            return attachment;
          }
          
        }));
  var chunkIds = Prelude.$$Array.flatMap(foundFutures, (function (a) {
            return a.allChunks;
          })).map(function (param) {
        return param.id;
      });
  var ids = foundFutures.map(function (a) {
        return a.id;
      });
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.futureAttachments = ids.map(function (id) {
              return {
                      TAG: "Delete",
                      _0: id
                    };
            }), newrecord));
  await LocalFile.deleteMany(chunkIds);
  var payloads$1 = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG !== "FutureAttachment") {
            return payload;
          }
          var match = Belt_SetString.get(found, payload._1.id);
          if (match !== undefined) {
            return ;
          } else {
            return payload;
          }
        }));
  return {
          TAG: "Ok",
          _0: payloads$1
        };
}

async function filterChunks(payloads) {
  var chunkIds = Prelude.$$Array.flatMap(UploadPayload.getFutureAttachments(payloads), (function (group) {
            return group.allChunks;
          })).map(function (param) {
        return param.id;
      });
  if (chunkIds.length === 0) {
    return {
            TAG: "Ok",
            _0: payloads
          };
  }
  var user = await State__User.getCurrent();
  var existingChunks = await State__User.Auth.redirectIfUnauthorized(FutureAttachmentAPI.getMany(undefined, undefined, undefined, user, chunkIds));
  if (existingChunks.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: existingChunks._0
          };
  }
  var match = Curry._2(Prelude.$$Array.partition, payloads, (function (p) {
          if (p.TAG === "FutureAttachment") {
            return true;
          } else {
            return false;
          }
        }));
  var groupPayloads = match[0];
  var found = FutureAttachmentChunkId.$$Set.fromArray(existingChunks._0.map(function (param) {
            return param.id;
          }));
  var groups = Curry._2(Prelude.$$Array.keepMap, groupPayloads, UploadPayload.getFutureAttachment);
  var groups$1 = groups.map(function (group) {
        var sentChunks = Curry._2(Prelude.$$Array.keep, group.allChunks, (function (c) {
                return FutureAttachmentChunkId.$$Set.has(found, c.id);
              }));
        var localChunks = Curry._2(Prelude.$$Array.keep, group.allChunks, (function (c) {
                return !FutureAttachmentChunkId.$$Set.has(found, c.id);
              }));
        return {
                id: group.id,
                vessel_id: group.vessel_id,
                allChunks: group.allChunks,
                localChunks: localChunks,
                sentChunks: sentChunks,
                checksum: group.checksum,
                checksum_algorithm: group.checksum_algorithm,
                size: group.size,
                payload: group.payload,
                mimetype: group.mimetype,
                created_at: group.created_at
              };
      });
  var match$1 = Curry._2(Prelude.$$Array.partition, groups$1, (function (group) {
          return Prelude.$$Array.isEmpty(group.localChunks);
        }));
  var partial = match$1[1];
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.futureAttachments = Curry._2(Prelude.$$Array.concat, partial.map(function (g) {
                  return {
                          TAG: "Save",
                          _0: g
                        };
                }), match$1[0].map(function (g) {
                  return {
                          TAG: "Delete",
                          _0: g.id
                        };
                })), newrecord));
  var index = AttachmentId.$$Map.fromArray(partial.map(function (g) {
            return [
                    g.id,
                    g
                  ];
          }));
  var groupPayloads$1 = Curry._2(Prelude.$$Array.keepMap, groupPayloads, (function (payload) {
          if (payload.TAG === "FutureAttachment") {
            var vessel = payload._0;
            return Curry._2(Prelude.OptionExported.$$Option.map, AttachmentId.$$Map.get(index, payload._1.id), (function (group) {
                          return {
                                  TAG: "FutureAttachment",
                                  _0: vessel,
                                  _1: group
                                };
                        }));
          }
          throw {
                RE_EXN_ID: Prelude.AssertionError,
                _1: "All chunk payload should be FutureAttachment variant",
                Error: new Error()
              };
        }));
  return {
          TAG: "Ok",
          _0: Curry._2(Prelude.$$Array.concat, match[1], groupPayloads$1)
        };
}

async function next(payloads) {
  var result = await filterAttachments(payloads);
  var result$1;
  result$1 = result.TAG === "Ok" ? await filterChunks(result._0) : ({
        TAG: "Error",
        _0: result._0
      });
  if (result$1.TAG === "Ok") {
    return {
            TAG: "Preparing",
            _0: result$1._0
          };
  }
  var msg = result$1._0;
  Browser.captureException(msg);
  return {
          TAG: "Done",
          _0: [{
              TAG: "Error",
              _0: [
                "GET",
                UploadErrors.GenericError.reason(msg)
              ]
            }]
        };
}

export {
  filterAttachments ,
  filterChunks ,
  next ,
}
/* Query Not a pure module */

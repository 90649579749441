// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../tree/Question.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.js";
import * as LevelStatus from "../tree/LevelStatus.js";
import * as UserProfile from "../accounts/UserProfile.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as State__Memory from "../state/State__Memory.js";
import * as WindowLocation from "../../libs/WindowLocation.js";
import * as LevelRiskStatus from "../tree/LevelRiskStatus.js";

function make(areas, risks, answered, ranks) {
  return {
          areas: Core__Option.getOr(Core__Option.map(areas, (function (ls) {
                      ls.sort(Caml_obj.compare);
                      return ls;
                    })), []),
          risks: Core__Option.getOr(Core__Option.map(risks, (function (ls) {
                      ls.sort(Caml_obj.compare);
                      return ls;
                    })), []),
          answered: Core__Option.getOr(Core__Option.map(answered, (function (ls) {
                      ls.sort(Caml_obj.compare);
                      return ls;
                    })), []),
          ranks: Core__Option.getOr(Core__Option.map(ranks, (function (ls) {
                      ls.sort(Caml_obj.compare);
                      return ls;
                    })), [])
        };
}

function fromURL() {
  var areas = Core__Option.map(Mithril.route.param("areas"), (function (p) {
          return Core__Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var answered = Core__Option.map(Mithril.route.param("answered"), (function (p) {
          return Core__Array.keepSome(p.split(",").map(function (x) {
                          return Question.Answered.fromJs(x);
                        }));
        }));
  var risks = Core__Option.map(Mithril.route.param("risks"), (function (p) {
          return Core__Array.keepSome(p.split(",").map(LevelRiskStatus.tFromJs));
        }));
  var ranks = Core__Option.map(Mithril.route.param("ranks"), (function (p) {
          return Core__Array.keepSome(p.split(",").map(function (x) {
                          return UserProfile.Rank.crewFromJs(x);
                        }));
        }));
  return make(areas, risks, answered, ranks);
}

function getStateFilters(filters) {
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var questionFilter = function (questions) {
    return questions.filter(function (q) {
                if (Question.filterByQuestionArea(q, filters.areas) && Question.filterByQuestionRank(q, currentProfile)) {
                  return Question.isVisibleForRanks(q, filters.ranks);
                } else {
                  return false;
                }
              });
  };
  var questionStatusFilter = function (questions) {
    var answeredQuestionIds = function (q) {
      if (Core__Option.isSome(q.lastAnswer) || Core__Option.isSome(q.lastNewAnswer)) {
        return [q.question.id];
      } else {
        return [];
      }
    };
    return questions.filter(function (q) {
                return Question.filterByAnswered(q.question, filters.answered, answeredQuestionIds(q));
              });
  };
  var childrenFilter = function (children) {
    return children.filter(function (l) {
                return LevelStatus.filterByRiskStatus(l, filters.risks);
              });
  };
  return LevelStatus.StateFilters.make(undefined, questionFilter, questionStatusFilter, childrenFilter);
}

function _updateParam(searchParameters, name, values, serialize) {
  if (values.length > 0) {
    searchParameters.set(name, values.map(function (x) {
                return serialize(x);
              }).join(","));
  } else {
    searchParameters.delete(name);
  }
}

function updateURL(filters, replaceOpt) {
  var replace = replaceOpt !== undefined ? replaceOpt : false;
  var search = WindowLocation.get().search;
  var searchParameters = new URLSearchParams(search);
  _updateParam(searchParameters, "areas", filters.areas, (function (x) {
          return Question.QuestionArea.tToJs(x);
        }));
  _updateParam(searchParameters, "risks", filters.risks, LevelRiskStatus.tToJs);
  _updateParam(searchParameters, "answered", filters.answered, (function (x) {
          return Question.Answered.tToJs(x);
        }));
  _updateParam(searchParameters, "ranks", filters.ranks, (function (x) {
          return x;
        }));
  var pathname = WindowLocation.get().pathname;
  Mithril.route.set(pathname, Js_dict.fromArray(Array.from(searchParameters.entries())), {
        replace: replace
      });
}

function count(filters) {
  return ((filters.areas.length + filters.ranks.length | 0) + filters.answered.length | 0) + filters.risks.length | 0;
}

function all(param) {
  return {
          areas: [],
          risks: [],
          answered: [],
          ranks: []
        };
}

var Defaults = {
  all: all
};

export {
  make ,
  fromURL ,
  getStateFilters ,
  _updateParam ,
  updateURL ,
  count ,
  Defaults ,
}
/* mithril Not a pure module */

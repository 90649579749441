// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";

var include = Utils.MakeOpaqueIdentifier({});

var make = include.make;

var $$null = include.$$null;

var zero = include.zero;

var toHTMLID = include.toHTMLID;

var $$Option = include.$$Option;

var Comparator = include.Comparator;

var Hasher = include.Hasher;

var $$Set = include.$$Set;

var MutableSet = include.MutableSet;

var $$Map = include.$$Map;

var Route = include.Route;

var $$Array = include.$$Array;

var SortArray = include.SortArray;

export {
  make ,
  $$null ,
  zero ,
  toHTMLID ,
  $$Option ,
  Comparator ,
  Hasher ,
  $$Set ,
  MutableSet ,
  $$Map ,
  Route ,
  $$Array ,
  SortArray ,
}
/* include Not a pure module */

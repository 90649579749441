// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Luxon from "luxon";
import * as Completion from "../common/Completion.js";
import * as StandardRecurrence from "../common/StandardRecurrence.js";

function completion(self) {
  if (self.finished) {
    return "completed";
  } else {
    return Completion.Status.fromCurrentDate(self.start_date, self.end_date);
  }
}

function dateRange(param) {
  var start = Luxon.DateTime.fromJSDate(param.start_date).setZone("UTC").setLocale("en").toLocaleString(Luxon.DateTime.DATE_MED_WITH_WEEKDAY);
  var end = Luxon.DateTime.fromJSDate(param.end_date).minus(Luxon.Duration.fromObject({
                  days: 1
                })).setZone("UTC").setLocale("en").toLocaleString(Luxon.DateTime.DATE_MED_WITH_WEEKDAY);
  return start + " - " + end;
}

function name(self) {
  var dateInUTC = Luxon.DateTime.fromJSDate(self.start_date).setZone("UTC").setLocale("en");
  var startYear = dateInUTC.toFormat("yyyy");
  var startMonth = self.start_date.getUTCMonth() | 0;
  var match = StandardRecurrence.classify(self.recurrence);
  if (match === undefined) {
    return dateInUTC.toFormat("DDD");
  }
  switch (match) {
    case 7 :
        return dateInUTC.toFormat("yyyy - 'Week' WW");
    case 28 :
        return dateInUTC.toFormat("yyyy - LLLL");
    case 84 :
        return dateInUTC.toFormat("yyyy - 'Q'q");
    case 168 :
        if (startMonth < 6) {
          return startYear + " - 1st Half";
        } else {
          return startYear + " - 2nd Half";
        }
    case 365 :
        return startYear;
    
  }
}

export {
  completion ,
  dateRange ,
  name ,
}
/* luxon Not a pure module */

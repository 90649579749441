// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.onremove(Mithril.oninit(Mithril.component("Init"), (function (vnode) {
                        var match = vnode.state.data;
                        if (typeof match === "object") {
                          return ;
                        }
                        if (match !== "Init") {
                          return ;
                        }
                        var delay = vnode.attrs.delay;
                        if (delay === undefined) {
                          return Mithril.setState(vnode, "Showing");
                        }
                        var timer = setTimeout((function () {
                                Mithril.setState(vnode, "Showing");
                              }), delay);
                        Mithril.setState(vnode, {
                              TAG: "Waiting",
                              _0: timer
                            });
                      })), (function (vnode) {
                    var timer = vnode.state.data;
                    if (typeof timer !== "object") {
                      return ;
                    }
                    clearTimeout(timer._0);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object" && match !== "Init") {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("p", {
                                          children: "Loading...",
                                          className: "mt-2 text-center",
                                          style: {
                                            width: "100%"
                                          }
                                        }),
                                    className: "loading loading-lg"
                                  }),
                              style: {
                                marginTop: "3rem",
                                width: "100%"
                              }
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function make(param) {
  return function (db, _transaction) {
    IDB__Migration__Database.createStandardStore(db, "draftCompanyFormSubmissions", [
          "vessel_id",
          "form_id"
        ]);
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Database Not a pure module */

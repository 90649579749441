// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as AnswerValue from "../../tree/AnswerValue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "../../accounts/UserProfile.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var answer = vnode.attrs.answer;
                var level = vnode.attrs.level;
                var question = vnode.attrs.question;
                var answerProfile = vnode.attrs.answerProfile;
                var allowResolve = vnode.attrs.allowResolve;
                var allowEdit = vnode.attrs.allowEdit;
                var vesselId = vnode.attrs.vesselId;
                var match = question.kind;
                var tmp;
                var exit = 0;
                if (typeof match === "object") {
                  var variant = match.NAME;
                  if (variant === "MultipleDatesQuestion" || variant === "MultipleIntegersQuestion" || variant === "MultipleTextsQuestion") {
                    var values = Prelude.default(AnswerValue.toHuman(answer.value), []);
                    tmp = match.VAL.labels.map(function (label, index) {
                          return ReactDOM.jsx("span", {
                                      children: label + ": " + Prelude.default(Curry._2(Prelude.$$Array.get, values, index), "None"),
                                      className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                    });
                        });
                  } else if (variant === "MultipleDatesTextsQuestion") {
                    var options = match.VAL;
                    var values$1 = Prelude.default(AnswerValue.toHuman(answer.value), []);
                    tmp = Curry._2(Prelude.$$Array.concat, options.dates_labels, options.texts_labels).map(function (label, index) {
                          return ReactDOM.jsx("span", {
                                      children: label + ": " + Prelude.default(Curry._2(Prelude.$$Array.get, values$1, index), "None"),
                                      className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                    });
                        });
                  } else if (variant === "MultipleDatesYesNoQuestion") {
                    var options$1 = match.VAL;
                    var values$2 = Prelude.default(AnswerValue.toHuman(answer.value), []);
                    tmp = Curry._2(Prelude.$$Array.concat, options$1.dates_labels, options$1.bool_labels).map(function (label, index) {
                          return ReactDOM.jsx("span", {
                                      children: label + ": " + Prelude.default(Curry._2(Prelude.$$Array.get, values$2, index), "None"),
                                      className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                    });
                        });
                  } else {
                    exit = 1;
                  }
                } else if (match === "SignatureQuestion" || match === "PhotoQuestion") {
                  tmp = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(answer.evidences), (function (param) {
                                  return param.id;
                                })), (function (resource) {
                              return ReactDOM.jsx("div", {
                                          children: React.jsx(LocalImage.Resource.make, {
                                                resource: resource
                                              }),
                                          className: "card-image"
                                        });
                            })), Mithril.empty);
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp = Prelude.default(AnswerValue.toHuman(answer.value), []).map(function (label) {
                        return ReactDOM.jsx("span", {
                                    children: label,
                                    className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                  });
                      });
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("span", {
                                    children: [
                                      !allowResolve && allowEdit ? React.jsx(Mithril.Link.make, {
                                              href: URLs.answerEditorUrl(false, undefined, level, question, vesselId),
                                              className: "btn btn-link btn-action float-right",
                                              children: Caml_option.some(React.jsx(Feather.make, {
                                                        icon: "edit"
                                                      }))
                                            }) : Mithril.empty,
                                      ReactDOM.jsx("div", {
                                            children: question.name,
                                            className: "card-subtitle text-small"
                                          }),
                                      Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, answerProfile, (function (p) {
                                                  return ReactDOM.jsx("div", {
                                                              children: UserProfile.nameWithRank(p),
                                                              className: "card-subtitle text-small"
                                                            });
                                                })), Mithril.empty)
                                    ],
                                    className: "card-header"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "card-body"
                                  }),
                              allowResolve ? ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("div", {
                                            children: ReactDOM.jsxs("a", {
                                                  children: [
                                                    React.jsx(Feather.make, {
                                                          icon: "info"
                                                        }),
                                                    "Details"
                                                  ],
                                                  className: "btn",
                                                  href: URLs.answerDetails(vesselId, level.id, answer.id)
                                                }),
                                            className: "btn-group btn-group-block"
                                          }),
                                      className: "card-footer"
                                    }) : Mithril.empty
                            ],
                            className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-m-2"
                          });
              }));
}

export {
  make ,
}
/* URLs Not a pure module */

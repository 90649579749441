// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../common/Forms.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as UUID7 from "../../../libs/UUID7.js";
import * as Browser from "../../../libs/Browser.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Inspection from "../../survey/Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ComponentId from "../../vessel/ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as CompanyFormId from "../CompanyFormId.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as ProcedureUpdate from "../../survey/ProcedureUpdate.js";
import * as CompanyFormManager from "../CompanyFormManager.js";
import * as CompanyFormSubmission from "../CompanyFormSubmission.js";
import * as CompanyFormSubmissionId from "../CompanyFormSubmissionId.js";
import * as CompanyForm_Components_DiverseQuestions from "./CompanyForm_Components_DiverseQuestions.js";

function edit(vnode, _event) {
  var match = vnode.state.data;
  Mithril.setState(vnode, {
        editing: true,
        text: match.text
      });
}

function readOnly(vnode, _event) {
  var match = vnode.state.data;
  Mithril.setState(vnode, {
        editing: false,
        text: match.text
      });
}

function onEdit(vnode, onEditCallback, $$event) {
  $$event.stopPropagation();
  if ($$event.keyCode === 13) {
    return readOnly(vnode, $$event);
  }
  var reExtraSpace = /\s+/g;
  var text = $$event.target.value.replace(reExtraSpace, " ");
  vnode.state.data = {
    editing: true,
    text: text
  };
  onEditCallback(text);
}

function CompanyForm_Procedures$Editor(props) {
  var onEditCallback = props.onEditCallback;
  return Mithril.view(Mithril.onupdate(Mithril.component({
                      editing: false,
                      text: props.text
                    }), (function (vnode) {
                    Forms.focusBySelector(vnode, "#text-input");
                  })), (function (vnode) {
                var match = vnode.state.data;
                var text = match.text;
                if (match.editing) {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("textarea", {
                                    className: "form-input text-center text-bold",
                                    id: "text-input",
                                    style: {
                                      resize: "none"
                                    },
                                    maxLength: 65,
                                    name: "text-input",
                                    rows: 2,
                                    value: text,
                                    onKeyUp: (function (extra) {
                                        return onEdit(vnode, onEditCallback, extra);
                                      }),
                                    onBlur: (function (extra) {
                                        return readOnly(vnode, extra);
                                      })
                                  }),
                              className: "form-group m-2"
                            });
                } else {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsxs("label", {
                                    children: [
                                      text,
                                      React.jsx(Feather.make, {
                                            icon: "edit",
                                            style: "position:absolute; left:95%; top:0;",
                                            size: 16
                                          })
                                    ],
                                    className: "form-input text-center text-bold",
                                    style: {
                                      border: "none",
                                      position: "relative"
                                    },
                                    onClick: (function (extra) {
                                        return edit(vnode, extra);
                                      })
                                  }),
                              className: "form-group m-2"
                            });
                }
              }));
}

var Editor = {
  edit: edit,
  readOnly: readOnly,
  onEdit: onEdit,
  make: CompanyForm_Procedures$Editor
};

function mergeItemsProcedureUpdates(_vnode, procedures, items, updateByProcedureId) {
  return items.map(function (item) {
              var procedureById = ProcedureId.$$Map.fromArray(procedures.map(function (p) {
                        return [
                                p.id,
                                p
                              ];
                      }));
              var getNotApplicableStatus = function (procedureId) {
                return ProcedureId.$$Map.get(procedureById, procedureId).not_applicable_status;
              };
              var groups = item.componentGroups.map(function (group) {
                    var groupProcedures = group.procedures.map(function (procedure_rel) {
                          var procedure = ProcedureId.$$Map.get(updateByProcedureId, procedure_rel.id);
                          if (procedure !== undefined) {
                            var newrecord = Caml_obj.obj_dup(procedure_rel);
                            newrecord.not_applicable_status = procedure.not_applicable_status;
                            return newrecord;
                          }
                          var newrecord$1 = Caml_obj.obj_dup(procedure_rel);
                          newrecord$1.not_applicable_status = getNotApplicableStatus(procedure_rel.id);
                          return newrecord$1;
                        });
                    return {
                            key: group.key,
                            component: group.component,
                            procedures: groupProcedures
                          };
                  });
              return {
                      key: item.key,
                      sectionId: item.sectionId,
                      sectionName: item.sectionName,
                      documentId: item.documentId,
                      componentGroups: groups
                    };
            });
}

function isCompletedFromMaps(newInspectionsOfProcedure, oldInspectionsOfProcedure, companyForm, procedures) {
  var index = ProcedureId.$$Set.fromArray(companyForm.related_procedures.map(function (p) {
            return p.procedure_id;
          }));
  var procedures$1 = Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
          return ProcedureId.$$Set.has(index, p.id);
        }));
  var inspections = Curry._2(Prelude.$$Array.concat, Curry._1(Prelude.$$Array.concatMany, ProcedureId.$$Map.valuesToArray(newInspectionsOfProcedure)), Curry._1(Prelude.$$Array.concatMany, ProcedureId.$$Map.valuesToArray(oldInspectionsOfProcedure)));
  var inspectedProcedureIds = inspections.map(function (i) {
        return i.procedure_id;
      });
  var requiredProcedureIds = Curry._2(Prelude.$$Array.keep, procedures$1, (function (p) {
            return p.not_applicable_status === undefined;
          })).map(function (p) {
        return p.id;
      });
  return CompanyFormSubmission.isCompleted(inspectedProcedureIds, requiredProcedureIds, companyForm.allow_partial_submission);
}

async function load(vnode) {
  var formId = CompanyFormId.Route.param("formId");
  var submissionId = CompanyFormSubmissionId.Route.param("submissionId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.draftCompanyFormSubmissions = {
          TAG: "Get",
          _0: submissionId
        }, newrecord.companyForms = {
          TAG: "Get",
          _0: formId
        }, newrecord));
  var draftCompanyFormSubmissions = match.draftCompanyFormSubmissions;
  var companyForms = match.companyForms;
  var submission = draftCompanyFormSubmissions[0];
  var procedureIds = Curry._1(Prelude.$$Array.concatMany, companyForms.map(function (form) {
            return form.related_procedures.map(function (param) {
                        return param.procedure_id;
                      });
          }));
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.procedureUpdates = {
          TAG: "In",
          _0: procedureIds
        }, newrecord$1.newInspections = {
          TAG: "In",
          _0: submission.inspections
        }, newrecord$1.inspections = {
          TAG: "In",
          _0: submission.inspections
        }, newrecord$1.procedures = {
          TAG: "In",
          _0: procedureIds
        }, newrecord$1));
  var procedures = match$1.procedures;
  var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$2.components = {
          TAG: "In",
          _0: procedures.map(Procedure.byComponent)
        }, newrecord$2.sections = {
          TAG: "In",
          _0: procedures.map(function (p) {
                return p.section_id;
              })
        }, newrecord$2));
  var sections = match$2.sections;
  var procedures$1 = ProcedureUpdate.apply(match$1.procedureUpdates, procedures);
  var match$3 = Prelude.$$Array.first(companyForms);
  var match$4 = Prelude.$$Array.first(draftCompanyFormSubmissions);
  if (match$3 !== undefined) {
    if (match$4 !== undefined) {
      var procedureIdToIndex = ProcedureId.$$Map.fromArray(match$3.related_procedures.map(function (param) {
                  return param.procedure_id;
                }).map(function (procedureId, index) {
                return [
                        procedureId,
                        index
                      ];
              }));
      var procedures$2 = Prelude.$$Array.sort(procedures$1, (function (param) {
              return ProcedureId.$$Map.get(procedureIdToIndex, param.id);
            }), undefined);
      var componentById = ComponentId.$$Map.fromArray(match$2.components.map(function (component) {
                return [
                        component.id,
                        component
                      ];
              }));
      var items = Curry._1(Prelude.$$Array.reverse, Belt_List.toArray(Curry._3(Prelude.$$Array.fold, procedures$2, /* [] */0, (function (items, procedure) {
                      var match = ComponentId.$$Map.get(componentById, procedure.component_id);
                      if (!items) {
                        if (match !== undefined) {
                          return {
                                  hd: {
                                    key: UUID7.make(),
                                    sectionId: procedure.section_id,
                                    sectionName: procedure.extra.section_name,
                                    documentId: Prelude.default(Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, sections, (function (s) {
                                                      return Caml_obj.equal(s.id, procedure.section_id);
                                                    })).map(function (s) {
                                                  return s.guidance_document;
                                                })), undefined),
                                    componentGroups: [{
                                        key: UUID7.make(),
                                        component: match,
                                        procedures: [procedure]
                                      }]
                                  },
                                  tl: /* [] */0
                                };
                        } else {
                          console.log("This should never happen");
                          return items;
                        }
                      }
                      if (match !== undefined) {
                        var match$1 = items.hd;
                        var componentGroups = match$1.componentGroups;
                        if (Caml_obj.notequal(match$1.sectionId, procedure.section_id)) {
                          return {
                                  hd: {
                                    key: UUID7.make(),
                                    sectionId: procedure.section_id,
                                    sectionName: procedure.extra.section_name,
                                    documentId: Prelude.default(Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, sections, (function (s) {
                                                      return Caml_obj.equal(s.id, procedure.section_id);
                                                    })).map(function (s) {
                                                  return s.guidance_document;
                                                })), undefined),
                                    componentGroups: [{
                                        key: UUID7.make(),
                                        component: match,
                                        procedures: [procedure]
                                      }]
                                  },
                                  tl: items
                                };
                        }
                        var componentGroup = Prelude.$$Array.lastUnsafe(componentGroups);
                        if (Caml_obj.notequal(componentGroup.component.id, match.id)) {
                          componentGroups.push({
                                key: UUID7.make(),
                                component: match,
                                procedures: [procedure]
                              });
                          return items;
                        } else {
                          componentGroup.procedures.push(procedure);
                          return items;
                        }
                      }
                      console.log("This should never happen");
                      return items;
                    }))));
      var newInspectionsOfProcedure = ProcedureId.$$Array.group(match$1.newInspections, NewInspection.byProcedure);
      var oldInspectionsOfProcedure = ProcedureId.$$Array.group(match$1.inspections, Inspection.byProcedure);
      var isCompleted = isCompletedFromMaps(newInspectionsOfProcedure, oldInspectionsOfProcedure, match$3, procedures$2);
      Mithril.setState(vnode, {
            TAG: "Loaded",
            procedures: procedures$2,
            submission: match$4,
            companyForm: match$3,
            items: items,
            isCompleted: isCompleted,
            oldInspectionsOfProcedure: oldInspectionsOfProcedure,
            newInspectionsOfProcedure: newInspectionsOfProcedure
          });
      return KeepScrollLink.restoreScroll(false);
    }
    window.history.back();
    return ;
  }
  window.history.back();
}

function updateInspectionMaps(vnode, procedure, newInspection) {
  var match = vnode.state.data;
  var tmp;
  if (typeof match !== "object") {
    tmp = Promise.resolve("NotReady");
  } else {
    var newInspectionsOfProcedure = match.newInspectionsOfProcedure;
    var oldInspectionsOfProcedure = match.oldInspectionsOfProcedure;
    var items = match.items;
    var companyForm = match.companyForm;
    var submission = match.submission;
    var procedures = match.procedures;
    if (newInspection !== undefined) {
      var currentInspections = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, ProcedureId.$$Map.get(newInspectionsOfProcedure, procedure.id), []);
      ProcedureId.$$Map.set(newInspectionsOfProcedure, procedure.id, Prelude.$$Array.append(currentInspections, newInspection));
      var isCompleted = isCompletedFromMaps(newInspectionsOfProcedure, oldInspectionsOfProcedure, companyForm, procedures);
      var newrecord = Caml_obj.obj_dup(Query.makeRead());
      tmp = Prelude.thenResolve(Query.read((newrecord.draftCompanyFormSubmissions = {
                  TAG: "Get",
                  _0: submission.id
                }, newrecord)), (function (param) {
              return {
                      TAG: "Loaded",
                      procedures: procedures,
                      submission: param.draftCompanyFormSubmissions[0],
                      companyForm: companyForm,
                      items: items,
                      isCompleted: isCompleted,
                      oldInspectionsOfProcedure: oldInspectionsOfProcedure,
                      newInspectionsOfProcedure: newInspectionsOfProcedure
                    };
            }));
    } else {
      tmp = Promise.resolve({
            TAG: "Loaded",
            procedures: procedures,
            submission: submission,
            companyForm: companyForm,
            items: items,
            isCompleted: match.isCompleted,
            oldInspectionsOfProcedure: oldInspectionsOfProcedure,
            newInspectionsOfProcedure: newInspectionsOfProcedure
          });
    }
  }
  Prelude.thenDo(tmp, (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function updateProcedureMaps(vnode, _event) {
  var match = vnode.state.data;
  var tmp;
  if (typeof match !== "object") {
    tmp = Promise.resolve("NotReady");
  } else {
    var newInspectionsOfProcedure = match.newInspectionsOfProcedure;
    var oldInspectionsOfProcedure = match.oldInspectionsOfProcedure;
    var items = match.items;
    var submission = match.submission;
    var procedures = match.procedures;
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    tmp = Prelude.thenResolve(Query.read((newrecord.procedureUpdates = "All", newrecord.draftCompanyFormSubmissions = {
                TAG: "Get",
                _0: submission.id
              }, newrecord.companyForms = {
                TAG: "Get",
                _0: submission.form_id
              }, newrecord)), (function (param) {
            var procedureUpdates = param.procedureUpdates;
            var updateByProcedureId = ProcedureId.$$Map.fromArray(procedureUpdates.map(function (p) {
                      return [
                              p.id,
                              p
                            ];
                    }));
            var procedures$1 = ProcedureUpdate.apply(procedureUpdates, procedures);
            var items$1 = mergeItemsProcedureUpdates(vnode, procedures$1, items, updateByProcedureId);
            var companyForm = param.companyForms[0];
            var isCompleted = isCompletedFromMaps(newInspectionsOfProcedure, oldInspectionsOfProcedure, companyForm, procedures$1);
            return {
                    TAG: "Loaded",
                    procedures: procedures$1,
                    submission: param.draftCompanyFormSubmissions[0],
                    companyForm: companyForm,
                    items: items$1,
                    isCompleted: isCompleted,
                    oldInspectionsOfProcedure: oldInspectionsOfProcedure,
                    newInspectionsOfProcedure: newInspectionsOfProcedure
                  };
          }));
  }
  Prelude.thenDo(tmp, (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function title(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return "";
  } else {
    return match.companyForm.name;
  }
}

function createSubmission(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  } else {
    return Prelude.thenDo(CompanyFormManager.convertDraftSubmissionToSubmission(match.submission, match.companyForm.allow_partial_submission), (function (success) {
                  if (success) {
                    window.history.back();
                    return ;
                  }
                  
                }));
  }
}

function deleteDraftSubmission(draftSubmission) {
  Prelude.thenDo(CompanyFormManager.deleteDraftSubmission(draftSubmission), (function (param) {
          window.history.back();
        }));
}

function finishButton(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return Mithril.empty;
  }
  var disabled = !match.isCompleted;
  return ReactDOM.jsx("button", {
              children: "Finish",
              className: "t-finish-company-form btn btn-primary",
              disabled: disabled,
              onClick: (function (extra) {
                  return createSubmission(vnode, extra);
                })
            });
}

function setSubmissionName(vnode, text) {
  var data = vnode.state.data;
  if (typeof data !== "object") {
    return ;
  }
  var text$1 = text.trim();
  var init = data.submission;
  var submission_id = init.id;
  var submission_vessel_id = init.vessel_id;
  var submission_form_id = init.form_id;
  var submission_timestamp = init.timestamp;
  var submission_target_date = init.target_date;
  var submission_inspections = init.inspections;
  var submission = {
    id: submission_id,
    name: text$1,
    vessel_id: submission_vessel_id,
    form_id: submission_form_id,
    timestamp: submission_timestamp,
    target_date: submission_target_date,
    inspections: submission_inspections
  };
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  Prelude.thenDo(Query.write((newrecord.draftCompanyFormSubmissions = [{
                TAG: "Save",
                _0: submission
              }], newrecord)), (function (param) {
          Mithril.setState(vnode, {
                TAG: "Loaded",
                procedures: data.procedures,
                submission: submission,
                companyForm: data.companyForm,
                items: data.items,
                isCompleted: data.isCompleted,
                oldInspectionsOfProcedure: data.oldInspectionsOfProcedure,
                newInspectionsOfProcedure: data.newInspectionsOfProcedure
              });
        }));
}

function make(_vnode) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), load), (function (vnode) {
                var match = vnode.state.data;
                var actions;
                if (typeof match !== "object") {
                  actions = [];
                } else {
                  var submission = match.submission;
                  actions = [{
                      icon: "trash_2",
                      effect: {
                        TAG: "Fn",
                        _0: (function ($$event) {
                            $$event.preventDefault();
                            if (Browser.$$Window.confirm("Are you sure?")) {
                              return deleteDraftSubmission(submission);
                            }
                            
                          })
                      }
                    }];
                }
                var match$1 = vnode.state.data;
                var tmp;
                if (typeof match$1 !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var newInspectionsOfProcedure = match$1.newInspectionsOfProcedure;
                  var oldInspectionsOfProcedure = match$1.oldInspectionsOfProcedure;
                  var companyForm = match$1.companyForm;
                  var submission$1 = match$1.submission;
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          React.jsx(CompanyForm_Procedures$Editor, {
                                text: submission$1.name,
                                onEditCallback: (function (extra) {
                                    return setSubmissionName(vnode, extra);
                                  })
                              }),
                          match$1.items.map(function (param) {
                                var documentId = param.documentId;
                                var sectionName = param.sectionName;
                                var docUrl = documentId !== undefined ? URLs.markdownDocument(Caml_option.valFromOption(documentId)) : "";
                                return ReactDOM.jsxsKeyed("div", {
                                            children: [
                                              ReactDOM.jsx("div", {
                                                    children: ReactDOM.jsx("div", {
                                                          children: docUrl !== "" ? ReactDOM.jsxs("a", {
                                                                  children: [
                                                                    sectionName,
                                                                    React.jsx(Feather.make, {
                                                                          icon: "map",
                                                                          className: "mx-2",
                                                                          size: 14
                                                                        })
                                                                  ],
                                                                  className: "mx-2",
                                                                  href: docUrl
                                                                }) : sectionName
                                                        }),
                                                    className: Mithril.$$class([
                                                          [
                                                            "text-center text-capitalize px-2 mt-3",
                                                            true
                                                          ],
                                                          [
                                                            "text-gray",
                                                            docUrl === ""
                                                          ],
                                                          [
                                                            "text-primary",
                                                            docUrl !== ""
                                                          ]
                                                        ], undefined)
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    children: param.componentGroups.map(function (param) {
                                                          return React.jsxKeyed(CompanyForm_Components_DiverseQuestions.make, {
                                                                      companyForm: companyForm,
                                                                      component: param.component,
                                                                      procedures: param.procedures,
                                                                      oldInspectionsOfProcedure: oldInspectionsOfProcedure,
                                                                      newInspectionsOfProcedure: newInspectionsOfProcedure,
                                                                      onInspectionCreated: (function (extra, extra$1) {
                                                                          return updateInspectionMaps(vnode, extra, extra$1);
                                                                        }),
                                                                      onChangeProcedure: (function (extra) {
                                                                          return updateProcedureMaps(vnode, extra);
                                                                        }),
                                                                      draftSubmission: submission$1,
                                                                      counterOverlayClassName: "completion-group"
                                                                    }, param.key, undefined);
                                                        })
                                                  })
                                            ]
                                          }, param.key, undefined);
                              })
                        ]
                      });
                }
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode),
                            overNavbar: Caml_option.some(finishButton(vnode)),
                            overNavbarHeight: "1rem",
                            actions: actions
                          });
              }));
}

export {
  Editor ,
  mergeItemsProcedureUpdates ,
  isCompletedFromMaps ,
  load ,
  updateInspectionMaps ,
  updateProcedureMaps ,
  title ,
  createSubmission ,
  deleteDraftSubmission ,
  finishButton ,
  setSubmissionName ,
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ProtectedView from "./ProtectedView.js";
import * as State__Memory from "../../state/State__Memory.js";

function Make(M) {
  var ProtectedViewMaker$Make = function (props) {
    return Mithril.view(Mithril.component(), (function (param) {
                  return React.jsx(ProtectedView.make, {
                              requireNonCrew: M.requireNonCrew,
                              requireProfile: M.requireProfile,
                              children: React.jsx(M.make, {})
                            });
                }));
  };
  return {
          make: ProtectedViewMaker$Make
        };
}

function EnsureUser(M) {
  var ProtectedViewMaker$EnsureUser$Inner = function (props) {
    return Mithril.view(Mithril.component(), (function (param) {
                  var user = Curry._1(Prelude.OptionExported.$$Option.getExn, State__Memory.Current.get().user);
                  return React.jsx(M.make, {
                              user: user
                            });
                }));
  };
  var ProtectedViewMaker$EnsureUser = function (props) {
    return Mithril.view(Mithril.component(), (function (param) {
                  return React.jsx(ProtectedView.make, {
                              requireNonCrew: false,
                              requireProfile: false,
                              children: React.jsx(ProtectedViewMaker$EnsureUser$Inner, {})
                            });
                }));
  };
  return {
          make: ProtectedViewMaker$EnsureUser
        };
}

function EnsureProfile(M) {
  var ProtectedViewMaker$EnsureProfile$Inner = function (props) {
    return Mithril.view(Mithril.component(), (function (param) {
                  var profile = Curry._1(Prelude.OptionExported.$$Option.getExn, State__Memory.Current.get().profile);
                  var user = Curry._1(Prelude.OptionExported.$$Option.getExn, State__Memory.Current.get().user);
                  return React.jsx(M.make, {
                              profile: profile,
                              user: user
                            });
                }));
  };
  var ProtectedViewMaker$EnsureProfile = function (props) {
    return Mithril.view(Mithril.component(), (function (param) {
                  return React.jsx(ProtectedView.make, {
                              requireNonCrew: false,
                              requireProfile: true,
                              children: React.jsx(ProtectedViewMaker$EnsureProfile$Inner, {})
                            });
                }));
  };
  return {
          make: ProtectedViewMaker$EnsureProfile
        };
}

export {
  Make ,
  EnsureUser ,
  EnsureProfile ,
}
/* React Not a pure module */

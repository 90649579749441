// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../../libs/URL.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalFile from "../../../libs/LocalFile.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.js";
import MarkdownIt from "markdown-it";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as ProcedureImage from "../../survey/ProcedureImage.js";
import * as MarkdownDocumentId from "../MarkdownDocumentId.js";
import * as Webapi__Dom__Element from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.js";

async function createImageURLs(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var $$document = vnode.attrs.document;
  var results = await ProcedureImage.loadMany($$document.attachments);
  var loaded = LocalFile.UUID.$$Map.fromArray(results.map(function (r) {
            return [
                    r.id,
                    URL.createObjectURL(r.file)
                  ];
          }));
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              _0: loaded
            });
}

function destroyImageURLs(vnode) {
  var urls = vnode.state.data;
  if (typeof urls !== "object") {
    return ;
  }
  LocalFile.UUID.$$Map.valuesToArray(urls._0).map(function (prim) {
          return prim;
        }).map($$URL.revokeObjectURL);
}

var imageRegExp = /\!\[kaiko\.attachment::([0-9a-f\-]+)\]\([^\)]+\)/g;

function trapClicks($$event) {
  var target = $$event.target;
  var match = target.tagName;
  var match$1 = target.href;
  if (match !== "A") {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  $$event.preventDefault();
  var el = document.createElement("A");
  el.setAttribute("href", Caml_option.valFromOption(match$1));
  el.setAttribute("target", "_blank");
  Core__Option.map(Webapi__Dom__Element.asHtmlElement(el), (function (prim) {
          prim.click();
        }));
}

function make(param) {
  return Mithril.view(Mithril.onremove(Mithril.oninitAsync(Mithril.component("Init"), createImageURLs), destroyImageURLs), (function (vnode) {
                var dataUrls = vnode.state.data;
                if (typeof dataUrls !== "object") {
                  return Mithril.empty;
                }
                var dataUrls$1 = dataUrls._0;
                var $$document = vnode.attrs.document;
                var contents = $$document.contents.replaceAll(imageRegExp, (function (image, uuid) {
                        var dataURL = LocalFile.UUID.$$Map.get(dataUrls$1, LocalFile.UUID.fromString(uuid));
                        if (dataURL !== undefined) {
                          return "![replaced::" + uuid + "](" + Caml_option.valFromOption(dataURL) + ")";
                        } else {
                          return image;
                        }
                      }));
                var parsed = new MarkdownIt().render(contents);
                return ReactDOM.jsx("div", {
                            children: Mithril$1.trust(parsed),
                            className: "tw-p-[1rem] markdown-container",
                            onClick: trapClicks
                          });
              }));
}

var MarkdownDocumentView = {
  createImageURLs: createImageURLs,
  destroyImageURLs: destroyImageURLs,
  trapClicks: trapClicks,
  make: make
};

async function loadDocument(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  if (match !== "Init") {
    return ;
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.documents = {
          TAG: "Get",
          _0: vnode.attrs.documentId
        }, newrecord));
  var documents = match$1.documents;
  if (documents.length !== 1) {
    return Mithril.setState(vnode, "NotFound");
  }
  var $$document = documents[0];
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              _0: $$document
            });
}

function make$1(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("Init"), loadDocument), (function (vnode) {
                var $$document = vnode.state.data;
                if (typeof $$document !== "object") {
                  if ($$document === "Init") {
                    return Mithril.empty;
                  } else {
                    return React.jsx(PageLayout.make, {
                                header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                          content: {
                                            NAME: "Title",
                                            VAL: "Not Found"
                                          }
                                        })),
                                children: Caml_option.some(ReactDOM.jsx("p", {
                                          children: "The document was not found locally.  This is probably a synchronization issue.\n                   If you keep experiencing this error, please contact Kaiko Systems."
                                        }))
                              });
                  }
                }
                var $$document$1 = $$document._0;
                var title = Core__Array.keepSome([
                        Core__Option.map($$document$1.title, (function (prim) {
                                return prim.trim();
                              })),
                        vnode.attrs.defaultTitle,
                        "Document"
                      ])[0];
                return React.jsx(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                      content: {
                                        NAME: "Title",
                                        VAL: title
                                      }
                                    })),
                            children: Caml_option.some(React.jsx(make, {
                                      document: $$document$1
                                    }))
                          });
              }));
}

var MarkdownDocumentPage = {
  loadDocument: loadDocument,
  make: make$1
};

function make$2(param) {
  return Mithril.view(Mithril.component(), (function (param) {
                var documentId = MarkdownDocumentId.Route.paramOr("documentId", MarkdownDocumentId.$$null);
                return React.jsx(make$1, {
                            documentId: documentId
                          });
              }));
}

export {
  MarkdownDocumentView ,
  MarkdownDocumentPage ,
  make$2 as make,
}
/* URL Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Utils from "../../../libs/Utils.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as VesselId from "../VesselId.js";
import * as Procedure from "../../survey/Procedure.js";
import * as SectionId from "../SectionId.js";
import * as Completion from "../../common/Completion.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ComponentId from "../ComponentId.js";
import * as PlannedProcedure from "../../survey/PlannedProcedure.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";

var Queue = ActionQueue.MakeActionQueue({
      name: "Feedback queue"
    });

var queue = Queue.make(false, 10, undefined);

function $$do(fn) {
  Queue.append(queue, (function () {
          return Utils.$$continue(fn);
        }), undefined);
}

var FeedbackQueue = {
  Queue: Queue,
  $$do: $$do
};

function updateState(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    var procedures = vnode.attrs.procedures;
    var proc = Prelude.$$Array.first(procedures);
    if (proc === undefined) {
      return ;
    }
    var section = proc.extra.section_name;
    var section_id = proc.section_id;
    var component_type = proc.extra.component_type;
    return Mithril.setState(vnode, {
                TAG: "Initial",
                section: section,
                section_id: section_id,
                component_type: component_type,
                procedures: procedures
              });
  }
  if (match.TAG !== "Initial") {
    return ;
  }
  var procedures$1 = match.procedures;
  var components = ComponentId.$$Map.valuesToArray(ComponentId.$$Array.group(procedures$1, (function (p) {
              return p.component_id;
            })));
  var dueStatusText = Procedure.getGreatestOverdueText(undefined, procedures$1);
  var completion = Completion.Information.make(components.length, Curry._3(Prelude.$$Array.fold, components.map(function (procs) {
                if (Curry._2(Prelude.$$Array.every, procs, Procedure.isFullyInspected)) {
                  return 1;
                } else {
                  return 0;
                }
              }), 0, (function (a, b) {
              return a + b | 0;
            })), Procedure.areOverdue(procedures$1));
  Mithril.setState(vnode, {
        TAG: "Rendered",
        section: match.section,
        section_id: match.section_id,
        component_type: match.component_type,
        procedures: procedures$1,
        dueStatusText: dueStatusText,
        completion: completion
      });
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), updateState), updateState), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                if (match.TAG === "Initial") {
                  var section_id = match.section_id;
                  return React.jsxKeyed(TileLink.make, {
                              title: match.section,
                              href: URLs.sectionComponents(section_id, match.component_type, true),
                              subtitle: "Loading..."
                            }, section_id + "-initial", undefined);
                }
                var section_id$1 = match.section_id;
                return React.jsxKeyed(TileLink.make, {
                            title: match.section,
                            href: URLs.sectionComponents(section_id$1, match.component_type, true),
                            subtitle: match.dueStatusText,
                            action: Caml_option.some(React.jsx(InspectionCompletion.make, {
                                      completion: match.completion,
                                      counterOverlayClassName: "completion-group"
                                    }))
                          }, section_id$1 + "-rendered", undefined);
              }));
}

var SectionCard = {
  make: make
};

function updateState$1(vnode) {
  var match = vnode.state.data;
  if (match.TAG === "Initial") {
    return Mithril.setState(vnode, {
                TAG: "Rendering",
                title: match.title,
                rendered: [],
                remaining: Prelude.$$Array.sort(SectionId.$$Map.toArray(match.data), (function (param) {
                        return param[1][0].extra.section_name;
                      }), undefined)
              });
  }
  var remaining = match.remaining;
  var title = match.title;
  if (remaining.length === 0) {
    return ;
  }
  var page = Curry._3(Prelude.$$Array.slice, remaining, 0, 5);
  var rendered = Curry._2(Prelude.$$Array.concat, match.rendered, page);
  var remaining$1 = Curry._2(Prelude.$$Array.sliceToEnd, remaining, 5);
  $$do(function () {
        Mithril.setState(vnode, {
              TAG: "Rendering",
              title: title,
              rendered: rendered,
              remaining: remaining$1
            });
      });
}

function make$1(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component({
                          TAG: "Initial",
                          title: "",
                          data: SectionId.$$Map.make()
                        }), (function (vnode) {
                        vnode.state.data = {
                          TAG: "Initial",
                          title: vnode.attrs.title,
                          data: vnode.attrs.sections
                        };
                      })), updateState$1), (function (vnode) {
                var match = vnode.state.data;
                if (match.TAG === "Initial") {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("div", {
                                      children: match.title,
                                      className: "text-gray text-center px-2 text-capitalize",
                                      style: {
                                        fontVariant: "small-caps"
                                      }
                                    }),
                                ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("div", {
                                            className: "column"
                                          }),
                                      className: "pb-2 columns"
                                    })
                              ],
                              className: "pb-2"
                            });
                }
                var rendered = match.rendered;
                if (rendered.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("div", {
                                      children: match.title,
                                      className: "text-gray text-center px-2 text-capitalize",
                                      style: {
                                        fontVariant: "small-caps"
                                      }
                                    }),
                                ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("div", {
                                            children: rendered.map(function (param) {
                                                  return React.jsxKeyed(make, {
                                                              procedures: param[1]
                                                            }, param[0], undefined);
                                                }),
                                            className: "column"
                                          }),
                                      className: "pb-2 columns"
                                    })
                              ],
                              className: "pb-2"
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

var ComponentTypeGroup = {
  make: make$1
};

function sectionDataByComponentType(procedures) {
  return SectionId.$$Array.group(procedures, (function (p) {
                return p.section_id;
              }));
}

function loadState(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.plannedProcedures = PlannedProcedure.ofVessel(vesselId), newrecord)), (function (param) {
          var plannedProcedures = param.plannedProcedures;
          var structural = SectionId.$$Array.group(Curry._2(Prelude.$$Array.keep, plannedProcedures, (function (p) {
                      return p.extra.component_type === "structural";
                    })), (function (p) {
                  return p.section_id;
                }));
          var safety = SectionId.$$Array.group(Curry._2(Prelude.$$Array.keep, plannedProcedures, (function (p) {
                      return p.extra.component_type === "safety";
                    })), (function (p) {
                  return p.section_id;
                }));
          var checklist = SectionId.$$Array.group(Curry._2(Prelude.$$Array.keep, plannedProcedures, (function (p) {
                      return p.extra.component_type === "checklist";
                    })), (function (p) {
                  return p.section_id;
                }));
          Mithril.setState(vnode, {
                TAG: "Loaded",
                structural: structural,
                safety: safety,
                checklist: checklist
              });
        }));
}

function make$2(_vnode) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadState), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                } else {
                  return React.jsxs(ListLayout.make, {
                              children: [
                                React.jsx(make$1, {
                                      title: "Hull & Equipment",
                                      sections: match.structural
                                    }),
                                React.jsx(make$1, {
                                      title: "Safety",
                                      sections: match.safety
                                    }),
                                React.jsx(make$1, {
                                      title: "Checklists",
                                      sections: match.checklist
                                    })
                              ],
                              title: "Planned Sections"
                            });
                }
              }));
}

export {
  FeedbackQueue ,
  SectionCard ,
  ComponentTypeGroup ,
  sectionDataByComponentType ,
  loadState ,
  make$2 as make,
}
/* Queue Not a pure module */

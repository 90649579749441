// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ProfileId from "../ProfileId.js";
import * as ButtonLink from "../../common/components/ButtonLink.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProfileCard from "../components/ProfileCard.js";
import * as UserProfile from "../UserProfile.js";
import * as ProfileFilter from "../components/ProfileFilter.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as ButtonSlimIcon from "../../common/components/ButtonSlimIcon.js";
import * as PageNavigation from "../../common/views/PageNavigation.js";
import * as LabelLandingPage from "../components/LabelLandingPage.js";
import * as ButtonFilterToggle from "../../common/components/ButtonFilterToggle.js";
import * as ProtectedViewMaker from "./ProtectedViewMaker.js";

async function initialize(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var cur_state = State__Memory.Current.get();
  var user = Curry._1(Prelude.OptionExported.$$Option.getExn, cur_state.user);
  var vessel = Curry._1(Prelude.OptionExported.$$Option.getExn, cur_state.vessel);
  var vesselId = vessel.id;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.staffers = {
          TAG: "Is",
          _0: "vessel",
          _1: vesselId
        }, newrecord.newUserProfiles = {
          TAG: "Is",
          _0: "vessel",
          _1: vesselId
        }, newrecord.userProfiles = {
          TAG: "Is",
          _0: "vessel",
          _1: vesselId
        }, newrecord));
  var staffers = Curry._2(Prelude.$$Array.keep, match$1.staffers, (function (s) {
          return Caml_obj.equal(s.user, user.id);
        }));
  var staffInfo = Prelude.$$Array.first(staffers);
  if (staffInfo !== undefined) {
    var match$2 = State__Memory.Current.get();
    var is_crew = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match$2.role, (function (r) {
                return r === "crew";
              })), true);
    var profilesById = ProfileId.$$Map.fromArray(match$1.userProfiles.map(function (p) {
              return [
                      p.id,
                      p
                    ];
            }));
    Curry._2(Prelude.$$Array.forEach, match$1.newUserProfiles, (function (p) {
            ProfileId.$$Map.set(profilesById, p.id, p);
          }));
    var profiles = Curry._2(Prelude.$$Array.keep, ProfileId.$$Map.valuesToArray(profilesById), (function (p) {
            if (is_crew) {
              return p.rank !== "TSI";
            } else {
              return true;
            }
          }));
    var disabledFilters = Curry._3(Prelude.$$Array.fold, profiles, ProfileFilter.Filter.defaultTrue, (function (disabledFilters, profile) {
            return {
                    master: profile.rank !== "MASTER" && disabledFilters.master,
                    chief_officer: profile.rank !== "CHIEF_OFFICER" && disabledFilters.chief_officer,
                    second_officer: profile.rank !== "SECOND_OFFICER" && disabledFilters.second_officer,
                    third_officer: profile.rank !== "THIRD_OFFICER" && disabledFilters.third_officer,
                    chief_engineer: profile.rank !== "CHIEF_ENGINEER" && disabledFilters.chief_engineer,
                    second_engineer: profile.rank !== "SECOND_ENGINEER" && disabledFilters.second_engineer,
                    third_engineer: profile.rank !== "THIRD_ENGINEER" && disabledFilters.third_engineer,
                    fourth_engineer: profile.rank !== "FOURTH_ENGINEER" && disabledFilters.fourth_engineer,
                    deck_rating: profile.rank !== "DECK_RATING" && disabledFilters.deck_rating,
                    engine_rating: profile.rank !== "ENGINE_RATING" && disabledFilters.engine_rating,
                    eto_electrician: profile.rank !== "ETO_ELECTRICIAN" && disabledFilters.eto_electrician,
                    active: profile.state !== "active" && disabledFilters.active,
                    inactive: profile.state !== "inactive" && disabledFilters.inactive
                  };
          }));
    var profiles$1 = Belt_SortArray.stableSortBy(profiles, (function (profileA, profileB) {
            var rankOrder = UserProfile.Rank.compareRank(profileA.rank, profileB.rank);
            if (rankOrder !== 0) {
              return rankOrder;
            } else {
              return Caml_obj.compare(UserProfile.name(profileA), UserProfile.name(profileB));
            }
          }));
    var profile = cur_state.profile;
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                user: user,
                vessel: vessel,
                profile: profile,
                staffInfo: staffInfo,
                profiles: profiles$1,
                showModal: false,
                filterState: ProfileFilter.Filter.defaultFalse,
                disabledFilters: disabledFilters
              });
  }
  console.error("Accounts_LandingPageProfile", "Detected logged user without any role", {
        user: user.id
      });
}

function sameId(profileA, profileB) {
  if (profileB !== undefined) {
    return Caml_obj.equal(profileA.id, profileB.id);
  } else {
    return false;
  }
}

function filterUpdate(vnode, filterType, newValue) {
  var curState = vnode.state.data;
  if (typeof curState !== "object") {
    return ;
  }
  var filterState = curState.filterState;
  var newFilterState = filterType === "DECK_RATING" ? ({
        master: filterState.master,
        chief_officer: filterState.chief_officer,
        second_officer: filterState.second_officer,
        third_officer: filterState.third_officer,
        chief_engineer: filterState.chief_engineer,
        second_engineer: filterState.second_engineer,
        third_engineer: filterState.third_engineer,
        fourth_engineer: filterState.fourth_engineer,
        deck_rating: newValue,
        engine_rating: filterState.engine_rating,
        eto_electrician: filterState.eto_electrician,
        active: filterState.active,
        inactive: filterState.inactive
      }) : (
      filterType === "FOURTH_ENGINEER" ? ({
            master: filterState.master,
            chief_officer: filterState.chief_officer,
            second_officer: filterState.second_officer,
            third_officer: filterState.third_officer,
            chief_engineer: filterState.chief_engineer,
            second_engineer: filterState.second_engineer,
            third_engineer: filterState.third_engineer,
            fourth_engineer: newValue,
            deck_rating: filterState.deck_rating,
            engine_rating: filterState.engine_rating,
            eto_electrician: filterState.eto_electrician,
            active: filterState.active,
            inactive: filterState.inactive
          }) : (
          filterType === "inactive" ? ({
                master: filterState.master,
                chief_officer: filterState.chief_officer,
                second_officer: filterState.second_officer,
                third_officer: filterState.third_officer,
                chief_engineer: filterState.chief_engineer,
                second_engineer: filterState.second_engineer,
                third_engineer: filterState.third_engineer,
                fourth_engineer: filterState.fourth_engineer,
                deck_rating: filterState.deck_rating,
                engine_rating: filterState.engine_rating,
                eto_electrician: filterState.eto_electrician,
                active: filterState.active,
                inactive: newValue
              }) : (
              filterType === "MASTER" ? ({
                    master: newValue,
                    chief_officer: filterState.chief_officer,
                    second_officer: filterState.second_officer,
                    third_officer: filterState.third_officer,
                    chief_engineer: filterState.chief_engineer,
                    second_engineer: filterState.second_engineer,
                    third_engineer: filterState.third_engineer,
                    fourth_engineer: filterState.fourth_engineer,
                    deck_rating: filterState.deck_rating,
                    engine_rating: filterState.engine_rating,
                    eto_electrician: filterState.eto_electrician,
                    active: filterState.active,
                    inactive: filterState.inactive
                  }) : (
                  filterType === "ENGINE_RATING" ? ({
                        master: filterState.master,
                        chief_officer: filterState.chief_officer,
                        second_officer: filterState.second_officer,
                        third_officer: filterState.third_officer,
                        chief_engineer: filterState.chief_engineer,
                        second_engineer: filterState.second_engineer,
                        third_engineer: filterState.third_engineer,
                        fourth_engineer: filterState.fourth_engineer,
                        deck_rating: filterState.deck_rating,
                        engine_rating: newValue,
                        eto_electrician: filterState.eto_electrician,
                        active: filterState.active,
                        inactive: filterState.inactive
                      }) : (
                      filterType === "CHIEF_OFFICER" ? ({
                            master: filterState.master,
                            chief_officer: newValue,
                            second_officer: filterState.second_officer,
                            third_officer: filterState.third_officer,
                            chief_engineer: filterState.chief_engineer,
                            second_engineer: filterState.second_engineer,
                            third_engineer: filterState.third_engineer,
                            fourth_engineer: filterState.fourth_engineer,
                            deck_rating: filterState.deck_rating,
                            engine_rating: filterState.engine_rating,
                            eto_electrician: filterState.eto_electrician,
                            active: filterState.active,
                            inactive: filterState.inactive
                          }) : (
                          filterType === "SECOND_OFFICER" ? ({
                                master: filterState.master,
                                chief_officer: filterState.chief_officer,
                                second_officer: newValue,
                                third_officer: filterState.third_officer,
                                chief_engineer: filterState.chief_engineer,
                                second_engineer: filterState.second_engineer,
                                third_engineer: filterState.third_engineer,
                                fourth_engineer: filterState.fourth_engineer,
                                deck_rating: filterState.deck_rating,
                                engine_rating: filterState.engine_rating,
                                eto_electrician: filterState.eto_electrician,
                                active: filterState.active,
                                inactive: filterState.inactive
                              }) : (
                              filterType === "active" ? ({
                                    master: filterState.master,
                                    chief_officer: filterState.chief_officer,
                                    second_officer: filterState.second_officer,
                                    third_officer: filterState.third_officer,
                                    chief_engineer: filterState.chief_engineer,
                                    second_engineer: filterState.second_engineer,
                                    third_engineer: filterState.third_engineer,
                                    fourth_engineer: filterState.fourth_engineer,
                                    deck_rating: filterState.deck_rating,
                                    engine_rating: filterState.engine_rating,
                                    eto_electrician: filterState.eto_electrician,
                                    active: newValue,
                                    inactive: filterState.inactive
                                  }) : (
                                  filterType === "THIRD_ENGINEER" ? ({
                                        master: filterState.master,
                                        chief_officer: filterState.chief_officer,
                                        second_officer: filterState.second_officer,
                                        third_officer: filterState.third_officer,
                                        chief_engineer: filterState.chief_engineer,
                                        second_engineer: filterState.second_engineer,
                                        third_engineer: newValue,
                                        fourth_engineer: filterState.fourth_engineer,
                                        deck_rating: filterState.deck_rating,
                                        engine_rating: filterState.engine_rating,
                                        eto_electrician: filterState.eto_electrician,
                                        active: filterState.active,
                                        inactive: filterState.inactive
                                      }) : (
                                      filterType === "CHIEF_ENGINEER" ? ({
                                            master: filterState.master,
                                            chief_officer: filterState.chief_officer,
                                            second_officer: filterState.second_officer,
                                            third_officer: filterState.third_officer,
                                            chief_engineer: newValue,
                                            second_engineer: filterState.second_engineer,
                                            third_engineer: filterState.third_engineer,
                                            fourth_engineer: filterState.fourth_engineer,
                                            deck_rating: filterState.deck_rating,
                                            engine_rating: filterState.engine_rating,
                                            eto_electrician: filterState.eto_electrician,
                                            active: filterState.active,
                                            inactive: filterState.inactive
                                          }) : (
                                          filterType === "SECOND_ENGINEER" ? ({
                                                master: filterState.master,
                                                chief_officer: filterState.chief_officer,
                                                second_officer: filterState.second_officer,
                                                third_officer: filterState.third_officer,
                                                chief_engineer: filterState.chief_engineer,
                                                second_engineer: newValue,
                                                third_engineer: filterState.third_engineer,
                                                fourth_engineer: filterState.fourth_engineer,
                                                deck_rating: filterState.deck_rating,
                                                engine_rating: filterState.engine_rating,
                                                eto_electrician: filterState.eto_electrician,
                                                active: filterState.active,
                                                inactive: filterState.inactive
                                              }) : (
                                              filterType === "ETO_ELECTRICIAN" ? ({
                                                    master: filterState.master,
                                                    chief_officer: filterState.chief_officer,
                                                    second_officer: filterState.second_officer,
                                                    third_officer: filterState.third_officer,
                                                    chief_engineer: filterState.chief_engineer,
                                                    second_engineer: filterState.second_engineer,
                                                    third_engineer: filterState.third_engineer,
                                                    fourth_engineer: filterState.fourth_engineer,
                                                    deck_rating: filterState.deck_rating,
                                                    engine_rating: filterState.engine_rating,
                                                    eto_electrician: newValue,
                                                    active: filterState.active,
                                                    inactive: filterState.inactive
                                                  }) : ({
                                                    master: filterState.master,
                                                    chief_officer: filterState.chief_officer,
                                                    second_officer: filterState.second_officer,
                                                    third_officer: newValue,
                                                    chief_engineer: filterState.chief_engineer,
                                                    second_engineer: filterState.second_engineer,
                                                    third_engineer: filterState.third_engineer,
                                                    fourth_engineer: filterState.fourth_engineer,
                                                    deck_rating: filterState.deck_rating,
                                                    engine_rating: filterState.engine_rating,
                                                    eto_electrician: filterState.eto_electrician,
                                                    active: filterState.active,
                                                    inactive: filterState.inactive
                                                  })
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  Mithril.setState(vnode, {
        TAG: "Loaded",
        user: curState.user,
        vessel: curState.vessel,
        profile: curState.profile,
        staffInfo: curState.staffInfo,
        profiles: curState.profiles,
        showModal: curState.showModal,
        filterState: newFilterState,
        disabledFilters: curState.disabledFilters
      });
}

function Accounts_LandingPageProfile$ProfileLandingPage(props) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), initialize), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var filterState = match.filterState;
                var profile = match.profile;
                var vessel = match.vessel;
                var usingFilters = ProfileFilter.Filter.anyFilter(filterState);
                var rankOn = function (rank) {
                  if (ProfileFilter.Filter.rankActive(filterState)) {
                    return ProfileFilter.Filter.isOn(rank, filterState);
                  } else {
                    return true;
                  }
                };
                var statusOn = function (filter) {
                  if (ProfileFilter.Filter.statusActive(filterState)) {
                    return ProfileFilter.Filter.isOn(filter, filterState);
                  } else {
                    return true;
                  }
                };
                var filteredProfiles = Curry._2(Prelude.$$Array.keepMap, match.profiles, (function (profile) {
                        var match = profile.state;
                        var match$1 = ProfileFilter.Filter.anyFilter(filterState);
                        if (match === "archived") {
                          return ;
                        }
                        if (match === "active") {
                          if (!match$1) {
                            return profile;
                          }
                          
                        } else if (!match$1) {
                          return ;
                        }
                        if (statusOn(match) && profile.rank !== "TSI" && rankOn(profile.rank)) {
                          return profile;
                        }
                        
                      }));
                var match$1 = State__Memory.Current.get();
                var navbarDisabled = Curry._1(Prelude.OptionExported.$$Option.isNone, match$1.vessel) || Curry._1(Prelude.OptionExported.$$Option.isNone, match$1.profile);
                return React.jsxs(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.WithLogo.make, {})),
                            footer: Caml_option.some(React.jsx(PageNavigation.make, {
                                      disabled: navbarDisabled
                                    })),
                            children: [
                              React.jsx(ProfileFilter.make, {
                                    showModal: match.showModal,
                                    filters: filterState,
                                    disabledFilters: match.disabledFilters,
                                    filterUpdate: (function (filter, newValue) {
                                        filterUpdate(vnode, filter, newValue);
                                      }),
                                    onClear: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      profile: curState.profile,
                                                      staffInfo: curState.staffInfo,
                                                      profiles: curState.profiles,
                                                      showModal: curState.showModal,
                                                      filterState: ProfileFilter.Filter.defaultFalse,
                                                      disabledFilters: curState.disabledFilters
                                                    });
                                        }
                                      }),
                                    onClose: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      profile: curState.profile,
                                                      staffInfo: curState.staffInfo,
                                                      profiles: curState.profiles,
                                                      showModal: false,
                                                      filterState: curState.filterState,
                                                      disabledFilters: curState.disabledFilters
                                                    });
                                        }
                                      })
                                  }),
                              !usingFilters && Caml_obj.equal(filteredProfiles, []) ? ReactDOM.jsxs("div", {
                                      children: [
                                        React.jsx(LabelLandingPage.make, {
                                              text: "There are currently no active profiles for this vessel."
                                            }),
                                        React.jsx(ButtonLink.make, {
                                              children: "Add new profile",
                                              href: URLs.addProfile(vessel.id),
                                              style: "primary"
                                            })
                                      ],
                                      className: "t-no-profiles tw-px-3 tw-pt-2 tw-flex tw-flex-col tw-gap-2"
                                    }) : ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsxs("div", {
                                              children: [
                                                React.jsx(ButtonSlimIcon.make, {
                                                      icon: "filter",
                                                      onClick: (function (_event) {
                                                          var curState = vnode.state.data;
                                                          if (typeof curState !== "object") {
                                                            return ;
                                                          } else {
                                                            return Mithril.setState(vnode, {
                                                                        TAG: "Loaded",
                                                                        user: curState.user,
                                                                        vessel: curState.vessel,
                                                                        profile: curState.profile,
                                                                        staffInfo: curState.staffInfo,
                                                                        profiles: curState.profiles,
                                                                        showModal: true,
                                                                        filterState: curState.filterState,
                                                                        disabledFilters: curState.disabledFilters
                                                                      });
                                                          }
                                                        }),
                                                      className: "!tw-px-2"
                                                    }),
                                                ProfileFilter.Filter.activeFilters(filterState).map(function (filter) {
                                                      return React.jsx(ButtonFilterToggle.make, {
                                                                  text: ProfileFilter.Filter.toString(filter),
                                                                  onClick: (function (_event) {
                                                                      filterUpdate(vnode, filter, false);
                                                                    })
                                                                });
                                                    })
                                              ],
                                              className: Mithril.classList([
                                                    "tw-flex tw-flex-row tw-items-center tw-justify-start tw-flex-wrap",
                                                    "tw-gap-1 tw-px-1 tw-pt-2"
                                                  ], undefined)
                                            }),
                                        ReactDOM.jsxs("div", {
                                              children: [
                                                React.jsx(LabelLandingPage.make, {
                                                      text: "Select profile:"
                                                    }),
                                                ReactDOM.jsx("div", {
                                                      children: filteredProfiles.map(function (curProfile) {
                                                            return React.jsx(ProfileCard.make, {
                                                                        profile: curProfile,
                                                                        onClick: (function (p) {
                                                                            State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setCurrentProfile(State__Memory.Transformations.make(State__Memory.Current.get()), p)));
                                                                            var curState = vnode.state.data;
                                                                            if (typeof curState !== "object") {
                                                                              return ;
                                                                            } else {
                                                                              return Mithril.setState(vnode, {
                                                                                          TAG: "Loaded",
                                                                                          user: curState.user,
                                                                                          vessel: curState.vessel,
                                                                                          profile: p,
                                                                                          staffInfo: curState.staffInfo,
                                                                                          profiles: curState.profiles,
                                                                                          showModal: curState.showModal,
                                                                                          filterState: curState.filterState,
                                                                                          disabledFilters: curState.disabledFilters
                                                                                        });
                                                                            }
                                                                          }),
                                                                        selected: sameId(curProfile, profile)
                                                                      });
                                                          }),
                                                      className: "t-profile-cards"
                                                    })
                                              ],
                                              className: "t-profile-view tw-px-3 tw-pt-2"
                                            }),
                                        ReactDOM.jsx("div", {
                                              className: "tw-flex-grow"
                                            }),
                                        ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("div", {
                                                      className: "tw-h-6 tw-bg-fade-white"
                                                    }),
                                                ReactDOM.jsxs("div", {
                                                      children: [
                                                        React.jsx(ButtonLink.make, {
                                                              children: "Add new Profile",
                                                              href: URLs.addProfile(vessel.id)
                                                            }),
                                                        React.jsx(ButtonLink.make, {
                                                              children: "Continue",
                                                              href: "/",
                                                              disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, profile),
                                                              style: Curry._1(Prelude.OptionExported.$$Option.isSome, profile) ? "primary" : "gray"
                                                            })
                                                      ],
                                                      className: Mithril.classList([
                                                            "tw-flex tw-flex-col tw-gap-3 tw-px-3 tw-pt-1 tw-pb-5",
                                                            "tw-bg-white"
                                                          ], undefined)
                                                    })
                                              ],
                                              className: "tw-sticky tw-bottom-0 tw-w-full tw-flex tw-flex-col"
                                            })
                                      ],
                                      className: "tw-flex tw-flex-col"
                                    })
                            ]
                          });
              }));
}

function Accounts_LandingPageProfile(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(Accounts_LandingPageProfile$ProfileLandingPage, {});
              }));
}

var include = ProtectedViewMaker.Make({
      make: Accounts_LandingPageProfile,
      requireProfile: false,
      requireNonCrew: false
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */

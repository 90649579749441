// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as VesselId from "../vessel/VesselId.js";
import * as AppSchema from "../AppSchema.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__User from "./State__User.js";
import * as SyncVessels from "../api/SyncVessels.js";
import * as State__Amounts from "./State__Amounts.js";
import * as SyncVesselsList from "../api/SyncVesselsList.js";
import * as FindingCommentAPI from "../api/endpoints/FindingCommentAPI.js";
import * as UpdatedCommentAPI from "../api/endpoints/UpdatedCommentAPI.js";
import * as LevelSubmissionAPI from "../api/endpoints/LevelSubmissionAPI.js";
import * as FutureAttachmentAPI from "../api/endpoints/FutureAttachmentAPI.js";
import * as CompanyFormSubmissionAPI from "../api/endpoints/CompanyFormSubmissionAPI.js";
import * as ReadNotificationEntryAPI from "../api/endpoints/ReadNotificationEntryAPI.js";

function $$fetch() {
  return Prelude.PromisedResult.map(State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (user) {
                        return SyncVesselsList.$$do(user);
                      })).finally(function () {
                  State__Amounts.refresh();
                }), (function (param) {
                return AppSchema.Vessels.all(param.final);
              }));
}

var FetchVesselsList = {
  $$fetch: $$fetch
};

function $$fetch$1(vesselIds) {
  return Prelude.then(State__User.Auth.required(function (user) {
                  return State__User.Auth.redirectIfUnauthorized(SyncVessels.$$do({
                                  user: user,
                                  vessels: vesselIds
                                }));
                }), (function (r) {
                return Prelude.thenResolve(State__Amounts.refresh(), (function (param) {
                              return r;
                            }));
              }));
}

var FetchVessels = {
  $$fetch: $$fetch$1
};

function upload(comments) {
  var params = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.$$Array.get, comments, 0), (function (v) {
              return {
                      log_vessels: v.vessel_id
                    };
            })), {
        log_vessels: VesselId.$$null
      });
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return FindingCommentAPI.post(Caml_option.some(params), __x, comments);
                }));
}

var FindingComments = {
  upload: upload
};

function upload$1(comments) {
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return UpdatedCommentAPI.post(__x, comments);
                }));
}

var UpdatedComments = {
  upload: upload$1
};

function upload$2(entries) {
  var params = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.$$Array.get, entries, 0), (function (v) {
              return {
                      log_vessels: v.vessel_id
                    };
            })), {
        log_vessels: VesselId.$$null
      });
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return ReadNotificationEntryAPI.post(Caml_option.some(params), __x, entries);
                }));
}

var ReadNotificationEntries = {
  upload: upload$2
};

function upload$3(submissions) {
  var params = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.$$Array.get, submissions, 0), (function (v) {
              return {
                      log_vessels: v.vessel_id
                    };
            })), {
        log_vessels: VesselId.$$null
      });
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return CompanyFormSubmissionAPI.post(Caml_option.some(params), __x, submissions);
                }));
}

var FormSubmissions = {
  upload: upload$3
};

function upload$4(submissions) {
  var params = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.$$Array.get, submissions, 0), (function (v) {
              return {
                      log_vessels: v.vessel_id
                    };
            })), {
        log_vessels: VesselId.$$null
      });
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return LevelSubmissionAPI.post(Caml_option.some(params), __x, submissions);
                }));
}

var LevelSubmissions = {
  upload: upload$4
};

function upload$5(future, chunkId) {
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return FutureAttachmentAPI.uploadChunk({
                              log_vessels: future.vessel_id
                            }, __x, future, chunkId);
                }));
}

var FutureAttachment = {
  upload: upload$5
};

var Current;

var Transformations;

var Persisted;

var User;

var Auth;

var Amounts;

var Procedures;

var Inspections;

var Answers;

var UserProfiles;

export {
  Current ,
  Transformations ,
  Persisted ,
  User ,
  Auth ,
  Amounts ,
  Procedures ,
  FetchVesselsList ,
  FetchVessels ,
  Inspections ,
  Answers ,
  UserProfiles ,
  FindingComments ,
  UpdatedComments ,
  ReadNotificationEntries ,
  FormSubmissions ,
  LevelSubmissions ,
  FutureAttachment ,
}
/* VesselId Not a pure module */

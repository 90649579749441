// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ApiMaker from "../../../libs/ApiMaker.js";

var fields = [[
    "id",
    "String"
  ]];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/notifications/mark-as-read"
    });

function post(params, user, entries) {
  return Impl.postMany(params, user, entries.map(function (entry) {
                  return {
                          id: entry.id
                        };
                }));
}

export {
  Impl ,
  post ,
}
/* Impl Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Optimizing procedures planner";
}

function $$process(proc) {
  return {
          id: proc.id,
          component_id: proc.component_id
        };
}

var PlannedProceduresRewriter = InitFeedback.MakeInfallibleTransformator({
      storeName: "plannedProcedures",
      message: message,
      $$process: $$process
    });

function message$1(param, param$1) {
  return "Optimizing vessel sections";
}

function $$process$1(section) {
  return {
          id: section.id,
          vessel_id: section.vessel_id,
          name: section.name,
          type: section.type,
          image: section.image,
          picture_prompt_every_n_inspections: Caml_option.nullable_to_opt(section.picture_prompt_every_n_inspections)
        };
}

var SectionTransformator = InitFeedback.MakeInfallibleTransformator({
      storeName: "sections",
      message: message$1,
      $$process: $$process$1
    });

function message$2() {
  return "Optimizing procedures planner";
}

function migrate(param, transaction) {
  transaction.objectStore("plannedProcedures").deleteIndex("picture_prompt");
  return Prelude.PromisedResult.bind(PlannedProceduresRewriter.rewrite(transaction), (function () {
                return SectionTransformator.rewrite(transaction);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$2,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* PlannedProceduresRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as Completion from "../../common/Completion.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as CompanyFormManager from "../CompanyFormManager.js";
import * as ExpectedSubmission from "../ExpectedSubmission.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";

function loadCompletion(vnode) {
  var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
  var expectedSubmission = vnode.attrs.expectedSubmission;
  var completion = ExpectedSubmission.completion(expectedSubmission);
  var match = expectedSubmission.submission;
  var match$1 = expectedSubmission.finished;
  var tmp;
  var exit = 0;
  if (match !== undefined && !match$1) {
    tmp = Prelude.thenResolve(CompanyFormManager.getSubmissionCompletion(match), (function (completionInformation) {
            var newrecord = Caml_obj.obj_dup(completionInformation);
            return React.jsx(InspectionCompletion.make, {
                        completion: (newrecord.isOverdue = completion !== "completed", newrecord),
                        counterOverlayClassName: counterOverlayClassName
                      });
          }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var icon = completion === "overdue" || completion === "due" ? "circle" : "check_circle";
    var color = Completion.Color.getStatusColor(completion);
    tmp = Promise.resolve(React.jsx(Feather.make, {
              icon: icon,
              className: "text-" + color
            }));
  }
  Prelude.thenDo(tmp, (function (completion) {
          Mithril.setState(vnode, {
                TAG: "Loaded",
                completion: completion
              });
        }));
}

function openSubmission(vnode, $$event) {
  $$event.stopPropagation();
  $$event.preventDefault();
  KeepScrollLink.saveScroll();
  var expectedSubmission = vnode.attrs.expectedSubmission;
  var form = vnode.attrs.form;
  var submission = expectedSubmission.submission;
  if (submission !== undefined) {
    Mithril$1.route.set(URLs.newCompanyFormSubmission(form.id, submission.id, undefined, undefined));
    return ;
  }
  var match = vnode.attrs;
  var form$1 = match.form;
  var targetDate = match.expectedSubmission.start_date;
  Prelude.thenDo(CompanyFormManager.createDraftSubmission(Caml_option.some(targetDate), form$1), (function (draftSubmission) {
          Mithril$1.route.set(URLs.newCompanyFormSubmission(form$1.id, draftSubmission.id, undefined, undefined));
        }));
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadCompletion), (function (vnode) {
                var expectedSubmission = vnode.attrs.expectedSubmission;
                var match = vnode.state.data;
                var tmp;
                tmp = typeof match !== "object" ? Mithril.empty : match.completion;
                return React.jsx(TileLink.make, {
                            disabled: expectedSubmission.finished,
                            title: ExpectedSubmission.name(expectedSubmission),
                            subtitle: ExpectedSubmission.dateRange(expectedSubmission),
                            action: Caml_option.some(tmp),
                            onClick: (function (extra) {
                                return openSubmission(vnode, extra);
                              })
                          });
              }));
}

export {
  loadCompletion ,
  openSubmission ,
  make ,
}
/* URLs Not a pure module */

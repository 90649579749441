// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rating from "../../survey/Rating.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";

var url = "/survey/rating_styling_rules";

var rule = {
  TAG: "Object",
  _0: [
    [
      "label",
      "String"
    ],
    [
      "css_class",
      Rating.StylingRule.deserCssClassFromJS
    ],
    [
      "description",
      {
        TAG: "Optional",
        _0: "String"
      }
    ]
  ]
};

var fields = [
  [
    "id",
    "String"
  ],
  [
    "rules",
    {
      TAG: "Tuple",
      _0: [
        rule,
        rule,
        rule,
        rule,
        rule
      ]
    }
  ]
];

var Definition = {
  url: url,
  rule: rule,
  fields: fields
};

var include = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var get = include.get;

var getAll = include.getAll;

var options = include.options;

var postMany = include.postMany;

var postOne = include.postOne;

var postForm = include.postForm;

export {
  Definition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  get ,
  getAll ,
  options ,
  postMany ,
  postOne ,
  postForm ,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Source from "../../Source.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Settings from "../../Settings.js";
import * as AppSchema from "../../AppSchema.js";
import * as LocalFile from "../../../libs/LocalFile.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function MakeChainPipeline(M) {
  var $$do = function (state) {
    return Prelude.PromisedResult.chain(state, M.steps.map(function (param) {
                    var transformer = param[1];
                    var name = param[0];
                    return function (state) {
                      var promise;
                      try {
                        promise = transformer(state);
                      }
                      catch (raw_error){
                        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                        console.error("Crashed:", name, error);
                        return Prelude.rejectWithError(error);
                      }
                      return Prelude.thenResolve(Prelude.catchResolve(promise, (function (error) {
                                        console.error("Crashed:", name, error);
                                        return {
                                                TAG: "Error",
                                                _0: error
                                              };
                                      })), (function (r) {
                                    if (r.TAG !== "Ok") {
                                      console.error("Failed:", name, r._0);
                                    }
                                    return r;
                                  }));
                    };
                  }));
  };
  return {
          $$do: $$do
        };
}

function stepModToStepFn(trace, mod) {
  return [
          trace,
          mod.$$do
        ];
}

function MakeStandardAPIPipeline(S) {
  var steps = [
    stepModToStepFn(S.name + ": Read server state", S.ReadServerState),
    stepModToStepFn(S.name + ": Fill initial status from local DB", S.FillInitialStatus),
    stepModToStepFn(S.name + ": Apply locally known state", S.ApplyLocallyKnownState),
    stepModToStepFn(S.name + ": Update local DB", S.UpdateDB),
    stepModToStepFn(S.name + ": Collect files", S.CollectFiles),
    stepModToStepFn(S.name + ": Clean up", S.CleanUpState)
  ];
  var $$do = function (state) {
    return Prelude.PromisedResult.chain(state, steps.map(function (param) {
                    var transformer = param[1];
                    var name = param[0];
                    return function (state) {
                      var promise;
                      try {
                        promise = transformer(state);
                      }
                      catch (raw_error){
                        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                        console.error("Crashed:", name, error);
                        return Prelude.rejectWithError(error);
                      }
                      return Prelude.thenResolve(Prelude.catchResolve(promise, (function (error) {
                                        console.error("Crashed:", name, error);
                                        return {
                                                TAG: "Error",
                                                _0: error
                                              };
                                      })), (function (r) {
                                    if (r.TAG !== "Ok") {
                                      console.error("Failed:", name, r._0);
                                    }
                                    return r;
                                  }));
                    };
                  }));
  };
  return {
          $$do: $$do
        };
}

function MakeNoOpPipelineStep($star) {
  var $$do = function (state) {
    return Promise.resolve({
                TAG: "Ok",
                _0: state
              });
  };
  return {
          $$do: $$do
        };
}

function $$do(state) {
  return Promise.resolve({
              TAG: "Ok",
              _0: state
            });
}

var NoOpPipelineStep = {
  $$do: $$do
};

function MakeFailingPipelineStep($star) {
  var Failure = /* @__PURE__ */Caml_exceptions.create("ServerSyncPipeline.MakeFailingPipelineStep(*).Failure");
  var $$do = function (state) {
    if (Settings.DeveloperDebug.active) {
      throw {
            RE_EXN_ID: Failure,
            code: 500,
            response: {},
            message: "Developer's error",
            Error: new Error()
          };
    }
    return Promise.resolve({
                TAG: "Ok",
                _0: state
              });
  };
  return {
          Failure: Failure,
          $$do: $$do
        };
}

var Failure = /* @__PURE__ */Caml_exceptions.create("ServerSyncPipeline.MakeFailingPipelineStep(*).Failure");

function $$do$1(state) {
  if (Settings.DeveloperDebug.active) {
    throw {
          RE_EXN_ID: Failure,
          code: 500,
          response: {},
          message: "Developer's error",
          Error: new Error()
        };
  }
  return Promise.resolve({
              TAG: "Ok",
              _0: state
            });
}

var FailingPipelineStep = {
  Failure: Failure,
  $$do: $$do$1
};

function MakeStandardCleanup(V) {
  var $$do = function (state) {
    Curry._2(Prelude.$$Array.forEach, V.views, (function (v) {
            v.clear(state.initial);
            v.clear(state.final);
          }));
    return Promise.resolve({
                TAG: "Ok",
                _0: state
              });
  };
  return {
          $$do: $$do
        };
}

function MakeFilesCollector(V) {
  var $$do = function (appstate, keepCloud, keepDevice, onlyExisting) {
    var filterFilesBySource = function (files) {
      if (keepCloud) {
        if (keepDevice) {
          return files.map(Source.strip);
        } else {
          return Curry._2(Prelude.$$Array.keepMap, files, Source.cloud);
        }
      } else if (keepDevice) {
        return Curry._2(Prelude.$$Array.keepMap, files, Source.device);
      } else {
        return [];
      }
    };
    var filterFilesByExistence;
    if (onlyExisting) {
      var existingFiles = {
        contents: undefined
      };
      filterFilesByExistence = (function (files) {
          var getKnownIds = function (param) {
            var ids = existingFiles.contents;
            if (ids !== undefined) {
              return Promise.resolve(Caml_option.valFromOption(ids));
            } else {
              return Prelude.thenResolve(LocalFile.allIds(), (function (ids) {
                            var result = LocalFile.UUID.$$Set.fromArray(ids);
                            existingFiles.contents = Caml_option.some(result);
                            return result;
                          }));
            }
          };
          return Prelude.thenResolve(getKnownIds(), (function (ids) {
                        return {
                                TAG: "Ok",
                                _0: Curry._2(Prelude.$$Array.keep, files, (function (f) {
                                        return LocalFile.UUID.$$Set.has(ids, f.id);
                                      }))
                              };
                      }));
        });
    } else {
      filterFilesByExistence = (function (files) {
          return Promise.resolve({
                      TAG: "Ok",
                      _0: files
                    });
        });
    }
    var filterFiles = function (files) {
      return filterFilesByExistence(filterFilesBySource(files));
    };
    return Prelude.PromisedResult.chain(appstate, V.views.map(function (v) {
                    return function (s) {
                      return Prelude.PromisedResult.map(filterFiles(v.getImages(s)), (function (extra) {
                                    return AppSchema.Files.updateMany(s, extra);
                                  }));
                    };
                  }));
  };
  var $$do$1 = function (state) {
    return Prelude.PromisedResult.map($$do(state.initial, true, false, true), (function (initial) {
                  return {
                          user: state.user,
                          vessels: state.vessels,
                          initial: initial,
                          final: state.final
                        };
                }));
  };
  var $$do$2 = function (state) {
    return Prelude.PromisedResult.map($$do(state.final, true, true, false), (function ($$final) {
                  return {
                          user: state.user,
                          vessels: state.vessels,
                          initial: state.initial,
                          final: $$final
                        };
                }));
  };
  var $$do$3 = function (state) {
    return Prelude.PromisedResult.chain(state, [
                $$do$1,
                $$do$2
              ]);
  };
  return {
          $$do: $$do$3
        };
}

export {
  MakeChainPipeline ,
  MakeStandardAPIPipeline ,
  MakeNoOpPipelineStep ,
  NoOpPipelineStep ,
  MakeFailingPipelineStep ,
  FailingPipelineStep ,
  MakeStandardCleanup ,
  MakeFilesCollector ,
}
/* Settings Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Attachment from "../attachment_chunks/Attachment.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function map(val, fn) {
  return Curry._2(Prelude.OptionExported.$$Option.map, val, fn);
}

function fmap(val, fn) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, val, (function (v) {
                if (typeof v === "object") {
                  return Caml_option.some(fn(v.VAL));
                }
                
              }));
}

function serialize(value) {
  return Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                if (typeof v !== "object") {
                  return {
                          not_applicable: true
                        };
                }
                var v$1 = v.VAL;
                var variant = v$1.NAME;
                if (variant === "MultipleDatesTexts") {
                  var match = v$1.VAL;
                  return {
                          dates: match[0].map(function (__x) {
                                return Curry._2(Prelude.OptionExported.$$Option.map, __x, (function (prim) {
                                              return prim.toISOString();
                                            }));
                              }),
                          answer: match[1]
                        };
                }
                if (variant === "WeightInfo") {
                  var match$1 = v$1.VAL;
                  return {
                          texts: match$1[0],
                          numbers: match$1[1],
                          multi_texts: match$1[2]
                        };
                }
                if (variant === "MultipleDates") {
                  return {
                          dates: v$1.VAL
                        };
                }
                if (variant === "Quiz" || variant === "MultipleTexts" || variant === "YesNo" || variant === "Int" || variant === "MultipleIntegers") {
                  return {
                          answer: v$1.VAL
                        };
                }
                if (variant === "Rating") {
                  var match$2 = v$1.VAL;
                  return {
                          rating: match$2[0],
                          annotations: match$2[1]
                        };
                }
                if (variant === "OKNotOK") {
                  var match$3 = v$1.VAL;
                  return {
                          answer: match$3[0],
                          annotations: match$3[1]
                        };
                }
                if (variant === "Pictures") {
                  return {
                          attachments: v$1.VAL
                        };
                }
                if (variant === "MultipleDatesIntegers") {
                  var match$4 = v$1.VAL;
                  return {
                          dates: match$4[0].map(function (__x) {
                                return Curry._2(Prelude.OptionExported.$$Option.map, __x, (function (prim) {
                                              return prim.toISOString();
                                            }));
                              }),
                          answer: match$4[1]
                        };
                }
                if (variant === "Date") {
                  return {
                          date: v$1.VAL.toISOString()
                        };
                }
                if (variant === "MultipleDatesYesNo") {
                  var match$5 = v$1.VAL;
                  return {
                          dates: match$5[0].map(function (__x) {
                                return Curry._2(Prelude.OptionExported.$$Option.map, __x, (function (prim) {
                                              return prim.toISOString();
                                            }));
                              }),
                          answer: match$5[1]
                        };
                }
                if (variant === "Signature") {
                  return {
                          signature: v$1.VAL
                        };
                }
                if (variant === "Text") {
                  return {
                          text: v$1.VAL
                        };
                }
                throw {
                      RE_EXN_ID: Prelude.NotImplementedError,
                      Error: new Error()
                    };
              }));
}

var Serializer = {
  serialize: serialize
};

function MakeValueDeserializer(T) {
  var fields = {
    TAG: "Object",
    _0: T.fields
  };
  var Impl = $$JSON.MakeDeserializer({
        fields: fields
      });
  var fromJSON = function (d) {
    return Curry._2(Prelude.Result.map, Impl.fromJSON(d), T.map);
  };
  return {
          fields: T.fields,
          map: T.map,
          Impl: Impl,
          fromJSON: fromJSON
        };
}

var fields = [[
    "answer",
    "Boolean"
  ]];

function map$1(v) {
  return {
          NAME: "YesNo",
          VAL: v.answer
        };
}

var fields$1 = {
  TAG: "Object",
  _0: fields
};

var Impl = $$JSON.MakeDeserializer({
      fields: fields$1
    });

function fromJSON(d) {
  return Curry._2(Prelude.Result.map, Impl.fromJSON(d), map$1);
}

var YesNo = {
  fields: fields,
  map: map$1,
  Impl: Impl,
  fromJSON: fromJSON
};

var fields$2 = [
  [
    "answer",
    "Boolean"
  ],
  [
    "annotations",
    {
      TAG: "Array",
      _0: "String"
    }
  ]
];

function map$2(v) {
  return {
          NAME: "OKNotOK",
          VAL: [
            v.answer,
            v.annotations
          ]
        };
}

var fields$3 = {
  TAG: "Object",
  _0: fields$2
};

var Impl$1 = $$JSON.MakeDeserializer({
      fields: fields$3
    });

function fromJSON$1(d) {
  return Curry._2(Prelude.Result.map, Impl$1.fromJSON(d), map$2);
}

var OKNotOK = {
  fields: fields$2,
  map: map$2,
  Impl: Impl$1,
  fromJSON: fromJSON$1
};

var fields$4 = [[
    "date",
    "Date"
  ]];

function map$3(v) {
  return {
          NAME: "Date",
          VAL: v.date
        };
}

var fields$5 = {
  TAG: "Object",
  _0: fields$4
};

var Impl$2 = $$JSON.MakeDeserializer({
      fields: fields$5
    });

function fromJSON$2(d) {
  return Curry._2(Prelude.Result.map, Impl$2.fromJSON(d), map$3);
}

var $$Date = {
  fields: fields$4,
  map: map$3,
  Impl: Impl$2,
  fromJSON: fromJSON$2
};

var fields$6 = [[
    "text",
    "String"
  ]];

function map$4(p) {
  return {
          NAME: "Text",
          VAL: p.text
        };
}

var fields$7 = {
  TAG: "Object",
  _0: fields$6
};

var Impl$3 = $$JSON.MakeDeserializer({
      fields: fields$7
    });

function fromJSON$3(d) {
  return Curry._2(Prelude.Result.map, Impl$3.fromJSON(d), map$4);
}

var $$Text = {
  fields: fields$6,
  map: map$4,
  Impl: Impl$3,
  fromJSON: fromJSON$3
};

var fields$8 = [[
    "answer",
    "Int"
  ]];

function map$5(p) {
  return {
          NAME: "Int",
          VAL: p.answer
        };
}

var fields$9 = {
  TAG: "Object",
  _0: fields$8
};

var Impl$4 = $$JSON.MakeDeserializer({
      fields: fields$9
    });

function fromJSON$4(d) {
  return Curry._2(Prelude.Result.map, Impl$4.fromJSON(d), map$5);
}

var Int = {
  fields: fields$8,
  map: map$5,
  Impl: Impl$4,
  fromJSON: fromJSON$4
};

var fields$10 = [
  [
    "rating",
    "Int"
  ],
  [
    "annotations",
    {
      TAG: "OptionalWithDefault",
      _0: {
        TAG: "Array",
        _0: "String"
      },
      _1: []
    }
  ]
];

function map$6(p) {
  return {
          NAME: "Rating",
          VAL: [
            p.rating,
            p.annotations
          ]
        };
}

var fields$11 = {
  TAG: "Object",
  _0: fields$10
};

var Impl$5 = $$JSON.MakeDeserializer({
      fields: fields$11
    });

function fromJSON$5(d) {
  return Curry._2(Prelude.Result.map, Impl$5.fromJSON(d), map$6);
}

var Rating = {
  fields: fields$10,
  map: map$6,
  Impl: Impl$5,
  fromJSON: fromJSON$5
};

var fields$12 = [[
    "dates",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Date"
      }
    }
  ]];

function map$7(v) {
  return {
          NAME: "MultipleDates",
          VAL: v.dates
        };
}

var fields$13 = {
  TAG: "Object",
  _0: fields$12
};

var Impl$6 = $$JSON.MakeDeserializer({
      fields: fields$13
    });

function fromJSON$6(d) {
  return Curry._2(Prelude.Result.map, Impl$6.fromJSON(d), map$7);
}

var MultipleDates = {
  fields: fields$12,
  map: map$7,
  Impl: Impl$6,
  fromJSON: fromJSON$6
};

var fields$14 = [
  [
    "dates",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Date"
      }
    }
  ],
  [
    "answer",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Boolean"
      }
    }
  ]
];

function map$8(v) {
  return {
          NAME: "MultipleDatesYesNo",
          VAL: [
            v.dates,
            v.answer
          ]
        };
}

var fields$15 = {
  TAG: "Object",
  _0: fields$14
};

var Impl$7 = $$JSON.MakeDeserializer({
      fields: fields$15
    });

function fromJSON$7(d) {
  return Curry._2(Prelude.Result.map, Impl$7.fromJSON(d), map$8);
}

var MultipleDatesYesNo = {
  fields: fields$14,
  map: map$8,
  Impl: Impl$7,
  fromJSON: fromJSON$7
};

var fields$16 = [
  [
    "dates",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Date"
      }
    }
  ],
  [
    "answer",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Int"
      }
    }
  ]
];

function map$9(v) {
  return {
          NAME: "MultipleDatesIntegers",
          VAL: [
            v.dates,
            v.answer
          ]
        };
}

var fields$17 = {
  TAG: "Object",
  _0: fields$16
};

var Impl$8 = $$JSON.MakeDeserializer({
      fields: fields$17
    });

function fromJSON$8(d) {
  return Curry._2(Prelude.Result.map, Impl$8.fromJSON(d), map$9);
}

var MultipleDatesIntegers = {
  fields: fields$16,
  map: map$9,
  Impl: Impl$8,
  fromJSON: fromJSON$8
};

var fields$18 = [
  [
    "dates",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Date"
      }
    }
  ],
  [
    "answer",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "String"
      }
    }
  ]
];

function map$10(v) {
  return {
          NAME: "MultipleDatesTexts",
          VAL: [
            v.dates,
            v.answer
          ]
        };
}

var fields$19 = {
  TAG: "Object",
  _0: fields$18
};

var Impl$9 = $$JSON.MakeDeserializer({
      fields: fields$19
    });

function fromJSON$9(d) {
  return Curry._2(Prelude.Result.map, Impl$9.fromJSON(d), map$10);
}

var MultipleDatesTexts = {
  fields: fields$18,
  map: map$10,
  Impl: Impl$9,
  fromJSON: fromJSON$9
};

var fields$20 = [[
    "answer",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Int"
      }
    }
  ]];

function map$11(v) {
  return {
          NAME: "MultipleIntegers",
          VAL: v.answer
        };
}

var fields$21 = {
  TAG: "Object",
  _0: fields$20
};

var Impl$10 = $$JSON.MakeDeserializer({
      fields: fields$21
    });

function fromJSON$10(d) {
  return Curry._2(Prelude.Result.map, Impl$10.fromJSON(d), map$11);
}

var MultipleIntegers = {
  fields: fields$20,
  map: map$11,
  Impl: Impl$10,
  fromJSON: fromJSON$10
};

var fields$22 = [[
    "answer",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "String"
      }
    }
  ]];

function map$12(v) {
  return {
          NAME: "MultipleTexts",
          VAL: v.answer
        };
}

var fields$23 = {
  TAG: "Object",
  _0: fields$22
};

var Impl$11 = $$JSON.MakeDeserializer({
      fields: fields$23
    });

function fromJSON$11(d) {
  return Curry._2(Prelude.Result.map, Impl$11.fromJSON(d), map$12);
}

var MultipleTexts = {
  fields: fields$22,
  map: map$12,
  Impl: Impl$11,
  fromJSON: fromJSON$11
};

var fields$24 = [[
    "answer",
    {
      TAG: "Array",
      _0: "String"
    }
  ]];

function map$13(v) {
  return {
          NAME: "Quiz",
          VAL: v.answer
        };
}

var fields$25 = {
  TAG: "Object",
  _0: fields$24
};

var Impl$12 = $$JSON.MakeDeserializer({
      fields: fields$25
    });

function fromJSON$12(d) {
  return Curry._2(Prelude.Result.map, Impl$12.fromJSON(d), map$13);
}

var Quiz = {
  fields: fields$24,
  map: map$13,
  Impl: Impl$12,
  fromJSON: fromJSON$12
};

var fields$26 = [[
    "attachments",
    {
      TAG: "Collection",
      _0: Attachment.Deserializer
    }
  ]];

function map$14(p) {
  return {
          NAME: "Pictures",
          VAL: p.attachments
        };
}

var fields$27 = {
  TAG: "Object",
  _0: fields$26
};

var Impl$13 = $$JSON.MakeDeserializer({
      fields: fields$27
    });

function fromJSON$13(d) {
  return Curry._2(Prelude.Result.map, Impl$13.fromJSON(d), map$14);
}

var Pictures = {
  fields: fields$26,
  map: map$14,
  Impl: Impl$13,
  fromJSON: fromJSON$13
};

var fields$28 = [
  [
    "texts",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "String"
      }
    }
  ],
  [
    "numbers",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "Float"
      }
    }
  ],
  [
    "multi_texts",
    {
      TAG: "Array",
      _0: {
        TAG: "Optional",
        _0: "String"
      }
    }
  ]
];

function map$15(v) {
  return {
          NAME: "WeightInfo",
          VAL: [
            v.texts,
            v.numbers,
            v.multi_texts
          ]
        };
}

var fields$29 = {
  TAG: "Object",
  _0: fields$28
};

var Impl$14 = $$JSON.MakeDeserializer({
      fields: fields$29
    });

function fromJSON$14(d) {
  return Curry._2(Prelude.Result.map, Impl$14.fromJSON(d), map$15);
}

var WeightInfo = {
  fields: fields$28,
  map: map$15,
  Impl: Impl$14,
  fromJSON: fromJSON$14
};

var fields$30 = [[
    "signature",
    {
      TAG: "Deserializer",
      _0: Attachment.Deserializer
    }
  ]];

function map$16(p) {
  return {
          NAME: "Signature",
          VAL: p.signature
        };
}

var fields$31 = {
  TAG: "Object",
  _0: fields$30
};

var Impl$15 = $$JSON.MakeDeserializer({
      fields: fields$31
    });

function fromJSON$15(d) {
  return Curry._2(Prelude.Result.map, Impl$15.fromJSON(d), map$16);
}

var Signature = {
  fields: fields$30,
  map: map$16,
  Impl: Impl$15,
  fromJSON: fromJSON$15
};

var fields$32 = {
  TAG: "Object",
  _0: [[
      "not_applicable",
      {
        TAG: "Optional",
        _0: "Boolean"
      }
    ]]
};

var Impl$16 = $$JSON.MakeDeserializer({
      fields: fields$32
    });

function map$17(v, fn) {
  var match = Impl$16.fromJSON(v);
  if (match.TAG === "Ok") {
    var match$1 = match._0.not_applicable;
    if (match$1 !== undefined && match$1) {
      return {
              TAG: "Ok",
              _0: "NotApplicable"
            };
    }
    
  }
  return Curry._2(Prelude.Result.map, fn(v), (function (r) {
                return {
                        NAME: "Value",
                        VAL: r
                      };
              }));
}

var NotApplicableDiscriminator = {
  Impl: Impl$16,
  map: map$17
};

function valueFromJSON(kind, v) {
  if (typeof kind !== "object") {
    if (kind === "PhotoQuestion") {
      return fromJSON$13(v);
    } else if (kind === "TextQuestion") {
      return fromJSON$3(v);
    } else if (kind === "DateQuestion") {
      return fromJSON$2(v);
    } else if (kind === "SignatureQuestion") {
      return fromJSON$15(v);
    } else {
      return fromJSON$4(v);
    }
  }
  var variant = kind.NAME;
  if (variant === "OKNotOKQuestion") {
    return fromJSON$1(v);
  } else if (variant === "MultipleTextsQuestion") {
    return fromJSON$11(v);
  } else if (variant === "RatingQuestion") {
    return fromJSON$5(v);
  } else if (variant === "WeightInfoQuestion") {
    return fromJSON$14(v);
  } else if (variant === "MultipleDatesTextsQuestion") {
    return fromJSON$9(v);
  } else if (variant === "MultipleDatesYesNoQuestion") {
    return fromJSON$7(v);
  } else if (variant === "MultipleIntegersQuestion") {
    return fromJSON$10(v);
  } else if (variant === "YesNoQuestion") {
    return fromJSON(v);
  } else if (variant === "MultipleDatesQuestion") {
    return fromJSON$6(v);
  } else if (variant === "Quiz") {
    return fromJSON$12(v);
  } else {
    return fromJSON$8(v);
  }
}

function fromJSON$16(kind, data) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, data, (function (v) {
                    return map$17(v, (function (extra) {
                                  return valueFromJSON(kind, extra);
                                }));
                  })), {
              TAG: "Ok",
              _0: undefined
            });
}

var Deserializer = {
  MakeValueDeserializer: MakeValueDeserializer,
  YesNo: YesNo,
  OKNotOK: OKNotOK,
  $$Date: $$Date,
  $$Text: $$Text,
  Int: Int,
  Rating: Rating,
  MultipleDates: MultipleDates,
  MultipleDatesYesNo: MultipleDatesYesNo,
  MultipleDatesIntegers: MultipleDatesIntegers,
  MultipleDatesTexts: MultipleDatesTexts,
  MultipleIntegers: MultipleIntegers,
  MultipleTexts: MultipleTexts,
  Quiz: Quiz,
  Pictures: Pictures,
  WeightInfo: WeightInfo,
  Signature: Signature,
  NotApplicableDiscriminator: NotApplicableDiscriminator,
  valueFromJSON: valueFromJSON,
  fromJSON: fromJSON$16
};

function toHuman(value) {
  return map(value, (function (v) {
                if (typeof v !== "object") {
                  return [];
                }
                var value = v.VAL;
                if (typeof value !== "object") {
                  return [""];
                }
                var variant = value.NAME;
                if (variant === "MultipleDatesTexts") {
                  var match = value.VAL;
                  return Curry._2(Prelude.$$Array.concat, match[0].map(function (date) {
                                  if (date !== undefined) {
                                    return Prelude.default(Prelude.$$Array.first(Caml_option.valFromOption(date).toISOString().split("T")), "None");
                                  } else {
                                    return "None";
                                  }
                                }), match[1].map(function (val) {
                                  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, val, (function (v) {
                                                    return v;
                                                  })), "");
                                }));
                }
                if (variant === "WeightInfo") {
                  var match$1 = value.VAL;
                  return Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, match$1[0].map(function (val) {
                                      return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, val, (function (v) {
                                                        return v;
                                                      })), "");
                                    }), match$1[1].map(function (val) {
                                      return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, val, (function (v) {
                                                        return String(v);
                                                      })), "");
                                    })), match$1[2].map(function (val) {
                                  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, val, (function (v) {
                                                    return v;
                                                  })), "");
                                }));
                }
                if (variant === "MultipleDates") {
                  return value.VAL.map(function (date) {
                              if (date !== undefined) {
                                return Prelude.default(Prelude.$$Array.first(Caml_option.valFromOption(date).toISOString().split("T")), "None");
                              } else {
                                return "None";
                              }
                            });
                }
                if (variant === "MultipleIntegers") {
                  return value.VAL.map(function (val) {
                              return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, val, (function (v) {
                                                return String(v);
                                              })), "");
                            });
                }
                if (variant === "Rating") {
                  return [value.VAL[0] > 2 ? "OK" : "Not OK"];
                }
                if (variant === "Int") {
                  return [String(value.VAL)];
                }
                if (variant === "YesNo") {
                  if (value.VAL) {
                    return ["Yes"];
                  } else {
                    return ["No"];
                  }
                }
                if (variant === "OKNotOK") {
                  if (value.VAL[0]) {
                    return ["OK"];
                  } else {
                    return ["Not OK"];
                  }
                }
                if (variant === "MultipleTexts") {
                  return value.VAL.map(function (val) {
                              return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, val, (function (v) {
                                                return v;
                                              })), "");
                            });
                }
                if (variant === "MultipleDatesIntegers") {
                  var match$2 = value.VAL;
                  return Curry._2(Prelude.$$Array.concat, match$2[0].map(function (date) {
                                  if (date !== undefined) {
                                    return Prelude.default(Prelude.$$Array.first(Caml_option.valFromOption(date).toISOString().split("T")), "None");
                                  } else {
                                    return "None";
                                  }
                                }), match$2[1].map(function (val) {
                                  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, val, (function (v) {
                                                    return String(v);
                                                  })), "");
                                }));
                }
                if (variant === "Date") {
                  return [Prelude.default(Prelude.$$Array.first(value.VAL.toISOString().split("T")), "")];
                }
                if (variant !== "MultipleDatesYesNo") {
                  if (variant === "Quiz") {
                    return value.VAL;
                  } else if (variant === "Text") {
                    return [value.VAL];
                  } else {
                    return [""];
                  }
                }
                var match$3 = value.VAL;
                return Curry._2(Prelude.$$Array.concat, match$3[0].map(function (date) {
                                if (date !== undefined) {
                                  return Prelude.default(Prelude.$$Array.first(Caml_option.valFromOption(date).toISOString().split("T")), "None");
                                } else {
                                  return "None";
                                }
                              }), match$3[1].map(function (val) {
                                if (Caml_obj.equal(val, true)) {
                                  return "Yes";
                                } else {
                                  return "No";
                                }
                              }));
              }));
}

var $$null;

export {
  $$null ,
  map ,
  fmap ,
  Serializer ,
  Deserializer ,
  toHuman ,
}
/* Impl Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../../libs/ApiMaker.js";
import * as Settings from "../../../Settings.js";
import * as UserAgent from "../../../runtime/UserAgent.js";
import * as AnswerValue from "../../../tree/AnswerValue.js";
import * as AttachmentAPI from "../AttachmentAPI.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SimpleSerializer from "../../common/SimpleSerializer.js";

function convert(answer) {
  return {
          id: answer.id,
          level_id: answer.level_id,
          vessel_id: answer.vessel_id,
          question_id: answer.question_id,
          user_id: answer.user_id,
          profile_id: answer.profile_id,
          value: AnswerValue.Serializer.serialize(answer.value),
          timestamp: answer.timestamp.toISOString(),
          remarks: answer.remarks,
          walked_steps: answer.walked_steps,
          expected_attachment_ids: answer.evidences.map(function (e) {
                return e.id;
              }),
          app_version: Prelude.default(answer.app_version, Settings.releaseVersion),
          app_user_agent: UserAgent.getUA(),
          app_browser_name: UserAgent.getBrowser().name,
          app_browser_version: UserAgent.getBrowser().version,
          app_device_name: UserAgent.getDeviceString().trim(),
          app_device_cpu: UserAgent.getCPU().architecture,
          app_device_os: UserAgent.getOS().name,
          picture_prompted: answer.picture_prompted,
          sire2_vetting_period_id: answer.sire2_vetting_period_id
        };
}

var Serializer = SimpleSerializer.MakeSimpleSerializer({
      convert: convert
    });

var fields = [
  [
    "id",
    "String"
  ],
  [
    "level_id",
    "String"
  ],
  [
    "vessel_id",
    "String"
  ],
  [
    "question_id",
    "String"
  ],
  [
    "user_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "profile_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "value",
    "Any"
  ],
  [
    "timestamp",
    "Date"
  ],
  [
    "remarks",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "walked_steps",
    "Int"
  ],
  [
    "evidences",
    {
      TAG: "Collection",
      _0: AttachmentAPI.Basic.Deserializer
    }
  ],
  [
    "picture_prompted",
    "Boolean"
  ],
  [
    "sire2_vetting_period_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ]
];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/tree/answers"
    });

var InvalidLocalInspectionData = /* @__PURE__ */Caml_exceptions.create("AnswerAPI.InvalidLocalInspectionData");

function post(user, answers) {
  var answers$1 = Prelude.ManyResults.bail(answers.map(Serializer.serialize));
  if (answers$1.TAG === "Ok") {
    return Impl.postMany(undefined, user, answers$1._0);
  }
  var message = answers$1._0;
  return Prelude.rejectWithError({
              RE_EXN_ID: InvalidLocalInspectionData,
              code: 400,
              response: {
                value: Prelude.default(JSON.stringify(message), "Could not serialize")
              },
              message: Prelude.default(JSON.stringify(message), "Could not serialize")
            });
}

var Deserializer = {};

function get(params, payload, user, id) {
  return Impl.get(params, payload, user, id);
}

function getMany(params, payload, user, ids) {
  return Impl.getMany(params, payload, undefined, user, ids);
}

function getAll(params, payload, user) {
  return Impl.getAll(params, payload, undefined, user);
}

export {
  Serializer ,
  Impl ,
  InvalidLocalInspectionData ,
  post ,
  Deserializer ,
  get ,
  getMany ,
  getAll ,
}
/* Serializer Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Section from "../Section.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as VesselId from "../VesselId.js";
import * as Component from "../Component.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Inspection from "../../survey/Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ComponentId from "../ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as ComponentType from "../ComponentType.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as Mithril__Route from "../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as PlannedProcedure from "../../survey/PlannedProcedure.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var section = vnode.attrs.section;
                    var componentType = vnode.attrs.componentType;
                    var asPlanned = vnode.attrs.asPlanned;
                    Prelude.thenDo(Query.$$do(asPlanned ? [
                                {
                                  TAG: "Read",
                                  _0: (function (param) {
                                      var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                      newrecord.plannedProcedures = "All";
                                      newrecord.components = {
                                        TAG: "And",
                                        _0: Component.ofSections([section.id]),
                                        _1: Component.ofType(componentType)
                                      };
                                      return newrecord;
                                    })
                                },
                                {
                                  TAG: "Read",
                                  _0: (function (param) {
                                      var componentIds = Component.ids(param.components);
                                      var plannedProceduresIds = param.plannedProcedures.map(function (pp) {
                                            return pp.id;
                                          });
                                      var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                      newrecord.plannedProcedures = "All";
                                      newrecord.procedures = {
                                        TAG: "In",
                                        _0: plannedProceduresIds
                                      };
                                      newrecord.components = {
                                        TAG: "In",
                                        _0: componentIds
                                      };
                                      return newrecord;
                                    })
                                }
                              ] : [
                                {
                                  TAG: "Read",
                                  _0: (function (param) {
                                      var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                      newrecord.components = {
                                        TAG: "And",
                                        _0: Component.ofSections([section.id]),
                                        _1: Component.ofType(componentType)
                                      };
                                      return newrecord;
                                    })
                                },
                                {
                                  TAG: "Read",
                                  _0: (function (param) {
                                      var componentIds = Component.ids(param.components);
                                      var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                      newrecord.plannedProcedures = asPlanned ? PlannedProcedure.ofComponents(componentIds) : "NoOp";
                                      newrecord.procedures = Procedure.ofComponents(componentIds);
                                      return newrecord;
                                    })
                                }
                              ]), (function (param) {
                            var procedures = param.procedures;
                            var plannedProcedureIds = ProcedureId.MutableSet.fromArray(PlannedProcedure.ids(param.plannedProcedures));
                            var procedures$1 = asPlanned ? Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
                                      return ProcedureId.MutableSet.has(plannedProcedureIds, p.id);
                                    })) : procedures;
                            var componentIds = Procedure.componentIds(procedures$1);
                            var components = Curry._2(Prelude.$$Array.keep, param.components, (function (c) {
                                    return ComponentId.MutableSet.has(componentIds, c.id);
                                  }));
                            var futureState;
                            if (Prelude.$$Array.isEmpty(components)) {
                              futureState = Promise.resolve("Hidden");
                            } else {
                              Mithril.setState(vnode, "Visible");
                              var procedureIds = Procedure.ids(procedures$1);
                              var newrecord = Caml_obj.obj_dup(Query.makeRead());
                              futureState = Prelude.thenResolve(Query.read((newrecord.newInspections = NewInspection.ofProcedures(procedureIds), newrecord.inspections = Inspection.ofProcedures(procedureIds), newrecord)), (function (param) {
                                      return {
                                              TAG: "Loaded",
                                              completion: Section.getCompletion(section, components, procedures$1),
                                              dueStatusText: Procedure.getGreatestOverdueText(undefined, procedures$1)
                                            };
                                    }));
                            }
                            Prelude.thenDo(futureState, (function (state) {
                                    Mithril.setState(vnode, state);
                                  }));
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  switch (match) {
                    case "NotReady" :
                    case "Hidden" :
                        return Mithril.empty;
                    case "Visible" :
                        var section = vnode.attrs.section;
                        var componentType = vnode.attrs.componentType;
                        var asPlanned = vnode.attrs.asPlanned;
                        return React.jsx(TileLink.make, {
                                    title: section.name,
                                    href: URLs.sectionComponents(section.id, componentType, asPlanned),
                                    subtitle: "Loading..."
                                  });
                    
                  }
                } else {
                  var section$1 = vnode.attrs.section;
                  var componentType$1 = vnode.attrs.componentType;
                  var asPlanned$1 = vnode.attrs.asPlanned;
                  return React.jsx(TileLink.make, {
                              title: section$1.name,
                              href: URLs.sectionComponents(section$1.id, componentType$1, asPlanned$1),
                              subtitle: match.dueStatusText,
                              action: Caml_option.some(React.jsx(InspectionCompletion.make, {
                                        completion: match.completion,
                                        counterOverlayClassName: "completion-group"
                                      }))
                            });
                }
              }));
}

var SectionTile = {
  make: make
};

function title(state) {
  if (typeof state !== "object") {
    return "";
  }
  var match = state.componentType;
  if (match === "structural") {
    return "Hull & Equipment";
  } else if (match === "checklist") {
    return "Checklists";
  } else {
    return "Safety";
  }
}

function shouldShowBackButton(componentType, vessels) {
  if (componentType === "safety" || vessels.length > 1) {
    return true;
  }
  if (vessels.length !== 1) {
    return false;
  }
  var vessel = vessels[0];
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vessel.modules, (function (modules) {
                    if (modules.checklist) {
                      if (modules.structural || modules.safety) {
                        return true;
                      } else {
                        return false;
                      }
                    } else if (modules.structural && modules.safety) {
                      return true;
                    } else {
                      return false;
                    }
                  })), false);
}

function make$1(_vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var componentType = Prelude.default(Prelude.maybe(Mithril$1.route.param("componentType"), ComponentType.tFromJs), "structural");
  var asPlanned = Prelude.default(Mithril__Route.paramBool("asPlanned"), false);
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.sections = Section.ofVessel(vesselId), newrecord.vessels = {
                                TAG: "Get",
                                _0: vesselId
                              }, newrecord)), (function (param) {
                            var vessels = param.vessels;
                            var match = Prelude.$$Array.first(vessels);
                            if (match !== undefined) {
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          sections: Prelude.$$Array.sort(Section.unarchived(param.sections), Section.byName, undefined),
                                          asPlanned: asPlanned,
                                          showBackButton: shouldShowBackButton(componentType, vessels),
                                          componentType: componentType
                                        });
                            } else {
                              window.history.back();
                              return ;
                            }
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var componentType = match.componentType;
                  var asPlanned = match.asPlanned;
                  tmp = match.sections.map(function (section) {
                        return React.jsxKeyed(make, {
                                    section: section,
                                    componentType: componentType,
                                    asPlanned: asPlanned
                                  }, section.id, undefined);
                      });
                }
                var match$1 = vnode.state.data;
                var tmp$1;
                tmp$1 = typeof match$1 !== "object" ? false : match$1.showBackButton;
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode.state.data),
                            showBackButton: tmp$1,
                            showSettings: true,
                            section: "survey"
                          });
              }));
}

export {
  SectionTile ,
  title ,
  shouldShowBackButton ,
  make$1 as make,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

var ComponentReader = IDB__Migration__Utils.MakeReader({
      storeName: "components"
    });

function message() {
  return "Optimizing vessels modules";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.map(Prelude.then(ComponentReader.read(transaction), (function (components) {
                    var message = function (param, param$1) {
                      return "Optimizing vessels modules";
                    };
                    var $$process = function (vessel) {
                      var modules = Curry._2(Prelude.$$Array.keep, components, (function (c) {
                                return Caml_obj.equal(c.vessel_id, vessel.id);
                              })).reduce((function (param, component) {
                              return {
                                      checklist: param.checklist || component.type === "checklist",
                                      structural: param.structural || component.type === "structural",
                                      safety: param.safety || component.type === "safety"
                                    };
                            }), {
                            checklist: false,
                            structural: false,
                            safety: false
                          });
                      return {
                              id: vessel.id,
                              name: vessel.name,
                              max_pic_resolution: vessel.max_pic_resolution,
                              picture_prompt_every_n_inspections: vessel.picture_prompt_every_n_inspections,
                              require_landscape_pictures: vessel.require_landscape_pictures,
                              modules: modules
                            };
                    };
                    var VesselRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
                          storeName: "vessels",
                          message: message,
                          $$process: $$process
                        });
                    return VesselRewriter.rewrite(transaction);
                  })), (function () {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "./URL.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

function makeHtmlImageElementFromUrl(url) {
  return new Promise((function (resolve, reject) {
                var img = new Image();
                img.addEventListener("load", (function (_event) {
                        resolve(img);
                      }));
                img.addEventListener("error", (function (_error) {
                        reject(Js_exn.raiseError("Faild to load the image"));
                      }));
                img.src = url;
              }));
}

function isLandscape(photo) {
  var url = URL.createObjectURL(photo);
  return Prelude.$$catch(Prelude.then(makeHtmlImageElementFromUrl(url), (function (img) {
                      var width = img.width;
                      var height = img.height;
                      return Promise.resolve({
                                  TAG: "Ok",
                                  _0: width > height
                                });
                    })), (function (error) {
                  return Promise.resolve({
                              TAG: "Error",
                              _0: error
                            });
                })).finally(function () {
              $$URL.revokeObjectURL(url);
            });
}

export {
  makeHtmlImageElementFromUrl ,
  isLandscape ,
}
/* URL Not a pure module */

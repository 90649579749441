// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

var SectionId = Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

var ComponentId = Utils.MakeOpaqueIdentifier({});

var SectionReader = IDB__Migration__Utils.MakeReader({
      storeName: "sections"
    });

function message(param, param$1) {
  return "Optimizing components and procedures";
}

function $$process(component, sections) {
  var vessel = SectionId.$$Map.get(sections, component.section_id);
  if (vessel !== undefined) {
    return {
            TAG: "Update",
            _0: {
              id: component.id,
              vessel_id: Caml_option.valFromOption(vessel),
              section_id: component.section_id,
              name: component.name,
              number: component.number,
              order: component.order,
              type: component.type,
              safety_type: component.safety_type,
              suggested_annotations: component.suggested_annotations,
              rating_styling_rules_id: component.rating_styling_rules_id,
              require_image_conditions: component.require_image_conditions
            }
          };
  } else {
    return "Delete";
  }
}

var ComponentRewriter = InitFeedback.MakeRewriter({
      storeName: "components",
      message: message,
      $$process: $$process
    });

var ComponentReader = IDB__Migration__Utils.MakeReader({
      storeName: "components"
    });

function message$1(param, param$1) {
  return "Optimizing components and procedures";
}

function $$process$1(procedure, components) {
  var component = ComponentId.$$Map.get(components, procedure.component_id);
  if (component !== undefined) {
    var vessel_id = component.vessel_id;
    var section_id = component.section_id;
    return {
            TAG: "Update",
            _0: {
              id: procedure.id,
              vessel_id: vessel_id,
              section_id: section_id,
              component_id: procedure.component_id,
              name: procedure.name,
              order: procedure.order,
              position_diagram_id: procedure.position_diagram_id,
              position_diagram_desc: procedure.position_diagram_desc,
              example_image_id: procedure.example_image_id,
              example_image_desc: procedure.example_image_desc,
              description: procedure.description,
              require_landscape_pictures: procedure.require_landscape_pictures,
              question_type: procedure.question_type,
              rating_styling_rules_id: procedure.rating_styling_rules_id,
              require_image_conditions: procedure.require_image_conditions,
              recurrence: procedure.recurrence,
              due_date: procedure.due_date,
              overdue_date: procedure.overdue_date
            }
          };
  }
  console.error("Cannot locate vessel and section for procedure", procedure);
  return "Delete";
}

var ProcedureRewriter = InitFeedback.MakeRewriter({
      storeName: "procedures",
      message: message$1,
      $$process: $$process$1
    });

function message$2() {
  return "Optimizing components and procedures";
}

function migrate(param, transaction) {
  transaction.objectStore("components").createIndex("vessel_id", "vessel_id");
  IDB__Migration__Utils.createManyIndexes(transaction.objectStore("procedures"), [
        "vessel_id",
        "section_id"
      ]);
  return Prelude.PromisedResult.bind(Prelude.PromisedResult.bind(Prelude.then(SectionReader.read(transaction), (function (sections) {
                        var sections$1 = SectionId.$$Map.fromArray(sections.map(function (s) {
                                  return [
                                          s.id,
                                          s.vessel_id
                                        ];
                                }));
                        return ComponentRewriter.rewrite(transaction, sections$1);
                      })), (function () {
                    return Prelude.thenResolve(ComponentReader.read(transaction), (function (components) {
                                  return {
                                          TAG: "Ok",
                                          _0: components
                                        };
                                }));
                  })), (function (components) {
                var components$1 = ComponentId.$$Map.fromArray(components.map(function (c) {
                          return [
                                  c.id,
                                  c
                                ];
                        }));
                return ProcedureRewriter.rewrite(transaction, components$1);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$2,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* SectionId Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Luxon from "luxon";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var forms = vnode.attrs.forms;
                if (forms.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("h5", {
                                      children: "Completed",
                                      className: "text-success mb-0"
                                    }),
                                ReactDOM.jsx("ul", {
                                      children: forms.map(function (param) {
                                            var form = param[0];
                                            return ReactDOM.jsxsKeyed("li", {
                                                        children: [
                                                          form.name,
                                                          ReactDOM.jsx("ul", {
                                                                children: param[1].map(function (submission) {
                                                                      return ReactDOM.jsxKeyed("li", {
                                                                                  children: ReactDOM.jsx("small", {
                                                                                        children: [
                                                                                          "Date: ",
                                                                                          Luxon.DateTime.fromJSDate(submission.timestamp).toLocaleString(Luxon.DateTime.DATE_MED_WITH_WEEKDAY)
                                                                                        ]
                                                                                      })
                                                                                }, submission.id, undefined);
                                                                    })
                                                              })
                                                        ]
                                                      }, form.id, undefined);
                                          })
                                    })
                              ],
                              className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-mb-2"
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

export {
  make ,
}
/* luxon Not a pure module */

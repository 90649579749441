// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../common/Forms.js";
import * as Query from "../../Query.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as QuestionType from "../QuestionType.js";

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.oninit(Mithril.component("Init"), (function (vnode) {
                            var inspection = vnode.attrs.inspection;
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  value: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, QuestionType.Value.getDate(inspection.value), (function (v) {
                                              return Prelude.$$Array.first(v.toISOString().split("T"));
                                            })), ""),
                                  inspection: inspection
                                });
                          })), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var value;
                value = typeof match !== "object" ? "" : match.value;
                var procedure = vnode.attrs.procedure;
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("div", {
                                          children: ReactDOM.jsx("label", {
                                                children: procedure.name,
                                                className: "form-label",
                                                htmlFor: "question-input-date"
                                              }),
                                          className: "col-3"
                                        }),
                                    ReactDOM.jsx("div", {
                                          children: ReactDOM.jsx("input", {
                                                className: "form-input",
                                                id: "question-input-date",
                                                name: procedure.id,
                                                type: "date",
                                                value: value,
                                                onChange: (function (ev) {
                                                    var match = vnode.state.data;
                                                    if (typeof match !== "object") {
                                                      return ;
                                                    }
                                                    var inspection = match.inspection;
                                                    var target = ev.target;
                                                    var value;
                                                    var exit = 0;
                                                    var v;
                                                    try {
                                                      v = target.value;
                                                      exit = 1;
                                                    }
                                                    catch (exn){
                                                      value = undefined;
                                                    }
                                                    if (exit === 1) {
                                                      if (v !== undefined) {
                                                        var v$1 = Caml_option.valFromOption(v);
                                                        value = v$1 !== "" ? Caml_option.some(v$1) : undefined;
                                                      } else {
                                                        value = undefined;
                                                      }
                                                    }
                                                    var date = Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                                                            var result = new Date(v);
                                                            if (Number.isNaN(result.getDate())) {
                                                              return ;
                                                            } else {
                                                              return Caml_option.some(result);
                                                            }
                                                          }));
                                                    if (date !== undefined) {
                                                      var inspection_id = inspection.id;
                                                      var inspection_procedure_id = inspection.procedure_id;
                                                      var inspection_component_id = inspection.component_id;
                                                      var inspection_section_id = inspection.section_id;
                                                      var inspection_vessel_id = inspection.vessel_id;
                                                      var inspection_inspector_id = inspection.inspector_id;
                                                      var inspection_timestamp = inspection.timestamp;
                                                      var inspection_code = inspection.code;
                                                      var inspection_annotations = inspection.annotations;
                                                      var inspection_additional_annotation = inspection.additional_annotation;
                                                      var inspection_picture_prompted = inspection.picture_prompted;
                                                      var inspection_walked_steps = inspection.walked_steps;
                                                      var inspection_app_version = inspection.app_version;
                                                      var inspection_deficiency_id = inspection.deficiency_id;
                                                      var inspection_attachments = inspection.attachments;
                                                      var inspection_value = {
                                                        NAME: "Date",
                                                        VAL: Caml_option.valFromOption(date)
                                                      };
                                                      var inspection_deadline = inspection.deadline;
                                                      var inspection_profile = inspection.profile;
                                                      var inspection$1 = {
                                                        id: inspection_id,
                                                        procedure_id: inspection_procedure_id,
                                                        component_id: inspection_component_id,
                                                        section_id: inspection_section_id,
                                                        vessel_id: inspection_vessel_id,
                                                        inspector_id: inspection_inspector_id,
                                                        timestamp: inspection_timestamp,
                                                        code: inspection_code,
                                                        annotations: inspection_annotations,
                                                        additional_annotation: inspection_additional_annotation,
                                                        picture_prompted: inspection_picture_prompted,
                                                        walked_steps: inspection_walked_steps,
                                                        app_version: inspection_app_version,
                                                        deficiency_id: inspection_deficiency_id,
                                                        attachments: inspection_attachments,
                                                        value: inspection_value,
                                                        deadline: inspection_deadline,
                                                        profile: inspection_profile
                                                      };
                                                      vnode.attrs.onChange(inspection$1);
                                                      var newrecord = Caml_obj.obj_dup(Query.makeWrite());
                                                      return Prelude.thenDo(Query.write((newrecord.newInspections = [{
                                                                          TAG: "Save",
                                                                          _0: inspection$1
                                                                        }], newrecord)), (function (param) {
                                                                    Mithril.setState(vnode, {
                                                                          TAG: "Loaded",
                                                                          value: Prelude.default(value, ""),
                                                                          inspection: inspection$1
                                                                        });
                                                                  }));
                                                    }
                                                    var inspection_id$1 = inspection.id;
                                                    var inspection_procedure_id$1 = inspection.procedure_id;
                                                    var inspection_component_id$1 = inspection.component_id;
                                                    var inspection_section_id$1 = inspection.section_id;
                                                    var inspection_vessel_id$1 = inspection.vessel_id;
                                                    var inspection_inspector_id$1 = inspection.inspector_id;
                                                    var inspection_timestamp$1 = inspection.timestamp;
                                                    var inspection_code$1 = inspection.code;
                                                    var inspection_annotations$1 = inspection.annotations;
                                                    var inspection_additional_annotation$1 = inspection.additional_annotation;
                                                    var inspection_picture_prompted$1 = inspection.picture_prompted;
                                                    var inspection_walked_steps$1 = inspection.walked_steps;
                                                    var inspection_app_version$1 = inspection.app_version;
                                                    var inspection_deficiency_id$1 = inspection.deficiency_id;
                                                    var inspection_attachments$1 = inspection.attachments;
                                                    var inspection_deadline$1 = inspection.deadline;
                                                    var inspection_profile$1 = inspection.profile;
                                                    var inspection$2 = {
                                                      id: inspection_id$1,
                                                      procedure_id: inspection_procedure_id$1,
                                                      component_id: inspection_component_id$1,
                                                      section_id: inspection_section_id$1,
                                                      vessel_id: inspection_vessel_id$1,
                                                      inspector_id: inspection_inspector_id$1,
                                                      timestamp: inspection_timestamp$1,
                                                      code: inspection_code$1,
                                                      annotations: inspection_annotations$1,
                                                      additional_annotation: inspection_additional_annotation$1,
                                                      picture_prompted: inspection_picture_prompted$1,
                                                      walked_steps: inspection_walked_steps$1,
                                                      app_version: inspection_app_version$1,
                                                      deficiency_id: inspection_deficiency_id$1,
                                                      attachments: inspection_attachments$1,
                                                      value: undefined,
                                                      deadline: inspection_deadline$1,
                                                      profile: inspection_profile$1
                                                    };
                                                    vnode.attrs.onChange(inspection$2);
                                                    Mithril.setState(vnode, {
                                                          TAG: "Loaded",
                                                          value: Prelude.default(value, ""),
                                                          inspection: inspection$2
                                                        });
                                                  })
                                              }),
                                          className: "col-9"
                                        })
                                  ],
                                  className: "form-group"
                                }),
                            className: "form-horizontal"
                          });
              }));
}

export {
  make ,
}
/* Forms Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Completion from "../../common/Completion.js";
import * as ComponentId from "../../vessel/ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as CompanyFormManager from "../CompanyFormManager.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";
import * as InlineProcedureWidget from "../../vessel/views/InlineProcedureWidget.js";

function componentInfo(vnode) {
  var procedures = Curry._2(Prelude.$$Array.keep, vnode.attrs.procedures, (function (p) {
          return p.not_applicable_status === undefined;
        }));
  var totalNested = procedures.length;
  var totalInspected = CompanyFormManager.countProceduresWithValidInspections(Procedure.ids(procedures), vnode.attrs.oldInspectionsOfProcedure, vnode.attrs.newInspectionsOfProcedure);
  return [
          totalInspected === totalNested ? "Completed" : "Incomplete",
          Completion.Information.make(totalNested, totalInspected, false)
        ];
}

function componentId(vnode, component) {
  var proceduresOfComponent = ComponentId.$$Array.getter(vnode.attrs.procedures, Procedure.byComponent);
  var procedure = Prelude.$$Array.first(proceduresOfComponent(component.id));
  if (procedure !== undefined) {
    return "component-" + ProcedureId.toHTMLID(procedure.id);
  } else {
    return ComponentId.toHTMLID(component.id);
  }
}

function toggleContent(id, _event) {
  var element = document.getElementById(id);
  if (!(element == null)) {
    element.classList.toggle("hidden");
    return ;
  }
  
}

function componentDetails(vnode) {
  var match = componentInfo(vnode);
  var component = vnode.attrs.component;
  var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
  return ReactDOM.jsx("div", {
              children: ReactDOM.jsxs("div", {
                    children: [
                      ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: component.name,
                                    className: "h5"
                                  }),
                              ReactDOM.jsx("small", {
                                    children: match[0],
                                    className: "tile-subtitle text-gray"
                                  })
                            ],
                            className: "tile-content",
                            onClick: (function (extra) {
                                return toggleContent(componentId(vnode, component), extra);
                              })
                          }),
                      React.jsx(InspectionCompletion.make, {
                            completion: match[1],
                            counterOverlayClassName: counterOverlayClassName
                          })
                    ],
                    className: "tile tile-centered t-component"
                  }),
              className: "card-title",
              style: {
                cursor: "pointer"
              }
            });
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var companyForm = vnode.attrs.companyForm;
                var component = vnode.attrs.component;
                var draftSubmission = vnode.attrs.draftSubmission;
                var oldInspectionsOfProcedure = function (extra) {
                  return CompanyFormManager.getInspections(vnode.attrs.oldInspectionsOfProcedure, extra);
                };
                var newInspectionsOfProcedure = function (extra) {
                  return CompanyFormManager.getInspections(vnode.attrs.newInspectionsOfProcedure, extra);
                };
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                return ReactDOM.jsxs("div", {
                            children: [
                              componentDetails(vnode),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      component.number === 0 ? Mithril.empty : ReactDOM.jsx("div", {
                                              children: "Number " + String(component.number),
                                              className: "h6 text-center pt-2"
                                            }),
                                      ReactDOM.jsx("div", {
                                            children: [vnode.attrs.procedures.map(function (procedure) {
                                                    var companyFormRel = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, companyForm.related_procedures, (function (rel) {
                                                                return Caml_obj.equal(rel.procedure_id, procedure.id);
                                                              })));
                                                    return React.jsxKeyed(InlineProcedureWidget.make, {
                                                                procedure: procedure,
                                                                oldInspectionsOfProcedure: oldInspectionsOfProcedure,
                                                                newInspectionsOfProcedure: newInspectionsOfProcedure,
                                                                onInspectionCreated: (function (procedure, newInspection) {
                                                                    vnode.attrs.onInspectionCreated(procedure, newInspection);
                                                                  }),
                                                                onChangeProcedure: (function (procedure) {
                                                                    vnode.attrs.onChangeProcedure(procedure);
                                                                  }),
                                                                draftSubmission: draftSubmission,
                                                                companyFormRel: companyFormRel,
                                                                counterOverlayClassName: counterOverlayClassName
                                                              }, procedure.id, undefined);
                                                  })],
                                            className: "card-body p-0 hidden",
                                            id: componentId(vnode, component)
                                          })
                                    ],
                                    className: "card-body p-0"
                                  })
                            ],
                            className: "card card-shadow my-2 t-xc-xq"
                          });
              }));
}

export {
  componentInfo ,
  componentId ,
  toggleContent ,
  componentDetails ,
  make ,
}
/* React Not a pure module */

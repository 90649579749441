// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import MarkdownIt from "markdown-it";

function make(param) {
  return Mithril.view(Mithril.component("Closed"), (function (vnode) {
                var procedure = vnode.attrs.procedure;
                var description = Curry._2(Prelude.OptionExported.$$Option.map, procedure.description, (function (prim) {
                        return prim.trim();
                      }));
                if (description === undefined) {
                  return Mithril.empty;
                }
                if (description === "") {
                  return Mithril.empty;
                }
                var match = vnode.state.data;
                var open_;
                open_ = match === "Closed" ? undefined : true;
                var match$1 = vnode.state.data;
                var tmp;
                tmp = match$1 === "Closed" ? [
                    "Show more",
                    React.jsx(Feather.make, {
                          icon: "chevron_down",
                          size: 18
                        })
                  ] : [
                    "Show less",
                    React.jsx(Feather.make, {
                          icon: "chevron_up",
                          size: 18
                        })
                  ];
                return ReactDOM.jsxs("details", {
                            children: [
                              ReactDOM.jsx("summary", {
                                    children: tmp,
                                    className: "btn btn-link p-0 tw-p-x-0 text-tiny active tw-flex tw-gap-2 tw-items-center",
                                    style: {
                                      boxShadow: "none"
                                    }
                                  }),
                              ReactDOM.jsx("div", {
                                    children: Mithril$1.trust(new MarkdownIt().render(description)),
                                    className: "py-2"
                                  })
                            ],
                            className: "micro-doc py-2",
                            open: open_,
                            onClick: (function (ev) {
                                ev.preventDefault();
                                var match = vnode.state.data;
                                if (match === "Closed") {
                                  return Mithril.setState(vnode, "Open");
                                } else {
                                  return Mithril.setState(vnode, "Closed");
                                }
                              })
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "./rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                if (vnode.attrs.cond) {
                  return vnode.children;
                } else {
                  return Prelude.default(vnode.attrs.fallback, Mithril.empty);
                }
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";
import * as Sire2VettingPeriodAPI from "../endpoints/Sire2VettingPeriodAPI.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(Sire2VettingPeriodAPI.getAll(undefined, {
                  vessel__id__in: vessels.join(",")
                }, undefined, user), (function (periods) {
                var $$final$1 = AppSchema.Sire2VettingPeriods.updateMany($$final, periods);
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final$1
                      };
              }));
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.sire2VettingPeriods = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.sire2VettingPeriods, (function (extra) {
                        return AppSchema.Sire2VettingPeriods.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.sire2VettingPeriods = AppSchema.Sire2VettingPeriods.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncSire2VettingPeriods" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.UserProfiles];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncSire2VettingPeriods",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */

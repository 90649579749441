// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../accounts/User.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Sentry from "../../libs/Sentry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as UserProfile from "../accounts/UserProfile.js";
import * as Browser from "@sentry/browser";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

var Identifier = Utils.MakeOpaqueIdentifier({});

var state = {
  contents: {
    id: Identifier.make(),
    user: undefined,
    vessel: undefined,
    profile: undefined,
    role: undefined,
    activeFindings: 0,
    pendingUploads: 0,
    unreadNotifications: 0,
    toast: undefined
  }
};

function get() {
  return state.contents;
}

function set($$new) {
  var previous = state.contents;
  if (!Caml_obj.notequal(previous, $$new)) {
    return ;
  }
  state.contents = $$new;
  var userChanged = Caml_obj.notequal(previous.user, $$new.user);
  var profileChanged = Caml_obj.notequal(previous.profile, $$new.profile);
  if (userChanged || profileChanged) {
    var match = $$new.user;
    var match$1 = $$new.profile;
    Sentry.setUser(match !== undefined ? (
            match$1 !== undefined ? ({
                  id: match.id,
                  email: match.email,
                  name: UserProfile.name(match$1)
                }) : ({
                  id: match.id,
                  email: match.email,
                  name: User.name(match)
                })
          ) : ({
              id: "0",
              email: "anonymous@kaikosystems.com"
            }));
  }
  var position = $$new.profile;
  if (position !== undefined) {
    Browser.setTag("rank", UserProfile.Rank.toString(position.rank));
  } else {
    Browser.setTag("rank", "");
  }
  var vessel = $$new.vessel;
  if (vessel !== undefined) {
    Browser.setTag("vessel_name", vessel.name);
    Browser.setTag("vessel_id", vessel.id);
  } else {
    Browser.setTag("vessel_name", "");
    Browser.setTag("vessel_id", "");
  }
}

var Current = {
  Identifier: Identifier,
  get: get,
  set: set
};

function make(state) {
  return [
          state,
          []
        ];
}

function setCurrentUser(param, user) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetCurrentUser",
                _0: user
              })
        ];
}

function removeCurrentUser(param) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], "RemoveCurrentUser")
        ];
}

function setCurrentProfile(param, profile) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetCurrentProfile",
                _0: profile
              })
        ];
}

function removeCurrentProfile(param) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], "RemoveCurrentProfile")
        ];
}

function setCurrentRole(param, role) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetCurrentRole",
                _0: role
              })
        ];
}

function removeCurrentRole(param) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], "RemoveCurrentRole")
        ];
}

function setCurrentVessel(param, vessel) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetCurrentVessel",
                _0: vessel
              })
        ];
}

function removeCurrentVessel(param) {
  return removeCurrentProfile([
              param[0],
              Prelude.$$Array.append(param[1], "RemoveCurrentVessel")
            ]);
}

function setToast(param, text) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetToast",
                _0: text
              })
        ];
}

function removeToast(param) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], "RemoveToast")
        ];
}

function setActiveFindings(param, findings) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetActiveFindings",
                _0: findings
              })
        ];
}

function setPendingUploads(param, uploads) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetPendingUploads",
                _0: uploads
              })
        ];
}

function setUnreadNotifications(param, notifications) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "SetUnreadNotifications",
                _0: notifications
              })
        ];
}

function autoSelectVessel(param, vessels) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "AutoSelectVessel",
                _0: vessels
              })
        ];
}

function autoSelectProfile(param, profiles) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "AutoSelectProfile",
                _0: profiles
              })
        ];
}

function autoSelectRole(param, staffers) {
  return [
          param[0],
          Prelude.$$Array.append(param[1], {
                TAG: "AutoSelectRole",
                _0: staffers
              })
        ];
}

function setRole(state, role) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function removeRole(state) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: undefined,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function setProfile(state, profile) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function removeProfile(state) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: undefined,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function setUser(state, user) {
  return {
          id: state.id,
          user: user,
          vessel: state.vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function removeUser(state) {
  return {
          id: state.id,
          user: undefined,
          vessel: state.vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function setVessel(state, vessel) {
  return {
          id: state.id,
          user: state.user,
          vessel: vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function removeVessel(state) {
  return {
          id: state.id,
          user: state.user,
          vessel: undefined,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function setToast$1(state, text) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: text
        };
}

function removeToast$1(state) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: undefined
        };
}

function setActiveFindings$1(state, findings) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: findings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function setPendingUploads$1(state, uploads) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: uploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function setUnreadNotifications$1(state, notifications) {
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: notifications,
          toast: state.toast
        };
}

function autoSelectVessel$1(state, vessels) {
  var match = state.vessel;
  if (vessels.length === 0) {
    return {
            id: state.id,
            user: state.user,
            vessel: undefined,
            profile: state.profile,
            role: state.role,
            activeFindings: state.activeFindings,
            pendingUploads: state.pendingUploads,
            unreadNotifications: state.unreadNotifications,
            toast: state.toast
          };
  }
  if (match === undefined) {
    return {
            id: state.id,
            user: state.user,
            vessel: Prelude.$$Array.first(vessels),
            profile: state.profile,
            role: state.role,
            activeFindings: state.activeFindings,
            pendingUploads: state.pendingUploads,
            unreadNotifications: state.unreadNotifications,
            toast: state.toast
          };
  }
  var exists = Curry._1(Prelude.OptionExported.$$Option.isSome, vessels.find(function (vessel) {
            return Caml_obj.equal(match.id, vessel.id);
          }));
  if (exists) {
    return {
            id: state.id,
            user: state.user,
            vessel: match,
            profile: state.profile,
            role: state.role,
            activeFindings: state.activeFindings,
            pendingUploads: state.pendingUploads,
            unreadNotifications: state.unreadNotifications,
            toast: state.toast
          };
  } else {
    return {
            id: state.id,
            user: state.user,
            vessel: Prelude.$$Array.first(vessels),
            profile: state.profile,
            role: state.role,
            activeFindings: state.activeFindings,
            pendingUploads: state.pendingUploads,
            unreadNotifications: state.unreadNotifications,
            toast: state.toast
          };
  }
}

function autoSelectProfile$1(state, profiles) {
  var vessel = state.vessel;
  if (vessel === undefined) {
    return {
            id: state.id,
            user: state.user,
            vessel: state.vessel,
            profile: undefined,
            role: state.role,
            activeFindings: state.activeFindings,
            pendingUploads: state.pendingUploads,
            unreadNotifications: state.unreadNotifications,
            toast: state.toast
          };
  }
  var profile = state.profile;
  if (profile !== undefined) {
    if (Caml_obj.equal(profile.vessel, vessel.id)) {
      var profile$1 = profiles.find(function (p) {
            return Caml_obj.equal(p.id, profile.id);
          });
      if (Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, profile$1, (function (p) {
                    return p.state === "active";
                  })), false)) {
        return state;
      }
      var profile$2 = profiles.find(function (p) {
            if (Caml_obj.equal(p.vessel, vessel.id)) {
              return p.state === "active";
            } else {
              return false;
            }
          });
      return {
              id: state.id,
              user: state.user,
              vessel: state.vessel,
              profile: profile$2,
              role: state.role,
              activeFindings: state.activeFindings,
              pendingUploads: state.pendingUploads,
              unreadNotifications: state.unreadNotifications,
              toast: state.toast
            };
    }
    var profile$3 = profiles.find(function (p) {
          if (Caml_obj.equal(p.vessel, vessel.id)) {
            return p.state === "active";
          } else {
            return false;
          }
        });
    return {
            id: state.id,
            user: state.user,
            vessel: state.vessel,
            profile: profile$3,
            role: state.role,
            activeFindings: state.activeFindings,
            pendingUploads: state.pendingUploads,
            unreadNotifications: state.unreadNotifications,
            toast: state.toast
          };
  }
  var profile$4 = profiles.find(function (p) {
        if (Caml_obj.equal(p.vessel, vessel.id)) {
          return p.state === "active";
        } else {
          return false;
        }
      });
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: profile$4,
          role: state.role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

function autoSelectRole$1(state, staffers) {
  var match = state.vessel;
  var match$1 = state.user;
  if (match === undefined) {
    return state;
  }
  if (match$1 === undefined) {
    return state;
  }
  var role = Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, staffers, (function (s) {
                  if (Caml_obj.equal(s.vessel, match.id)) {
                    return Caml_obj.equal(s.user, match$1.id);
                  } else {
                    return false;
                  }
                }))), (function (s) {
          return s.role;
        }));
  return {
          id: state.id,
          user: state.user,
          vessel: state.vessel,
          profile: state.profile,
          role: role,
          activeFindings: state.activeFindings,
          pendingUploads: state.pendingUploads,
          unreadNotifications: state.unreadNotifications,
          toast: state.toast
        };
}

var Run = {
  setRole: setRole,
  removeRole: removeRole,
  setProfile: setProfile,
  removeProfile: removeProfile,
  setUser: setUser,
  removeUser: removeUser,
  setVessel: setVessel,
  removeVessel: removeVessel,
  setToast: setToast$1,
  removeToast: removeToast$1,
  setActiveFindings: setActiveFindings$1,
  setPendingUploads: setPendingUploads$1,
  setUnreadNotifications: setUnreadNotifications$1,
  autoSelectVessel: autoSelectVessel$1,
  autoSelectProfile: autoSelectProfile$1,
  autoSelectRole: autoSelectRole$1
};

function run(request) {
  var actions = Prelude.$$Array.sort(request[1], (function (action) {
          if (typeof action !== "object") {
            if (action === "RemoveToast") {
              return 50;
            } else {
              return 0;
            }
          }
          switch (action.TAG) {
            case "SetCurrentUser" :
                return 100;
            case "SetCurrentProfile" :
                return 400;
            case "SetCurrentRole" :
                return 300;
            case "SetCurrentVessel" :
                return 200;
            case "SetActiveFindings" :
            case "SetPendingUploads" :
            case "SetUnreadNotifications" :
            case "SetToast" :
                return 900;
            case "AutoSelectVessel" :
                return 700;
            case "AutoSelectProfile" :
            case "AutoSelectRole" :
                return 800;
            
          }
        }), undefined);
  return actions.reduce((function (state, cmd) {
                if (typeof cmd !== "object") {
                  switch (cmd) {
                    case "RemoveCurrentUser" :
                        return removeUser(state);
                    case "RemoveCurrentProfile" :
                        return removeProfile(state);
                    case "RemoveCurrentRole" :
                        return removeRole(state);
                    case "RemoveCurrentVessel" :
                        return removeVessel(state);
                    case "RemoveToast" :
                        return removeToast$1(state);
                    
                  }
                } else {
                  switch (cmd.TAG) {
                    case "SetCurrentUser" :
                        return setUser(state, cmd._0);
                    case "SetCurrentProfile" :
                        return setProfile(state, cmd._0);
                    case "SetCurrentRole" :
                        return setRole(state, cmd._0);
                    case "SetCurrentVessel" :
                        return setVessel(state, cmd._0);
                    case "SetActiveFindings" :
                        return setActiveFindings$1(state, cmd._0);
                    case "SetPendingUploads" :
                        return setPendingUploads$1(state, cmd._0);
                    case "SetUnreadNotifications" :
                        return setUnreadNotifications$1(state, cmd._0);
                    case "SetToast" :
                        return setToast$1(state, cmd._0);
                    case "AutoSelectVessel" :
                        return autoSelectVessel$1(state, cmd._0);
                    case "AutoSelectProfile" :
                        return autoSelectProfile$1(state, cmd._0);
                    case "AutoSelectRole" :
                        return autoSelectRole$1(state, cmd._0);
                    
                  }
                }
              }), request[0]);
}

var Transformations = {
  make: make,
  setCurrentUser: setCurrentUser,
  removeCurrentUser: removeCurrentUser,
  setCurrentProfile: setCurrentProfile,
  removeCurrentProfile: removeCurrentProfile,
  setCurrentRole: setCurrentRole,
  removeCurrentRole: removeCurrentRole,
  setCurrentVessel: setCurrentVessel,
  removeCurrentVessel: removeCurrentVessel,
  setToast: setToast,
  removeToast: removeToast,
  setActiveFindings: setActiveFindings,
  setPendingUploads: setPendingUploads,
  setUnreadNotifications: setUnreadNotifications,
  autoSelectVessel: autoSelectVessel,
  autoSelectProfile: autoSelectProfile,
  autoSelectRole: autoSelectRole,
  Run: Run,
  run: run
};

var Model = ReIndexed.MakeModel({});

function migrations() {
  return [(function (param) {
              return function (db, param) {
                IDB__Migration__Database.createObjectStore(db, "state");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            })];
}

var Database = ReIndexed.MakeDatabase({
      migrations: migrations
    });

function makeRead() {
  return {
          state: "All"
        };
}

function makeWrite() {
  return {
          state: []
        };
}

function makeResponse() {
  return {
          state: []
        };
}

var StateQueryDef = {
  makeRead: makeRead,
  makeWrite: makeWrite,
  makeResponse: makeResponse
};

var StateQuery = Curry._1(Database.MakeQuery, StateQueryDef);

async function read() {
  var match = await StateQuery.read(StateQuery.makeRead());
  var state$1 = Prelude.$$Array.first(match.state);
  if (state$1 === undefined) {
    return state.contents;
  }
  var $$default = state.contents;
  var $$new = Object.assign($$default, state$1);
  set($$new);
  return $$new;
}

async function save(state) {
  var state_id = Identifier.make();
  var state_user = state.user;
  var state_vessel = state.vessel;
  var state_profile = state.profile;
  var state_role = state.role;
  var state_activeFindings = state.activeFindings;
  var state_pendingUploads = state.pendingUploads;
  var state_unreadNotifications = state.unreadNotifications;
  var state_toast = state.toast;
  var state$1 = {
    id: state_id,
    user: state_user,
    vessel: state_vessel,
    profile: state_profile,
    role: state_role,
    activeFindings: state_activeFindings,
    pendingUploads: state_pendingUploads,
    unreadNotifications: state_unreadNotifications,
    toast: state_toast
  };
  await Prelude.PromisedResult.scream(StateQuery.Safe.write({
            state: [
              "Clear",
              {
                TAG: "Save",
                _0: state$1
              }
            ]
          }));
  set(state$1);
  return state$1;
}

function store(state) {
  var state_id = Identifier.make();
  var state_user = state.user;
  var state_vessel = state.vessel;
  var state_profile = state.profile;
  var state_role = state.role;
  var state_activeFindings = state.activeFindings;
  var state_pendingUploads = state.pendingUploads;
  var state_unreadNotifications = state.unreadNotifications;
  var state_toast = state.toast;
  var state$1 = {
    id: state_id,
    user: state_user,
    vessel: state_vessel,
    profile: state_profile,
    role: state_role,
    activeFindings: state_activeFindings,
    pendingUploads: state_pendingUploads,
    unreadNotifications: state_unreadNotifications,
    toast: state_toast
  };
  Prelude.PromisedResult.scream(StateQuery.Safe.write({
            state: [
              "Clear",
              {
                TAG: "Save",
                _0: state$1
              }
            ]
          }));
  set(state$1);
  return state$1;
}

function init() {
  return Prelude.catchResolve(Prelude.then(Database.connect("kaiko-v2-statekeeper"), (function (param) {
                    return Prelude.thenResolve(StateQuery.read(StateQuery.makeRead()), (function (param) {
                                  var state$1 = Prelude.$$Array.first(param.state);
                                  if (state$1 !== undefined) {
                                    return set(state$1);
                                  } else {
                                    return set(store(state.contents));
                                  }
                                }));
                  })), (function (param) {
                
              }));
}

var Persisted = {
  Model: Model,
  Database: Database,
  StateQueryDef: StateQueryDef,
  StateQuery: StateQuery,
  read: read,
  save: save,
  store: store,
  init: init
};

export {
  Current ,
  Transformations ,
  Persisted ,
}
/* Identifier Not a pure module */

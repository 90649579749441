// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Dialog from "../../../libs/Dialog.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as WindowLocation from "../../../libs/WindowLocation.js";
import * as LevelRiskStatus from "../LevelRiskStatus.js";

var areaChoices = [
  {
    label: "Hardware",
    item: "Hardware"
  },
  {
    label: "Human",
    item: "Human"
  },
  {
    label: "Process",
    item: "Process"
  }
];

var riskChoices = [
  {
    label: "High / Core",
    item: "high"
  },
  {
    label: "Medium / Rotational I",
    item: "medium"
  },
  {
    label: "Low / Rotational II",
    item: "low"
  }
];

function toggleRisk(vnode, risk) {
  var match = vnode.state.data;
  var risks = match.risks;
  var newRisks = Prelude.$$Array.includes(risks, risk) ? Curry._2(Prelude.$$Array.keep, risks, (function (r) {
            return r !== risk;
          })) : Curry._2(Prelude.$$Array.concat, risks, [risk]);
  Mithril.setState(vnode, {
        risks: newRisks,
        areas: match.areas
      });
}

function toggleArea(vnode, area) {
  var match = vnode.state.data;
  var areas = match.areas;
  var newAreas = Prelude.$$Array.includes(areas, area) ? Curry._2(Prelude.$$Array.keep, areas, (function (r) {
            return r !== area;
          })) : Curry._2(Prelude.$$Array.concat, areas, [area]);
  Mithril.setState(vnode, {
        risks: match.risks,
        areas: newAreas
      });
}

function toggleAll(vnode, ckeck, option) {
  var match = vnode.state.data;
  Mithril.setState(vnode, option === "risk" ? ({
            risks: ckeck ? riskChoices.map(function (r) {
                    return r.item;
                  }) : [],
            areas: match.areas
          }) : (
          option === "all" ? (
              ckeck ? ({
                    risks: riskChoices.map(function (r) {
                          return r.item;
                        }),
                    areas: areaChoices.map(function (a) {
                          return a.item;
                        })
                  }) : ({
                    risks: [],
                    areas: []
                  })
            ) : ({
                risks: match.risks,
                areas: ckeck ? areaChoices.map(function (a) {
                        return a.item;
                      }) : []
              })
        ));
}

function doFilter(vnode, param) {
  var match = vnode.state.data;
  var search = WindowLocation.get().search;
  var searchParameters = new URLSearchParams(search);
  searchParameters.set("risks", match.risks.map(LevelRiskStatus.tToJs).join(","));
  searchParameters.set("areas", match.areas.map(function (x) {
              return Question.QuestionArea.toJs(x);
            }).join(","));
  var pathname = WindowLocation.get().pathname;
  Mithril$1.route.set(pathname, Js_dict.fromArray(Array.from(searchParameters.entries())), {
        replace: true
      });
}

function QuestionFilter(props) {
  var id = props.id;
  var risks = Prelude.$$Array.keepSome(Prelude.default(Mithril$1.route.param("risks"), "low,high,medium").split(",").map(LevelRiskStatus.tFromJs));
  var areas = Prelude.$$Array.keepSome(Prelude.default(Mithril$1.route.param("areas"), "HUMAN,PROCESS,HARDWARE").split(",").map(function (x) {
            return Question.QuestionArea.fromJs(x);
          }));
  return Mithril.view(Mithril.component({
                  risks: risks,
                  areas: areas
                }), (function (vnode) {
                var match = vnode.state.data;
                var areas = match.areas;
                var risks = match.risks;
                var actions = [
                  {
                    label: "Cancel"
                  },
                  {
                    onClick: (function (extra) {
                        return doFilter(vnode, extra);
                      }),
                    label: "Apply Filter"
                  }
                ];
                var allRisks = risks.length === riskChoices.length;
                var allAreas = areas.length === areaChoices.length;
                return React.jsxs(Dialog.make, {
                            id: id,
                            actions: actions,
                            header: "Filter",
                            children: [
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsxs("label", {
                                          children: [
                                            ReactDOM.jsx("input", {
                                                  checked: allAreas && allRisks,
                                                  type: "checkbox",
                                                  onChange: (function (param) {
                                                      toggleAll(vnode, !(allAreas && allRisks), "all");
                                                    })
                                                }),
                                            ReactDOM.jsx("i", {
                                                  className: "form-icon"
                                                }),
                                            "All"
                                          ],
                                          className: "form-checkbox"
                                        }),
                                    className: "form-group"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("label", {
                                            children: "Area"
                                          }),
                                      ReactDOM.jsxs("label", {
                                            children: [
                                              ReactDOM.jsx("input", {
                                                    checked: allAreas,
                                                    type: "checkbox",
                                                    onChange: (function (param) {
                                                        toggleAll(vnode, !allAreas, "area");
                                                      })
                                                  }),
                                              ReactDOM.jsx("i", {
                                                    className: "form-icon"
                                                  }),
                                              "All areas"
                                            ],
                                            className: "form-checkbox"
                                          }),
                                      areaChoices.map(function (a) {
                                            return ReactDOM.jsxs("label", {
                                                        children: [
                                                          ReactDOM.jsx("input", {
                                                                checked: Prelude.$$Array.includes(areas, a.item),
                                                                type: "checkbox",
                                                                onChange: (function (param) {
                                                                    toggleArea(vnode, a.item);
                                                                  })
                                                              }),
                                                          ReactDOM.jsx("i", {
                                                                className: "form-icon"
                                                              }),
                                                          a.label
                                                        ],
                                                        className: "form-checkbox"
                                                      });
                                          })
                                    ],
                                    className: "form-group"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("label", {
                                            children: "Type / Risk"
                                          }),
                                      ReactDOM.jsxs("label", {
                                            children: [
                                              ReactDOM.jsx("input", {
                                                    checked: allRisks,
                                                    type: "checkbox",
                                                    onChange: (function (param) {
                                                        toggleAll(vnode, !allRisks, "risk");
                                                      })
                                                  }),
                                              ReactDOM.jsx("i", {
                                                    className: "form-icon"
                                                  }),
                                              "All risk levels"
                                            ],
                                            className: "form-checkbox"
                                          }),
                                      riskChoices.map(function (a) {
                                            return ReactDOM.jsxs("label", {
                                                        children: [
                                                          ReactDOM.jsx("input", {
                                                                checked: Prelude.$$Array.includes(risks, a.item),
                                                                type: "checkbox",
                                                                onChange: (function (param) {
                                                                    toggleRisk(vnode, a.item);
                                                                  })
                                                              }),
                                                          ReactDOM.jsx("i", {
                                                                className: "form-icon"
                                                              }),
                                                          a.label
                                                        ],
                                                        className: "form-checkbox"
                                                      });
                                          })
                                    ],
                                    className: "form-group"
                                  })
                            ]
                          });
              }));
}

var make = QuestionFilter;

export {
  areaChoices ,
  riskChoices ,
  toggleRisk ,
  toggleArea ,
  toggleAll ,
  doFilter ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "./Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as KeepScrollLink from "./KeepScrollLink.js";
import * as SessionSidebar from "../../accounts/components/SessionSidebar.js";
import * as PageHeaderTheme from "./PageHeaderTheme.js";
import FullLogoPng from "/images/full-logo.png";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var className = Core__Option.getOr(vnode.attrs.className, "tw-text-primary tw-p-0 tw-flex tw-items-center tw-justify-center tw-min-w-[35px]");
                var disabled = vnode.attrs.disabled;
                var steps = Math.abs(Core__Option.getOr(vnode.attrs.steps, 1));
                return React.jsx(KeepScrollLink.make, {
                            className: className,
                            disabled: disabled,
                            children: Caml_option.some(React.jsx(Feather.make, {
                                      icon: "arrow_left"
                                    })),
                            onClick: (function ($$event) {
                                $$event.preventDefault();
                                if (!Core__Option.getOr(disabled, false)) {
                                  window.history.go(Math.imul(-1, steps));
                                  return ;
                                }
                                
                              })
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var left = match.left;
                if (typeof left === "object") {
                  return left.VAL;
                } else if (left === "BackButton") {
                  return React.jsx(make, {});
                } else {
                  return Mithril.empty;
                }
              }));
}

function make$2(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var right = match.right;
                if (typeof right === "object") {
                  return right.VAL;
                } else if (right === "Avatar") {
                  return React.jsx(SessionSidebar.make, {});
                } else {
                  return Mithril.empty;
                }
              }));
}

function make$3(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var theme = match.theme;
                var content = match.content;
                if (typeof content === "object") {
                  if (content.NAME !== "Title") {
                    return content.VAL;
                  }
                  var props = PageHeaderTheme.mergeProps(theme, "content", {
                        className: "tw-flex-grow tw-text-center tw-mb-0 tw-truncate tw-px-2"
                      });
                  var newrecord = Caml_obj.obj_dup(props);
                  return ReactDOM.jsx("h4", (newrecord.children = content.VAL, newrecord));
                }
                if (content !== "Logo") {
                  return Mithril.empty;
                }
                var props$1 = PageHeaderTheme.mergeProps(theme, "content", {
                      className: "tw-py-1 tw-max-h-[3.5rem] tw-min-h-[2.5rem]"
                    });
                var newrecord$1 = Caml_obj.obj_dup(props$1);
                return ReactDOM.jsx("img", (newrecord$1.src = FullLogoPng, newrecord$1));
              }));
}

function make$4(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var className = vnode.attrs.className;
                var left = Core__Option.getOr(vnode.attrs.left, "Empty");
                var right = Core__Option.getOr(vnode.attrs.right, "Empty");
                var content = Core__Option.getOr(vnode.attrs.content, "Logo");
                var noClassName = Core__Option.isNone(className);
                var theme = Core__Option.getOr(vnode.attrs.theme, PageHeaderTheme.default);
                var newrecord = Caml_obj.obj_dup(PageHeaderTheme.mergeProps(theme, "root", {
                          className: Mithril.$$class([
                                [
                                  "t-app-header",
                                  true
                                ],
                                [
                                  "tw-w-full tw-h-header tw-min-h-header tw-max-h-header",
                                  noClassName
                                ],
                                [
                                  "tw-bg-white tw-shadow-header tw-px-4 tw-z-[1000]",
                                  noClassName
                                ],
                                [
                                  "tw-flex tw-flex-row tw-items-center tw-justify-between",
                                  noClassName
                                ]
                              ], className)
                        }));
                return ReactDOM.jsxs("header", (newrecord.children = [
                              React.jsx(make$1, {
                                    left: left
                                  }),
                              React.jsx(make$3, {
                                    content: content,
                                    theme: theme
                                  }),
                              React.jsx(make$2, {
                                    right: right
                                  })
                            ], newrecord));
              }));
}

var all = {
  left: "BackButton",
  right: "Avatar",
  content: "Logo"
};

var back = {
  left: "BackButton"
};

var avatar = {
  right: "Avatar"
};

var logo = {
  content: "Logo"
};

var Defaults = {
  all: all,
  back: back,
  avatar: avatar,
  logo: logo
};

function make$5(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var props = vnode.attrs;
                var resolvedProps = Object.assign(Object.assign({}, all), props);
                return React.jsx(make$4, resolvedProps);
              }));
}

var Full = {
  make: make$5
};

function make$6(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var props = vnode.attrs;
                var resolvedProps = Object.assign(Object.assign({}, logo), props);
                return React.jsx(make$4, resolvedProps);
              }));
}

var WithLogo = {
  make: make$6
};

function make$7(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var props = vnode.attrs;
                var resolvedProps = Object.assign(Object.assign({}, back), props);
                return React.jsx(make$4, resolvedProps);
              }));
}

var WithBackButton = {
  make: make$7
};

function make$8(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var props = vnode.attrs;
                var resolvedProps = Object.assign(Object.assign({}, avatar), props);
                return React.jsx(make$4, resolvedProps);
              }));
}

var WithAvatar = {
  make: make$8
};

var Left = {};

var Right = {};

var Content = {};

var F;

var WithEverything;

export {
  Left ,
  Right ,
  Content ,
  make$4 as make,
  Defaults ,
  Full ,
  F ,
  WithEverything ,
  WithLogo ,
  WithBackButton ,
  WithAvatar ,
}
/* React Not a pure module */

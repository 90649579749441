// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UAParser from "../../libs/UAParser.js";
import QuickLru from "quick-lru";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import UaParserJs from "ua-parser-js";

var state = new QuickLru({
      maxSize: 1
    });

function get() {
  var result = state.get();
  if (result !== undefined) {
    return Caml_option.valFromOption(result);
  }
  var result$1 = new UaParserJs();
  state.set(undefined, result$1);
  return result$1;
}

function getUA() {
  return UAParser.getUA(get());
}

function getBrowser() {
  return UAParser.getBrowser(get());
}

function getDevice() {
  return UAParser.getDevice(get());
}

function getDeviceString() {
  var match = getDevice();
  return match.vendor + " " + match.type + " " + match.model;
}

function getOS() {
  return UAParser.getOS(get());
}

function getCPU() {
  return UAParser.getCPU(get());
}

export {
  getUA ,
  getBrowser ,
  getDevice ,
  getDeviceString ,
  getOS ,
  getCPU ,
}
/* state Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InitFeedback from "../InitFeedback.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function transform(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "YesNo") {
    return ;
  }
  var val$1 = val.VAL;
  return Array.isArray(val$1) ? ({
              NAME: name,
              VAL: val$1
            }) : ({
              NAME: name,
              VAL: [
                val$1,
                undefined
              ]
            });
}

function transform$1(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "Text") {
    return ;
  }
  var val$1 = val.VAL;
  return Array.isArray(val$1) ? ({
              NAME: name,
              VAL: val$1
            }) : ({
              NAME: name,
              VAL: [
                val$1,
                undefined
              ]
            });
}

function transform$2(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "Int") {
    return ;
  }
  var val$1 = val.VAL;
  return Array.isArray(val$1) ? ({
              NAME: name,
              VAL: val$1
            }) : ({
              NAME: name,
              VAL: [
                val$1,
                undefined
              ]
            });
}

function transform$3(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "Date") {
    return ;
  }
  var val$1 = val.VAL;
  return Array.isArray(val$1) ? ({
              NAME: name,
              VAL: val$1
            }) : ({
              NAME: name,
              VAL: [
                val$1,
                undefined
              ]
            });
}

function transform$4(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "Signature") {
    return ;
  }
  var val$1 = val.VAL;
  return Array.isArray(val$1) ? ({
              NAME: name,
              VAL: val$1
            }) : ({
              NAME: name,
              VAL: [
                val$1,
                undefined
              ]
            });
}

function transform$5(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "Pictures") {
    return ;
  }
  var value$1 = val.VAL;
  if (!Array.isArray(value$1)) {
    return ;
  }
  var tmp;
  if (value$1.length !== 2) {
    tmp = {
      NAME: name,
      VAL: [
        value$1,
        undefined
      ]
    };
  } else {
    var v = Prelude.$$Array.lastUnsafe(value$1);
    tmp = v !== undefined && typeof Caml_option.valFromOption(v) !== "boolean" ? ({
          NAME: name,
          VAL: [
            value$1,
            undefined
          ]
        }) : ({
          NAME: name,
          VAL: value$1
        });
  }
  return tmp;
}

function transform$6(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "MultipleDates") {
    return ;
  }
  var value$1 = val.VAL;
  if (!Array.isArray(value$1)) {
    return ;
  }
  var tmp;
  if (value$1.length !== 2) {
    tmp = {
      NAME: name,
      VAL: [
        value$1,
        undefined
      ]
    };
  } else {
    var v = Prelude.$$Array.lastUnsafe(value$1);
    tmp = v !== undefined && typeof Caml_option.valFromOption(v) !== "boolean" ? ({
          NAME: name,
          VAL: [
            value$1,
            undefined
          ]
        }) : ({
          NAME: name,
          VAL: value$1
        });
  }
  return tmp;
}

function transform$7(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "MultipleIntegers") {
    return ;
  }
  var value$1 = val.VAL;
  if (!Array.isArray(value$1)) {
    return ;
  }
  var tmp;
  if (value$1.length !== 2) {
    tmp = {
      NAME: name,
      VAL: [
        value$1,
        undefined
      ]
    };
  } else {
    var v = Prelude.$$Array.lastUnsafe(value$1);
    tmp = v !== undefined && typeof Caml_option.valFromOption(v) !== "boolean" ? ({
          NAME: name,
          VAL: [
            value$1,
            undefined
          ]
        }) : ({
          NAME: name,
          VAL: value$1
        });
  }
  return tmp;
}

function transform$8(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "MultipleTexts") {
    return ;
  }
  var value$1 = val.VAL;
  if (!Array.isArray(value$1)) {
    return ;
  }
  var tmp;
  if (value$1.length !== 2) {
    tmp = {
      NAME: name,
      VAL: [
        value$1,
        undefined
      ]
    };
  } else {
    var v = Prelude.$$Array.lastUnsafe(value$1);
    tmp = v !== undefined && typeof Caml_option.valFromOption(v) !== "boolean" ? ({
          NAME: name,
          VAL: [
            value$1,
            undefined
          ]
        }) : ({
          NAME: name,
          VAL: value$1
        });
  }
  return tmp;
}

function transform$9(value) {
  var val;
  try {
    val = value;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Migration_093", e);
    return ;
  }
  var name = val.NAME;
  if (name !== "Quiz") {
    return ;
  }
  var value$1 = val.VAL;
  if (!Array.isArray(value$1)) {
    return ;
  }
  var tmp;
  if (value$1.length !== 2) {
    tmp = {
      NAME: name,
      VAL: [
        value$1,
        undefined
      ]
    };
  } else {
    var v = Prelude.$$Array.lastUnsafe(value$1);
    tmp = v !== undefined && typeof Caml_option.valFromOption(v) !== "boolean" ? ({
          NAME: name,
          VAL: [
            value$1,
            undefined
          ]
        }) : ({
          NAME: name,
          VAL: value$1
        });
  }
  return tmp;
}

function message(param, param$1) {
  return "Installing N/A answers for SIRE 2.0";
}

function applyTrasformer(fn, answer) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, fn(answer.value), (function (v) {
                    var newrecord = Caml_obj.obj_dup(answer);
                    return {
                            TAG: "Update",
                            _0: (newrecord.value = v, newrecord)
                          };
                  })), "Delete");
}

function $$process(answer) {
  var value = answer.value;
  if (value === undefined) {
    return "Next";
  }
  var variant = value.NAME;
  if (variant === "MultipleDates") {
    return applyTrasformer(transform$6, answer);
  } else if (variant === "MultipleIntegers") {
    return applyTrasformer(transform$7, answer);
  } else if (variant === "Int") {
    return applyTrasformer(transform$2, answer);
  } else if (variant === "YesNo") {
    return applyTrasformer(transform, answer);
  } else if (variant === "Pictures") {
    return applyTrasformer(transform$5, answer);
  } else if (variant === "MultipleTexts") {
    return applyTrasformer(transform$8, answer);
  } else if (variant === "Date") {
    return applyTrasformer(transform$3, answer);
  } else if (variant === "Quiz") {
    return applyTrasformer(transform$9, answer);
  } else if (variant === "Signature") {
    return applyTrasformer(transform$4, answer);
  } else if (variant === "Text") {
    return applyTrasformer(transform$1, answer);
  } else {
    return "Next";
  }
}

var AnswersRewriter = InitFeedback.MakeSimpleRewriter({
      storeName: "answers",
      message: message,
      $$process: $$process
    });

function message$1(param, param$1) {
  return "Installing N/A answers for SIRE 2.0";
}

function applyTrasformer$1(fn, answer) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, fn(answer.value), (function (v) {
                    var newrecord = Caml_obj.obj_dup(answer);
                    return {
                            TAG: "Update",
                            _0: (newrecord.value = v, newrecord)
                          };
                  })), "Delete");
}

function $$process$1(answer) {
  var value = answer.value;
  if (value === undefined) {
    return "Next";
  }
  var variant = value.NAME;
  if (variant === "MultipleDates") {
    return applyTrasformer$1(transform$6, answer);
  } else if (variant === "MultipleIntegers") {
    return applyTrasformer$1(transform$7, answer);
  } else if (variant === "Int") {
    return applyTrasformer$1(transform$2, answer);
  } else if (variant === "YesNo") {
    return applyTrasformer$1(transform, answer);
  } else if (variant === "Pictures") {
    return applyTrasformer$1(transform$5, answer);
  } else if (variant === "MultipleTexts") {
    return applyTrasformer$1(transform$8, answer);
  } else if (variant === "Date") {
    return applyTrasformer$1(transform$3, answer);
  } else if (variant === "Quiz") {
    return applyTrasformer$1(transform$9, answer);
  } else if (variant === "Signature") {
    return applyTrasformer$1(transform$4, answer);
  } else if (variant === "Text") {
    return applyTrasformer$1(transform$1, answer);
  } else {
    return "Next";
  }
}

var NewAnswersRewriter = InitFeedback.MakeSimpleRewriter({
      storeName: "newAnswers",
      message: message$1,
      $$process: $$process$1
    });

function message$2(param) {
  return "Installing N/A answers for SIRE 2.0";
}

function migrate(_db, transaction) {
  return Prelude.PromisedResult.bind(NewAnswersRewriter.rewrite(transaction), (function () {
                return AnswersRewriter.rewrite(transaction);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$2,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* AnswersRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Finding from "../../tree/Finding.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Deficiency from "../../tasks/Deficiency.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as UploadRequest from "../upload/UploadRequest.js";
import * as FindingCommentsReview from "../../tree/views/FindingCommentsReview.js";
import * as DeficiencyCommentsReview from "../../tasks/views/DeficiencyCommentsReview.js";

async function init(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var requests = vnode.attrs.requests;
  var comments = Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getNewComment);
  var readComments = Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getUpdatedComment);
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              comments: comments,
              readComments: readComments
            });
}

function valid(param) {
  return true;
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (v) {
                    init(v);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var comments = match.comments;
                if (comments.length === 0 && match.readComments.length === 0) {
                  return Mithril.empty;
                }
                var readComments = match.readComments;
                var deficiencyComments = Curry._2(Prelude.$$Array.keep, comments, (function (c) {
                        return c.object_app === Deficiency.appName;
                      }));
                var findingComments = Curry._2(Prelude.$$Array.keep, comments, (function (c) {
                        return c.object_app === Finding.appName;
                      }));
                var deficiencyCommentList;
                if (deficiencyComments.length !== 0) {
                  var finding_ids = deficiencyComments.map(function (c) {
                        return c.object_id;
                      });
                  deficiencyCommentList = ReactDOM.jsx("div", {
                        children: React.jsx(DeficiencyCommentsReview.make, {
                              finding_ids: finding_ids,
                              comments: deficiencyComments
                            }),
                        className: "tw-mb-2 tw-p-2"
                      });
                } else {
                  deficiencyCommentList = Mithril.empty;
                }
                var findingCommentList;
                if (findingComments.length !== 0) {
                  var finding_ids$1 = findingComments.map(function (c) {
                        return c.object_id;
                      });
                  findingCommentList = ReactDOM.jsx("div", {
                        children: React.jsx(FindingCommentsReview.make, {
                              finding_ids: finding_ids$1,
                              comments: findingComments
                            }),
                        className: "tw-my-2 tw-p-2"
                      });
                } else {
                  findingCommentList = Mithril.empty;
                }
                var commentsToShow = (comments.length + readComments.length | 0) > 0;
                return React.jsx(Conditional.make, {
                            cond: commentsToShow,
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("h5", {
                                          children: "Comments"
                                        }),
                                    React.jsx(Conditional.make, {
                                          cond: !Prelude.$$Array.isEmpty(comments),
                                          children: [
                                            deficiencyCommentList,
                                            findingCommentList
                                          ]
                                        }),
                                    React.jsx(Conditional.make, {
                                          cond: !Prelude.$$Array.isEmpty(readComments),
                                          children: ReactDOM.jsx("div", {
                                                children: "Notify to shore you have read some comments about deficiencies",
                                                className: "text-tiny text-center"
                                              })
                                        })
                                  ],
                                  className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-mb-2"
                                })
                          });
              }));
}

export {
  init ,
  valid ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RRule from "../../../libs/RRule.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as CompanyForm from "../../company_forms/CompanyForm.js";
import * as CompanyFormSubmission from "../../company_forms/CompanyFormSubmission.js";

var url = "/company-forms/";

var fields = [
  [
    "id",
    "String"
  ],
  [
    "vessel_id",
    "String"
  ],
  [
    "name",
    "String"
  ],
  [
    "related_procedures",
    {
      TAG: "Array",
      _0: {
        TAG: "Deserializer",
        _0: CompanyForm.RelatedProcedure.Deserializer
      }
    }
  ],
  [
    "last_submissions",
    {
      TAG: "Array",
      _0: {
        TAG: "Deserializer",
        _0: CompanyFormSubmission.Deserializer
      }
    }
  ],
  [
    "allow_partial_submission",
    "Boolean"
  ],
  [
    "recurrence",
    {
      TAG: "Optional",
      _0: {
        TAG: "DefaultWhenInvalid",
        _0: {
          TAG: "Deserializer",
          _0: {
            name: RRule.Deserializer.name,
            fromJSON: RRule.Deserializer.fromJSON,
            checkFieldsSanity: RRule.Deserializer.checkFieldsSanity
          }
        },
        _1: RRule.Defaults.biweekly
      }
    }
  ]
];

var CompanyFormAPIDefinition = {
  url: url,
  fields: fields
};

var include = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var get = include.get;

var options = include.options;

export {
  CompanyFormAPIDefinition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  get ,
  options ,
}
/* include Not a pure module */

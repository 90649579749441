// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";
import * as DevelopersArenaWrapper from "./DevelopersArenaWrapper.js";

function DevelopersArenaLink(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(DevelopersArenaWrapper.make, {
                            children: React.jsx(Mithril.Link.make, {
                                  href: "/!debug",
                                  className: "tw-text-[#808CA3] tw-block",
                                  children: "Go to Developer's Arena"
                                })
                          });
              }));
}

var make = DevelopersArenaLink;

export {
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Permissions from "./Permissions.js";

var Options = {};

var canShowDesktopNotification = ((typeof Notification != 'undefined'));

var canRequestPermission = ((typeof Notification != 'undefined') && typeof Notification.requestPermission === 'function');

var include = $$Permissions.MakePermissionRequester({
      canRequestPermission: canRequestPermission,
      requestPermission: (function (prim) {
          return Notification.requestPermission();
        })
    });

function make(param) {
  
}

function makeWithOptions(param, param$1) {
  
}

function addEventListener(param, param$1, param$2) {
  
}

function requestPermission() {
  return Promise.resolve("default");
}

var make$1 = canShowDesktopNotification ? (function (prim) {
      return new Notification(prim);
    }) : make;

var makeWithOptions$1 = canShowDesktopNotification ? (function (prim0, prim1) {
      return new Notification(prim0, prim1);
    }) : makeWithOptions;

var getPermission = canShowDesktopNotification ? (function () {
      return Notification.permission;
    }) : (function () {
      return "default";
    });

var addEventListener$1 = canShowDesktopNotification ? (function (prim0, prim1, prim2) {
      prim0.addEventListener(prim1, prim2);
    }) : addEventListener;

var requestPermission$1 = canShowDesktopNotification ? include.requestPermission : requestPermission;

var hasDesktopNotification = canShowDesktopNotification;

export {
  Options ,
  hasDesktopNotification ,
  make$1 as make,
  makeWithOptions$1 as makeWithOptions,
  getPermission ,
  addEventListener$1 as addEventListener,
  requestPermission$1 as requestPermission,
}
/* canShowDesktopNotification Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Luxon from "luxon";
import * as Rrule from "rrule";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var Frequency = {};

function after(self, date, inclOpt, param) {
  var incl = inclOpt !== undefined ? inclOpt : false;
  return Caml_option.nullable_to_opt(self.after(date, incl));
}

function before(self, date, inclOpt, param) {
  var incl = inclOpt !== undefined ? inclOpt : false;
  return Caml_option.nullable_to_opt(self.before(date, incl));
}

var Rule = {
  after: after,
  before: before
};

function after$1(self, date, inclOpt) {
  var incl = inclOpt !== undefined ? inclOpt : false;
  return Caml_option.nullable_to_opt(self.after(date, incl));
}

function before$1(self, date, inclOpt) {
  var incl = inclOpt !== undefined ? inclOpt : false;
  return Caml_option.nullable_to_opt(self.before(date, incl));
}

function fromString(text) {
  var parsed;
  try {
    parsed = Rrule.rrulestr(text, {
          forceset: true
        });
  }
  catch (exn){
    return {
            TAG: "Error",
            _0: "Can't parse \"" + text + "\""
          };
  }
  Curry._2(Prelude.OptionExported.$$Option.forEach, Prelude.$$Array.first(Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keepMap, parsed.rrules(), (function (rule) {
                      return rule.origOptions.dtstart;
                    })), (function (prim) {
                  return prim;
                }), undefined)), (function (extra) {
          parsed.rdate(extra);
        }));
  return {
          TAG: "Ok",
          _0: parsed
        };
}

function harmonizationDate(self) {
  return Prelude.default(Prelude.$$Array.first(Prelude.$$Array.sort(self.rdates(), (function (prim) {
                        return prim;
                      }), undefined)), Luxon.DateTime.now().minus({
                    weeks: 1
                  }).toJSDate());
}

var $$Set = {
  after: after$1,
  before: before$1,
  fromString: fromString,
  harmonizationDate: harmonizationDate
};

function validate(input) {
  return Curry._2(Prelude.Result.map, fromString(input), (function (param) {
                return input;
              }));
}

function toSet(intput) {
  return Curry._1(Prelude.Result.getExn, fromString(intput));
}

var Defaults = {
  weekly: "RRULE:FREQ=WEEKLY;BYDAY=SU",
  biweekly: "RRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=SU",
  monthly: "RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=-1",
  quarterly: "RRULE:FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=-1",
  halfyearly: "RRULE:FREQ=MONTHLY;INTERVAL=6;BYMONTHDAY=-1",
  yearly: "RRULE:FREQ=YEARLY;BYYEARDAY=-1",
  biyearly: "RRULE:FREQ=YEARLY;INTERVAL=2;BYYEARDAY=-1"
};

var D = $$JSON.MakeDeserializer({
      fields: "String"
    });

function fromJSON(data) {
  return Curry._2(Prelude.Result.flatMap, D.fromJSON(data), validate);
}

var Deserializer_name = D.name;

var Deserializer_checkFieldsSanity = D.checkFieldsSanity;

var Deserializer = {
  D: D,
  name: Deserializer_name,
  fromJSON: fromJSON,
  checkFieldsSanity: Deserializer_checkFieldsSanity
};

export {
  Frequency ,
  Rule ,
  $$Set ,
  validate ,
  toSet ,
  Defaults ,
  Deserializer ,
}
/* D Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mithril from "mithril";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Js_promise2 from "rescript/lib/es6/js_promise2.js";

function get(url) {
  return {
          url: url,
          method: "GET",
          params: {},
          body: undefined,
          headers: {},
          background: true
        };
}

function post(url) {
  return {
          url: url,
          method: "POST",
          params: {},
          body: undefined,
          headers: {},
          background: true
        };
}

function options(url) {
  return {
          url: url,
          method: "OPTIONS",
          params: {},
          body: undefined,
          headers: {},
          background: true
        };
}

function put(url) {
  return {
          url: url,
          method: "PUT",
          params: {},
          body: undefined,
          headers: {},
          background: true
        };
}

function $$delete(url) {
  return {
          url: url,
          method: "DELETE",
          params: {},
          body: undefined,
          headers: {},
          background: true
        };
}

function patch(url) {
  return {
          url: url,
          method: "PATCH",
          params: {},
          body: undefined,
          headers: {},
          background: true
        };
}

function head(url) {
  return {
          url: url,
          method: "HEAD",
          params: {},
          body: undefined,
          headers: {},
          background: true
        };
}

function payload(request, payload$1) {
  var match = request.method;
  if (match === "HEAD" || match === "GET") {
    return {
            url: request.url,
            method: request.method,
            params: payload$1,
            body: request.body,
            headers: request.headers,
            background: request.background
          };
  } else {
    return {
            url: request.url,
            method: request.method,
            params: request.params,
            body: payload$1,
            headers: request.headers,
            background: request.background
          };
  }
}

function form(request, form$1) {
  var match = request.method;
  if (match === "GET") {
    return {
            url: request.url,
            method: request.method,
            params: form$1,
            body: request.body,
            headers: request.headers,
            background: request.background
          };
  } else {
    return {
            url: request.url,
            method: request.method,
            params: request.params,
            body: form$1,
            headers: request.headers,
            background: request.background
          };
  }
}

function json(request, body) {
  return {
          url: request.url,
          method: request.method,
          params: request.params,
          body: body,
          headers: request.headers,
          background: request.background
        };
}

function headers(request, headers$1) {
  return {
          url: request.url,
          method: request.method,
          params: request.params,
          body: request.body,
          headers: Js_dict.fromArray(headers$1),
          background: request.background
        };
}

function addHeaders(request, extraHeaders) {
  var entries = Js_array.concat(Js_dict.entries(request.headers), extraHeaders);
  return headers(request, entries);
}

function $$do(request) {
  return Js_promise2.$$catch(Js_promise2.then(Mithril.request(request), (async function (data) {
                    return {
                            TAG: "Ok",
                            _0: data
                          };
                  })), (async function (error) {
                return {
                        TAG: "Error",
                        _0: error
                      };
              }));
}

var _empty;

export {
  _empty ,
  get ,
  post ,
  options ,
  put ,
  $$delete ,
  patch ,
  head ,
  payload ,
  form ,
  json ,
  headers ,
  addHeaders ,
  $$do ,
}
/* mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mutex from "../../libs/Mutex.js";
import * as Query from "../Query.js";
import * as Network from "../../libs/Network.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as VesselId from "../vessel/VesselId.js";
import * as AppSchema from "../AppSchema.js";
import * as VesselStaff from "../vessel/VesselStaff.js";
import * as FleetVesselAPI from "./endpoints/FleetVesselAPI.js";
import * as ServerSyncFiles from "./syncsteps/ServerSyncFiles.js";
import * as ServerSyncPipeline from "./common/ServerSyncPipeline.js";
import * as ConnectionIndicator from "../common/views/ConnectionIndicator.js";
import * as ServerSyncUserProfiles from "./syncsteps/ServerSyncUserProfiles.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var user = param.user;
  return Prelude.PromisedResult.map(FleetVesselAPI.getAll(undefined, undefined, undefined, user), (function (vesselsWithStaff) {
                var index = VesselId.$$Set.fromArray(vesselsWithStaff.map(function (v) {
                          return v.id;
                        }));
                var vesselIds = VesselId.$$Set.toArray(index);
                var newStaff = Prelude.$$Array.flatMap(vesselsWithStaff, (function (i) {
                          return i.staff.map(function (s) {
                                      return [
                                              i.id,
                                              s
                                            ];
                                    });
                        })).map(function (param) {
                      return VesselStaff.fromDeserialized(param[0], param[1]);
                    });
                var newVessels = vesselsWithStaff.map(function (vessel) {
                      return {
                              id: vessel.id,
                              name: vessel.name,
                              max_pic_resolution: vessel.max_pic_resolution,
                              picture_prompt_every_n_inspections: vessel.picture_prompt_every_n_inspections,
                              company_logo: vessel.company_logo,
                              migrated: vessel.migrated,
                              mandatory_findings_deadline: vessel.mandatory_findings_deadline,
                              mandatory_findings_observations: vessel.mandatory_findings_observations,
                              hide_observations: vessel.hide_observations,
                              use_embedded_camera: vessel.use_embedded_camera,
                              enable_psc: vessel.enable_psc
                            };
                    });
                var $$final$1 = AppSchema.Staffers.updateMany(AppSchema.Vessels.updateMany($$final, newVessels), newStaff);
                return {
                        user: user,
                        vessels: vesselIds,
                        initial: initial,
                        final: $$final$1
                      };
              }));
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.staffers = {
                    TAG: "NoneOf",
                    _0: "vessel",
                    _1: vessels
                  }, newrecord.newAnswers = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.answers = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.questions = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.levels = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.companyFormSubmissions = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.companyForms = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.deficiencies = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.newInspections = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.draftInspections = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.inspections = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.procedures = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.components = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.sections = {
                    TAG: "NoneOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.vessels = "All", newrecord)), (function (param) {
                var initial$1 = AppSchema.Vessels.updateMany(initial, param.vessels);
                Curry._2(Prelude.$$Array.forEach, param.sections, (function (extra) {
                        return AppSchema.Sections.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.components, (function (extra) {
                        return AppSchema.Components.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.deficiencies, (function (extra) {
                        return AppSchema.Deficiencies.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.newInspections, (function (extra) {
                        return AppSchema.NewInspections.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.draftInspections, (function (extra) {
                        return AppSchema.DraftInspections.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.companyForms, (function (extra) {
                        return AppSchema.CompanyForms.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.companyFormSubmissions, (function (extra) {
                        return AppSchema.CompanyFormSubmissions.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.levels, (function (extra) {
                        return AppSchema.Levels.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.questions, (function (extra) {
                        return AppSchema.Questions.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.answers, (function (extra) {
                        return AppSchema.Answers.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.newAnswers, (function (extra) {
                        return AppSchema.Answers.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.inspections, (function (extra) {
                        return AppSchema.Inspections.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.procedures, (function (extra) {
                        return AppSchema.Procedures.update(initial$1, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.staffers, (function (extra) {
                        return AppSchema.Staffers.update(initial$1, extra);
                      }));
                var finalVessels = AppSchema.Vessels.map($$final);
                AppSchema.Vessels.forEach(initial$1, (function (v) {
                        var vessel = VesselId.$$Map.get(finalVessels, v.id);
                        if (vessel === undefined) {
                          return ;
                        }
                        var newrecord = Caml_obj.obj_dup(vessel);
                        AppSchema.Vessels.update($$final, (newrecord.modules = v.modules, newrecord));
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial$1,
                        final: $$final
                      };
              }));
}

var ApplyLocallyKnownState = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.staffers = AppSchema.Staffers.getActions(initial, $$final);
  newrecord.newAnswers = AppSchema.Answers.getActions(initial, $$final);
  newrecord.answers = AppSchema.Answers.getActions(initial, $$final);
  newrecord.questions = AppSchema.Questions.getActions(initial, $$final);
  newrecord.levels = AppSchema.Levels.getActions(initial, $$final);
  newrecord.companyFormSubmissions = AppSchema.CompanyFormSubmissions.getActions(initial, $$final);
  newrecord.companyForms = AppSchema.CompanyForms.getActions(initial, $$final);
  newrecord.deficiencies = AppSchema.Deficiencies.getActions(initial, $$final);
  newrecord.newInspections = AppSchema.NewInspections.getActions(initial, $$final);
  newrecord.draftInspections = AppSchema.DraftInspections.getActions(initial, $$final);
  newrecord.inspections = AppSchema.Inspections.getActions(initial, $$final);
  newrecord.procedures = AppSchema.Procedures.getActions(initial, $$final);
  newrecord.components = AppSchema.Components.getActions(initial, $$final);
  newrecord.sections = AppSchema.Sections.getActions(initial, $$final);
  newrecord.vessels = AppSchema.Vessels.getActions(initial, $$final);
  Query.ActionLogging.info("SyncVesselsList" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var FillInitialStatus = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [
  AppSchema.Vessels,
  AppSchema.Sections,
  AppSchema.Components,
  AppSchema.Procedures,
  AppSchema.Inspections,
  AppSchema.CompanyForms,
  AppSchema.CompanyFormSubmissions,
  AppSchema.Levels,
  AppSchema.Questions,
  AppSchema.Answers
];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var Standard = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "SyncVesselsList",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var steps = [
  [
    "API",
    Standard.$$do
  ],
  [
    "Sync with user profiles API endpoint",
    ServerSyncUserProfiles.$$do
  ],
  [
    "Trigger files updates",
    ServerSyncFiles.$$do
  ]
];

var include = ServerSyncPipeline.MakeChainPipeline({
      steps: steps
    });

var $$do$3 = include.$$do;

var Pipeline = {
  Standard: Standard,
  $$do: $$do$3
};

function action(user) {
  Network.QueueManager.clear();
  return ConnectionIndicator.show(function () {
              return $$do$3({
                          user: user,
                          vessels: [],
                          initial: AppSchema.make(),
                          final: AppSchema.make()
                        });
            });
}

var Executor = {
  name: "SyncVesselsList",
  action: action
};

var include$1 = Mutex.MakeMutexQueue(Executor);

var name = include$1.name;

var action$1 = include$1.action;

var $$do$4 = include$1.$$do;

export {
  ReadServerState ,
  ApplyLocallyKnownState ,
  UpdateDB ,
  Pipeline ,
  Executor ,
  name ,
  action$1 as action,
  $$do$4 as $$do,
}
/* Standard Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function make(param) {
  return function (db, _transaction) {
    var levels = IDB__Migration__Database.createObjectStore(db, "levels");
    IDB__Migration__Store.createSimpleIndex(levels, "vessel_id");
    IDB__Migration__Store.createSimpleIndex(levels, "parent_id");
    IDB__Migration__Store.createSimpleIndex(levels, "path");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Store Not a pure module */

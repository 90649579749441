// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing updates for company forms";
}

function toRelatedProcedure(procedure) {
  return {
          procedure_id: procedure,
          not_applicable_status: undefined
        };
}

function $$process(companyForm, param) {
  return {
          TAG: "Update",
          _0: {
            id: companyForm.id,
            vessel_id: companyForm.vessel_id,
            name: companyForm.name,
            related_procedures: companyForm.related_procedures.map(toRelatedProcedure),
            recurrence: companyForm.recurrence
          }
        };
}

var CompanyFormRewriter = InitFeedback.MakeRewriter({
      storeName: "companyForms",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing updates for company forms";
}

function migrate(_db, transaction) {
  return CompanyFormRewriter.rewrite(transaction, undefined);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* CompanyFormRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UserId from "./UserId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var UserDef = {};

var include = ReIndexed.MakeIdModel(UserDef, {});

function name(user) {
  var match = user.first_name;
  var match$1 = user.last_name;
  if (match === "") {
    if (match$1 === "") {
      return ;
    } else {
      return match$1;
    }
  } else if (match$1 === "") {
    return match;
  } else {
    return match + " " + match$1;
  }
}

var anonymous = {
  id: UserId.zero,
  first_name: "",
  last_name: "",
  token: "",
  email: "",
  has_valid_password: false
};

function isKaikoEmployee(user) {
  return user.email.endsWith("@kaikosystems.com");
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  UserDef ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  name ,
  anonymous ,
  isKaikoEmployee ,
}
/* include Not a pure module */

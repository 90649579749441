// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as IDB__Request from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/IDB__Request.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

var Id = Utils.MakeOpaqueIdentifier({});

var SectionReader = IDB__Migration__Utils.MakeReader({
      storeName: "sections"
    });

var Id$1 = Utils.MakeOpaqueIdentifier({});

var ComponentReader = IDB__Migration__Utils.MakeReader({
      storeName: "components"
    });

Utils.MakeOpaqueIdentifier({});

function message(param, param$1) {
  return "Installing updates for inspection procedures";
}

function $$process(proc, param) {
  var match = Id.$$Map.get(param[0], proc.section_id);
  var match$1 = Id$1.$$Map.get(param[1], proc.component_id);
  if (match !== undefined && match$1 !== undefined) {
    return {
            TAG: "Update",
            _0: {
              id: proc.id,
              vessel_id: proc.vessel_id,
              section_id: proc.section_id,
              component_id: proc.component_id,
              name: proc.name,
              order: proc.order,
              position_diagram_id: proc.position_diagram_id,
              position_diagram_desc: proc.position_diagram_desc,
              example_image_id: proc.example_image_id,
              example_image_desc: proc.example_image_desc,
              description: proc.description,
              require_landscape_pictures: proc.require_landscape_pictures,
              question_type: proc.question_type,
              rating_styling_rules_id: proc.rating_styling_rules_id,
              require_image_conditions: proc.require_image_conditions,
              recurrence: proc.recurrence,
              due_date: proc.due_date,
              overdue_date: proc.overdue_date,
              ignore_overdue_status: proc.ignore_overdue_status,
              vessel_position: proc.vessel_position,
              extra: {
                section_name: match.name,
                component_name: match$1.name,
                section_type: match.type,
                component_type: match$1.type,
                component_safety_type: match$1.safety_type
              }
            }
          };
  } else {
    return "Delete";
  }
}

var ProcedureRewriter = InitFeedback.MakeRewriter({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing updates for inspection procedures";
}

function migrate(_db, transaction) {
  return Prelude.PromisedResult.map(Prelude.PromisedResult.bind(Prelude.then(Prelude.then(SectionReader.read(transaction), (function (sections) {
                            var sections$1 = Id.$$Map.fromArray(sections.map(function (s) {
                                      return [
                                              s.id,
                                              s
                                            ];
                                    }));
                            return Prelude.thenResolve(ComponentReader.read(transaction), (function (components) {
                                          return [
                                                  sections$1,
                                                  Id$1.$$Map.fromArray(components.map(function (s) {
                                                            return [
                                                                    s.id,
                                                                    s
                                                                  ];
                                                          }))
                                                ];
                                        }));
                          })), (function (param) {
                        return ProcedureRewriter.rewrite(transaction, [
                                    param[0],
                                    param[1]
                                  ]);
                      })), (function () {
                    return Prelude.catchResolve(Prelude.thenResolve(IDB__Request.promise(transaction.objectStore("plannedProcedures").clear()), (function (param) {
                                      return {
                                              TAG: "Ok",
                                              _0: undefined
                                            };
                                    })), (function (param) {
                                  return {
                                          TAG: "Error",
                                          _0: "Failed to clear plannedProcedures"
                                        };
                                }));
                  })), (function () {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* Id Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LocalFile from "../../../libs/LocalFile.js";
import * as WebCryptoAPI from "../../../libs/WebCryptoAPI.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as FutureAttachment from "../../attachment_chunks/FutureAttachment.js";

var MissingAttachment = /* @__PURE__ */Caml_exceptions.create("FutureAttachmentAPI.MissingAttachment");

var fields = [
  [
    "future_attachment_id",
    "String"
  ],
  [
    "id",
    "String"
  ]
];

var fields$1 = [
  [
    "future_attachment_id",
    "String"
  ],
  [
    "id",
    "String"
  ]
];

var fields$2 = [
  [
    "future_attachment_id",
    "String"
  ],
  [
    "id",
    "String"
  ]
];

var fields$3 = [
  [
    "future_attachment_id",
    "String"
  ],
  [
    "id",
    "String"
  ]
];

var ReadEndpoint = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields,
      url: "/attachments_chunks"
    });

var AnswerWriteEndpoint = ApiMaker.MakeAPIEndpoint({
      fields: fields$1,
      url: "/attachments_chunks/answer"
    });

var InspectionWriteEndpoint = ApiMaker.MakeAPIEndpoint({
      fields: fields$2,
      url: "/attachments_chunks/inspection"
    });

var AvatarWriteEndpoint = ApiMaker.MakeAPIEndpoint({
      fields: fields$3,
      url: "/attachments_chunks/avatar"
    });

async function uploadChunk(params, user, group, chunkId) {
  var future_attachment_size = group.size;
  var chunk = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, group.allChunks, (function (c) {
              return Caml_obj.equal(c.id, chunkId);
            })));
  if (chunk !== undefined) {
    var resource = await LocalFile.getResource(chunkId);
    if (resource !== undefined) {
      var form = new FormData();
      var match = group.mimetype;
      var fileName;
      switch (match) {
        case "image/avif" :
            fileName = chunkId + ".avif";
            break;
        case "image/gif" :
            fileName = chunkId + ".gif";
            break;
        case "image/jpeg" :
            fileName = chunkId + ".jpg";
            break;
        case "image/png" :
            fileName = chunkId + ".png";
            break;
        case "image/svg+xml" :
            fileName = chunkId + ".svg";
            break;
        case "image/tiff" :
            fileName = chunkId + ".tiff";
            break;
        case "image/webp" :
            fileName = chunkId + ".webp";
            break;
        default:
          fileName = chunkId;
      }
      form.append("file", new File([resource.file], fileName, {
                type: "application/x-kaiko-future-attachment-chunk"
              }), undefined);
      form.append("id", chunkId);
      form.append("size", chunk.size);
      form.append("future_attachment_id", group.id);
      form.append("future_attachment_size", String(future_attachment_size));
      form.append("future_attachment_checksum", WebCryptoAPI.HexDigest.toString(group.checksum));
      form.append("future_attachment_checksum_algorithm", group.checksum_algorithm);
      Prelude.OptionExported.$$Option.$$do(chunk.checksum, (function (checksum) {
              form.append("checksum", WebCryptoAPI.HexDigest.toString(checksum));
            }));
      Prelude.OptionExported.$$Option.$$do(chunk.checksum_algorithm, (function (checksum_algorithm) {
              form.append("checksum_algorithm", checksum_algorithm);
            }));
      Curry._2(Prelude.$$Array.forEach, group.allChunks, (function (c) {
              form.append("expected_chunks_ids", c.id);
            }));
      form.append("chunk_sequence", FutureAttachment.Sequence.toString(chunk.sequence));
      var match$1 = group.payload;
      var variant = match$1.NAME;
      if (variant === "profile") {
        form.append("profile", match$1.VAL);
        return await AvatarWriteEndpoint.postForm(params, user, form);
      }
      if (variant === "inspection") {
        var match$2 = match$1.VAL;
        form.append("inspection", match$2[0]);
        form.append("role", match$2[1]);
        return await InspectionWriteEndpoint.postForm(params, user, form);
      }
      var match$3 = match$1.VAL;
      form.append("answer", match$3[0]);
      form.append("role", match$3[1]);
      return await AnswerWriteEndpoint.postForm(params, user, form);
    }
    console.error("FutureAttachmentAPI", "File not found locally, chunk id " + chunkId);
    return await Prelude.rejectWithError({
                RE_EXN_ID: MissingAttachment,
                code: 400,
                response: {},
                message: "Not found chunk id locally"
              });
  }
  console.error("FutureAttachmentAPI", "Chunk " + chunkId + " not found in the group");
  return await Prelude.rejectWithError({
              RE_EXN_ID: MissingAttachment,
              code: 400,
              response: {},
              message: "Chunk not found in the group"
            });
}

var getAll = ReadEndpoint.getAll;

var getMany = ReadEndpoint.getMany;

export {
  getAll ,
  getMany ,
  uploadChunk ,
}
/* ReadEndpoint Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as Answer from "../../tree/Answer.js";
import * as Vessel from "../../vessel/Vessel.js";
import * as Feather from "../../common/views/Feather.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../tree/Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as LevelType from "../../tree/LevelType.js";
import * as Deficiency from "../Deficiency.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as AnswerValue from "../../tree/AnswerValue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as R4SChecklist from "../../risk4sea/R4SChecklist.js";
import * as DeadlineLabel from "./DeadlineLabel.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as ButtonSlimIcon from "../../common/components/ButtonSlimIcon.js";
import * as FindingsFilter from "./FindingsFilter.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as LevelRiskStatus from "../../tree/LevelRiskStatus.js";
import * as ButtonFilterToggle from "../../common/components/ButtonFilterToggle.js";

function fromDeficiency(deficiency) {
  return {
          status: deficiency.status,
          profile_id: deficiency.profile_id,
          profile_name: deficiency.profile_name,
          deadline: deficiency.deadline
        };
}

function fromFinding(finding) {
  return {
          status: finding.status,
          profile_id: finding.profile_id,
          profile_name: finding.profile_name,
          deadline: finding.deadline
        };
}

var _map = {"current":"current","all":"all"};

function stateFromJs(param) {
  return _map[param];
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var extDeficiency = vnode.attrs.deficiency;
                var deficiency = extDeficiency.deficiency;
                var inspection = extDeficiency.inspection;
                var procedure = extDeficiency.procedure;
                var component = extDeficiency.component;
                var section = extDeficiency.section;
                var hasNewInspections = extDeficiency.hasNewInspections;
                var hasNewComments = extDeficiency.hasNewComments;
                var cardFooter = ReactDOM.jsxs("div", {
                      children: [
                        React.jsx(Conditional.make, {
                              cond: hasNewInspections,
                              children: ReactDOM.jsx("span", {
                                    children: "New inspection",
                                    className: "label label-gray ml-2 text-italic text-tiny"
                                  })
                            }),
                        React.jsx(Conditional.make, {
                              cond: hasNewComments,
                              children: ReactDOM.jsx("span", {
                                    children: "New comment",
                                    className: "label label-gray ml-2 text-italic text-tiny"
                                  })
                            })
                      ],
                      className: "card-footer px-2 py-1"
                    });
                if (inspection === undefined) {
                  return Mithril.empty;
                }
                var name = extDeficiency.profile_name;
                return React.jsx(KeepScrollLink.make, {
                            href: URLs.deficiency(deficiency.id),
                            children: Caml_option.some(ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("div", {
                                                      children: React.jsx(DeadlineLabel.make, {
                                                            status: deficiency.status,
                                                            deadline: deficiency.deadline,
                                                            className: "text-tiny m-0"
                                                          })
                                                    }),
                                                ReactDOM.jsx("div", {
                                                      children: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, inspection.code, (function (code) {
                                                                  return code;
                                                                })), ""),
                                                      className: "card-title text-bold text-small"
                                                    }),
                                                ReactDOM.jsxs("div", {
                                                      children: [
                                                        ReactDOM.jsxs("div", {
                                                              children: [
                                                                ReactDOM.jsx("b", {
                                                                      children: "Location: "
                                                                    }),
                                                                section.name + " / " + component.name
                                                              ]
                                                            }),
                                                        ReactDOM.jsxs("div", {
                                                              children: [
                                                                ReactDOM.jsx("b", {
                                                                      children: "Procedure: "
                                                                    }),
                                                                procedure.name
                                                              ]
                                                            }),
                                                        ReactDOM.jsxs("div", {
                                                              children: [
                                                                ReactDOM.jsx("b", {
                                                                      children: "Submitted: "
                                                                    }),
                                                                Luxon.DateTime.fromJSDate(inspection.timestamp).setZone("UTC").toLocaleString(Luxon.DateTime.DATETIME_FULL)
                                                              ]
                                                            }),
                                                        ReactDOM.jsxs("div", {
                                                              children: [
                                                                ReactDOM.jsx("b", {
                                                                      children: "Inspector: "
                                                                    }),
                                                                name !== undefined ? name : "Not Specified"
                                                              ]
                                                            })
                                                      ],
                                                      className: "card-subtitle text-gray text-tiny"
                                                    })
                                              ],
                                              className: "card-header"
                                            }),
                                        cardFooter
                                      ],
                                      className: "card card-shadow m-2"
                                    }))
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var extFinding = vnode.attrs.finding;
                var finding = extFinding.finding;
                var answer = extFinding.answer;
                var question = extFinding.question;
                var level = extFinding.level;
                var name = extFinding.profile_name;
                return React.jsx(KeepScrollLink.make, {
                            href: URLs.answerDetails(finding.vessel_id, level.id, answer.id),
                            children: Caml_option.some(ReactDOM.jsx("div", {
                                      children: ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("div", {
                                                    children: React.jsx(DeadlineLabel.make, {
                                                          status: finding.status,
                                                          deadline: finding.deadline,
                                                          className: "text-tiny m-0"
                                                        })
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    children: question.name,
                                                    className: "card-title text-small"
                                                  }),
                                              ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsxs("div", {
                                                            children: [
                                                              ReactDOM.jsx("b", {
                                                                    children: "Chapter: "
                                                                  }),
                                                              level.name
                                                            ]
                                                          }),
                                                      ReactDOM.jsxs("div", {
                                                            children: [
                                                              ReactDOM.jsx("b", {
                                                                    children: "Submitted: "
                                                                  }),
                                                              Luxon.DateTime.fromJSDate(answer.timestamp).toLocaleString(Luxon.DateTime.DATETIME_FULL)
                                                            ]
                                                          }),
                                                      ReactDOM.jsxs("div", {
                                                            children: [
                                                              ReactDOM.jsx("b", {
                                                                    children: "Inspector: "
                                                                  }),
                                                              name !== undefined ? name : "Not Specified"
                                                            ]
                                                          })
                                                    ],
                                                    className: "card-subtitle text-gray text-tiny mt-2"
                                                  })
                                            ],
                                            className: "card-header"
                                          }),
                                      className: "card card-shadow m-2"
                                    }))
                          });
              }));
}

function load(vnode) {
  var extFinding = vnode.attrs.finding;
  var vesselId = extFinding.finding.vessel_id;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.r4schecklists = R4SChecklist.ofVessel(vesselId), newrecord)), (function (param) {
          Mithril.setState(vnode, {
                TAG: "Loaded",
                checklists: param.r4schecklists
              });
        }));
}

function make$2(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), load), (function (vnode) {
                var extFinding = vnode.attrs.finding;
                var finding = extFinding.finding;
                var answer = extFinding.answer;
                var question = extFinding.question;
                var level = extFinding.level;
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var checklist = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, match.checklists, (function (c) {
                            return Caml_obj.equal(c.level_id, Prelude.default(level.parent_id, LevelId.$$null));
                          })));
                var item = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, checklist.items, (function (i) {
                            return Caml_obj.equal(i.level_id, level.id);
                          })));
                var name = extFinding.profile_name;
                return React.jsx(KeepScrollLink.make, {
                            href: URLs.answerDetails(finding.vessel_id, level.id, answer.id),
                            children: Caml_option.some(ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("div", {
                                            children: ReactDOM.jsxs("div", {
                                                  children: [
                                                    React.jsx(DeadlineLabel.make, {
                                                          status: finding.status,
                                                          deadline: finding.deadline,
                                                          className: "tw-text-xs tw-mt-0 tw-mx-0 tw-mb-2 tw-leading-5"
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("b", {
                                                                  children: "Checklist for: "
                                                                }),
                                                            R4SChecklist.getName(checklist)
                                                          ],
                                                          className: "tw-mb-2"
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("b", {
                                                                  children: "Question type: "
                                                                }),
                                                            Prelude.default(item.detention_type, "None")
                                                          ],
                                                          className: "tw-mb-2"
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("b", {
                                                                  children: "Question group: "
                                                                }),
                                                            item.description
                                                          ],
                                                          className: "tw-mb-2"
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("b", {
                                                                  children: "Question: "
                                                                }),
                                                            question.name
                                                          ],
                                                          className: "tw-mb-2"
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("b", {
                                                                  children: "Previous answer: "
                                                                }),
                                                            AnswerValue.toHuman(answer.value)
                                                          ],
                                                          className: "tw-mb-2"
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("b", {
                                                                  children: "Inspector: "
                                                                }),
                                                            name !== undefined ? name : "Not Specified"
                                                          ],
                                                          className: "tw-mb-2"
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("b", {
                                                                  children: "Submitted: "
                                                                }),
                                                            Luxon.DateTime.fromJSDate(answer.timestamp).toLocaleString(Luxon.DateTime.DATETIME_FULL)
                                                          ],
                                                          className: "tw-mb-2"
                                                        })
                                                  ],
                                                  className: "text-gray tw-text-xs tw-mt-2 tw-p-2"
                                                }),
                                            className: "tw-shadow-kaiko-card"
                                          }),
                                      className: "tw-p-2 sm:tw-w-10/12 tw-w-full tw-mx-auto "
                                    }))
                          });
              }));
}

function sortByDate(items, key) {
  return Prelude.$$Array.sort(items, (function (a) {
                return key(a).valueOf();
              }), true);
}

function getProfileName(profile_id, userProfiles) {
  if (profile_id === undefined) {
    return ;
  }
  var id = Caml_option.valFromOption(profile_id);
  var profile = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, userProfiles, (function (p) {
              return Caml_obj.equal(p.id, id);
            })));
  if (profile !== undefined) {
    return UserProfile.name(profile);
  }
  
}

async function populate(vnode, activeTab, profile) {
  Mithril.setState(vnode, "NotReady");
  var match = State__Memory.Current.get();
  var vesselId = VesselId.$$Option.map(match.vessel, (function (v) {
          return v.id;
        }));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.sire2VettingPeriods = {
          TAG: "Is",
          _0: "vessel_id",
          _1: Vessel.Id.toString(vesselId)
        }, newrecord.findings = {
          TAG: "Is",
          _0: "vessel_id",
          _1: Vessel.Id.toString(vesselId)
        }, newrecord.userProfiles = "All", newrecord.deficiencies = {
          TAG: "Is",
          _0: "vessel_id",
          _1: Vessel.Id.toString(vesselId)
        }, newrecord));
  var findings = match$1.findings;
  var userProfiles = match$1.userProfiles;
  var deficiencies = match$1.deficiencies;
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$1.answers = Answer.ofQuestions(findings.map(function (f) {
                  return f.question_id;
                })), newrecord$1.questions = {
          TAG: "In",
          _0: findings.map(function (f) {
                return f.question_id;
              })
        }, newrecord$1.levelTypes = "All", newrecord$1.levels = {
          TAG: "In",
          _0: findings.map(function (f) {
                return f.level_id;
              })
        }, newrecord$1.newComments = {
          TAG: "Is",
          _0: "vessel_id",
          _1: Vessel.Id.toString(vesselId)
        }, newrecord$1.newInspections = NewInspection.ofProcedures(deficiencies.map(Deficiency.byProcedure)), newrecord$1.inspections = {
          TAG: "In",
          _0: deficiencies.map(Deficiency.byDetectedWith)
        }, newrecord$1.procedures = {
          TAG: "In",
          _0: deficiencies.map(Deficiency.byProcedure)
        }, newrecord$1.components = {
          TAG: "In",
          _0: deficiencies.map(Deficiency.byComponent)
        }, newrecord$1.sections = {
          TAG: "In",
          _0: deficiencies.map(Deficiency.bySection)
        }, newrecord$1));
  var answers = match$2.answers;
  var questions = match$2.questions;
  var levelTypes = match$2.levelTypes;
  var levels = match$2.levels;
  var newComments = match$2.newComments;
  var newInspections = match$2.newInspections;
  var inspections = match$2.inspections;
  var procedures = match$2.procedures;
  var components = match$2.components;
  var sections = match$2.sections;
  var extendedDeficiencies = sortByDate(Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.keepMap, deficiencies, (function (deficiency) {
                  var firstInspection = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, inspections, (function (i) {
                              return Caml_obj.equal(i.deficiency_id, Caml_option.some(deficiency.id));
                            })));
                  var lastInspection = Prelude.$$Array.last(Curry._2(Prelude.$$Array.keep, inspections, (function (i) {
                              return Caml_obj.equal(i.deficiency_id, Caml_option.some(deficiency.id));
                            })));
                  var inspection = firstInspection !== undefined ? firstInspection : lastInspection;
                  var procedure = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
                              return Caml_obj.equal(p.id, deficiency.procedure_id);
                            })));
                  var component = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, components, (function (c) {
                              return Caml_obj.equal(c.id, deficiency.component_id);
                            })));
                  var section = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, sections, (function (s) {
                              return Caml_obj.equal(s.id, deficiency.section_id);
                            })));
                  if (procedure === undefined) {
                    return ;
                  }
                  if (component === undefined) {
                    return ;
                  }
                  if (section === undefined) {
                    return ;
                  }
                  var status = deficiency.status;
                  var profile_id = inspection !== undefined ? inspection.profile : undefined;
                  var profile_name = getProfileName(profile_id, userProfiles);
                  var area = component.type;
                  var deadline = deficiency.deadline;
                  var hasNewInspections = Curry._2(Prelude.$$Array.keep, newInspections, (function (c) {
                          return Caml_obj.equal(c.procedure_id, procedure.id);
                        })).length !== 0;
                  var hasNewComments = Curry._2(Prelude.$$Array.keep, newComments, (function (c) {
                          return c.object_id === deficiency.id;
                        })).length !== 0;
                  return {
                          status: status,
                          deficiency: deficiency,
                          inspection: inspection,
                          procedure: procedure,
                          component: component,
                          section: section,
                          profile_id: profile_id,
                          profile_name: profile_name,
                          area: area,
                          deadline: deadline,
                          hasNewInspections: hasNewInspections,
                          hasNewComments: hasNewComments
                        };
                })), (function (extendedDeficiency) {
              return extendedDeficiency.inspection !== undefined;
            })), (function (extendedDeficiency) {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, extendedDeficiency.inspection, (function (i) {
                            return i.timestamp;
                          })), new Date());
        }));
  var extendedFindings = sortByDate(Curry._2(Prelude.$$Array.keepMap, findings, (function (finding) {
              var answer = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, answers, (function (a) {
                          return Caml_obj.equal(a.id, finding.created_by_id);
                        })));
              var question = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                          return Caml_obj.equal(q.id, finding.question_id);
                        })));
              var level = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                          return Caml_obj.equal(l.id, finding.level_id);
                        })));
              if (answer !== undefined && question !== undefined && level !== undefined) {
                var status = finding.status;
                var profile_id = answer.profile_id;
                var profile_name = getProfileName(profile_id, userProfiles);
                var deadline = finding.deadline;
                return {
                        status: status,
                        finding: finding,
                        answer: answer,
                        question: question,
                        level: level,
                        profile_id: profile_id,
                        profile_name: profile_name,
                        deadline: deadline
                      };
              }
              console.error("FindingList", "Missing something of the finding", {
                    finding: finding,
                    answer: answer,
                    question: question,
                    level: level
                  });
            })), (function (extendedFinding) {
          return extendedFinding.answer.timestamp;
        }));
  var sireType = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levelTypes, (function (l) {
                  return Prelude.$$Array.includes(["sire_chapter"], l.kind);
                }))), LevelType.Defaults.$$null);
  var r4sType = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levelTypes, (function (l) {
                  return Prelude.$$Array.includes(["risk4sea_checklist_item"], l.kind);
                }))), LevelType.Defaults.$$null);
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              activeTab: activeTab,
              profile: profile,
              deficiencies: extendedDeficiencies,
              findings: extendedFindings,
              sireType: sireType,
              r4sType: r4sType,
              accordion: {
                activeChecklist: false,
                activeStructural: false,
                activeSafety: false,
                activeSire: false,
                activeR4SChecklist: false,
                rectifiedChecklist: false,
                rectifiedStructural: false,
                rectifiedSafety: false,
                rectifiedSire: false,
                rectifiedR4SChecklist: false
              },
              filterState: FindingsFilter.Filter.defaultState,
              showModal: false,
              sire2VettingPeriodId: Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match$1.sire2VettingPeriods), (function (v) {
                      return v.id;
                    }))
            });
}

function switchState(vnode, newActiveTab, newProfile) {
  Mithril$1.route.set(URLs.deficiencyList(newActiveTab, newProfile));
  var currentState = vnode.state.data;
  if (typeof currentState !== "object") {
    return Mithril.setState(vnode, "NotReady");
  } else {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                activeTab: newActiveTab,
                profile: newProfile,
                deficiencies: currentState.deficiencies,
                findings: currentState.findings,
                sireType: currentState.sireType,
                r4sType: currentState.r4sType,
                accordion: currentState.accordion,
                filterState: currentState.filterState,
                showModal: currentState.showModal,
                sire2VettingPeriodId: currentState.sire2VettingPeriodId
              });
  }
}

function filterUpdate(vnode, filterType, newValue) {
  var currentState = vnode.state.data;
  if (typeof currentState !== "object") {
    return ;
  }
  var filterState = currentState.filterState;
  var newFilterState = filterType === "no" ? ({
        checklist: filterState.checklist,
        structural: filterState.structural,
        safety: filterState.safety,
        sire: filterState.sire,
        r4schecklist: filterState.r4schecklist,
        yes: filterState.yes,
        no: newValue
      }) : (
      filterType === "yes" ? ({
            checklist: filterState.checklist,
            structural: filterState.structural,
            safety: filterState.safety,
            sire: filterState.sire,
            r4schecklist: filterState.r4schecklist,
            yes: newValue,
            no: filterState.no
          }) : (
          filterType === "r4schecklist" ? ({
                checklist: filterState.checklist,
                structural: filterState.structural,
                safety: filterState.safety,
                sire: filterState.sire,
                r4schecklist: newValue,
                yes: filterState.yes,
                no: filterState.no
              }) : (
              filterType === "safety" ? ({
                    checklist: filterState.checklist,
                    structural: filterState.structural,
                    safety: newValue,
                    sire: filterState.sire,
                    r4schecklist: filterState.r4schecklist,
                    yes: filterState.yes,
                    no: filterState.no
                  }) : (
                  filterType === "structural" ? ({
                        checklist: filterState.checklist,
                        structural: newValue,
                        safety: filterState.safety,
                        sire: filterState.sire,
                        r4schecklist: filterState.r4schecklist,
                        yes: filterState.yes,
                        no: filterState.no
                      }) : (
                      filterType === "checklist" ? ({
                            checklist: newValue,
                            structural: filterState.structural,
                            safety: filterState.safety,
                            sire: filterState.sire,
                            r4schecklist: filterState.r4schecklist,
                            yes: filterState.yes,
                            no: filterState.no
                          }) : ({
                            checklist: filterState.checklist,
                            structural: filterState.structural,
                            safety: filterState.safety,
                            sire: newValue,
                            r4schecklist: filterState.r4schecklist,
                            yes: filterState.yes,
                            no: filterState.no
                          })
                    )
                )
            )
        )
    );
  Mithril.setState(vnode, {
        TAG: "Loaded",
        activeTab: currentState.activeTab,
        profile: currentState.profile,
        deficiencies: currentState.deficiencies,
        findings: currentState.findings,
        sireType: currentState.sireType,
        r4sType: currentState.r4sType,
        accordion: currentState.accordion,
        filterState: newFilterState,
        showModal: currentState.showModal,
        sire2VettingPeriodId: currentState.sire2VettingPeriodId
      });
}

function findingsAccordion(vnode, accordion_type, checked, label, count, content) {
  return ReactDOM.jsxs("div", {
              children: [
                ReactDOM.jsx("input", {
                      hidden: true,
                      id: accordion_type,
                      checked: checked,
                      name: "accordion-checkbox",
                      type: "checkbox"
                    }),
                ReactDOM.jsxs("label", {
                      children: [
                        React.jsx(Feather.make, {
                              icon: "chevron_right",
                              className: "icon",
                              style: "top: 0.1rem; position: relative; float: left; color: #5755D9;",
                              size: 27
                            }),
                        label,
                        ReactDOM.jsx("span", {
                              children: "(" + String(count) + ")",
                              className: "tw-pl-1 tw-text-gray-400 tw-font-normal"
                            })
                      ],
                      className: "accordion-header h5",
                      htmlFor: accordion_type,
                      onClick: (function (_event) {
                          var newValue = !checked;
                          var currentState = vnode.state.data;
                          if (typeof currentState !== "object") {
                            return ;
                          }
                          var accordion = currentState.accordion;
                          var newAccordion = accordion_type === "rectified_sire" ? ({
                                activeChecklist: accordion.activeChecklist,
                                activeStructural: accordion.activeStructural,
                                activeSafety: accordion.activeSafety,
                                activeSire: accordion.activeSire,
                                activeR4SChecklist: accordion.activeR4SChecklist,
                                rectifiedChecklist: accordion.rectifiedChecklist,
                                rectifiedStructural: accordion.rectifiedStructural,
                                rectifiedSafety: accordion.rectifiedSafety,
                                rectifiedSire: newValue,
                                rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                              }) : (
                              accordion_type === "active_safety" ? ({
                                    activeChecklist: accordion.activeChecklist,
                                    activeStructural: accordion.activeStructural,
                                    activeSafety: newValue,
                                    activeSire: accordion.activeSire,
                                    activeR4SChecklist: accordion.activeR4SChecklist,
                                    rectifiedChecklist: accordion.rectifiedChecklist,
                                    rectifiedStructural: accordion.rectifiedStructural,
                                    rectifiedSafety: accordion.rectifiedSafety,
                                    rectifiedSire: accordion.rectifiedSire,
                                    rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                  }) : (
                                  accordion_type === "active_r4schecklist" ? ({
                                        activeChecklist: accordion.activeChecklist,
                                        activeStructural: accordion.activeStructural,
                                        activeSafety: accordion.activeSafety,
                                        activeSire: accordion.activeSire,
                                        activeR4SChecklist: newValue,
                                        rectifiedChecklist: accordion.rectifiedChecklist,
                                        rectifiedStructural: accordion.rectifiedStructural,
                                        rectifiedSafety: accordion.rectifiedSafety,
                                        rectifiedSire: accordion.rectifiedSire,
                                        rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                      }) : (
                                      accordion_type === "rectified_checklist" ? ({
                                            activeChecklist: accordion.activeChecklist,
                                            activeStructural: accordion.activeStructural,
                                            activeSafety: accordion.activeSafety,
                                            activeSire: accordion.activeSire,
                                            activeR4SChecklist: accordion.activeR4SChecklist,
                                            rectifiedChecklist: newValue,
                                            rectifiedStructural: accordion.rectifiedStructural,
                                            rectifiedSafety: accordion.rectifiedSafety,
                                            rectifiedSire: accordion.rectifiedSire,
                                            rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                          }) : (
                                          accordion_type === "active_structural" ? ({
                                                activeChecklist: accordion.activeChecklist,
                                                activeStructural: newValue,
                                                activeSafety: accordion.activeSafety,
                                                activeSire: accordion.activeSire,
                                                activeR4SChecklist: accordion.activeR4SChecklist,
                                                rectifiedChecklist: accordion.rectifiedChecklist,
                                                rectifiedStructural: accordion.rectifiedStructural,
                                                rectifiedSafety: accordion.rectifiedSafety,
                                                rectifiedSire: accordion.rectifiedSire,
                                                rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                              }) : (
                                              accordion_type === "rectified_r4schecklist" ? ({
                                                    activeChecklist: accordion.activeChecklist,
                                                    activeStructural: accordion.activeStructural,
                                                    activeSafety: accordion.activeSafety,
                                                    activeSire: accordion.activeSire,
                                                    activeR4SChecklist: accordion.activeR4SChecklist,
                                                    rectifiedChecklist: accordion.rectifiedChecklist,
                                                    rectifiedStructural: accordion.rectifiedStructural,
                                                    rectifiedSafety: accordion.rectifiedSafety,
                                                    rectifiedSire: accordion.rectifiedSire,
                                                    rectifiedR4SChecklist: newValue
                                                  }) : (
                                                  accordion_type === "rectified_structural" ? ({
                                                        activeChecklist: accordion.activeChecklist,
                                                        activeStructural: accordion.activeStructural,
                                                        activeSafety: accordion.activeSafety,
                                                        activeSire: accordion.activeSire,
                                                        activeR4SChecklist: accordion.activeR4SChecklist,
                                                        rectifiedChecklist: accordion.rectifiedChecklist,
                                                        rectifiedStructural: newValue,
                                                        rectifiedSafety: accordion.rectifiedSafety,
                                                        rectifiedSire: accordion.rectifiedSire,
                                                        rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                                      }) : (
                                                      accordion_type === "active_checklist" ? ({
                                                            activeChecklist: newValue,
                                                            activeStructural: accordion.activeStructural,
                                                            activeSafety: accordion.activeSafety,
                                                            activeSire: accordion.activeSire,
                                                            activeR4SChecklist: accordion.activeR4SChecklist,
                                                            rectifiedChecklist: accordion.rectifiedChecklist,
                                                            rectifiedStructural: accordion.rectifiedStructural,
                                                            rectifiedSafety: accordion.rectifiedSafety,
                                                            rectifiedSire: accordion.rectifiedSire,
                                                            rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                                          }) : (
                                                          accordion_type === "rectified_safety" ? ({
                                                                activeChecklist: accordion.activeChecklist,
                                                                activeStructural: accordion.activeStructural,
                                                                activeSafety: accordion.activeSafety,
                                                                activeSire: accordion.activeSire,
                                                                activeR4SChecklist: accordion.activeR4SChecklist,
                                                                rectifiedChecklist: accordion.rectifiedChecklist,
                                                                rectifiedStructural: accordion.rectifiedStructural,
                                                                rectifiedSafety: newValue,
                                                                rectifiedSire: accordion.rectifiedSire,
                                                                rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                                              }) : ({
                                                                activeChecklist: accordion.activeChecklist,
                                                                activeStructural: accordion.activeStructural,
                                                                activeSafety: accordion.activeSafety,
                                                                activeSire: newValue,
                                                                activeR4SChecklist: accordion.activeR4SChecklist,
                                                                rectifiedChecklist: accordion.rectifiedChecklist,
                                                                rectifiedStructural: accordion.rectifiedStructural,
                                                                rectifiedSafety: accordion.rectifiedSafety,
                                                                rectifiedSire: accordion.rectifiedSire,
                                                                rectifiedR4SChecklist: accordion.rectifiedR4SChecklist
                                                              })
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            );
                          Mithril.setState(vnode, {
                                TAG: "Loaded",
                                activeTab: currentState.activeTab,
                                profile: currentState.profile,
                                deficiencies: currentState.deficiencies,
                                findings: currentState.findings,
                                sireType: currentState.sireType,
                                r4sType: currentState.r4sType,
                                accordion: newAccordion,
                                filterState: currentState.filterState,
                                showModal: currentState.showModal,
                                sire2VettingPeriodId: currentState.sire2VettingPeriodId
                              });
                        })
                    }),
                ReactDOM.jsx("div", {
                      children: content,
                      className: "accordion-body"
                    })
              ],
              className: "accordion tw-mt-4"
            });
}

function buildFindingsHeader(vnode, hasFindings) {
  var currentState = vnode.state.data;
  if (typeof currentState !== "object") {
    return Mithril.empty;
  }
  var activeTab = currentState.activeTab;
  var profile = currentState.profile;
  var filterState = currentState.filterState;
  var filterOn = FindingsFilter.Filter.anyFilter(filterState);
  var onClick = function (_event) {
    Mithril.setState(vnode, {
          TAG: "Loaded",
          activeTab: currentState.activeTab,
          profile: currentState.profile,
          deficiencies: currentState.deficiencies,
          findings: currentState.findings,
          sireType: currentState.sireType,
          r4sType: currentState.r4sType,
          accordion: currentState.accordion,
          filterState: currentState.filterState,
          showModal: true,
          sire2VettingPeriodId: currentState.sire2VettingPeriodId
        });
  };
  return ReactDOM.jsxs("div", {
              children: [
                React.jsx(Conditional.make, {
                      cond: filterOn,
                      children: ReactDOM.jsxs("div", {
                            children: [
                              React.jsx(ButtonSlimIcon.make, {
                                    icon: "filter",
                                    onClick: onClick
                                  }),
                              FindingsFilter.Filter.activeFilters(filterState).map(function (filter) {
                                    return React.jsx(ButtonFilterToggle.make, {
                                                text: FindingsFilter.Filter.toLabel(filter),
                                                onClick: (function (_event) {
                                                    filterUpdate(vnode, filter, false);
                                                  })
                                              });
                                  })
                            ],
                            className: Mithril.classList([
                                  "tw-flex tw-flex-row tw-items-center tw-justify-start tw-flex-wrap",
                                  "tw-gap-1 tw-pb-1"
                                ], undefined)
                          })
                    }),
                ReactDOM.jsxs("div", {
                      children: [
                        React.jsx(Conditional.make, {
                              cond: !filterOn,
                              children: React.jsx(ButtonSlimIcon.make, {
                                    icon: "filter",
                                    onClick: onClick,
                                    disabled: !hasFindings,
                                    className: "tw-absolute tw-left-1 tw-top-[-0.25rem]"
                                  })
                            }),
                        ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("button", {
                                      children: "Active",
                                      className: Mithril.$$class([
                                            [
                                              "btn",
                                              true
                                            ],
                                            [
                                              "active",
                                              activeTab === "active"
                                            ]
                                          ], undefined),
                                      onClick: (function (_event) {
                                          switchState(vnode, "active", profile);
                                        })
                                    }),
                                ReactDOM.jsx("button", {
                                      children: "Rectified",
                                      className: Mithril.$$class([
                                            [
                                              "btn",
                                              true
                                            ],
                                            [
                                              "active",
                                              activeTab === "rectified"
                                            ]
                                          ], undefined),
                                      onClick: (function (_event) {
                                          switchState(vnode, "rectified", profile);
                                        })
                                    })
                              ],
                              className: "btn-tabs"
                            })
                      ],
                      className: "tw-flex tw-items-center tw-justify-center tw-relative"
                    })
              ],
              className: "t-findings-header"
            });
}

function filterByDeadline(findings, key, deadlineType) {
  return findings.filter(function (finding) {
              if (deadlineType === "yes") {
                return Curry._1(Prelude.OptionExported.$$Option.isSome, key(finding).deadline);
              } else {
                return Curry._1(Prelude.OptionExported.$$Option.isNone, key(finding).deadline);
              }
            });
}

function createViewData(activeTab, profile, deficiencies, findings, sireType, r4sType, filterState, sire2VettingPeriodId) {
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var areasFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var filterByQuestionArea = function (question) {
    if (areasFilter !== undefined) {
      if (areasFilter.length !== 0) {
        if (Curry._1(Prelude.OptionExported.$$Option.isSome, question.area)) {
          return Prelude.$$Array.includes(areasFilter, Curry._1(Prelude.OptionExported.$$Option.getExn, question.area));
        } else {
          return false;
        }
      } else {
        return Curry._1(Prelude.OptionExported.$$Option.isNone, question.area);
      }
    } else {
      return true;
    }
  };
  var risksFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(LevelRiskStatus.tFromJs));
        }));
  var filterByRiskStatus = function (level) {
    if (risksFilter !== undefined) {
      if (risksFilter.length !== 0) {
        if (Curry._1(Prelude.OptionExported.$$Option.isSome, level.risk_status)) {
          return Prelude.$$Array.includes(risksFilter, Curry._1(Prelude.OptionExported.$$Option.getExn, level.risk_status));
        } else {
          return false;
        }
      } else {
        return Curry._1(Prelude.OptionExported.$$Option.isNone, level.risk_status);
      }
    } else {
      return true;
    }
  };
  var viewDeficiencies = Curry._3(Prelude.$$Array.fold, deficiencies, {
        structuralFindings: [],
        safetyFindings: [],
        checklistFindings: []
      }, (function (acc, d) {
          var checklistFindings = acc.checklistFindings;
          var safetyFindings = acc.safetyFindings;
          var structuralFindings = acc.structuralFindings;
          var isCurrentStatus = d.status === activeTab;
          var isAllOrCurrentProfile = profile === "all" ? true : (
              currentProfile !== undefined ? Caml_obj.equal(d.profile_id, Caml_option.some(currentProfile.id)) : false
            );
          if (isCurrentStatus && isAllOrCurrentProfile) {
            var match = d.area;
            if (match === "structural") {
              structuralFindings.push(d);
            } else if (match === "checklist") {
              checklistFindings.push(d);
            } else {
              safetyFindings.push(d);
            }
          }
          return {
                  structuralFindings: structuralFindings,
                  safetyFindings: safetyFindings,
                  checklistFindings: checklistFindings
                };
        }));
  var mainChecklistFindings = viewDeficiencies.checklistFindings;
  var mainStructuralFindings = viewDeficiencies.structuralFindings;
  var mainSafetyFindings = viewDeficiencies.safetyFindings;
  var mainSireFindings = sire2VettingPeriodId !== undefined ? Curry._2(Prelude.$$Array.keep, findings, (function (f) {
            var isAllOrCurrentProfile = profile === "all" ? true : (
                currentProfile !== undefined ? Caml_obj.equal(f.profile_id, Caml_option.some(currentProfile.id)) : false
              );
            if (f.status === activeTab && isAllOrCurrentProfile && Caml_obj.equal(f.level.kind, sireType.id) && filterByQuestionArea(f.question) && filterByRiskStatus(f.level)) {
              return Caml_obj.equal(f.answer.sire2_vetting_period_id, sire2VettingPeriodId);
            } else {
              return false;
            }
          })) : [];
  var mainR4SFindings = Curry._2(Prelude.$$Array.keep, findings, (function (f) {
          if (Caml_obj.equal(f.level.kind, r4sType.id)) {
            return f.status === activeTab;
          } else {
            return false;
          }
        }));
  var hasUnfilteredFindings = 0 < ((((mainChecklistFindings.length + mainStructuralFindings.length | 0) + mainSafetyFindings.length | 0) + mainSireFindings.length | 0) + mainR4SFindings.length | 0);
  var activeAreaFilter = FindingsFilter.Filter.activeAreaFilter(filterState);
  var match = activeAreaFilter ? [
      filterState.checklist ? mainChecklistFindings : [],
      filterState.structural ? mainStructuralFindings : [],
      filterState.safety ? mainSafetyFindings : [],
      filterState.sire ? mainSireFindings : [],
      filterState.r4schecklist ? mainR4SFindings : []
    ] : [
      mainChecklistFindings,
      mainStructuralFindings,
      mainSafetyFindings,
      mainSireFindings,
      mainR4SFindings
    ];
  var midR4SFindings = match[4];
  var midSireFindings = match[3];
  var midSafetyFindings = match[2];
  var midStructuralFindings = match[1];
  var midChecklistFindings = match[0];
  var activeDeadlineFilter = FindingsFilter.Filter.activeDeadlineFilter(filterState);
  var activeFilter = filterState.yes ? "yes" : "no";
  var deficienciesFiltering = function (deficiencies) {
    return filterByDeadline(deficiencies, fromDeficiency, activeFilter);
  };
  var findingsFiltering = function (findings) {
    return filterByDeadline(findings, fromFinding, activeFilter);
  };
  var match$1 = activeDeadlineFilter ? [
      deficienciesFiltering(midChecklistFindings),
      deficienciesFiltering(midStructuralFindings),
      deficienciesFiltering(midSafetyFindings),
      findingsFiltering(midSireFindings),
      findingsFiltering(midR4SFindings)
    ] : [
      midChecklistFindings,
      midStructuralFindings,
      midSafetyFindings,
      midSireFindings,
      midR4SFindings
    ];
  var finalR4SFindings = match$1[4];
  var finalSireFindings = match$1[3];
  var finalSafetyFindings = match$1[2];
  var finalStructuralFindings = match$1[1];
  var finalChecklistFindings = match$1[0];
  var countDeficienciesValue = function (deficiencies, value) {
    return filterByDeadline(deficiencies, fromDeficiency, value).length;
  };
  var countFindingsValue = function (findings, value) {
    return filterByDeadline(findings, fromFinding, value).length;
  };
  var filterValues_checklist = mainChecklistFindings.length;
  var filterValues_structural = mainStructuralFindings.length;
  var filterValues_safety = mainSafetyFindings.length;
  var filterValues_sire = mainSireFindings.length;
  var filterValues_r4schecklist = mainR4SFindings.length;
  var filterValues_yes = (((countDeficienciesValue(midChecklistFindings, "yes") + countDeficienciesValue(midStructuralFindings, "yes") | 0) + countDeficienciesValue(midSafetyFindings, "yes") | 0) + countFindingsValue(midSireFindings, "yes") | 0) + countFindingsValue(midR4SFindings, "yes") | 0;
  var filterValues_no = (((countDeficienciesValue(midChecklistFindings, "no") + countDeficienciesValue(midStructuralFindings, "no") | 0) + countDeficienciesValue(midSafetyFindings, "no") | 0) + countFindingsValue(midSireFindings, "no") | 0) + countFindingsValue(midR4SFindings, "no") | 0;
  var filterValues_currentTotal = (((finalChecklistFindings.length + finalStructuralFindings.length | 0) + finalSafetyFindings.length | 0) + finalSireFindings.length | 0) + finalR4SFindings.length | 0;
  var filterValues = {
    checklist: filterValues_checklist,
    structural: filterValues_structural,
    safety: filterValues_safety,
    sire: filterValues_sire,
    r4schecklist: filterValues_r4schecklist,
    yes: filterValues_yes,
    no: filterValues_no,
    currentTotal: filterValues_currentTotal
  };
  var disabledFilters_checklist = mainChecklistFindings.length === 0;
  var disabledFilters_structural = mainStructuralFindings.length === 0;
  var disabledFilters_safety = mainSafetyFindings.length === 0;
  var disabledFilters_sire = mainSireFindings.length === 0;
  var disabledFilters_r4schecklist = mainR4SFindings.length === 0;
  var disabledFilters_yes = 0 === ((((countDeficienciesValue(mainChecklistFindings, "yes") + countDeficienciesValue(mainStructuralFindings, "yes") | 0) + countDeficienciesValue(mainSafetyFindings, "yes") | 0) + countFindingsValue(mainSireFindings, "yes") | 0) + countFindingsValue(mainR4SFindings, "yes") | 0);
  var disabledFilters_no = 0 === ((((countDeficienciesValue(mainChecklistFindings, "no") + countDeficienciesValue(mainStructuralFindings, "no") | 0) + countDeficienciesValue(mainSafetyFindings, "no") | 0) + countFindingsValue(mainSireFindings, "no") | 0) + countFindingsValue(mainR4SFindings, "no") | 0);
  var disabledFilters = {
    checklist: disabledFilters_checklist,
    structural: disabledFilters_structural,
    safety: disabledFilters_safety,
    sire: disabledFilters_sire,
    r4schecklist: disabledFilters_r4schecklist,
    yes: disabledFilters_yes,
    no: disabledFilters_no
  };
  return {
          hasUnfilteredFindings: hasUnfilteredFindings,
          structuralFindings: finalStructuralFindings,
          safetyFindings: finalSafetyFindings,
          checklistFindings: finalChecklistFindings,
          sireFindings: finalSireFindings,
          r4sFindings: finalR4SFindings,
          filterValues: filterValues,
          disabledFilters: disabledFilters
        };
}

function make$3() {
  var activeTab = Prelude.default(Prelude.maybe(Mithril$1.route.param("activeTab"), Deficiency.statusFromJs), "active");
  var profile = Prelude.default(Prelude.maybe(Mithril$1.route.param("profile"), stateFromJs), "all");
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    populate(vnode, activeTab, profile);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var filterState = match.filterState;
                var accordion = match.accordion;
                var profile = match.profile;
                var activeTab = match.activeTab;
                var viewData = createViewData(activeTab, profile, match.deficiencies, match.findings, match.sireType, match.r4sType, filterState, match.sire2VettingPeriodId);
                var hasUnfilteredFindings = viewData.hasUnfilteredFindings;
                var structuralFindings = viewData.structuralFindings;
                var safetyFindings = viewData.safetyFindings;
                var checklistFindings = viewData.checklistFindings;
                var sireFindings = viewData.sireFindings;
                var r4sFindings = viewData.r4sFindings;
                var filterValues = viewData.filterValues;
                var disabledFilters = viewData.disabledFilters;
                var structuralCount = structuralFindings.length;
                var safetyCount = safetyFindings.length;
                var checklistCount = checklistFindings.length;
                var sireCount = sireFindings.length;
                var r4sCount = r4sFindings.length;
                var totalCount = (((structuralCount + safetyCount | 0) + checklistCount | 0) + sireCount | 0) + r4sCount | 0;
                var hasStructuralFindings = structuralCount > 0;
                var hasSafetyFindings = safetyCount > 0;
                var hasChecklistFindings = checklistCount > 0;
                var hasSireFindings = sireCount > 0;
                var hasR4SFindings = r4sCount > 0;
                var hasFindings = totalCount > 0;
                var emptyMessage = activeTab === "rectified" ? "No rectified findings" : "No active findings";
                var bannerMessage = profile === "all" ? "Findings displayed for all profiles" : "Findings displayed for current profile";
                var match$1 = activeTab === "rectified" ? [
                    "rectified_checklist",
                    accordion.rectifiedChecklist
                  ] : [
                    "active_checklist",
                    accordion.activeChecklist
                  ];
                var match$2 = activeTab === "rectified" ? [
                    "rectified_structural",
                    accordion.rectifiedStructural
                  ] : [
                    "active_structural",
                    accordion.activeStructural
                  ];
                var match$3 = activeTab === "rectified" ? [
                    "rectified_safety",
                    accordion.rectifiedSafety
                  ] : [
                    "active_safety",
                    accordion.activeSafety
                  ];
                var match$4 = activeTab === "rectified" ? [
                    "rectified_sire",
                    accordion.rectifiedSire
                  ] : [
                    "active_sire",
                    accordion.activeSire
                  ];
                var match$5 = activeTab === "rectified" ? [
                    "rectified_r4schecklist",
                    accordion.rectifiedR4SChecklist
                  ] : [
                    "active_r4schecklist",
                    accordion.activeR4SChecklist
                  ];
                return React.jsxs(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                      content: {
                                        NAME: "Title",
                                        VAL: "Findings"
                                      }
                                    })),
                            mod: "findings",
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      bannerMessage,
                                      React.jsx(Feather.make, {
                                            icon: "refresh_cw"
                                          })
                                    ],
                                    className: "findings-profile-banner t-banner tw-w-full tw-mb-2",
                                    onClick: (function (_event) {
                                        var newProfile = profile === "all" ? "current" : "all";
                                        switchState(vnode, activeTab, newProfile);
                                      })
                                  }),
                              React.jsx(FindingsFilter.make, {
                                    showModal: match.showModal,
                                    filters: filterState,
                                    values: filterValues,
                                    disabledFilters: disabledFilters,
                                    filterUpdate: (function (filterId, newValue) {
                                        filterUpdate(vnode, filterId, newValue);
                                      }),
                                    onClear: (function (_event) {
                                        var currentState = vnode.state.data;
                                        if (typeof currentState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      activeTab: currentState.activeTab,
                                                      profile: currentState.profile,
                                                      deficiencies: currentState.deficiencies,
                                                      findings: currentState.findings,
                                                      sireType: currentState.sireType,
                                                      r4sType: currentState.r4sType,
                                                      accordion: currentState.accordion,
                                                      filterState: FindingsFilter.Filter.defaultState,
                                                      showModal: currentState.showModal,
                                                      sire2VettingPeriodId: currentState.sire2VettingPeriodId
                                                    });
                                        }
                                      }),
                                    onClose: (function (_event) {
                                        var currentState = vnode.state.data;
                                        if (typeof currentState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      activeTab: currentState.activeTab,
                                                      profile: currentState.profile,
                                                      deficiencies: currentState.deficiencies,
                                                      findings: currentState.findings,
                                                      sireType: currentState.sireType,
                                                      r4sType: currentState.r4sType,
                                                      accordion: currentState.accordion,
                                                      filterState: currentState.filterState,
                                                      showModal: false,
                                                      sire2VettingPeriodId: currentState.sire2VettingPeriodId
                                                    });
                                        }
                                      })
                                  }),
                              buildFindingsHeader(vnode, hasUnfilteredFindings),
                              hasFindings ? ReactDOM.jsxs("div", {
                                      children: [
                                        React.jsx(Conditional.make, {
                                              cond: hasChecklistFindings,
                                              children: findingsAccordion(vnode, match$1[0], match$1[1], "Checklists", checklistCount, checklistFindings.map(function (extendedDef) {
                                                        var key = activeTab + "_" + extendedDef.deficiency.id;
                                                        return React.jsxKeyed(make, {
                                                                    deficiency: extendedDef
                                                                  }, key, undefined);
                                                      }))
                                            }),
                                        React.jsx(Conditional.make, {
                                              cond: hasStructuralFindings,
                                              children: findingsAccordion(vnode, match$2[0], match$2[1], "Hull & Equipment", structuralCount, structuralFindings.map(function (extendedDef) {
                                                        var key = activeTab + "_" + extendedDef.deficiency.id;
                                                        return React.jsxKeyed(make, {
                                                                    deficiency: extendedDef
                                                                  }, key, undefined);
                                                      }))
                                            }),
                                        React.jsx(Conditional.make, {
                                              cond: hasSafetyFindings,
                                              children: findingsAccordion(vnode, match$3[0], match$3[1], "Safety", safetyCount, safetyFindings.map(function (extendedDef) {
                                                        var key = activeTab + "_" + extendedDef.deficiency.id;
                                                        return React.jsxKeyed(make, {
                                                                    deficiency: extendedDef
                                                                  }, key, undefined);
                                                      }))
                                            }),
                                        React.jsx(Conditional.make, {
                                              cond: hasSireFindings,
                                              children: findingsAccordion(vnode, match$4[0], match$4[1], "SIRE 2.0 - Active vetting period", sireCount, sireFindings.map(function (extendedFinding) {
                                                        var key = activeTab + "_" + extendedFinding.finding.id;
                                                        return React.jsxKeyed(make$1, {
                                                                    finding: extendedFinding
                                                                  }, key, undefined);
                                                      }))
                                            }),
                                        React.jsx(Conditional.make, {
                                              cond: hasR4SFindings,
                                              children: findingsAccordion(vnode, match$5[0], match$5[1], "Port checklists", r4sCount, r4sFindings.map(function (extendedFinding) {
                                                        var key = activeTab + "_" + extendedFinding.finding.id;
                                                        return React.jsxKeyed(make$2, {
                                                                    finding: extendedFinding
                                                                  }, key, undefined);
                                                      }))
                                            })
                                      ],
                                      className: "t-findings pb-2"
                                    }) : ReactDOM.jsxs("div", {
                                      children: [
                                        React.jsx(Feather.make, {
                                              icon: "alert_triangle",
                                              className: "p-centered",
                                              size: 45
                                            }),
                                        ReactDOM.jsx("div", {
                                              className: "divider blank lg"
                                            }),
                                        ReactDOM.jsx("h4", {
                                              children: emptyMessage,
                                              className: "tw-text-gray-400"
                                            })
                                      ],
                                      className: "tw-mt-[50%] text-center text-gray"
                                    })
                            ]
                          });
              }));
}

export {
  make$3 as make,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing new Questions options";
}

function $$process(question) {
  return {
          id: question.id,
          levels: question.levels,
          level_ids: question.level_ids,
          level_paths: question.level_paths,
          vessel_id: question.vessel_id,
          guidance_document_id: undefined,
          name: question.name,
          kind: question.kind
        };
}

var QuestionRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "questions",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing new Questions options";
}

function migrate(param, t) {
  return QuestionRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* QuestionRewriter Not a pure module */

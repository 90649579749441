// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as RRule from "../../libs/RRule.js";
import * as Luxon from "luxon";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as VesselId from "../vessel/VesselId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CompanyFormId from "./CompanyFormId.js";
import * as CompanyFormSubmission from "./CompanyFormSubmission.js";

var fields = {
  TAG: "Object",
  _0: [[
      "procedure_id",
      "String"
    ]]
};

var include = $$JSON.MakeDeserializer({
      fields: fields
    });

var Deserializer = include;

var RelatedProcedure = {
  Deserializer: Deserializer
};

var Definition = {};

var null_related_procedures = [];

var $$null = {
  id: CompanyFormId.$$null,
  vessel_id: VesselId.$$null,
  name: "",
  related_procedures: null_related_procedures,
  allow_partial_submission: false,
  recurrence: undefined
};

var Defaults = {
  $$null: $$null
};

function cmp(a, b) {
  return Caml_obj.compare(a.id, b.id);
}

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

var include$1 = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function byName(companyForm) {
  return companyForm.name.toLowerCase();
}

var _map = {"recurrent":"recurrent","oneOff":"oneOff"};

function type_ToJs(param) {
  return param;
}

function type_FromJs(param) {
  return _map[param];
}

function getType(companyForm) {
  if (Curry._1(Prelude.OptionExported.$$Option.isSome, companyForm.recurrence)) {
    return "recurrent";
  } else {
    return "oneOff";
  }
}

function completionInformation(procedures, inspectedProcedures) {
  var total = procedures.length;
  var completed = Curry._2(Prelude.$$Array.keep, procedures, (function (id) {
          return Prelude.$$Array.includes(inspectedProcedures, id);
        })).length;
  var incomplete = total - completed | 0;
  return {
          total: total,
          completed: completed,
          incomplete: incomplete
        };
}

function isFullyInspected(procedures, inspectedProcedures) {
  var completion = completionInformation(procedures, inspectedProcedures);
  return completion.incomplete === 0;
}

function expectedSubmissions(form, submissions, draftSubmissionIds) {
  var recurrence = form.recurrence;
  if (recurrence === undefined) {
    return [];
  }
  var recurrence$1 = Caml_option.valFromOption(recurrence);
  var rset = RRule.toSet(recurrence$1);
  var today = new Date();
  var submissions$1 = Prelude.$$Array.sort(submissions, CompanyFormSubmission.byTargetDate, undefined);
  var harmonizationDate = RRule.$$Set.harmonizationDate(rset);
  var oldestSubmission = Prelude.$$Array.first(submissions$1);
  var begining;
  if (oldestSubmission !== undefined) {
    begining = oldestSubmission.target_date;
  } else {
    var today$1 = Luxon.DateTime.fromJSDate(today);
    var interval = Luxon.Interval.fromDateTimes(Luxon.DateTime.fromJSDate(harmonizationDate), today$1);
    begining = interval.length("years") >= 1.0 ? today$1.minus({
              years: 1
            }).toJSDate() : harmonizationDate;
  }
  var begining$1 = Prelude.default(RRule.$$Set.before(rset, begining, true), begining);
  var end = Prelude.default(RRule.$$Set.after(rset, today, undefined), today);
  var occurrences = rset.between(begining$1, end, true);
  var number = {
    contents: 0
  };
  return Curry._1(Prelude.$$Array.concatMany, Curry._2(Prelude.$$Array.zip, occurrences, Curry._2(Prelude.$$Array.sliceToEnd, occurrences, 1)).map(function (param) {
                  var until = param[1];
                  var since = param[0];
                  var submissions$2 = Curry._2(Prelude.$$Array.keep, submissions$1, (function (submission) {
                          if (Caml_obj.lessequal(since, submission.target_date)) {
                            return Caml_obj.lessthan(submission.target_date, until);
                          } else {
                            return false;
                          }
                        }));
                  if (submissions$2.length !== 0) {
                    return submissions$2.map(function (submission) {
                                number.contents = number.contents + 1 | 0;
                                return {
                                        id: number.contents,
                                        start_date: since,
                                        end_date: until,
                                        recurrence: recurrence$1,
                                        submission: submission,
                                        finished: !Prelude.$$Array.includes(draftSubmissionIds, submission.id)
                                      };
                              });
                  } else {
                    number.contents = number.contents + 1 | 0;
                    return [{
                              id: number.contents,
                              start_date: since,
                              end_date: until,
                              recurrence: recurrence$1,
                              submission: undefined,
                              finished: false
                            }];
                  }
                }));
}

var Identifier;

var clear = include$1.clear;

var save = include$1.save;

var $$delete = include$1.$$delete;

var remove = include$1.remove;

var IdComparator = include$1.IdComparator;

var Id = include$1.Id;

export {
  Identifier ,
  RelatedProcedure ,
  Definition ,
  Defaults ,
  Comparator ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  byName ,
  type_ToJs ,
  type_FromJs ,
  getType ,
  completionInformation ,
  isFullyInspected ,
  expectedSubmissions ,
}
/* include Not a pure module */

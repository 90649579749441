// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as UserProfileAPI from "../endpoints/UserProfileAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function convertProfile(state, profile) {
  var converted_id = profile.id;
  var converted_first_name = profile.first_name;
  var converted_last_name = profile.last_name;
  var converted_rank = profile.rank;
  var converted_vessel = profile.vessel;
  var converted_avatars = profile.avatars;
  var converted_user = profile.user;
  var converted_state = profile.state;
  var converted = {
    id: converted_id,
    first_name: converted_first_name,
    last_name: converted_last_name,
    rank: converted_rank,
    vessel: converted_vessel,
    avatars: converted_avatars,
    user: converted_user,
    state: converted_state
  };
  AppSchema.UserProfiles.update(state, converted);
  return state;
}

function convertProfiles(state, userProfiles) {
  return Curry._3(Prelude.$$Array.fold, userProfiles, state, convertProfile);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  if (Prelude.$$Array.isNotEmpty(vessels)) {
    return Prelude.PromisedResult.map(UserProfileAPI.getAll(undefined, {
                    vessel__id__in: vessels.join(",")
                  }, undefined, user), (function (profiles) {
                  var $$final$1 = convertProfiles($$final, profiles);
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final$1
                        };
                }));
  } else {
    AppSchema.UserProfiles.clear($$final);
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  }
}

var ReadServerState = {
  convertProfile: convertProfile,
  convertProfiles: convertProfiles,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.userProfiles = {
                    TAG: "AnyOf",
                    _0: "vessel",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.userProfiles, (function (extra) {
                        return AppSchema.UserProfiles.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.userProfiles = AppSchema.UserProfiles.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncUserProfiles" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.UserProfiles];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncUserProfiles",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../Query.js";
import * as React from "../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as UserId from "../accounts/UserId.js";
import * as Vessel from "../vessel/Vessel.js";
import * as Feather from "../common/views/Feather.js";
import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../libs/rescript-mithril/src/ReactDOM.js";
import * as Settings from "../Settings.js";
import * as VesselId from "../vessel/VesselId.js";
import * as Deficiency from "../tasks/Deficiency.js";
import * as ListLayout from "../common/views/ListLayout.js";
import * as Conditional from "../../libs/Conditional.js";
import * as State__Memory from "../state/State__Memory.js";
import * as WebNotification from "../../libs/WebNotification.js";
import * as NotificationEntry from "./NotificationEntry.js";
import * as ReadNotificationEntry from "./ReadNotificationEntry.js";

function loadState(vnode) {
  if (vnode.attrs.notification.read) {
    return Mithril.setState(vnode, "Read");
  } else {
    return Mithril.setState(vnode, "Unread");
  }
}

function markAsRead(vnode, notification) {
  var match = vnode.state.data;
  if (match === "Read") {
    var url = vnode.attrs.subjectUrl;
    if (url !== undefined) {
      Mithril$1.route.set(url);
      return ;
    } else {
      return ;
    }
  }
  var entry = ReadNotificationEntry.make(notification);
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  Prelude.thenDo(Query.write((newrecord.readNotificationEntries = [{
                TAG: "Save",
                _0: entry
              }], newrecord.notificationEntries = [{
                TAG: "Save",
                _0: {
                  id: notification.id,
                  vessel_id: notification.vessel_id,
                  kind: notification.kind,
                  user: notification.user,
                  read: true,
                  subject: notification.subject,
                  timestamp: notification.timestamp,
                  metadata: notification.metadata
                }
              }], newrecord)), (function (param) {
          var url = vnode.attrs.subjectUrl;
          if (url !== undefined) {
            Mithril$1.route.set(url);
          } else {
            vnode.state.data = "Read";
          }
        }));
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("Unread"), loadState), (function (vnode) {
                var state = vnode.state.data;
                var notification = vnode.attrs.notification;
                var unread = state === "Unread";
                var unreadClass = unread ? "badge" : "";
                var unreadCardClass = unread ? "bg-secondary" : "";
                var content = Curry._2(Prelude.Dict.get, notification.metadata, "text");
                var tmp;
                tmp = state === "Read" ? ReactDOM.jsx("div", {
                        children: content,
                        className: "text-italic"
                      }) : Mithril.empty;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  className: "float-right " + unreadClass
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: notification.subject,
                                                  className: unread ? "text-bold" : ""
                                                }),
                                            tmp
                                          ],
                                          className: "card-title text-small"
                                        }),
                                    className: "card-header"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("div", {
                                          children: ReactDOM.jsx("div", {
                                                children: Luxon.DateTime.fromJSDate(notification.timestamp).toRelative(),
                                                className: "column col-4 time-ago"
                                              }),
                                          className: "columns"
                                        }),
                                    className: "card-footer py-1 text-gray text-tiny"
                                  })
                            ],
                            className: "card card-shadow m-2 " + unreadCardClass,
                            onClick: (function (param) {
                                markAsRead(vnode, notification);
                              })
                          });
              }));
}

var NotificationCard = {
  loadState: loadState,
  markAsRead: markAsRead,
  make: make
};

var _map = {"granted":"granted","denied":"denied","default":"default"};

function permissionToJs(param) {
  return param;
}

function permissionFromJs(param) {
  return _map[param];
}

async function loadState$1(vnode) {
  var match = State__Memory.Current.get();
  var vesselId = VesselId.$$Option.map(match.vessel, (function (v) {
          return v.id;
        }));
  var userId = UserId.$$Option.map(match.user, (function (u) {
          return u.id;
        }));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.readNotificationEntries = ReadNotificationEntry.ofVessel(vesselId), newrecord.notificationEntries = {
          TAG: "And",
          _0: NotificationEntry.ofVessel(vesselId),
          _1: NotificationEntry.ofUser(userId)
        }, newrecord.findings = {
          TAG: "Is",
          _0: "vessel_id",
          _1: Vessel.Id.toString(vesselId)
        }, newrecord.deficiencies = {
          TAG: "Is",
          _0: "vessel_id",
          _1: Vessel.Id.toString(vesselId)
        }, newrecord));
  var deficiencies = match$1.deficiencies;
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$1.inspections = {
          TAG: "In",
          _0: deficiencies.map(Deficiency.byDetectedWith)
        }, newrecord$1));
  var readNotificationEntrieIds = match$1.readNotificationEntries.map(function (n) {
        return n.id;
      });
  var notificationEntries = match$1.notificationEntries.map(function (n) {
        var read = n.read || Prelude.$$Array.includes(readNotificationEntrieIds, n.id);
        return {
                id: n.id,
                vessel_id: n.vessel_id,
                kind: n.kind,
                user: n.user,
                read: read,
                subject: n.subject,
                timestamp: n.timestamp,
                metadata: n.metadata
              };
      });
  var read = Curry._2(Prelude.$$Array.keep, notificationEntries, (function (n) {
          return n.read === true;
        }));
  var unread = Curry._2(Prelude.$$Array.keep, notificationEntries, (function (n) {
          return n.read === false;
        }));
  var notificationPermission = WebNotification.getPermission();
  var enableNotifications = notificationPermission === "default" ? "asking" : "answered";
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              all: notificationEntries,
              read: read,
              unread: unread,
              deficiencies: deficiencies,
              findings: match$1.findings,
              inspections: match$2.inspections,
              enableNotifications: enableNotifications
            });
}

function askForPermission(vnode, _event) {
  console.log("Requesting permission to receive notifications.");
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  } else {
    return Prelude.thenDo(WebNotification.requestPermission(), (function (permission) {
                  var enableNotifications = permission === "default" ? "asking" : "answered";
                  Mithril.setState(vnode, {
                        TAG: "Loaded",
                        all: state.all,
                        read: state.read,
                        unread: state.unread,
                        deficiencies: state.deficiencies,
                        findings: state.findings,
                        inspections: state.inspections,
                        enableNotifications: enableNotifications
                      });
                }));
  }
}

function dismissEnableNotificationsMessage(vnode, _event) {
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  } else {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                all: state.all,
                read: state.read,
                unread: state.unread,
                deficiencies: state.deficiencies,
                findings: state.findings,
                inspections: state.inspections,
                enableNotifications: "dismissed"
              });
  }
}

function getSubjectFromNotification(notification, deficiencies, inspections) {
  var match = notification.kind;
  if (match === "finding_related_comment_was_posted") {
    var inspection = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, inspections, (function (i) {
                return notification.subject.indexOf(Prelude.default(i.code, "")) > -1;
              })));
    if (inspection === undefined) {
      return "";
    }
    var deficiency = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, deficiencies, (function (d) {
                return Caml_obj.equal(d.detected_with_id, inspection.id);
              })));
    return URLs.deficiency(deficiency.id);
  }
  var match$1 = State__Memory.Current.get();
  var vesselId = VesselId.$$Option.map(match$1.vessel, (function (v) {
          return v.id;
        }));
  return URLs.vesselSIRE(vesselId);
}

function make$1(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), loadState$1), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var inspections = match.inspections;
                var deficiencies = match.deficiencies;
                var all = match.all;
                var showEnableNotificationsMessage = Settings.useNotifications && WebNotification.hasDesktopNotification || match.enableNotifications === "asking";
                return React.jsxs(ListLayout.make, {
                            children: [
                              React.jsx(Conditional.make, {
                                    cond: showEnableNotificationsMessage,
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("button", {
                                                  className: "btn btn-clear float-right",
                                                  onClick: (function (extra) {
                                                      return dismissEnableNotificationsMessage(vnode, extra);
                                                    })
                                                }),
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    ReactDOM.jsx("p", {
                                                          children: "Do you want to receive notifications?"
                                                        }),
                                                    ReactDOM.jsx("a", {
                                                          children: "Enable notifications",
                                                          className: "btn btn-primary",
                                                          onClick: (function (extra) {
                                                              return askForPermission(vnode, extra);
                                                            })
                                                        })
                                                  ],
                                                  className: "p-2",
                                                  style: {
                                                    display: "flex",
                                                    flexDirection: "column"
                                                  }
                                                })
                                          ],
                                          className: "bg-secondary p-2"
                                        })
                                  }),
                              React.jsx(Conditional.make, {
                                    cond: Prelude.$$Array.isEmpty(all),
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    React.jsx(Feather.make, {
                                                          icon: "check",
                                                          className: "p-centered",
                                                          size: 45
                                                        }),
                                                    ReactDOM.jsx("div", {
                                                          className: "divider blank xl"
                                                        }),
                                                    ReactDOM.jsx("h4", {
                                                          children: "There are no notifications here",
                                                          className: "text-gray text-bold"
                                                        })
                                                  ],
                                                  className: "text-center text-gray"
                                                }),
                                            ReactDOM.jsx("div", {})
                                          ],
                                          style: {
                                            marginTop: "50%"
                                          }
                                        })
                                  }),
                              React.jsx(Conditional.make, {
                                    cond: !Prelude.$$Array.isEmpty(all),
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            match.unread.map(function (notification) {
                                                  var subjectUrl = getSubjectFromNotification(notification, deficiencies, inspections);
                                                  return React.jsxKeyed(make, {
                                                              notification: notification,
                                                              subjectUrl: subjectUrl
                                                            }, notification.id, undefined);
                                                }),
                                            match.read.map(function (notification) {
                                                  var subjectUrl = getSubjectFromNotification(notification, deficiencies, inspections);
                                                  return React.jsxKeyed(make, {
                                                              notification: notification,
                                                              subjectUrl: subjectUrl
                                                            }, notification.id, undefined);
                                                })
                                          ]
                                        })
                                  })
                            ],
                            title: "Notifications",
                            showBackButton: false,
                            showSettings: true,
                            section: "notifications"
                          });
              }));
}

export {
  NotificationCard ,
  permissionToJs ,
  permissionFromJs ,
  loadState$1 as loadState,
  askForPermission ,
  dismissEnableNotificationsMessage ,
  getSubjectFromNotification ,
  make$1 as make,
}
/* URLs Not a pure module */

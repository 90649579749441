// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RRule from "../../libs/RRule.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function recurrence_from_cooldown(cooldown) {
  if (cooldown === "P30DT00H00M00S") {
    return "RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=-1";
  } else if (cooldown === "P1825DT00H00M00S") {
    return "RRULE:FREQ=YEARLY;INTERVAL=5;BYYEARDAY=-1";
  } else if (cooldown === "P730DT00H00M00S") {
    return "RRULE:FREQ=YEARLY;INTERVAL=2;BYYEARDAY=-1";
  } else if (cooldown === "P365DT00H00M00S") {
    return "RRULE:FREQ=YEARLY;BYYEARDAY=-1";
  } else if (cooldown === "P90DT00H00M00S") {
    return "RRULE:FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=-1";
  } else if (cooldown === "P3650DT00H00M00S") {
    return "RRULE:FREQ=YEARLY;INTERVAL=10;BYYEARDAY=-1";
  } else if (cooldown === "P182DT00H00M00S") {
    return "RRULE:FREQ=MONTHLY;INTERVAL=6;BYMONTHDAY=-1";
  } else if (cooldown === "P7DT00H00M00S") {
    return "RRULE:FREQ=WEEKLY;BYDAY=SU";
  } else {
    return "RRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=SU";
  }
}

function due_date_from_cooldown(cooldown) {
  var recurrence = Prelude.default(Prelude.Result.warn(RRule.validate(recurrence_from_cooldown(cooldown))), RRule.Defaults.biweekly);
  return Prelude.default(RRule.$$Set.after(RRule.toSet(recurrence), new Date(), undefined), new Date());
}

function overdue_date_from_cooldown(cooldown) {
  var due_date = due_date_from_cooldown(cooldown);
  var recurrence = Prelude.default(Prelude.Result.warn(RRule.validate(recurrence_from_cooldown(cooldown))), RRule.Defaults.biweekly);
  return Prelude.default(RRule.$$Set.after(RRule.toSet(recurrence), due_date, undefined), new Date());
}

function message(param, param$1) {
  return "Installing new recurrence patterns";
}

function $$process(proc) {
  return {
          id: proc.id,
          component_id: proc.component_id,
          name: proc.name,
          order: proc.order,
          cooldown: proc.cooldown,
          position_diagram_id: proc.position_diagram_id,
          position_diagram_desc: proc.position_diagram_desc,
          example_image_id: proc.example_image_id,
          example_image_desc: proc.example_image_desc,
          description: proc.description,
          require_landscape_pictures: proc.require_landscape_pictures,
          question_type: proc.question_type,
          require_image_conditions: proc.require_image_conditions,
          recurrence: recurrence_from_cooldown(proc.cooldown),
          due_date: due_date_from_cooldown(proc.cooldown),
          overdue_date: overdue_date_from_cooldown(proc.cooldown)
        };
}

var ProcedureMigration = InitFeedback.MakeInfallibleTransformator({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing new recurrence patterns";
}

function migrate(param, transaction) {
  return ProcedureMigration.rewrite(transaction);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* ProcedureMigration Not a pure module */

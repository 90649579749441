// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function up(space) {
  switch (space.TAG) {
    case "Bytes" :
        return {
                TAG: "KiloB",
                _0: space._0 / 1000.0
              };
    case "KiloB" :
        return {
                TAG: "MegaB",
                _0: space._0 / 1000.0
              };
    case "MegaB" :
        return {
                TAG: "GigaB",
                _0: space._0 / 1000.0
              };
    case "GigaB" :
        return {
                TAG: "GigaB",
                _0: space._0
              };
    
  }
}

function down(space) {
  switch (space.TAG) {
    case "Bytes" :
        return {
                TAG: "Bytes",
                _0: space._0
              };
    case "KiloB" :
        return {
                TAG: "Bytes",
                _0: space._0 * 1000.0
              };
    case "MegaB" :
        return {
                TAG: "KiloB",
                _0: space._0 * 1000.0
              };
    case "GigaB" :
        return {
                TAG: "MegaB",
                _0: space._0 * 1000.0
              };
    
  }
}

var CannotExtract = /* @__PURE__ */Caml_exceptions.create("StorageUnits.CannotExtract");

function uppable(a) {
  if (a.TAG === "GigaB") {
    return false;
  } else {
    return true;
  }
}

function extract(b) {
  if (b.TAG === "Bytes") {
    return b._0;
  }
  throw {
        RE_EXN_ID: CannotExtract,
        _1: b,
        Error: new Error()
      };
}

function extractUnsafe(b) {
  return b._0;
}

function toBytes(a) {
  return down(down(down(a)));
}

function toBest(a) {
  var a$1 = toBytes(a);
  while(a$1._0 >= 1000.0 && uppable(a$1)) {
    a$1 = up(a$1);
  };
  return a$1;
}

function syncUp(a, b) {
  var a$1 = toBytes(a);
  var b$1 = toBytes(b);
  if (extract(a$1) <= extract(b$1)) {
    while(a$1._0 >= 1000.0 && uppable(a$1)) {
      a$1 = up(a$1);
      b$1 = up(b$1);
    };
  } else {
    while(b$1._0 >= 1000.0 && uppable(b$1)) {
      a$1 = up(a$1);
      b$1 = up(b$1);
    };
  }
  var match = a$1;
  var match$1 = b$1;
  switch (match.TAG) {
    case "Bytes" :
        if (match$1.TAG !== "Bytes") {
          throw {
                RE_EXN_ID: Prelude.AssertionError,
                _1: "Should be the same unit",
                Error: new Error()
              };
        }
        break;
    case "KiloB" :
        if (match$1.TAG !== "KiloB") {
          throw {
                RE_EXN_ID: Prelude.AssertionError,
                _1: "Should be the same unit",
                Error: new Error()
              };
        }
        break;
    case "MegaB" :
        if (match$1.TAG !== "MegaB") {
          throw {
                RE_EXN_ID: Prelude.AssertionError,
                _1: "Should be the same unit",
                Error: new Error()
              };
        }
        break;
    case "GigaB" :
        if (match$1.TAG !== "GigaB") {
          throw {
                RE_EXN_ID: Prelude.AssertionError,
                _1: "Should be the same unit",
                Error: new Error()
              };
        }
        break;
    
  }
  return [
          a$1,
          b$1
        ];
}

function toString(space) {
  switch (space.TAG) {
    case "Bytes" :
        return space._0.toFixed() + " bytes";
    case "KiloB" :
        return space._0.toFixed() + " kB";
    case "MegaB" :
        return space._0.toFixed() + " MB";
    case "GigaB" :
        return space._0.toFixed() + " GB";
    
  }
}

function fractionToString(a, b) {
  var match = syncUp(a, b);
  return match[0]._0.toFixed() + " / " + toString(match[1]);
}

function percent(a, b) {
  var a$1 = extract(toBytes(a));
  var b$1 = extract(toBytes(b));
  return a$1 / b$1 * 100.0;
}

function sub(a, b) {
  var a$1 = toBytes(a);
  var b$1 = toBytes(b);
  return toBest({
              TAG: "Bytes",
              _0: a$1._0 - b$1._0
            });
}

export {
  up ,
  down ,
  CannotExtract ,
  uppable ,
  extract ,
  extractUnsafe ,
  toBytes ,
  toBest ,
  syncUp ,
  toString ,
  fractionToString ,
  percent ,
  sub ,
}
/* No side effect */

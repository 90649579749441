// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Canvas from "./Canvas.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LocalFile from "./LocalFile.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_HashMap from "rescript/lib/es6/belt_HashMap.js";
import * as Browser from "@sentry/browser";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import BlueimpLoadImage from "blueimp-load-image";

var cmp = Caml_obj.compare;

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function hash(r) {
  switch (r) {
    case "FHD" :
        return 1920;
    case "HD" :
        return 1280;
    case "SD" :
        return 640;
    case "ORIGINAL" :
        return 0;
    
  }
}

var eq = Caml_obj.equal;

var Hash = Belt_Id.MakeHashableU({
      hash: hash,
      eq: eq
    });

function fromArray(param) {
  return Belt_HashMap.fromArray(param, Hash);
}

var HashMap = {
  fromArray: fromArray,
  get: Belt_HashMap.get
};

var intToResolutionIndex = Belt_HashMapInt.fromArray([
      [
        1920,
        "FHD"
      ],
      [
        1280,
        "HD"
      ],
      [
        640,
        "SD"
      ],
      [
        0,
        "ORIGINAL"
      ]
    ]);

var resolutionToIndex = Belt_HashMap.fromArray([
      [
        "FHD",
        1920
      ],
      [
        "HD",
        1280
      ],
      [
        "SD",
        640
      ],
      [
        "ORIGINAL",
        0
      ]
    ], Hash);

function fromJs(k) {
  return Belt_HashMapInt.get(intToResolutionIndex, k);
}

function toJs(r) {
  return Belt_HashMap.get(resolutionToIndex, r);
}

var deserFromJS = $$JSON.Field.variadicInt("Expected image resolution", fromJs);

var Resolution = {
  Comparator: Comparator,
  Hash: Hash,
  HashMap: HashMap,
  fromJs: fromJs,
  toJs: toJs,
  deserFromJS: deserFromJS
};

function apply(canvas) {
  try {
    var ctx = canvas.getContext("2d");
    var originalWidth = canvas.width;
    var width = originalWidth * 0.1;
    var width$1 = width < 50.0 ? 50.0 : width;
    var width$2 = width$1 > originalWidth ? originalWidth : width$1;
    var width$3 = width$2 > 500 ? 500.0 : width$2;
    var originalHeight = canvas.height;
    var height = originalHeight * 0.1;
    var height$1 = height < 50.0 ? 50.0 : height;
    var height$2 = height$1 > originalHeight ? originalHeight : height$1;
    var height$3 = height$2 > 500 ? 500.0 : height$2;
    var sx = (originalWidth - width$3) / 2.0;
    var sy = (originalHeight - height$3) / 2.0;
    var image = ctx.getImageData(sx, sy, width$3, height$3);
    var pixels = image.data;
    console.log("Image", "running black-center heuristic using " + String(pixels.length / 4 | 0) + " (" + String(width$3 | 0) + " x " + String(height$3 | 0) + ") pixels");
    return pixels.every(function (pixel, index) {
                if (index % 4 !== 3) {
                  return pixel === 0;
                } else {
                  return true;
                }
              });
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    Browser.captureException(error);
    return true;
  }
}

var BlacknessDectector = {
  apply: apply
};

var $$ImageBitmap = {};

async function compress(blob, resolution) {
  var bitmap = await createImageBitmap(blob);
  var originalWidth = bitmap.width;
  var originalHeight = bitmap.height;
  bitmap.close();
  var newHeight = {
    contents: Belt_HashMap.get(resolutionToIndex, resolution)
  };
  if (Math.imul(originalHeight, originalWidth) > 237300 && newHeight.contents < originalHeight) {
    var newWidth = {
      contents: Caml_int32.div(Math.imul(originalWidth, newHeight.contents), originalHeight)
    };
    if (newWidth.contents < 420) {
      newWidth.contents = 420;
      newHeight.contents = Caml_int32.div(Math.imul(originalHeight, newWidth.contents), originalWidth);
    }
    if (newWidth.contents < 420 || newHeight.contents < 420) {
      console.warn("Image", "refusing to compress with either too small width and height", {
            width: newWidth,
            height: newHeight,
            originalWidth: originalWidth,
            originalHeight: originalHeight
          });
      return blob;
    }
    console.log("Image", "compressing blob to " + String(newHeight.contents) + "p");
    var loaded = await BlueimpLoadImage(blob, {
          maxWidth: newWidth.contents,
          maxHeight: newHeight.contents,
          meta: true,
          orientation: true,
          canvas: true,
          contain: true
        });
    var canvas = loaded.image;
    if (apply(canvas)) {
      console.warn("Image", "detected blacked-out image after compression.  Using original.");
      return blob;
    } else {
      return await Canvas.Promises.toBlob(canvas, {
                  type: "image/jpeg",
                  quality: 0.85
                });
    }
  }
  console.warn("Image", "Refusing to compress with either too small width and height", {
        height: newHeight,
        originalWidth: originalWidth,
        originalHeight: originalHeight,
        "originalHeight * originalWidth": Math.imul(originalHeight, originalWidth),
        _MIN_SIZE: 237300
      });
  return blob;
}

var Impl = {
  BlacknessDectector: BlacknessDectector,
  $$ImageBitmap: $$ImageBitmap,
  compress: compress
};

async function compress$1(resolution, blob) {
  var compressed;
  try {
    compressed = await compress(blob, resolution);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Error while compressing image", e);
    return {
            TAG: "Error",
            _0: e
          };
  }
  if (compressed.size > blob.size) {
    return {
            TAG: "Ok",
            _0: blob
          };
  } else {
    return {
            TAG: "Ok",
            _0: compressed
          };
  }
}

async function compressLocalImage(key, resolution) {
  if (resolution === "ORIGINAL") {
    return await LocalFile.getResource(key);
  } else {
    return await LocalFile.compressWith(key, (function (extra) {
                  return compress$1(resolution, extra);
                }));
  }
}

var Compression = {
  Impl: Impl,
  compress: compress$1,
  compressLocalImage: compressLocalImage
};

export {
  Resolution ,
  Compression ,
}
/* Comparator Not a pure module */

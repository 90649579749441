// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as $$Comment from "../Comment.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as UpdatedComment from "../UpdatedComment.js";

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var author = vnode.attrs.author;
                    var readers = vnode.attrs.readers;
                    var match = State__Memory.Current.get();
                    var profile = match.profile;
                    var user = match.user;
                    var userNames = Prelude.$$Array.keepSome([
                          Curry._2(Prelude.OptionExported.$$Option.map, profile, (function (p) {
                                  return UserProfile.nameWithRank(p);
                                })),
                          Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                  return u.first_name + " " + u.last_name;
                                }))
                        ]);
                    var id = vnode.attrs.key;
                    var id$1 = id !== undefined ? id : $$Comment.Identifier.$$null;
                    if (userNames.length === 0) {
                      return ;
                    }
                    var currentReader = Prelude.$$Array.firstUnsafe(userNames);
                    if (readers !== undefined && !Prelude.$$Array.includes(readers, currentReader) && currentReader !== author) {
                      var newrecord = Caml_obj.obj_dup(Query.makeRead());
                      Prelude.thenDo(Query.read((newrecord.updatedComments = {
                                  TAG: "Get",
                                  _0: id$1
                                }, newrecord)), (function (param) {
                              if (Prelude.$$Array.isEmpty(param.updatedComments)) {
                                return Prelude.thenDo(UpdatedComment.make(id$1, currentReader), (function (comment) {
                                              var newrecord = Caml_obj.obj_dup(Query.makeWrite());
                                              Query.write((newrecord.updatedComments = [{
                                                        TAG: "Save",
                                                        _0: comment
                                                      }], newrecord));
                                            }));
                              }
                              
                            }));
                    }
                    Mithril.setState(vnode, {
                          TAG: "Loaded",
                          currentUser: user,
                          currentProfile: profile,
                          ownComment: Prelude.$$Array.includes(userNames, author)
                        });
                  })), (function (vnode) {
                var author = vnode.attrs.author;
                var body = vnode.attrs.body;
                var readers = vnode.attrs.readers;
                var date = Luxon.DateTime.fromJSDate(vnode.attrs.date).setZone("UTC").toLocaleString(Luxon.DateTime.DATETIME_FULL);
                var isNew = Prelude.default(vnode.attrs.isNew, false);
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var ownComment = match.ownComment;
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("span", {
                                                  children: author,
                                                  className: "text-bold mr-2",
                                                  style: {
                                                    color: ownComment ? "initial" : vnode.attrs.color
                                                  }
                                                }),
                                            ReactDOM.jsx("span", {
                                                  children: date,
                                                  className: "text-muted"
                                                }),
                                            !isNew && ownComment ? ReactDOM.jsx("div", {
                                                    children: readers !== undefined && !Prelude.$$Array.isEmpty(readers) ? React.jsx(Feather.make, {
                                                            icon: "2-checks",
                                                            size: 18
                                                          }) : React.jsx(Feather.make, {
                                                            icon: "check",
                                                            size: 18
                                                          }),
                                                    className: "p-absolute float-right text-primary",
                                                    style: {
                                                      bottom: "0",
                                                      right: "10px"
                                                    }
                                                  }) : Mithril.empty
                                          ],
                                          className: "tile-title text-tiny p-1"
                                        }),
                                    ReactDOM.jsx("pre", {
                                          children: body,
                                          className: "tile-subtitle comment-body p-1"
                                        })
                                  ],
                                  className: "tile-content p-relative"
                                }),
                            className: "tile px-0 py-1 m-0 comment " + (
                              ownComment ? "out-comment" : "in-comment"
                            )
                          });
              }));
}

var LoggedUser;

export {
  LoggedUser ,
  make ,
}
/* Query Not a pure module */

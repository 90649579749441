// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../tree/Level.js";
import * as Mutex from "../../libs/Mutex.js";
import * as Query from "../Query.js";
import * as UserId from "../accounts/UserId.js";
import * as Finding from "../tree/Finding.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as VesselId from "../vessel/VesselId.js";
import * as LevelType from "../tree/LevelType.js";
import * as Deficiency from "../tasks/Deficiency.js";
import * as UploadCounts from "../uploads/upload/UploadCounts.js";
import * as State__Memory from "./State__Memory.js";
import * as NotificationEntry from "../notifications/NotificationEntry.js";

async function action() {
  var st = State__Memory.Current.get();
  var vessel = st.vessel;
  var vesselId = vessel !== undefined ? vessel.id : VesselId.$$null;
  var userId = UserId.$$Option.map(st.user, (function (u) {
          return u.id;
        }));
  var counts = await UploadCounts.count(vesselId);
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.sire2VettingPeriods = {
          TAG: "Limit",
          _0: 1,
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        }, newrecord.notificationEntries = {
          TAG: "And",
          _0: NotificationEntry.ofVessel(vesselId),
          _1: NotificationEntry.ofUser(userId)
        }, newrecord.findings = {
          TAG: "And",
          _0: Finding.ofVessel(vesselId),
          _1: Finding.active
        }, newrecord.levelTypes = "All", newrecord.deficiencies = {
          TAG: "And",
          _0: Deficiency.ofVessel(vesselId),
          _1: Deficiency.active
        }, newrecord));
  var findings = match.findings;
  var levelTypes = match.levelTypes;
  var unread = Curry._2(Prelude.$$Array.keep, match.notificationEntries, (function (n) {
          return !n.read;
        }));
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.newAnswers = {
          TAG: "In",
          _0: findings.map(function (f) {
                return f.created_by_id;
              })
        }, newrecord$1.answers = {
          TAG: "In",
          _0: findings.map(function (f) {
                return f.created_by_id;
              })
        }, newrecord$1));
  var allAnswers = Curry._2(Prelude.$$Array.concat, match$1.answers, match$1.newAnswers);
  var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$2.levels = {
          TAG: "And",
          _0: {
            TAG: "In",
            _0: allAnswers.map(function (a) {
                  return a.level_id;
                })
          },
          _1: Level.ofKinds(levelTypes.map(function (l) {
                    return l.id;
                  }))
        }, newrecord$2));
  var levels = match$2.levels;
  var sireType = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levelTypes, (function (l) {
                  return Prelude.$$Array.includes(["sire_chapter"], l.kind);
                }))), LevelType.Defaults.$$null);
  var r4sType = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levelTypes, (function (l) {
                  return Prelude.$$Array.includes(["risk4sea_checklist_item"], l.kind);
                }))), LevelType.Defaults.$$null);
  var sire2VettingPeriodId = Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.sire2VettingPeriods), (function (v) {
          return v.id;
        }));
  var sireLevelIds = Curry._2(Prelude.$$Array.keep, levels, (function (l) {
            return Caml_obj.equal(l.kind, sireType.id);
          })).map(function (l) {
        return l.id;
      });
  var r4sLevelIds = Curry._2(Prelude.$$Array.keep, levels, (function (l) {
            return Caml_obj.equal(l.kind, r4sType.id);
          })).map(function (l) {
        return l.id;
      });
  var sireFindings = sire2VettingPeriodId !== undefined ? Curry._2(Prelude.$$Array.keep, allAnswers, (function (a) {
            if (Prelude.$$Array.includes(sireLevelIds, a.level_id)) {
              return Caml_obj.equal(a.sire2_vetting_period_id, sire2VettingPeriodId);
            } else {
              return false;
            }
          })) : [];
  var r4sFindings = Curry._2(Prelude.$$Array.keep, allAnswers, (function (a) {
          return Prelude.$$Array.includes(r4sLevelIds, a.level_id);
        }));
  var vessel$1 = st.vessel;
  return State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setUnreadNotifications(State__Memory.Transformations.setActiveFindings(State__Memory.Transformations.setPendingUploads(State__Memory.Transformations.make(State__Memory.Current.get()), (((((((counts.newCompanyFormSubmissions + counts.profiles | 0) + counts.futureAttachments | 0) + counts.procedureUpdates | 0) + counts.newInspections | 0) + counts.newComments | 0) + counts.newAnswers | 0) + (
                                counts.updatedComments > 0 ? 1 : 0
                              ) | 0) + counts.readNotificationEntries | 0), vessel$1 !== undefined ? (
                            vessel$1.migrated ? findings.length : (match.deficiencies.length + sireFindings.length | 0) + r4sFindings.length | 0
                          ) : 0), unread.length)));
}

var AmountsKeeper = Mutex.MakeMutexQueue({
      name: "State Refresh Amounts",
      action: action
    });

var refresh = AmountsKeeper.$$do;

export {
  refresh ,
}
/* AmountsKeeper Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message() {
  return "Installing new profiles";
}

async function migrate(db, _transaction) {
  db.deleteObjectStore("positions");
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */

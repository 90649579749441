// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

function toClassName(theme) {
  var textColor = Core__Option.getOr(theme.textColor, "tw-text-white");
  var backgroundColor = Core__Option.getOr(theme.backgroundColor, "tw-bg-primary");
  var borderColor = Core__Option.getOr(theme.borderColor, "tw-border-primary");
  return Mithril.classList([
              textColor,
              backgroundColor,
              borderColor
            ], undefined);
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var icon = vnode.attrs.icon;
                var disabled = vnode.attrs.disabled;
                var theme = Core__Option.getOr(vnode.attrs.theme, {});
                return ReactDOM.jsxs("button", {
                            children: [
                              Core__Option.mapOr(icon, Mithril.empty, (function (icon) {
                                      return React.jsx(Feather.make, {
                                                  icon: icon,
                                                  className: "tw-mr-1.5"
                                                });
                                    })),
                              ReactDOM.jsx("span", {
                                    children: match.text
                                  })
                            ],
                            className: Mithril.classList([
                                  toClassName(theme),
                                  "tw-inline-flex tw-items-center tw-justify-center tw-h-7 tw-py-1 tw-px-4",
                                  "tw-border tw-border-solid tw-rounded-2xl  tw-text-sm tw-select-none",
                                  "tw-cursor-pointer disabled:tw-opacity-60 disabled:tw-cursor-not-allowed",
                                  "tw-appearance-none tw-no-underline tw-outline-none ",
                                  "tw-whitespace-nowrap tw-transition tw-duration-200"
                                ], undefined),
                            disabled: disabled,
                            onClick: match.onClick
                          });
              }));
}

var Theme_on = {
  textColor: "tw-text-white",
  backgroundColor: "tw-bg-primary",
  borderColor: "tw-border-primary"
};

var Theme_off = {
  textColor: "tw-text-primary",
  backgroundColor: "tw-bg-filter-off",
  borderColor: "tw-border-filter-off"
};

var Theme = {
  on: Theme_on,
  off: Theme_off
};

export {
  Theme ,
  make ,
}
/* React Not a pure module */

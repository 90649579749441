// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "./Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as ProtectedView from "../../accounts/views/ProtectedView.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as KeepScrollLink from "./KeepScrollLink.js";
import * as SessionSidebar from "../../accounts/components/SessionSidebar.js";
import * as State__Amounts from "../../state/State__Amounts.js";
import * as UpdateAvailable from "./UpdateAvailable.js";
import * as ToastNotification from "../components/ToastNotification.js";
import * as ConnectionIndicator from "./ConnectionIndicator.js";
import FullLogoPng from "/images/full-logo.png";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var actions = vnode.attrs.actions;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("a", {
                                    children: React.jsx(Feather.make, {
                                          icon: "more_vertical"
                                        }),
                                    className: "btn btn-link dropdown-toggle",
                                    tabIndex: 0,
                                    href: "#"
                                  }),
                              ReactDOM.jsx("ul", {
                                    children: actions.map(function (action) {
                                          var fill = action.fill;
                                          var fn = action.effect;
                                          var tmp;
                                          tmp = fn.TAG === "Link" ? React.jsx(Mithril.Link.make, {
                                                  href: fn._0,
                                                  className: "text-primary btn btn-link flex-centered",
                                                  children: Caml_option.some(React.jsx(Feather.make, {
                                                            icon: action.icon,
                                                            fill: fill
                                                          }))
                                                }) : ReactDOM.jsx("a", {
                                                  children: React.jsx(Feather.make, {
                                                        icon: action.icon,
                                                        fill: fill
                                                      }),
                                                  className: "text-primary btn btn-link flex-centered",
                                                  onClick: fn._0
                                                });
                                          return ReactDOM.jsx("li", {
                                                      children: tmp,
                                                      className: "menu-item"
                                                    });
                                        }),
                                    className: "menu menu-right",
                                    style: {
                                      minWidth: "fit-content"
                                    }
                                  })
                            ],
                            className: "dropdown"
                          });
              }));
}

var Menu = {
  make: make
};

var Action = {
  Menu: Menu
};

function populate(vnode) {
  Prelude.thenDo(State__Amounts.refresh(), (function (persisted) {
          var state = vnode.state.data;
          var new_activeFindings = persisted.activeFindings;
          var new_pendingUploads = persisted.pendingUploads;
          var new_unreadNotifications = persisted.unreadNotifications;
          var $$new = {
            activeFindings: new_activeFindings,
            pendingUploads: new_pendingUploads,
            unreadNotifications: new_unreadNotifications
          };
          if (Caml_obj.notequal(state, $$new)) {
            return Mithril.setState(vnode, $$new);
          }
          
        }));
}

function navBarButton(props) {
  var count = props.badgeCount;
  return React.jsx(Mithril.Link.make, {
              href: props.href,
              className: Mithril.$$class([
                    [
                      "btn btn-action navbar-button",
                      true
                    ],
                    [
                      props.className,
                      true
                    ],
                    [
                      "btn-primary",
                      props.currentSection
                    ]
                  ], undefined),
              disabled: props.disabled,
              children: Caml_option.some(ReactDOM.jsxs("div", {
                        children: [
                          React.jsx(Feather.make, {
                                icon: props.icon
                              }),
                          count !== undefined && count > 0 ? ReactDOM.jsx("span", {
                                  className: "badge",
                                  data: {
                                    badge: String(count)
                                  }
                                }) : Mithril.empty
                        ],
                        className: "navbar-icon-container"
                      }))
            });
}

function make$1(param) {
  var st = State__Memory.Current.get();
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.oninit(Mithril.component({
                              activeFindings: st.activeFindings,
                              pendingUploads: st.pendingUploads,
                              unreadNotifications: st.unreadNotifications
                            }), populate), (function (_vnode) {
                        console.log("ListLayout/KeepScrollLink", "executing oncreate");
                        KeepScrollLink.restoreScroll(undefined);
                      })), populate), (function (vnode) {
                var showBackButton = Prelude.default(vnode.attrs.showBackButton, true);
                var showDrawer = Prelude.default(vnode.attrs.showDrawer, true);
                var backButtonSteps = Prelude.default(vnode.attrs.backButtonSteps, -1);
                var onBackClicked = Prelude.default(vnode.attrs.onBackClicked, (function () {
                        return Promise.resolve(true);
                      }));
                var useLogo = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, vnode.attrs.useLogo, false);
                var title = vnode.attrs.title;
                var hasHeader = useLogo || Curry._1(Prelude.OptionExported.$$Option.isSome, title);
                var section = Prelude.default(vnode.attrs.section, "survey");
                var bottomStatus = Prelude.default(vnode.attrs.bottomTaskBarStatus, "ShowEnabled");
                var overNavbarHeight = Prelude.default(vnode.attrs.overNavbarHeight, "0px");
                var hasContent = Curry._2(Prelude.$$Array.some, vnode.children, (function (prim) {
                        return Boolean(prim);
                      }));
                var viewTopContent = Prelude.default(vnode.attrs.viewTopContent, Mithril.empty);
                var match = vnode.attrs.overNavbar;
                var viewPortStyle;
                var exit = 0;
                if (match !== undefined) {
                  switch (bottomStatus) {
                    case "Hide" :
                        viewPortStyle = {
                          paddingTop: "0.5rem",
                          paddingBottom: overNavbarHeight !== "0px" ? overNavbarHeight : "0.8rem"
                        };
                        break;
                    case "ShowEnabled" :
                    case "ShowDisabled" :
                        exit = 1;
                        break;
                    
                  }
                } else {
                  switch (bottomStatus) {
                    case "Hide" :
                        viewPortStyle = {
                          paddingTop: "0.5rem",
                          paddingBottom: "0.8rem"
                        };
                        break;
                    case "ShowEnabled" :
                    case "ShowDisabled" :
                        exit = 1;
                        break;
                    
                  }
                }
                if (exit === 1) {
                  viewPortStyle = match !== undefined ? ({
                        paddingTop: "0.5rem",
                        paddingBottom: overNavbarHeight
                      }) : ({
                        paddingTop: "0.5rem"
                      });
                }
                var match$1 = vnode.attrs.overNavbar;
                var mainScrollingAreaContents = [
                  ReactDOM.jsxs("div", {
                        children: [
                          React.jsx(ToastNotification.make, {}),
                          viewTopContent,
                          ReactDOM.jsx("div", {
                                children: ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("div", {
                                            children: hasContent ? vnode.children : ReactDOM.jsx("div", {
                                                    children: ReactDOM.jsx("div", {
                                                          className: "loading loading-lg"
                                                        }),
                                                    className: "loader"
                                                  }),
                                            className: "column col-10 col-sm-12 col-mx-auto"
                                          }),
                                      className: "columns tw-h-full"
                                    }),
                                className: "container grid md tw-h-full",
                                style: viewPortStyle
                              })
                        ],
                        className: "tw-relative tw-flex tw-flex-col tw-grow"
                      }),
                  match$1 !== undefined ? ReactDOM.jsx("div", {
                          className: "divider blank xl"
                        }) : Mithril.empty
                ];
                var element = vnode.attrs.overNavbar;
                var mainScrollingArea = ReactDOM.jsx("div", {
                      children: ReactDOM.jsxs("div", {
                            children: [
                              mainScrollingAreaContents,
                              element !== undefined ? [
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("div", {
                                        children: Caml_option.valFromOption(element),
                                        className: "btn-group btn-group-block btn-group-floating"
                                      })
                                ] : Mithril.empty
                            ]
                          }),
                      id: "main-scroll-viewport",
                      style: {
                        overflow: "auto",
                        flexGrow: "1"
                      }
                    });
                var tmp;
                if (hasHeader) {
                  var header_title = title !== undefined ? ReactDOM.jsx("h4", {
                          children: title,
                          className: "tw-grow tw-mb-0 tw-truncate tw-px-2"
                        }) : ReactDOM.jsx("img", {
                          className: "company-logo kaiko",
                          src: FullLogoPng
                        });
                  var actions = vnode.attrs.actions;
                  tmp = ReactDOM.jsxs("header", {
                        children: [
                          ReactDOM.jsx("div", {
                                children: React.jsx(Conditional.make, {
                                      cond: showBackButton,
                                      children: React.jsx(KeepScrollLink.make, {
                                            className: "text-primary p-0 flex-centered",
                                            children: Caml_option.some(React.jsx(Feather.make, {
                                                      icon: "arrow_left"
                                                    })),
                                            style: {
                                              minWidth: "35px"
                                            },
                                            onClick: (function (e) {
                                                e.preventDefault();
                                                Prelude.thenDo(onBackClicked(), (function (goBack) {
                                                        if (goBack) {
                                                          KeepScrollLink.saveScroll();
                                                          window.history.go(backButtonSteps);
                                                          return ;
                                                        }
                                                        
                                                      }));
                                              })
                                          })
                                    }),
                                className: "back-button-container"
                              }),
                          header_title,
                          ReactDOM.jsx("div", {
                                children: React.jsx(Conditional.make, {
                                      cond: showDrawer,
                                      children: React.jsx(SessionSidebar.make, {})
                                    }),
                                className: "profile-pic-container"
                              }),
                          actions !== undefined ? ReactDOM.jsx("div", {
                                  children: React.jsx(make, {
                                        actions: actions
                                      }),
                                  className: "dropdown-menu-container"
                                }) : Mithril.empty
                        ],
                        className: "global-navbar t-navbar-header"
                      });
                } else {
                  tmp = ReactDOM.jsx("div", {});
                }
                var tmp$1;
                if (bottomStatus === "ShowEnabled" || bottomStatus === "ShowDisabled") {
                  var areaDisabled = bottomStatus === "ShowDisabled";
                  var bottom = vnode.attrs.bottom;
                  tmp$1 = bottom !== undefined ? Caml_option.valFromOption(bottom) : ReactDOM.jsx("footer", {
                          children: ReactDOM.jsxs("div", {
                                children: [
                                  [
                                    navBarButton({
                                          currentSection: section === "survey",
                                          href: "/home",
                                          className: "t-survey",
                                          icon: "ship",
                                          disabled: areaDisabled
                                        }),
                                    navBarButton({
                                          currentSection: section === "planner",
                                          href: "/inspection-planner",
                                          className: "t-planner",
                                          icon: "filter",
                                          disabled: areaDisabled
                                        }),
                                    navBarButton({
                                          currentSection: section === "deficiencies",
                                          href: "/deficiencies",
                                          className: "t-deficiencies",
                                          icon: "alert_triangle",
                                          disabled: areaDisabled,
                                          badgeCount: vnode.state.data.activeFindings
                                        }),
                                    navBarButton({
                                          currentSection: section === "notifications",
                                          href: "/notifications",
                                          className: "t-notifications",
                                          icon: "bell",
                                          disabled: areaDisabled,
                                          badgeCount: vnode.state.data.unreadNotifications
                                        }),
                                    navBarButton({
                                          currentSection: section === "send",
                                          href: "/upload",
                                          className: "t-send",
                                          icon: "send",
                                          disabled: areaDisabled,
                                          badgeCount: vnode.state.data.pendingUploads
                                        })
                                  ],
                                  React.jsx(UpdateAvailable.make, {})
                                ],
                                className: "btn-group btn-group-block"
                              }),
                          className: "global-navbar t-navbar-footer"
                        });
                } else {
                  tmp$1 = Mithril.empty;
                }
                return React.jsx(ProtectedView.make, {
                            children: [
                              tmp,
                              mainScrollingArea,
                              React.jsx(ConnectionIndicator.make, {}),
                              tmp$1
                            ]
                          });
              }));
}

export {
  Action ,
  populate ,
  navBarButton ,
  make$1 as make,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Luxon from "luxon";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var today = Luxon.DateTime.now().endOf("day");
                var status = vnode.attrs.status;
                var deadline = vnode.attrs.deadline;
                var color = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, deadline, (function (deadline) {
                            var match = Caml_obj.greaterequal(Luxon.DateTime.fromJSDate(deadline), today);
                            if (match) {
                              if (status === "rectified") {
                                return "gray";
                              } else {
                                return "error";
                              }
                            } else {
                              return "success";
                            }
                          })), "gray");
                var label = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, deadline, (function (deadline) {
                            return String(deadline.toISOString()).slice(0, 10);
                          })), "No deadline set");
                var className = Prelude.default(vnode.attrs.className, "");
                return ReactDOM.jsx("span", {
                            children: label,
                            className: className + " label label-" + color
                          });
              }));
}

export {
  make ,
}
/* luxon Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

var created_at = new Date("2024-01-01T01:23:45Z");

function message(param, param$1) {
  return "Optimizing images storage";
}

function $$process(future) {
  return {
          id: future.id,
          vessel_id: future.vessel_id,
          allChunks: future.allChunks,
          localChunks: future.localChunks,
          sentChunks: future.sentChunks,
          checksum: future.checksum,
          checksum_algorithm: future.checksum_algorithm,
          size: future.size,
          payload: future.payload,
          mimetype: future.mimetype,
          created_at: created_at
        };
}

var FutureAttachmentRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "futureAttachments",
      message: message,
      $$process: $$process
    });

function message$1(param) {
  return "Optimizing images storage";
}

async function migrate(param, transaction) {
  transaction.objectStore("futureAttachments").createIndex("created_at", "created_at");
  return await FutureAttachmentRewriter.rewrite(transaction);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* FutureAttachmentRewriter Not a pure module */

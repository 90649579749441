// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                return ReactDOM.jsxs("button", {
                            children: [
                              React.jsx(Feather.make, {
                                    icon: "x",
                                    className: "tw-mr-1",
                                    size: 15
                                  }),
                              match.text
                            ],
                            className: Mithril.classList([
                                  "tw-inline-flex tw-items-center tw-justify-center tw-h-6 tw-py-1 tw-px-2",
                                  "tw-rounded-2xl tw-border tw-border-solid tw-bg-primary tw-border-primary",
                                  "tw-text-white tw-text-sm tw-select-none tw-cursor-pointer",
                                  "tw-appearance-none tw-no-underline tw-outline-none ",
                                  "tw-whitespace-nowrap tw-transition tw-duration-200"
                                ], undefined),
                            onClick: match.onClick
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

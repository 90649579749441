// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

function toClassName(style) {
  if (style === "hidden") {
    return "tw-hidden";
  } else if (style === "error") {
    return "tw-text-white tw-bg-[#E85600] tw-border-[#E85600]";
  } else if (style === "primary") {
    return "tw-text-white tw-bg-primary tw-border-primary";
  } else if (style === "default") {
    return "tw-text-primary tw-bg-white tw-border-primary";
  } else {
    return "tw-text-white tw-bg-gray-400 tw-border-gray-400";
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var disabled = vnode.attrs.disabled;
                var style = Core__Option.getOr(vnode.attrs.style, "default");
                var customClass = vnode.attrs.className;
                return ReactDOM.jsx("button", {
                            children: vnode.children,
                            className: Mithril.classList([
                                  toClassName(style),
                                  "tw-inline-flex tw-gap-1 tw-items-center tw-justify-center disabled:tw-opacity-65",
                                  "tw-appearance-none tw-h-7 tw-py-1 tw-px-4 tw-cursor-pointer tw-w-full",
                                  "tw-border tw-border-solid tw-rounded-sm tw-inline-block tw-text-sm",
                                  "tw-text-center tw-align-middle tw-no-underline tw-outline-none tw-select-none",
                                  "tw-whitespace-nowrap tw-transition tw-duration-200"
                                ], customClass),
                            disabled: disabled,
                            onClick: match.onClick
                          });
              }));
}

var ButtonStyle = {};

export {
  ButtonStyle ,
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../Settings.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FutureAttachment from "./FutureAttachment.js";

async function $$delete(ids) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.futureAttachments = {
          TAG: "In",
          _0: ids
        }, newrecord));
  var files = LocalFile.UUID.manyFromString(Curry._2(Prelude.$$Array.concat, Prelude.$$Array.flatMap(match.futureAttachments, (function (f) {
                    return f.allChunks;
                  })).map(function (param) {
                return param.id;
              }), ids));
  await LocalFile.deleteMany(files);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord$1.futureAttachments = ids.map(function (id) {
              return {
                      TAG: "Delete",
                      _0: id
                    };
            }), newrecord$1));
}

async function rebuild(future, blob) {
  var size = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(future.allChunks), (function (c) {
              return c.size | 0;
            })), Settings.Upload.maxBlobChunkSizeInBytes);
  var newchunks = await FutureAttachment._splitBlob(blob, size);
  if (newchunks.length === future.allChunks.length) {
    var pairs = Curry._2(Prelude.$$Array.zip, future.allChunks, newchunks);
    var recoverable = Curry._3(Prelude.$$Array.fold, pairs.map(function (param) {
              var $$new = param[1];
              var prev = param[0];
              if (prev.size === $$new.size && Caml_obj.equal(prev.checksum, $$new.checksum) && Caml_obj.equal(prev.sequence, $$new.sequence)) {
                return Caml_obj.equal(prev.checksum_algorithm, $$new.checksum_algorithm);
              } else {
                return false;
              }
            }), true, (function (a, b) {
            if (a) {
              return b;
            } else {
              return false;
            }
          }));
    if (recoverable) {
      var results = await Prelude.Promises.catchResolve(Prelude.PromisedResult.bail(pairs.map(function (param) {
                    var $$new = param[1];
                    var prev = param[0];
                    return async function () {
                      var previous = await LocalFile.getWithMetadata(prev.id);
                      var recovered = await LocalFile.getWithMetadata($$new.id);
                      if (previous[0] !== undefined && previous[1] !== undefined) {
                        return {
                                TAG: "Ok",
                                _0: undefined
                              };
                      }
                      var metadata = recovered[0];
                      if (metadata !== undefined) {
                        var resource = recovered[1];
                        if (resource !== undefined) {
                          await LocalFile.store(Caml_option.some(prev.id), metadata.file, metadata.compressed, resource.file);
                          return {
                                  TAG: "Ok",
                                  _0: undefined
                                };
                        } else {
                          return {
                                  TAG: "Error",
                                  _0: undefined
                                };
                        }
                      }
                      var resource$1 = recovered[1];
                      if (resource$1 !== undefined) {
                        await LocalFile.store(Caml_option.some(prev.id), undefined, true, resource$1.file);
                        return {
                                TAG: "Ok",
                                _0: undefined
                              };
                      } else {
                        return {
                                TAG: "Error",
                                _0: undefined
                              };
                      }
                    };
                  })), (function (param) {
              return {
                      TAG: "Error",
                      _0: undefined
                    };
            }));
      await LocalFile.deleteMany(newchunks.map(function (c) {
                return c.id;
              }));
      if (results.TAG === "Ok") {
        console.log("FutureAttachmentManager", "Hooray! We recovered a missing file", future.id);
        return {
                TAG: "Ok",
                _0: future
              };
      }
      console.warn("FutureAttachmentManager", "Recover of future imposible.  We're really close, though!", future.id);
      return {
              TAG: "Error",
              _0: future
            };
    }
    console.warn("FutureAttachmentManager", "Recover of future imposible", future.id);
    await LocalFile.deleteMany(newchunks.map(function (c) {
              return c.id;
            }));
    return {
            TAG: "Error",
            _0: future
          };
  }
  console.warn("FutureAttachmentManager", "Recover of future imposible", future.id);
  await LocalFile.deleteMany(newchunks.map(function (c) {
            return c.id;
          }));
  return {
          TAG: "Error",
          _0: future
        };
}

export {
  $$delete ,
  rebuild ,
}
/* Query Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE


function make(param) {
  return function (db, param) {
    db.deleteObjectStore("notApplicableProcedures");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Adding state to the userProfiles and newUserProfiles";
}

function $$process(profile) {
  return {
          id: profile.id,
          first_name: profile.first_name,
          last_name: profile.last_name,
          rank: profile.rank,
          vessel: profile.vessel,
          avatars: profile.avatars,
          user: profile.user,
          state: "active"
        };
}

var UserProfileRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "userProfiles",
      message: message,
      $$process: $$process
    });

var NewUserProfileRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "newUserProfiles",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Add state to the userProfiles and newUserProfiles.";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.bind(UserProfileRewriter.rewrite(transaction), (function () {
                return NewUserProfileRewriter.rewrite(transaction);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* UserProfileRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../tree/Level.js";
import * as Query from "../Query.js";
import * as Answer from "../tree/Answer.js";
import * as Vessel from "../vessel/Vessel.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../tree/Question.js";
import * as QuestionId from "../tree/QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LevelStatus from "../tree/LevelStatus.js";
import * as UserProfile from "../accounts/UserProfile.js";
import * as State__Memory from "../state/State__Memory.js";
import * as LevelRiskStatus from "../tree/LevelRiskStatus.js";
import * as Sire2VettingPeriodId from "./Sire2VettingPeriodId.js";

async function load(vesselId, levelId) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.levels = {
          TAG: "Get",
          _0: levelId
        }, newrecord.vessels = {
          TAG: "Get",
          _0: vesselId
        }, newrecord));
  var level = Prelude.default(Prelude.$$Array.first(match.levels), Level.Defaults.$$null);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.sire2VettingPeriods = {
          TAG: "Limit",
          _0: 1,
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        }, newrecord$1.questions = Question.insideLevel(level.path), newrecord$1.levels = Level.childrenOf(levelId), newrecord$1.newUserProfiles = UserProfile.ofVessel(vesselId), newrecord$1.userProfiles = UserProfile.ofVessel(vesselId), newrecord$1));
  var sire2VettingPeriods = match$1.sire2VettingPeriods;
  var questions = match$1.questions;
  var sire2VettingPeriodId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(sire2VettingPeriods), (function (v) {
              return v.id;
            })), Sire2VettingPeriodId.$$null);
  var sire2QuestionSet = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(sire2VettingPeriods), (function (v) {
              return v.question_set;
            })), "all");
  var riskStatuses = sire2QuestionSet === "all" ? [
      "high",
      "medium",
      "low"
    ] : (
      sire2QuestionSet === "rotational1" ? [
          "high",
          "medium"
        ] : (
          sire2QuestionSet === "rotational2" ? [
              "high",
              "low"
            ] : ["high"]
        )
    );
  var riskStatusesToJs = riskStatuses.map(LevelRiskStatus.tToJs);
  var match$2 = State__Memory.Current.get();
  var isTSI = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match$2.profile, (function (p) {
              return p.rank === "TSI";
            })), false);
  var levelIds = Curry._1(Prelude.$$Array.concatMany, questions.map(function (q) {
            return q.level_ids;
          }));
  var tmp;
  if (isTSI) {
    var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
    tmp = Query.read((newrecord$2.levels = {
            TAG: "In",
            _0: levelIds
          }, newrecord$2));
  } else {
    var newrecord$3 = Caml_obj.obj_dup(Query.makeRead());
    tmp = Query.read((newrecord$3.levels = {
            TAG: "And",
            _0: {
              TAG: "AnyOf",
              _0: "risk_status",
              _1: riskStatusesToJs
            },
            _1: {
              TAG: "In",
              _0: levelIds
            }
          }, newrecord$3));
  }
  var match$3 = await tmp;
  var levelsPaths = match$3.levels.map(function (v) {
        return v.path;
      });
  var questions$1 = Curry._2(Prelude.$$Array.keep, questions, (function (q) {
          return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, q.level_paths, (function (v) {
                            return Prelude.$$Array.includes(levelsPaths, v);
                          })));
        }));
  var questionsIds = Question.ids(questions$1);
  var newrecord$4 = Caml_obj.obj_dup(Query.makeRead());
  var match$4 = await Query.read((newrecord$4.newAnswers = {
          TAG: "And",
          _0: Answer.ofQuestions(questionsIds),
          _1: {
            TAG: "Is",
            _0: "sire2_vetting_period_id",
            _1: sire2VettingPeriodId
          }
        }, newrecord$4.answers = {
          TAG: "And",
          _0: Answer.ofQuestions(questionsIds),
          _1: {
            TAG: "Is",
            _0: "sire2_vetting_period_id",
            _1: sire2VettingPeriodId
          }
        }, newrecord$4));
  return LevelStatus.LevelLoadedData.make(questions$1, match$4.answers, match$4.newAnswers, Curry._2(Prelude.$$Array.concat, match$1.userProfiles, match$1.newUserProfiles), level, match$1.levels, Prelude.default(Prelude.$$Array.first(match.vessels), Vessel.Defaults.$$null));
}

function hasChildren(vesselId, levelId) {
  return Prelude.thenResolve(load(vesselId, levelId), (function (levelData) {
                return [
                        levelData.level,
                        Prelude.$$Array.isNotEmpty(levelData.children)
                      ];
              }));
}

function getQuestionStatusInsideLevel(levelData, filters) {
  var filters$1 = Prelude.default(filters, LevelStatus.StateFilters.t_default);
  var profiles = levelData.profiles;
  var getLastOfMap = function (question, getter, profiles, currentProfile) {
    return Prelude.$$Array.last(filters$1.answerFilter(Curry._2(Prelude.$$Array.keep, Prelude.$$Array.sort(Prelude.default(QuestionId.$$Map.get(getter, question.id), []), (function (a) {
                              return a.timestamp;
                            }), undefined), (function (a) {
                          return Answer.questionVisibilityGetter(question)(currentProfile, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, profiles, (function (p) {
                                                return Caml_obj.equal(Caml_option.some(p.id), a.profile_id);
                                              }))));
                        }))));
  };
  var questions = filters$1.questionFilter(levelData.questions);
  var currentProfile = State__Memory.Current.get().profile;
  var answersByQuestion = QuestionId.$$Array.group(levelData.answers, (function (i) {
          return i.question_id;
        }));
  var newAnswersByQuestion = QuestionId.$$Array.group(levelData.newAnswers, (function (i) {
          return i.question_id;
        }));
  var allQuestionStatus = questions.map(function (q) {
        return LevelStatus.QuestionStatus.make(q, getLastOfMap(q, answersByQuestion, profiles, currentProfile), getLastOfMap(q, newAnswersByQuestion, profiles, currentProfile));
      });
  return filters$1.questionStatusFilter(allQuestionStatus);
}

function getLevelCompletionStatus(vesselId, levelId, filters) {
  return Prelude.thenResolve(load(vesselId, levelId), (function (levelData) {
                var filters$1 = Prelude.default(filters, LevelStatus.StateFilters.t_default);
                var questions = getQuestionStatusInsideLevel(levelData, filters$1);
                var level = levelData.level;
                var questions$1 = Curry._2(Prelude.$$Array.keep, questions, (function (qs) {
                        return Curry._2(Prelude.$$Array.some, qs.question.level_paths, (function (p) {
                                      if (level.path === p) {
                                        return true;
                                      } else {
                                        return p.startsWith(level.path);
                                      }
                                    }));
                      }));
                var questionsCount = questions$1.length;
                var answersCount = Curry._2(Prelude.$$Array.keep, questions$1, (function (qs) {
                        if (Curry._1(Prelude.OptionExported.$$Option.isSome, qs.lastAnswer)) {
                          return true;
                        } else {
                          return Curry._1(Prelude.OptionExported.$$Option.isSome, qs.lastNewAnswer);
                        }
                      })).length;
                return LevelStatus.CompletionStatus.make(questionsCount, answersCount, level);
              }));
}

async function getState(vesselId, levelId, filters, direct) {
  var filters$1 = Prelude.default(filters, LevelStatus.StateFilters.t_default);
  var levelData = await load(vesselId, levelId);
  var questions = getQuestionStatusInsideLevel(levelData, filters$1);
  var level = levelData.level;
  var match = Curry._3(Prelude.$$Array.fold, Curry._1(Prelude.$$Array.concatMany, questions.map(function (q) {
                return Curry._2(Prelude.$$Array.keep, q.question.level_paths, (function (i) {
                              return i.startsWith(level.path);
                            }));
              })), [
        [],
        levelData.children
      ], (function (param, path) {
          var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                  if (extra.path === path) {
                    return true;
                  } else {
                    return path.startsWith(extra.path);
                  }
                }));
          var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
          return [
                  branches,
                  match[1]
                ];
        }));
  var children = Level.NaturalSort.sorted(filters$1.childrenFilter(match[0]));
  var questions$1 = Caml_obj.equal(direct, true) ? Curry._2(Prelude.$$Array.keep, questions, (function (q) {
            return Curry._1(Prelude.OptionExported.$$Option.isSome, q.question.level_ids.find(function (l) {
                            return Caml_obj.equal(l, levelId);
                          }));
          })) : questions;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.newAnswers = Answer.ofVessel(vesselId), newrecord));
  return LevelStatus.LevelFilteredState.make(questions$1, levelData.vessel, level, children, match$1.newAnswers);
}

var SireLevelState = {
  load: load,
  hasChildren: hasChildren,
  getQuestionStatusInsideLevel: getQuestionStatusInsideLevel,
  getLevelCompletionStatus: getLevelCompletionStatus,
  getState: getState
};

function getSire2StateFilters(profile, areas, risks, answered, ranks) {
  var questionFilter = function (questions) {
    return Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                  if (Question.filterByQuestionArea(q, areas) && Question.filterByQuestionRank(q, profile)) {
                    return Question.isVisibleForRanks(q, ranks);
                  } else {
                    return false;
                  }
                }));
  };
  var questionStatusFilter = function (questions) {
    var answeredQuestionIds = function (q) {
      if (Curry._1(Prelude.OptionExported.$$Option.isSome, q.lastAnswer) || Curry._1(Prelude.OptionExported.$$Option.isSome, q.lastNewAnswer)) {
        return [q.question.id];
      } else {
        return [];
      }
    };
    return Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                  return Question.filterByAnswered(q.question, answered, answeredQuestionIds(q));
                }));
  };
  var childrenFilter = function (children) {
    return Curry._2(Prelude.$$Array.keep, children, (function (l) {
                  return LevelStatus.filterByRiskStatus(l, risks);
                }));
  };
  return LevelStatus.StateFilters.make(undefined, questionFilter, questionStatusFilter, childrenFilter);
}

export {
  SireLevelState ,
  getSire2StateFilters ,
}
/* Level Not a pure module */

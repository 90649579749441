// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../common/Forms.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../ProcedureId.js";
import * as State__Inspections from "../../state/State__Inspections.js";

function createInspection(vnode, _event) {
  var draftSubmissionId = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("draftSubmissionId"), (function (i) {
          return i;
        }));
  var match = vnode.state.data;
  if (typeof match !== "object") {
    window.history.back();
    return ;
  }
  var procedure = match.procedure;
  var value = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name='" + procedure.id + "']"));
  if (value !== undefined) {
    return Prelude.thenDo(State__Inspections.make(procedure, undefined, undefined, {
                    NAME: "Date",
                    VAL: new Date(value)
                  }, undefined, undefined, undefined, undefined, Caml_option.some(draftSubmissionId), undefined), (function (param) {
                  window.history.back();
                }));
  }
  
}

function setReady(vnode, $$event) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var target = $$event.target;
  var value;
  var exit = 0;
  var v;
  try {
    v = target.value;
    exit = 1;
  }
  catch (exn){
    value = undefined;
  }
  if (exit === 1) {
    if (v !== undefined) {
      var v$1 = Caml_option.valFromOption(v);
      value = v$1 !== "" ? Caml_option.some(v$1) : undefined;
    } else {
      value = undefined;
    }
  }
  var parsedDate = Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
          var result = new Date(v);
          if (Number.isNaN(result.getDate())) {
            return ;
          } else {
            return Caml_option.some(result);
          }
        }));
  Mithril.setState(vnode, {
        TAG: "Loaded",
        procedure: match.procedure,
        date: match.date,
        ready: Curry._1(Prelude.OptionExported.$$Option.isSome, parsedDate)
      });
}

function make() {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                            var procedureId = ProcedureId.Route.param("procedureId");
                            var newrecord = Caml_obj.obj_dup(Query.makeRead());
                            Prelude.thenDo(Query.read((newrecord.procedures = {
                                        TAG: "Get",
                                        _0: procedureId
                                      }, newrecord)), (function (param) {
                                    var procedure = Prelude.$$Array.first(param.procedures);
                                    if (procedure !== undefined) {
                                      return Mithril.setState(vnode, {
                                                  TAG: "Loaded",
                                                  procedure: procedure,
                                                  date: "",
                                                  ready: false
                                                });
                                    } else {
                                      window.history.back();
                                      return ;
                                    }
                                  }));
                          })), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var procedure = match.procedure;
                var overNavbar = ReactDOM.jsx("button", {
                      children: "Save",
                      className: Mithril.$$class([
                            [
                              "btn btn-lg mt-auto btn-primary",
                              true
                            ],
                            [
                              "disabled",
                              match.ready === false
                            ]
                          ], undefined),
                      onClick: (function (extra) {
                          return createInspection(vnode, extra);
                        })
                    });
                return React.jsx(ListLayout.make, {
                            children: ReactDOM.jsx("div", {
                                  children: ReactDOM.jsxs("div", {
                                        children: [
                                          ReactDOM.jsx("div", {
                                                children: ReactDOM.jsx("label", {
                                                      children: procedure.name,
                                                      className: "form-label",
                                                      htmlFor: "question-input-date"
                                                    }),
                                                className: "col-3"
                                              }),
                                          ReactDOM.jsx("div", {
                                                children: ReactDOM.jsx("input", {
                                                      className: "form-input",
                                                      id: "question-input-date",
                                                      name: procedure.id,
                                                      type: "date",
                                                      onChange: (function (extra) {
                                                          return setReady(vnode, extra);
                                                        })
                                                    }),
                                                className: "col-9"
                                              })
                                        ],
                                        className: "form-group"
                                      }),
                                  className: "form-horizontal"
                                }),
                            title: "Enter Date",
                            showBackButton: true,
                            overNavbar: Caml_option.some(overNavbar),
                            overNavbarHeight: "2.9rem"
                          });
              }));
}

export {
  createInspection ,
  setReady ,
  make ,
}
/* Forms Not a pure module */

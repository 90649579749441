// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UploadActionable from "../UploadActionable.js";

async function next(payloads) {
  var actionables = await UploadActionable.make(payloads);
  return {
          TAG: "Uploading",
          started: new Date(),
          uploaded: 0.0,
          size: Curry._3(Prelude.$$Array.fold, actionables.map(UploadActionable.size), 0.0, (function (r, s) {
                  return r + s;
                })),
          actionables: actionables,
          results: []
        };
}

export {
  next ,
}
/* UploadActionable Not a pure module */

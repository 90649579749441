// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MixPanel from "../../../libs/MixPanel.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Component from "../../vessel/Component.js";
import * as Procedure from "../Procedure.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ComponentId from "../../vessel/ComponentId.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as PlannedProcedure from "../PlannedProcedure.js";
import * as StandardSelector from "../../common/views/StandardSelector.js";

var $$null = {
  id: "",
  name: "Any"
};

function fromRank(rank) {
  return {
          id: rank,
          name: UserProfile.Rank.toString(rank),
          rank: rank
        };
}

var Item = {
  $$null: $$null,
  fromRank: fromRank
};

function select(vnode, item) {
  var onChange = vnode.attrs.onChange;
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  onChange(item.rank);
  Mithril.setState(vnode, {
        TAG: "Loaded",
        rank: item.rank,
        options: match.options
      });
}

function loadState(vnode) {
  var options = Curry._2(Prelude.$$Array.concat, [$$null], UserProfile.ranks.map(fromRank));
  Mithril.setState(vnode, {
        TAG: "Loaded",
        rank: undefined,
        options: options
      });
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadState), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var options = match.options;
                if (options.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("label", {
                                      children: "Rank",
                                      className: "text-left text-muted",
                                      htmlFor: "rank-selector"
                                    }),
                                React.jsx(StandardSelector.make, {
                                      id: "rank-selector",
                                      items: options,
                                      disabled: options.length < 2,
                                      selected: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.rank, (function (rank) {
                                                  return rank;
                                                })), ""),
                                      allowEmpty: false,
                                      onSelect: (function (position) {
                                          select(vnode, position);
                                        })
                                    })
                              ],
                              className: "form-group"
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

var VesselPositionRankFilter = {
  Item: Item,
  select: select,
  loadState: loadState,
  make: make
};

var $$null$1 = {
  id: "",
  name: "All",
  componentType: undefined
};

function fromComponentType(componentType) {
  return {
          id: componentType.toLowerCase(),
          name: Component.describeComponentType(componentType),
          componentType: componentType
        };
}

var Item$1 = {
  $$null: $$null$1,
  fromComponentType: fromComponentType
};

function loadState$1(vnode) {
  var componentType = vnode.attrs.componentType;
  var options = [
    $$null$1,
    fromComponentType("structural"),
    fromComponentType("safety"),
    fromComponentType("checklist")
  ];
  Mithril.setState(vnode, {
        TAG: "Loaded",
        componentType: componentType,
        options: options
      });
}

function select$1(vnode, item) {
  var onChange = Prelude.default(vnode.attrs.onChange, (function (x) {
          console.log("Selected component type", x);
        }));
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  onChange(item.componentType);
  Mithril.setState(vnode, {
        TAG: "Loaded",
        componentType: item.componentType,
        options: match.options
      });
}

function make$1(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadState$1), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var options = match.options;
                if (options.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("label", {
                                      children: "Type",
                                      className: "text-left text-muted",
                                      htmlFor: "type-selector"
                                    }),
                                React.jsx(StandardSelector.make, {
                                      id: "type-selector",
                                      items: options,
                                      disabled: options.length < 2,
                                      selected: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.componentType, (function (componentType) {
                                                  return componentType;
                                                })), ""),
                                      allowEmpty: false,
                                      onSelect: (function (component_type) {
                                          select$1(vnode, component_type);
                                        })
                                    })
                              ]
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

var ComponentTypeFilter = {
  Item: Item$1,
  loadState: loadState$1,
  select: select$1,
  make: make$1
};

var $$null$2 = {
  id: "",
  name: "All",
  status: undefined
};

function completionName(completion) {
  if (completion === "dueThisMonth") {
    return "This Month";
  } else if (completion === "overdue") {
    return "Overdue";
  } else {
    return "This Week";
  }
}

function fromCompletionStatus(completion) {
  return {
          id: completion,
          name: completionName(completion),
          status: completion
        };
}

var Item$2 = {
  $$null: $$null$2,
  completionName: completionName,
  fromCompletionStatus: fromCompletionStatus
};

function loadState$2(vnode) {
  var status = vnode.attrs.status;
  var options = [
    $$null$2,
    fromCompletionStatus("overdue"),
    fromCompletionStatus("dueThisWeek"),
    fromCompletionStatus("dueThisMonth")
  ];
  Mithril.setState(vnode, {
        TAG: "Loaded",
        status: status,
        options: options
      });
}

function select$2(vnode, item) {
  var onChange = Prelude.default(vnode.attrs.onChange, (function (x) {
          console.log("Selected due date", x);
        }));
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  onChange(item.status);
  Mithril.setState(vnode, {
        TAG: "Loaded",
        status: item.status,
        options: match.options
      });
}

function make$2(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), loadState$2), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var options = match.options;
                if (options.length !== 0) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("label", {
                                      children: "Due",
                                      className: "text-left text-muted",
                                      htmlFor: "due-selector"
                                    }),
                                React.jsx(StandardSelector.make, {
                                      id: "due-selector",
                                      items: options,
                                      disabled: options.length < 2,
                                      selected: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.status, (function (status) {
                                                  return status;
                                                })), ""),
                                      allowEmpty: false,
                                      onSelect: (function (completion_status) {
                                          select$2(vnode, completion_status);
                                        })
                                    })
                              ]
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

var CompletionStatusFilter = {
  Item: Item$2,
  loadState: loadState$2,
  select: select$2,
  make: make$2
};

function _updateState(vnode) {
  var match = State__Memory.Current.get();
  var vesselId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.vessel, (function (v) {
              return v.id;
            })), "");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.procedures = Procedure.ofVessel(vesselId), newrecord.components = Component.ofVessel(vesselId), newrecord)), (function (param) {
                var procedures = param.procedures;
                var components = param.components;
                var match = vnode.state.data;
                var rankFilter;
                rankFilter = typeof match !== "object" ? undefined : match.rankFilter;
                var match$1 = vnode.state.data;
                var componentTypeFilter;
                componentTypeFilter = typeof match$1 !== "object" ? undefined : match$1.componentTypeFilter;
                var match$2 = vnode.state.data;
                var completionStatusFilter;
                completionStatusFilter = typeof match$2 !== "object" ? undefined : match$2.completionStatusFilter;
                var match$3 = vnode.state.data;
                var search;
                search = typeof match$3 !== "object" ? "" : match$3.search;
                var structuralComponentIds = ComponentId.$$Set.fromArray(Curry._2(Prelude.$$Array.keep, components, (function (c) {
                              return c.type === "structural";
                            })).map(function (c) {
                          return c.id;
                        }));
                var structuralProcedures = Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
                        return ComponentId.$$Set.has(structuralComponentIds, p.component_id);
                      }));
                var safetyComponentIds = ComponentId.$$Set.fromArray(Curry._2(Prelude.$$Array.keep, components, (function (c) {
                              return c.type === "safety";
                            })).map(function (c) {
                          return c.id;
                        }));
                var safetyProcedures = Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
                        return ComponentId.$$Set.has(safetyComponentIds, p.component_id);
                      }));
                var checklistComponentIds = ComponentId.$$Set.fromArray(Curry._2(Prelude.$$Array.keep, components, (function (c) {
                              return c.type === "checklist";
                            })).map(function (c) {
                          return c.id;
                        }));
                var checklistProcedures = Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
                        return ComponentId.$$Set.has(checklistComponentIds, p.component_id);
                      }));
                var newState = {
                  TAG: "Loaded",
                  components: components,
                  procedures: procedures,
                  structuralProcedures: structuralProcedures,
                  safetyProcedures: safetyProcedures,
                  checklistProcedures: checklistProcedures,
                  rankFilter: rankFilter,
                  componentTypeFilter: componentTypeFilter,
                  completionStatusFilter: completionStatusFilter,
                  search: search
                };
                if (Caml_obj.notequal(newState, vnode.state.data)) {
                  return Mithril.setState(vnode, newState);
                }
                
              }));
}

function loadState$3(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  _updateState(vnode);
}

function updateState(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  _updateState(vnode);
}

function filterByRank(vnode, rank) {
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  }
  vnode.state.data = {
    TAG: "Loaded",
    components: state.components,
    procedures: state.procedures,
    structuralProcedures: state.structuralProcedures,
    safetyProcedures: state.safetyProcedures,
    checklistProcedures: state.checklistProcedures,
    rankFilter: rank,
    componentTypeFilter: state.componentTypeFilter,
    completionStatusFilter: state.completionStatusFilter,
    search: state.search
  };
}

function filterByComponentType(vnode, componentType) {
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  }
  vnode.state.data = {
    TAG: "Loaded",
    components: state.components,
    procedures: state.procedures,
    structuralProcedures: state.structuralProcedures,
    safetyProcedures: state.safetyProcedures,
    checklistProcedures: state.checklistProcedures,
    rankFilter: state.rankFilter,
    componentTypeFilter: componentType,
    completionStatusFilter: state.completionStatusFilter,
    search: state.search
  };
}

function filterByCompletionStatus(vnode, completionStatus) {
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  }
  vnode.state.data = {
    TAG: "Loaded",
    components: state.components,
    procedures: state.procedures,
    structuralProcedures: state.structuralProcedures,
    safetyProcedures: state.safetyProcedures,
    checklistProcedures: state.checklistProcedures,
    rankFilter: state.rankFilter,
    componentTypeFilter: state.componentTypeFilter,
    completionStatusFilter: completionStatus,
    search: state.search
  };
}

function filterByText(vnode, param) {
  var search = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=search]")), "");
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  }
  vnode.state.data = {
    TAG: "Loaded",
    components: state.components,
    procedures: state.procedures,
    structuralProcedures: state.structuralProcedures,
    safetyProcedures: state.safetyProcedures,
    checklistProcedures: state.checklistProcedures,
    rankFilter: state.rankFilter,
    componentTypeFilter: state.componentTypeFilter,
    completionStatusFilter: state.completionStatusFilter,
    search: search
  };
}

function url(param, componentType) {
  var match = State__Memory.Current.get();
  var vesselId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.vessel, (function (v) {
              return v.id;
            })), "");
  if (componentType !== undefined) {
    return URLs.vesselSections(vesselId, {
                NAME: "ComponentType",
                VAL: componentType
              }, true, undefined);
  } else {
    return URLs.vesselPlannedSections(vesselId);
  }
}

function searchFilterElement(vnode) {
  return ReactDOM.jsxs("div", {
              children: [
                React.jsx(Feather.make, {
                      icon: "search",
                      className: "form-icon"
                    }),
                ReactDOM.jsx("input", {
                      className: "form-input",
                      autoComplete: "off",
                      name: "search",
                      placeholder: "Search",
                      onInput: (function (extra) {
                          return filterByText(vnode, extra);
                        })
                    })
              ],
              className: "form-group has-icon-right py-2"
            });
}

function filters(vnode, componentTypeFilter, completionStatusFilter, rankFilter) {
  return ReactDOM.jsxs("div", {
              children: [
                ReactDOM.jsx("div", {
                      children: ReactDOM.jsx("div", {
                            children: React.jsx(make, {
                                  rank: rankFilter,
                                  onChange: (function (extra) {
                                      return filterByRank(vnode, extra);
                                    })
                                }),
                            className: "column col"
                          }),
                      className: "columns"
                    }),
                ReactDOM.jsx("div", {
                      className: "divider blank"
                    }),
                ReactDOM.jsxs("div", {
                      children: [
                        ReactDOM.jsx("div", {
                              children: React.jsx(make$1, {
                                    componentType: componentTypeFilter,
                                    onChange: (function (extra) {
                                        return filterByComponentType(vnode, extra);
                                      })
                                  }),
                              className: "column col"
                            }),
                        ReactDOM.jsx("div", {
                              children: React.jsx(make$2, {
                                    status: completionStatusFilter,
                                    onChange: (function (extra) {
                                        return filterByCompletionStatus(vnode, extra);
                                      })
                                  }),
                              className: "column col"
                            })
                      ],
                      className: "columns col-oneline"
                    })
              ]
            });
}

function getFilteredProcedures(components, procedures, structuralProcedures, safetyProcedures, checklistProcedures, rankFilter, componentTypeFilter, completionStatusFilter, search) {
  var components$1 = componentTypeFilter !== undefined ? Curry._2(Prelude.$$Array.keep, components, (function (c) {
            return c.type === componentTypeFilter;
          })) : components;
  var procedures$1 = componentTypeFilter !== undefined ? (
      componentTypeFilter === "structural" ? structuralProcedures : (
          componentTypeFilter === "checklist" ? checklistProcedures : safetyProcedures
        )
    ) : procedures;
  var procedures$2 = rankFilter !== undefined ? Curry._2(Prelude.$$Array.keep, procedures$1, (function (p) {
            return Caml_obj.equal(p.rank, rankFilter);
          })) : procedures$1;
  var procedures$3 = completionStatusFilter !== undefined ? Procedure.filterByCompletion(procedures$2, completionStatusFilter) : procedures$2;
  var terms = Curry._2(Prelude.$$Array.keepMap, Belt_SetString.toArray(Belt_SetString.fromArray(search.toLowerCase().split(" "))), (function (word) {
          var trimmed = word.trim();
          if (trimmed === "") {
            return ;
          } else {
            return trimmed;
          }
        }));
  var filteredComponentIds = ComponentId.$$Set.fromArray(Curry._2(Prelude.$$Array.keepMap, components$1, (function (c) {
              var componentName = c.name.toLowerCase();
              if (Curry._2(Prelude.$$Array.every, terms, (function (term) {
                        return componentName.includes(term);
                      }))) {
                return Caml_option.some(c.id);
              }
              
            })));
  var proceduresFilteredByComponentName = Curry._2(Prelude.$$Array.keep, procedures$3, (function (p) {
          return ComponentId.$$Set.has(filteredComponentIds, p.component_id);
        }));
  var proceduresFilteredByName = Curry._2(Prelude.$$Array.keep, procedures$3, (function (p) {
          var procedureName = p.name.toLowerCase();
          return Curry._2(Prelude.$$Array.every, terms, (function (term) {
                        return procedureName.includes(term);
                      }));
        }));
  return Procedure.$$Set.toArray(Procedure.$$Set.fromArray(Curry._2(Prelude.$$Array.concat, proceduresFilteredByComponentName, proceduresFilteredByName)));
}

function planAndNavigate(vnode, procedures, componentTypeFilter, rankFilter, completionStatusFilter, search, _event) {
  var plannedProcedures = PlannedProcedure.makeForProcedures(procedures);
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  Prelude.thenDo(Query.write((newrecord.plannedProcedures = Curry._2(Prelude.$$Array.concat, ["Clear"], plannedProcedures.map(function (plannedProcedure) {
                      return {
                              TAG: "Save",
                              _0: plannedProcedure
                            };
                    })), newrecord)), (function (param) {
          var properties = {
            type: componentTypeFilter,
            rank: rankFilter,
            status: completionStatusFilter,
            "text-search": search
          };
          var user = State__Memory.Current.get().user;
          MixPanel.track(user, "inspection-planner-button-click", Caml_option.some(properties));
          Mithril$1.route.set(url(vnode, componentTypeFilter));
        }));
}

function submitButton(vnode, components, procedures, structuralProcedures, safetyProcedures, checklistProcedures, rankFilter, componentTypeFilter, completionStatusFilter, search) {
  var procedures$1 = getFilteredProcedures(components, procedures, structuralProcedures, safetyProcedures, checklistProcedures, rankFilter, componentTypeFilter, completionStatusFilter, search);
  return ReactDOM.jsxs("button", {
              children: [
                ReactDOM.jsx("span", {
                      children: "Start Inspection (" + String(procedures$1.length) + ")",
                      className: "text-bold text-left col-11"
                    }),
                ReactDOM.jsx("span", {
                      children: React.jsx(Feather.make, {
                            icon: "chevron_right",
                            className: "float-right"
                          }),
                      className: "col-1"
                    })
              ],
              className: Mithril.$$class([
                    [
                      "btn btn-lg btn-block active",
                      true
                    ],
                    [
                      "disabled",
                      Prelude.$$Array.isEmpty(procedures$1)
                    ]
                  ], undefined),
              style: {
                marginBottom: "1rem",
                borderRadius: "1.5rem"
              },
              onClick: (function (extra) {
                  return planAndNavigate(vnode, procedures$1, componentTypeFilter, rankFilter, completionStatusFilter, search, extra);
                })
            });
}

function make$3(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), loadState$3), updateState), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var completionStatusFilter = match.completionStatusFilter;
                var componentTypeFilter = match.componentTypeFilter;
                var rankFilter = match.rankFilter;
                return React.jsx(ListLayout.make, {
                            children: ReactDOM.jsx("div", {
                                  children: [
                                    searchFilterElement(vnode),
                                    filters(vnode, componentTypeFilter, completionStatusFilter, rankFilter),
                                    ReactDOM.jsx("div", {
                                          className: "divider blank xl"
                                        }),
                                    submitButton(vnode, match.components, match.procedures, match.structuralProcedures, match.safetyProcedures, match.checklistProcedures, rankFilter, componentTypeFilter, completionStatusFilter, match.search)
                                  ]
                                }),
                            title: "Filter",
                            showBackButton: true,
                            showSettings: true,
                            section: "planner"
                          });
              }));
}

export {
  VesselPositionRankFilter ,
  ComponentTypeFilter ,
  CompletionStatusFilter ,
  _updateState ,
  loadState$3 as loadState,
  updateState ,
  filterByRank ,
  filterByComponentType ,
  filterByCompletionStatus ,
  filterByText ,
  url ,
  searchFilterElement ,
  filters ,
  getFilteredProcedures ,
  planAndNavigate ,
  submitButton ,
  make$3 as make,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_math from "rescript/lib/es6/js_math.js";
import * as StorageUnits from "../../../libs/StorageUnits.js";

function formatBytes(bytes) {
  return StorageUnits.toString(StorageUnits.toBest({
                  TAG: "Bytes",
                  _0: bytes
                }));
}

function walkedDistanceInMeters(steps) {
  return Js_math.ceil_int(steps * 0.762);
}

var stepToMeterRatio = 0.762;

export {
  formatBytes ,
  stepToMeterRatio ,
  walkedDistanceInMeters ,
}
/* No side effect */

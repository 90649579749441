// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserAPI from "../../api/endpoints/UserAPI.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as HttpError from "../../../libs/HttpError.js";
import * as State__User from "../../state/State__User.js";
import FullLogoPng from "/images/full-logo.png";

function errors(error) {
  var status = HttpError.safeCode(error);
  if (!(400 <= status && status < 500)) {
    if (500 <= status) {
      return Curry._1(Prelude.Dict.fromArray, [[
                    "__all__",
                    "There was an error from the server side"
                  ]]);
    } else {
      return Curry._1(Prelude.Dict.fromArray, [[
                    "__all__",
                    "Are you offline?"
                  ]]);
    }
  }
  var d;
  try {
    d = error.response;
  }
  catch (exn){
    return Curry._1(Prelude.Dict.fromArray, [[
                  "__all__",
                  "Are you offline?"
                ]]);
  }
  if (d === null || d === undefined) {
    if (d === null) {
      return Curry._1(Prelude.Dict.fromArray, [[
                    "__all__",
                    "Are you offline?"
                  ]]);
    } else {
      return Curry._1(Prelude.Dict.fromArray, [[
                    "__all__",
                    "Are you offline?"
                  ]]);
    }
  } else {
    return d;
  }
}

function handleError(error) {
  var errors$1 = errors(error);
  return {
          token: Curry._2(Prelude.Dict.get, errors$1, "token"),
          email: Curry._2(Prelude.Dict.get, errors$1, "email"),
          general: Curry._2(Prelude.Dict.get, errors$1, "__all__")
        };
}

function verifyLoginToken(email, token) {
  return Prelude.then(UserAPI.VerifyLogin.verify(email, {
                  TAG: "Token",
                  _0: token
                }), (function (result) {
                if (result.TAG === "Ok") {
                  return Prelude.PromisedResult.mapWithDefault(State__User.login(result._0), {
                              TAG: "VerificationFailed",
                              _0: {
                                token: undefined,
                                email: email,
                                general: undefined
                              }
                            }, (function (param) {
                                return "Succeed";
                              }));
                } else {
                  return Promise.resolve({
                              TAG: "VerificationFailed",
                              _0: handleError(result._0)
                            });
                }
              }));
}

function submitEmail(vnode) {
  var email = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=email]"));
  if (email !== undefined) {
    return Prelude.thenResolve(UserAPI.RequestLoginToken.send(email), (function (result) {
                  if (result.TAG === "Ok") {
                    return "ReadytoVerifyToken";
                  } else {
                    return {
                            TAG: "RequestFailed",
                            _0: handleError(result._0)
                          };
                  }
                }));
  } else {
    return Promise.resolve("Ready");
  }
}

function submitToken(vnode) {
  var email = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=email]"));
  var token = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=token]"));
  if (email !== undefined && token !== undefined) {
    return Prelude.thenResolve(verifyLoginToken(email, token), (function (state) {
                  if (typeof state !== "object" && state === "Succeed") {
                    Mithril$1.route.set("/");
                  }
                  return state;
                }));
  } else {
    return Promise.resolve(vnode.state.data);
  }
}

function setFocus(vnode) {
  var match = vnode.state.data;
  var exit = 0;
  if (typeof match !== "object") {
    switch (match) {
      case "Ready" :
          exit = 1;
          break;
      case "ReadytoVerifyToken" :
          exit = 2;
          break;
      default:
        return ;
    }
  } else {
    exit = match.TAG === "RequestFailed" ? 1 : 2;
  }
  switch (exit) {
    case 1 :
        var email = Mithril$1.route.param("email");
        var exit$1 = 0;
        if (email !== undefined && email !== "") {
          Mithril.$$Element.setValue(Mithril.$$Element.select(vnode, "[name=email]"), email);
          return Mithril.$$Element.focus(Mithril.$$Element.select(vnode, "[name=password]"));
        }
        exit$1 = 3;
        if (exit$1 === 3) {
          return Mithril.$$Element.focus(Mithril.$$Element.select(vnode, "[name=email]"));
        }
        break;
    case 2 :
        return Mithril.$$Element.focus(Mithril.$$Element.select(vnode, "[name=token]"));
    
  }
}

function make() {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.oninit(Mithril.component("Ready"), (function (param) {
                            State__User.Auth.redirectToIndexIfLoggedIn();
                          })), setFocus), setFocus), (function (vnode) {
                var state = vnode.state.data;
                var tmp;
                if (typeof state !== "object") {
                  switch (state) {
                    case "RequestingToken" :
                    case "ReadytoVerifyToken" :
                    case "VeryfingToken" :
                        tmp = true;
                        break;
                    default:
                      tmp = false;
                  }
                } else {
                  tmp = state.TAG === "VerificationFailed" ? state._0.email === undefined : false;
                }
                var tmp$1;
                var exit = 0;
                if (typeof state !== "object") {
                  tmp$1 = Mithril.empty;
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  var error = state._0.email;
                  tmp$1 = error !== undefined ? ReactDOM.jsx("p", {
                          children: error,
                          className: "form-input-hint"
                        }) : Mithril.empty;
                }
                var tmp$2;
                tmp$2 = typeof state !== "object" ? false : state._0.email !== undefined;
                var tmp$3;
                var exit$1 = 0;
                if (typeof state !== "object") {
                  switch (state) {
                    case "Ready" :
                    case "RequestingToken" :
                        tmp$3 = Mithril.empty;
                        break;
                    default:
                      exit$1 = 1;
                  }
                } else if (state.TAG === "RequestFailed") {
                  tmp$3 = Mithril.empty;
                } else {
                  exit$1 = 1;
                }
                if (exit$1 === 1) {
                  var tmp$4;
                  if (typeof state !== "object") {
                    switch (state) {
                      case "RequestingToken" :
                      case "VeryfingToken" :
                          tmp$4 = true;
                          break;
                      default:
                        tmp$4 = false;
                    }
                  } else {
                    tmp$4 = false;
                  }
                  var tmp$5;
                  var exit$2 = 0;
                  if (typeof state !== "object") {
                    tmp$5 = Mithril.empty;
                  } else {
                    exit$2 = 2;
                  }
                  if (exit$2 === 2) {
                    var error$1 = state._0.token;
                    tmp$5 = error$1 !== undefined ? ReactDOM.jsx("p", {
                            children: error$1,
                            className: "form-input-hint"
                          }) : Mithril.empty;
                  }
                  var tmp$6;
                  tmp$6 = typeof state !== "object" || state.TAG !== "VerificationFailed" ? false : state._0.token !== undefined;
                  tmp$3 = ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("label", {
                                children: "Copy here the code we sent to your email",
                                className: "form-label",
                                htmlFor: "login-form-token"
                              }),
                          ReactDOM.jsx("input", {
                                className: "form-input",
                                id: "login-form-token",
                                autoComplete: "off",
                                disabled: tmp$4,
                                name: "token",
                                placeholder: "000111",
                                type: "number"
                              }),
                          tmp$5
                        ],
                        className: Mithril.$$class([
                              [
                                "form-group",
                                true
                              ],
                              [
                                "has-error",
                                tmp$6
                              ]
                            ], undefined)
                      });
                }
                var tmp$7;
                var exit$3 = 0;
                if (typeof state !== "object") {
                  tmp$7 = Mithril.empty;
                } else {
                  exit$3 = 1;
                }
                if (exit$3 === 1) {
                  var error$2 = state._0.general;
                  tmp$7 = error$2 !== undefined ? ReactDOM.jsx("p", {
                          children: error$2,
                          className: "form-input-hint text-error"
                        }) : Mithril.empty;
                }
                var tmp$8;
                var exit$4 = 0;
                if (typeof state !== "object") {
                  switch (state) {
                    case "Ready" :
                    case "RequestingToken" :
                        exit$4 = 1;
                        break;
                    default:
                      exit$4 = 2;
                  }
                } else {
                  exit$4 = state.TAG === "RequestFailed" ? 1 : 2;
                }
                switch (exit$4) {
                  case 1 :
                      var tmp$9;
                      tmp$9 = typeof state !== "object" && state === "RequestingToken" ? true : false;
                      tmp$8 = ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("button", {
                                          children: "Send me a code",
                                          className: Mithril.$$class([
                                                [
                                                  "btn btn-primary btn-block",
                                                  true
                                                ],
                                                [
                                                  "loading",
                                                  tmp$9
                                                ]
                                              ], undefined),
                                          type: "submit"
                                        }),
                                    className: "column col mb-2"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("button", {
                                          children: "Sign in with password",
                                          className: "btn btn-block",
                                          onClick: (function ($$event) {
                                              $$event.preventDefault();
                                              Mithril$1.route.set("/login/password", {
                                                    email: Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=email]"))
                                                  });
                                            })
                                        }),
                                    className: "column col"
                                  })
                            ],
                            className: "columns"
                          });
                      break;
                  case 2 :
                      var tmp$10;
                      tmp$10 = typeof state !== "object" && state === "VeryfingToken" ? true : false;
                      tmp$8 = ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("button", {
                                          children: "Sign in",
                                          className: Mithril.$$class([
                                                [
                                                  "btn btn-primary btn-block",
                                                  true
                                                ],
                                                [
                                                  "loading",
                                                  tmp$10
                                                ]
                                              ], undefined),
                                          type: "submit"
                                        }),
                                    className: "form-group"
                                  }),
                              ReactDOM.jsx("button", {
                                    children: "Go back",
                                    className: "btn btn-link btn-block",
                                    onClick: (function (extra) {
                                        extra.preventDefault();
                                        vnode.state.data = "Ready";
                                      })
                                  })
                            ]
                          });
                      break;
                  
                }
                var tmp$11;
                if (typeof state !== "object") {
                  switch (state) {
                    case "RequestingToken" :
                    case "VeryfingToken" :
                        tmp$11 = true;
                        break;
                    default:
                      tmp$11 = false;
                  }
                } else {
                  tmp$11 = false;
                }
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsx("div", {
                                  children: ReactDOM.jsx("div", {
                                        children: ReactDOM.jsx("div", {
                                              children: ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsx("img", {
                                                            className: "img-responsive",
                                                            src: FullLogoPng
                                                          }),
                                                      ReactDOM.jsx("div", {
                                                            className: "divider blank"
                                                          }),
                                                      ReactDOM.jsx("form", {
                                                            children: ReactDOM.jsxs("fieldset", {
                                                                  children: [
                                                                    ReactDOM.jsxs("div", {
                                                                          children: [
                                                                            ReactDOM.jsx("label", {
                                                                                  children: "Email",
                                                                                  className: "form-label",
                                                                                  htmlFor: "login-form-email"
                                                                                }),
                                                                            ReactDOM.jsx("input", {
                                                                                  className: "form-input",
                                                                                  id: "login-form-email",
                                                                                  disabled: tmp,
                                                                                  name: "email",
                                                                                  placeholder: "your@email.com",
                                                                                  type: "email"
                                                                                }),
                                                                            tmp$1
                                                                          ],
                                                                          className: Mithril.$$class([
                                                                                [
                                                                                  "form-group",
                                                                                  true
                                                                                ],
                                                                                [
                                                                                  "has-error",
                                                                                  tmp$2
                                                                                ]
                                                                              ], undefined)
                                                                        }),
                                                                    tmp$3,
                                                                    tmp$7,
                                                                    tmp$8
                                                                  ],
                                                                  disabled: tmp$11
                                                                }),
                                                            onSubmit: (function (extra) {
                                                                extra.preventDefault();
                                                                var anyOtherState = vnode.state.data;
                                                                var tmp;
                                                                var exit = 0;
                                                                if (typeof anyOtherState !== "object") {
                                                                  switch (anyOtherState) {
                                                                    case "Ready" :
                                                                        exit = 1;
                                                                        break;
                                                                    case "ReadytoVerifyToken" :
                                                                        exit = 2;
                                                                        break;
                                                                    default:
                                                                      tmp = Promise.resolve(anyOtherState);
                                                                  }
                                                                } else {
                                                                  exit = anyOtherState.TAG === "RequestFailed" ? 1 : 2;
                                                                }
                                                                switch (exit) {
                                                                  case 1 :
                                                                      vnode.state.data = "RequestingToken";
                                                                      tmp = submitEmail(vnode);
                                                                      break;
                                                                  case 2 :
                                                                      vnode.state.data = "VeryfingToken";
                                                                      tmp = submitToken(vnode);
                                                                      break;
                                                                  
                                                                }
                                                                Prelude.thenResolve(tmp, (function (extra) {
                                                                        return Mithril.setState(vnode, extra);
                                                                      }));
                                                              })
                                                          })
                                                    ],
                                                    className: "hero-body"
                                                  }),
                                              className: "hero"
                                            }),
                                        className: "column col-6 col-lg-8 col-md-10 col-sm-12 col-mx-auto"
                                      }),
                                  className: "columns"
                                }),
                            className: "container grid md"
                          });
              }));
}

export {
  errors ,
  make ,
}
/* Mithril Not a pure module */

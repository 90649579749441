// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";

function focusBySelector(vnode, selector) {
  Mithril.$$Element.focus(Mithril.$$Element.select(vnode, selector));
}

function focus(vnode) {
  focusBySelector(vnode, "[class=form-input]");
}

var Input = {
  focus: focus
};

export {
  focusBySelector ,
  Input ,
}
/* Mithril Not a pure module */

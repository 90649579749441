// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as CompanyFormAPI from "../endpoints/CompanyFormAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function $$do(state) {
  var $$final = state.final;
  var initial = state.initial;
  var vessels = state.vessels;
  var user = state.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.companyFormSubmissions = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord.companyForms = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.companyForms, (function (extra) {
                        return AppSchema.CompanyForms.update(initial, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.companyFormSubmissions, (function (extra) {
                        return AppSchema.CompanyFormSubmissions.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do
};

function convertCompanyFormSubmissions(state, form, submissions) {
  return AppSchema.CompanyFormSubmissions.updateMany(state, submissions.map(function (submission) {
                  return {
                          id: submission.id,
                          name: submission.name,
                          vessel_id: form.vessel_id,
                          form_id: form.id,
                          timestamp: submission.timestamp,
                          target_date: submission.target_date,
                          inspections: submission.inspections
                        };
                }));
}

function convertCompanyForm(state, companyForm) {
  var appCompanyForm_id = companyForm.id;
  var appCompanyForm_vessel_id = companyForm.vessel_id;
  var appCompanyForm_name = companyForm.name;
  var appCompanyForm_related_procedures = companyForm.related_procedures;
  var appCompanyForm_allow_partial_submission = companyForm.allow_partial_submission;
  var appCompanyForm_recurrence = companyForm.recurrence;
  var appCompanyForm = {
    id: appCompanyForm_id,
    vessel_id: appCompanyForm_vessel_id,
    name: appCompanyForm_name,
    related_procedures: appCompanyForm_related_procedures,
    allow_partial_submission: appCompanyForm_allow_partial_submission,
    recurrence: appCompanyForm_recurrence
  };
  AppSchema.CompanyForms.update(state, appCompanyForm);
  return convertCompanyFormSubmissions(state, appCompanyForm, companyForm.last_submissions);
}

function convertCompanyForms(state, companyForms) {
  return Curry._3(Prelude.$$Array.fold, companyForms, state, convertCompanyForm);
}

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(CompanyFormAPI.getAll(undefined, {
                  vessel__id__in: vessels.join(",")
                }, undefined, user), (function (forms) {
                var $$final$1 = convertCompanyForms($$final, forms);
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final$1
                      };
              }));
}

var ReadServerState = {
  convertCompanyFormSubmissions: convertCompanyFormSubmissions,
  convertCompanyForm: convertCompanyForm,
  convertCompanyForms: convertCompanyForms,
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.companyFormSubmissions = AppSchema.CompanyFormSubmissions.getActions(initial, $$final);
  newrecord.companyForms = AppSchema.CompanyForms.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncCompanyForms" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [
  AppSchema.CompanyForms,
  AppSchema.CompanyFormSubmissions
];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var views$1 = [AppSchema.CompanyFormSubmissions];

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup({
      views: views$1
    });

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncCompanyForms",
      ReadServerState: {
        $$do: $$do$1
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  FillInitialStatus ,
  ReadServerState ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Browser from "../../../libs/Browser.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Attachment from "../../attachment_chunks/Attachment.js";
import * as Inspection from "../Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../ProcedureId.js";
import * as InspectionId from "../InspectionId.js";
import * as Mithril__Route from "../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as SignaturePadView from "../../common/views/SignaturePadView.js";
import * as State__Inspections from "../../state/State__Inspections.js";
import * as NewInspectionManager from "../NewInspectionManager.js";

async function updateInspection(inspection, attachment, _promise) {
  var oldSignature = Inspection.signature(inspection);
  var inspection$1;
  if (oldSignature !== undefined) {
    var inspection$2 = await NewInspectionManager.deletePhoto(inspection, oldSignature);
    inspection$1 = NewInspectionManager.addAttachment(inspection$2, attachment);
  } else {
    inspection$1 = NewInspectionManager.addAttachment(inspection, attachment);
  }
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  return await Query.write((newrecord.newInspections = [{
                  TAG: "Save",
                  _0: {
                    id: inspection$1.id,
                    procedure_id: inspection$1.procedure_id,
                    component_id: inspection$1.component_id,
                    section_id: inspection$1.section_id,
                    vessel_id: inspection$1.vessel_id,
                    inspector_id: inspection$1.inspector_id,
                    timestamp: inspection$1.timestamp,
                    code: inspection$1.code,
                    annotations: inspection$1.annotations,
                    additional_annotation: inspection$1.additional_annotation,
                    picture_prompted: inspection$1.picture_prompted,
                    walked_steps: inspection$1.walked_steps,
                    app_version: inspection$1.app_version,
                    deficiency_id: inspection$1.deficiency_id,
                    attachments: inspection$1.attachments,
                    value: {
                      NAME: "Signature",
                      VAL: true
                    },
                    deadline: inspection$1.deadline,
                    profile: inspection$1.profile
                  }
                }], newrecord));
}

function createInspection(procedure, attachment, _promise) {
  var draftSubmissionId = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("draftSubmissionId"), (function (i) {
          return i;
        }));
  return Prelude.then(State__Inspections.make(procedure, undefined, attachment, {
                  NAME: "Signature",
                  VAL: true
                }, undefined, undefined, undefined, undefined, Caml_option.some(draftSubmissionId), undefined), (function (param) {
                return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, param[1], (function (i) {
                                  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
                                  return Prelude.thenResolve(Query.write((newrecord.newInspections = [{
                                                      TAG: "Save",
                                                      _0: i
                                                    }], newrecord)), (function (param) {
                                                return i;
                                              }));
                                })), Promise.resolve(undefined));
              }));
}

function createAttachment(vnode, procedure, inspection, image) {
  var match = Attachment.make(image, "SIGNATURE");
  var promise = match[1];
  var attachment = match[0];
  return Prelude.thenResolve(inspection !== undefined ? Prelude.thenResolve(updateInspection(inspection, attachment, promise), (function (param) {
                      return inspection;
                    })) : createInspection(procedure, attachment, promise), (function (param) {
                return vnode.state.data;
              }));
}

function onSignatureTaken(vnode, blob) {
  var match = vnode.state.data;
  var tmp;
  tmp = typeof match !== "object" ? Promise.resolve("NotReady") : (
      blob !== undefined ? createAttachment(vnode, match.procedure, match.inspection, Caml_option.valFromOption(blob)) : Promise.resolve(match)
    );
  Prelude.thenDo(tmp, (function (param) {
          window.history.back();
        }));
}

function clear(vnode, _event) {
  
}

function deleteInspection(vnode, $$event) {
  $$event.preventDefault();
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var inspection = match.inspection;
  if (inspection !== undefined) {
    if (Browser.$$Window.confirm("Are you sure?")) {
      return Prelude.thenDo(State__Inspections.$$delete(inspection), (function (param) {
                    window.history.back();
                  }));
    } else {
      return ;
    }
  } else {
    window.history.back();
    return ;
  }
}

function make() {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var procedureId = ProcedureId.Route.param("procedureId");
                    var inspectionId = InspectionId.Route.paramOr("inspectionId", InspectionId.$$Option.$$default(undefined));
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.newInspections = {
                                TAG: "Get",
                                _0: inspectionId
                              }, newrecord.procedures = {
                                TAG: "Get",
                                _0: procedureId
                              }, newrecord)), (function (param) {
                            var procedure = Prelude.$$Array.first(param.procedures);
                            if (procedure !== undefined) {
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          procedure: procedure,
                                          inspection: Prelude.$$Array.first(param.newInspections),
                                          update: Prelude.default(Mithril__Route.paramBool("update"), false),
                                          signature_pad: undefined
                                        });
                            } else {
                              window.history.back();
                              return ;
                            }
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var actions = match.update ? [{
                      icon: "trash_2",
                      effect: {
                        TAG: "Fn",
                        _0: (function (extra) {
                            return deleteInspection(vnode, extra);
                          })
                      }
                    }] : [];
                return React.jsx(ListLayout.make, {
                            children: React.jsx(SignaturePadView.make, {
                                  onSign: (function (extra) {
                                      return onSignatureTaken(vnode, extra);
                                    })
                                }),
                            title: match.procedure.name,
                            showBackButton: true,
                            bottomTaskBarStatus: "Hide",
                            actions: actions
                          });
              }));
}

export {
  updateInspection ,
  createInspection ,
  createAttachment ,
  onSignatureTaken ,
  clear ,
  deleteInspection ,
  make ,
}
/* Query Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

var UUID = Utils.MakeOpaqueIdentifier({});

var ProcedureImagesReader = IDB__Migration__Utils.MakeReader({
      storeName: "procedureImages"
    });

function message(param, param$1) {
  return "Optimizing procedure diagrams and images";
}

function $$process(procedure, images) {
  var position_diagram = Curry._2(Prelude.OptionExported.$$Option.flatMap, procedure.position_diagram_id, (function (extra) {
          return Curry._2(UUID.$$Map.get, images, extra);
        }));
  var example_image = Curry._2(Prelude.OptionExported.$$Option.flatMap, procedure.example_image_id, (function (extra) {
          return Curry._2(UUID.$$Map.get, images, extra);
        }));
  return {
          TAG: "Update",
          _0: {
            id: procedure.id,
            vessel_id: procedure.vessel_id,
            section_id: procedure.section_id,
            component_id: procedure.component_id,
            name: procedure.name,
            order: procedure.order,
            position_diagram: position_diagram,
            position_diagram_desc: procedure.position_diagram_desc,
            example_image: example_image,
            example_image_desc: procedure.example_image_desc,
            description: procedure.description,
            require_landscape_pictures: procedure.require_landscape_pictures,
            question_type: procedure.question_type,
            rating_styling_rules_id: procedure.rating_styling_rules_id,
            require_image_conditions: procedure.require_image_conditions,
            recurrence: procedure.recurrence,
            due_date: procedure.due_date,
            overdue_date: procedure.overdue_date,
            ignore_overdue_status: procedure.ignore_overdue_status,
            vessel_position: procedure.vessel_position,
            extra: procedure.extra
          }
        };
}

var ProcedureRewriter = InitFeedback.MakeRewriter({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

var PlannedProcedureRewriter = InitFeedback.MakeRewriter({
      storeName: "plannedProcedures",
      message: message,
      $$process: $$process
    });

function message$1(param) {
  return "Optimizing procedure diagrams and images";
}

function migrate(param, transaction) {
  return Prelude.then(ProcedureImagesReader.read(transaction), (function (images) {
                var images$1 = UUID.$$Map.fromArray(images.map(function (img) {
                          return [
                                  img.id,
                                  img
                                ];
                        }));
                return Prelude.PromisedResult.map(Prelude.PromisedResult.bail([
                                (function () {
                                    return ProcedureRewriter.rewrite(transaction, images$1);
                                  }),
                                (function () {
                                    return PlannedProcedureRewriter.rewrite(transaction, images$1);
                                  }),
                                (function () {
                                    var store = transaction.objectStore("procedureImages");
                                    var val;
                                    try {
                                      store.name = "deprecated_ProcedureImages";
                                      val = undefined;
                                    }
                                    catch (exn){
                                      return Promise.resolve({
                                                  TAG: "Error",
                                                  _0: "Cannot rename store 'procedureImages'"
                                                });
                                    }
                                    return Promise.resolve({
                                                TAG: "Ok",
                                                _0: undefined
                                              });
                                  })
                              ]), (function (param) {
                              
                            }));
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* UUID Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";

function message() {
  return "Installing new index for planned procedures";
}

function migrate(_db, transaction) {
  IDB__Migration__Store.createSimpleIndex(transaction.objectStore("plannedProcedures"), "vessel_id");
  return Promise.resolve({
              TAG: "Ok",
              _0: undefined
            });
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE


function make(param) {
  return function (db, _transaction) {
    db.deleteObjectStore("vesselSurveys");
    db.deleteObjectStore("newReadings");
    db.deleteObjectStore("readings");
    db.deleteObjectStore("variables");
    db.deleteObjectStore("forms");
    db.deleteObjectStore("findings");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InspectionId from "./InspectionId.js";
import * as FutureAttachmentManager from "../attachment_chunks/FutureAttachmentManager.js";

async function deletePhoto(inspection, attachment) {
  await FutureAttachmentManager.$$delete([attachment.id]);
  return {
          id: inspection.id,
          procedure_id: inspection.procedure_id,
          component_id: inspection.component_id,
          section_id: inspection.section_id,
          vessel_id: inspection.vessel_id,
          inspector_id: inspection.inspector_id,
          timestamp: inspection.timestamp,
          code: inspection.code,
          annotations: inspection.annotations,
          additional_annotation: inspection.additional_annotation,
          picture_prompted: inspection.picture_prompted,
          walked_steps: inspection.walked_steps,
          app_version: inspection.app_version,
          deficiency_id: inspection.deficiency_id,
          attachments: Curry._2(Prelude.$$Array.keep, inspection.attachments, (function (x) {
                  return Caml_obj.notequal(x.id, attachment.id);
                })),
          value: inspection.value,
          deadline: inspection.deadline,
          profile: inspection.profile
        };
}

function addAttachment(inspection, attachment) {
  return {
          id: inspection.id,
          procedure_id: inspection.procedure_id,
          component_id: inspection.component_id,
          section_id: inspection.section_id,
          vessel_id: inspection.vessel_id,
          inspector_id: inspection.inspector_id,
          timestamp: inspection.timestamp,
          code: inspection.code,
          annotations: inspection.annotations,
          additional_annotation: inspection.additional_annotation,
          picture_prompted: inspection.picture_prompted,
          walked_steps: inspection.walked_steps,
          app_version: inspection.app_version,
          deficiency_id: inspection.deficiency_id,
          attachments: Prelude.$$Array.append(inspection.attachments, attachment),
          value: inspection.value,
          deadline: inspection.deadline,
          profile: inspection.profile
        };
}

function make(procedure_id, component_id, section_id, vessel_id, valueOpt, inspector_idOpt, picture_promptedOpt, walked_stepsOpt, photoOpt, signatureOpt, additional_annotationOpt, annotationsOpt, id, profile) {
  var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : undefined;
  var inspector_id = inspector_idOpt !== undefined ? Caml_option.valFromOption(inspector_idOpt) : undefined;
  var picture_prompted = picture_promptedOpt !== undefined ? picture_promptedOpt : false;
  var walked_steps = walked_stepsOpt !== undefined ? walked_stepsOpt : 0;
  var photo = photoOpt !== undefined ? Caml_option.valFromOption(photoOpt) : undefined;
  var signature = signatureOpt !== undefined ? Caml_option.valFromOption(signatureOpt) : undefined;
  var additional_annotation = additional_annotationOpt !== undefined ? additional_annotationOpt : "";
  var annotations = annotationsOpt !== undefined ? annotationsOpt : [];
  var inspection_id = Prelude.default(id, InspectionId.make());
  var inspection_timestamp = new Date();
  var inspection_attachments = [];
  var inspection_profile = Curry._2(Prelude.OptionExported.$$Option.map, profile, (function (p) {
          return p.id;
        }));
  var inspection = {
    id: inspection_id,
    procedure_id: procedure_id,
    component_id: component_id,
    section_id: section_id,
    vessel_id: vessel_id,
    inspector_id: inspector_id,
    timestamp: inspection_timestamp,
    code: undefined,
    annotations: annotations,
    additional_annotation: additional_annotation,
    picture_prompted: picture_prompted,
    walked_steps: walked_steps,
    app_version: Settings.releaseVersion,
    deficiency_id: undefined,
    attachments: inspection_attachments,
    value: value,
    deadline: undefined,
    profile: inspection_profile
  };
  var inspection$1 = photo !== undefined ? addAttachment(inspection, {
          id: photo.id,
          file: photo.file,
          thumbnail: photo.thumbnail,
          role: "MAIN_EVIDENCE"
        }) : inspection;
  if (signature !== undefined) {
    return addAttachment(inspection$1, {
                id: signature.id,
                file: signature.file,
                thumbnail: signature.thumbnail,
                role: "SIGNATURE"
              });
  } else {
    return inspection$1;
  }
}

export {
  deletePhoto ,
  addAttachment ,
  make ,
}
/* Settings Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Component from "../../vessel/Component.js";
import * as ComponentId from "../../vessel/ComponentId.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as ReviewUpload__ComponentAccordion from "./ReviewUpload__ComponentAccordion.js";

function toggleState(vnode, _event) {
  var match = vnode.state.data;
  var tmp;
  if (typeof match !== "object") {
    var inspections = vnode.attrs.inspections;
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    tmp = Prelude.thenResolve(Query.read((newrecord.components = {
                TAG: "In",
                _0: inspections.map(NewInspection.byComponent)
              }, newrecord)), (function (param) {
            return {
                    TAG: "Open",
                    componentGroups: Component.groupByNameAndNumber(param.components, undefined, undefined),
                    inspectionsOfComponent: ComponentId.$$Array.getter(inspections, NewInspection.byComponent)
                  };
          }));
  } else {
    tmp = Promise.resolve("Closed");
  }
  Prelude.thenDo(tmp, (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function make(param) {
  return Mithril.view(Mithril.component("Closed"), (function (vnode) {
                var section = vnode.attrs.section;
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var inspectionsOfComponent = match.inspectionsOfComponent;
                  tmp = match.componentGroups.map(function (components) {
                        var id = vnode.attrs.id + "_" + Prelude.$$Array.firstUnsafe(components).id;
                        return React.jsxKeyed(ReviewUpload__ComponentAccordion.make, {
                                    id: id,
                                    components: components,
                                    inspections: Curry._1(Prelude.$$Array.concatMany, components.map(function (param) {
                                              return inspectionsOfComponent(param.id);
                                            }))
                                  }, id, undefined);
                      });
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("input", {
                                    hidden: true,
                                    id: vnode.attrs.id,
                                    name: "accordion-checkbox",
                                    type: "checkbox",
                                    onChange: (function (extra) {
                                        return toggleState(vnode, extra);
                                      })
                                  }),
                              ReactDOM.jsxs("label", {
                                    children: [
                                      section.name,
                                      React.jsx(Feather.make, {
                                            icon: "chevron_right",
                                            className: "icon",
                                            style: "top: 0.25rem; position: relative; float: right"
                                          })
                                    ],
                                    className: "accordion-header text-primary h5",
                                    htmlFor: vnode.attrs.id
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "accordion-body"
                                  })
                            ],
                            className: "accordion"
                          });
              }));
}

var ComponentAccordion;

export {
  ComponentAccordion ,
  toggleState ,
  make ,
}
/* Query Not a pure module */

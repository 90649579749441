// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as State from "../../state/State.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../../Settings.js";
import * as State__User from "../../state/State__User.js";
import * as UploadErrors from "./UploadErrors.js";
import * as State__Answers from "../../state/State__Answers.js";
import * as State__Procedures from "../../state/State__Procedures.js";
import * as State__Inspections from "../../state/State__Inspections.js";
import * as State__UserProfiles from "../../state/State__UserProfiles.js";
import * as EndOfTransmissionAPI from "../../api/endpoints/EndOfTransmissionAPI.js";

function describe(actionable) {
  if (typeof actionable !== "object") {
    return "GET";
  }
  switch (actionable.TAG) {
    case "UserProfiles" :
        return "UserProfiles";
    case "ProcedureUpdates" :
        return "ProcedureUpdates";
    case "Answers" :
        return "Answers";
    case "Comments" :
        return "Comments";
    case "UpdatedComments" :
        return "UpdatedComments";
    case "CompanyFormSubmissions" :
        return "CompanyFormSubmissions";
    case "LevelSubmissions" :
        return "LevelSubmissions";
    case "Inspections" :
        return "Inspections";
    case "ReadNotificationEntries" :
        return "ReadNotificationEntries";
    case "UploadFutureAttachmentChunk" :
        return "UploadFutureAttachmentChunk";
    case "EOT" :
        return "EOT";
    
  }
}

function repr(actionable) {
  if (typeof actionable !== "object") {
    return "GET";
  }
  switch (actionable.TAG) {
    case "UserProfiles" :
        return "UserProfiles([" + String(actionable._0.length) + "])";
    case "ProcedureUpdates" :
        return "ProcedureUpdates([" + String(actionable._0.length) + "])";
    case "Answers" :
        return "Answers([" + String(actionable._0.length) + "])";
    case "Comments" :
        return "Comments([" + String(actionable._0.length) + "])";
    case "UpdatedComments" :
        return "UpdatedComments([" + String(actionable._0.length) + "])";
    case "CompanyFormSubmissions" :
        return "CompanyFormSubmissions([" + String(actionable._0.length) + "])";
    case "LevelSubmissions" :
        return "LevelSubmissions([" + String(actionable._0.length) + "])";
    case "Inspections" :
        return "Inspections([" + String(actionable._0.length) + "])";
    case "ReadNotificationEntries" :
        return "ReadNotificationEntries([" + String(actionable._0.length) + "])";
    case "UploadFutureAttachmentChunk" :
        return "UploadFutureAttachmentChunk(chunkId: " + actionable.chunkId + ", future: " + actionable.future.id + ")";
    case "EOT" :
        return "EOT";
    
  }
}

function describe$1(result) {
  if (result.TAG === "Ok") {
    return "Ok: " + describe(result._0);
  } else {
    return "Error: " + describe(result._0[0]);
  }
}

var Status = {
  describe: describe$1
};

function _fromInspections(payloads) {
  var inspections = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "Inspection") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(inspections, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "Inspections",
                      _0: chunk
                    };
            });
}

function _fromComments(payloads) {
  var comments = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "Comment") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(comments, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "Comments",
                      _0: chunk
                    };
            });
}

function _fromUpdatedComments(payloads) {
  var updatedComments = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "UpdatedComment") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(updatedComments, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "UpdatedComments",
                      _0: chunk
                    };
            });
}

function _fromUserProfiles(payloads) {
  var profiles = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "UserProfile") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(profiles, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "UserProfiles",
                      _0: chunk
                    };
            });
}

function _fromAnswers(payloads) {
  var answers = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "Answer") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(answers, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "Answers",
                      _0: chunk
                    };
            });
}

function _fromCompanyFormSubmissions(payloads) {
  var companyFormSubmissions = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "CompanyFormSubmission") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(companyFormSubmissions, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "CompanyFormSubmissions",
                      _0: chunk
                    };
            });
}

function _fromLevelSubmissions(payloads) {
  var levelSubmissions = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "LevelSubmission") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(levelSubmissions, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "LevelSubmissions",
                      _0: chunk
                    };
            });
}

function _fromProcedureUpdates(payloads) {
  var procedureUpdates = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "ProcedureUpdate") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(procedureUpdates, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "ProcedureUpdates",
                      _0: chunk
                    };
            });
}

function _fromReadNotificationEntries(payloads) {
  var readNotificationsEntries = Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
          if (payload.TAG === "ReadNotificationEntry") {
            return [
                    payload,
                    payload._1
                  ];
          }
          
        }));
  return Curry._2(Prelude.$$Array.keep, Prelude.$$Array.chunks(readNotificationsEntries, Settings.Upload.maxPostItems), Prelude.$$Array.isNotEmpty).map(function (chunk) {
              return {
                      TAG: "ReadNotificationEntries",
                      _0: chunk
                    };
            });
}

function _fromPreviousChunkGroups(payloads) {
  return Prelude.$$Array.flatMap(Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                    if (payload.TAG === "FutureAttachment") {
                      return [
                              payload,
                              payload._1
                            ];
                    }
                    
                  })), (function (param) {
                var future = param[1];
                var payload = param[0];
                return future.localChunks.map(function (chunk) {
                            return {
                                    TAG: "UploadFutureAttachmentChunk",
                                    payload: payload,
                                    future: future,
                                    chunkId: chunk.id,
                                    retries: Settings.Upload.attachmentRetries,
                                    delay: 0
                                  };
                          });
              }));
}

async function make(payloads) {
  return Curry._1(Prelude.$$Array.concatMany, [
              _fromUserProfiles(payloads),
              _fromInspections(payloads),
              _fromAnswers(payloads),
              _fromLevelSubmissions(payloads),
              _fromCompanyFormSubmissions(payloads),
              _fromProcedureUpdates(payloads),
              _fromComments(payloads),
              _fromUpdatedComments(payloads),
              _fromReadNotificationEntries(payloads),
              _fromPreviousChunkGroups(payloads)
            ]);
}

function wrap(actionable, result) {
  if (result.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: actionable
          };
  } else {
    return {
            TAG: "Error",
            _0: [
              actionable,
              UploadErrors.GenericError.reason(result._0)
            ]
          };
  }
}

async function execute(actionable) {
  if (typeof actionable !== "object") {
    return {
            TAG: "Ok",
            _0: actionable
          };
  }
  switch (actionable.TAG) {
    case "UserProfiles" :
        var result = await State__UserProfiles.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result);
    case "ProcedureUpdates" :
        var result$1 = await State__Procedures.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$1);
    case "Answers" :
        var result$2 = await State__Answers.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$2);
    case "Comments" :
        var result$3 = await State.FindingComments.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$3);
    case "UpdatedComments" :
        var result$4 = await State.UpdatedComments.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$4);
    case "CompanyFormSubmissions" :
        var result$5 = await State.FormSubmissions.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$5);
    case "LevelSubmissions" :
        var result$6 = await State.LevelSubmissions.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$6);
    case "Inspections" :
        var inspections = actionable._0;
        if (inspections.length === 0) {
          return {
                  TAG: "Ok",
                  _0: actionable
                };
        }
        var result$7 = await State__Inspections.upload(inspections.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$7);
    case "ReadNotificationEntries" :
        var result$8 = await State.ReadNotificationEntries.upload(actionable._0.map(function (param) {
                  return param[1];
                }));
        return wrap(actionable, result$8);
    case "UploadFutureAttachmentChunk" :
        var payload = actionable.payload;
        var future;
        future = payload.TAG === "FutureAttachment" ? payload._1 : undefined;
        if (future !== undefined) {
          var result$9 = await State.FutureAttachment.upload(future, actionable.chunkId);
          return wrap(actionable, result$9);
        }
        console.error("UploadActionable", "UploadFutureAttachmentChunk without a future attachment");
        return {
                TAG: "Ok",
                _0: actionable
              };
    case "EOT" :
        var vesselIds = actionable._0;
        var result$10 = await State__User.Auth.required(function (user) {
              return EndOfTransmissionAPI.post(user, vesselIds);
            });
        return wrap(actionable, result$10);
    
  }
}

function abortable(actionable) {
  if (typeof actionable !== "object") {
    return false;
  }
  switch (actionable.TAG) {
    case "UserProfiles" :
    case "Answers" :
    case "Inspections" :
        return true;
    default:
      return false;
  }
}

function retry(actionable) {
  if (typeof actionable !== "object") {
    return ;
  }
  if (actionable.TAG !== "UploadFutureAttachmentChunk") {
    return ;
  }
  var retries = actionable.retries;
  if (retries !== 0) {
    return {
            TAG: "UploadFutureAttachmentChunk",
            payload: actionable.payload,
            future: actionable.future,
            chunkId: actionable.chunkId,
            retries: retries - 1 | 0,
            delay: Js_math.ceil(actionable.delay * 1.1 + 800.0)
          };
  }
  
}

function getPayloads(actionable) {
  if (typeof actionable !== "object") {
    return [];
  }
  switch (actionable.TAG) {
    case "UserProfiles" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "ProcedureUpdates" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "Answers" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "Comments" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "UpdatedComments" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "CompanyFormSubmissions" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "LevelSubmissions" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "Inspections" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "ReadNotificationEntries" :
        return actionable._0.map(function (param) {
                    return param[0];
                  });
    case "UploadFutureAttachmentChunk" :
        return [actionable.payload];
    case "EOT" :
        return [];
    
  }
}

function size(actionable) {
  if (typeof actionable !== "object") {
    return 10.0;
  }
  switch (actionable.TAG) {
    case "UserProfiles" :
    case "ProcedureUpdates" :
        return actionable._0.length * 200.0;
    case "Answers" :
        return actionable._0.length * 600.0;
    case "Comments" :
        return actionable._0.length * 500.0;
    case "UpdatedComments" :
        return actionable._0.length * 100.0;
    case "CompanyFormSubmissions" :
    case "LevelSubmissions" :
        return actionable._0.length * 540.0;
    case "Inspections" :
        return actionable._0.length * 610.0;
    case "ReadNotificationEntries" :
        return actionable._0.length * 80.0;
    case "UploadFutureAttachmentChunk" :
        var chunkId = actionable.chunkId;
        return Prelude.default(Prelude.$$Array.first(Curry._2(Prelude.$$Array.keepMap, actionable.future.allChunks, (function (param) {
                              if (Caml_obj.equal(chunkId, param.id)) {
                                return param.size;
                              }
                              
                            }))), 0.0);
    case "EOT" :
        return 10.0;
    
  }
}

function failible(actionable) {
  if (typeof actionable !== "object") {
    return false;
  }
  switch (actionable.TAG) {
    case "UploadFutureAttachmentChunk" :
    case "EOT" :
        return true;
    default:
      return false;
  }
}

export {
  Status ,
  make ,
  execute ,
  abortable ,
  retry ,
  getPayloads ,
  size ,
  describe ,
  repr ,
  failible ,
}
/* State Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                return ReactDOM.jsx("label", {
                            children: match.text,
                            className: "tw-text-lg tw-font-medium"
                          });
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Attachment from "../../../attachment_chunks/Attachment.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as QuestionWidget from "./QuestionWidget.js";
import * as SignaturePadView from "../../../common/views/SignaturePadView.js";

function getSignature(vnode, blob) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  } else {
    return Curry._2(Prelude.OptionExported.$$Option.map, blob, (function (b) {
                  var match = Attachment.make(b, "SIGNATURE");
                  var attachment = match[0];
                  return Prelude.thenResolve(match[1], (function () {
                                return attachment;
                              }));
                }));
  }
}

function SignatureQuestionWidget$Editor(props) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                tmp = typeof match !== "object" ? Mithril.empty : React.jsx(SignaturePadView.make, {
                        onSign: (function (extra) {
                            var promise = getSignature(vnode, extra);
                            if (promise !== undefined) {
                              return Prelude.thenDo(Caml_option.valFromOption(promise), (function (attachment) {
                                            QuestionWidget.Editor.save(vnode, (function () {
                                                    return {
                                                            value: {
                                                              NAME: "Value",
                                                              VAL: {
                                                                NAME: "Signature",
                                                                VAL: attachment
                                                              }
                                                            },
                                                            attachments: [attachment]
                                                          };
                                                  }), false, undefined);
                                          }));
                            } else {
                              console.warn("SignatureQuestionWidget", "Signature not obtained");
                              return ;
                            }
                          })
                      });
                return React.jsx(QuestionWidget.Editor.make, {
                            updateChildrenState: (function (extra) {
                                var newState;
                                newState = typeof extra !== "object" ? "NotReady" : ({
                                      TAG: "Loaded",
                                      lastAnswer: extra.lastAnswer,
                                      lastNewAnswer: extra.lastNewAnswer,
                                      question: extra.question,
                                      level: extra.level,
                                      vessel: extra.vessel
                                    });
                                return Mithril.setState(vnode, newState);
                              }),
                            children: tmp
                          });
              }));
}

function SignatureQuestionWidget(props) {
  var draftSubmission = props.draftSubmission;
  var lastAnswer = props.lastAnswer;
  var lastNewAnswer = props.lastNewAnswer;
  var vessel = props.vessel;
  var level = props.level;
  var question = props.question;
  return Mithril.view(Mithril.component(), (function (vnode) {
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                var submissionId = Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                        return s.id;
                      }));
                var editUrl = function (none, extra, extra$1, extra$2, extra$3) {
                  return URLs.signatureQuestionWidgetEditor(none, Prelude.default(Mithril__Route.paramBool("isRectification"), false), extra, extra$1, extra$2, extra$3);
                };
                return React.jsx(QuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            draftSubmission: draftSubmission,
                            editUrl: editUrl,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "pen_tool"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active ",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isNone(submissionId)
                                        ],
                                        [
                                          "bg-success",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isSome(submissionId)
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return QuestionWidget.startInspection(vnode, editUrl, extra);
                                    })
                                })
                          });
              }));
}

var Editor = {
  make: SignatureQuestionWidget$Editor
};

var make = SignatureQuestionWidget;

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */

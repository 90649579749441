// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Signature_pad from "signature_pad";

function takeSignature(vnode, callback, _event) {
  var match = vnode.state.data;
  if (match === undefined) {
    return callback(undefined);
  }
  var canvas = Mithril.$$Element.select(vnode, "#canvas");
  if (canvas !== undefined) {
    Caml_option.valFromOption(canvas).toBlob(callback, "image/png", 1.0);
    return ;
  } else {
    return callback(undefined);
  }
}

function resizeCanvas(vnode) {
  var canvas = Mithril.$$Element.select(vnode, "#canvas");
  var html_element = Mithril.$$Element.asHtmlElement(canvas);
  if (html_element === undefined) {
    return ;
  }
  var element = Caml_option.valFromOption(html_element);
  var offsetWidth = element.offsetWidth;
  var offsetHeight = element.offsetHeight;
  if (canvas === undefined) {
    return ;
  }
  var c = Caml_option.valFromOption(canvas);
  c.width = offsetWidth;
  c.height = offsetHeight;
}

function isEmpty(vnode) {
  var signature = vnode.state.data;
  if (signature !== undefined) {
    return Caml_option.valFromOption(signature).isEmpty();
  } else {
    return false;
  }
}

function make(param) {
  return Mithril.view(Mithril.onremove(Mithril.onupdate(Mithril.oncreate(Mithril.component(undefined), (function (vnode) {
                            var signaturePad = Curry._2(Prelude.OptionExported.$$Option.map, Mithril.$$Element.select(vnode, "#canvas"), (function (prim) {
                                    return new Signature_pad(prim);
                                  }));
                            if (signaturePad !== undefined) {
                              Caml_option.valFromOption(signaturePad).addEventListener("afterUpdateStroke", (function (_event) {
                                      var btn = Mithril.$$Element.select(vnode, "#save");
                                      var match = isEmpty(vnode);
                                      if (btn === undefined) {
                                        return ;
                                      }
                                      var btn$1 = Caml_option.valFromOption(btn);
                                      if (match) {
                                        btn$1.classList.add("disabled");
                                      } else {
                                        btn$1.classList.remove("disabled");
                                      }
                                    }));
                            }
                            Curry._2(Prelude.OptionExported.$$Option.forEach, signaturePad, (function (signature) {
                                    vnode.state.data = Caml_option.some(signature);
                                  }));
                          })), (function (vnode) {
                        resizeCanvas(vnode);
                      })), (function (vnode) {
                    Curry._2(Prelude.OptionExported.$$Option.forEach, vnode.state.data, (function (signature) {
                            signature.off();
                            signature.clear();
                          }));
                  })), (function (vnode) {
                var onSign = Prelude.default(vnode.attrs.onSign, (function (param) {
                        
                      }));
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("canvas", {
                                    id: "canvas"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: "Sign above",
                                    className: "flex-centered"
                                  }),
                              ReactDOM.jsx("button", {
                                    children: "Save",
                                    className: Mithril.$$class([
                                          [
                                            "btn btn-primary btn-block btn-lg",
                                            true
                                          ],
                                          [
                                            "disabled",
                                            isEmpty(vnode) === true
                                          ]
                                        ], undefined),
                                    id: "save",
                                    onClick: (function (extra) {
                                        return takeSignature(vnode, onSign, extra);
                                      })
                                  })
                            ],
                            className: "signature-pad",
                            id: "signature-pad"
                          });
              }));
}

export {
  takeSignature ,
  resizeCanvas ,
  isEmpty ,
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Dialog from "../../../libs/Dialog.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function make(param) {
  return Mithril.view(Mithril.component(undefined), (function (vnode) {
                var id = vnode.attrs.id;
                var currentValue = vnode.attrs.currentValue;
                var actions = [
                  {
                    label: "Cancel",
                    className: "btn"
                  },
                  {
                    onClick: (function (extra) {
                        var callback = vnode.attrs.onCallback;
                        var body = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=additional_annotation]"));
                        var value = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, body, (function (prim) {
                                    return prim.trim();
                                  })), "");
                        return callback(value);
                      }),
                    label: "Save",
                    className: "btn btn-primary ml-1"
                  }
                ];
                return React.jsxs(Dialog.make, {
                            id: id,
                            actions: actions,
                            header: "Add an observation",
                            children: [
                              currentValue !== undefined && currentValue.length > 0 ? ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsx("p", {
                                              children: "Previous:",
                                              className: "m-0 text-tiny text-bold"
                                            }),
                                        ReactDOM.jsx("p", {
                                              children: currentValue,
                                              className: "text-tiny text-italic"
                                            })
                                      ]
                                    }) : Mithril.empty,
                              ReactDOM.jsx("textarea", {
                                    className: "form-input p-2",
                                    name: "additional_annotation",
                                    placeholder: "Observation here..."
                                  })
                            ]
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

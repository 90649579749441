// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Conditions from "./Conditions.js";
import * as AnswerValue from "../tree/AnswerValue.js";

function match(conds, value) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, Conditions.toArray(conds).find(function (c) {
                  return c === value;
                }));
}

function matchAlways(conditions) {
  return match(conditions, "Always");
}

function matchNever(conditions) {
  return match(conditions, "Never");
}

function matchRandomly(conditions) {
  return match(conditions, "Randomly");
}

function matchWhenBadlyRated(conditions) {
  return match(conditions, "WhenBadlyRated");
}

function matchWhenRectifyingFinding(conditions) {
  return match(conditions, "WhenRectifyingFinding");
}

var Common = {
  matchAlways: matchAlways,
  matchNever: matchNever,
  matchRandomly: matchRandomly,
  matchWhenBadlyRated: matchWhenBadlyRated,
  matchWhenRectifyingFinding: matchWhenRectifyingFinding
};

function _safeDiv(a, b) {
  if (b === 0.0) {
    return a;
  } else {
    return a / b;
  }
}

function match$1(question, currentAnswer, lastAnswer) {
  var match$2 = question.kind;
  var correctAnswer = typeof match$2 === "object" && match$2.NAME === "YesNoQuestion" ? match$2.VAL.correct_answer : true;
  var isOk = function (v) {
    return Prelude.default(AnswerValue.fmap(v, (function (v) {
                      if (typeof v !== "object") {
                        return true;
                      }
                      var variant = v.NAME;
                      if (variant === "Rating") {
                        return v.VAL[0] > 2;
                      } else if (variant === "YesNo") {
                        return v.VAL === correctAnswer;
                      } else if (variant === "OKNotOK") {
                        return v.VAL[0] === correctAnswer;
                      } else {
                        return true;
                      }
                    })), true);
  };
  var isBad = function (v) {
    return !isOk(v);
  };
  var conditions = Prelude.default(question.require_image_conditions, Conditions.Defaults.never);
  if (match(conditions, "Always")) {
    return Promise.resolve([
                true,
                false
              ]);
  }
  if (!isOk(currentAnswer) && match(conditions, "WhenBadlyRated")) {
    return Promise.resolve([
                true,
                false
              ]);
  }
  if (isOk(currentAnswer) && Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastAnswer, isBad), false) && match(conditions, "WhenRectifyingFinding")) {
    return Promise.resolve([
                true,
                false
              ]);
  }
  if (!match(conditions, "Randomly")) {
    return Promise.resolve([
                false,
                false
              ]);
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.newAnswers = "All", newrecord.vessels = {
                    TAG: "Get",
                    _0: question.vessel_id
                  }, newrecord)), (function (param) {
                var newAnswers = param.newAnswers;
                var picture_prompt_every_n_inspections = Prelude.$$Array.first(Prelude.$$Array.keepSome([Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(param.vessels), (function (param) {
                                  return param.picture_prompt_every_n_inspections;
                                }))]));
                var result;
                if (picture_prompt_every_n_inspections !== undefined) {
                  var totalnenewAnswers = newAnswers.length;
                  var picturePrompted = Curry._2(Prelude.$$Array.keep, newAnswers, (function (answer) {
                          return answer.picture_prompted;
                        })).length;
                  var linearProbability = _safeDiv(totalnenewAnswers / (1 + picturePrompted | 0), picture_prompt_every_n_inspections);
                  var probability;
                  if (linearProbability <= 1.0) {
                    var a = _safeDiv(1.0, (picture_prompt_every_n_inspections << 3));
                    var b = -1.0 - 2.0 * a;
                    probability = - a * (1.0 / (linearProbability + b)) + a;
                  } else {
                    probability = 1.0;
                  }
                  result = Math.random() <= probability;
                } else {
                  result = false;
                }
                return [
                        result,
                        result
                      ];
              }));
}

var Question = {
  _safeDiv: _safeDiv,
  match: match$1
};

async function match$2(procedure) {
  var conditions = Prelude.default(procedure.require_image_conditions, Conditions.Defaults.$$default(procedure.question_type));
  console.log("ConditionMatcher", conditions);
  if (match(conditions, "Always") || !match(conditions, "Never")) {
    return [
            true,
            false
          ];
  } else {
    return [
            false,
            false
          ];
  }
}

var RatingProcedure = {
  match: match$2
};

function _safeDiv$1(a, b) {
  if (b === 0.0) {
    return a;
  } else {
    return a / b;
  }
}

function match$3(procedure, lastAnswer, currentAnswer) {
  var conditions = Prelude.default(procedure.require_image_conditions, Conditions.Defaults.$$default(procedure.question_type));
  if (match(conditions, "Always")) {
    return Promise.resolve([
                true,
                false
              ]);
  }
  if (currentAnswer === "No" && match(conditions, "WhenBadlyRated")) {
    return Promise.resolve([
                true,
                false
              ]);
  }
  if (currentAnswer === "Yes" && Caml_obj.equal(lastAnswer, "No") && match(conditions, "WhenRectifyingFinding")) {
    return Promise.resolve([
                true,
                false
              ]);
  }
  if (!match(conditions, "Randomly")) {
    return Promise.resolve([
                false,
                false
              ]);
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.newInspections = "All", newrecord.sections = {
                    TAG: "Get",
                    _0: procedure.section_id
                  }, newrecord.vessels = {
                    TAG: "Get",
                    _0: procedure.vessel_id
                  }, newrecord)), (function (param) {
                var newInspections = param.newInspections;
                var picture_prompt_every_n_inspections = Prelude.$$Array.first(Prelude.$$Array.keepSome([
                          Curry._2(Prelude.OptionExported.$$Option.flatMap, Prelude.$$Array.first(param.sections), (function (param) {
                                  return param.picture_prompt_every_n_inspections;
                                })),
                          Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(param.vessels), (function (param) {
                                  return param.picture_prompt_every_n_inspections;
                                }))
                        ]));
                var result;
                if (picture_prompt_every_n_inspections !== undefined) {
                  var totalnenewAnswers = newInspections.length;
                  var picturePrompted = Curry._2(Prelude.$$Array.keep, newInspections, (function (param) {
                          return param.picture_prompted;
                        })).length;
                  var linearProbability = _safeDiv$1(totalnenewAnswers / (1 + picturePrompted | 0), picture_prompt_every_n_inspections);
                  var probability;
                  if (linearProbability <= 1.0) {
                    var a = _safeDiv$1(1.0, (picture_prompt_every_n_inspections << 3));
                    var b = -1.0 - 2.0 * a;
                    probability = - a * (1.0 / (linearProbability + b)) + a;
                  } else {
                    probability = 1.0;
                  }
                  result = Math.random() <= probability;
                } else {
                  result = false;
                }
                return [
                        result,
                        true
                      ];
              }));
}

var OkNotOkProcedure = {
  _safeDiv: _safeDiv$1,
  match: match$3
};

export {
  Common ,
  Question ,
  RatingProcedure ,
  OkNotOkProcedure ,
}
/* Query Not a pure module */

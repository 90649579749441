// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "./Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as KeepScrollLink from "./KeepScrollLink.js";
import * as State__Amounts from "../../state/State__Amounts.js";
import * as ApplicationModule from "../ApplicationModule.js";
import * as PageNavigationTheme from "./PageNavigationTheme.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var mod = vnode.attrs.mod;
                var disabled = Core__Option.getOr(vnode.attrs.disabled, false);
                var selected = Core__Option.getOr(vnode.attrs.selected, false);
                var count = ApplicationModule.getCurrentCount(mod);
                return React.jsx(KeepScrollLink.make, {
                            href: ApplicationModule.getUrl(mod),
                            className: Mithril.$$class([
                                  [
                                    "t-nav-btn tw-pt-2 tw-pb-1 tw-border-none",
                                    true
                                  ],
                                  [
                                    "tw-flex tw-flex-grow tw-align-center tw-justify-center",
                                    true
                                  ],
                                  [
                                    "tw-text-primary tw-bg-white",
                                    !selected
                                  ],
                                  [
                                    "tw-selected tw-text-white tw-bg-primary",
                                    selected
                                  ]
                                ], undefined),
                            disabled: disabled,
                            children: Caml_option.some(ReactDOM.jsxs("div", {
                                      children: [
                                        React.jsx(Feather.make, {
                                              icon: ApplicationModule.getIcon(mod)
                                            }),
                                        React.jsx(Conditional.make, {
                                              cond: count > 0,
                                              children: ReactDOM.jsx("span", {
                                                    className: Mithril.$$class([
                                                          [
                                                            "tw-badge tw-absolute tw-right-[-0.8rem] tw-top-[-0.1rem]",
                                                            true
                                                          ],
                                                          [
                                                            "tw-whitespace-nowrap",
                                                            true
                                                          ]
                                                        ], undefined),
                                                    data: {
                                                      badge: count.toString()
                                                    }
                                                  })
                                            })
                                      ],
                                      className: "tw-relative tw-flex tw-align-center tw-justify-center"
                                    }))
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component(), (function (param) {
                        State__Amounts.refresh();
                      })), (function (param) {
                    State__Amounts.refresh();
                  })), (function (vnode) {
                var mod = Core__Option.getOr(vnode.attrs.mod, "home");
                var disabled = Core__Option.getOr(vnode.attrs.disabled, false);
                var fixed = Core__Option.getOr(vnode.attrs.fixed, false);
                var theme = Core__Option.getOr(vnode.attrs.theme, PageNavigationTheme.default);
                var newrecord = Caml_obj.obj_dup(PageNavigationTheme.mergeProps(theme, "footer", {
                          className: Mithril.$$class([
                                [
                                  "t-app-footer",
                                  true
                                ],
                                [
                                  "tw-flex tw-z-999 tw-bg-white tw-shadow-footer",
                                  true
                                ],
                                [
                                  "tw-w-full tw-h-footer tw-min-h-footer tw-max-h-footer",
                                  true
                                ],
                                [
                                  "tw-fixed tw-bottom-0",
                                  fixed
                                ]
                              ], undefined)
                        }));
                var newrecord$1 = Caml_obj.obj_dup(PageNavigationTheme.mergeProps(theme, "footer", {
                          className: Mithril.$$class([
                                [
                                  "tw-flex tw-flex-grow tw-flex-row tw-gap-0 tw-align-center tw-justify-between",
                                  true
                                ],
                                [
                                  "tw-cursor-default tw-opacity-50 tw-pointer-events-none",
                                  disabled
                                ]
                              ], undefined)
                        }));
                newrecord.children = ReactDOM.jsxs("nav", (newrecord$1.children = [
                        React.jsx(make, {
                              disabled: disabled,
                              selected: mod === "home",
                              mod: "home"
                            }),
                        React.jsx(make, {
                              disabled: disabled,
                              selected: mod === "planner",
                              mod: "planner"
                            }),
                        React.jsx(make, {
                              disabled: disabled,
                              selected: mod === "findings",
                              mod: "findings"
                            }),
                        React.jsx(make, {
                              disabled: disabled,
                              selected: mod === "notifications",
                              mod: "notifications"
                            }),
                        React.jsx(make, {
                              disabled: disabled,
                              selected: mod === "upload",
                              mod: "upload"
                            })
                      ], newrecord$1));
                return ReactDOM.jsx("footer", newrecord);
              }));
}

export {
  make$1 as make,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as MarkdownDocumentId from "./MarkdownDocumentId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function getImages(documents) {
  return Prelude.$$Array.flatMap(documents, (function (d) {
                  return d.attachments;
                })).map(function (i) {
              return {
                      TAG: "Cloud",
                      _0: i
                    };
            });
}

function normalize(doc) {
  return {
          id: doc.id,
          title: doc.title,
          contents: doc.contents,
          attachments: doc.attachments.map(function (a) {
                return a.id;
              })
        };
}

var Normalizer = {
  normalize: normalize
};

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  getImages ,
  Normalizer ,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing updates for new comments";
}

function $$process(newComment, param) {
  return {
          TAG: "Update",
          _0: {
            id: newComment.id,
            vessel_id: newComment.vessel_id,
            replies_to_id: newComment.replies_to_id,
            body: newComment.body,
            author: newComment.author,
            object_app: "tasks",
            object_type: "deficiency",
            object_id: newComment.object_id.VAL,
            timestamp: newComment.timestamp
          }
        };
}

var NewCommentRewriter = InitFeedback.MakeRewriter({
      storeName: "newComments",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing updates for new comments";
}

function migrate(_db, transaction) {
  return NewCommentRewriter.rewrite(transaction, undefined);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* NewCommentRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as ButtonFilterModal from "../../common/components/ButtonFilterModal.js";
import * as Sire2NominalFilters from "../Sire2NominalFilters.js";
import * as FilterModalContainer from "../../common/components/FilterModalContainer.js";

var choices = [
  {
    name: "Hardware",
    value: "Hardware",
    icon: "hardware"
  },
  {
    name: "Human",
    value: "Human",
    icon: "hr"
  },
  {
    name: "Process",
    value: "Process",
    icon: "process"
  }
];

var cmp = Caml_obj.compare;

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function isExpanded(vnode) {
  var match = vnode.state.data;
  if (match.TAG === "Collapsed") {
    return false;
  } else {
    return true;
  }
}

function toggleValue(selection, value) {
  if (Belt_Set.has(selection, value)) {
    return Belt_Set.remove(selection, value);
  } else {
    return Belt_Set.add(selection, value);
  }
}

function make(v) {
  var selection = Belt_Set.fromArray(v.selected, Comparator);
  return Mithril.view(Mithril.onupdate(Mithril.component({
                      TAG: "Collapsed",
                      _0: selection
                    }), (function (vnode) {
                    var selected = Belt_Set.fromArray(vnode.attrs.selected, Comparator);
                    var s = vnode.state.data;
                    if (s.TAG === "Collapsed") {
                      if (!Belt_Set.eq(s._0, selected)) {
                        return Mithril.setState(vnode, {
                                    TAG: "Collapsed",
                                    _0: selected
                                  });
                      } else {
                        return ;
                      }
                    } else if (!Belt_Set.eq(s._0, selected)) {
                      return Mithril.setState(vnode, {
                                  TAG: "Expanded",
                                  _0: selected
                                });
                    } else {
                      return ;
                    }
                  })), (function (vnode) {
                var choices$1 = Core__Option.getOr(vnode.attrs.choices, choices);
                var match = vnode.state.data;
                var s = match._0;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("label", {
                                            children: vnode.attrs.name,
                                            className: "tile-title h5"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: React.jsx(Feather.make, {
                                                  icon: isExpanded(vnode) ? "chevron_down" : "chevron_right",
                                                  className: "tw-align-middle tw-text-primary hover:tw-cursor-pointer",
                                                  style: "margin-left: 0.2rem;"
                                                })
                                          })
                                    ],
                                    className: "tw-flex tw-justify-between tw-border-r tw-pb-2 tw-my-1",
                                    onClick: (function (param) {
                                        var p = vnode.state.data;
                                        var tmp;
                                        tmp = p.TAG === "Collapsed" ? ({
                                              TAG: "Expanded",
                                              _0: p._0
                                            }) : ({
                                              TAG: "Collapsed",
                                              _0: p._0
                                            });
                                        Mithril.setState(vnode, tmp);
                                      })
                                  }),
                              isExpanded(vnode) ? ReactDOM.jsx("div", {
                                      children: choices$1.map(function (opt) {
                                            var icon = opt.icon;
                                            var active = Belt_Set.has(s, opt.value);
                                            return ReactDOM.jsx("div", {
                                                        children: React.jsx(ButtonFilterModal.make, {
                                                              text: opt.name,
                                                              onClick: (function (param) {
                                                                  var value = opt.value;
                                                                  var s = vnode.state.data;
                                                                  if (s.TAG === "Collapsed") {
                                                                    return Mithril.setState(vnode, {
                                                                                TAG: "Collapsed",
                                                                                _0: toggleValue(s._0, value)
                                                                              });
                                                                  }
                                                                  var s$1 = toggleValue(s._0, value);
                                                                  var onChange = vnode.attrs.onChange;
                                                                  Mithril.setState(vnode, {
                                                                        TAG: "Expanded",
                                                                        _0: s$1
                                                                      });
                                                                  onChange(Belt_Set.toArray(s$1));
                                                                }),
                                                              icon: icon,
                                                              theme: active ? ButtonFilterModal.Theme.on : ButtonFilterModal.Theme.off
                                                            }),
                                                        className: "tw-flex tw-flex-row"
                                                      });
                                          }),
                                      className: "tw-flex tw-flex-wrap tw-gap-4 tw-pt-1 tw-pb-4"
                                    }) : Mithril.empty
                            ],
                            className: "tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded tw-shadow-md tw-px-4"
                          });
              }));
}

var choices$1 = [
  {
    name: "High / Core",
    value: "high"
  },
  {
    name: "Medium / Rotational I",
    value: "medium"
  },
  {
    name: "Low / Rotational II",
    value: "low"
  }
];

var cmp$1 = Caml_obj.compare;

var Comparator$1 = Belt_Id.MakeComparableU({
      cmp: cmp$1
    });

function isExpanded$1(vnode) {
  var match = vnode.state.data;
  if (match.TAG === "Collapsed") {
    return false;
  } else {
    return true;
  }
}

function toggleValue$1(selection, value) {
  if (Belt_Set.has(selection, value)) {
    return Belt_Set.remove(selection, value);
  } else {
    return Belt_Set.add(selection, value);
  }
}

function make$1(v) {
  var selection = Belt_Set.fromArray(v.selected, Comparator$1);
  return Mithril.view(Mithril.onupdate(Mithril.component({
                      TAG: "Collapsed",
                      _0: selection
                    }), (function (vnode) {
                    var selected = Belt_Set.fromArray(vnode.attrs.selected, Comparator$1);
                    var s = vnode.state.data;
                    if (s.TAG === "Collapsed") {
                      if (!Belt_Set.eq(s._0, selected)) {
                        return Mithril.setState(vnode, {
                                    TAG: "Collapsed",
                                    _0: selected
                                  });
                      } else {
                        return ;
                      }
                    } else if (!Belt_Set.eq(s._0, selected)) {
                      return Mithril.setState(vnode, {
                                  TAG: "Expanded",
                                  _0: selected
                                });
                    } else {
                      return ;
                    }
                  })), (function (vnode) {
                var choices$2 = Core__Option.getOr(vnode.attrs.choices, choices$1);
                var match = vnode.state.data;
                var s = match._0;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("label", {
                                            children: vnode.attrs.name,
                                            className: "tile-title h5"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: React.jsx(Feather.make, {
                                                  icon: isExpanded$1(vnode) ? "chevron_down" : "chevron_right",
                                                  className: "tw-align-middle tw-text-primary hover:tw-cursor-pointer",
                                                  style: "margin-left: 0.2rem;"
                                                })
                                          })
                                    ],
                                    className: "tw-flex tw-justify-between tw-border-r tw-pb-2 tw-my-1",
                                    onClick: (function (param) {
                                        var p = vnode.state.data;
                                        var tmp;
                                        tmp = p.TAG === "Collapsed" ? ({
                                              TAG: "Expanded",
                                              _0: p._0
                                            }) : ({
                                              TAG: "Collapsed",
                                              _0: p._0
                                            });
                                        Mithril.setState(vnode, tmp);
                                      })
                                  }),
                              isExpanded$1(vnode) ? ReactDOM.jsx("div", {
                                      children: choices$2.map(function (opt) {
                                            var icon = opt.icon;
                                            var active = Belt_Set.has(s, opt.value);
                                            return ReactDOM.jsx("div", {
                                                        children: React.jsx(ButtonFilterModal.make, {
                                                              text: opt.name,
                                                              onClick: (function (param) {
                                                                  var value = opt.value;
                                                                  var s = vnode.state.data;
                                                                  if (s.TAG === "Collapsed") {
                                                                    return Mithril.setState(vnode, {
                                                                                TAG: "Collapsed",
                                                                                _0: toggleValue$1(s._0, value)
                                                                              });
                                                                  }
                                                                  var s$1 = toggleValue$1(s._0, value);
                                                                  var onChange = vnode.attrs.onChange;
                                                                  Mithril.setState(vnode, {
                                                                        TAG: "Expanded",
                                                                        _0: s$1
                                                                      });
                                                                  onChange(Belt_Set.toArray(s$1));
                                                                }),
                                                              icon: icon,
                                                              theme: active ? ButtonFilterModal.Theme.on : ButtonFilterModal.Theme.off
                                                            }),
                                                        className: "tw-flex tw-flex-row"
                                                      });
                                          }),
                                      className: "tw-flex tw-flex-wrap tw-gap-4 tw-pt-1 tw-pb-4"
                                    }) : Mithril.empty
                            ],
                            className: "tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded tw-shadow-md tw-px-4"
                          });
              }));
}

var choices$2 = [
  {
    name: "Answered",
    value: "Answered"
  },
  {
    name: "Unanswered",
    value: "Unanswered"
  }
];

var cmp$2 = Caml_obj.compare;

var Comparator$2 = Belt_Id.MakeComparableU({
      cmp: cmp$2
    });

function isExpanded$2(vnode) {
  var match = vnode.state.data;
  if (match.TAG === "Collapsed") {
    return false;
  } else {
    return true;
  }
}

function toggleValue$2(selection, value) {
  if (Belt_Set.has(selection, value)) {
    return Belt_Set.remove(selection, value);
  } else {
    return Belt_Set.add(selection, value);
  }
}

function make$2(v) {
  var selection = Belt_Set.fromArray(v.selected, Comparator$2);
  return Mithril.view(Mithril.onupdate(Mithril.component({
                      TAG: "Collapsed",
                      _0: selection
                    }), (function (vnode) {
                    var selected = Belt_Set.fromArray(vnode.attrs.selected, Comparator$2);
                    var s = vnode.state.data;
                    if (s.TAG === "Collapsed") {
                      if (!Belt_Set.eq(s._0, selected)) {
                        return Mithril.setState(vnode, {
                                    TAG: "Collapsed",
                                    _0: selected
                                  });
                      } else {
                        return ;
                      }
                    } else if (!Belt_Set.eq(s._0, selected)) {
                      return Mithril.setState(vnode, {
                                  TAG: "Expanded",
                                  _0: selected
                                });
                    } else {
                      return ;
                    }
                  })), (function (vnode) {
                var choices$3 = Core__Option.getOr(vnode.attrs.choices, choices$2);
                var match = vnode.state.data;
                var s = match._0;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("label", {
                                            children: vnode.attrs.name,
                                            className: "tile-title h5"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: React.jsx(Feather.make, {
                                                  icon: isExpanded$2(vnode) ? "chevron_down" : "chevron_right",
                                                  className: "tw-align-middle tw-text-primary hover:tw-cursor-pointer",
                                                  style: "margin-left: 0.2rem;"
                                                })
                                          })
                                    ],
                                    className: "tw-flex tw-justify-between tw-border-r tw-pb-2 tw-my-1",
                                    onClick: (function (param) {
                                        var p = vnode.state.data;
                                        var tmp;
                                        tmp = p.TAG === "Collapsed" ? ({
                                              TAG: "Expanded",
                                              _0: p._0
                                            }) : ({
                                              TAG: "Collapsed",
                                              _0: p._0
                                            });
                                        Mithril.setState(vnode, tmp);
                                      })
                                  }),
                              isExpanded$2(vnode) ? ReactDOM.jsx("div", {
                                      children: choices$3.map(function (opt) {
                                            var icon = opt.icon;
                                            var active = Belt_Set.has(s, opt.value);
                                            return ReactDOM.jsx("div", {
                                                        children: React.jsx(ButtonFilterModal.make, {
                                                              text: opt.name,
                                                              onClick: (function (param) {
                                                                  var value = opt.value;
                                                                  var s = vnode.state.data;
                                                                  if (s.TAG === "Collapsed") {
                                                                    return Mithril.setState(vnode, {
                                                                                TAG: "Collapsed",
                                                                                _0: toggleValue$2(s._0, value)
                                                                              });
                                                                  }
                                                                  var s$1 = toggleValue$2(s._0, value);
                                                                  var onChange = vnode.attrs.onChange;
                                                                  Mithril.setState(vnode, {
                                                                        TAG: "Expanded",
                                                                        _0: s$1
                                                                      });
                                                                  onChange(Belt_Set.toArray(s$1));
                                                                }),
                                                              icon: icon,
                                                              theme: active ? ButtonFilterModal.Theme.on : ButtonFilterModal.Theme.off
                                                            }),
                                                        className: "tw-flex tw-flex-row"
                                                      });
                                          }),
                                      className: "tw-flex tw-flex-wrap tw-gap-4 tw-pt-1 tw-pb-4"
                                    }) : Mithril.empty
                            ],
                            className: "tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded tw-shadow-md tw-px-4"
                          });
              }));
}

var choices$3 = UserProfile.Ranks.crew.map(function (value) {
      return {
              name: UserProfile.Rank.toString(value),
              value: value
            };
    });

var cmp$3 = Caml_obj.compare;

var Comparator$3 = Belt_Id.MakeComparableU({
      cmp: cmp$3
    });

function isExpanded$3(vnode) {
  var match = vnode.state.data;
  if (match.TAG === "Collapsed") {
    return false;
  } else {
    return true;
  }
}

function toggleValue$3(selection, value) {
  if (Belt_Set.has(selection, value)) {
    return Belt_Set.remove(selection, value);
  } else {
    return Belt_Set.add(selection, value);
  }
}

function make$3(v) {
  var selection = Belt_Set.fromArray(v.selected, Comparator$3);
  return Mithril.view(Mithril.onupdate(Mithril.component({
                      TAG: "Collapsed",
                      _0: selection
                    }), (function (vnode) {
                    var selected = Belt_Set.fromArray(vnode.attrs.selected, Comparator$3);
                    var s = vnode.state.data;
                    if (s.TAG === "Collapsed") {
                      if (!Belt_Set.eq(s._0, selected)) {
                        return Mithril.setState(vnode, {
                                    TAG: "Collapsed",
                                    _0: selected
                                  });
                      } else {
                        return ;
                      }
                    } else if (!Belt_Set.eq(s._0, selected)) {
                      return Mithril.setState(vnode, {
                                  TAG: "Expanded",
                                  _0: selected
                                });
                    } else {
                      return ;
                    }
                  })), (function (vnode) {
                var choices$4 = Core__Option.getOr(vnode.attrs.choices, choices$3);
                var match = vnode.state.data;
                var s = match._0;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("label", {
                                            children: vnode.attrs.name,
                                            className: "tile-title h5"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: React.jsx(Feather.make, {
                                                  icon: isExpanded$3(vnode) ? "chevron_down" : "chevron_right",
                                                  className: "tw-align-middle tw-text-primary hover:tw-cursor-pointer",
                                                  style: "margin-left: 0.2rem;"
                                                })
                                          })
                                    ],
                                    className: "tw-flex tw-justify-between tw-border-r tw-pb-2 tw-my-1",
                                    onClick: (function (param) {
                                        var p = vnode.state.data;
                                        var tmp;
                                        tmp = p.TAG === "Collapsed" ? ({
                                              TAG: "Expanded",
                                              _0: p._0
                                            }) : ({
                                              TAG: "Collapsed",
                                              _0: p._0
                                            });
                                        Mithril.setState(vnode, tmp);
                                      })
                                  }),
                              isExpanded$3(vnode) ? ReactDOM.jsx("div", {
                                      children: choices$4.map(function (opt) {
                                            var icon = opt.icon;
                                            var active = Belt_Set.has(s, opt.value);
                                            return ReactDOM.jsx("div", {
                                                        children: React.jsx(ButtonFilterModal.make, {
                                                              text: opt.name,
                                                              onClick: (function (param) {
                                                                  var value = opt.value;
                                                                  var s = vnode.state.data;
                                                                  if (s.TAG === "Collapsed") {
                                                                    return Mithril.setState(vnode, {
                                                                                TAG: "Collapsed",
                                                                                _0: toggleValue$3(s._0, value)
                                                                              });
                                                                  }
                                                                  var s$1 = toggleValue$3(s._0, value);
                                                                  var onChange = vnode.attrs.onChange;
                                                                  Mithril.setState(vnode, {
                                                                        TAG: "Expanded",
                                                                        _0: s$1
                                                                      });
                                                                  onChange(Belt_Set.toArray(s$1));
                                                                }),
                                                              icon: icon,
                                                              theme: active ? ButtonFilterModal.Theme.on : ButtonFilterModal.Theme.off
                                                            }),
                                                        className: "tw-flex tw-flex-row"
                                                      });
                                          }),
                                      className: "tw-flex tw-flex-wrap tw-gap-4 tw-pt-1 tw-pb-4"
                                    }) : Mithril.empty
                            ],
                            className: "tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded tw-shadow-md tw-px-4"
                          });
              }));
}

function make$4(v) {
  return Mithril.view(Mithril.component(v.filters), (function (vnode) {
                var match = vnode.attrs;
                var onClose = match.onClose;
                var onClear = match.onClear;
                var onChange = match.onChange;
                var filters = vnode.state.data;
                var onClear$1 = function (param) {
                  Mithril.setState(vnode, Sire2NominalFilters.Defaults.all());
                  onClear();
                };
                var onClose$1 = function (param) {
                  onClose();
                };
                var onApply = function (param) {
                  onClose();
                  onChange(vnode.state.data);
                };
                var match$1 = State__Memory.Current.get();
                var isTsiProfile = Core__Option.getOr(Core__Option.map(match$1.profile, UserProfile.isTsiProfile), false);
                return React.jsx(FilterModalContainer.make, {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    React.jsx(make, {
                                          name: "Area",
                                          selected: filters.areas,
                                          onChange: (function (areas) {
                                              Mithril.setState(vnode, {
                                                    areas: areas,
                                                    risks: filters.risks,
                                                    answered: filters.answered,
                                                    ranks: filters.ranks
                                                  });
                                            })
                                        }),
                                    React.jsx(make$1, {
                                          name: "Type/Risk",
                                          selected: filters.risks,
                                          onChange: (function (risks) {
                                              Mithril.setState(vnode, {
                                                    areas: filters.areas,
                                                    risks: risks,
                                                    answered: filters.answered,
                                                    ranks: filters.ranks
                                                  });
                                            })
                                        }),
                                    React.jsx(make$2, {
                                          name: "Question Answered",
                                          selected: filters.answered,
                                          onChange: (function (answered) {
                                              Mithril.setState(vnode, {
                                                    areas: filters.areas,
                                                    risks: filters.risks,
                                                    answered: answered,
                                                    ranks: filters.ranks
                                                  });
                                            })
                                        }),
                                    isTsiProfile ? React.jsx(make$3, {
                                            name: "Ranks",
                                            selected: filters.ranks,
                                            onChange: (function (ranks) {
                                                Mithril.setState(vnode, {
                                                      areas: filters.areas,
                                                      risks: filters.risks,
                                                      answered: filters.answered,
                                                      ranks: ranks
                                                    });
                                              })
                                          }) : Mithril.empty
                                  ],
                                  className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                }),
                            showModal: true,
                            textApplyButton: "Apply",
                            onClear: onClear$1,
                            onClose: onClose$1,
                            onApplyFilter: onApply
                          });
              }));
}

export {
  make$4 as make,
}
/* Comparator Not a pure module */

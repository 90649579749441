// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";

function make(param) {
  return function (param, transaction) {
    IDB__Migration__Store.createSimpleIndex(transaction.objectStore("newComments"), "vessel_id");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Store Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as R4SChecklistId from "../../risk4sea/R4SChecklistId.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";
import * as R4SChecklistItemAPI from "../endpoints/R4SChecklistItemAPI.js";

function convertChecklist(state, item) {
  var r4schecklists = AppSchema.R4SChecklist.map(state);
  var checklist = R4SChecklistId.$$Map.get(r4schecklists, item.checklist_id);
  if (checklist !== undefined) {
    checklist.items.push({
          code: item.code,
          description: item.description,
          detention_type: item.detention_type,
          order: item.order,
          level_id: item.level_id,
          level_path: item.level_path
        });
    AppSchema.R4SChecklist.update(state, checklist);
  } else {
    console.log("ServerSyncR4SChecklistsItem", "Checklist id: " + item.checklist_id + " not found.");
  }
  return state;
}

function convertItems(state, r4schecklists) {
  return Curry._3(Prelude.$$Array.fold, r4schecklists, state, convertChecklist);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(Prelude.PromisedResult.map(R4SChecklistItemAPI.getAll(undefined, {
                      checklist__vessel__id__in: vessels.join(",")
                    }, undefined, user), (function (extra) {
                    return convertItems($$final, extra);
                  })), (function ($$final) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var ReadServerState = {
  convertChecklist: convertChecklist,
  convertItems: convertItems,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.r4schecklists = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.r4schecklists = AppSchema.R4SChecklist.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncR4SChecklistsItem" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.UserProfiles];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncR4SChecklistsItem",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */

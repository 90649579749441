// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

function message(param, param$1) {
  return "Optimizing access to comments in findings";
}

function $$process(comment, param) {
  return {
          TAG: "Update",
          _0: {
            id: comment.id,
            vessel_id: comment.vessel_id,
            replies_to_id: comment.replies_to_id,
            body: comment.body,
            author: comment.author,
            object_id: {
              NAME: "Finding",
              VAL: comment.object_id
            },
            timestamp: comment.timestamp
          }
        };
}

var Comments = InitFeedback.MakeRewriter({
      storeName: "newComments",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Optimizing access to comments in findings";
}

function migrate(param, transaction) {
  transaction.objectStore("newComments").deleteIndex("object_id");
  return Prelude.PromisedResult.map(Comments.rewrite(transaction, undefined), (function () {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as State from "../../state/State.js";
import * as Vessel from "../Vessel.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../VesselId.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as StillLoading from "../../common/views/StillLoading.js";
import R4sSvg from "/images/r4s.svg";
import * as Browser from "@sentry/browser";
import SireSvg from "/images/sire.svg";
import SafetySvg from "/images/safety.svg";
import * as ProtectedViewMaker from "../../accounts/views/ProtectedViewMaker.js";
import Logo512Png from "/images/logo512.png";
import ChecklistsSvg from "/images/checklists.svg";
import StructuralSvg from "/images/structural.svg";
import CompanyFormsSvg from "/images/company-forms.svg";

function title(state) {
  if (typeof state !== "object") {
    return "";
  }
  return state.VAL.vessel.name;
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                return React.jsx(Mithril.Link.make, {
                            href: Curry._2(Prelude.OptionExported.$$Option.getWithDefault, vnode.attrs.href, ""),
                            className: Mithril.$$class(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.className, (function (c) {
                                            return [[
                                                      c,
                                                      true
                                                    ]];
                                          })), []), undefined),
                            children: Caml_option.some(ReactDOM.jsx("div", {
                                      children: [
                                        Curry._3(Prelude.OptionExported.$$Option.mapWithDefault, vnode.attrs.image, Mithril.empty, (function (image) {
                                                if (image.TAG === "Static") {
                                                  return ReactDOM.jsx("img", {
                                                              className: "tw-block tw-w-full tw-rounded-lg",
                                                              src: image._0
                                                            });
                                                } else {
                                                  return React.jsx(LocalImage.Resource.make, {
                                                              resource: image._0,
                                                              maxRetries: 100,
                                                              className: "tw-block tw-w-full tw-rounded-lg",
                                                              children: Caml_option.some(ReactDOM.jsx("div", {
                                                                        children: ReactDOM.jsx("div", {
                                                                              className: "tw-inline-block tw-h-5 tw-w-5 tw-animate-spin tw-rounded-full tw-border-2 tw-border-solid tw-border-current tw-border-e-transparent tw-text-primary"
                                                                            }),
                                                                        className: "tw-grow tw-flex tw-items-center tw-justify-center"
                                                                      }))
                                                            });
                                                }
                                              })),
                                        ReactDOM.jsx("div", {
                                              className: "tw-flex-grow"
                                            }),
                                        ReactDOM.jsx("div", {
                                              children: vnode.attrs.title,
                                              className: "tw-py-1 tw-text-center tw-text-sm tw-font-medium"
                                            })
                                      ],
                                      className: "tw-shadow-lg tw-rounded-lg tw-h-full tw-flex tw-flex-col"
                                    }))
                          });
              }));
}

var VesselMenuCard = {
  make: make
};

function isEmpty(vessel) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vessel.modules, (function (param) {
                    if (!param.checklist && !param.structural && !param.safety && !param.companyForms && !param.sireVettingPeriods) {
                      return !param.pscEnabled;
                    } else {
                      return false;
                    }
                  })), true);
}

async function load(vesselId) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.vessels = {
          TAG: "Get",
          _0: vesselId
        }, newrecord));
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.vessels), (function (vessel) {
                    var migrated = vessel.migrated;
                    var match = Prelude.default(vessel.modules, Vessel.Defaults.Modules.$$null);
                    var sire = match.sire;
                    var checkListUrl = match.checklist ? (
                        migrated ? URLs.vesselTreeKind(vessel.id, "checklist_part") : URLs.vesselSections(vessel.id, {
                                NAME: "ComponentType",
                                VAL: "checklist"
                              }, undefined, undefined)
                      ) : undefined;
                    var structuralComponentsUrl = match.structural ? (
                        migrated ? URLs.vesselTreeKind(vessel.id, "structural_component") : URLs.vesselSections(vessel.id, {
                                NAME: "ComponentType",
                                VAL: "structural"
                              }, undefined, undefined)
                      ) : undefined;
                    var safetyComponentsUrl = match.safety ? (
                        migrated ? URLs.vesselTreeKind(vessel.id, "safety_component") : URLs.vesselSections(vessel.id, {
                                NAME: "ComponentType",
                                VAL: "safety"
                              }, undefined, undefined)
                      ) : undefined;
                    var companyFormsUrl = match.companyForms ? (
                        migrated ? URLs.vesselTreeKind(vessel.id, "company_form") : URLs.vesselCompanyForms(vessel.id, undefined, undefined)
                      ) : undefined;
                    var risk4seaChecklistUrl = match.pscEnabled ? URLs.r4sListUrl(vessel.id) : undefined;
                    var rootNodeUrl = sire && match.sireVettingPeriods ? URLs.vesselSIRE(vessel.id) : undefined;
                    return {
                            TAG: "Ok",
                            _0: {
                              vessel: vessel,
                              checkListUrl: checkListUrl,
                              structuralComponentsUrl: structuralComponentsUrl,
                              safetyComponentsUrl: safetyComponentsUrl,
                              companyFormsUrl: companyFormsUrl,
                              rootNodeUrl: rootNodeUrl,
                              tsiModuleUrl: undefined,
                              risk4seaChecklistUrl: risk4seaChecklistUrl,
                              hasSire: sire,
                              empty: isEmpty(vessel)
                            }
                          };
                  })), {
              TAG: "Error",
              _0: undefined
            });
}

async function effect(vnode) {
  var nosync = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("nosync"), (function (param) {
              return true;
            })), false);
  var match = vnode.state.data;
  if (typeof match === "object") {
    if (match.NAME !== "RequestedSync") {
      return ;
    }
    var payload = match.VAL;
    var nosync$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("nosync"), (function (param) {
                return true;
              })), false);
    if (nosync$1) {
      return Mithril.setState(vnode, {
                  NAME: "Synced",
                  VAL: {
                    vessel: payload.vessel,
                    checkListUrl: payload.checkListUrl,
                    structuralComponentsUrl: payload.structuralComponentsUrl,
                    safetyComponentsUrl: payload.safetyComponentsUrl,
                    companyFormsUrl: payload.companyFormsUrl,
                    rootNodeUrl: payload.rootNodeUrl,
                    tsiModuleUrl: payload.tsiModuleUrl,
                    risk4seaChecklistUrl: payload.risk4seaChecklistUrl,
                    hasSire: payload.hasSire,
                    empty: isEmpty(payload.vessel)
                  }
                });
    }
    vnode.state.data = {
      NAME: "Syncing",
      VAL: payload
    };
    var payload$1 = await Prelude.PromisedResult.bind(Prelude.PromisedResult.mapError(Prelude.PromisedResult.mapError(State.FetchVessels.$$fetch([payload.vessel.id]), (function (prim) {
                    Browser.captureException(prim);
                  })), (function () {
                
              })), (function (param) {
            return load(payload.vessel.id);
          }));
    if (payload$1.TAG !== "Ok") {
      if (isEmpty(payload.vessel)) {
        return Mithril.setState(vnode, "SyncError");
      } else {
        return Mithril.setState(vnode, {
                    NAME: "Offline",
                    VAL: payload
                  });
      }
    }
    var payload$2 = payload$1._0;
    return Mithril.setState(vnode, {
                NAME: "Synced",
                VAL: {
                  vessel: payload$2.vessel,
                  checkListUrl: payload$2.checkListUrl,
                  structuralComponentsUrl: payload$2.structuralComponentsUrl,
                  safetyComponentsUrl: payload$2.safetyComponentsUrl,
                  companyFormsUrl: payload$2.companyFormsUrl,
                  rootNodeUrl: payload$2.rootNodeUrl,
                  tsiModuleUrl: payload$2.tsiModuleUrl,
                  risk4seaChecklistUrl: payload$2.risk4seaChecklistUrl,
                  hasSire: payload$2.hasSire,
                  empty: isEmpty(payload$2.vessel)
                }
              });
  }
  if (match === "NotFound" || match === "SyncError") {
    return ;
  }
  var vesselId = VesselId.Route.param("vesselId");
  var payload$3 = await load(vesselId);
  if (payload$3.TAG !== "Ok") {
    return Mithril.setState(vnode, "NotFound");
  }
  var payload$4 = payload$3._0;
  if (nosync) {
    return Mithril.setState(vnode, {
                NAME: "Synced",
                VAL: {
                  vessel: payload$4.vessel,
                  checkListUrl: payload$4.checkListUrl,
                  structuralComponentsUrl: payload$4.structuralComponentsUrl,
                  safetyComponentsUrl: payload$4.safetyComponentsUrl,
                  companyFormsUrl: payload$4.companyFormsUrl,
                  rootNodeUrl: payload$4.rootNodeUrl,
                  tsiModuleUrl: payload$4.tsiModuleUrl,
                  risk4seaChecklistUrl: payload$4.risk4seaChecklistUrl,
                  hasSire: payload$4.hasSire,
                  empty: isEmpty(payload$4.vessel)
                }
              });
  } else {
    return Mithril.setState(vnode, {
                NAME: "RequestedSync",
                VAL: {
                  vessel: payload$4.vessel,
                  checkListUrl: payload$4.checkListUrl,
                  structuralComponentsUrl: payload$4.structuralComponentsUrl,
                  safetyComponentsUrl: payload$4.safetyComponentsUrl,
                  companyFormsUrl: payload$4.companyFormsUrl,
                  rootNodeUrl: payload$4.rootNodeUrl,
                  tsiModuleUrl: payload$4.tsiModuleUrl,
                  risk4seaChecklistUrl: payload$4.risk4seaChecklistUrl,
                  hasSire: payload$4.hasSire,
                  empty: isEmpty(payload$4.vessel)
                }
              });
  }
}

var Private = {
  isEmpty: isEmpty,
  load: load,
  effect: effect
};

function make$1(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                        console.log("Vessel_Dashboard", {
                              profile: vnode.attrs.profile
                            });
                        effect(vnode);
                      })), (function (vnode) {
                    effect(vnode);
                  })), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                var exit = 0;
                var vessel;
                var checkListUrl;
                var structuralComponentsUrl;
                var safetyComponentsUrl;
                var companyFormsUrl;
                var rootNodeUrl;
                var tsiModuleUrl;
                var risk4seaChecklistUrl;
                if (typeof match === "object") {
                  var variant = match.NAME;
                  if (variant === "RequestedSync") {
                    var match$1 = match.VAL;
                    if (match$1.empty) {
                      tmp = React.jsx(StillLoading.make, {});
                    } else {
                      vessel = match$1.vessel;
                      checkListUrl = match$1.checkListUrl;
                      structuralComponentsUrl = match$1.structuralComponentsUrl;
                      safetyComponentsUrl = match$1.safetyComponentsUrl;
                      companyFormsUrl = match$1.companyFormsUrl;
                      rootNodeUrl = match$1.rootNodeUrl;
                      tsiModuleUrl = match$1.tsiModuleUrl;
                      risk4seaChecklistUrl = match$1.risk4seaChecklistUrl;
                      exit = 2;
                    }
                  } else if (variant === "Offline") {
                    var match$2 = match.VAL;
                    if (match$2.empty) {
                      exit = 1;
                    } else {
                      vessel = match$2.vessel;
                      checkListUrl = match$2.checkListUrl;
                      structuralComponentsUrl = match$2.structuralComponentsUrl;
                      safetyComponentsUrl = match$2.safetyComponentsUrl;
                      companyFormsUrl = match$2.companyFormsUrl;
                      rootNodeUrl = match$2.rootNodeUrl;
                      tsiModuleUrl = match$2.tsiModuleUrl;
                      risk4seaChecklistUrl = match$2.risk4seaChecklistUrl;
                      exit = 2;
                    }
                  } else if (variant === "Synced") {
                    var match$3 = match.VAL;
                    var exit$1 = 0;
                    if (match$3.hasSire) {
                      if (match$3.empty) {
                        tmp = ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsxs("h2", {
                                      children: [
                                        React.jsx(Feather.make, {
                                              icon: "alert_triangle",
                                              className: "mr-2",
                                              style: "vertical-align: bottom",
                                              size: 36
                                            }),
                                        "SIRE 2.0 vetting periods not yet configured"
                                      ],
                                      className: "text-center text-error"
                                    }),
                                ReactDOM.jsx("p", {
                                      children: "Our records indicate this vessel is setup for SIRE 2.0 but no vetting period has been configured yet."
                                    }),
                                ReactDOM.jsx("p", {
                                      children: [
                                        "Please, contact your Kaiko System if you think this is a problem ",
                                        "with our servers."
                                      ]
                                    }),
                                ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("button", {
                                            children: "Sync again",
                                            className: "btn btn-primary btn-rounded",
                                            onClick: (function (param) {
                                                vnode.state.data = "NotReady";
                                                effect(vnode);
                                              })
                                          }),
                                      className: "text-right"
                                    })
                              ],
                              style: {
                                margin: "4rem auto",
                                width: "80%"
                              }
                            });
                      } else {
                        exit$1 = 3;
                      }
                    } else if (match$3.empty) {
                      exit = 1;
                    } else {
                      exit$1 = 3;
                    }
                    if (exit$1 === 3) {
                      vessel = match$3.vessel;
                      checkListUrl = match$3.checkListUrl;
                      structuralComponentsUrl = match$3.structuralComponentsUrl;
                      safetyComponentsUrl = match$3.safetyComponentsUrl;
                      companyFormsUrl = match$3.companyFormsUrl;
                      rootNodeUrl = match$3.rootNodeUrl;
                      tsiModuleUrl = match$3.tsiModuleUrl;
                      risk4seaChecklistUrl = match$3.risk4seaChecklistUrl;
                      exit = 2;
                    }
                    
                  } else {
                    var match$4 = match.VAL;
                    if (match$4.empty) {
                      tmp = React.jsx(StillLoading.make, {});
                    } else {
                      vessel = match$4.vessel;
                      checkListUrl = match$4.checkListUrl;
                      structuralComponentsUrl = match$4.structuralComponentsUrl;
                      safetyComponentsUrl = match$4.safetyComponentsUrl;
                      companyFormsUrl = match$4.companyFormsUrl;
                      rootNodeUrl = match$4.rootNodeUrl;
                      tsiModuleUrl = match$4.tsiModuleUrl;
                      risk4seaChecklistUrl = match$4.risk4seaChecklistUrl;
                      exit = 2;
                    }
                  }
                } else {
                  tmp = match === "NotFound" || match === "SyncError" ? ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsxs("h2", {
                                  children: [
                                    React.jsx(Feather.make, {
                                          icon: "alert_triangle",
                                          className: "mr-2",
                                          style: "vertical-align: bottom",
                                          size: 36
                                        }),
                                    "Connection error"
                                  ],
                                  className: "text-center text-error"
                                }),
                            ReactDOM.jsx("p", {
                                  children: "There was an error while synchronizing this vessel with our servers.  "
                                }),
                            ReactDOM.jsx("p", {
                                  children: "Please review your internet connection, and try again in a few minutes."
                                }),
                            ReactDOM.jsx("p", {
                                  children: [
                                    "If the problem persists while you are connected to the internet, ",
                                    "please contact Kaiko Systems."
                                  ]
                                }),
                            ReactDOM.jsx("div", {
                                  children: ReactDOM.jsx("button", {
                                        children: "Try again",
                                        className: "btn btn-primary btn-rounded",
                                        onClick: (function (param) {
                                            vnode.state.data = "NotReady";
                                            effect(vnode);
                                          })
                                      }),
                                  className: "text-right"
                                })
                          ],
                          style: {
                            margin: "4rem auto",
                            width: "80%"
                          }
                        }) : React.jsx(StillLoading.make, {});
                }
                switch (exit) {
                  case 1 :
                      tmp = ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("h2", {
                                    children: [
                                      React.jsx(Feather.make, {
                                            icon: "alert_triangle",
                                            className: "mr-2",
                                            style: "vertical-align: bottom",
                                            size: 36
                                          }),
                                      "No modules found"
                                    ],
                                    className: "text-center text-error"
                                  }),
                              ReactDOM.jsx("p", {
                                    children: "Our records indicate this vessel is not completely setup in our server."
                                  }),
                              ReactDOM.jsx("p", {
                                    children: [
                                      "Please, contact your Kaiko System if you think this is a problem ",
                                      "with our servers."
                                    ]
                                  }),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("button", {
                                          children: "Sync again",
                                          className: "btn btn-primary btn-rounded",
                                          onClick: (function (param) {
                                              vnode.state.data = "NotReady";
                                              effect(vnode);
                                            })
                                        }),
                                    className: "text-right"
                                  })
                            ],
                            style: {
                              margin: "4rem auto",
                              width: "80%"
                            }
                          });
                      break;
                  case 2 :
                      tmp = ReactDOM.jsx("div", {
                            children: [
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, structuralComponentsUrl, (function (url) {
                                          return React.jsx(make, {
                                                      className: "t-structural-section",
                                                      href: url,
                                                      image: {
                                                        TAG: "Static",
                                                        _0: StructuralSvg
                                                      },
                                                      title: "Hull & Equipment"
                                                    });
                                        })), Mithril.empty),
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, safetyComponentsUrl, (function (url) {
                                          return React.jsx(make, {
                                                      className: "t-safety-section",
                                                      href: url,
                                                      image: {
                                                        TAG: "Static",
                                                        _0: SafetySvg
                                                      },
                                                      title: "Safety"
                                                    });
                                        })), Mithril.empty),
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, checkListUrl, (function (url) {
                                          return React.jsx(make, {
                                                      className: "t-checklist-section",
                                                      href: url,
                                                      image: {
                                                        TAG: "Static",
                                                        _0: ChecklistsSvg
                                                      },
                                                      title: "Checklists"
                                                    });
                                        })), Mithril.empty),
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, companyFormsUrl, (function (url) {
                                          return React.jsx(make, {
                                                      className: "t-company-forms-section company-forms-section",
                                                      href: url,
                                                      image: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vessel.company_logo, (function (param) {
                                                                  return {
                                                                          TAG: "Resource",
                                                                          _0: Vessel.getCompanyLogoFileUUID(vessel)
                                                                        };
                                                                })), {
                                                            TAG: "Static",
                                                            _0: CompanyFormsSvg
                                                          }),
                                                      title: "Company Forms"
                                                    });
                                        })), Mithril.empty),
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, rootNodeUrl, (function (url) {
                                          return React.jsx(make, {
                                                      className: "t-sire-section",
                                                      href: url,
                                                      image: {
                                                        TAG: "Static",
                                                        _0: SireSvg
                                                      },
                                                      title: "SIRE 2.0"
                                                    });
                                        })), Mithril.empty),
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, tsiModuleUrl, (function (url) {
                                          return React.jsx(make, {
                                                      className: "t-tsi-section",
                                                      href: url,
                                                      image: {
                                                        TAG: "Static",
                                                        _0: Logo512Png
                                                      },
                                                      title: "TSI"
                                                    });
                                        })), Mithril.empty),
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, risk4seaChecklistUrl, (function (url) {
                                          return React.jsx(make, {
                                                      className: "t-r4s-section",
                                                      href: url,
                                                      image: {
                                                        TAG: "Static",
                                                        _0: R4sSvg
                                                      },
                                                      title: "Port Checklist"
                                                    });
                                        })), Mithril.empty)
                            ],
                            className: "vessel-dashboard tw-grid tw-grid-cols-2 tw-gap-4 tw-place-content-center"
                          });
                      break;
                  
                }
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode.state.data),
                            showBackButton: false,
                            showSettings: true
                          });
              }));
}

var include = ProtectedViewMaker.EnsureProfile({
      make: make$1
    });

var make$2 = include.make;

export {
  title ,
  VesselMenuCard ,
  Private ,
  make$2 as make,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var hasEstimate = (typeof navigator.storage != "undefined" && typeof navigator.storage.estimate != 'undefined');

function get() {
  return navigator.storage;
}

var estimate = hasEstimate ? (function (prim) {
      return prim.estimate();
    }) : (function (param) {
      return Promise.resolve(undefined);
    });

var Core = {
  get: get,
  estimate: estimate
};

function MakeHelper(F) {
  var get = function () {
    var storage = navigator.storage;
    if (storage !== undefined) {
      return Prelude.thenResolve(F.fn(Caml_option.valFromOption(storage)), (function (r) {
                    return Caml_option.some(r);
                  }));
    } else {
      return Promise.resolve(undefined);
    }
  };
  return {
          fn: F.fn,
          get: get
        };
}

function get$1() {
  var storage = navigator.storage;
  if (storage !== undefined) {
    return Prelude.thenResolve(estimate(Caml_option.valFromOption(storage)), (function (r) {
                  return Caml_option.some(r);
                }));
  } else {
    return Promise.resolve(undefined);
  }
}

var Estimate = {
  fn: estimate,
  get: get$1
};

function fn(prim) {
  return prim.persist();
}

function get$2() {
  var storage = navigator.storage;
  if (storage !== undefined) {
    return Prelude.thenResolve(Caml_option.valFromOption(storage).persist(), (function (r) {
                  return Caml_option.some(r);
                }));
  } else {
    return Promise.resolve(undefined);
  }
}

var Persist = {
  fn: fn,
  get: get$2
};

function fn$1(prim) {
  return prim.persisted();
}

function get$3() {
  var storage = navigator.storage;
  if (storage !== undefined) {
    return Prelude.thenResolve(Caml_option.valFromOption(storage).persisted(), (function (r) {
                  return Caml_option.some(r);
                }));
  } else {
    return Promise.resolve(undefined);
  }
}

var Persisted = {
  fn: fn$1,
  get: get$3
};

function estimate$1() {
  return Prelude.thenResolve(get$1(), (function (q) {
                return Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, q, (function (x) {
                                  return x;
                                })), (function (param) {
                              return {
                                      usage: {
                                        TAG: "Bytes",
                                        _0: param.usage
                                      },
                                      quota: {
                                        TAG: "Bytes",
                                        _0: param.quota
                                      }
                                    };
                            }));
              }));
}

var Helpers = {
  MakeHelper: MakeHelper,
  Estimate: Estimate,
  Persist: Persist,
  Persisted: Persisted,
  estimate: estimate$1,
  persist: get$2,
  persisted: get$3
};

var Units;

export {
  Units ,
  Core ,
  get ,
  estimate ,
  Helpers ,
}
/* hasEstimate Not a pure module */

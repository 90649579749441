// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as User from "../User.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Settings from "../../Settings.js";
import * as State__User from "../../state/State__User.js";
import * as UserProfile from "../UserProfile.js";
import * as ButtonAction from "../../common/components/ButtonAction.js";
import * as StorageUnits from "../../../libs/StorageUnits.js";
import * as ModalBackdrop from "../../common/components/ModalBackdrop.js";
import * as ProfileAvatar from "./ProfileAvatar.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as ButtonSlimIcon from "../../common/components/ButtonSlimIcon.js";
import * as StorageManager from "../../../libs/StorageManager.js";
import * as DevelopersArenaLink from "../../debug/DevelopersArenaLink.js";
import * as ModalTemplateSimple from "../../common/components/ModalTemplateSimple.js";
import FullLogoPng from "/images/full-logo.png";
import * as Accounts_ChangePassword from "../views/Accounts_ChangePassword.js";

function toElementSize(size) {
  if (size === "xl") {
    return [
            40,
            "tw-text-2xl"
          ];
  } else if (size === "big") {
    return [
            35,
            "tw-text-xl"
          ];
  } else {
    return [
            30,
            "tw-text-base"
          ];
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var size = Prelude.default(vnode.attrs.size, "big");
                var onClick = vnode.attrs.onClick;
                var match$1 = toElementSize(size);
                return ReactDOM.jsxs("button", {
                            children: [
                              React.jsx(Feather.make, {
                                    icon: match.icon,
                                    className: "tw-pr-1.5",
                                    size: match$1[0]
                                  }),
                              ReactDOM.jsx("div", {
                                    children: match.label,
                                    className: match$1[1]
                                  })
                            ],
                            className: Mithril.classList([
                                  "tw-text-black tw-bg-transparent tw-border-0 tw-rounded-sm tw-p-0",
                                  "tw-flex tw-flex-row tw-items-center tw-justify-start",
                                  "tw-cursor-pointer tw-appearance-none tw-h-7 tw-no-underline",
                                  "tw-outline-none tw-select-none tw-font-normal"
                                ], undefined),
                            onClick: onClick
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: match.labelName,
                                    className: "tw-font-medium"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: match.content,
                                    className: "tw-ml-1"
                                  })
                            ],
                            className: "tw-flex tw-flex-row tw-items-start tw-justify-start tw-text-left"
                          });
              }));
}

function make$2(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: match.labelName,
                                    className: "tw-font-medium"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: match.content,
                                    className: "tw-ml-1"
                                  })
                            ],
                            className: Mithril.classList([
                                  "tw-flex tw-flex-row tw-items-start tw-justify-start tw-text-left",
                                  "tw-text-[#808CA3]"
                                ], undefined)
                          });
              }));
}

function make$3(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("Undefined"), (function (vnode) {
                    Prelude.thenDo(Prelude.thenResolve(StorageManager.Helpers.estimate(), (function (quota) {
                                return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, quota, (function (q) {
                                                  return {
                                                          TAG: "Ready",
                                                          _0: q
                                                        };
                                                })), "Undefined");
                              })), (function (extra) {
                            return Mithril.setState(vnode, extra);
                          }));
                  })), (function (vnode) {
                var match = vnode.attrs;
                var onClose = match.onClose;
                var match$1 = State__Memory.Current.get();
                var profile = match$1.profile;
                var vessel = match$1.vessel;
                var user = match$1.user;
                var defaultName = "Not Available";
                var userName = user !== undefined ? Prelude.default(User.name(user), defaultName) : defaultName;
                var profileName;
                if (profile !== undefined) {
                  var rankName = UserProfile.Rank.toString(profile.rank);
                  var text = UserProfile.name(profile);
                  profileName = text !== undefined ? text + ", " + rankName : defaultName;
                } else {
                  profileName = defaultName;
                }
                var vesselName = vessel !== undefined ? vessel.name : defaultName;
                var match$2 = vnode.state.data;
                var match$3;
                if (typeof match$2 !== "object") {
                  match$3 = [
                    "N/A",
                    ""
                  ];
                } else {
                  var match$4 = match$2._0;
                  var quota = match$4.quota;
                  var usage = match$4.usage;
                  var free = StorageUnits.sub(quota, usage);
                  var percent = StorageUnits.percent(usage, quota);
                  var percentLabel = percent.toFixed() + "%";
                  var storageLabel = StorageUnits.fractionToString(usage, quota) + (" (" + StorageUnits.toString(free) + " free)");
                  match$3 = [
                    percentLabel,
                    storageLabel
                  ];
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              React.jsx(ModalBackdrop.make, {
                                    onClick: onClose
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("div", {
                                            children: React.jsx(ButtonSlimIcon.make, {
                                                  icon: "x",
                                                  onClick: onClose,
                                                  size: 18,
                                                  style: "black",
                                                  className: "tw-pr-2"
                                                }),
                                            className: "tw-flex tw-flex-row tw-items-center tw-justify-end"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: ReactDOM.jsx("img", {
                                                  className: "tw-h-8 tw-min-h-10 tw-max-h-10",
                                                  src: FullLogoPng
                                                }),
                                            className: "tw-flex tw-flex-row tw-items-center tw-justify-start tw-mt-2"
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              React.jsx(make, {
                                                    icon: "user",
                                                    label: "Manage Profiles",
                                                    onClick: (function (_event) {
                                                        Mithril$1.route.set(URLs.userProfiles());
                                                      })
                                                  }),
                                              React.jsx(make, {
                                                    icon: "refresh_cw",
                                                    label: "Switch Vessel",
                                                    onClick: (function (_event) {
                                                        Mithril$1.route.set("/landingpage/vessel");
                                                      })
                                                  }),
                                              React.jsx(make, {
                                                    icon: "settings",
                                                    label: "Settings",
                                                    onClick: match.onOpenSettings
                                                  })
                                            ],
                                            className: Mithril.classList([
                                                  "tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-5",
                                                  "tw-mt-7 tw-px-1"
                                                ], undefined)
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsxs("div", {
                                                    children: [
                                                      React.jsx(make$1, {
                                                            labelName: "Name:",
                                                            content: userName
                                                          }),
                                                      React.jsx(make$1, {
                                                            labelName: "Profile:",
                                                            content: profileName
                                                          })
                                                    ]
                                                  }),
                                              React.jsx(make$1, {
                                                    labelName: "Vessel:",
                                                    content: vesselName
                                                  }),
                                              ReactDOM.jsxs("div", {
                                                    children: [
                                                      React.jsx(make$2, {
                                                            labelName: "Application Version:",
                                                            content: Settings.releaseVersion
                                                          }),
                                                      React.jsx(make$2, {
                                                            labelName: "API Version:",
                                                            content: Settings.apiVersion
                                                          }),
                                                      React.jsx(make$2, {
                                                            labelName: "Estimated storage usage:",
                                                            content: ""
                                                          }),
                                                      React.jsx(make$2, {
                                                            labelName: "",
                                                            content: match$3[0]
                                                          }),
                                                      React.jsx(make$2, {
                                                            labelName: "",
                                                            content: match$3[1]
                                                          })
                                                    ]
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    children: React.jsx(DevelopersArenaLink.make, {}),
                                                    className: "tw-mt-2"
                                                  })
                                            ],
                                            className: Mithril.classList([
                                                  "tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-8",
                                                  "tw-mt-12 tw-px-1"
                                                ], undefined)
                                          })
                                    ],
                                    className: Mithril.classList([
                                          "tw-fixed tw-top-0 tw-right-0 tw-h-full tw-min-w-[320px] tw-max-w-[90vw]",
                                          "tw-flex tw-flex-col tw-overflow-y-auto tw-z-1501 tw-p-2 tw-bg-white"
                                        ], undefined)
                                  })
                            ],
                            className: "t-drawer-modal"
                          });
              }));
}

function make$4(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                return React.jsxs(ModalTemplateSimple.make, {
                            children: [
                              React.jsx(make, {
                                    icon: "settings",
                                    label: "Settings",
                                    size: "xl"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(make, {
                                            icon: "lock",
                                            label: "Password",
                                            size: "big"
                                          }),
                                      React.jsx(Accounts_ChangePassword.make, {
                                            successCallback: match.onPasswordChanged
                                          })
                                    ],
                                    className: "tw-mt-7 tw-text-left"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(make, {
                                            icon: "log_out",
                                            label: "Log out",
                                            size: "big"
                                          }),
                                      React.jsx(ButtonAction.make, {
                                            children: "Log out",
                                            onClick: match.onLogout,
                                            style: "error"
                                          })
                                    ],
                                    className: "tw-flex tw-flex-col tw-gap-2 tw-mt-2"
                                  })
                            ],
                            onClose: match.onClose,
                            onBack: match.onBack
                          });
              }));
}

function make$5(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                return React.jsxs(ModalTemplateSimple.make, {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("div", {
                                          children: "Are you sure you want to log out?"
                                        }),
                                    className: "tw-flex tw-flex-row tw-items-center tw-justify-start"
                                  }),
                              React.jsx(ButtonAction.make, {
                                    children: "Yes, log me out of Kaiko Systems",
                                    onClick: match.onLogout,
                                    style: "error",
                                    className: "tw-mt-3"
                                  })
                            ],
                            onClose: match.onClose,
                            onBack: match.onBack
                          });
              }));
}

function SessionSidebar(props) {
  return Mithril.view(Mithril.component({
                  view: "Hidden"
                }), (function (vnode) {
                var systemState = State__Memory.Current.get();
                var curState = vnode.state.data;
                var profile = systemState.profile;
                var match = curState.view;
                var tmp;
                switch (match) {
                  case "Hidden" :
                      tmp = Mithril.empty;
                      break;
                  case "Drawer" :
                      tmp = React.jsx(make$3, {
                            onClose: (function (_event) {
                                Mithril.setState(vnode, {
                                      view: "Hidden"
                                    });
                              }),
                            onOpenSettings: (function (_event) {
                                Mithril.setState(vnode, {
                                      view: "Settings"
                                    });
                              })
                          });
                      break;
                  case "Settings" :
                      tmp = React.jsx(make$4, {
                            onBack: (function (_event) {
                                Mithril.setState(vnode, {
                                      view: "Drawer"
                                    });
                              }),
                            onClose: (function (_event) {
                                Mithril.setState(vnode, {
                                      view: "Hidden"
                                    });
                              }),
                            onLogout: (function (_event) {
                                Mithril.setState(vnode, {
                                      view: "LogoutConfirm"
                                    });
                              }),
                            onPasswordChanged: (function () {
                                State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setToast(State__Memory.Transformations.make(State__Memory.Current.get()), "Password saved successfully")));
                                Mithril.setState(vnode, {
                                      view: "Hidden"
                                    });
                              })
                          });
                      break;
                  case "LogoutConfirm" :
                      tmp = React.jsx(make$5, {
                            onBack: (function (_event) {
                                Mithril.setState(vnode, {
                                      view: "Settings"
                                    });
                              }),
                            onClose: (function (_event) {
                                Mithril.setState(vnode, {
                                      view: "Hidden"
                                    });
                              }),
                            onLogout: (function (_event) {
                                State__User.logout();
                              })
                          });
                      break;
                  
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: profile !== undefined ? ReactDOM.jsx("div", {
                                            children: React.jsx(ProfileAvatar.make, {
                                                  style: "header",
                                                  profile: profile
                                                }),
                                            className: "tw-h-[40px] tw-w-[40px]"
                                          }) : React.jsx(Feather.make, {
                                            icon: "settings"
                                          }),
                                    className: "tw-flex tw-items-center tw-justify-center tw-cursor-pointer",
                                    onClick: (function (_event) {
                                        Mithril.setState(vnode, {
                                              view: "Drawer"
                                            });
                                      })
                                  }),
                              tmp
                            ],
                            className: "t-app-menu"
                          });
              }));
}

var make$6 = SessionSidebar;

export {
  make$6 as make,
}
/* URLs Not a pure module */

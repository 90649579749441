// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

function rewrite(comment) {
  return {
          id: comment.id,
          body: comment.body,
          author: comment.author,
          timestamp: comment.timestamp
        };
}

function message(param, param$1) {
  return "Installing fixes to Comments in findings";
}

function $$process(finding, param) {
  return {
          TAG: "Update",
          _0: {
            id: finding.id,
            vessel_id: finding.vessel_id,
            detected_with_id: finding.detected_with_id,
            resolved_with_id: finding.resolved_with_id,
            status: finding.status,
            deadline: finding.deadline,
            comments: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, finding.comments, (function (__x) {
                        return __x.map(rewrite);
                      })), [])
          }
        };
}

var Findings = InitFeedback.MakeRewriter({
      storeName: "deficiencies",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing fixes to Comments in findings";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.map(Findings.rewrite(transaction, undefined), (function () {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

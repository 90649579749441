// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

var ProcedureId = Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

function message(param, param$1) {
  return "Installing new types of procedures";
}

function $$process(procedure, param) {
  var match = procedure.question_type;
  return {
          TAG: "Update",
          _0: {
            id: procedure.id,
            vessel_id: procedure.vessel_id,
            section_id: procedure.section_id,
            component_id: procedure.component_id,
            name: procedure.name,
            order: procedure.order,
            position_diagram_id: procedure.position_diagram_id,
            position_diagram_desc: procedure.position_diagram_desc,
            example_image_id: procedure.example_image_id,
            example_image_desc: procedure.example_image_desc,
            description: procedure.description,
            require_landscape_pictures: procedure.require_landscape_pictures,
            question_type: match === "ComponentWeightMeasurementQuestion" ? "WeightDeltaQuestion" : (
                match === "OkNotOkQuestion" ? "OkNotOkQuestion" : (
                    match === "DateQuestion" ? "DateQuestion" : "RatingQuestion"
                  )
              ),
            rating_styling_rules_id: procedure.rating_styling_rules_id,
            require_image_conditions: procedure.require_image_conditions,
            recurrence: procedure.recurrence,
            due_date: procedure.due_date,
            overdue_date: procedure.overdue_date,
            ignore_overdue_status: procedure.ignore_overdue_status
          }
        };
}

var ProcedureRewriter = InitFeedback.MakeRewriter({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

function make(questionType, rating, okOrNot, weightDeltaOpt, dateOpt, param) {
  var weightDelta = weightDeltaOpt !== undefined ? Caml_option.valFromOption(weightDeltaOpt) : undefined;
  var date = dateOpt !== undefined ? Caml_option.valFromOption(dateOpt) : undefined;
  if (questionType === "WeightDeltaQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, weightDelta, (function (w) {
                  return {
                          NAME: "WeightDelta",
                          VAL: w
                        };
                }));
  } else if (questionType === "OkNotOkQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, okOrNot, (function (rating) {
                  return {
                          NAME: "OkNotOk",
                          VAL: rating === 5
                        };
                }));
  } else if (questionType === "DateQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, date, (function (d) {
                  return {
                          NAME: "Date",
                          VAL: d
                        };
                }));
  } else {
    return Curry._2(Prelude.OptionExported.$$Option.map, rating, (function (r) {
                  return {
                          NAME: "Rating",
                          VAL: r
                        };
                }));
  }
}

var ProcedureReader = IDB__Migration__Utils.MakeReader({
      storeName: "procedures"
    });

Utils.MakeOpaqueIdentifier({});

function message$1(param, param$1) {
  return "Installing new types of inspections (cloud)";
}

function $$process$1(inspection, getProcedure) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, getProcedure(inspection.procedure_id), (function (procedure) {
                        return make(procedure.question_type, inspection.rating, inspection.rating, Caml_option.some(inspection.component_weight_value), Caml_option.some(inspection.date_value), undefined);
                      })), (function (value) {
                    return {
                            TAG: "Update",
                            _0: {
                              id: inspection.id,
                              procedure_id: inspection.procedure_id,
                              component_id: inspection.component_id,
                              section_id: inspection.section_id,
                              vessel_id: inspection.vessel_id,
                              inspector_id: inspection.inspector_id,
                              timestamp: inspection.timestamp,
                              code: inspection.code,
                              annotations: inspection.annotations,
                              additional_annotation: inspection.additional_annotation,
                              picture_prompted: inspection.picture_prompted,
                              walked_steps: inspection.walked_steps,
                              app_version: inspection.app_version,
                              deficiency_id: inspection.deficiency_id,
                              attachments: inspection.attachments,
                              value: value
                            }
                          };
                  })), {
              TAG: "Update",
              _0: {
                id: inspection.id,
                procedure_id: inspection.procedure_id,
                component_id: inspection.component_id,
                section_id: inspection.section_id,
                vessel_id: inspection.vessel_id,
                inspector_id: inspection.inspector_id,
                timestamp: inspection.timestamp,
                code: inspection.code,
                annotations: inspection.annotations,
                additional_annotation: inspection.additional_annotation,
                picture_prompted: inspection.picture_prompted,
                walked_steps: inspection.walked_steps,
                app_version: inspection.app_version,
                deficiency_id: inspection.deficiency_id,
                attachments: inspection.attachments,
                value: undefined
              }
            });
}

var InspectionRewriter = InitFeedback.MakeRewriter({
      storeName: "inspections",
      message: message$1,
      $$process: $$process$1
    });

function message$2(param, param$1) {
  return "Installing new types of inspections (local)";
}

function $$process$2(inspection, getProcedure) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, getProcedure(inspection.procedure_id), (function (procedure) {
                        return make(procedure.question_type, inspection.rating, inspection.rating, Caml_option.some(inspection.component_weight_value), Caml_option.some(inspection.date_value), undefined);
                      })), (function (value) {
                    return {
                            TAG: "Update",
                            _0: {
                              id: inspection.id,
                              procedure_id: inspection.procedure_id,
                              component_id: inspection.component_id,
                              section_id: inspection.section_id,
                              vessel_id: inspection.vessel_id,
                              inspector_id: inspection.inspector_id,
                              timestamp: inspection.timestamp,
                              code: inspection.code,
                              annotations: inspection.annotations,
                              additional_annotation: inspection.additional_annotation,
                              picture_prompted: inspection.picture_prompted,
                              walked_steps: inspection.walked_steps,
                              app_version: inspection.app_version,
                              deficiency_id: inspection.deficiency_id,
                              attachments: inspection.attachments,
                              value: value
                            }
                          };
                  })), {
              TAG: "Update",
              _0: {
                id: inspection.id,
                procedure_id: inspection.procedure_id,
                component_id: inspection.component_id,
                section_id: inspection.section_id,
                vessel_id: inspection.vessel_id,
                inspector_id: inspection.inspector_id,
                timestamp: inspection.timestamp,
                code: inspection.code,
                annotations: inspection.annotations,
                additional_annotation: inspection.additional_annotation,
                picture_prompted: inspection.picture_prompted,
                walked_steps: inspection.walked_steps,
                app_version: inspection.app_version,
                deficiency_id: inspection.deficiency_id,
                attachments: inspection.attachments,
                value: undefined
              }
            });
}

var NewInspectionRewriter = InitFeedback.MakeRewriter({
      storeName: "newInspections",
      message: message$2,
      $$process: $$process$2
    });

function message$3(param, param$1) {
  return "Installing new types of inspections (draft)";
}

function $$process$3(inspection, getProcedure) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, getProcedure(inspection.procedure_id), (function (procedure) {
                        return make(procedure.question_type, inspection.rating, inspection.rating, Caml_option.some(inspection.component_weight_value), Caml_option.some(inspection.date_value), undefined);
                      })), (function (value) {
                    return {
                            TAG: "Update",
                            _0: {
                              id: inspection.id,
                              procedure_id: inspection.procedure_id,
                              component_id: inspection.component_id,
                              section_id: inspection.section_id,
                              vessel_id: inspection.vessel_id,
                              inspector_id: inspection.inspector_id,
                              timestamp: inspection.timestamp,
                              code: inspection.code,
                              annotations: inspection.annotations,
                              additional_annotation: inspection.additional_annotation,
                              picture_prompted: inspection.picture_prompted,
                              walked_steps: inspection.walked_steps,
                              app_version: inspection.app_version,
                              deficiency_id: inspection.deficiency_id,
                              attachments: inspection.attachments,
                              value: value
                            }
                          };
                  })), {
              TAG: "Update",
              _0: {
                id: inspection.id,
                procedure_id: inspection.procedure_id,
                component_id: inspection.component_id,
                section_id: inspection.section_id,
                vessel_id: inspection.vessel_id,
                inspector_id: inspection.inspector_id,
                timestamp: inspection.timestamp,
                code: inspection.code,
                annotations: inspection.annotations,
                additional_annotation: inspection.additional_annotation,
                picture_prompted: inspection.picture_prompted,
                walked_steps: inspection.walked_steps,
                app_version: inspection.app_version,
                deficiency_id: inspection.deficiency_id,
                attachments: inspection.attachments,
                value: undefined
              }
            });
}

var DraftInspectionRewriter = InitFeedback.MakeRewriter({
      storeName: "draftInspections",
      message: message$3,
      $$process: $$process$3
    });

function message$4() {
  return "Installing new types of procedures and inspections";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.bind(ProcedureRewriter.rewrite(transaction, undefined), (function () {
                return Prelude.PromisedResult.bind(Prelude.PromisedResult.bind(Prelude.then(Prelude.thenResolve(ProcedureReader.read(transaction), (function (procs) {
                                          var get = ProcedureId.$$Array.getter(procs, (function (p) {
                                                  return p.id;
                                                }));
                                          return function (key) {
                                            return Prelude.$$Array.first(get(key));
                                          };
                                        })), (function (getProcedure) {
                                      return Prelude.PromisedResult.map(InspectionRewriter.rewrite(transaction, getProcedure), (function () {
                                                    return getProcedure;
                                                  }));
                                    })), (function (getProcedure) {
                                  return Prelude.PromisedResult.map(NewInspectionRewriter.rewrite(transaction, getProcedure), (function () {
                                                return getProcedure;
                                              }));
                                })), (function (getProcedure) {
                              return Prelude.PromisedResult.map(DraftInspectionRewriter.rewrite(transaction, getProcedure), (function () {
                                            
                                          }));
                            }));
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$4,
      migrate: migrate
    });

var make$1 = include.make;

export {
  make$1 as make,
}
/* ProcedureId Not a pure module */

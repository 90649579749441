// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

Utils.MakeOpaqueIdentifier({});

function rewrite(submission) {
  return {
          id: submission.id,
          vessel_id: submission.vessel_id,
          form_id: submission.form_id,
          timestamp: submission.timestamp,
          inspections: submission.inspections
        };
}

var CompanyFormSubmissionReader = IDB__Migration__Utils.MakeReader({
      storeName: "companyFormSubmissions"
    });

function message(param, param$1) {
  return "Optimizing company forms";
}

function $$process(submission, param) {
  var match = submission.status;
  if (match === "uploaded") {
    return {
            TAG: "Update",
            _0: rewrite(submission)
          };
  } else {
    return "Delete";
  }
}

var CompanyFormSubmissionRewriter = InitFeedback.MakeRewriter({
      storeName: "companyFormSubmissions",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Optimizing company forms";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.bind(Prelude.then(CompanyFormSubmissionReader.read(transaction), (function (submissions) {
                    var data = function () {
                      return Curry._2(Prelude.$$Array.keep, submissions, (function (s) {
                                      return s.status === "ready_to_upload";
                                    })).map(rewrite);
                    };
                    var NewCompanyFormSubmissionWriter = IDB__Migration__Utils.MakeWriter({
                          storeName: "newCompanyFormSubmissions",
                          data: data
                        });
                    return NewCompanyFormSubmissionWriter.write(transaction, true);
                  })), (function (param) {
                return CompanyFormSubmissionRewriter.rewrite(transaction, undefined);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

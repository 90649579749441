// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";

var _map = {"structural":"structural","safety":"safety","checklist":"checklist"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var fromJS = $$JSON.Field.variadicString("Expected component type", tFromJs);

var Deserializer = {
  fromJS: fromJS
};

var _map$1 = {"life":"life","fire":"fire"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$1[param];
}

var fromJS$1 = $$JSON.Field.variadicString("Expected component safety type", (function (x) {
        return _map$1[x];
      }));

var Deserializer$1 = {
  fromJS: fromJS$1
};

var Safety = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1,
  Deserializer: Deserializer$1
};

export {
  tToJs ,
  tFromJs ,
  Deserializer ,
  Safety ,
}
/* fromJS Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../Query.js";
import * as RRule from "../../libs/RRule.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as VesselId from "../vessel/VesselId.js";
import * as Inspection from "../survey/Inspection.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../survey/ProcedureId.js";
import * as State__User from "./State__User.js";
import * as StepCounter from "../runtime/StepCounter.js";
import * as InspectionAPI from "../api/endpoints/InspectionAPI.js";
import * as NewInspection from "../survey/NewInspection.js";
import * as State__Memory from "./State__Memory.js";
import * as State__Amounts from "./State__Amounts.js";
import * as CompanyFormManager from "../company_forms/CompanyFormManager.js";
import * as NewInspectionManager from "../survey/NewInspectionManager.js";
import * as FutureAttachmentManager from "../attachment_chunks/FutureAttachmentManager.js";

async function getCurrentInspector() {
  var user = await State__User.getCurrent();
  return {
          user: user,
          inspector_id: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                  return u.id;
                }))
        };
}

async function getCurrentProfile() {
  var state = State__Memory.Current.get();
  var profile = state.profile;
  if (profile !== undefined) {
    return profile;
  }
  
}

var steps = {
  contents: 0
};

function count(info) {
  steps.contents = steps.contents + info.steps | 0;
}

function steps$1() {
  var res = steps.contents;
  steps.contents = 0;
  return res;
}

StepCounter.addListener(count);

var Steps = {
  steps: steps$1
};

async function make(procedure, photo, signature, value, picture_promptedOpt, is_draftOpt, annotationsOpt, additional_annotationOpt, draftSubmissionIdOpt, param) {
  var picture_prompted = picture_promptedOpt !== undefined ? picture_promptedOpt : false;
  var is_draft = is_draftOpt !== undefined ? is_draftOpt : false;
  var annotations = annotationsOpt !== undefined ? annotationsOpt : [];
  var additional_annotation = additional_annotationOpt !== undefined ? additional_annotationOpt : "";
  var draftSubmissionId = draftSubmissionIdOpt !== undefined ? Caml_option.valFromOption(draftSubmissionIdOpt) : undefined;
  var match = await getCurrentInspector();
  var profile = await getCurrentProfile();
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  newrecord.draftCompanyFormSubmissions = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmissionId, (function (i) {
              return {
                      TAG: "Get",
                      _0: i
                    };
            })), "NoOp");
  newrecord.newInspections = {
    TAG: "Limit",
    _0: 1,
    _1: "All"
  };
  newrecord.plannedProcedures = {
    TAG: "Get",
    _0: procedure.id
  };
  newrecord.components = {
    TAG: "Get",
    _0: procedure.component_id
  };
  newrecord.sections = {
    TAG: "Get",
    _0: procedure.section_id
  };
  var match$1 = await Query.read(newrecord);
  var match$2 = Prelude.$$Array.first(match$1.sections);
  var match$3 = Prelude.$$Array.first(match$1.components);
  if (match$2 === undefined) {
    return [
            procedure,
            undefined
          ];
  }
  if (match$3 === undefined) {
    return [
            procedure,
            undefined
          ];
  }
  var isTheFirstInspection = Prelude.$$Array.isEmpty(match$1.newInspections);
  var walked_steps = isTheFirstInspection ? 0 : steps$1();
  var inspection = NewInspectionManager.make(procedure.id, match$3.id, match$2.id, match$2.vessel_id, Caml_option.some(value), Caml_option.some(match.inspector_id), picture_prompted, walked_steps, Caml_option.some(photo), Caml_option.some(signature), additional_annotation, annotations, undefined, profile);
  var query;
  if (is_draft) {
    query = Query.makeWrite();
  } else {
    var recurrence = RRule.toSet(procedure.recurrence);
    var due_date = Prelude.default(RRule.$$Set.after(recurrence, inspection.timestamp, undefined), inspection.timestamp);
    var overdue_date = Prelude.default(RRule.$$Set.after(recurrence, due_date, undefined), due_date);
    var newrecord$1 = Caml_obj.obj_dup(procedure);
    newrecord$1.overdue_date = overdue_date;
    newrecord$1.due_date = due_date;
    var match$4 = Prelude.$$Array.first(match$1.plannedProcedures);
    if (match$4 !== undefined) {
      var newrecord$2 = Caml_obj.obj_dup(Query.makeWrite());
      newrecord$2.plannedProcedures = [{
          TAG: "Save",
          _0: newrecord$1
        }];
      newrecord$2.procedures = [{
          TAG: "Save",
          _0: newrecord$1
        }];
      query = newrecord$2;
    } else {
      var newrecord$3 = Caml_obj.obj_dup(Query.makeWrite());
      newrecord$3.procedures = [{
          TAG: "Save",
          _0: newrecord$1
        }];
      query = newrecord$3;
    }
  }
  var query$1;
  if (is_draft) {
    var newrecord$4 = Caml_obj.obj_dup(query);
    newrecord$4.draftInspections = [{
        TAG: "Save",
        _0: inspection
      }];
    query$1 = newrecord$4;
  } else {
    var newrecord$5 = Caml_obj.obj_dup(query);
    newrecord$5.newInspections = [{
        TAG: "Save",
        _0: inspection
      }];
    query$1 = newrecord$5;
  }
  var draftSubmission = Prelude.$$Array.first(match$1.draftCompanyFormSubmissions);
  var query$2;
  if (draftSubmission !== undefined) {
    var newrecord$6 = Caml_obj.obj_dup(Query.makeRead());
    var match$5 = await Query.read((newrecord$6.companyForms = {
            TAG: "Get",
            _0: draftSubmission.form_id
          }, newrecord$6.newInspections = {
            TAG: "In",
            _0: draftSubmission.inspections
          }, newrecord$6.inspections = {
            TAG: "In",
            _0: draftSubmission.inspections
          }, newrecord$6));
    var companyForm = match$5.companyForms[0];
    var newInspectionsOfProcedure = ProcedureId.$$Array.group(Prelude.$$Array.append(match$5.newInspections, inspection), NewInspection.byProcedure);
    var oldInspectionsOfProcedure = ProcedureId.$$Array.group(match$5.inspections, Inspection.byProcedure);
    var match$6 = await CompanyFormManager.getInspectionsThatCanBeIncludedInSubmission([
          newInspectionsOfProcedure,
          oldInspectionsOfProcedure
        ], companyForm);
    var newrecord$7 = Caml_obj.obj_dup(query$1);
    newrecord$7.draftCompanyFormSubmissions = [{
        TAG: "Save",
        _0: {
          id: draftSubmission.id,
          name: draftSubmission.name,
          vessel_id: draftSubmission.vessel_id,
          form_id: draftSubmission.form_id,
          timestamp: draftSubmission.timestamp,
          target_date: draftSubmission.target_date,
          inspections: Inspection.ids(match$6[0])
        }
      }];
    query$2 = newrecord$7;
  } else {
    query$2 = query$1;
  }
  await Query.write(query$2);
  return [
          procedure,
          inspection
        ];
}

async function $$delete(inspection) {
  var procedureId = inspection.procedure_id;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.newInspections = NewInspection.ofProcedure(procedureId), newrecord.inspections = Inspection.ofProcedure(procedureId), newrecord.procedures = {
          TAG: "Get",
          _0: procedureId
        }, newrecord));
  var procedure = match.procedures[0];
  var recurrence = RRule.toSet(procedure.recurrence);
  var harmonizationDate = RRule.$$Set.harmonizationDate(recurrence);
  var lastInspection = Inspection.lastInspection(Curry._2(Prelude.$$Array.concat, match.inspections, match.newInspections));
  var attachment_ids = inspection.attachments.map(function (a) {
        return a.id;
      });
  await FutureAttachmentManager.$$delete(attachment_ids);
  var due_date = lastInspection !== undefined ? Prelude.default(RRule.$$Set.after(recurrence, lastInspection.timestamp, undefined), lastInspection.timestamp) : Prelude.default(RRule.$$Set.after(recurrence, harmonizationDate, undefined), harmonizationDate);
  var overdue_date = Prelude.default(RRule.$$Set.after(recurrence, due_date, undefined), due_date);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
  newrecord$1.newInspections = [{
      TAG: "Delete",
      _0: inspection.id
    }];
  newrecord$1.draftInspections = [{
      TAG: "Delete",
      _0: inspection.id
    }];
  var newrecord$2 = Caml_obj.obj_dup(procedure);
  newrecord$1.procedures = [{
      TAG: "Save",
      _0: (newrecord$2.overdue_date = overdue_date, newrecord$2.due_date = due_date, newrecord$2)
    }];
  var result = await Query.write(newrecord$1);
  await State__Amounts.refresh();
  return result;
}

function upload(inspections) {
  var params = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.$$Array.get, inspections, 0), (function (v) {
              return {
                      log_vessels: v.vessel_id
                    };
            })), {
        log_vessels: VesselId.$$null
      });
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return InspectionAPI.post(Caml_option.some(params), __x, inspections);
                }));
}

var LoggedUser;

var Amounts;

export {
  LoggedUser ,
  Amounts ,
  getCurrentInspector ,
  getCurrentProfile ,
  Steps ,
  make ,
  $$delete ,
  upload ,
}
/*  Not a pure module */

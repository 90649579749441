// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Dialog from "../../../libs/Dialog.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as NotApplicableStatus from "../../survey/NotApplicableStatus.js";

function onClickAction(vnode, action) {
  var onCallback = vnode.attrs.onCallback;
  var dialogId = vnode.attrs.id;
  Dialog.closeDialog(vnode, dialogId);
  onCallback(action);
}

function getActions(vnode) {
  var dialogId = vnode.attrs.id;
  var selected = vnode.attrs.selected;
  var actions = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.actions, (function (v) {
              return v;
            })), NotApplicableStatus.choices);
  var actions$1 = actions.map(function (choice) {
        var isSelected = Caml_obj.equal(choice.value, selected);
        return {
                onClick: isSelected ? (function () {
                      Dialog.closeDialog(vnode, dialogId);
                    }) : (function () {
                      onClickAction(vnode, choice.value);
                    }),
                label: choice.label,
                className: Mithril.$$class([
                      [
                        "btn btn-block mb-2",
                        true
                      ],
                      [
                        " bg-primary",
                        isSelected
                      ]
                    ], undefined)
              };
      });
  return Prelude.$$Array.append(actions$1, {
              onClick: (function () {
                  onClickAction(vnode, "");
                }),
              label: "Clear",
              className: Mithril.$$class([
                    [
                      "btn btn-block btn-link text-dark bg-gray mb-2",
                      true
                    ],
                    [
                      "d-none",
                      Curry._1(Prelude.OptionExported.$$Option.isNone, selected)
                    ]
                  ], undefined)
            });
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var id = vnode.attrs.id;
                var header = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.header, (function (v) {
                            return v;
                          })), "Procedure options");
                var bodyText = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.bodyText, (function (v) {
                            return v;
                          })), "The following options will mark this procedure for this company form\n          as N/A. Please, choose the reason.");
                var actions = getActions(vnode);
                return React.jsx(Dialog.make, {
                            id: id,
                            actions: actions,
                            header: header,
                            children: bodyText
                          });
              }));
}

var openDialog = Dialog.openDialog;

var closeDialog = Dialog.closeDialog;

export {
  openDialog ,
  closeDialog ,
  onClickAction ,
  getActions ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "./rescript-mithril/src/React.js";
import * as Dialog from "./Dialog.js";
import * as Mithril from "./rescript-mithril/src/Mithril.js";

function onClickAction(vnode, onCallback, action, dialogId, param) {
  Dialog.closeDialog(vnode, dialogId);
  onCallback(action);
}

function DialogNewEdit(props) {
  var content = props.content;
  var onCallback = props.onCallback;
  var id = props.id;
  return Mithril.view(Mithril.component(), (function (vnode) {
                var actions = [
                  {
                    onClick: (function (extra) {
                        return onClickAction(vnode, onCallback, "Edit", id, extra);
                      }),
                    label: "Edit"
                  },
                  {
                    onClick: (function (extra) {
                        return onClickAction(vnode, onCallback, "New", id, extra);
                      }),
                    label: "New"
                  }
                ];
                return React.jsx(Dialog.make, {
                            id: id,
                            actions: actions,
                            header: "Caution",
                            children: content
                          });
              }));
}

var openDialog = Dialog.openDialog;

var closeDialog = Dialog.closeDialog;

var make = DialogNewEdit;

export {
  openDialog ,
  closeDialog ,
  onClickAction ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as Level from "../../Level.js";
import * as Query from "../../../Query.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../../Answer.js";
import * as Vessel from "../../../vessel/Vessel.js";
import * as Browser from "../../../../libs/Browser.js";
import * as Feather from "../../../common/views/Feather.js";
import * as LevelId from "../../LevelId.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../Question.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../../vessel/VesselId.js";
import * as ListLayout from "../../../common/views/ListLayout.js";
import * as QuestionId from "../../QuestionId.js";
import * as Hyphenated from "hyphenated";
import * as AnswerValue from "../../AnswerValue.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../../libs/Conditional.js";
import MarkdownIt from "markdown-it";
import * as DialogNewEdit from "../../../../libs/DialogNewEdit.js";
import * as GestureWrapper from "../../../../libs/GestureWrapper.js";
import * as KeepScrollLink from "../../../common/views/KeepScrollLink.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as State__Answers from "../../../state/State__Answers.js";
import * as DialogNewEditAnswer from "./DialogNewEditAnswer.js";
import * as NotApplicableDialog from "../../../vessel/views/NotApplicableDialog.js";
import * as NotApplicableStatus from "../../../survey/NotApplicableStatus.js";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.js";

function _save(answerOpt, vnode, getValue, picture_prompted) {
  var answer = answerOpt !== undefined ? Caml_option.valFromOption(answerOpt) : undefined;
  var match = getValue();
  var remarks = match.remarks;
  var attachments = match.attachments;
  var value = match.value;
  var match$1 = vnode.state.data;
  if (typeof match$1 !== "object") {
    return ;
  }
  var level = match$1.level;
  var question = match$1.question;
  var tmp;
  if (answer !== undefined) {
    var newrecord = Caml_obj.obj_dup(answer);
    tmp = State__Answers.update((newrecord.picture_prompted = picture_prompted, newrecord.evidences = attachments, newrecord.remarks = Prelude.default(remarks, ""), newrecord.value = value, newrecord));
  } else {
    tmp = State__Answers.make(match$1.vessel.id, level.id, level.path, question.id, question.kind, value, Prelude.default(remarks, ""), attachments, picture_prompted);
  }
  Prelude.thenDo(tmp, (function (param) {
          window.history.back();
        }));
}

function save(vnode, getValue, picture_prompted, _event) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  if (createNew) {
    return _save(undefined, vnode, getValue, picture_prompted);
  }
  var match = vnode.state.data;
  if (typeof match !== "object") {
    console.log();
    return ;
  }
  var lastNewAnswer = match.lastNewAnswer;
  if (Curry._1(Prelude.OptionExported.$$Option.isNone, lastNewAnswer)) {
    console.log();
    return ;
  } else {
    return _save(Caml_option.some(lastNewAnswer), vnode, getValue, picture_prompted);
  }
}

function setFlexGrow(viewport, newValue) {
  Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Webapi__Dom__HtmlElement.ofElement(viewport), (function (d) {
              d.style.setProperty("flex-grow", newValue);
            })), undefined);
}

function addListenerOnVisualViewportResize(viewport, originalHeight) {
  var visualViewport = window.visualViewport;
  visualViewport.addEventListener("resize", (function (e) {
          var vp = e.target;
          e.stopPropagation();
          var currentHeight = vp.height;
          if (currentHeight !== originalHeight) {
            return setFlexGrow(viewport, "0");
          } else {
            return setFlexGrow(viewport, "1");
          }
        }));
}

function QuestionWidget$Editor(props) {
  var bottomTaskBarStatus = props.bottomTaskBarStatus;
  var bottom = props.bottom;
  var updateChildrenState = props.updateChildrenState;
  var isDirty = props.isDirty;
  var allowSave = props.allowSave;
  var getValue = props.getValue;
  return Mithril.view(Mithril.oncreate(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                        var questionId = QuestionId.Route.param("questionId");
                        var vesselId = VesselId.Route.param("vesselId");
                        var levelId = LevelId.Route.param("levelId");
                        var newrecord = Caml_obj.obj_dup(Query.makeRead());
                        Prelude.thenDo(Query.read((newrecord.newAnswers = {
                                    TAG: "And",
                                    _0: Answer.ofQuestion(questionId),
                                    _1: Answer.ofLevel(levelId)
                                  }, newrecord.answers = {
                                    TAG: "And",
                                    _0: Answer.ofQuestion(questionId),
                                    _1: Answer.ofLevel(levelId)
                                  }, newrecord.questions = {
                                    TAG: "Get",
                                    _0: questionId
                                  }, newrecord.levels = {
                                    TAG: "Get",
                                    _0: levelId
                                  }, newrecord.vessels = {
                                    TAG: "Get",
                                    _0: vesselId
                                  }, newrecord)), (function (param) {
                                var level = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(param.levels), Level.Defaults.$$null);
                                var vessel = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(param.vessels), Vessel.Defaults.$$null);
                                var question = Prelude.default(Prelude.$$Array.first(param.questions), Question.Defaults.$$null);
                                var lastAnswer = Prelude.$$Array.last(Prelude.$$Array.sort(param.answers, (function (a) {
                                            return a.timestamp;
                                          }), undefined));
                                var lastNewAnswer = Prelude.$$Array.last(Prelude.$$Array.sort(param.newAnswers, (function (a) {
                                            return a.timestamp;
                                          }), undefined));
                                Mithril.setState(vnode, {
                                      TAG: "Loaded",
                                      lastAnswer: lastAnswer,
                                      lastNewAnswer: lastNewAnswer,
                                      question: question,
                                      level: level,
                                      vessel: vessel
                                    });
                                updateChildrenState(vnode.state.data);
                              }));
                      })), (function (vnode) {
                    var originalHeight = window.visualViewport.height;
                    var waitForViewportReady = function () {
                      var viewport = Caml_option.nullable_to_opt(document.getElementById("main-scroll-viewport"));
                      if (viewport !== undefined) {
                        var viewport$1 = Caml_option.valFromOption(viewport);
                        var input = viewport$1.getElementsByClassName("form-input").item(0);
                        return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, (input == null) ? undefined : Caml_option.some(input), (function (i) {
                                          addListenerOnVisualViewportResize(viewport$1, originalHeight);
                                          Mithril.$$Element.focus(Caml_option.some(i));
                                        })), undefined);
                      }
                      setTimeout(waitForViewportReady, 100);
                    };
                    if (Browser.runningOnMobile()) {
                      return waitForViewportReady();
                    } else {
                      return Forms.Input.focus(vnode);
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var getValue$1 = getValue !== undefined ? getValue : (function () {
                      return {
                              attachments: []
                            };
                    });
                var allowSave$1 = allowSave !== undefined ? allowSave() : true;
                var onBackClicked = function () {
                  var isDirty$1 = isDirty !== undefined ? isDirty : (function () {
                        return false;
                      });
                  if (isDirty$1()) {
                    return Promise.resolve(window.confirm("Are you sure you want to leave? All entered data will be lost."));
                  } else {
                    return Promise.resolve(true);
                  }
                };
                var match$1 = vnode.state.data;
                var actions;
                if (typeof match$1 !== "object") {
                  actions = [];
                } else {
                  var lastNewAnswer = match$1.lastNewAnswer;
                  actions = lastNewAnswer !== undefined ? [{
                        icon: "trash_2",
                        effect: {
                          TAG: "Fn",
                          _0: (function ($$event) {
                              $$event.preventDefault();
                              if (Browser.$$Window.confirm("Are you sure?")) {
                                return Prelude.thenDo(State__Answers.$$delete(lastNewAnswer), (function (param) {
                                              window.history.back();
                                            }));
                              }
                              
                            })
                        }
                      }] : [];
                }
                var bottom$1 = bottom !== undefined ? bottom() : ReactDOM.jsx("button", {
                        children: "Save",
                        className: Mithril.$$class([
                              [
                                "btn btn-primary btn-block btn-lg ",
                                true
                              ],
                              [
                                "disabled",
                                !allowSave$1
                              ]
                            ], undefined),
                        onClick: (function (extra) {
                            return save(vnode, getValue$1, false, extra);
                          })
                      });
                return React.jsx(ListLayout.make, {
                            children: vnode.children,
                            title: match.level.name,
                            showBackButton: true,
                            onBackClicked: onBackClicked,
                            bottom: Caml_option.some(bottom$1),
                            bottomTaskBarStatus: bottomTaskBarStatus,
                            actions: actions
                          });
              }));
}

function startInspection(vnode, editUrl, _event) {
  var vessel = vnode.attrs.vessel;
  var level = vnode.attrs.level;
  var question = vnode.attrs.question;
  var dialogId = question.id;
  var draftSubmission = vnode.attrs.draftSubmission;
  var lastNewAnswer = vnode.attrs.lastNewAnswer;
  var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
  KeepScrollLink.saveScroll();
  if (isAlreadyAnswered) {
    return DialogNewEdit.openDialog(vnode, dialogId);
  } else {
    Mithril$1.route.set(editUrl(undefined, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                        return s.id;
                      }))), vessel.id, level.id, question.id));
    return ;
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question = vnode.attrs.question;
                var editUrl = vnode.attrs.editUrl;
                var lastAnswer = vnode.attrs.lastAnswer;
                var lastNewAnswer = vnode.attrs.lastNewAnswer;
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                var questionTitle = new MarkdownIt().render(Hyphenated.hyphenated(question.name));
                var questionNAModalId = question.id + "-na";
                var newAnswerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastNewAnswer, (function (a) {
                            return a.value;
                          })), undefined);
                var answerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastAnswer, (function (a) {
                            return a.value;
                          })), undefined);
                var answeredAsNA = newAnswerValue !== undefined ? (
                    typeof newAnswerValue === "object" ? false : true
                  ) : (
                    answerValue !== undefined && typeof answerValue !== "object" ? true : false
                  );
                var match = question.area;
                return React.jsxs(GestureWrapper.make, {
                            children: [
                              ReactDOM.jsxsKeyed("div", {
                                    children: [
                                      ReactDOM.jsxKeyed("div", {
                                            children: [
                                              match !== undefined ? (
                                                  match === "Hardware" ? React.jsx(Feather.make, {
                                                          icon: "hardware",
                                                          className: "question-icon col-1",
                                                          size: 24
                                                        }) : (
                                                      match === "Process" ? React.jsx(Feather.make, {
                                                              icon: "process",
                                                              className: "question-icon col-1",
                                                              size: 24
                                                            }) : React.jsx(Feather.make, {
                                                              icon: "hr",
                                                              className: "question-icon col-1",
                                                              size: 24
                                                            })
                                                    )
                                                ) : Mithril.empty,
                                              ReactDOM.jsx("div", {
                                                    children: Mithril$1.trust(questionTitle),
                                                    className: "col-11 col-sm-12 px-1"
                                                  })
                                            ],
                                            className: "text-small question-name col col-10 col-sm-12 d-flex"
                                          }, QuestionId.toHTMLID(question.id) + "title", undefined),
                                      ReactDOM.jsxsKeyed("div", {
                                            children: [
                                              React.jsx(Conditional.make, {
                                                    cond: isAlreadyAnswered,
                                                    children: React.jsx(DialogNewEditAnswer.make, {
                                                          id: question.id,
                                                          onCallback: (function (extra) {
                                                              var vesselId = vnode.attrs.vessel.id;
                                                              var levelId = vnode.attrs.level.id;
                                                              var questionId = vnode.attrs.question.id;
                                                              var draftSubmission = vnode.attrs.draftSubmission;
                                                              Mithril$1.route.set(editUrl(extra !== "Edit", Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                                                                                  return s.id;
                                                                                }))), vesselId, levelId, questionId));
                                                            })
                                                        })
                                                  }),
                                              vnode.children
                                            ],
                                            className: "tile-action question-answers col col-2 col-sm-12"
                                          }, QuestionId.toHTMLID(question.id) + "widget", undefined)
                                    ],
                                    className: "tw-rounded tw-shadow-kaiko-card tile tile-shadow tile-centered question p-2 mx-0 my-2 columns"
                                  }, QuestionId.toHTMLID(question.id), undefined),
                              React.jsxKeyed(Conditional.make, {
                                    cond: answeredAsNA,
                                    children: ReactDOM.jsx("div", {
                                          children: "Not Applicable",
                                          className: "label label-rounded label-primary text-uppercase not-applicable-label"
                                        })
                                  }, QuestionId.toHTMLID(question.id) + "na", undefined),
                              React.jsxKeyed(NotApplicableDialog.make, {
                                    id: questionNAModalId,
                                    header: "Question options",
                                    bodyText: "Do you want to answer this questions as Not Applicable? This action will replace the current answer.",
                                    actions: NotApplicableStatus.confirmationChoices,
                                    onCallback: (function (extra) {
                                        if (extra !== "yes") {
                                          return ;
                                        }
                                        KeepScrollLink.saveScroll();
                                        var vessel = vnode.attrs.vessel;
                                        var level = vnode.attrs.level;
                                        var question = vnode.attrs.question;
                                        var draftSubmission = vnode.attrs.draftSubmission;
                                        Mithril$1.route.set(URLs.createAnswerNotApplicable(Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                                                            return s.id;
                                                          }))), vessel.id, level.id, question.id));
                                      })
                                  }, QuestionId.toHTMLID(question.id) + "na-dialog", undefined)
                            ],
                            onSwipeLeft: (function (extra) {
                                return NotApplicableDialog.openDialog(vnode, question.id + "-na");
                              }),
                            rightContent: Caml_option.some(ReactDOM.jsx("span", {
                                      children: "N/A"
                                    })),
                            className: "answered-as-na",
                            enabled: !answeredAsNA
                          });
              }));
}

var Editor = {
  save: save,
  make: QuestionWidget$Editor
};

export {
  Editor ,
  startInspection ,
  make ,
}
/* URLs Not a pure module */

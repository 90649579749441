// Generated by ReScript, PLEASE EDIT WITH CARE


function make(param) {
  return function (_db, transaction) {
    transaction.objectStore("positions").deleteIndex("is_crew");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as QuestionId from "../../tree/QuestionId.js";
import * as FindingsAPI from "../endpoints/FindingsAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function convertLevel($$final, finding) {
  var answer = AppSchema.Answers.get($$final, finding.created_by_id);
  var match = finding.solved_by_id;
  var finding_id = finding.id;
  var finding_vessel_id = finding.vessel_id;
  var finding_created_by_id = finding.created_by_id;
  var finding_solved_by_id = finding.solved_by_id;
  var finding_level_id = finding.level_id;
  var finding_question_id = answer !== undefined ? answer.question_id : QuestionId.$$null;
  var finding_status = match !== undefined ? "rectified" : "active";
  var finding_deadline = finding.deadline;
  var finding_comments = finding.comments;
  var finding$1 = {
    id: finding_id,
    vessel_id: finding_vessel_id,
    created_by_id: finding_created_by_id,
    solved_by_id: finding_solved_by_id,
    level_id: finding_level_id,
    question_id: finding_question_id,
    status: finding_status,
    deadline: finding_deadline,
    comments: finding_comments
  };
  AppSchema.Findings.update($$final, finding$1);
  return $$final;
}

function convertFindings($$final, findings) {
  return Curry._3(Prelude.$$Array.fold, findings, $$final, convertLevel);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var questions = AppSchema.Questions.all($$final);
  if (Prelude.$$Array.isEmpty(questions)) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  } else {
    return Prelude.PromisedResult.map(FindingsAPI.getAll(undefined, {
                    vessel__id__in: vessels.join(",")
                  }, undefined, user), (function (findings) {
                  var $$final$1 = convertFindings($$final, findings);
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final$1
                        };
                }));
  }
}

var ReadServerState = {
  convertLevel: convertLevel,
  convertFindings: convertFindings,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.findings = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.findings, (function (extra) {
                        return AppSchema.Findings.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.findings = AppSchema.Findings.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncLevelFindings" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.Findings];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var views$1 = [AppSchema.Findings];

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup({
      views: views$1
    });

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncLevelFindings",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

Utils.MakeOpaqueIdentifier({});

var ProcedureId = Utils.MakeOpaqueIdentifier({});

var ProcedureReaderDef = {
  storeName: "procedures"
};

var ProcedureReader = IDB__Migration__Utils.MakeReader(ProcedureReaderDef);

function message(param, param$1) {
  return "Checking component's weight measurements";
}

function $$process(inspection, procedures) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Belt_Map.get(procedures, inspection.procedure_id), (function (proc) {
                    var match = proc.question_type;
                    if (match === "ComponentWeightMeasurementQuestion" && !Curry._1(Prelude.OptionExported.$$Option.isSome, inspection.component_weight_value)) {
                      console.error("Removing new inspection", inspection);
                      return "Delete";
                    } else {
                      return {
                              TAG: "Update",
                              _0: inspection
                            };
                    }
                  })), "Delete");
}

var NewInspectionRewriterDef = {
  storeName: "newInspections",
  message: message,
  $$process: $$process
};

var NewInspectionRewriter = InitFeedback.MakeRewriter(NewInspectionRewriterDef);

function message$1() {
  return "Checking component's weight measurements";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.map(Prelude.then(Prelude.thenResolve(ProcedureReader.read(transaction), (function (procedures) {
                        return Belt_Map.fromArray(procedures.map(function (p) {
                                        return [
                                                p.id,
                                                p
                                              ];
                                      }), ProcedureId.Comparator);
                      })), (function (procedures) {
                    return NewInspectionRewriter.rewrite(transaction, procedures);
                  })), (function () {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

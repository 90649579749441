// Generated by ReScript, PLEASE EDIT WITH CARE


function safeCode(error) {
  var x;
  try {
    x = error.code;
  }
  catch (exn){
    return 0;
  }
  if (x !== undefined) {
    return x;
  } else {
    return 0;
  }
}

export {
  safeCode ,
}
/* No side effect */

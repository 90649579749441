// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as QuestionWidget from "./QuestionWidget.js";
import * as DateQuestionUtils from "./DateQuestionUtils.js";

function DateQuestionWidget$Editor(props) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var question = match.question;
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("label", {
                                children: question.name,
                                className: "form-label",
                                htmlFor: question.id
                              }),
                          ReactDOM.jsx("input", {
                                className: "form-input",
                                id: question.id,
                                type: "date",
                                value: match.value,
                                onChange: (function (extra) {
                                    var match = vnode.state.data;
                                    if (typeof match !== "object") {
                                      return ;
                                    }
                                    var target = extra.target;
                                    var value;
                                    var exit = 0;
                                    var v;
                                    try {
                                      v = target.value;
                                      exit = 1;
                                    }
                                    catch (exn){
                                      value = undefined;
                                    }
                                    if (exit === 1) {
                                      if (v !== undefined) {
                                        var v$1 = Caml_option.valFromOption(v);
                                        value = v$1 !== "" ? Caml_option.some(v$1) : undefined;
                                      } else {
                                        value = undefined;
                                      }
                                    }
                                    var date = Curry._2(Prelude.OptionExported.$$Option.flatMap, value, DateQuestionUtils.dateFromString);
                                    return Mithril.setState(vnode, {
                                                TAG: "Loaded",
                                                lastNewAnswer: match.lastNewAnswer,
                                                lastAnswer: match.lastAnswer,
                                                question: match.question,
                                                date: date,
                                                level: match.level,
                                                value: value
                                              });
                                  })
                              })
                        ],
                        className: "form-group"
                      });
                }
                return React.jsx(QuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return {
                                          attachments: []
                                        };
                                } else {
                                  return {
                                          value: {
                                            NAME: "Value",
                                            VAL: {
                                              NAME: "Date",
                                              VAL: Prelude.default(match.date, new Date())
                                            }
                                          },
                                          attachments: []
                                        };
                                }
                              }),
                            allowSave: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                } else {
                                  return Curry._1(Prelude.OptionExported.$$Option.isSome, match.date);
                                }
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var date = match.date;
                                var lastNewAnswer = match.lastNewAnswer;
                                if (createNew) {
                                  return Curry._1(Prelude.OptionExported.$$Option.isSome, date);
                                }
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 === "object" && match$2.NAME === "Date") {
                                  return Caml_option.some(match$2.VAL) !== date;
                                } else {
                                  return false;
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var newState;
                                if (typeof extra !== "object") {
                                  newState = "NotReady";
                                } else {
                                  var lastNewAnswer = extra.lastNewAnswer;
                                  var match = vnode.state.data;
                                  var match$1;
                                  if (typeof match !== "object") {
                                    if (createNew || lastNewAnswer === undefined) {
                                      match$1 = [
                                        undefined,
                                        undefined
                                      ];
                                    } else {
                                      var match$2 = lastNewAnswer.value;
                                      if (match$2 !== undefined && typeof match$2 === "object" && match$2.NAME === "Value") {
                                        var match$3 = match$2.VAL;
                                        if (typeof match$3 === "object" && match$3.NAME === "Date") {
                                          var value = match$3.VAL;
                                          match$1 = [
                                            DateQuestionUtils.formatDate(value),
                                            Caml_option.some(value)
                                          ];
                                        } else {
                                          match$1 = [
                                            undefined,
                                            undefined
                                          ];
                                        }
                                      } else {
                                        match$1 = [
                                          undefined,
                                          undefined
                                        ];
                                      }
                                    }
                                  } else {
                                    match$1 = [
                                      match.value,
                                      match.date
                                    ];
                                  }
                                  newState = {
                                    TAG: "Loaded",
                                    lastNewAnswer: lastNewAnswer,
                                    lastAnswer: extra.lastAnswer,
                                    question: extra.question,
                                    date: match$1[1],
                                    level: extra.level,
                                    value: match$1[0]
                                  };
                                }
                                return Mithril.setState(vnode, newState);
                              }),
                            children: tmp
                          });
              }));
}

function DateQuestionWidget(props) {
  var draftSubmission = props.draftSubmission;
  var lastAnswer = props.lastAnswer;
  var lastNewAnswer = props.lastNewAnswer;
  var vessel = props.vessel;
  var level = props.level;
  var question = props.question;
  return Mithril.view(Mithril.component(), (function (vnode) {
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                var submissionId = Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                        return s.id;
                      }));
                var editUrl = function (none, extra, extra$1, extra$2, extra$3) {
                  return URLs.dateQuestionWidgetEditor(none, Prelude.default(Mithril__Route.paramBool("isRectification"), false), extra, extra$1, extra$2, extra$3);
                };
                return React.jsx(QuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            draftSubmission: draftSubmission,
                            editUrl: editUrl,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "calendar"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active ",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isNone(submissionId)
                                        ],
                                        [
                                          "bg-success",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isSome(submissionId)
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return QuestionWidget.startInspection(vnode, editUrl, extra);
                                    })
                                })
                          });
              }));
}

var Editor = {
  make: DateQuestionWidget$Editor
};

var make = DateQuestionWidget;

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Dialog from "../../../libs/Dialog.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Inspection from "../../survey/Inspection.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as State__User from "../../state/State__User.js";
import * as StepCounter from "../../runtime/StepCounter.js";
import * as InspectionId from "../../survey/InspectionId.js";
import * as QuestionType from "../../survey/QuestionType.js";
import * as DialogNewEdit from "../../../libs/DialogNewEdit.js";
import * as GestureWrapper from "../../../libs/GestureWrapper.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as ProcedureAnswer from "../../survey/ProcedureAnswer.js";
import * as ConditionMatcher from "../../common/ConditionMatcher.js";
import * as State__Procedures from "../../state/State__Procedures.js";
import * as State__Inspections from "../../state/State__Inspections.js";
import * as NotApplicableDialog from "./NotApplicableDialog.js";
import * as NotApplicableStatus from "../../survey/NotApplicableStatus.js";
import * as DialogNewEditInspection from "./DialogNewEditInspection.js";
import * as InlineProcedureDescription from "./InlineProcedureDescription.js";
import * as DialogAdditionalAnnotations from "./DialogAdditionalAnnotations.js";

function oldInspections(vnode) {
  var draftSubmission = vnode.attrs.draftSubmission;
  if (draftSubmission !== undefined) {
    return Curry._2(Prelude.$$Array.keep, Prelude.default(vnode.attrs.oldInspections, []), (function (i) {
                  return Prelude.$$Array.includes(draftSubmission.inspections, i.id);
                }));
  } else {
    return Prelude.default(vnode.attrs.oldInspections, []);
  }
}

function newInspections(vnode) {
  var draftSubmission = vnode.attrs.draftSubmission;
  if (draftSubmission !== undefined) {
    return Curry._2(Prelude.$$Array.keep, Prelude.default(vnode.attrs.newInspections, []), (function (i) {
                  return Prelude.$$Array.includes(draftSubmission.inspections, i.id);
                }));
  } else {
    return Prelude.default(vnode.attrs.newInspections, []);
  }
}

function lastOldInspection(vnode) {
  return Inspection.lastInspection(oldInspections(vnode));
}

function lastInspection(vnode, submissionOpt, param) {
  var submission = submissionOpt !== undefined ? Caml_option.valFromOption(submissionOpt) : undefined;
  var inspections = Curry._2(Prelude.$$Array.concat, Prelude.default(vnode.attrs.oldInspections, []), Prelude.default(vnode.attrs.newInspections, []));
  if (submission !== undefined) {
    return Inspection.lastInspection(Curry._2(Prelude.$$Array.keep, inspections, (function (i) {
                      return Prelude.$$Array.includes(submission.inspections, i.id);
                    })));
  } else {
    return Inspection.lastInspection(inspections);
  }
}

function requiresNewInspection(vnode) {
  var procedure = vnode.attrs.procedure;
  var requireAnswer = Prelude.default(vnode.attrs.requireAnswer, false);
  var match = procedure.not_applicable_status;
  if (match !== undefined) {
    return false;
  } else if (requireAnswer) {
    return true;
  } else {
    return !Procedure.isFullyInspected(procedure);
  }
}

function extraCssClass(vnode, lastOldInspection, lastNewInspection) {
  var procedure = vnode.attrs.procedure;
  var requireAnswer = Prelude.default(vnode.attrs.requireAnswer, false);
  var inspectedInPast = Procedure.isFullyInspected(procedure);
  var neverInspected = lastOldInspection === undefined;
  var newInspectionToUpload = lastNewInspection !== undefined;
  if (requireAnswer) {
    return "btn-link";
  } else if (newInspectionToUpload) {
    return "btn-primary";
  } else if (neverInspected) {
    return "btn-link";
  } else if (inspectedInPast) {
    if (Procedure.isOverdue(procedure) || Procedure.isDue(procedure)) {
      return "btn-link bg-gray";
    } else {
      return "btn-success";
    }
  } else if (Procedure.isOverdue(procedure)) {
    return "btn-link";
  } else if (Procedure.isDue(procedure)) {
    return "btn-link bg-gray";
  } else {
    return "btn-success";
  }
}

function isAlreadyInspected(vnode) {
  return Caml_obj.notequal(newInspections(vnode), []);
}

function buttonClass(vnode, expectedAnswer, lastNewInspectionOpt, disabledOpt, param) {
  var lastNewInspection = lastNewInspectionOpt !== undefined ? Caml_option.valFromOption(lastNewInspectionOpt) : undefined;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var lastNewInspection$1 = lastNewInspection !== undefined ? lastNewInspection : Inspection.lastInspection(newInspections(vnode));
  var lastOldInspection$1 = lastOldInspection(vnode);
  var cssClass = lastNewInspection$1 !== undefined ? (
      expectedAnswer !== undefined ? (
          QuestionType.Value.getAnswer(lastNewInspection$1.value) === expectedAnswer ? extraCssClass(vnode, lastOldInspection$1, lastNewInspection$1) : "btn-link"
        ) : "btn-primary"
    ) : (
      lastOldInspection$1 !== undefined ? (
          expectedAnswer !== undefined ? (
              QuestionType.Value.getAnswer(lastOldInspection$1.value) === expectedAnswer ? extraCssClass(vnode, lastOldInspection$1, lastNewInspection$1) : "btn-link"
            ) : (
              Procedure.isInspectedOnTimeBy(vnode.attrs.procedure, lastOldInspection$1) ? extraCssClass(vnode, lastOldInspection$1, lastNewInspection$1) : "btn-link"
            )
        ) : "btn-link"
    );
  if (disabled) {
    return cssClass + " disabled";
  } else {
    return cssClass;
  }
}

function onDialogCallback(vnode, startInspectionUrl, action) {
  var newInspections$1 = newInspections(vnode);
  var inspectionId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(newInspections$1), (function (i) {
              return i.id;
            })), InspectionId.$$null);
  if (action === "Edit") {
    Mithril$1.route.set(URLs.editInspection(inspectionId));
  } else {
    Mithril$1.route.set(startInspectionUrl);
  }
}

function startInspection(vnode, startInspectionUrl, _event) {
  var dialogId = vnode.attrs.procedure.id;
  KeepScrollLink.saveScrollById(ProcedureId.toHTMLID(vnode.attrs.procedure.id));
  if (isAlreadyInspected(vnode)) {
    return DialogNewEdit.openDialog(vnode, dialogId);
  } else {
    Mithril$1.route.set(startInspectionUrl);
    return ;
  }
}

function showMarkNAModal(vnode, dialogId, _event) {
  NotApplicableDialog.openDialog(vnode, dialogId);
}

function markProcedureAsNotApplicable(vnode, action) {
  var procedure = vnode.attrs.procedure;
  var actionValue = action === "" ? undefined : action;
  Prelude.thenDo(State__Procedures.updateNotApplicableStatus(procedure, actionValue), (function (param) {
          var callback = Prelude.default(vnode.attrs.onChangeProcedure, (function (x) {
                  console.log("Procedure updated", x);
                }));
          callback(procedure);
        }));
}

function removeNotApplicableFromProcedure(vnode, _event) {
  var procedure = vnode.attrs.procedure;
  Prelude.thenDo(State__Procedures.updateNotApplicableStatus(procedure, undefined), (function (param) {
          var callback = Prelude.default(vnode.attrs.onChangeProcedure, (function (x) {
                  console.log("Procedure updated", x);
                }));
          callback(procedure);
        }));
}

function lastAnswer(vnode) {
  return Curry._2(Prelude.OptionExported.$$Option.map, lastInspection(vnode, undefined, undefined), (function (inspection) {
                return ProcedureAnswer.fromRating(QuestionType.Value.rate(inspection.value));
              }));
}

function answer(additional_annotationOpt, vnode, currentAnswer) {
  var additional_annotation = additional_annotationOpt !== undefined ? additional_annotationOpt : "";
  var draftSubmission = vnode.attrs.draftSubmission;
  Prelude.thenDo(Prelude.then(State__User.getCurrent(), StepCounter.start), (function () {
          var procedure = vnode.attrs.procedure;
          var rating = ProcedureAnswer.toRating(currentAnswer);
          var match = procedure.question_type;
          Prelude.thenDo(match === "OkNotOkQuestion" ? Prelude.thenResolve(ConditionMatcher.OkNotOkProcedure.match(procedure, lastAnswer(vnode), currentAnswer), (function (param) {
                        var randomly = param[1];
                        var required = param[0];
                        console.log("Picture prompt? Required: ", required, "Randomly: ", randomly);
                        return [
                                required,
                                randomly
                              ];
                      })) : Promise.resolve([
                      false,
                      false
                    ]), (function (param) {
                  if (param[0]) {
                    var url = URLs.startInspectionProcedure(procedure.id, rating, Caml_option.some(param[1]), undefined, undefined, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                    return i.id;
                                  }))), undefined);
                    KeepScrollLink.saveScrollById(ProcedureId.toHTMLID(procedure.id));
                    Mithril$1.route.set(url);
                    return ;
                  }
                  var value = QuestionType.Value.make(procedure.question_type, undefined, Caml_option.some(rating), undefined, undefined, undefined, Caml_option.some(currentAnswer === "Yes"), undefined, undefined);
                  Prelude.thenDo(value !== undefined ? State__Inspections.make(procedure, undefined, undefined, value, undefined, undefined, undefined, additional_annotation, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                        return i.id;
                                      }))), undefined) : Promise.resolve([
                              procedure,
                              undefined
                            ]), (function (procedureAndInspection) {
                          var match = vnode.attrs.onInspectionCreated;
                          if (match !== undefined) {
                            return match(procedureAndInspection[0], procedureAndInspection[1]);
                          }
                          var inspection = procedureAndInspection[1];
                          if (inspection !== undefined) {
                            return Mithril.setState(vnode, inspection);
                          } else {
                            return Mithril.setState(vnode, undefined);
                          }
                        }));
                }));
        }));
}

function answerYesWithAnnotations(vnode, annotation) {
  answer(annotation, vnode, "Yes");
}

function onDialogCallbackQuestion(vnode, procedureAnswer, action) {
  var procedure = vnode.attrs.procedure;
  var inspectionId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(newInspections(vnode)), (function (i) {
              return i.id;
            })), InspectionId.$$null);
  if (action === "Edit") {
    Mithril$1.route.set(URLs.editInspection(inspectionId));
    return ;
  } else if (procedure.always_require_additional_annotation && procedureAnswer === "Yes") {
    return Dialog.openDialog(vnode, procedure.id + "additional-annotations");
  } else {
    return answer(undefined, vnode, procedureAnswer);
  }
}

function startInspection$1(vnode, dialogId, procedureAnswer, _event) {
  var procedure = vnode.attrs.procedure;
  KeepScrollLink.saveScrollById(ProcedureId.toHTMLID(vnode.attrs.procedure.id));
  if (isAlreadyInspected(vnode)) {
    return DialogNewEdit.openDialog(vnode, dialogId);
  } else if (procedure.always_require_additional_annotation && procedureAnswer === "Yes") {
    return Dialog.openDialog(vnode, procedure.id + "additional-annotations");
  } else {
    return answer(undefined, vnode, procedureAnswer);
  }
}

function make(param) {
  return Mithril.view(Mithril.component(undefined), (function (vnode) {
                var procedure = vnode.attrs.procedure;
                var companyFormRel = vnode.attrs.companyFormRel;
                var name = Prelude.default(vnode.attrs.name, procedure.name);
                var procedureNAModalId = procedure.id + "-na";
                var canMarkAsNotApplicable = Procedure.canMarkAsNotApplicable(procedure) || Caml_obj.equal(Curry._2(Prelude.OptionExported.$$Option.map, companyFormRel, (function (c) {
                            return c.procedure_id;
                          })), Caml_option.some(procedure.id)) && procedure.allow_not_applicable_status;
                var selectedStatus = procedure.not_applicable_status;
                var hasSelectedStatus = selectedStatus !== undefined;
                var procedureNotApplicableStatus = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, selectedStatus, (function (status) {
                            return ReactDOM.jsxs("button", {
                                        children: [
                                          ReactDOM.jsx("span", {
                                                children: NotApplicableStatus.getLabel(status)
                                              }),
                                          React.jsx(Feather.make, {
                                                icon: "x",
                                                className: "ml-1",
                                                size: 16
                                              })
                                        ],
                                        className: "btn chip bg-primary text-secondary text-tiny",
                                        onClick: (function (extra) {
                                            return removeNotApplicableFromProcedure(vnode, extra);
                                          })
                                      });
                          })), Mithril.empty);
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var questionElement = [
                  ReactDOM.jsx("div", {
                        className: "divider m-0"
                      }),
                  ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsxs("div", {
                                children: [
                                  name,
                                  ReactDOM.jsx("br", {}),
                                  procedureNotApplicableStatus,
                                  React.jsx(InlineProcedureDescription.make, {
                                        procedure: procedure
                                      })
                                ],
                                className: "tile-content text-small"
                              }),
                          ReactDOM.jsxs("div", {
                                children: [
                                  React.jsx(Conditional.make, {
                                        cond: isAlreadyInspected(vnode),
                                        children: React.jsx(DialogNewEditInspection.make, {
                                              id: "1" + procedure.id,
                                              onCallback: (function (extra) {
                                                  return onDialogCallbackQuestion(vnode, "Yes", extra);
                                                })
                                            })
                                      }),
                                  React.jsx(Conditional.make, {
                                        cond: procedure.always_require_additional_annotation,
                                        children: React.jsx(DialogAdditionalAnnotations.make, {
                                              id: procedure.id + "additional-annotations",
                                              currentValue: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(newInspections(vnode)), (function (i) {
                                                          return i.additional_annotation;
                                                        })), undefined),
                                              onCallback: (function (extra) {
                                                  return answerYesWithAnnotations(vnode, extra);
                                                })
                                            })
                                      }),
                                  ReactDOM.jsx("button", {
                                        children: React.jsx(Feather.make, {
                                              icon: "check"
                                            }),
                                        className: "t-yes-answer btn btn-action s-circle mx-1 " + buttonClass(vnode, "Yes", Caml_option.some(vnode.state.data), hasSelectedStatus, undefined),
                                        onClick: (function (extra) {
                                            return startInspection$1(vnode, "1" + procedure.id, "Yes", extra);
                                          })
                                      }),
                                  React.jsx(Conditional.make, {
                                        cond: isAlreadyInspected(vnode),
                                        children: React.jsx(DialogNewEditInspection.make, {
                                              id: "2" + procedure.id,
                                              onCallback: (function (extra) {
                                                  return onDialogCallbackQuestion(vnode, "No", extra);
                                                })
                                            })
                                      }),
                                  ReactDOM.jsx("button", {
                                        children: React.jsx(Feather.make, {
                                              icon: "x"
                                            }),
                                        className: "t-no-answer btn btn-action s-circle " + buttonClass(vnode, "No", Caml_option.some(vnode.state.data), hasSelectedStatus, undefined),
                                        onClick: (function (extra) {
                                            return startInspection$1(vnode, "2" + procedure.id, "No", extra);
                                          })
                                      })
                                ],
                                className: "tile-action self-centered"
                              })
                        ],
                        className: Mithril.$$class([
                              [
                                "tile tile-centered question t-question p-2",
                                true
                              ],
                              [
                                Prelude.default(counterOverlayClassName, ""),
                                Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                              ]
                            ], undefined),
                        data: Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName) ? ({
                              incomplete: requiresNewInspection(vnode) ? 1 : 0
                            }) : ({
                              incomplete: 0
                            })
                      })
                ];
                var gestureRightContent = ReactDOM.jsx("span", {
                      children: "N/A"
                    });
                if (canMarkAsNotApplicable) {
                  return React.jsxs(GestureWrapper.make, {
                              children: [
                                questionElement,
                                React.jsx(NotApplicableDialog.make, {
                                      id: procedureNAModalId,
                                      onCallback: (function (extra) {
                                          return markProcedureAsNotApplicable(vnode, extra);
                                        }),
                                      selected: selectedStatus
                                    })
                              ],
                              onSwipeLeft: (function (extra) {
                                  return NotApplicableDialog.openDialog(vnode, procedureNAModalId);
                                }),
                              rightContent: Caml_option.some(gestureRightContent)
                            });
                } else {
                  return questionElement;
                }
              }));
}

var Question = {
  lastAnswer: lastAnswer,
  answer: answer,
  answerYesWithAnnotations: answerYesWithAnnotations,
  onDialogCallbackQuestion: onDialogCallbackQuestion,
  startInspection: startInspection$1,
  make: make
};

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var draftSubmission = vnode.attrs.draftSubmission;
                var procedure = vnode.attrs.procedure;
                var name = Prelude.default(vnode.attrs.name, procedure.name);
                var startInspectionUrl = URLs.startInspectionProcedure(procedure.id, undefined, undefined, undefined, undefined, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                return i.id;
                              }))), undefined);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      name,
                                      React.jsx(InlineProcedureDescription.make, {
                                            procedure: procedure
                                          })
                                    ],
                                    className: "tile-content text-small"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(Conditional.make, {
                                            cond: isAlreadyInspected(vnode),
                                            children: React.jsx(DialogNewEditInspection.make, {
                                                  id: procedure.id,
                                                  onCallback: (function (extra) {
                                                      return onDialogCallback(vnode, startInspectionUrl, extra);
                                                    })
                                                })
                                          }),
                                      ReactDOM.jsx("button", {
                                            children: React.jsx(Feather.make, {
                                                  icon: "camera"
                                                }),
                                            className: "btn btn-action s-circle " + buttonClass(vnode, undefined, undefined, undefined, undefined),
                                            onClick: (function (extra) {
                                                return startInspection(vnode, startInspectionUrl, extra);
                                              })
                                          })
                                    ],
                                    className: "tile-action self-centered"
                                  })
                            ],
                            className: Mithril.$$class([
                                  [
                                    "tile tile-centered question t-question t-picture px-2",
                                    true
                                  ],
                                  [
                                    Prelude.default(counterOverlayClassName, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                                  ]
                                ], undefined),
                            data: Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName) ? ({
                                  incomplete: requiresNewInspection(vnode) ? 1 : 0
                                }) : ({
                                  incomplete: 0
                                })
                          });
              }));
}

var Picture = {
  make: make$1
};

function onDialogCallback$1(vnode, action) {
  var draftSubmission = vnode.attrs.draftSubmission;
  var procedure = vnode.attrs.procedure;
  var inspectionId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(newInspections(vnode)), (function (i) {
              return i.id;
            })), InspectionId.$$null);
  if (action === "Edit") {
    Mithril$1.route.set(URLs.startSignatureInspection(procedure.id, Caml_option.some(Caml_option.some(inspectionId)), true, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                        return i.id;
                      }))), undefined));
  } else {
    Mithril$1.route.set(URLs.startSignatureInspection(procedure.id, undefined, undefined, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                        return i.id;
                      }))), undefined));
  }
}

function make$2(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var procedure = vnode.attrs.procedure;
                var draftSubmission = vnode.attrs.draftSubmission;
                var name = Prelude.default(vnode.attrs.name, procedure.name);
                var startInspectionUrl = URLs.startSignatureInspection(procedure.id, undefined, undefined, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                return i.id;
                              }))), undefined);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      name,
                                      React.jsx(InlineProcedureDescription.make, {
                                            procedure: procedure
                                          })
                                    ],
                                    className: "tile-content text-small"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(Conditional.make, {
                                            cond: isAlreadyInspected(vnode),
                                            children: React.jsx(DialogNewEditInspection.make, {
                                                  id: procedure.id,
                                                  onCallback: (function (extra) {
                                                      return onDialogCallback$1(vnode, extra);
                                                    })
                                                })
                                          }),
                                      ReactDOM.jsx("button", {
                                            children: React.jsx(Feather.make, {
                                                  icon: "pen_tool"
                                                }),
                                            className: "btn btn-action s-circle " + buttonClass(vnode, undefined, undefined, undefined, undefined),
                                            onClick: (function (extra) {
                                                return startInspection(vnode, startInspectionUrl, extra);
                                              })
                                          })
                                    ],
                                    className: "tile-action self-centered"
                                  })
                            ],
                            className: Mithril.$$class([
                                  [
                                    "tile tile-centered question t-question t-picture px-2",
                                    true
                                  ],
                                  [
                                    Prelude.default(counterOverlayClassName, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                                  ]
                                ], undefined),
                            data: Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName) ? ({
                                  incomplete: requiresNewInspection(vnode) ? 1 : 0
                                }) : ({
                                  incomplete: 0
                                })
                          });
              }));
}

var Signature = {
  onDialogCallback: onDialogCallback$1,
  make: make$2
};

function onDialogCallback$2(vnode, action) {
  var procedure = vnode.attrs.procedure;
  var draftSubmission = vnode.attrs.draftSubmission;
  var inspectionId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(newInspections(vnode)), (function (i) {
              return i.id;
            })), InspectionId.$$null);
  if (action === "Edit") {
    Mithril$1.route.set(URLs.startWeightMeasurementInspectionProcedure(procedure.id, Caml_option.some(Caml_option.some(inspectionId)), undefined, true, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                        return i.id;
                      }))), undefined));
  } else {
    Mithril$1.route.set(URLs.startWeightMeasurementInspectionProcedure(procedure.id, undefined, undefined, undefined, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                        return i.id;
                      }))), undefined));
  }
}

function make$3(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var draftSubmission = vnode.attrs.draftSubmission;
                var procedure = vnode.attrs.procedure;
                var name = Prelude.default(vnode.attrs.name, procedure.name);
                var startInspectionUrl = URLs.startWeightMeasurementInspectionProcedure(procedure.id, undefined, undefined, undefined, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                return i.id;
                              }))), undefined);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      name,
                                      React.jsx(InlineProcedureDescription.make, {
                                            procedure: procedure
                                          })
                                    ],
                                    className: "tile-content text-small"
                                  }),
                              React.jsx(Conditional.make, {
                                    cond: isAlreadyInspected(vnode),
                                    children: React.jsx(DialogNewEditInspection.make, {
                                          id: procedure.id,
                                          onCallback: (function (extra) {
                                              return onDialogCallback$2(vnode, extra);
                                            })
                                        })
                                  }),
                              ReactDOM.jsx("button", {
                                    children: React.jsx(Feather.make, {
                                          icon: "activity"
                                        }),
                                    className: "btn btn-action s-circle " + buttonClass(vnode, undefined, undefined, undefined, undefined),
                                    onClick: (function (extra) {
                                        return startInspection(vnode, startInspectionUrl, extra);
                                      })
                                  })
                            ],
                            className: Mithril.$$class([
                                  [
                                    "tile tile-centered question t-question t-picture px-2",
                                    true
                                  ],
                                  [
                                    Prelude.default(counterOverlayClassName, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                                  ]
                                ], undefined),
                            data: Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName) ? ({
                                  incomplete: requiresNewInspection(vnode) ? 1 : 0
                                }) : ({
                                  incomplete: 0
                                })
                          });
              }));
}

var WeightMeasurement = {
  onDialogCallback: onDialogCallback$2,
  make: make$3
};

function date(vnode) {
  var lastNewInspection = Inspection.lastInspection(newInspections(vnode));
  if (lastNewInspection !== undefined) {
    return Prelude.default(Prelude.$$Array.first(Prelude.default(QuestionType.Value.getDate(lastNewInspection.value), new Date()).toISOString().split("T")), "");
  } else {
    return "";
  }
}

function make$4(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var procedure = vnode.attrs.procedure;
                var draftSubmission = vnode.attrs.draftSubmission;
                var name = Prelude.default(vnode.attrs.name, procedure.name);
                var startInspectionUrl = URLs.startDateInspection(procedure.id, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                return i.id;
                              }))), undefined);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      name,
                                      React.jsx(InlineProcedureDescription.make, {
                                            procedure: procedure
                                          })
                                    ],
                                    className: "tile-content text-small"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(Conditional.make, {
                                            cond: isAlreadyInspected(vnode),
                                            children: React.jsx(DialogNewEditInspection.make, {
                                                  id: procedure.id,
                                                  onCallback: (function (extra) {
                                                      return onDialogCallback(vnode, startInspectionUrl, extra);
                                                    })
                                                })
                                          }),
                                      ReactDOM.jsx("button", {
                                            children: ReactDOM.jsxs("span", {
                                                  children: [
                                                    ReactDOM.jsx("span", {
                                                          children: date(vnode),
                                                          className: Mithril.$$class([
                                                                [
                                                                  "mr-2 text-tiny ",
                                                                  true
                                                                ],
                                                                [
                                                                  "inline_date",
                                                                  date(vnode) !== ""
                                                                ]
                                                              ], undefined)
                                                        }),
                                                    React.jsx(Feather.make, {
                                                          icon: "calendar"
                                                        })
                                                  ],
                                                  className: "d-flex"
                                                }),
                                            className: Mithril.$$class([
                                                  [
                                                    "btn " + buttonClass(vnode, undefined, undefined, undefined, undefined),
                                                    true
                                                  ],
                                                  [
                                                    "btn-action s-circle ",
                                                    date(vnode) === ""
                                                  ],
                                                  [
                                                    "p-1 ",
                                                    date(vnode) !== ""
                                                  ]
                                                ], undefined),
                                            onClick: (function (extra) {
                                                return startInspection(vnode, startInspectionUrl, extra);
                                              })
                                          })
                                    ],
                                    className: "tile-action self-centered"
                                  })
                            ],
                            className: Mithril.$$class([
                                  [
                                    "tile tile-centered question t-question t-picture px-2",
                                    true
                                  ],
                                  [
                                    Prelude.default(counterOverlayClassName, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                                  ]
                                ], undefined),
                            data: Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName) ? ({
                                  incomplete: requiresNewInspection(vnode) ? 1 : 0
                                }) : ({
                                  incomplete: 0
                                })
                          });
              }));
}

var InlineDate = {
  date: date,
  make: make$4
};

function make$5(param) {
  return Mithril.view(Mithril.component(false), (function (vnode) {
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var procedure = vnode.attrs.procedure;
                var draftSubmission = vnode.attrs.draftSubmission;
                var name = Prelude.default(vnode.attrs.name, procedure.name);
                var startInspectionUrl = URLs.startTextInspection(procedure.id, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                return i.id;
                              }))), undefined);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      name,
                                      React.jsx(InlineProcedureDescription.make, {
                                            procedure: procedure
                                          })
                                    ],
                                    className: "tile-content text-small"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(Conditional.make, {
                                            cond: isAlreadyInspected(vnode),
                                            children: React.jsx(DialogNewEditInspection.make, {
                                                  id: procedure.id,
                                                  onCallback: (function (extra) {
                                                      return onDialogCallback(vnode, startInspectionUrl, extra);
                                                    })
                                                })
                                          }),
                                      ReactDOM.jsx("button", {
                                            children: React.jsx(Feather.make, {
                                                  icon: "type"
                                                }),
                                            className: "btn btn-action s-circle " + buttonClass(vnode, undefined, undefined, undefined, undefined),
                                            onClick: (function (extra) {
                                                return startInspection(vnode, startInspectionUrl, extra);
                                              })
                                          })
                                    ],
                                    className: "tile-action self-centered"
                                  })
                            ],
                            className: Mithril.$$class([
                                  [
                                    "tile tile-centered question t-question t-picture px-2",
                                    true
                                  ],
                                  [
                                    Prelude.default(counterOverlayClassName, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                                  ]
                                ], undefined),
                            data: Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName) ? ({
                                  incomplete: requiresNewInspection(vnode) ? 1 : 0
                                }) : ({
                                  incomplete: 0
                                })
                          });
              }));
}

var InlineText = {
  make: make$5
};

function make$6(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var procedure = vnode.attrs.procedure;
                var draftSubmission = vnode.attrs.draftSubmission;
                var name = Prelude.default(vnode.attrs.name, procedure.name);
                var startInspectionUrl = URLs.startIntegerInspection(procedure.id, Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (i) {
                                return i.id;
                              }))), undefined);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      name,
                                      React.jsx(InlineProcedureDescription.make, {
                                            procedure: procedure
                                          })
                                    ],
                                    className: "tile-content text-small"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(Conditional.make, {
                                            cond: isAlreadyInspected(vnode),
                                            children: React.jsx(DialogNewEditInspection.make, {
                                                  id: procedure.id,
                                                  onCallback: (function (extra) {
                                                      return onDialogCallback(vnode, startInspectionUrl, extra);
                                                    })
                                                })
                                          }),
                                      ReactDOM.jsx("button", {
                                            children: React.jsx(Feather.make, {
                                                  icon: "hash"
                                                }),
                                            className: "btn btn-action s-circle " + buttonClass(vnode, undefined, undefined, undefined, undefined),
                                            onClick: (function (extra) {
                                                return startInspection(vnode, startInspectionUrl, extra);
                                              })
                                          })
                                    ],
                                    className: "tile-action self-centered"
                                  })
                            ],
                            className: Mithril.$$class([
                                  [
                                    "tile tile-centered question t-question t-picture px-2",
                                    true
                                  ],
                                  [
                                    Prelude.default(counterOverlayClassName, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                                  ]
                                ], undefined),
                            data: Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName) ? ({
                                  incomplete: requiresNewInspection(vnode) ? 1 : 0
                                }) : ({
                                  incomplete: 0
                                })
                          });
              }));
}

var InlineInteger = {
  make: make$6
};

export {
  oldInspections ,
  newInspections ,
  lastOldInspection ,
  lastInspection ,
  requiresNewInspection ,
  extraCssClass ,
  isAlreadyInspected ,
  buttonClass ,
  onDialogCallback ,
  startInspection ,
  showMarkNAModal ,
  markProcedureAsNotApplicable ,
  removeNotApplicableFromProcedure ,
  Question ,
  Picture ,
  Signature ,
  WeightMeasurement ,
  InlineDate ,
  InlineText ,
  InlineInteger ,
}
/* URLs Not a pure module */

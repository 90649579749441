// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../Answer.js";
import * as Finding from "../Finding.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as CommentDetails from "../../comments/views/CommentDetails.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question = vnode.attrs.question;
                var comments = vnode.attrs.comments;
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("h5", {
                                          children: question.name
                                        }),
                                    comments.map(function (comment) {
                                          var profile = comment.profile;
                                          return React.jsxKeyed(CommentDetails.make, {
                                                      author: profile !== undefined ? UserProfile.nameWithRank(profile) : comment.author.first_name + " " + comment.author.last_name,
                                                      color: "initial",
                                                      body: comment.body,
                                                      date: comment.timestamp,
                                                      isNew: true
                                                    }, comment.id, undefined);
                                        })
                                  ],
                                  className: "tile-content"
                                }),
                            className: "tile p-0"
                          });
              }));
}

var FindingCommentCard = {
  make: make
};

function populate(vnode) {
  var finding_ids = vnode.attrs.finding_ids;
  Prelude.thenDo(Query.$$do([
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.findings = {
                    TAG: "In",
                    _0: finding_ids
                  };
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var findings = param.findings;
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.answers = {
                    TAG: "In",
                    _0: findings.map(Finding.byDetectedWith)
                  };
                  newrecord.levels = {
                    TAG: "In",
                    _0: findings.map(Finding.byLevel)
                  };
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.questions = {
                    TAG: "In",
                    _0: param.answers.map(Answer.byQuestion)
                  };
                  return newrecord;
                })
            }
          ]), (function (param) {
          Mithril.setState(vnode, {
                TAG: "Found",
                findings: param.findings,
                answers: param.answers,
                questions: param.questions,
                levels: param.levels
              });
        }));
}

function make$1(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), populate), (function (vnode) {
                var comments = vnode.attrs.comments;
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var levels = match.levels;
                var questions = match.questions;
                var answers = match.answers;
                return ReactDOM.jsx("div", {
                            children: match.findings.map(function (finding) {
                                  var findingComments = Curry._2(Prelude.$$Array.keep, comments, (function (c) {
                                          return Caml_obj.equal(c.object_id, finding.id);
                                        }));
                                  var answer = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, answers, (function (a) {
                                              return Caml_obj.equal(a.id, finding.created_by_id);
                                            })));
                                  var question = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                                              return Caml_obj.equal(q.id, answer.question_id);
                                            })));
                                  var level = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                              return Caml_obj.equal(l.id, finding.level_id);
                                            })));
                                  var key = finding.id;
                                  return React.jsxKeyed(make, {
                                              finding: finding,
                                              answer: answer,
                                              question: question,
                                              level: level,
                                              comments: findingComments
                                            }, key, undefined);
                                }),
                            className: "t-findings"
                          });
              }));
}

export {
  FindingCommentCard ,
  populate ,
  make$1 as make,
}
/* Query Not a pure module */

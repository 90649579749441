// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing updates for SIRE 2.0 (landscape pictures)";
}

function $$process(vessel) {
  return {
          id: vessel.id,
          name: vessel.name,
          max_pic_resolution: vessel.max_pic_resolution,
          picture_prompt_every_n_inspections: vessel.picture_prompt_every_n_inspections,
          require_landscape_pictures: false
        };
}

var VesselRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "vessels",
      message: message,
      $$process: $$process
    });

function message$1(param, param$1) {
  return "Installing updates for SIRE 2.0 (landscape pictures)";
}

function $$process$1(proc) {
  return {
          id: proc.id,
          component_id: proc.component_id,
          name: proc.name,
          order: proc.order,
          cooldown: proc.cooldown,
          position_diagram_id: proc.position_diagram_id,
          position_diagram_desc: proc.position_diagram_desc,
          example_image_id: proc.example_image_id,
          example_image_desc: proc.example_image_desc,
          description: proc.description,
          require_landscape_pictures: false
        };
}

var ProcedureRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "procedures",
      message: message$1,
      $$process: $$process$1
    });

function message$2() {
  return "Installing updates for SIRE 2.0 (landscape pictures)";
}

function migrate(_db, transaction) {
  return Prelude.PromisedResult.bind(VesselRewriter.rewrite(transaction), (function () {
                return ProcedureRewriter.rewrite(transaction);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$2,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* VesselRewriter Not a pure module */

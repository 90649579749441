// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "./rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "./rescript-mithril/src/ReactDOM.js";

function make(param) {
  var stop = function (vnode) {
    var match = vnode.state.data;
    if (typeof match !== "object") {
      return ;
    }
    if (match.TAG !== "Running") {
      return ;
    }
    clearInterval(match.ticker);
  };
  var start = function (vnode) {
    var match = vnode.state.data;
    if (typeof match === "object") {
      return ;
    }
    var timeout = vnode.attrs.timeout;
    var interval = Prelude.default(vnode.attrs.interval, 100);
    var ticker = setInterval((function (extra) {
            var match = vnode.state.data;
            if (typeof match !== "object") {
              return ;
            }
            if (match.TAG !== "Running") {
              return ;
            }
            var timeout = match.timeout;
            var interval = match.interval;
            var value = match.value + interval | 0;
            if (value < timeout) {
              return Mithril.setState(vnode, {
                          TAG: "Running",
                          ticker: match.ticker,
                          value: value,
                          interval: interval,
                          timeout: timeout
                        });
            } else {
              stop(vnode);
              return Mithril.setState(vnode, {
                          TAG: "Done",
                          value: timeout,
                          timeout: timeout
                        });
            }
          }), interval);
    Mithril.setState(vnode, {
          TAG: "Running",
          ticker: ticker,
          value: 0,
          interval: interval,
          timeout: timeout
        });
  };
  return Mithril.view(Mithril.onremove(Mithril.oninit(Mithril.component("Starting"), start), stop), (function (vnode) {
                var style = Prelude.default(vnode.attrs.style, {
                      width: "100%"
                    });
                var match = vnode.state.data;
                var value;
                var timeout;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                if (match.TAG === "Running") {
                  value = match.value;
                  timeout = match.timeout;
                } else {
                  value = match.value;
                  timeout = match.timeout;
                }
                return ReactDOM.jsx("progress", {
                            style: style,
                            max: String(timeout),
                            value: String(value)
                          });
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing new inspections types";
}

function $$process(proc) {
  var match = proc.position_diagram_id;
  var match$1 = proc.example_image_id;
  return {
          id: proc.id,
          component_id: proc.component_id,
          name: proc.name,
          order: proc.order,
          cooldown: proc.cooldown,
          position_diagram_id: proc.position_diagram_id,
          position_diagram_desc: proc.position_diagram_desc,
          example_image_id: proc.example_image_id,
          example_image_desc: proc.example_image_desc,
          description: proc.description,
          require_landscape_pictures: proc.require_landscape_pictures,
          question_type: match !== undefined || match$1 !== undefined ? "RatingQuestion" : "OkNotOkQuestion"
        };
}

var ProcedureRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing new inspections types";
}

function migrate(param, t) {
  return ProcedureRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* ProcedureRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing procedure's overdue custom check";
}

function $$process(procedure) {
  return {
          id: procedure.id,
          vessel_id: procedure.vessel_id,
          section_id: procedure.section_id,
          component_id: procedure.component_id,
          name: procedure.name,
          order: procedure.order,
          position_diagram_id: procedure.position_diagram_id,
          position_diagram_desc: procedure.position_diagram_desc,
          example_image_id: procedure.example_image_id,
          example_image_desc: procedure.example_image_desc,
          description: procedure.description,
          require_landscape_pictures: procedure.require_landscape_pictures,
          question_type: procedure.question_type,
          rating_styling_rules_id: procedure.rating_styling_rules_id,
          require_image_conditions: procedure.require_image_conditions,
          recurrence: procedure.recurrence,
          due_date: procedure.due_date,
          overdue_date: procedure.overdue_date,
          ignore_overdue_status: false
        };
}

var ProcedureRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "procedures",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing procedure's overdue custom check";
}

function migrate(param, transaction) {
  return Prelude.PromisedResult.map(ProcedureRewriter.rewrite(transaction), (function () {
                
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* ProcedureRewriter Not a pure module */

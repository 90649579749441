// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as QuizWidget from "../../tree/views/questions/QuizWidget.js";
import * as IntQuestionWidget from "../../tree/views/questions/IntQuestionWidget.js";
import * as DateQuestionWidget from "../../tree/views/questions/DateQuestionWidget.js";
import * as TextQuestionWidget from "../../tree/views/questions/TextQuestionWidget.js";
import * as PhotoQuestionWidget from "../../tree/views/questions/PhotoQuestionWidget.js";
import * as YesNoQuestionWidget from "../../tree/views/questions/YesNoQuestionWidget.js";
import * as RatingQuestionWidget from "../../tree/views/questions/RatingQuestionWidget.js";
import * as OKNotOKQuestionWidget from "../../tree/views/questions/OKNotOKQuestionWidget.js";
import * as SignatureQuestionWidget from "../../tree/views/questions/SignatureQuestionWidget.js";
import * as WeightInfoQuestionWidget from "../../tree/views/questions/WeightInfoQuestionWidget.js";
import * as MultipleDatesQuestionWidget from "../../tree/views/questions/MultipleDatesQuestionWidget.js";
import * as MultipleTextsQuestionWidget from "../../tree/views/questions/MultipleTextsQuestionWidget.js";
import * as MultipleIntegersQuestionWidget from "../../tree/views/questions/MultipleIntegersQuestionWidget.js";
import * as MultipleDatesTextsQuestionWidget from "../../tree/views/questions/MultipleDatesTextsQuestionWidget.js";
import * as MultipleDatesYesNoQuestionWidget from "../../tree/views/questions/MultipleDatesYesNoQuestionWidget.js";
import * as MultipleDatesIntegersQuestionWidget from "../../tree/views/questions/MultipleDatesIntegersQuestionWidget.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs.levelStatus;
                var level = match.level;
                var vessel = match.vessel;
                var questions = match.questions;
                if (Prelude.$$Array.isEmpty(questions)) {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("p", {
                                    children: "No results",
                                    className: "empty-title h5"
                                  }),
                              className: "empty"
                            });
                }
                var widgets = [ReactDOM.jsx("h5", {
                        children: level.name,
                        className: "p-2 chapter-title"
                      })];
                return Curry._2(Prelude.$$Array.concat, widgets, questions.map(function (param) {
                                var lastNewAnswer = param.lastNewAnswer;
                                var lastAnswer = param.lastAnswer;
                                var question = param.question;
                                var match = question.kind;
                                if (typeof match !== "object") {
                                  if (match === "PhotoQuestion") {
                                    return React.jsx(PhotoQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "TextQuestion") {
                                    return React.jsx(TextQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "DateQuestion") {
                                    return React.jsx(DateQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "SignatureQuestion") {
                                    return React.jsx(SignatureQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else {
                                    return React.jsx(IntQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  }
                                }
                                var variant = match.NAME;
                                if (variant === "OKNotOKQuestion") {
                                  return React.jsx(OKNotOKQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleTextsQuestion") {
                                  return React.jsx(MultipleTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "RatingQuestion") {
                                  return React.jsx(RatingQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "WeightInfoQuestion") {
                                  return React.jsx(WeightInfoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesTextsQuestion") {
                                  return React.jsx(MultipleDatesTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesYesNoQuestion") {
                                  return React.jsx(MultipleDatesYesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleIntegersQuestion") {
                                  return React.jsx(MultipleIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "YesNoQuestion") {
                                  return React.jsx(YesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesQuestion") {
                                  return React.jsx(MultipleDatesQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "Quiz") {
                                  return React.jsx(QuizWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else {
                                  return React.jsx(MultipleDatesIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                }
                              }));
              }));
}

export {
  make ,
}
/* React Not a pure module */

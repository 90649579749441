// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function make(param) {
  return function (db, _transaction) {
    var readings = IDB__Migration__Database.createObjectStore(db, "readings");
    IDB__Migration__Store.createSimpleIndex(readings, "timestamp");
    IDB__Migration__Store.createSimpleIndex(readings, "variable");
    var newReadings = IDB__Migration__Database.createObjectStore(db, "newReadings");
    IDB__Migration__Store.createSimpleIndex(newReadings, "timestamp");
    IDB__Migration__Store.createSimpleIndex(newReadings, "variable");
    var forms = IDB__Migration__Database.createObjectStore(db, "forms");
    IDB__Migration__Store.createSimpleIndex(forms, "survey_id");
    IDB__Migration__Database.createObjectStore(db, "variables");
    var vesselSurveys = IDB__Migration__Database.createObjectStore(db, "vesselSurveys");
    IDB__Migration__Store.createSimpleIndex(vesselSurveys, "vessel_id");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Store Not a pure module */

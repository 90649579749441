// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

var counter = {
  contents: 0
};

function ConnectionIndicator(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return ReactDOM.jsxKeyed("progress", {
                            className: Mithril.$$class([
                                  [
                                    "progress connection-indicator",
                                    true
                                  ],
                                  [
                                    "hidden",
                                    counter.contents === 0
                                  ]
                                ], undefined),
                            max: "100"
                          }, "connection-indicator", undefined);
              }));
}

function up() {
  counter.contents = counter.contents + 1 | 0;
  if (counter.contents > 0) {
    Mithril$1.redraw();
    return ;
  }
  
}

function down() {
  counter.contents = counter.contents - 1 | 0;
  if (counter.contents === 0) {
    Mithril$1.redraw();
    return ;
  }
  
}

function show(f) {
  up();
  return f().finally(down);
}

var make = ConnectionIndicator;

export {
  counter ,
  make ,
  up ,
  down ,
  show ,
}
/* Mithril Not a pure module */

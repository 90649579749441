// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProfileRankImage from "./ProfileRankImage.js";

function toAvatarIcon(style) {
  var match = style === "profilePage" ? [
      150,
      "tw-border-[3px]"
    ] : (
      style === "header" ? [
          24,
          "tw-border-[1px]"
        ] : [
          40,
          "tw-border-[1.5px]"
        ]
    );
  return React.jsx(Feather.make, {
              icon: "user",
              className: Mithril.classList([
                    match[1],
                    "tw-text-gray-400 tw-stroke-[0.5] tw-w-full tw-h-full tw-box-border",
                    "tw-rounded-full tw-border-solid tw-border-gray-400"
                  ], undefined),
              size: match[0]
            });
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var profile = vnode.attrs.profile;
                var rank = vnode.attrs.rank;
                var image = vnode.attrs.image;
                var customClass = vnode.attrs.className;
                var className = Mithril.classList([
                      "tw-block tw-rounded-full tw-border tw-border-solid tw-border-black",
                      "tw-aspect-square tw-object-cover tw-w-full tw-h-full"
                    ], customClass);
                var defaultAvatar = toAvatarIcon(match.style);
                var toRankImage = function (rank) {
                  return React.jsx(ProfileRankImage.make, {
                              rank: rank,
                              className: className
                            });
                };
                var toLocalImage = function (attachment) {
                  return React.jsx(LocalImage.Resource.make, {
                              resource: attachment.id,
                              className: className,
                              children: Caml_option.some(defaultAvatar)
                            });
                };
                if (profile === undefined) {
                  if (rank !== undefined) {
                    return toRankImage(rank);
                  } else if (image !== undefined) {
                    return toLocalImage(image);
                  } else {
                    return defaultAvatar;
                  }
                }
                var attachment = Prelude.$$Array.first(profile.avatars);
                if (attachment !== undefined) {
                  return toLocalImage(attachment);
                } else {
                  return toRankImage(profile.rank);
                }
              }));
}

var AvatarStyle = {};

export {
  AvatarStyle ,
  make ,
}
/* React Not a pure module */

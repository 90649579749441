// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function MakeSimpleSerializer(T) {
  var serialize = function (item) {
    var result;
    try {
      result = T.convert(item);
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      return {
              TAG: "Error",
              _0: e
            };
    }
    return {
            TAG: "Ok",
            _0: result
          };
  };
  return {
          serialize: serialize
        };
}

export {
  MakeSimpleSerializer ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "./InitFeedback.js";

var initializers = [];

function init(db) {
  InitFeedback.updateFeedback(["ClearMigrations"]);
  var actions = initializers.map(function (mod, index) {
        var message = mod.message;
        console.log(message);
        InitFeedback.updateFeedback([{
                TAG: "AddMigration",
                _0: index,
                _1: message
              }]);
        return function () {
          InitFeedback.updateFeedback([{
                  TAG: "RunningMigration",
                  _0: index,
                  _1: message
                }]);
          return Prelude.catchResolve(mod.initializer(db), (function (e) {
                          console.error("Error in initializer", mod.message, e);
                          return {
                                  TAG: "Error",
                                  _0: "Error while running initializers"
                                };
                        })).finally(function () {
                      InitFeedback.updateFeedback([{
                              TAG: "DoneMigration",
                              _0: index,
                              _1: message
                            }]);
                    });
        };
      });
  return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(actions), (function (param) {
                
              }));
}

export {
  init ,
}
/* InitFeedback Not a pure module */

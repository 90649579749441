// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../ProcedureId.js";
import * as State__Inspections from "../../state/State__Inspections.js";
import * as TextQuestionEditor from "./TextQuestionEditor.js";
import * as NewInspectionManager from "../NewInspectionManager.js";

function createInspection(vnode, _event) {
  var draftSubmissionId = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("draftSubmissionId"), (function (i) {
          return i;
        }));
  var match = vnode.state.data;
  if (typeof match !== "object") {
    window.history.back();
    return ;
  }
  var procedure = match.procedure;
  var value = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name='" + procedure.id + "']"));
  if (value !== undefined) {
    return Prelude.thenDo(State__Inspections.make(procedure, undefined, undefined, {
                    NAME: "Text",
                    VAL: value
                  }, undefined, undefined, undefined, undefined, Caml_option.some(draftSubmissionId), undefined), (function (param) {
                  window.history.back();
                }));
  }
  
}

function setText(vnode, text) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var inspection = match.inspection;
  var text$1 = text.trim();
  var inspection_id = inspection.id;
  var inspection_procedure_id = inspection.procedure_id;
  var inspection_component_id = inspection.component_id;
  var inspection_section_id = inspection.section_id;
  var inspection_vessel_id = inspection.vessel_id;
  var inspection_inspector_id = inspection.inspector_id;
  var inspection_timestamp = inspection.timestamp;
  var inspection_code = inspection.code;
  var inspection_annotations = inspection.annotations;
  var inspection_additional_annotation = inspection.additional_annotation;
  var inspection_picture_prompted = inspection.picture_prompted;
  var inspection_walked_steps = inspection.walked_steps;
  var inspection_app_version = inspection.app_version;
  var inspection_deficiency_id = inspection.deficiency_id;
  var inspection_attachments = inspection.attachments;
  var inspection_value = {
    NAME: "Text",
    VAL: text$1
  };
  var inspection_deadline = inspection.deadline;
  var inspection_profile = inspection.profile;
  var inspection$1 = {
    id: inspection_id,
    procedure_id: inspection_procedure_id,
    component_id: inspection_component_id,
    section_id: inspection_section_id,
    vessel_id: inspection_vessel_id,
    inspector_id: inspection_inspector_id,
    timestamp: inspection_timestamp,
    code: inspection_code,
    annotations: inspection_annotations,
    additional_annotation: inspection_additional_annotation,
    picture_prompted: inspection_picture_prompted,
    walked_steps: inspection_walked_steps,
    app_version: inspection_app_version,
    deficiency_id: inspection_deficiency_id,
    attachments: inspection_attachments,
    value: inspection_value,
    deadline: inspection_deadline,
    profile: inspection_profile
  };
  Mithril.setState(vnode, {
        TAG: "Loaded",
        procedure: match.procedure,
        inspection: inspection$1,
        ready: text$1.length > 0
      });
}

function TextQuestionView(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var procedureId = ProcedureId.Route.param("procedureId");
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.procedures = {
                                TAG: "Get",
                                _0: procedureId
                              }, newrecord)), (function (param) {
                            var procedure = Prelude.$$Array.first(param.procedures);
                            if (procedure !== undefined) {
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          procedure: procedure,
                                          inspection: NewInspectionManager.make(procedure.id, procedure.component_id, procedure.section_id, procedure.vessel_id, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                                          ready: false
                                        });
                            } else {
                              window.history.back();
                              return ;
                            }
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var overNavbar = ReactDOM.jsx("button", {
                      children: "Save",
                      className: Mithril.$$class([
                            [
                              "btn btn-lg mt-auto btn-primary",
                              true
                            ],
                            [
                              "disabled",
                              match.ready === false
                            ]
                          ], undefined),
                      onClick: (function (extra) {
                          return createInspection(vnode, extra);
                        })
                    });
                return React.jsx(ListLayout.make, {
                            children: React.jsx(TextQuestionEditor.make, {
                                  procedure: match.procedure,
                                  inspection: match.inspection,
                                  setText: (function (extra) {
                                      return setText(vnode, extra);
                                    })
                                }),
                            title: "Enter Text",
                            showBackButton: true,
                            overNavbar: Caml_option.some(overNavbar),
                            overNavbarHeight: "2.9rem"
                          });
              }));
}

var make = TextQuestionView;

export {
  createInspection ,
  setText ,
  make ,
}
/* Query Not a pure module */

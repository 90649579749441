// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as UploadRequest from "../upload/UploadRequest.js";

async function init(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var requests = vnode.attrs.requests;
  var readNotificationEntries = Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getReadNotificationEntry);
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              _0: !Prelude.$$Array.isEmpty(readNotificationEntries)
            });
}

function valid(param) {
  return true;
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (v) {
                    init(v);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object" || !match._0) {
                  return Mithril.empty;
                } else {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("h5", {
                                      children: "Notifications"
                                    }),
                                ReactDOM.jsx("div", {
                                      children: "Notify shore you have read some notifications",
                                      className: "text-tiny text-center"
                                    })
                              ],
                              className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-mb-2"
                            });
                }
              }));
}

export {
  init ,
  valid ,
  make ,
}
/* Mithril Not a pure module */

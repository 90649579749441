// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";

var Identifier = Utils.MakeOpaqueIdentifier({});

export {
  Identifier ,
}
/* Identifier Not a pure module */

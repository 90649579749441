// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";

function make(param) {
  return function (_db, transaction) {
    var formsStore = transaction.objectStore("forms");
    IDB__Migration__Store.createSimpleIndex(formsStore, "vessel_id");
    IDB__Migration__Store.createSimpleMultiEntryIndex(formsStore, "tags");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Store Not a pure module */

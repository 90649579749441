// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UploadPayload from "../upload/UploadPayload.js";
import * as UploadActionable from "../upload/UploadActionable.js";
import * as UploadSummary__FailedReport from "./summary/UploadSummary__FailedReport.js";
import * as UploadSummary__SucceededReport from "./summary/UploadSummary__SucceededReport.js";

function summarize(results) {
  var succeeded = Prelude.$$Array.flatMap(Curry._2(Prelude.$$Array.keepMap, results, (function (r) {
              if (r.TAG === "Ok") {
                return UploadActionable.getPayloads(r._0);
              }
              
            })), (function (r) {
          return r;
        }));
  var failed = Curry._2(Prelude.$$Array.keepMap, results, (function (r) {
          if (r.TAG === "Ok") {
            return ;
          }
          var match = r._0;
          return [
                  match[0],
                  match[1]
                ];
        }));
  var match = Curry._2(Prelude.$$Array.partition, failed, (function (param) {
          return !UploadActionable.failible(param[0]);
        }));
  return {
          succeeded: succeeded,
          hardFailures: match[0],
          softFailures: match[1]
        };
}

function succeeded(summary) {
  return summary.hardFailures.length === 0;
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onClickRetry = match.onClickRetry;
                var match$1 = summarize(match.results);
                var hardFailures = match$1.hardFailures;
                var succeeded = match$1.succeeded;
                if (hardFailures.length !== 0) {
                  return React.jsx(UploadSummary__FailedReport.make, {
                              errors: hardFailures.map(function (prim) {
                                    return prim[1];
                                  }),
                              onClickRetry: onClickRetry
                            });
                }
                var softFailures = match$1.softFailures;
                if (softFailures.length !== 0) {
                  return React.jsx(UploadSummary__SucceededReport.make, {
                              payloads: succeeded,
                              failures: UploadPayload.deduplicate(Curry._1(Prelude.$$Array.concatMany, softFailures.map(function (prim) {
                                              return prim[0];
                                            }).map(UploadActionable.getPayloads))),
                              onClickRetry: onClickRetry
                            });
                } else {
                  return React.jsx(UploadSummary__SucceededReport.make, {
                              payloads: succeeded
                            });
                }
              }));
}

export {
  summarize ,
  succeeded ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as CompanyForm from "../../company_forms/CompanyForm.js";
import * as UploadRequest from "../upload/UploadRequest.js";
import * as Belt_MutableMap from "rescript/lib/es6/belt_MutableMap.js";
import * as ReviewUpload__CompletedCompanyFormsCard from "./ReviewUpload__CompletedCompanyFormsCard.js";
import * as ReviewUpload__IncompleteCompanyFormsCard from "./ReviewUpload__IncompleteCompanyFormsCard.js";

function init(vnode) {
  var match = vnode.attrs;
  var requests = match.requests;
  var completed = Belt_MutableMap.toArray(Prelude.$$Array.groupByComparable(Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getCompleteCompanyFormSubmission), (function (param) {
                return param[1];
              }), CompanyForm.Comparator)).map(function (param) {
        return [
                param[0],
                param[1].map(function (param) {
                      return param[0];
                    })
              ];
      });
  var incomplete = Curry._2(Prelude.$$Array.keepMap, requests, UploadRequest.getIncompleteCompanyForm);
  Mithril.setState(vnode, {
        TAG: "Loaded",
        completed: completed,
        incomplete: incomplete
      });
}

function valid(param) {
  return true;
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), init), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var completed = match.completed;
                if (completed.length === 0 && match.incomplete.length === 0) {
                  return Mithril.empty;
                }
                return [
                        ReactDOM.jsx("h3", {
                              children: "Company forms",
                              className: "mt-3 text-center"
                            }),
                        React.jsx(ReviewUpload__CompletedCompanyFormsCard.make, {
                              forms: completed
                            }),
                        React.jsx(ReviewUpload__IncompleteCompanyFormsCard.make, {
                              forms: match.incomplete
                            })
                      ];
              }));
}

var CompletedCompanyFormsCard;

var IncompleteCompanyFormsCard;

export {
  CompletedCompanyFormsCard ,
  IncompleteCompanyFormsCard ,
  init ,
  valid ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Utils from "../../libs/Utils.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var Identifier = Utils.MakeOpaqueIdentifier({});

var _map = {"kaiko":"kaiko","shore":"shore","crew":"crew"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function fromString(x) {
  return _map[x];
}

function toString(x) {
  return x;
}

var Role = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  fromString: fromString,
  toString: toString
};

var ModelDefinition = {};

var include = ReIndexed.MakeIdModel(ModelDefinition, {});

var fields = {
  TAG: "Object",
  _0: [
    [
      "user",
      "String"
    ],
    [
      "role",
      $$JSON.Field.variadicString("role", fromString)
    ]
  ]
};

var include$1 = $$JSON.MakeDeserializer({
      fields: fields
    });

var Deserializer = include$1;

function fromDeserialized(vessel, parsed) {
  return {
          id: "staff://" + vessel + "/" + parsed.user,
          vessel: vessel,
          user: parsed.user,
          role: parsed.role
        };
}

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Role ,
  ModelDefinition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  Deserializer ,
  fromDeserialized ,
}
/* Identifier Not a pure module */

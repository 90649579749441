// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Query from "../Query.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as State__User from "./State__User.js";
import * as ProceduresAPI from "../api/endpoints/ProceduresAPI.js";

function upload(procedures) {
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return ProceduresAPI.post(__x, procedures);
                }));
}

async function updateNotApplicableStatus(procedure, not_applicable_status) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  newrecord.procedures = {
    TAG: "Get",
    _0: procedure.id
  };
  var match = await Query.read(newrecord);
  var procedures = match.procedures;
  if (procedures.length === 1) {
    var procedure$1 = procedures[0];
    var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
    newrecord$1.procedureUpdates = [{
        TAG: "Save",
        _0: {
          id: procedure$1.id,
          vessel_id: procedure$1.vessel_id,
          not_applicable_status: not_applicable_status
        }
      }];
    var newrecord$2 = Caml_obj.obj_dup(procedure$1);
    newrecord$1.procedures = [{
        TAG: "Save",
        _0: (newrecord$2.not_applicable_status = not_applicable_status, newrecord$2)
      }];
    await Query.write(newrecord$1);
  }
  return procedure.id;
}

var LoggedUser;

export {
  LoggedUser ,
  upload ,
  updateNotApplicableStatus ,
}
/* Query Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Browser from "../../../libs/Browser.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Inspection from "../Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../ProcedureId.js";
import * as InspectionId from "../InspectionId.js";
import * as QuestionType from "../QuestionType.js";
import * as Mithril__Route from "../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as State__Inspections from "../../state/State__Inspections.js";

function toValue(inspection) {
  return Curry._2(Prelude.OptionExported.$$Option.map, QuestionType.Value.getWeightDelta(inspection.value), (function (value) {
                return {
                        manufacturer: value.manufacturer,
                        initialWeight: String(value.initial_weight),
                        currentWeight: String(value.current_weight),
                        comment: inspection.additional_annotation
                      };
              }));
}

function fromValue(value) {
  return {
          manufacturer: value.manufacturer,
          initial_weight: parseFloat(value.initialWeight),
          current_weight: parseFloat(value.currentWeight)
        };
}

var emptyValue = {
  manufacturer: "",
  initialWeight: "",
  currentWeight: "",
  comment: ""
};

function updateValue(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var value = Prelude.default(match.value, emptyValue);
  var newValue_manufacturer = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=manufacturer]")), value.manufacturer);
  var newValue_initialWeight = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=initialWeight]")), value.initialWeight);
  var newValue_currentWeight = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=currentWeight]")), "");
  var newValue_comment = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=comment]")), value.comment);
  var newValue = {
    manufacturer: newValue_manufacturer,
    initialWeight: newValue_initialWeight,
    currentWeight: newValue_currentWeight,
    comment: newValue_comment
  };
  vnode.state.data = {
    TAG: "Loaded",
    procedure: match.procedure,
    lastInspection: match.lastInspection,
    informationCompleted: match.informationCompleted,
    value: newValue,
    update: match.update,
    back: match.back
  };
}

function updateSaveButton(vnode, _event) {
  updateValue(vnode);
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var value = match.value;
  Mithril.setState(vnode, {
        TAG: "Loaded",
        procedure: match.procedure,
        lastInspection: match.lastInspection,
        informationCompleted: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                    if (parseFloat(v.currentWeight) > 0.0) {
                      return parseFloat(v.initialWeight) > 0.0;
                    } else {
                      return false;
                    }
                  })), false),
        value: value,
        update: match.update,
        back: match.back
      });
}

function getWeightLossAnnotation(rating) {
  if (rating === 1) {
    return "More than 5% of weight lost";
  } else {
    return "Less than 5% of weight lost";
  }
}

function createInspection(vnode, _event) {
  var draftSubmissionId = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("draftSubmissionId"), (function (i) {
          return i;
        }));
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var back = match.back;
  var value = match.value;
  var lastInspection = match.lastInspection;
  var weight = Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, value, fromValue), (function (v) {
          return {
                  NAME: "WeightDelta",
                  VAL: v
                };
        }));
  if (match.update) {
    var newrecord = Caml_obj.obj_dup(Query.makeWrite());
    return Prelude.thenDo(Query.write((newrecord.newInspections = [{
                        TAG: "Save",
                        _0: {
                          id: lastInspection.id,
                          procedure_id: lastInspection.procedure_id,
                          component_id: lastInspection.component_id,
                          section_id: lastInspection.section_id,
                          vessel_id: lastInspection.vessel_id,
                          inspector_id: lastInspection.inspector_id,
                          timestamp: lastInspection.timestamp,
                          code: lastInspection.code,
                          annotations: weight !== undefined ? [getWeightLossAnnotation(QuestionType.Value.rate(weight))] : [],
                          additional_annotation: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                                      return v.comment;
                                    })), ""),
                          picture_prompted: lastInspection.picture_prompted,
                          walked_steps: lastInspection.walked_steps,
                          app_version: lastInspection.app_version,
                          deficiency_id: lastInspection.deficiency_id,
                          attachments: lastInspection.attachments,
                          value: weight,
                          deadline: lastInspection.deadline,
                          profile: lastInspection.profile
                        }
                      }], newrecord)), (function (param) {
                  window.history.go(back);
                }));
  }
  var weight$1 = Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, value, fromValue), (function (v) {
          return {
                  NAME: "WeightDelta",
                  VAL: v
                };
        }));
  if (weight$1 !== undefined) {
    return Prelude.thenDo(State__Inspections.make(match.procedure, undefined, undefined, weight$1, undefined, undefined, [getWeightLossAnnotation(QuestionType.Value.rate(weight$1))], Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                              return v.comment;
                            })), ""), Caml_option.some(draftSubmissionId), undefined), (function (param) {
                  window.history.go(back);
                }));
  }
  
}

function deleteInspection(vnode, $$event) {
  $$event.preventDefault();
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var back = match.back;
  var lastInspection = match.lastInspection;
  if (lastInspection !== undefined) {
    if (Browser.$$Window.confirm("Are you sure?")) {
      return Prelude.thenDo(State__Inspections.$$delete(lastInspection), (function (param) {
                    window.history.go(back);
                  }));
    } else {
      return ;
    }
  } else {
    window.history.go(back);
    return ;
  }
}

function WeightMeasurementInspectionProcedure(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var procedureId = ProcedureId.Route.param("procedureId");
                    var inspectionId = InspectionId.Route.paramOr("inspectionId", InspectionId.$$Option.$$default(undefined));
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.newInspections = {
                                TAG: "Get",
                                _0: inspectionId
                              }, newrecord.inspections = Inspection.ofProcedure(procedureId), newrecord.procedures = {
                                TAG: "Get",
                                _0: procedureId
                              }, newrecord)), (function (param) {
                            var procedure = Prelude.$$Array.first(param.procedures);
                            if (procedure !== undefined) {
                              var inspection = Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                        Prelude.$$Array.first(param.newInspections),
                                        Inspection.lastInspection(param.inspections)
                                      ]));
                              var value = Curry._2(Prelude.OptionExported.$$Option.flatMap, inspection, toValue);
                              var informationCompleted = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                                          if (parseFloat(v.currentWeight) > 0.0) {
                                            return parseFloat(v.initialWeight) > 0.0;
                                          } else {
                                            return false;
                                          }
                                        })), false);
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          procedure: procedure,
                                          lastInspection: inspection,
                                          informationCompleted: informationCompleted,
                                          value: value,
                                          update: Prelude.default(Mithril__Route.paramBool("update"), false),
                                          back: Prelude.default(Mithril__Route.paramInt("back"), -1)
                                        });
                            }
                            window.history.back();
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var procedure = match.procedure;
                var value = Prelude.default(match.value, emptyValue);
                var actions = match.update ? [{
                      icon: "trash_2",
                      effect: {
                        TAG: "Fn",
                        _0: (function (extra) {
                            return deleteInspection(vnode, extra);
                          })
                      }
                    }] : [];
                var overNavbar = ReactDOM.jsx("button", {
                      children: "Save",
                      className: Mithril.$$class([
                            [
                              "btn btn-lg mt-auto btn-primary",
                              true
                            ],
                            [
                              "disabled",
                              match.informationCompleted === false
                            ]
                          ], undefined),
                      onClick: (function (extra) {
                          return createInspection(vnode, extra);
                        })
                    });
                return React.jsx(ListLayout.make, {
                            children: ReactDOM.jsxs("form", {
                                  children: [
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("label", {
                                                  children: "Maker / Type",
                                                  className: "form-label text-muted text-small"
                                                }),
                                            ReactDOM.jsx("input", {
                                                  className: "form-input",
                                                  name: "manufacturer",
                                                  type: "text",
                                                  value: value.manufacturer,
                                                  onInput: (function (extra) {
                                                      return updateSaveButton(vnode, extra);
                                                    })
                                                })
                                          ],
                                          className: "form-group"
                                        }),
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("label", {
                                                  children: "Initial Weight (kg)",
                                                  className: "form-label text-muted text-small"
                                                }),
                                            ReactDOM.jsx("input", {
                                                  className: "form-input",
                                                  name: "initialWeight",
                                                  type: "number",
                                                  value: value.initialWeight,
                                                  onInput: (function (extra) {
                                                      return updateSaveButton(vnode, extra);
                                                    })
                                                })
                                          ],
                                          className: "form-group"
                                        }),
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("label", {
                                                  children: "Current Weight (kg)",
                                                  className: "form-label text-muted text-small"
                                                }),
                                            ReactDOM.jsx("input", {
                                                  className: "form-input",
                                                  name: "currentWeight",
                                                  type: "number",
                                                  value: value.currentWeight,
                                                  onInput: (function (extra) {
                                                      return updateSaveButton(vnode, extra);
                                                    })
                                                })
                                          ],
                                          className: "form-group"
                                        }),
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("label", {
                                                  children: "Comment",
                                                  className: "form-label text-muted text-small",
                                                  htmlFor: procedure.id
                                                }),
                                            ReactDOM.jsx("textarea", {
                                                  className: "form-input",
                                                  id: procedure.id,
                                                  name: "comment",
                                                  value: value.comment,
                                                  onInput: (function (extra) {
                                                      return updateSaveButton(vnode, extra);
                                                    })
                                                })
                                          ],
                                          className: "form-group"
                                        })
                                  ],
                                  id: "weightMeasurementForm"
                                }),
                            title: procedure.name,
                            showBackButton: true,
                            overNavbar: Caml_option.some(overNavbar),
                            overNavbarHeight: "2.9rem",
                            actions: actions
                          });
              }));
}

var make = WeightMeasurementInspectionProcedure;

export {
  toValue ,
  fromValue ,
  emptyValue ,
  updateValue ,
  updateSaveButton ,
  getWeightLossAnnotation ,
  createInspection ,
  deleteInspection ,
  make ,
}
/* Query Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var onClick = Core__Option.getOr(vnode.attrs.onClick, (function (param) {
                        
                      }));
                var showModal = Core__Option.getOr(vnode.attrs.showModal, true);
                return ReactDOM.jsx("div", {
                            className: Mithril.classList([
                                  showModal ? "tw-block" : "tw-hidden",
                                  "tw-fixed tw-inset-0 tw-bg-black/10 tw-z-1500"
                                ], undefined),
                            onClick: onClick
                          });
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Section from "../Section.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as SectionId from "../SectionId.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function title(vnode) {
  var section = vnode.state.data;
  if (typeof section !== "object") {
    return "";
  } else {
    return section._0.name;
  }
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var sectionId = SectionId.Route.param("sectionId");
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.sections = {
                                TAG: "Get",
                                _0: sectionId
                              }, newrecord)), (function (param) {
                            var section = Prelude.$$Array.first(param.sections);
                            if (section !== undefined) {
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          _0: section
                                        });
                            } else {
                              window.history.back();
                              return ;
                            }
                          }));
                  })), (function (vnode) {
                var section = vnode.state.data;
                var tmp;
                tmp = typeof section !== "object" ? Mithril.empty : React.jsx(LocalImage.Resource.make, {
                        resource: Section.getImage(section._0).id,
                        className: "img-responsive img-fit-contain p-centered"
                      });
                return React.jsx(ListLayout.make, {
                            children: ReactDOM.jsx("div", {
                                  children: tmp
                                }),
                            title: title(vnode),
                            bottom: Caml_option.some(Mithril.empty)
                          });
              }));
}

export {
  make ,
}
/* Query Not a pure module */

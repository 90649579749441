// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../libs/rescript-mithril/src/ReactDOM.js";
import * as ListLayout from "../common/views/ListLayout.js";
import * as ProtectedView from "../accounts/views/ProtectedView.js";

function TSIsArea(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(ProtectedView.make, {
                            requireNonCrew: true,
                            children: React.jsx(ListLayout.make, {
                                  children: ReactDOM.jsx("div", {
                                        children: ReactDOM.jsx("div", {
                                              children: "Coming soon",
                                              className: "column col-10 col-sm-12 mx-auto text-center"
                                            }),
                                        className: "columns",
                                        style: {
                                          fontSize: "1.8rem",
                                          marginTop: "4rem"
                                        }
                                      }),
                                  title: "TSI",
                                  showBackButton: true
                                })
                          });
              }));
}

var make = TSIsArea;

export {
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Add vessel settings for findings.";
}

function $$process(vessel) {
  return {
          id: vessel.id,
          name: vessel.name,
          max_pic_resolution: vessel.max_pic_resolution,
          picture_prompt_every_n_inspections: vessel.picture_prompt_every_n_inspections,
          require_landscape_pictures: vessel.require_landscape_pictures,
          modules: vessel.modules,
          company_logo: vessel.company_logo,
          use_new_user_profiles: vessel.use_new_user_profiles,
          migrated: vessel.migrated,
          mandatory_findings_deadline: false,
          mandatory_findings_observations: false
        };
}

var VesselRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "vessels",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Add vessel settings for findings.";
}

function migrate(_db, transaction) {
  return VesselRewriter.rewrite(transaction);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* VesselRewriter Not a pure module */

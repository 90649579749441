// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as Component from "../Component.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Inspection from "../../survey/Inspection.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";

function title(vnode) {
  return Component.nameWithNumber(vnode.attrs.component);
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var component = vnode.attrs.component;
                    var procedureIds = Procedure.ids(vnode.attrs.procedures);
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.newInspections = NewInspection.ofProcedures(procedureIds), newrecord.inspections = Inspection.ofProcedures(procedureIds), newrecord)), (function (param) {
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  completion: Component.getCompletion(component, vnode.attrs.procedures),
                                  dueStatusText: Procedure.getGreatestOverdueText((function (p) {
                                          return "Component: " + component.name + ", Procedure: " + p.name;
                                        }), vnode.attrs.procedures)
                                });
                          }));
                  })), (function (vnode) {
                var component = vnode.attrs.component;
                var href = URLs.componentProcedures(component.id);
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(TileLink.make, {
                              title: title(vnode),
                              href: href,
                              subtitle: "Loading..."
                            });
                } else {
                  return React.jsx(TileLink.make, {
                              title: title(vnode),
                              href: href,
                              subtitle: match.dueStatusText,
                              action: Caml_option.some(React.jsx(InspectionCompletion.make, {
                                        completion: match.completion,
                                        counterOverlayClassName: counterOverlayClassName
                                      }))
                            });
                }
              }));
}

export {
  title ,
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as PageNavigation from "../../common/views/PageNavigation.js";
import * as LabelLandingPage from "../components/LabelLandingPage.js";
import * as ProtectedViewMaker from "./ProtectedViewMaker.js";

function stringToTokens(input) {
  return Belt_List.fromArray(Curry._2(Prelude.$$Array.keepMap, Belt_SetString.toArray(Belt_SetString.fromArray(input.toLowerCase().split(" "))), (function (word) {
                    var trimmed = word.trim();
                    if (trimmed === "") {
                      return ;
                    } else {
                      return trimmed;
                    }
                  })));
}

function insideTokens(token, contentTokens) {
  if (!contentTokens) {
    return [
            false,
            /* [] */0
          ];
  }
  var xs = contentTokens.tl;
  var x = contentTokens.hd;
  if (x.includes(token)) {
    return [
            true,
            xs
          ];
  }
  var match = insideTokens(token, xs);
  return [
          match[0],
          {
            hd: x,
            tl: match[1]
          }
        ];
}

async function initialize(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  if (match !== "NotReady") {
    return ;
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.vessels = "All", newrecord));
  var vessels = match$1.vessels;
  var vesselIds = vessels.map(function (v) {
        return v.id;
      });
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$1.staffers = {
          TAG: "AnyOf",
          _0: "vessel",
          _1: vesselIds
        }, newrecord$1));
  var staffers = match$2.staffers;
  var knownVesselWithStaff = VesselId.$$Set.fromArray(staffers.map(function (s) {
            return s.vessel;
          }));
  var vessels$1 = Curry._2(Prelude.$$Array.keep, vessels, (function (v) {
          return VesselId.$$Set.has(knownVesselWithStaff, v.id);
        }));
  var system = State__Memory.Transformations.make(State__Memory.Current.get());
  var len = vessels$1.length;
  var system$1;
  if (len !== 1) {
    system$1 = len !== 0 ? system : State__Memory.Transformations.removeCurrentVessel(system);
  } else {
    var vessel = vessels$1[0];
    system$1 = State__Memory.Transformations.setCurrentVessel(system, vessel);
  }
  var state = State__Memory.Persisted.store(State__Memory.Transformations.run(system$1));
  var vessel$1 = state.vessel;
  return Mithril.setState(vnode, vessels$1.length !== 0 ? ({
                  TAG: "Loaded",
                  vessel: vessel$1,
                  vessels: vessels$1,
                  search: "",
                  staffers: staffers
                }) : "NoVessels");
}

function sameId(vesselA, vesselB) {
  if (vesselB !== undefined) {
    return Caml_obj.equal(vesselA.id, vesselB.id);
  } else {
    return false;
  }
}

function Accounts_LandingPageVessel$VesselLandingPage(props) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), initialize), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var search = match.search;
                var vessels = match.vessels;
                var vessel = match.vessel;
                var filteredVessels;
                if (search === "") {
                  filteredVessels = vessels;
                } else {
                  var queryTokens = stringToTokens(search);
                  filteredVessels = vessels.filter(function (curVessel) {
                        var contentTokens = stringToTokens(curVessel.name);
                        var _queryTokens = queryTokens;
                        var _contentTokens = contentTokens;
                        while(true) {
                          var contentTokens$1 = _contentTokens;
                          var queryTokens$1 = _queryTokens;
                          if (!queryTokens$1) {
                            return true;
                          }
                          var match = insideTokens(queryTokens$1.hd, contentTokens$1);
                          if (!match[0]) {
                            return false;
                          }
                          _contentTokens = match[1];
                          _queryTokens = queryTokens$1.tl;
                          continue ;
                        };
                      });
                }
                var match$1 = State__Memory.Current.get();
                var navbarDisabled = Curry._1(Prelude.OptionExported.$$Option.isNone, match$1.vessel) || Curry._1(Prelude.OptionExported.$$Option.isNone, match$1.profile);
                var staffersByVessel = VesselId.$$Array.getter(match.staffers, (function (s) {
                        return s.vessel;
                      }));
                return React.jsx(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.Full.make, {})),
                            footer: Caml_option.some(React.jsx(PageNavigation.make, {
                                      disabled: navbarDisabled
                                    })),
                            children: Caml_option.some(ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsxs("div", {
                                              children: [
                                                React.jsx(Feather.make, {
                                                      icon: "search",
                                                      className: "form-icon"
                                                    }),
                                                ReactDOM.jsx("input", {
                                                      className: "form-input",
                                                      autoComplete: "off",
                                                      name: "search",
                                                      placeholder: "Search",
                                                      onInput: (function (_event) {
                                                          var search = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=search]")), "");
                                                          var curState = vnode.state.data;
                                                          if (typeof curState !== "object") {
                                                            return ;
                                                          } else {
                                                            return Mithril.setState(vnode, {
                                                                        TAG: "Loaded",
                                                                        vessel: curState.vessel,
                                                                        vessels: curState.vessels,
                                                                        search: search,
                                                                        staffers: curState.staffers
                                                                      });
                                                          }
                                                        })
                                                    })
                                              ],
                                              className: "has-icon-right tw-py-2"
                                            }),
                                        React.jsx(LabelLandingPage.make, {
                                              text: "Select vessel:"
                                            }),
                                        filteredVessels.map(function (curVessel) {
                                              var isCurVessel = sameId(curVessel, vessel);
                                              return ReactDOM.jsx("div", {
                                                          children: curVessel.name,
                                                          className: Mithril.$$class([
                                                                [
                                                                  "tw-grow tw-border-[1.5px] tw-rounded tw-shadow-kaiko-card",
                                                                  true
                                                                ],
                                                                [
                                                                  "tw-my-2 tw-px-2 tw-py-1 tw-font-medium",
                                                                  true
                                                                ],
                                                                [
                                                                  "tw-border-solid tw-border-primary",
                                                                  isCurVessel
                                                                ]
                                                              ], undefined),
                                                          onClick: (function (_event) {
                                                              var staffers = staffersByVessel(curVessel.id);
                                                              var systemState = State__Memory.Current.get();
                                                              var profile = systemState.profile;
                                                              if (profile !== undefined && Caml_obj.equal(profile.vessel, curVessel.id)) {
                                                                State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.autoSelectRole(State__Memory.Transformations.setCurrentVessel(State__Memory.Transformations.make(systemState), curVessel), staffers)));
                                                                Mithril$1.route.set(URLs.userProfiles());
                                                                return ;
                                                              }
                                                              State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.autoSelectRole(State__Memory.Transformations.setCurrentVessel(State__Memory.Transformations.removeCurrentProfile(State__Memory.Transformations.make(systemState)), curVessel), staffers)));
                                                              Mithril$1.route.set("/");
                                                            })
                                                        });
                                            })
                                      ],
                                      className: "t-vessel-view tw-px-3 tw-pt-2"
                                    }))
                          });
              }));
}

function Accounts_LandingPageVessel(props) {
  return Mithril.view(Mithril.component(), (function (param) {
                return React.jsx(Accounts_LandingPageVessel$VesselLandingPage, {});
              }));
}

var include = ProtectedViewMaker.Make({
      make: Accounts_LandingPageVessel,
      requireProfile: false,
      requireNonCrew: false
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */

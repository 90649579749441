// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

var ProcedureId = Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

var ProcedureReader = IDB__Migration__Utils.MakeReader({
      storeName: "procedures"
    });

function message(param, param$1) {
  return "Installing updates for planned inspections";
}

function $$process(plannedProc, index) {
  var proc = ProcedureId.$$Map.get(index, plannedProc.id);
  if (proc !== undefined) {
    return {
            TAG: "Update",
            _0: {
              id: proc.id,
              component_id: proc.component_id,
              section_id: proc.section_id
            }
          };
  } else {
    return "Delete";
  }
}

var PlannedProcedureRewriter = InitFeedback.MakeRewriter({
      storeName: "plannedProcedures",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing updates for planned inspections";
}

function migrate(_db, transaction) {
  IDB__Migration__Store.createSimpleIndex(transaction.objectStore("plannedProcedures"), "section_id");
  return Prelude.then(ProcedureReader.read(transaction), (function (procedures) {
                var index = ProcedureId.$$Map.fromArray(procedures.map(function (p) {
                          return [
                                  p.id,
                                  p
                                ];
                        }));
                return PlannedProcedureRewriter.rewrite(transaction, index);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* ProcedureId Not a pure module */

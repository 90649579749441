// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var _map = {"OkNotOkQuestion":"OkNotOkQuestion","RatingQuestion":"RatingQuestion","WeightDeltaQuestion":"WeightDeltaQuestion","DateQuestion":"DateQuestion","TextQuestion":"TextQuestion","SignatureQuestion":"SignatureQuestion","YesNoQuestion":"YesNoQuestion","PhotoAlbumQuestion":"PhotoAlbumQuestion","IntegerQuestion":"IntegerQuestion"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var Type = {
  tToJs: tToJs,
  tFromJs: tFromJs
};

var fromJS_1 = $$JSON.Field.usingString(function (x) {
      if (x === "ComponentWeightMeasurementQuestion") {
        return "WeightDeltaQuestion";
      } else {
        return _map[x];
      }
    });

var fromJS = {
  TAG: "Morphism",
  _0: "String",
  _1: fromJS_1
};

var Deserializer = {
  fromJS: fromJS
};

function toJSON(item) {
  return item;
}

var Serializer = {
  toJSON: toJSON
};

function make(questionType, ratingOpt, okOrNotOpt, weightOpt, dateOpt, textOpt, yesOrNoOpt, integerOpt, param) {
  var rating = ratingOpt !== undefined ? Caml_option.valFromOption(ratingOpt) : undefined;
  var okOrNot = okOrNotOpt !== undefined ? Caml_option.valFromOption(okOrNotOpt) : undefined;
  var weight = weightOpt !== undefined ? Caml_option.valFromOption(weightOpt) : undefined;
  var date = dateOpt !== undefined ? Caml_option.valFromOption(dateOpt) : undefined;
  var text = textOpt !== undefined ? Caml_option.valFromOption(textOpt) : undefined;
  var yesOrNo = yesOrNoOpt !== undefined ? Caml_option.valFromOption(yesOrNoOpt) : undefined;
  var integer = integerOpt !== undefined ? Caml_option.valFromOption(integerOpt) : undefined;
  if (questionType === "RatingQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, rating, (function (r) {
                  return {
                          NAME: "Rating",
                          VAL: r
                        };
                }));
  } else if (questionType === "WeightDeltaQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, weight, (function (w) {
                  return {
                          NAME: "WeightDelta",
                          VAL: w
                        };
                }));
  } else if (questionType === "OkNotOkQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, okOrNot, (function (rating) {
                  return {
                          NAME: "OkNotOk",
                          VAL: rating === 5
                        };
                }));
  } else if (questionType === "YesNoQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, yesOrNo, (function (b) {
                  return {
                          NAME: "YesNo",
                          VAL: b
                        };
                }));
  } else if (questionType === "TextQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, text, (function (t) {
                  return {
                          NAME: "Text",
                          VAL: t
                        };
                }));
  } else if (questionType === "DateQuestion") {
    return Curry._2(Prelude.OptionExported.$$Option.map, date, (function (d) {
                  return {
                          NAME: "Date",
                          VAL: d
                        };
                }));
  } else if (questionType === "PhotoAlbumQuestion") {
    return {
            NAME: "PhotoAlbum",
            VAL: true
          };
  } else if (questionType === "SignatureQuestion") {
    return {
            NAME: "Signature",
            VAL: true
          };
  } else {
    return Curry._2(Prelude.OptionExported.$$Option.map, integer, (function (i) {
                  return {
                          NAME: "Integer",
                          VAL: i
                        };
                }));
  }
}

function matches(value, questionType) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                    var variant = v.NAME;
                    if (variant === "WeightDelta") {
                      return questionType === "WeightDeltaQuestion";
                    } else if (variant === "Integer") {
                      return questionType === "IntegerQuestion";
                    } else if (variant === "Rating") {
                      return questionType === "RatingQuestion";
                    } else if (variant === "YesNo") {
                      return questionType === "YesNoQuestion";
                    } else if (variant === "PhotoAlbum") {
                      return questionType === "PhotoAlbumQuestion";
                    } else if (variant === "Date") {
                      return questionType === "DateQuestion";
                    } else if (variant === "Signature") {
                      return questionType === "SignatureQuestion";
                    } else if (variant === "Text") {
                      return questionType === "TextQuestion";
                    } else {
                      return questionType === "OkNotOkQuestion";
                    }
                  })), false);
}

function rate(value) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                    var variant = v.NAME;
                    if (variant !== "WeightDelta") {
                      if (variant === "Text" || variant === "Signature" || variant === "Date" || variant === "PhotoAlbum" || variant === "YesNo" || variant === "Integer") {
                        return 5;
                      } else if (variant === "Rating") {
                        return v.VAL;
                      } else if (v.VAL) {
                        return 5;
                      } else {
                        return 1;
                      }
                    }
                    var match = v.VAL;
                    if (match.current_weight / match.initial_weight > 0.95) {
                      return 5;
                    } else {
                      return 1;
                    }
                  })), 0);
}

function getRating(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                if (typeof v === "object" && v.NAME === "Rating") {
                  return v.VAL;
                }
                
              }));
}

function getOkOrNot(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                if (typeof v === "object" && v.NAME === "OkNotOk") {
                  return v.VAL;
                }
                
              }));
}

function getDate(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                if (typeof v === "object" && v.NAME === "Date") {
                  return Caml_option.some(v.VAL);
                }
                
              }));
}

function getText(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                if (typeof v === "object" && v.NAME === "Text") {
                  return v.VAL;
                }
                
              }));
}

function getWeightDelta(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                if (typeof v === "object" && v.NAME === "WeightDelta") {
                  return v.VAL;
                }
                
              }));
}

function getYesNo(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                if (typeof v === "object" && v.NAME === "YesNo") {
                  return v.VAL;
                }
                
              }));
}

function getAnswer(value) {
  if (value === undefined) {
    return "Unknown";
  }
  if (typeof value !== "object") {
    return "Unknown";
  }
  var variant = value.NAME;
  if (variant === "YesNo" || variant === "OkNotOk") {
    if (value.VAL) {
      return "Yes";
    } else {
      return "No";
    }
  } else {
    return "Unknown";
  }
}

function getInteger(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                if (typeof v === "object" && v.NAME === "Integer") {
                  return v.VAL;
                }
                
              }));
}

var Value = {
  make: make,
  matches: matches,
  rate: rate,
  getRating: getRating,
  getOkOrNot: getOkOrNot,
  getDate: getDate,
  getText: getText,
  getWeightDelta: getWeightDelta,
  getYesNo: getYesNo,
  getAnswer: getAnswer,
  getInteger: getInteger
};

export {
  Type ,
  tToJs ,
  tFromJs ,
  Deserializer ,
  Serializer ,
  Value ,
}
/* fromJS Not a pure module */

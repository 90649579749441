// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../../Query.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Rating from "../../../survey/Rating.js";
import * as Vessel from "../../../vessel/Vessel.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../../libs/LocalImage.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../../libs/Conditional.js";
import * as TakePicture from "../../../common/views/TakePicture.js";
import * as DetailImages from "../../../common/views/DetailImages.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as QuestionWidget from "./QuestionWidget.js";
import * as NavigationPills from "../../../common/views/NavigationPills.js";
import * as MarkdownDocumentId from "../../../markdown/MarkdownDocumentId.js";
import * as MarkdownDocumentPage from "../../../markdown/views/MarkdownDocumentPage.js";

function allowSave(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return false;
  } else {
    return Curry._1(Prelude.OptionExported.$$Option.isSome, match.rating);
  }
}

function getValue(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return {
            attachments: []
          };
  } else {
    return {
            value: {
              NAME: "Value",
              VAL: {
                NAME: "Rating",
                VAL: [
                  Prelude.default(match.rating, 0),
                  match.annotations
                ]
              }
            },
            attachments: match.pictures,
            remarks: match.remarks
          };
  }
}

function processAttachment(vnode, _image, attachment, param) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                vessel: match.vessel,
                lastNewAnswer: match.lastNewAnswer,
                lastAnswer: match.lastAnswer,
                question: match.question,
                document: match.document,
                level: match.level,
                pictures: Curry._2(Prelude.$$Array.concat, match.pictures, [attachment]),
                step: 2,
                rating: match.rating,
                annotations: match.annotations,
                remarks: match.remarks
              });
  }
  console.log("Error");
}

function RatingQuestionWidget$Editor(props) {
  return Mithril.view(Mithril.component("NotReady"), (function (vnode) {
                var isRectification = Prelude.default(Mithril__Route.paramBool("isRectification"), false);
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var remarks = match.remarks;
                  var annotations = match.annotations;
                  var rating = match.rating;
                  var step = match.step;
                  var pictures = match.pictures;
                  var level = match.level;
                  var $$document = match.document;
                  var question = match.question;
                  var lastAnswer = match.lastAnswer;
                  var lastNewAnswer = match.lastNewAnswer;
                  var vessel = match.vessel;
                  switch (step) {
                    case 1 :
                        var last = Curry._2(Prelude.OptionExported.$$Option.orElse, lastNewAnswer, lastAnswer);
                        var tmp$1;
                        if (last !== undefined) {
                          var attachment = Prelude.$$Array.first(last.evidences);
                          tmp$1 = ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("h4", {
                                        children: "Example",
                                        className: "text-center"
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank"
                                      }),
                                  ReactDOM.jsxs("figure", {
                                        children: [
                                          attachment !== undefined ? React.jsx(LocalImage.Resource.make, {
                                                  resource: attachment.id
                                                }) : Mithril.empty,
                                          ReactDOM.jsx("div", {
                                                className: "divider blank xl"
                                              }),
                                          ReactDOM.jsx("figcaption", {
                                                children: "The photo should look like this",
                                                className: "figure-caption text-center"
                                              })
                                        ],
                                        className: "figure"
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      })
                                ]
                              });
                        } else {
                          tmp$1 = Mithril.empty;
                        }
                        tmp = [
                          React.jsx(NavigationPills.make, {
                                currentStep: step - 1 | 0,
                                stepCount: 2
                              }),
                          $$document !== undefined ? React.jsx(MarkdownDocumentPage.MarkdownDocumentView.make, {
                                  document: $$document
                                }) : Mithril.empty,
                          tmp$1
                        ];
                        break;
                    case 2 :
                        var attachment$1 = Prelude.$$Array.first(pictures);
                        tmp = [
                          React.jsx(NavigationPills.make, {
                                currentStep: step - 1 | 0,
                                stepCount: 2
                              }),
                          ReactDOM.jsx("p", {
                                children: question.name
                              }),
                          attachment$1 !== undefined ? React.jsx(LocalImage.Resource.make, {
                                  resource: attachment$1.id
                                }) : Mithril.empty,
                          ReactDOM.jsx("div", {
                                className: "divider xl blank"
                              }),
                          React.jsx(DetailImages.make, {
                                deleteAttachment: (async function (v1, param) {
                                    console.log(v1);
                                  }),
                                attachments: Curry._2(Prelude.$$Array.sliceToEnd, pictures, 1)
                              })
                        ];
                        break;
                    case 3 :
                        var buttons = [];
                        var styleRules = [
                          Rating.StylingRule.Defaults.rating_1,
                          Rating.StylingRule.Defaults.rating_2,
                          Rating.StylingRule.Defaults.rating_3,
                          Rating.StylingRule.Defaults.rating_4,
                          Rating.StylingRule.Defaults.rating_5
                        ];
                        for(var i = 1; i <= 5; ++i){
                          var ratingStyleRule = styleRules[i - 1 | 0];
                          var cssClass = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ratingStyleRule.css_class, (function (v) {
                                      return "btn-" + v;
                                    })), "");
                          buttons.push(ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("button", {
                                          children: i,
                                          className: "btn-action btn s-circle " + (
                                            Caml_obj.equal(rating, i) ? cssClass + " active" : ""
                                          ),
                                          onClick: (function(i){
                                          return function (param) {
                                            Mithril.setState(vnode, {
                                                  TAG: "Loaded",
                                                  vessel: vessel,
                                                  lastNewAnswer: lastNewAnswer,
                                                  lastAnswer: lastAnswer,
                                                  question: question,
                                                  document: $$document,
                                                  level: level,
                                                  pictures: pictures,
                                                  step: step,
                                                  rating: i,
                                                  annotations: annotations,
                                                  remarks: remarks
                                                });
                                          }
                                          }(i))
                                        }),
                                    className: "column text-center"
                                  }));
                        }
                        var match$1 = question.kind;
                        var annotationOptions = typeof match$1 === "object" ? (
                            match$1.NAME === "RatingQuestion" ? match$1.VAL.annotations.map(function (annotation) {
                                    return ReactDOM.jsxKeyed("div", {
                                                children: ReactDOM.jsxs("label", {
                                                      children: [
                                                        ReactDOM.jsx("input", {
                                                              checked: Prelude.$$Array.includes(annotations, annotation),
                                                              type: "checkbox",
                                                              onChange: (function ($$event) {
                                                                  var state = vnode.state.data;
                                                                  if (typeof state !== "object") {
                                                                    return ;
                                                                  }
                                                                  var newAnnotations = Prelude.$$Array.includes(state.annotations, annotation) ? Curry._2(Prelude.$$Array.keep, state.annotations, (function (a) {
                                                                            return a !== annotation;
                                                                          })) : Curry._2(Prelude.$$Array.concat, state.annotations, [annotation]);
                                                                  Mithril.setState(vnode, {
                                                                        TAG: "Loaded",
                                                                        vessel: state.vessel,
                                                                        lastNewAnswer: state.lastNewAnswer,
                                                                        lastAnswer: state.lastAnswer,
                                                                        question: state.question,
                                                                        document: state.document,
                                                                        level: state.level,
                                                                        pictures: state.pictures,
                                                                        step: state.step,
                                                                        rating: state.rating,
                                                                        annotations: newAnnotations,
                                                                        remarks: state.remarks
                                                                      });
                                                                })
                                                            }),
                                                        ReactDOM.jsx("i", {
                                                              className: "form-icon"
                                                            }),
                                                        annotation
                                                      ],
                                                      className: "form-checkbox"
                                                    }),
                                                className: "form-group"
                                              }, annotation, undefined);
                                  }) : []
                          ) : [];
                        tmp = [
                          ReactDOM.jsx("p", {
                                children: question.name
                              }),
                          ReactDOM.jsx("div", {
                                className: "divider xl blank"
                              }),
                          ReactDOM.jsx("div", {
                                children: buttons,
                                className: "columns m-auto"
                              }),
                          ReactDOM.jsx("div", {
                                className: "divider blank"
                              }),
                          ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("div", {
                                        className: "column col-1"
                                      }),
                                  ReactDOM.jsxs("div", {
                                        children: [
                                          React.jsxs(Conditional.make, {
                                                cond: vessel.hide_observations && !isRectification,
                                                children: [
                                                  annotationOptions,
                                                  ReactDOM.jsx("div", {
                                                        children: ReactDOM.jsx("input", {
                                                              className: "form-input",
                                                              name: "remarks",
                                                              placeholder: "Write a comment",
                                                              type: "text",
                                                              value: remarks,
                                                              onInput: (function (extra) {
                                                                  var annotation = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=remarks]")), "");
                                                                  var state = vnode.state.data;
                                                                  if (typeof state !== "object") {
                                                                    return ;
                                                                  } else {
                                                                    return Mithril.setState(vnode, {
                                                                                TAG: "Loaded",
                                                                                vessel: state.vessel,
                                                                                lastNewAnswer: state.lastNewAnswer,
                                                                                lastAnswer: state.lastAnswer,
                                                                                question: state.question,
                                                                                document: state.document,
                                                                                level: state.level,
                                                                                pictures: state.pictures,
                                                                                step: state.step,
                                                                                rating: state.rating,
                                                                                annotations: state.annotations,
                                                                                remarks: annotation
                                                                              });
                                                                  }
                                                                })
                                                            }),
                                                        className: "form-group"
                                                      })
                                                ]
                                              }),
                                          ReactDOM.jsx("div", {
                                                className: "column col-1"
                                              })
                                        ],
                                        className: "column col-mx-auto"
                                      })
                                ],
                                className: "columns t-rating-editor"
                              })
                        ];
                        break;
                    default:
                      tmp = [];
                  }
                }
                return React.jsx(QuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                return getValue(vnode, extra);
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var pictures = match.pictures;
                                if (createNew) {
                                  return Caml_obj.notequal(pictures, []);
                                }
                                var lastNewAnswer = match.lastNewAnswer;
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 !== "object") {
                                  return false;
                                }
                                if (match$2.NAME !== "Rating") {
                                  return false;
                                }
                                var match$3 = match$2.VAL;
                                if (Caml_obj.notequal(pictures, lastNewAnswer.evidences) || Caml_obj.notequal(Prelude.$$Array.sort(match.annotations, (function (a) {
                                              return a;
                                            }), undefined), Prelude.$$Array.sort(match$3[1], (function (a) {
                                              return a;
                                            }), undefined)) || Caml_obj.notequal(match.rating, match$3[0])) {
                                  return true;
                                } else {
                                  return match.remarks !== lastNewAnswer.remarks;
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var step = Prelude.default(Mithril__Route.paramInt("step"), createNew ? 1 : 2);
                                if (typeof extra !== "object") {
                                  return Mithril.setState(vnode, "NotReady");
                                }
                                var vessel = extra.vessel;
                                var level = extra.level;
                                var question = extra.question;
                                var lastNewAnswer = extra.lastNewAnswer;
                                var lastAnswer = extra.lastAnswer;
                                var record = vnode.state.data;
                                if (typeof record === "object") {
                                  return Mithril.setState(vnode, {
                                              TAG: "Loaded",
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              question: question,
                                              document: record.document,
                                              level: level,
                                              pictures: record.pictures,
                                              step: record.step,
                                              rating: record.rating,
                                              annotations: record.annotations,
                                              remarks: record.remarks
                                            });
                                }
                                var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                return Prelude.thenDo(Query.read((newrecord.documents = {
                                                  TAG: "Get",
                                                  _0: Prelude.default(question.guidance_document_id, MarkdownDocumentId.$$null)
                                                }, newrecord)), (function (param) {
                                              var $$document = Prelude.$$Array.first(param.documents);
                                              var match;
                                              if (createNew) {
                                                match = [
                                                  [],
                                                  "",
                                                  undefined,
                                                  []
                                                ];
                                              } else if (lastNewAnswer !== undefined) {
                                                var match$1 = lastNewAnswer.value;
                                                var exit = 0;
                                                if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "Value") {
                                                  var match$2 = match$1.VAL;
                                                  if (typeof match$2 === "object" && match$2.NAME === "Rating") {
                                                    var match$3 = match$2.VAL;
                                                    match = [
                                                      lastNewAnswer.evidences,
                                                      lastNewAnswer.remarks,
                                                      match$3[0],
                                                      match$3[1]
                                                    ];
                                                  } else {
                                                    exit = 1;
                                                  }
                                                } else {
                                                  exit = 1;
                                                }
                                                if (exit === 1) {
                                                  match = [
                                                    [],
                                                    "",
                                                    undefined,
                                                    []
                                                  ];
                                                }
                                                
                                              } else {
                                                match = [
                                                  [],
                                                  "",
                                                  undefined,
                                                  []
                                                ];
                                              }
                                              Mithril.setState(vnode, {
                                                    TAG: "Loaded",
                                                    vessel: vessel,
                                                    lastNewAnswer: lastNewAnswer,
                                                    lastAnswer: lastAnswer,
                                                    question: question,
                                                    document: $$document,
                                                    level: level,
                                                    pictures: match[0],
                                                    step: step,
                                                    rating: match[2],
                                                    annotations: match[3],
                                                    remarks: match[1]
                                                  });
                                            }));
                              }),
                            bottom: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return Mithril.empty;
                                }
                                var question = match.question;
                                var vessel = match.vessel;
                                switch (match.step) {
                                  case 1 :
                                      return React.jsxs(TakePicture.make, {
                                                  role: "MAIN_EVIDENCE",
                                                  requireLandscape: question.require_landscape_pictures,
                                                  className: "btn btn-primary btn-block btn-lg t-continue",
                                                  children: [
                                                    "Take picture",
                                                    React.jsx(Feather.make, {
                                                          icon: "camera",
                                                          className: "ml-2"
                                                        })
                                                  ],
                                                  onCapture: (function (extra, extra$1, extra$2) {
                                                      return processAttachment(vnode, extra, extra$1, extra$2);
                                                    }),
                                                  onCameraFailure: (function (extra) {
                                                      console.log(vnode);
                                                    }),
                                                  useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                                  resolution: vessel.max_pic_resolution
                                                });
                                  case 2 :
                                      return ReactDOM.jsxs("div", {
                                                  children: [
                                                    React.jsxs(TakePicture.make, {
                                                          role: "EVIDENCE",
                                                          requireLandscape: question.require_landscape_pictures,
                                                          style: {
                                                            height: "3rem",
                                                            marginRight: "0.66666667%",
                                                            width: "66%"
                                                          },
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "camera"
                                                                }),
                                                            "Add detail pictures"
                                                          ],
                                                          onCapture: (function (extra, extra$1, extra$2) {
                                                              return processAttachment(vnode, extra, extra$1, extra$2);
                                                            }),
                                                          useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                                          resolution: vessel.max_pic_resolution
                                                        }),
                                                    ReactDOM.jsxs("button", {
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "save"
                                                                }),
                                                            "Continue"
                                                          ],
                                                          className: "btn btn-primary col-4",
                                                          style: {
                                                            height: "3rem"
                                                          },
                                                          onClick: (function (extra) {
                                                              var match = vnode.state.data;
                                                              if (typeof match !== "object") {
                                                                return ;
                                                              } else {
                                                                return Mithril.setState(vnode, {
                                                                            TAG: "Loaded",
                                                                            vessel: match.vessel,
                                                                            lastNewAnswer: match.lastNewAnswer,
                                                                            lastAnswer: match.lastAnswer,
                                                                            question: match.question,
                                                                            document: match.document,
                                                                            level: match.level,
                                                                            pictures: match.pictures,
                                                                            step: 3,
                                                                            rating: match.rating,
                                                                            annotations: match.annotations,
                                                                            remarks: match.remarks
                                                                          });
                                                              }
                                                            })
                                                        })
                                                  ],
                                                  className: "columns m-0"
                                                });
                                  case 3 :
                                      return ReactDOM.jsxs("button", {
                                                  children: [
                                                    React.jsx(Feather.make, {
                                                          icon: "save"
                                                        }),
                                                    "Save"
                                                  ],
                                                  className: Mithril.$$class([
                                                        [
                                                          "btn btn-primary ",
                                                          true
                                                        ],
                                                        [
                                                          "disabled",
                                                          !allowSave(vnode, undefined)
                                                        ]
                                                      ], undefined),
                                                  style: {
                                                    height: "3rem"
                                                  },
                                                  onClick: (function (extra) {
                                                      return QuestionWidget.Editor.save(vnode, (function (extra) {
                                                                    return getValue(vnode, extra);
                                                                  }), false, extra);
                                                    })
                                                });
                                  default:
                                    return Mithril.empty;
                                }
                              }),
                            children: tmp
                          });
              }));
}

function RatingQuestionWidget(props) {
  var draftSubmission = props.draftSubmission;
  var lastAnswer = props.lastAnswer;
  var lastNewAnswer = props.lastNewAnswer;
  var vessel = props.vessel;
  var level = props.level;
  var question = props.question;
  return Mithril.view(Mithril.component(), (function (vnode) {
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                var submissionId = Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                        return s.id;
                      }));
                var editUrl = function (none, none$1, extra, extra$1, extra$2) {
                  return URLs.ratingQuestionWidgetEditor(none, Prelude.default(Mithril__Route.paramBool("isRectification"), false), none$1, Caml_option.some(undefined), extra, extra$1, extra$2);
                };
                return React.jsx(QuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            draftSubmission: draftSubmission,
                            editUrl: editUrl,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "camera"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active ",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isNone(submissionId)
                                        ],
                                        [
                                          "bg-success",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered && Belt_Option.isSome(submissionId)
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return QuestionWidget.startInspection(vnode, editUrl, extra);
                                    })
                                })
                          });
              }));
}

var Editor = {
  make: RatingQuestionWidget$Editor
};

var make = RatingQuestionWidget;

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */

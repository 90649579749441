// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../libs/rescript-mithril/src/React.js";
import * as Feather from "./common/views/Feather.js";
import * as Mithril from "../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../libs/rescript-mithril/src/ReactDOM.js";
import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as IDB__CountRequest from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/IDB__CountRequest.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";
import FullLogoVerticalPng from "/images/full-logo-vertical.png";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var message = vnode.attrs.migration;
                switch (message.TAG) {
                  case "Pending" :
                      return ReactDOM.jsxs("p", {
                                  children: [
                                    React.jsx(Feather.make, {
                                          icon: "check",
                                          size: 12
                                        }),
                                    ReactDOM.jsx("span", {
                                          children: message._0,
                                          className: "ml-2"
                                        })
                                  ],
                                  className: "text-tiny text-gray",
                                  style: {
                                    lineHeight: "0.8rem"
                                  }
                                });
                  case "Running" :
                      return ReactDOM.jsxs("p", {
                                  children: [
                                    React.jsx(Feather.make, {
                                          icon: "loader",
                                          size: 12
                                        }),
                                    ReactDOM.jsx("span", {
                                          children: message._0,
                                          className: "ml-2"
                                        })
                                  ],
                                  className: "text-tiny text-green",
                                  style: {
                                    lineHeight: "0.8rem"
                                  }
                                });
                  case "Done" :
                      return ReactDOM.jsxs("p", {
                                  children: [
                                    React.jsx(Feather.make, {
                                          icon: "check",
                                          size: 12
                                        }),
                                    ReactDOM.jsx("del", {
                                          children: message._0,
                                          className: "ml-2"
                                        })
                                  ],
                                  className: "text-tiny text-gray",
                                  style: {
                                    lineHeight: "0.8rem"
                                  }
                                });
                  
                }
              }));
}

var MigrationFeedback = {
  make: make
};

function make$1(state) {
  return Mithril.view(Mithril.component(), (function (param) {
                var migrations = Prelude.$$Array.sort(Belt_HashMapInt.toArray(state.migrations), (function (param) {
                          return param[0];
                        }), undefined).map(function (param) {
                      return React.jsx(make, {
                                  migration: param[1]
                                });
                    });
                var match = state.error;
                var match$1 = state.progress;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("img", {
                                    alt: "Kaiko Systems",
                                    src: FullLogoVerticalPng
                                  }),
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, state.message, (function (msg) {
                                          return [
                                                  ReactDOM.jsx("div", {
                                                        className: "divider blank"
                                                      }),
                                                  ReactDOM.jsxs("p", {
                                                        children: [
                                                          state.error ? React.jsx(Feather.make, {
                                                                  icon: "alert_triangle",
                                                                  className: "mr-2 text-error",
                                                                  size: 16
                                                                }) : Mithril.empty,
                                                          msg
                                                        ],
                                                        style: {
                                                          width: "80%"
                                                        }
                                                      })
                                                ];
                                        })), Mithril.empty),
                              match ? Mithril.empty : (
                                  match$1 !== undefined ? ReactDOM.jsx("progress", {
                                          style: {
                                            width: "80%"
                                          },
                                          max: "100",
                                          value: String(match$1 | 0)
                                        }) : ReactDOM.jsx("progress", {
                                          style: {
                                            width: "80%"
                                          }
                                        })
                                ),
                              state.error ? Mithril.empty : ReactDOM.jsx("div", {
                                      children: migrations,
                                      style: {
                                        marginTop: "1.5rem",
                                        maxHeight: "5rem",
                                        width: "80%"
                                      }
                                    })
                            ],
                            className: "loader"
                          });
              }));
}

var $$Screen = {
  MigrationFeedback: MigrationFeedback,
  make: make$1
};

var state = {
  contents: {
    message: undefined,
    progress: undefined,
    error: false,
    migrations: Belt_HashMapInt.make(10),
    done: false
  }
};

var Queue = ActionQueue.MakeActionQueue({
      name: "Init Feedback throttler"
    });

var queue = Queue.make(false, 1, undefined);

function updateFeedback(cmds) {
  Queue.replace(queue, (function () {
          return Prelude.thenResolve(Prelude.Promises.ellapsed(500), (function () {
                        var newState = state.contents.done ? state.contents : cmds.reduce((function (state, cmd) {
                                  if (typeof cmd !== "object") {
                                    switch (cmd) {
                                      case "RemoveMessage" :
                                          return {
                                                  message: undefined,
                                                  progress: state.progress,
                                                  error: state.error,
                                                  migrations: state.migrations,
                                                  done: state.done
                                                };
                                      case "RemoveProgress" :
                                          return {
                                                  message: state.message,
                                                  progress: undefined,
                                                  error: state.error,
                                                  migrations: state.migrations,
                                                  done: state.done
                                                };
                                      case "RemoveError" :
                                          return {
                                                  message: undefined,
                                                  progress: state.progress,
                                                  error: false,
                                                  migrations: state.migrations,
                                                  done: state.done
                                                };
                                      case "ClearMigrations" :
                                          Belt_HashMapInt.clear(state.migrations);
                                          return state;
                                      
                                    }
                                  } else {
                                    switch (cmd.TAG) {
                                      case "Message" :
                                          return {
                                                  message: cmd._0,
                                                  progress: state.progress,
                                                  error: state.error,
                                                  migrations: state.migrations,
                                                  done: state.done
                                                };
                                      case "ErrorMessage" :
                                          return {
                                                  message: cmd._0,
                                                  progress: state.progress,
                                                  error: true,
                                                  migrations: state.migrations,
                                                  done: state.done
                                                };
                                      case "Progress" :
                                          return {
                                                  message: state.message,
                                                  progress: cmd._0,
                                                  error: state.error,
                                                  migrations: state.migrations,
                                                  done: state.done
                                                };
                                      case "AddMigration" :
                                          Belt_HashMapInt.set(state.migrations, cmd._0, {
                                                TAG: "Pending",
                                                _0: cmd._1
                                              });
                                          return state;
                                      case "RunningMigration" :
                                          Belt_HashMapInt.set(state.migrations, cmd._0, {
                                                TAG: "Running",
                                                _0: cmd._1
                                              });
                                          return state;
                                      case "DoneMigration" :
                                          Belt_HashMapInt.set(state.migrations, cmd._0, {
                                                TAG: "Done",
                                                _0: cmd._1
                                              });
                                          return state;
                                      
                                    }
                                  }
                                }), state.contents);
                        if (Caml_obj.notequal(newState, state.contents)) {
                          state.contents = newState;
                          Curry._2(Prelude.OptionExported.$$Option.map, Caml_option.nullable_to_opt(document.getElementById("app")), (function (root) {
                                  Mithril$1.mount(root, make$1(newState));
                                }));
                          return ;
                        }
                        
                      }));
        }), undefined);
}

function updateMessage(msg) {
  updateFeedback([{
          TAG: "Message",
          _0: msg
        }]);
}

function removeProgress() {
  updateFeedback(["RemoveProgress"]);
}

function done() {
  var init = state.contents;
  state.contents = {
    message: init.message,
    progress: init.progress,
    error: init.error,
    migrations: init.migrations,
    done: true
  };
  Queue.clear(queue);
}

function MakeRewriter(T) {
  var progress = {
    contents: 0
  };
  var $$process = function (item, param) {
    var count = param[0];
    var msg = T.message(progress.contents, count);
    if (count > 0) {
      progress.contents = progress.contents + 1 | 0;
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            {
              TAG: "Progress",
              _0: progress.contents / count * 100.0
            }
          ]);
    } else {
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            "RemoveProgress"
          ]);
    }
    return T.$$process(item, param[1]);
  };
  var Rewriter = IDB__Migration__Utils.MakeRewriter({
        storeName: T.storeName,
        $$process: $$process
      });
  var count = function (transaction) {
    return IDB__CountRequest.promise(transaction.objectStore(T.storeName).count());
  };
  var rewrite = function (transaction, extra) {
    return Prelude.then(count(transaction), (function (count) {
                  console.log("Rewriting " + String(count) + " items of store " + T.storeName);
                  return Rewriter.rewrite(transaction, [
                              count,
                              extra
                            ]);
                }));
  };
  return {
          storeName: T.storeName,
          $$process: T.$$process,
          rewrite: rewrite
        };
}

function MakeInfallibleTransformator(T) {
  var storeName = T.storeName;
  var message = T.message;
  var $$process = function (item, param) {
    return {
            TAG: "Update",
            _0: T.$$process(item)
          };
  };
  var progress = {
    contents: 0
  };
  var $$process$1 = function (item, param) {
    var count = param[0];
    var msg = message(progress.contents, count);
    if (count > 0) {
      progress.contents = progress.contents + 1 | 0;
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            {
              TAG: "Progress",
              _0: progress.contents / count * 100.0
            }
          ]);
    } else {
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            "RemoveProgress"
          ]);
    }
    return {
            TAG: "Update",
            _0: T.$$process(item)
          };
  };
  var Rewriter = IDB__Migration__Utils.MakeRewriter({
        storeName: storeName,
        $$process: $$process$1
      });
  var count = function (transaction) {
    return IDB__CountRequest.promise(transaction.objectStore(storeName).count());
  };
  var rewrite = function (transaction, extra) {
    return Prelude.then(count(transaction), (function (count) {
                  console.log("Rewriting " + String(count) + " items of store " + storeName);
                  return Rewriter.rewrite(transaction, [
                              count,
                              extra
                            ]);
                }));
  };
  var Rewriter$1 = {
    storeName: storeName,
    $$process: $$process,
    rewrite: rewrite
  };
  var rewrite$1 = function (t) {
    return rewrite(t, undefined);
  };
  return {
          storeName: storeName,
          message: message,
          $$process: T.$$process,
          Rewriter: Rewriter$1,
          rewrite: rewrite$1
        };
}

function MakeSimpleRewriter(T) {
  var storeName = T.storeName;
  var message = T.message;
  var $$process = function (record, param) {
    return T.$$process(record);
  };
  var progress = {
    contents: 0
  };
  var $$process$1 = function (item, param) {
    var count = param[0];
    var msg = message(progress.contents, count);
    if (count > 0) {
      progress.contents = progress.contents + 1 | 0;
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            {
              TAG: "Progress",
              _0: progress.contents / count * 100.0
            }
          ]);
    } else {
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            "RemoveProgress"
          ]);
    }
    return T.$$process(item);
  };
  var Rewriter = IDB__Migration__Utils.MakeRewriter({
        storeName: storeName,
        $$process: $$process$1
      });
  var count = function (transaction) {
    return IDB__CountRequest.promise(transaction.objectStore(storeName).count());
  };
  var rewrite = function (transaction, extra) {
    return Prelude.then(count(transaction), (function (count) {
                  console.log("Rewriting " + String(count) + " items of store " + storeName);
                  return Rewriter.rewrite(transaction, [
                              count,
                              extra
                            ]);
                }));
  };
  var Impl = {
    storeName: storeName,
    $$process: $$process,
    rewrite: rewrite
  };
  var rewrite$1 = function (t) {
    return rewrite(t, undefined);
  };
  return {
          Impl: Impl,
          rewrite: rewrite$1
        };
}

function MakeSimpleInfallibleRewriter(R) {
  var storeName = R.storeName;
  var message = R.message;
  var $$process = function (record, param) {
    return {
            TAG: "Update",
            _0: R.$$process(record)
          };
  };
  var progress = {
    contents: 0
  };
  var $$process$1 = function (item, param) {
    var count = param[0];
    var msg = message(progress.contents, count);
    if (count > 0) {
      progress.contents = progress.contents + 1 | 0;
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            {
              TAG: "Progress",
              _0: progress.contents / count * 100.0
            }
          ]);
    } else {
      updateFeedback([
            {
              TAG: "Message",
              _0: msg
            },
            "RemoveProgress"
          ]);
    }
    return {
            TAG: "Update",
            _0: R.$$process(item)
          };
  };
  var Rewriter = IDB__Migration__Utils.MakeRewriter({
        storeName: storeName,
        $$process: $$process$1
      });
  var count = function (transaction) {
    return IDB__CountRequest.promise(transaction.objectStore(storeName).count());
  };
  var rewrite = function (transaction, extra) {
    return Prelude.then(count(transaction), (function (count) {
                  console.log("Rewriting " + String(count) + " items of store " + storeName);
                  return Rewriter.rewrite(transaction, [
                              count,
                              extra
                            ]);
                }));
  };
  var Impl = {
    storeName: storeName,
    $$process: $$process,
    rewrite: rewrite
  };
  var rewrite$1 = function (t) {
    return rewrite(t, undefined);
  };
  var Impl$1 = {
    Impl: Impl,
    rewrite: rewrite$1
  };
  return {
          Impl: Impl$1,
          storeName: R.storeName,
          message: R.message,
          $$process: R.$$process,
          rewrite: rewrite$1
        };
}

function MakeMigration(T) {
  var make = function (index) {
    var message = T.message();
    updateFeedback([{
            TAG: "AddMigration",
            _0: index,
            _1: message
          }]);
    return function (db, transaction) {
      updateFeedback([
            {
              TAG: "RunningMigration",
              _0: index,
              _1: message
            },
            "RemoveProgress",
            "RemoveMessage"
          ]);
      return Prelude.catchResolve(T.migrate(db, transaction), (function (e) {
                      console.error("Failed migration", e);
                      return {
                              TAG: "Error",
                              _0: "Failed migration " + String(index)
                            };
                    })).finally(function () {
                  updateFeedback([
                        {
                          TAG: "DoneMigration",
                          _0: index,
                          _1: message
                        },
                        "RemoveProgress",
                        "RemoveMessage"
                      ]);
                });
    };
  };
  return {
          make: make
        };
}

var IntMap;

export {
  IntMap ,
  $$Screen ,
  Queue ,
  updateFeedback ,
  updateMessage ,
  removeProgress ,
  done ,
  MakeRewriter ,
  MakeInfallibleTransformator ,
  MakeSimpleRewriter ,
  MakeSimpleInfallibleRewriter ,
  MakeMigration ,
}
/* state Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var Failed = /* @__PURE__ */Caml_exceptions.create("EndOfTransmissionAPI.Failed");

var url = "/survey/eot";

var fields = [[
    "ok",
    "Boolean"
  ]];

var Definition = {
  url: url,
  fields: fields
};

var Endpoint = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: url
    });

function post(user, vesselIds) {
  return Prelude.catchResolve(Prelude.PromisedResult.map(Endpoint.postOne(undefined, user, {
                      vessel_ids: vesselIds
                    }), (function (response) {
                    if (response !== undefined) {
                      if (response.ok) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: Failed,
                            Error: new Error()
                          };
                    }
                    throw {
                          RE_EXN_ID: Failed,
                          Error: new Error()
                        };
                  })), (function (e) {
                return {
                        TAG: "Error",
                        _0: e
                      };
              }));
}

export {
  Failed ,
  Definition ,
  Endpoint ,
  post ,
}
/* Endpoint Not a pure module */

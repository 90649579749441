// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../User.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserAPI from "../../api/endpoints/UserAPI.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as HttpError from "../../../libs/HttpError.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as State__User from "../../state/State__User.js";
import * as State__Memory from "../../state/State__Memory.js";

function handleError(error) {
  var status = HttpError.safeCode(error);
  var errors = 400 <= status && status < 500 ? error.response : (
      500 <= status ? Js_dict.fromArray([[
                "__all__",
                ["There was an error from the server side"]
              ]]) : Js_dict.fromArray([[
                "__all__",
                ["Are you offline?"]
              ]])
    );
  return {
          general: Curry._2(Prelude.Dict.get, errors, "__all__"),
          newPassword: Curry._2(Prelude.Dict.get, errors, "new_password"),
          oldPassowrd: Curry._2(Prelude.Dict.get, errors, "old_password")
        };
}

function changePassword(vnode, callback) {
  var oldPassowrd = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=old_password]"));
  var newPassword = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=new_password]"));
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return Promise.resolve(undefined);
  }
  switch (match.TAG) {
    case "Ready" :
    case "ErrorChangingThePassword" :
        break;
    default:
      return Promise.resolve(undefined);
  }
  if (newPassword === undefined) {
    return Promise.resolve(undefined);
  }
  var user = match.user;
  Mithril.setState(vnode, {
        TAG: "Busy",
        user: user
      });
  return Prelude.then(UserAPI.ChangePassword.change(user, oldPassowrd, newPassword), (function (result) {
                if (result.TAG === "Ok") {
                  callback();
                  Mithril.setState(vnode, {
                        TAG: "Ready",
                        user: user
                      });
                  return Prelude.PromisedResult.warn(State__User.login({
                                  id: user.id,
                                  first_name: user.first_name,
                                  last_name: user.last_name,
                                  token: user.token,
                                  email: user.email,
                                  has_valid_password: true
                                }));
                }
                Mithril.setState(vnode, {
                      TAG: "ErrorChangingThePassword",
                      user: user,
                      errors: handleError(result._0)
                    });
                return Promise.resolve(undefined);
              }));
}

function showMultipleErrors(errors) {
  return ReactDOM.jsx("p", {
              children: errors.map(function (error) {
                    return ReactDOM.jsxKeyed("li", {
                                children: error,
                                className: "m-0 pl-1"
                              }, error, undefined);
                  }),
              className: "text-error"
            });
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                        Prelude.thenDo(Prelude.PromisedResult.mapError(Prelude.PromisedResult.bind(UserAPI.ReadUser.read(Prelude.default(State__Memory.Current.get().user, User.anonymous)), State__User.login), (function (param) {
                                    
                                  })), (function (result) {
                                var tmp;
                                tmp = result.TAG === "Ok" ? ({
                                      TAG: "Ready",
                                      user: result._0
                                    }) : "YouAreOffline";
                                Mithril.setState(vnode, tmp);
                              }));
                      })), (function (vnode) {
                    var match = vnode.state.data;
                    var tmp;
                    if (typeof match !== "object") {
                      tmp = undefined;
                    } else {
                      switch (match.TAG) {
                        case "Ready" :
                            tmp = match.user.has_valid_password ? Mithril.$$Element.select(vnode, "[name=old_password]") : Mithril.$$Element.select(vnode, "[name=new_password]");
                            break;
                        case "ErrorChangingThePassword" :
                            tmp = Mithril.$$Element.select(vnode, "[name=new_password]");
                            break;
                        default:
                          tmp = undefined;
                      }
                    }
                    Mithril.$$Element.focus(tmp);
                  })), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = match === "NotReady" ? Mithril.empty : ReactDOM.jsx("div", {
                          children: "You are offline. Can't change password while offline",
                          className: "disable"
                        });
                } else {
                  var user = match.user;
                  var match$1 = vnode.state.data;
                  var tmp$1;
                  if (typeof match$1 !== "object" || match$1.TAG !== "ErrorChangingThePassword") {
                    tmp$1 = Mithril.empty;
                  } else {
                    var errors = match$1.errors.oldPassowrd;
                    tmp$1 = errors !== undefined ? ReactDOM.jsx("p", {
                            children: errors.join(" "),
                            className: "form-input-hint"
                          }) : Mithril.empty;
                  }
                  var match$2 = vnode.state.data;
                  var tmp$2;
                  tmp$2 = typeof match$2 !== "object" || match$2.TAG !== "ErrorChangingThePassword" ? false : match$2.errors.oldPassowrd !== undefined;
                  var match$3 = vnode.state.data;
                  var tmp$3;
                  if (typeof match$3 !== "object" || match$3.TAG !== "ErrorChangingThePassword") {
                    tmp$3 = Mithril.empty;
                  } else {
                    var errors$1 = match$3.errors.newPassword;
                    tmp$3 = errors$1 !== undefined ? showMultipleErrors(errors$1) : Mithril.empty;
                  }
                  var match$4 = vnode.state.data;
                  var tmp$4;
                  tmp$4 = typeof match$4 !== "object" || match$4.TAG !== "ErrorChangingThePassword" ? false : match$4.errors.newPassword !== undefined;
                  var match$5 = vnode.state.data;
                  var tmp$5;
                  if (typeof match$5 !== "object" || match$5.TAG !== "ErrorChangingThePassword") {
                    tmp$5 = Mithril.empty;
                  } else {
                    var errors$2 = match$5.errors.general;
                    tmp$5 = errors$2 !== undefined ? showMultipleErrors(errors$2) : Mithril.empty;
                  }
                  var match$6 = vnode.state.data;
                  var tmp$6;
                  tmp$6 = typeof match$6 !== "object" || match$6.TAG !== "Busy" ? false : true;
                  var match$7 = vnode.state.data;
                  var tmp$7;
                  tmp$7 = typeof match$7 !== "object" || match$7.TAG !== "Busy" ? false : true;
                  tmp = ReactDOM.jsx("form", {
                        children: ReactDOM.jsxs("fieldset", {
                              children: [
                                React.jsx(Conditional.make, {
                                      cond: user.has_valid_password,
                                      children: ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("label", {
                                                    children: "Old password",
                                                    className: "form-label",
                                                    htmlFor: "old-password"
                                                  }),
                                              ReactDOM.jsx("input", {
                                                    className: "form-input",
                                                    id: "old-password",
                                                    autoComplete: "off",
                                                    name: "old_password",
                                                    placeholder: "******",
                                                    required: true,
                                                    type: "password"
                                                  }),
                                              tmp$1
                                            ],
                                            className: Mithril.$$class([
                                                  [
                                                    "form-group",
                                                    true
                                                  ],
                                                  [
                                                    "has-error",
                                                    tmp$2
                                                  ]
                                                ], undefined)
                                          })
                                    }),
                                ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsx("label", {
                                              children: "New password",
                                              className: "form-label",
                                              htmlFor: "new-password"
                                            }),
                                        ReactDOM.jsx("input", {
                                              className: "form-input",
                                              id: "new-password",
                                              autoComplete: "off",
                                              name: "new_password",
                                              placeholder: "******",
                                              required: true,
                                              type: "password"
                                            }),
                                        tmp$3
                                      ],
                                      className: Mithril.$$class([
                                            [
                                              "form-group",
                                              true
                                            ],
                                            [
                                              "has-error",
                                              tmp$4
                                            ]
                                          ], undefined)
                                    }),
                                tmp$5,
                                ReactDOM.jsx("div", {
                                      className: "divider blank"
                                    }),
                                ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("button", {
                                            children: user.has_valid_password ? "Change password" : "Set Password",
                                            className: Mithril.$$class([
                                                  [
                                                    "btn btn-primary btn-block",
                                                    true
                                                  ],
                                                  [
                                                    "loading",
                                                    tmp$6
                                                  ]
                                                ], undefined),
                                            type: "submit"
                                          })
                                    })
                              ],
                              disabled: tmp$7
                            }),
                        onSubmit: (function (extra) {
                            var callback = vnode.attrs.successCallback;
                            extra.preventDefault();
                            changePassword(vnode, callback);
                          })
                      });
                }
                return ReactDOM.jsx("div", {
                            children: tmp
                          });
              }));
}

export {
  make ,
}
/* User Not a pure module */

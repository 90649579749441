// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as InitFeedback from "../InitFeedback.js";

Utils.MakeOpaqueIdentifier({});

function message(param, param$1) {
  return "Installing updates for company forms";
}

function $$process(companyForm, param) {
  return {
          TAG: "Update",
          _0: {
            id: companyForm.id,
            vessel_id: companyForm.vessel_id,
            name: companyForm.name,
            last_inspection_date: companyForm.last_inspection_date,
            related_procedures: companyForm.related_procedures,
            last_submit: undefined,
            requires_full_completion: false
          }
        };
}

var CompanyFormRewriter = InitFeedback.MakeRewriter({
      storeName: "companyForms",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing updates for company forms";
}

function migrate(_db, transaction) {
  return CompanyFormRewriter.rewrite(transaction, undefined);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

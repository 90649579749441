// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

var uploadTips = [
  "Take pictures from the same perspective every time, as shown in the Example Picture.",
  "There is no time limit to inspection uploads, go ahead!",
  "If you upload frequently, there is less risk of upload failure.",
  "Plan your inspection tour in the “Filter” tab.",
  "You can take multiple pictures of a finding using the “Add Detail Pictures” button.",
  "Always select your role in “Settings”, before starting an inspection tour.",
  "If a procedure is not applicable to your vessel, mark it as “Not OK” and comment “N/A”.",
  "You can read & write comments and communicate with the shore team in the “Findings” Tab.",
  "The app works fully offline, except uploading your inspection results.",
  "Rectify active findings directly in the “Findings” tab. Make sure to add a comment."
];

function cycle(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var tips = match.tips;
  var tipIndex = match.tipIndex + 1 | 0;
  var tipIndex$1 = tipIndex < tips.length ? tipIndex : 0;
  Mithril.setState(vnode, {
        TAG: "Cycling",
        tips: tips,
        tipIndex: tipIndex$1,
        intervalId: match.intervalId
      });
}

function UploadTips(props) {
  return Mithril.view(Mithril.onremove(Mithril.oninit(Mithril.component("Init"), (function (vnode) {
                        var match = vnode.state.data;
                        if (typeof match === "object") {
                          return ;
                        }
                        if (match !== "Init") {
                          return ;
                        }
                        var intervalId = setInterval((function (extra) {
                                return cycle(vnode, extra);
                              }), 30000);
                        var tips = Curry._1(Prelude.$$Array.shuffle, uploadTips);
                        Mithril.setState(vnode, {
                              TAG: "Cycling",
                              tips: tips,
                              tipIndex: 0,
                              intervalId: intervalId
                            });
                      })), (function (vnode) {
                    var match = vnode.state.data;
                    if (typeof match !== "object") {
                      if (match === "Init") {
                        vnode.state.data = "Done";
                        return ;
                      }
                      vnode.state.data = "Done";
                      return ;
                    } else {
                      clearInterval(match.intervalId);
                      vnode.state.data = "Done";
                      return ;
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var tip = match.tips[match.tipIndex];
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: React.jsx(Feather.make, {
                                          icon: "bulb",
                                          size: 45
                                        }),
                                    className: "empty-icon"
                                  }),
                              ReactDOM.jsx("p", {
                                    children: tip,
                                    className: "empty-title h5"
                                  })
                            ],
                            className: "empty"
                          });
              }));
}

var make = UploadTips;

export {
  cycle ,
  make ,
}
/* React Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as $$Permissions from "./Permissions.js";

var canRequestPermission = ((typeof DeviceMotionEvent != 'undefined') && (typeof DeviceMotionEvent.requestPermission === 'function'));

var include = $$Permissions.MakePermissionListener({
      canRequestPermission: canRequestPermission,
      requestPermission: (function (prim) {
          return DeviceMotionEvent.requestPermission();
        }),
      eventName: "devicemotion"
    });

function acceleration($$event) {
  var match = $$event.acceleration;
  return {
          x: Caml_option.nullable_to_opt(match.x),
          y: Caml_option.nullable_to_opt(match.y),
          z: Caml_option.nullable_to_opt(match.z)
        };
}

function accelerationIncludingGravity($$event) {
  var match = $$event.accelerationIncludingGravity;
  return {
          x: Caml_option.nullable_to_opt(match.x),
          y: Caml_option.nullable_to_opt(match.y),
          z: Caml_option.nullable_to_opt(match.z)
        };
}

var canRequestPermission$1 = include.canRequestPermission;

var requestPermission = include.requestPermission;

var addListener = include.addListener;

var removeListener = include.removeListener;

export {
  canRequestPermission$1 as canRequestPermission,
  requestPermission ,
  addListener ,
  removeListener ,
  acceleration ,
  accelerationIncludingGravity ,
}
/* include Not a pure module */

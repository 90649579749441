// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as LevelId from "./LevelId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as LevelSubmissionId from "./LevelSubmissionId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofLevel(levelId) {
  return {
          TAG: "Is",
          _0: "level_id",
          _1: levelId
        };
}

function byTargetDate(submission) {
  return submission.target_date;
}

function lastSubmissionByLevelId(submissions) {
  return function (id) {
    return LevelId.$$Map.get(Curry._3(Prelude.$$Array.fold, Prelude.$$Array.sort(submissions, byTargetDate, true), LevelId.$$Map.make(), (function (levelIdToSubmission, submission) {
                      if (Curry._1(Prelude.OptionExported.$$Option.isNone, LevelId.$$Map.get(levelIdToSubmission, submission.level_id))) {
                        LevelId.$$Map.set(levelIdToSubmission, submission.level_id, submission);
                      }
                      return levelIdToSubmission;
                    })), id);
  };
}

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "timestamp",
      "Date"
    ],
    [
      "target_date",
      "Date"
    ],
    [
      "answers",
      {
        TAG: "Array",
        _0: "String"
      }
    ]
  ]
};

var include$1 = $$JSON.MakeDeserializer({
      fields: fields
    });

var Deserializer = include$1;

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  ofLevel ,
  byTargetDate ,
  lastSubmissionByLevelId ,
  Deserializer ,
}
/* include Not a pure module */

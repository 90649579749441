// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "./rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "./rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function onTouchStart(vnode, $$event) {
  var enabled = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.enabled, (function (v) {
              return v;
            })), true);
  if (enabled) {
    vnode.state.data = {
      touchStartX: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, $$event.touches.item(0), (function (touch) {
                  return touch.pageX;
                })), 0.0),
      xAxis: 0.0,
      style: undefined
    };
    return ;
  }
  
}

function onTouchMove(vnode, $$event) {
  var enabled = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.enabled, (function (v) {
              return v;
            })), true);
  if (!enabled) {
    return ;
  }
  var xAxis = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, $$event.touches.item(0), (function (touch) {
              return touch.pageX;
            })), 0.0);
  var touchStartX = vnode.state.data.touchStartX | 0;
  var touchDeltaX = (xAxis | 0) - touchStartX | 0;
  var style = (xAxis | 0) < touchStartX && touchDeltaX < -10 ? Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.onSwipeLeft, (function (param) {
            return {
                    marginLeft: String(touchDeltaX) + "px"
                  };
          })) : (
      (xAxis | 0) > touchStartX && touchDeltaX > 10 ? Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.onSwipeRight, (function (param) {
                return {
                        marginRight: "-" + String(touchDeltaX) + "px",
                        marginLeft: String(touchDeltaX) + "px"
                      };
              })) : undefined
    );
  var init = vnode.state.data;
  vnode.state.data = {
    touchStartX: init.touchStartX,
    xAxis: xAxis,
    style: style
  };
}

function onTouchEnd(vnode, $$event) {
  var enabled = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vnode.attrs.enabled, (function (v) {
              return v;
            })), true);
  if (!enabled) {
    return ;
  }
  var touchEndX = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, $$event.changedTouches.item(0), (function (touch) {
              return touch.pageX | 0;
            })), 0);
  var touchStartX = vnode.state.data.touchStartX | 0;
  var touchDeltaX = touchEndX - touchStartX | 0;
  var init = vnode.state.data;
  vnode.state.data = {
    touchStartX: init.touchStartX,
    xAxis: 0.0,
    style: undefined
  };
  if (touchDeltaX < -200) {
    var onSwipeLeft = Prelude.default(vnode.attrs.onSwipeLeft, (function (param) {
            
          }));
    return onSwipeLeft($$event);
  }
  if (touchDeltaX <= 200) {
    return ;
  }
  var onSwipeRight = Prelude.default(vnode.attrs.onSwipeRight, (function (param) {
          
        }));
  onSwipeRight($$event);
}

function make(param) {
  return Mithril.view(Mithril.component({
                  touchStartX: 0.0,
                  xAxis: 0.0,
                  style: undefined
                }), (function (vnode) {
                var hasContent = Curry._2(Prelude.$$Array.some, vnode.children, (function (prim) {
                        return Boolean(prim);
                      }));
                var className = Prelude.default(vnode.attrs.className, "");
                var style = vnode.state.data.style;
                var match = vnode.attrs.onSwipeRight;
                var match$1 = vnode.attrs.leftContent;
                var match$2 = vnode.attrs.onSwipeLeft;
                var match$3 = vnode.attrs.rightContent;
                return ReactDOM.jsxs("div", {
                            children: [
                              match !== undefined && match$1 !== undefined ? ReactDOM.jsx("span", {
                                      children: Caml_option.valFromOption(match$1),
                                      className: "gesture-left-content"
                                    }) : Mithril.empty,
                              match$2 !== undefined && match$3 !== undefined ? ReactDOM.jsx("span", {
                                      children: Caml_option.valFromOption(match$3),
                                      className: "gesture-right-content"
                                    }) : Mithril.empty,
                              ReactDOM.jsx("div", {
                                    children: hasContent ? vnode.children : Mithril.empty,
                                    className: "gesture-item " + className,
                                    style: style,
                                    onTouchEnd: (function (extra) {
                                        return onTouchEnd(vnode, extra);
                                      }),
                                    onTouchMove: (function (extra) {
                                        return onTouchMove(vnode, extra);
                                      }),
                                    onTouchStart: (function (extra) {
                                        return onTouchStart(vnode, extra);
                                      })
                                  })
                            ],
                            className: "gesture-container p-relative"
                          });
              }));
}

export {
  onTouchStart ,
  onTouchMove ,
  onTouchEnd ,
  make ,
}
/* Mithril Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function map(x, fn) {
  if (x.TAG === "Cloud") {
    return {
            TAG: "Cloud",
            _0: fn(x._0)
          };
  } else {
    return {
            TAG: "Device",
            _0: fn(x._0)
          };
  }
}

function strip(x) {
  return x._0;
}

function cloud(x) {
  if (x.TAG === "Cloud") {
    return Caml_option.some(x._0);
  }
  
}

function device(x) {
  if (x.TAG === "Cloud") {
    return ;
  } else {
    return Caml_option.some(x._0);
  }
}

var Origin = {
  map: map,
  strip: strip,
  cloud: cloud,
  device: device
};

function map$1(x, fn) {
  return Belt_Option.map(x, (function (x) {
                return map(x, fn);
              }));
}

function strip$1(x) {
  return Belt_Option.map(x, strip);
}

function flatMap(x, fn) {
  return Belt_Option.flatMap(x, (function (x) {
                if (x.TAG === "Cloud") {
                  return Belt_Option.map(fn(x._0), (function (x) {
                                return {
                                        TAG: "Cloud",
                                        _0: x
                                      };
                              }));
                } else {
                  return Belt_Option.map(fn(x._0), (function (x) {
                                return {
                                        TAG: "Device",
                                        _0: x
                                      };
                              }));
                }
              }));
}

var Optional = {
  map: map$1,
  strip: strip$1,
  flatMap: flatMap
};

export {
  Origin ,
  map ,
  strip ,
  cloud ,
  device ,
  Optional ,
}
/* No side effect */

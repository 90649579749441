// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as IDB__Request from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/IDB__Request.js";

function make(param) {
  return function (_db, transaction) {
    var readings = transaction.objectStore("newReadings");
    return Prelude.thenResolve(IDB__Request.promise(readings.clear()), (function (param) {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                }));
  };
}

export {
  make ,
}
/* No side effect */

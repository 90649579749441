// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../../Settings.js";
import * as AppSchema from "../../AppSchema.js";
import * as WebNotification from "../../../libs/WebNotification.js";
import * as NotificationEntry from "../../notifications/NotificationEntry.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";
import FaviconPng from "/images/favicon.png";
import * as NotificationEntryAPI from "../endpoints/NotificationEntryAPI.js";
import * as ReadNotificationEntry from "../../notifications/ReadNotificationEntry.js";

function convertNotificationEntry(state, notification) {
  var converted_id = notification.id;
  var converted_vessel_id = notification.vessel_id;
  var converted_kind = notification.kind;
  var converted_user = notification.user;
  var converted_read = notification.read;
  var converted_subject = notification.subject;
  var converted_timestamp = notification.timestamp;
  var converted_metadata = notification.metadata;
  var converted = {
    id: converted_id,
    vessel_id: converted_vessel_id,
    kind: converted_kind,
    user: converted_user,
    read: converted_read,
    subject: converted_subject,
    timestamp: converted_timestamp,
    metadata: converted_metadata
  };
  AppSchema.NotificationEntries.update(state, converted);
  return state;
}

function convertNotificationEntries(state, notificationEntries) {
  return Curry._3(Prelude.$$Array.fold, notificationEntries, state, convertNotificationEntry);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(NotificationEntryAPI.getAll(undefined, {
                  vessel__id__in: vessels.join(",")
                }, undefined, user), (function (notificationEntries) {
                var $$final$1 = convertNotificationEntries($$final, notificationEntries);
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final$1
                      };
              }));
}

var ReadServerState = {
  convertNotificationEntry: convertNotificationEntry,
  convertNotificationEntries: convertNotificationEntries,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.notificationEntries = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.notificationEntries, (function (extra) {
                        return AppSchema.NotificationEntries.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.notificationEntries = AppSchema.NotificationEntries.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncNotificationEntries" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var _map = {"granted":"granted","denied":"denied","default":"default"};

function permissionToJs(param) {
  return param;
}

function permissionFromJs(param) {
  return _map[param];
}

function markAsReadAndOpen(entry, url) {
  var readEntry = ReadNotificationEntry.make(entry);
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  Prelude.thenDo(Query.write((newrecord.readNotificationEntries = [{
                TAG: "Save",
                _0: readEntry
              }], newrecord.notificationEntries = [{
                TAG: "Save",
                _0: {
                  id: entry.id,
                  vessel_id: entry.vessel_id,
                  kind: entry.kind,
                  user: entry.user,
                  read: true,
                  subject: entry.subject,
                  timestamp: entry.timestamp,
                  metadata: entry.metadata
                }
              }], newrecord)), (function (param) {
          Mithril.route.set(url);
        }));
}

function $$do$3(param) {
  var vessels = param.vessels;
  var user = param.user;
  var match = Settings.useNotifications && WebNotification.hasDesktopNotification;
  var match$1 = WebNotification.getPermission();
  if (match && match$1 === "granted") {
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    Prelude.thenDo(Prelude.PromisedResult.map(Query.Safe.read((newrecord.notificationEntries = {
                    TAG: "And",
                    _0: NotificationEntry.ofUser(user.id),
                    _1: {
                      TAG: "AnyOf",
                      _0: "vessel_id",
                      _1: vessels
                    }
                  }, newrecord)), (function (param) {
                var unread = Curry._2(Prelude.$$Array.keep, param.notificationEntries, (function (n) {
                        return n.read === false;
                      }));
                var icon = FaviconPng;
                Curry._2(Prelude.$$Array.forEach, unread, (function (entry) {
                        var body = Prelude.default(Curry._2(Prelude.Dict.get, entry.metadata, "text"), "");
                        var tag = entry.id;
                        var notification = WebNotification.makeWithOptions(entry.subject, {
                              body: body,
                              tag: tag,
                              icon: icon
                            });
                        WebNotification.addEventListener(notification, "click", (function (param) {
                                var deficiencyId = Curry._2(Prelude.Dict.get, entry.metadata, "comment_object_id");
                                if (deficiencyId !== undefined) {
                                  return markAsReadAndOpen(entry, URLs.deficiency(deficiencyId));
                                }
                                
                              }));
                      }));
              })), (function (param) {
            
          }));
  }
  return Promise.resolve({
              TAG: "Ok",
              _0: {
                user: user,
                vessels: vessels,
                initial: param.initial,
                final: param.final
              }
            });
}

var PushNotifications = {
  markAsReadAndOpen: markAsReadAndOpen,
  $$do: $$do$3
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var steps = [
  [
    "Save notifications into local DB",
    $$do$2
  ],
  [
    "Push notifications",
    $$do$3
  ]
];

var UpdateDB$1 = ServerSyncPipeline.MakeChainPipeline({
      steps: steps
    });

var views = [AppSchema.NotificationEntries];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncNotificationEntries",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB$1,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$4 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  permissionToJs ,
  permissionFromJs ,
  PushNotifications ,
  $$do$4 as $$do,
}
/* include Not a pure module */

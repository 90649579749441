// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Configure SIRE 2.0 Vetting Periods";
}

function $$process(before) {
  return {
          TAG: "Update",
          _0: {
            id: before.id,
            vessel_id: before.vessel_id,
            question_set: "all"
          }
        };
}

var AddSire2VettingPeriodQuestionSet = InitFeedback.MakeSimpleRewriter({
      storeName: "sire2VettingPeriods",
      message: message,
      $$process: $$process
    });

function message$1(param) {
  return "Configure SIRE 2.0 Vetting Periods";
}

function migrate(_db, transaction) {
  return AddSire2VettingPeriodQuestionSet.rewrite(transaction);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* AddSire2VettingPeriodQuestionSet Not a pure module */

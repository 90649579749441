// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";

function reason(errors) {
  var offline = errors.find(function (e) {
        if (typeof e !== "object" && e === "Offline") {
          return true;
        } else {
          return false;
        }
      });
  var server = errors.find(function (e) {
        if (typeof e !== "object" || e.TAG !== "ServerError") {
          return false;
        } else {
          return true;
        }
      });
  var data = errors.find(function (e) {
        if (typeof e !== "object" || e.TAG !== "BadRequest") {
          return false;
        } else {
          return true;
        }
      });
  var error = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(Prelude.$$Array.keepSome([
                    data,
                    offline,
                    server
                  ])), (function (e) {
              if (typeof e !== "object") {
                if (e === "Offline") {
                  return "offline";
                } else {
                  return "unknown";
                }
              } else if (e.TAG === "ServerError") {
                return "server";
              } else {
                return "request";
              }
            })), "unknown");
  if (error === "offline") {
    return ReactDOM.jsxs("div", {
                children: [
                  ReactDOM.jsx("h3", {
                        children: "Network Unavailable"
                      }),
                  ReactDOM.jsx("div", {
                        className: "divider blank"
                      }),
                  ReactDOM.jsx("p", {
                        children: "It seems you're not connected to the internet. Please check your\n        network settings and try again."
                      })
                ]
              });
  } else if (error === "unknown") {
    return ReactDOM.jsxs("div", {
                children: [
                  ReactDOM.jsx("h3", {
                        children: "Upload error"
                      }),
                  ReactDOM.jsx("div", {
                        className: "divider blank"
                      }),
                  ReactDOM.jsx("p", {
                        children: "We could not finish the upload. Maybe your internet connection\n        is currently not stable. Please, try to upload the inspections again\n        and try to improve your signal."
                      }),
                  ReactDOM.jsx("p", {
                        children: "Please make sure to have the latest version of the application\n        before retrying the upload."
                      }),
                  ReactDOM.jsx("p", {
                        children: "You can check the version by clicking the little gear near the\n        top-right of the screen. If you have the latest application version,\n        please contact Kaiko Support (support@kaikosystems.com) if the upload\n        issue persists."
                      })
                ]
              });
  } else if (error === "server") {
    return ReactDOM.jsxs("div", {
                children: [
                  ReactDOM.jsx("h3", {
                        children: "Server Error"
                      }),
                  ReactDOM.jsx("div", {
                        className: "divider blank"
                      }),
                  ReactDOM.jsx("p", {
                        children: "There was an error in the server while performing the upload.\n        Our team is automatically notified and we're working on it. Please wait\n        about an hour before retrying the upload."
                      })
                ]
              });
  } else {
    return ReactDOM.jsxs("div", {
                children: [
                  ReactDOM.jsx("h3", {
                        children: "Data error"
                      }),
                  ReactDOM.jsx("div", {
                        className: "divider blank"
                      }),
                  ReactDOM.jsx("p", {
                        children: "Please make sure you are connected to the internet and restart\n        the Kaiko Application to update to the latest version before retrying\n        the upload."
                      }),
                  ReactDOM.jsx("p", {
                        children: "Please contact Kaiko Support (support@kaikosystems.com) if the\n        upload issue persists."
                      })
                ]
              });
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onClickRetry = match.onClickRetry;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h3", {
                                            children: "Error"
                                          }),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank"
                                          }),
                                      reason(vnode.attrs.errors),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank"
                                          })
                                    ],
                                    className: "toast toast-error text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsx("button", {
                                          children: "Go back",
                                          className: "btn btn-link p-centered",
                                          onClick: (function (param) {
                                              onClickRetry();
                                            })
                                        })
                                  })
                            ]
                          });
              }));
}

export {
  reason ,
  make ,
}
/* Mithril Not a pure module */

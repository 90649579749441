// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Freq from "./Freq.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";

function MakeMutexQueue(T) {
  var Queue = ActionQueue.MakeActionQueue(T);
  var queue = Queue.make(true, 1, false);
  var $$do = function (arg) {
    if (Queue.busy(queue)) {
      return Queue.promise(queue);
    } else {
      return Queue.replace(queue, (function () {
                    return T.action(arg);
                  }), undefined);
    }
  };
  return {
          name: T.name,
          action: T.action,
          $$do: $$do
        };
}

function MakeThrottle(T) {
  var name = T.name;
  var last = {
    contents: undefined
  };
  var period = Freq.period(T.rate) - 5.0;
  var period$1 = period < 10.0 ? 10.0 : period;
  var action = function (arg) {
    var time = last.contents;
    var result;
    if (time !== undefined) {
      var now = window.performance.now();
      var ellapsed = now - time;
      var interval = period$1 - ellapsed | 0;
      result = interval > 0 ? Prelude.then(Prelude.Promises.ellapsed(interval), (function () {
                return T.action(arg);
              })) : T.action(arg);
    } else {
      result = T.action(arg);
    }
    return result.finally(function () {
                last.contents = window.performance.now();
              });
  };
  var Queue = ActionQueue.MakeActionQueue({
        name: name
      });
  var queue = Queue.make(true, 1, false);
  var $$do = function (arg) {
    if (Queue.busy(queue)) {
      return Queue.promise(queue);
    } else {
      return Queue.replace(queue, (function () {
                    return action(arg);
                  }), undefined);
    }
  };
  return {
          name: name,
          action: action,
          $$do: $$do
        };
}

export {
  MakeMutexQueue ,
  MakeThrottle ,
}
/* ActionQueue Not a pure module */

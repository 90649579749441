// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var _map = {"Always":"Always","Never":"Never","Randomly":"Randomly","WhenBadlyRated":"WhenBadlyRated","WhenRectifyingFinding":"WhenRectifyingFinding"};

function conditionToJs(param) {
  return param;
}

function conditionFromJs(param) {
  return _map[param];
}

var $$Set = Utils.MakeStringTypeSet({});

function fromArray(conditions) {
  return $$Set.toArray($$Set.fromArray(conditions));
}

function toArray(prim) {
  return prim;
}

var always = ["Always"];

var question = [
  "Randomly",
  "WhenBadlyRated",
  "WhenRectifyingFinding"
];

var never = ["Never"];

function $$default(question_type) {
  if (question_type === "PhotoAlbumQuestion" || question_type === "RatingQuestion") {
    return always;
  } else if (question_type === "OkNotOkQuestion") {
    return question;
  } else {
    return never;
  }
}

var Defaults = {
  always: always,
  question: question,
  never: never,
  $$default: $$default
};

var _map$1 = Curry._1(Prelude.Dict.fromArray, [
      [
        "Always",
        "Always"
      ],
      [
        "Never",
        "Never"
      ],
      [
        "Randomly",
        "Randomly"
      ],
      [
        "WhenBadlyRated",
        "WhenBadlyRated"
      ],
      [
        "WhenRectifyingFinding",
        "WhenRectifyingFinding"
      ]
    ]);

var fields = {
  TAG: "Optional",
  _0: {
    TAG: "Array",
    _0: $$JSON.Field.variadicString("Expected condition: Always, Never, Randomly, WhenBadlyRated, or WhenRectifyingFinding", conditionFromJs)
  }
};

var Impl = $$JSON.MakeDeserializer({
      fields: fields
    });

function fromJSON(data) {
  return Curry._2(Prelude.Result.flatMap, Impl.fromJSON(data), (function (x) {
                if (x !== undefined && x.length !== 0) {
                  return {
                          TAG: "Ok",
                          _0: Caml_option.some(fromArray(x))
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                }
              }));
}

var Deserializer_name = Impl.name;

var Deserializer_checkFieldsSanity = Impl.checkFieldsSanity;

var Deserializer = {
  Impl: Impl,
  name: Deserializer_name,
  fromJSON: fromJSON,
  checkFieldsSanity: Deserializer_checkFieldsSanity
};

export {
  conditionToJs ,
  conditionFromJs ,
  $$Set ,
  fromArray ,
  toArray ,
  Defaults ,
  _map$1 as _map,
  Deserializer ,
}
/* Set Not a pure module */

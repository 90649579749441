// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "./URL.js";
import * as React from "./rescript-mithril/src/React.js";
import * as UUID7 from "./UUID7.js";
import * as Mithril from "./rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "./rescript-mithril/src/ReactDOM.js";
import * as LocalFile from "./LocalFile.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function toggleFullscreen(id, $$event) {
  var element = document.getElementById(id);
  if (element == null) {
    return ;
  }
  var x = $$event.clientX;
  var width = element.scrollWidth;
  var proportion = Caml_int32.div(Math.imul(x, 100), element.scrollWidth);
  element.classList.toggle("fullscreen-img");
  setTimeout((function () {
          element.scrollTo((Math.imul(proportion, element.scrollWidth) / 100 | 0) - (width / 2 | 0) | 0, 0.0);
        }), 50);
}

function make(param) {
  var load = async function (vnode) {
    var delayRetry = Prelude.default(vnode.attrs.delayRetry, 200);
    var loadedId = vnode.state.data;
    if (typeof loadedId !== "object") {
      var retries = Prelude.default(vnode.attrs.maxRetries, 10);
      var match = vnode.attrs;
      return Mithril.setState(vnode, {
                  TAG: "Loading",
                  _0: match.resource,
                  _1: retries
                });
    }
    switch (loadedId.TAG) {
      case "Loading" :
          var file = loadedId._0;
          if (file.TAG === "Resource") {
            var retries$1 = loadedId._1;
            var id = file._0;
            var resource = await LocalFile.getResource(id);
            if (resource !== undefined) {
              return Mithril.setState(vnode, {
                          TAG: "Loaded",
                          _0: {
                            TAG: "Resource",
                            _0: id
                          },
                          _1: URL.createObjectURL(resource.file)
                        });
            } else if (retries$1 > 0) {
              await Prelude.Promises.ellapsed(delayRetry);
              return Mithril.setState(vnode, {
                          TAG: "Loading",
                          _0: {
                            TAG: "Resource",
                            _0: id
                          },
                          _1: retries$1 - 1 | 0
                        });
            } else {
              return Mithril.setState(vnode, {
                          TAG: "ResourceNotFound",
                          _0: id
                        });
            }
          }
          var file$1 = file._0;
          return Mithril.setState(vnode, {
                      TAG: "Loaded",
                      _0: {
                        TAG: "Blob",
                        _0: file$1
                      },
                      _1: URL.createObjectURL(file$1)
                    });
      case "ResourceNotFound" :
          var match$1 = vnode.attrs;
          var resource$1 = match$1.resource;
          if (resource$1.TAG === "Resource" && !Caml_obj.notequal(loadedId._0, resource$1._0)) {
            return ;
          } else {
            return Mithril.setState(vnode, "Init");
          }
      case "Loaded" :
          var loadedResource = loadedId._0;
          var match$2 = vnode.attrs;
          var resource$2 = match$2.resource;
          var stale;
          stale = resource$2.TAG === "Resource" ? (
              loadedResource.TAG === "Resource" ? Caml_obj.notequal(resource$2._0, loadedResource._0) : true
            ) : (
              loadedResource.TAG === "Resource" ? true : Caml_obj.notequal(resource$2._0, loadedResource._0)
            );
          if (stale) {
            var dataURL = loadedId._1;
            console.log("LocalImage", "Recycling local image");
            $$URL.revokeObjectURL(dataURL);
            return Mithril.setState(vnode, "Init");
          } else {
            return ;
          }
      
    }
  };
  return Mithril.view(Mithril.onremove(Mithril.onupdateAsync(Mithril.oninitAsync(Mithril.component("Init"), load), load), (function (vnode) {
                    var match = vnode.state.data;
                    if (typeof match !== "object" || match.TAG !== "Loaded") {
                      return ;
                    } else {
                      return $$URL.revokeObjectURL(match._1);
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return vnode.children;
                }
                if (match.TAG !== "Loaded") {
                  return vnode.children;
                }
                var src = match._1;
                var maximize = Prelude.default(vnode.attrs.maximize, false);
                var className = Prelude.default(vnode.attrs.className, "img-responsive img-fit-contain p-centered");
                var style = vnode.attrs.style;
                var id = Prelude.default(vnode.attrs.id, UUID7.make());
                if (maximize) {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("img", {
                                    className: className + " stretchable",
                                    style: style,
                                    src: src
                                  }),
                              className: "bg-white",
                              id: id,
                              onClick: (function (extra) {
                                  return toggleFullscreen(id, extra);
                                })
                            });
                } else {
                  return ReactDOM.jsx("img", {
                              className: className,
                              style: style,
                              src: src
                            });
                }
              }));
}

var Core = {
  toggleFullscreen: toggleFullscreen,
  make: make
};

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                return React.jsx(make, {
                            resource: {
                              TAG: "Resource",
                              _0: vnode.attrs.resource
                            },
                            id: vnode.attrs.id,
                            maxRetries: vnode.attrs.maxRetries,
                            delayRetry: vnode.attrs.delayRetry,
                            className: vnode.attrs.className,
                            style: vnode.attrs.style,
                            children: Caml_option.some(vnode.children)
                          });
              }));
}

var Resource = {
  make: make$1
};

function make$2(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                return React.jsx(make, {
                            resource: {
                              TAG: "Blob",
                              _0: vnode.attrs.blob
                            },
                            id: vnode.attrs.id,
                            maxRetries: vnode.attrs.maxRetries,
                            delayRetry: vnode.attrs.delayRetry,
                            className: vnode.attrs.className,
                            style: vnode.attrs.style,
                            maximize: vnode.attrs.maximize,
                            children: Caml_option.some(vnode.children)
                          });
              }));
}

var $$Blob = {
  make: make$2
};

export {
  Core ,
  toggleFullscreen ,
  make ,
  Resource ,
  $$Blob ,
}
/* URL Not a pure module */

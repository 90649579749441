// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../Level.js";
import * as Query from "../../../Query.js";
import * as Answer from "../../Answer.js";
import * as Vessel from "../../../vessel/Vessel.js";
import * as LevelId from "../../LevelId.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../Question.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../../vessel/VesselId.js";
import * as QuestionId from "../../QuestionId.js";
import * as State__Answers from "../../../state/State__Answers.js";
import * as FutureAttachmentManager from "../../../attachment_chunks/FutureAttachmentManager.js";

function loadState(param) {
  var questionId = QuestionId.Route.param("questionId");
  var vesselId = VesselId.Route.param("vesselId");
  var levelId = LevelId.Route.param("levelId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.newAnswers = {
              TAG: "And",
              _0: Answer.ofQuestion(questionId),
              _1: Answer.ofLevel(levelId)
            }, newrecord.questions = {
              TAG: "Get",
              _0: questionId
            }, newrecord.levels = {
              TAG: "Get",
              _0: levelId
            }, newrecord.vessels = {
              TAG: "Get",
              _0: vesselId
            }, newrecord)), (function (param) {
          var level = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(param.levels), Level.Defaults.$$null);
          var vessel = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(param.vessels), Vessel.Defaults.$$null);
          var question = Prelude.default(Prelude.$$Array.first(param.questions), Question.Defaults.$$null);
          var lastNewAnswer = Prelude.$$Array.last(Prelude.$$Array.sort(param.newAnswers, (function (a) {
                      return a.timestamp;
                    }), undefined));
          var value = "NotApplicable";
          var tmp;
          if (lastNewAnswer !== undefined) {
            var files = lastNewAnswer.evidences.map(function (param) {
                  return param.id;
                });
            var newrecord = Caml_obj.obj_dup(lastNewAnswer);
            tmp = Prelude.then(State__Answers.update((newrecord.evidences = [], newrecord.remarks = "Not Applicable", newrecord.timestamp = new Date(), newrecord.value = value, newrecord)), (function (param) {
                    return FutureAttachmentManager.$$delete(files);
                  }));
          } else {
            tmp = Prelude.thenResolve(State__Answers.make(vessel.id, level.id, level.path, question.id, question.kind, value, "Not Applicable", [], false), (function (param) {
                    
                  }));
          }
          Prelude.thenDo(tmp, (function () {
                  window.history.back();
                }));
        }));
}

function NotApplicableWidget(props) {
  return Mithril.view(Mithril.oninit(Mithril.component(), loadState), (function (param) {
                return ReactDOM.jsx("h1", {
                            children: "Not applicable"
                          });
              }));
}

var make = NotApplicableWidget;

export {
  make ,
}
/* Level Not a pure module */

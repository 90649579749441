// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function MakeSimpleQueueManager(T) {
  var Impl = ActionQueue.MakeActionQueue({
        name: T.name
      });
  var clear = Impl.clear;
  var concurrency = 1 <= T.concurrency && T.concurrency < 50 ? T.concurrency : 10;
  var $$new = function () {
    return Impl.make(true, concurrency, undefined);
  };
  var append = function (q, action, id) {
    return Impl.append(q, action, id);
  };
  var prepend = function (q, action, id) {
    return Impl.prepend(q, action, id);
  };
  var replace = function (q, action, id) {
    return Impl.replace(q, action, id);
  };
  var Queue_name = Impl.name;
  var Queue_make = Impl.make;
  var Queue_busy = Impl.busy;
  var Queue_length = Impl.length;
  var Queue_running = Impl.running;
  var Queue_pause = Impl.pause;
  var Queue_resume = Impl.resume;
  var Queue_paused = Impl.paused;
  var Queue_promise = Impl.promise;
  var Queue_info = Impl.info;
  var Queue_then = Impl.then;
  var Queue_catch = Impl.$$catch;
  var Queue_finally = Impl.$$finally;
  var Queue_oncancel = Impl.oncancel;
  var Queue = {
    Impl: Impl,
    name: Queue_name,
    make: Queue_make,
    busy: Queue_busy,
    length: Queue_length,
    running: Queue_running,
    pause: Queue_pause,
    resume: Queue_resume,
    paused: Queue_paused,
    clear: clear,
    promise: Queue_promise,
    info: Queue_info,
    then: Queue_then,
    $$catch: Queue_catch,
    $$finally: Queue_finally,
    oncancel: Queue_oncancel,
    $$new: $$new,
    append: append,
    prepend: prepend,
    replace: replace
  };
  var queue = $$new();
  var ActionError = /* @__PURE__ */Caml_exceptions.create("QueueManager.MakeSimpleQueueManager(T).ActionError");
  var append$1 = function (extra, extra$1) {
    return append(queue, extra, extra$1);
  };
  var prepend$1 = function (extra, extra$1) {
    return prepend(queue, extra, extra$1);
  };
  var clear$1 = function () {
    clear(queue);
  };
  var replace$1 = function (extra, extra$1) {
    return replace(queue, extra, extra$1);
  };
  return {
          Queue: Queue,
          ActionError: ActionError,
          append: append$1,
          prepend: prepend$1,
          clear: clear$1,
          replace: replace$1
        };
}

export {
  MakeSimpleQueueManager ,
}
/* ActionQueue Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Freq from "../../libs/Freq.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Mutex from "../../libs/Mutex.js";
import * as Query from "../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../Settings.js";
import * as UserAgent from "./UserAgent.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as $$Permissions from "../../libs/Permissions.js";
import * as LimitedArray from "../../libs/LimitedArray.js";
import * as DeviceMotionEvent from "../../libs/DeviceMotionEvent.js";
import * as StepsCounterState from "./StepsCounterState.js";

function MakeAlgorithm(Params) {
  var state = {
    contents: {
      TAG: "NotRecording",
      user: undefined,
      subscribers: []
    }
  };
  var getUser = function () {
    return state.contents.user;
  };
  var getTimer = function () {
    var match = state.contents;
    switch (match.TAG) {
      case "Recording" :
          return Caml_option.some(match.timer);
      case "NotRecording" :
      case "Stopped" :
          return ;
      
    }
  };
  var include = $$Permissions.MakeRejectedReportEvent({
        getUser: getUser,
        addListener: DeviceMotionEvent.addListener,
        rejectionMessage: "Access to DeviceMotionEvent rejected"
      });
  var addListener = include.addListener;
  var penalizeOutliers = function (data) {
    return data.map(function (param) {
                var z = param[2];
                var y = param[1];
                var x = param[0];
                if (Math.abs(x) >= Params.maxG || Math.abs(y) >= Params.maxG || Math.abs(z) >= Params.maxG) {
                  return [
                          0.0,
                          0.0,
                          0.0
                        ];
                } else {
                  return [
                          x,
                          y,
                          z
                        ];
                }
              });
  };
  var lowpass = function (data, window_size) {
    var len = data.length;
    var result = Belt_Array.make(len, 0.0);
    for(var i = 0; i < len; ++i){
      var start = Caml.int_max(0, i - (window_size / 2 | 0) | 0);
      var end = Caml.int_min(len, (i + (window_size / 2 | 0) | 0) + 1 | 0);
      var l = end - start | 0;
      var slice = Curry._3(Prelude.$$Array.slice, data, start, l);
      var s = Curry._3(Prelude.$$Array.fold, slice, 0.0, (function (s, v) {
              return s + v;
            }));
      var value = s / l;
      Curry._3(Prelude.$$Array.set, result, i, value);
    }
    return result;
  };
  var detect = function (data, threshold, size, rate) {
    var filtered = lowpass(penalizeOutliers(data).map(function (param) {
              var z = param[2];
              var y = param[1];
              var x = param[0];
              return Math.sqrt(x * x + y * y + z * z);
            }), size);
    var indicators = filtered.map(function (v) {
          if (v > threshold) {
            return 1;
          } else {
            return 0;
          }
        });
    var chunks = Prelude.$$Array.chunks(indicators, rate);
    return Curry._2(Prelude.$$Array.keep, chunks, (function (chunk) {
                  var size = Curry._3(Prelude.$$Array.fold, chunk, 0, (function (s, v) {
                          return s + v | 0;
                        }));
                  return size >= Params.ratio * rate;
                })).length;
  };
  var action = async function (steps) {
    var newrecord = Caml_obj.obj_dup(Query.makeWrite());
    await Query.write((newrecord.stepsStates = StepsCounterState.update(steps), newrecord));
  };
  var StateUpdateThrottle = Mutex.MakeThrottle({
        rate: {
          NAME: "Hz",
          VAL: 1
        },
        name: "StepCounter",
        action: action
      });
  var action$1 = function (motion) {
    var match = state.contents;
    var tmp;
    switch (match.TAG) {
      case "Recording" :
          var subscribers = match.subscribers;
          var counted_cycles = match.counted_cycles;
          var counted_steps = match.counted_steps;
          var signal = match.signal;
          var match$1;
          if (LimitedArray.isFull(signal)) {
            var data = LimitedArray.toArray(signal);
            LimitedArray.clear(signal);
            var steps = detect(data, Params.threshold, Params.windowSize, Freq.freq(Params.rate) | 0);
            StateUpdateThrottle.$$do(steps + counted_steps | 0);
            match$1 = [
              steps,
              1
            ];
          } else {
            match$1 = [
              0,
              0
            ];
          }
          var current_cycles = match$1[1];
          var current_steps = match$1[0];
          Curry._2(Prelude.$$Array.forEach, subscribers, (function (fn) {
                  fn({
                        motion: motion,
                        steps: current_steps,
                        cycles: counted_cycles + current_cycles | 0,
                        params: Params
                      });
                }));
          state.contents = {
            TAG: "Recording",
            user: match.user,
            timer: match.timer,
            motion: motion,
            motionHandler: match.motionHandler,
            ledger: LimitedArray.push(match.ledger, {
                  timestamp: window.performance.now(),
                  x: motion.x,
                  y: motion.y,
                  z: motion.z,
                  counted_steps: counted_steps + current_steps | 0,
                  counted_cycles: counted_cycles + current_cycles | 0
                }),
            signal: LimitedArray.push(signal, [
                  Prelude.default(motion.x, 0.0),
                  Prelude.default(motion.y, 0.0),
                  Prelude.default(motion.z, 0.0)
                ]),
            counted_steps: counted_steps + current_steps | 0,
            counted_cycles: counted_cycles + current_cycles | 0,
            subscribers: subscribers
          };
          tmp = undefined;
          break;
      case "NotRecording" :
      case "Stopped" :
          tmp = undefined;
          break;
      
    }
    return Promise.resolve(tmp);
  };
  var MotionThrottle = Mutex.MakeThrottle({
        rate: Params.rate,
        name: "Motion Tracker",
        action: action$1
      });
  var trackMotion = function ($$event) {
    Prelude.thenDo(MotionThrottle.$$do(DeviceMotionEvent.acceleration($$event)), (function () {
            
          }));
  };
  var stop = function () {
    var result = LimitedArray.make(1);
    Curry._2(Prelude.OptionExported.$$Option.map, getTimer(), (function (timer) {
            clearTimeout(timer);
          }));
    var x = state.contents;
    var tmp;
    switch (x.TAG) {
      case "Recording" :
          var ledger = x.ledger;
          console.log("StepCounter", "stopped");
          DeviceMotionEvent.removeListener(x.motionHandler);
          result = ledger;
          tmp = {
            TAG: "Stopped",
            user: x.user,
            ledger: ledger,
            subscribers: x.subscribers
          };
          break;
      case "NotRecording" :
      case "Stopped" :
          tmp = x;
          break;
      
    }
    state.contents = tmp;
    return result;
  };
  var setTimer = function () {
    Curry._2(Prelude.OptionExported.$$Option.map, getTimer(), (function (timer) {
            clearTimeout(timer);
          }));
    var ttl = Settings.movementTimeoutInMs / 60000 | 0;
    return setTimeout((function () {
                  console.log("Stopping after " + String(ttl) + " min of inactivity");
                  stop();
                }), Settings.movementTimeoutInMs);
  };
  var start = async function (user) {
    var match = state.contents;
    var subscribers;
    switch (match.TAG) {
      case "NotRecording" :
          subscribers = match.subscribers;
          break;
      case "Recording" :
          console.log("StepCounter", "tracking request ignored");
          return ;
      case "Stopped" :
          subscribers = match.subscribers;
          break;
      
    }
    console.log("StepCounter", "started");
    var permission = await addListener(trackMotion);
    if (permission !== "granted") {
      return ;
    }
    var newrecord = Caml_obj.obj_dup(Query.makeRead());
    var match$1 = await Query.read((newrecord.stepsStates = {
            TAG: "Get",
            _0: StepsCounterState.Identifier.zero
          }, newrecord));
    var timer = setTimer();
    state.contents = {
      TAG: "Recording",
      user: user,
      timer: timer,
      motion: {
        x: undefined,
        y: undefined,
        z: undefined
      },
      motionHandler: trackMotion,
      ledger: LimitedArray.make(10000),
      signal: LimitedArray.make(Params.signalSize),
      counted_steps: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match$1.stepsStates), (function (param) {
                  return param.current_steps;
                })), 0),
      counted_cycles: 0,
      subscribers: subscribers
    };
  };
  var steps = function () {
    var recording = state.contents;
    switch (recording.TAG) {
      case "Recording" :
          var counted_steps = recording.counted_steps;
          state.contents = {
            TAG: "Recording",
            user: recording.user,
            timer: recording.timer,
            motion: recording.motion,
            motionHandler: recording.motionHandler,
            ledger: recording.ledger,
            signal: recording.signal,
            counted_steps: 0,
            counted_cycles: recording.counted_cycles,
            subscribers: recording.subscribers
          };
          StateUpdateThrottle.$$do(0);
          return counted_steps;
      case "NotRecording" :
      case "Stopped" :
          break;
      
    }
    start(recording.user);
    return 0;
  };
  var addListener$1 = function (fn) {
    var match = state.contents;
    switch (match.TAG) {
      case "NotRecording" :
          match.subscribers.push(fn);
          return ;
      case "Recording" :
          match.subscribers.push(fn);
          return ;
      case "Stopped" :
          match.subscribers.push(fn);
          return ;
      
    }
  };
  var removeListener = function (fn) {
    var match = state.contents;
    var subscribers;
    switch (match.TAG) {
      case "NotRecording" :
          subscribers = match.subscribers;
          break;
      case "Recording" :
          subscribers = match.subscribers;
          break;
      case "Stopped" :
          subscribers = match.subscribers;
          break;
      
    }
    var pos = subscribers.indexOf(fn);
    if (pos >= 0) {
      subscribers.splice(pos, 1);
      return ;
    }
    
  };
  return {
          Params: Params,
          steps: steps,
          stop: stop,
          start: start,
          addListener: addListener$1,
          removeListener: removeListener
        };
}

var rate = {
  NAME: "Hz",
  VAL: 100
};

var GenericParameters = {
  maxG: 3.7,
  rate: rate,
  signalSize: 1000,
  windowSize: 25,
  threshold: 0.9,
  ratio: 0.75
};

var rate$1 = {
  NAME: "Hz",
  VAL: 200
};

var WindowsParameters = {
  maxG: 2.0,
  rate: rate$1,
  signalSize: 2000,
  windowSize: 100,
  threshold: 0.3,
  ratio: 0.2
};

var algo = {
  contents: undefined
};

function algorithm() {
  var algorithm$1 = algo.contents;
  var result;
  if (algorithm$1 !== undefined) {
    result = algorithm$1;
  } else {
    var match = UserAgent.getOS();
    if (match.name === "Windows") {
      var state = {
        contents: {
          TAG: "NotRecording",
          user: undefined,
          subscribers: []
        }
      };
      var getUser = function () {
        return state.contents.user;
      };
      var getTimer = function () {
        var match = state.contents;
        switch (match.TAG) {
          case "Recording" :
              return Caml_option.some(match.timer);
          case "NotRecording" :
          case "Stopped" :
              return ;
          
        }
      };
      var include = $$Permissions.MakeRejectedReportEvent({
            getUser: getUser,
            addListener: DeviceMotionEvent.addListener,
            rejectionMessage: "Access to DeviceMotionEvent rejected"
          });
      var addListener = include.addListener;
      var penalizeOutliers = function (data) {
        return data.map(function (param) {
                    var z = param[2];
                    var y = param[1];
                    var x = param[0];
                    if (Math.abs(x) >= 2.0 || Math.abs(y) >= 2.0 || Math.abs(z) >= 2.0) {
                      return [
                              0.0,
                              0.0,
                              0.0
                            ];
                    } else {
                      return [
                              x,
                              y,
                              z
                            ];
                    }
                  });
      };
      var lowpass = function (data, window_size) {
        var len = data.length;
        var result = Belt_Array.make(len, 0.0);
        for(var i = 0; i < len; ++i){
          var start = Caml.int_max(0, i - (window_size / 2 | 0) | 0);
          var end = Caml.int_min(len, (i + (window_size / 2 | 0) | 0) + 1 | 0);
          var l = end - start | 0;
          var slice = Curry._3(Prelude.$$Array.slice, data, start, l);
          var s = Curry._3(Prelude.$$Array.fold, slice, 0.0, (function (s, v) {
                  return s + v;
                }));
          var value = s / l;
          Curry._3(Prelude.$$Array.set, result, i, value);
        }
        return result;
      };
      var detect = function (data, threshold, size, rate) {
        var filtered = lowpass(penalizeOutliers(data).map(function (param) {
                  var z = param[2];
                  var y = param[1];
                  var x = param[0];
                  return Math.sqrt(x * x + y * y + z * z);
                }), size);
        var indicators = filtered.map(function (v) {
              if (v > threshold) {
                return 1;
              } else {
                return 0;
              }
            });
        var chunks = Prelude.$$Array.chunks(indicators, rate);
        return Curry._2(Prelude.$$Array.keep, chunks, (function (chunk) {
                      var size = Curry._3(Prelude.$$Array.fold, chunk, 0, (function (s, v) {
                              return s + v | 0;
                            }));
                      return size >= 0.2 * rate;
                    })).length;
      };
      var action = async function (steps) {
        var newrecord = Caml_obj.obj_dup(Query.makeWrite());
        await Query.write((newrecord.stepsStates = StepsCounterState.update(steps), newrecord));
      };
      var StateUpdateThrottle = Mutex.MakeThrottle({
            rate: {
              NAME: "Hz",
              VAL: 1
            },
            name: "StepCounter",
            action: action
          });
      var action$1 = function (motion) {
        var match = state.contents;
        var tmp;
        switch (match.TAG) {
          case "Recording" :
              var subscribers = match.subscribers;
              var counted_cycles = match.counted_cycles;
              var counted_steps = match.counted_steps;
              var signal = match.signal;
              var match$1;
              if (LimitedArray.isFull(signal)) {
                var data = LimitedArray.toArray(signal);
                LimitedArray.clear(signal);
                var steps = detect(data, 0.3, 100, Freq.freq(rate$1) | 0);
                StateUpdateThrottle.$$do(steps + counted_steps | 0);
                match$1 = [
                  steps,
                  1
                ];
              } else {
                match$1 = [
                  0,
                  0
                ];
              }
              var current_cycles = match$1[1];
              var current_steps = match$1[0];
              Curry._2(Prelude.$$Array.forEach, subscribers, (function (fn) {
                      fn({
                            motion: motion,
                            steps: current_steps,
                            cycles: counted_cycles + current_cycles | 0,
                            params: WindowsParameters
                          });
                    }));
              state.contents = {
                TAG: "Recording",
                user: match.user,
                timer: match.timer,
                motion: motion,
                motionHandler: match.motionHandler,
                ledger: LimitedArray.push(match.ledger, {
                      timestamp: window.performance.now(),
                      x: motion.x,
                      y: motion.y,
                      z: motion.z,
                      counted_steps: counted_steps + current_steps | 0,
                      counted_cycles: counted_cycles + current_cycles | 0
                    }),
                signal: LimitedArray.push(signal, [
                      Prelude.default(motion.x, 0.0),
                      Prelude.default(motion.y, 0.0),
                      Prelude.default(motion.z, 0.0)
                    ]),
                counted_steps: counted_steps + current_steps | 0,
                counted_cycles: counted_cycles + current_cycles | 0,
                subscribers: subscribers
              };
              tmp = undefined;
              break;
          case "NotRecording" :
          case "Stopped" :
              tmp = undefined;
              break;
          
        }
        return Promise.resolve(tmp);
      };
      var MotionThrottle = Mutex.MakeThrottle({
            rate: rate$1,
            name: "Motion Tracker",
            action: action$1
          });
      var trackMotion = function ($$event) {
        Prelude.thenDo(MotionThrottle.$$do(DeviceMotionEvent.acceleration($$event)), (function () {
                
              }));
      };
      var stop = function () {
        var result = LimitedArray.make(1);
        Curry._2(Prelude.OptionExported.$$Option.map, getTimer(), (function (timer) {
                clearTimeout(timer);
              }));
        var x = state.contents;
        var tmp;
        switch (x.TAG) {
          case "Recording" :
              var ledger = x.ledger;
              console.log("StepCounter", "stopped");
              DeviceMotionEvent.removeListener(x.motionHandler);
              result = ledger;
              tmp = {
                TAG: "Stopped",
                user: x.user,
                ledger: ledger,
                subscribers: x.subscribers
              };
              break;
          case "NotRecording" :
          case "Stopped" :
              tmp = x;
              break;
          
        }
        state.contents = tmp;
        return result;
      };
      var setTimer = function () {
        Curry._2(Prelude.OptionExported.$$Option.map, getTimer(), (function (timer) {
                clearTimeout(timer);
              }));
        var ttl = Settings.movementTimeoutInMs / 60000 | 0;
        return setTimeout((function () {
                      console.log("Stopping after " + String(ttl) + " min of inactivity");
                      stop();
                    }), Settings.movementTimeoutInMs);
      };
      var start = async function (user) {
        var match = state.contents;
        var subscribers;
        switch (match.TAG) {
          case "NotRecording" :
              subscribers = match.subscribers;
              break;
          case "Recording" :
              console.log("StepCounter", "tracking request ignored");
              return ;
          case "Stopped" :
              subscribers = match.subscribers;
              break;
          
        }
        console.log("StepCounter", "started");
        var permission = await addListener(trackMotion);
        if (permission !== "granted") {
          return ;
        }
        var newrecord = Caml_obj.obj_dup(Query.makeRead());
        var match$1 = await Query.read((newrecord.stepsStates = {
                TAG: "Get",
                _0: StepsCounterState.Identifier.zero
              }, newrecord));
        var timer = setTimer();
        state.contents = {
          TAG: "Recording",
          user: user,
          timer: timer,
          motion: {
            x: undefined,
            y: undefined,
            z: undefined
          },
          motionHandler: trackMotion,
          ledger: LimitedArray.make(10000),
          signal: LimitedArray.make(2000),
          counted_steps: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match$1.stepsStates), (function (param) {
                      return param.current_steps;
                    })), 0),
          counted_cycles: 0,
          subscribers: subscribers
        };
      };
      var steps = function () {
        var recording = state.contents;
        switch (recording.TAG) {
          case "Recording" :
              var counted_steps = recording.counted_steps;
              state.contents = {
                TAG: "Recording",
                user: recording.user,
                timer: recording.timer,
                motion: recording.motion,
                motionHandler: recording.motionHandler,
                ledger: recording.ledger,
                signal: recording.signal,
                counted_steps: 0,
                counted_cycles: recording.counted_cycles,
                subscribers: recording.subscribers
              };
              StateUpdateThrottle.$$do(0);
              return counted_steps;
          case "NotRecording" :
          case "Stopped" :
              break;
          
        }
        start(recording.user);
        return 0;
      };
      var addListener$1 = function (fn) {
        var match = state.contents;
        switch (match.TAG) {
          case "NotRecording" :
              match.subscribers.push(fn);
              return ;
          case "Recording" :
              match.subscribers.push(fn);
              return ;
          case "Stopped" :
              match.subscribers.push(fn);
              return ;
          
        }
      };
      var removeListener = function (fn) {
        var match = state.contents;
        var subscribers;
        switch (match.TAG) {
          case "NotRecording" :
              subscribers = match.subscribers;
              break;
          case "Recording" :
              subscribers = match.subscribers;
              break;
          case "Stopped" :
              subscribers = match.subscribers;
              break;
          
        }
        var pos = subscribers.indexOf(fn);
        if (pos >= 0) {
          subscribers.splice(pos, 1);
          return ;
        }
        
      };
      result = {
        Params: WindowsParameters,
        steps: steps,
        stop: stop,
        start: start,
        addListener: addListener$1,
        removeListener: removeListener
      };
    } else {
      var state$1 = {
        contents: {
          TAG: "NotRecording",
          user: undefined,
          subscribers: []
        }
      };
      var getUser$1 = function () {
        return state$1.contents.user;
      };
      var getTimer$1 = function () {
        var match = state$1.contents;
        switch (match.TAG) {
          case "Recording" :
              return Caml_option.some(match.timer);
          case "NotRecording" :
          case "Stopped" :
              return ;
          
        }
      };
      var include$1 = $$Permissions.MakeRejectedReportEvent({
            getUser: getUser$1,
            addListener: DeviceMotionEvent.addListener,
            rejectionMessage: "Access to DeviceMotionEvent rejected"
          });
      var addListener$2 = include$1.addListener;
      var penalizeOutliers$1 = function (data) {
        return data.map(function (param) {
                    var z = param[2];
                    var y = param[1];
                    var x = param[0];
                    if (Math.abs(x) >= 3.7 || Math.abs(y) >= 3.7 || Math.abs(z) >= 3.7) {
                      return [
                              0.0,
                              0.0,
                              0.0
                            ];
                    } else {
                      return [
                              x,
                              y,
                              z
                            ];
                    }
                  });
      };
      var lowpass$1 = function (data, window_size) {
        var len = data.length;
        var result = Belt_Array.make(len, 0.0);
        for(var i = 0; i < len; ++i){
          var start = Caml.int_max(0, i - (window_size / 2 | 0) | 0);
          var end = Caml.int_min(len, (i + (window_size / 2 | 0) | 0) + 1 | 0);
          var l = end - start | 0;
          var slice = Curry._3(Prelude.$$Array.slice, data, start, l);
          var s = Curry._3(Prelude.$$Array.fold, slice, 0.0, (function (s, v) {
                  return s + v;
                }));
          var value = s / l;
          Curry._3(Prelude.$$Array.set, result, i, value);
        }
        return result;
      };
      var detect$1 = function (data, threshold, size, rate) {
        var filtered = lowpass$1(penalizeOutliers$1(data).map(function (param) {
                  var z = param[2];
                  var y = param[1];
                  var x = param[0];
                  return Math.sqrt(x * x + y * y + z * z);
                }), size);
        var indicators = filtered.map(function (v) {
              if (v > threshold) {
                return 1;
              } else {
                return 0;
              }
            });
        var chunks = Prelude.$$Array.chunks(indicators, rate);
        return Curry._2(Prelude.$$Array.keep, chunks, (function (chunk) {
                      var size = Curry._3(Prelude.$$Array.fold, chunk, 0, (function (s, v) {
                              return s + v | 0;
                            }));
                      return size >= 0.75 * rate;
                    })).length;
      };
      var action$2 = async function (steps) {
        var newrecord = Caml_obj.obj_dup(Query.makeWrite());
        await Query.write((newrecord.stepsStates = StepsCounterState.update(steps), newrecord));
      };
      var StateUpdateThrottle$1 = Mutex.MakeThrottle({
            rate: {
              NAME: "Hz",
              VAL: 1
            },
            name: "StepCounter",
            action: action$2
          });
      var action$3 = function (motion) {
        var match = state$1.contents;
        var tmp;
        switch (match.TAG) {
          case "Recording" :
              var subscribers = match.subscribers;
              var counted_cycles = match.counted_cycles;
              var counted_steps = match.counted_steps;
              var signal = match.signal;
              var match$1;
              if (LimitedArray.isFull(signal)) {
                var data = LimitedArray.toArray(signal);
                LimitedArray.clear(signal);
                var steps = detect$1(data, 0.9, 25, Freq.freq(rate) | 0);
                StateUpdateThrottle$1.$$do(steps + counted_steps | 0);
                match$1 = [
                  steps,
                  1
                ];
              } else {
                match$1 = [
                  0,
                  0
                ];
              }
              var current_cycles = match$1[1];
              var current_steps = match$1[0];
              Curry._2(Prelude.$$Array.forEach, subscribers, (function (fn) {
                      fn({
                            motion: motion,
                            steps: current_steps,
                            cycles: counted_cycles + current_cycles | 0,
                            params: GenericParameters
                          });
                    }));
              state$1.contents = {
                TAG: "Recording",
                user: match.user,
                timer: match.timer,
                motion: motion,
                motionHandler: match.motionHandler,
                ledger: LimitedArray.push(match.ledger, {
                      timestamp: window.performance.now(),
                      x: motion.x,
                      y: motion.y,
                      z: motion.z,
                      counted_steps: counted_steps + current_steps | 0,
                      counted_cycles: counted_cycles + current_cycles | 0
                    }),
                signal: LimitedArray.push(signal, [
                      Prelude.default(motion.x, 0.0),
                      Prelude.default(motion.y, 0.0),
                      Prelude.default(motion.z, 0.0)
                    ]),
                counted_steps: counted_steps + current_steps | 0,
                counted_cycles: counted_cycles + current_cycles | 0,
                subscribers: subscribers
              };
              tmp = undefined;
              break;
          case "NotRecording" :
          case "Stopped" :
              tmp = undefined;
              break;
          
        }
        return Promise.resolve(tmp);
      };
      var MotionThrottle$1 = Mutex.MakeThrottle({
            rate: rate,
            name: "Motion Tracker",
            action: action$3
          });
      var trackMotion$1 = function ($$event) {
        Prelude.thenDo(MotionThrottle$1.$$do(DeviceMotionEvent.acceleration($$event)), (function () {
                
              }));
      };
      var stop$1 = function () {
        var result = LimitedArray.make(1);
        Curry._2(Prelude.OptionExported.$$Option.map, getTimer$1(), (function (timer) {
                clearTimeout(timer);
              }));
        var x = state$1.contents;
        var tmp;
        switch (x.TAG) {
          case "Recording" :
              var ledger = x.ledger;
              console.log("StepCounter", "stopped");
              DeviceMotionEvent.removeListener(x.motionHandler);
              result = ledger;
              tmp = {
                TAG: "Stopped",
                user: x.user,
                ledger: ledger,
                subscribers: x.subscribers
              };
              break;
          case "NotRecording" :
          case "Stopped" :
              tmp = x;
              break;
          
        }
        state$1.contents = tmp;
        return result;
      };
      var setTimer$1 = function () {
        Curry._2(Prelude.OptionExported.$$Option.map, getTimer$1(), (function (timer) {
                clearTimeout(timer);
              }));
        var ttl = Settings.movementTimeoutInMs / 60000 | 0;
        return setTimeout((function () {
                      console.log("Stopping after " + String(ttl) + " min of inactivity");
                      stop$1();
                    }), Settings.movementTimeoutInMs);
      };
      var start$1 = async function (user) {
        var match = state$1.contents;
        var subscribers;
        switch (match.TAG) {
          case "NotRecording" :
              subscribers = match.subscribers;
              break;
          case "Recording" :
              console.log("StepCounter", "tracking request ignored");
              return ;
          case "Stopped" :
              subscribers = match.subscribers;
              break;
          
        }
        console.log("StepCounter", "started");
        var permission = await addListener$2(trackMotion$1);
        if (permission !== "granted") {
          return ;
        }
        var newrecord = Caml_obj.obj_dup(Query.makeRead());
        var match$1 = await Query.read((newrecord.stepsStates = {
                TAG: "Get",
                _0: StepsCounterState.Identifier.zero
              }, newrecord));
        var timer = setTimer$1();
        state$1.contents = {
          TAG: "Recording",
          user: user,
          timer: timer,
          motion: {
            x: undefined,
            y: undefined,
            z: undefined
          },
          motionHandler: trackMotion$1,
          ledger: LimitedArray.make(10000),
          signal: LimitedArray.make(1000),
          counted_steps: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match$1.stepsStates), (function (param) {
                      return param.current_steps;
                    })), 0),
          counted_cycles: 0,
          subscribers: subscribers
        };
      };
      var steps$1 = function () {
        var recording = state$1.contents;
        switch (recording.TAG) {
          case "Recording" :
              var counted_steps = recording.counted_steps;
              state$1.contents = {
                TAG: "Recording",
                user: recording.user,
                timer: recording.timer,
                motion: recording.motion,
                motionHandler: recording.motionHandler,
                ledger: recording.ledger,
                signal: recording.signal,
                counted_steps: 0,
                counted_cycles: recording.counted_cycles,
                subscribers: recording.subscribers
              };
              StateUpdateThrottle$1.$$do(0);
              return counted_steps;
          case "NotRecording" :
          case "Stopped" :
              break;
          
        }
        start$1(recording.user);
        return 0;
      };
      var addListener$3 = function (fn) {
        var match = state$1.contents;
        switch (match.TAG) {
          case "NotRecording" :
              match.subscribers.push(fn);
              return ;
          case "Recording" :
              match.subscribers.push(fn);
              return ;
          case "Stopped" :
              match.subscribers.push(fn);
              return ;
          
        }
      };
      var removeListener$1 = function (fn) {
        var match = state$1.contents;
        var subscribers;
        switch (match.TAG) {
          case "NotRecording" :
              subscribers = match.subscribers;
              break;
          case "Recording" :
              subscribers = match.subscribers;
              break;
          case "Stopped" :
              subscribers = match.subscribers;
              break;
          
        }
        var pos = subscribers.indexOf(fn);
        if (pos >= 0) {
          subscribers.splice(pos, 1);
          return ;
        }
        
      };
      result = {
        Params: GenericParameters,
        steps: steps$1,
        stop: stop$1,
        start: start$1,
        addListener: addListener$3,
        removeListener: removeListener$1
      };
    }
  }
  algo.contents = result;
  return result;
}

function stop() {
  var Algo = algorithm();
  return Algo.stop();
}

function start(user) {
  var Algo = algorithm();
  return Algo.start(user);
}

function addListener(fn) {
  var Algo = algorithm();
  Algo.addListener(fn);
}

function removeListener(fn) {
  var Algo = algorithm();
  Algo.removeListener(fn);
}

function params() {
  return algorithm().Params;
}

export {
  MakeAlgorithm ,
  GenericParameters ,
  WindowsParameters ,
  stop ,
  start ,
  addListener ,
  removeListener ,
  params ,
}
/* Mutex Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_HashMap from "rescript/lib/es6/belt_HashMap.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

Utils.MakeOpaqueIdentifier({});

var InspectionIdentifier = Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

var Inspections = IDB__Migration__Utils.MakeReader({
      storeName: "inspections"
    });

function message(param, param$1) {
  return "Optimizing access to findings";
}

function $$process(finding, inspections) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Belt_HashMap.get(inspections, finding.detected_with_id), (function (inspection) {
                    return {
                            TAG: "Update",
                            _0: {
                              id: finding.id,
                              vessel_id: inspection.vessel_id,
                              detected_with_id: finding.detected_with_id,
                              resolved_with_id: finding.resolved_with_id,
                              status: finding.status,
                              deadline: finding.deadline,
                              comments: finding.comments
                            }
                          };
                  })), "Delete");
}

var Findings = InitFeedback.MakeRewriter({
      storeName: "deficiencies",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Optimizing access to findings";
}

function migrate(param, transaction) {
  transaction.objectStore("deficiencies").createIndex("vessel_id", "vessel_id");
  return Prelude.then(Inspections.read(transaction), (function (inspections) {
                var inspections$1 = Belt_HashMap.fromArray(inspections.map(function (i) {
                          return [
                                  i.id,
                                  i
                                ];
                        }), InspectionIdentifier.Hasher);
                return Prelude.thenResolve(Findings.rewrite(transaction, inspections$1), (function (param) {
                              return {
                                      TAG: "Ok",
                                      _0: undefined
                                    };
                            }));
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

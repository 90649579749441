// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../kaiko/Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "../kaiko/accounts/UserProfile.js";
import * as MixpanelBrowser from "mixpanel-browser";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var state = {
  lastPath: "",
  instance: undefined,
  user: undefined
};

var match = import.meta.env.MIXPANEL_TOKEN;

var match$1 = import.meta.env.MIXPANEL_API_HOST;

if (match !== undefined && match$1 !== undefined) {
  MixpanelBrowser.init(match, {
        api_host: match$1,
        loaded: (function (mixpanel) {
            state.instance = Caml_option.some(mixpanel);
          }),
        debug: Settings.releaseVersion === "dev"
      });
}

function setUser(user) {
  if (!Caml_obj.notequal(state.user, user)) {
    return ;
  }
  state.user = user;
  var match = state.instance;
  if (match === undefined) {
    return ;
  }
  var mp = Caml_option.valFromOption(match);
  if (user !== undefined) {
    mp.identify(user.email);
  } else {
    mp.identify("anonymous@kaikosystems.com");
  }
}

function track(user, eventName, properties) {
  setUser(user);
  var mp = state.instance;
  if (mp === undefined) {
    return ;
  }
  var email = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, user, (function (u) {
              return u.email;
            })), "anonymous@kaikosystems.com");
  var properties$1 = Object.assign(Prelude.default(properties, {}), {
        user: email
      });
  Caml_option.valFromOption(mp).track(eventName, properties$1);
}

function _report(currentPath, getContext) {
  if (currentPath === state.lastPath) {
    return ;
  }
  var match = getContext();
  var vessel = match.vessel;
  track(match.user, "Navigated", {
        from: state.lastPath,
        to: currentPath,
        vessel_name: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vessel, (function (v) {
                    return v.name;
                  })), "none"),
        vessel_id: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, vessel, (function (v) {
                    return v.id;
                  })), "none"),
        user_profile: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match.profile, (function (p) {
                    return UserProfile.nameWithRank(p);
                  })), "none")
      });
  state.lastPath = currentPath;
}

function _wrapController(path, controller, getContext) {
  return {
          onmatch: (function () {
              try {
                _report(path, getContext);
              }
              catch (raw_error){
                var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                console.error("MixPanel", error);
              }
              return controller;
            })
        };
}

function withAnalytics(routes, getContext) {
  return routes.map(function (param) {
              var path = param[0];
              return [
                      path,
                      _wrapController(path, param[1], getContext)
                    ];
            });
}

export {
  setUser ,
  track ,
  _wrapController ,
  withAnalytics ,
}
/* match Not a pure module */

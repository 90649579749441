// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing new answers options";
}

function $$process(answer) {
  return {
          id: answer.id,
          level_id: answer.level_id,
          vessel_id: answer.vessel_id,
          question_id: answer.question_id,
          user_id: answer.user_id,
          value: answer.value,
          timestamp: answer.timestamp,
          question_type: answer.question_type,
          level_path: ""
        };
}

var AnswerRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "newAnswers",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing new answers options";
}

function migrate(param, t) {
  return AnswerRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* AnswerRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__List from "@rescript/core/lib/es6/src/Core__List.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function MakeTheme(T) {
  var $$default = {};
  var props = {};
  var getProps = function (theme, path) {
    var path$1 = Core__List.fromArray(path.split("."));
    var _theme = theme;
    var _path = path$1;
    while(true) {
      var path$2 = _path;
      var theme$1 = _theme;
      if (!path$2) {
        return props;
      }
      var attrs = path$2.tl;
      var attr = path$2.hd;
      if (attrs) {
        var theme$2;
        try {
          theme$2 = theme$1[attr];
        }
        catch (exn){
          return props;
        }
        if (theme$2 === undefined) {
          return props;
        }
        _path = attrs;
        _theme = Caml_option.valFromOption(theme$2);
        continue ;
      }
      var props$1;
      try {
        props$1 = theme$1[attr];
      }
      catch (exn$1){
        return props;
      }
      if (props$1 !== undefined) {
        return Caml_option.valFromOption(props$1);
      } else {
        return props;
      }
    };
  };
  var mergeProps = function (theme, path, defaults) {
    var target = Object.assign({}, defaults);
    return Object.assign(target, getProps(theme, path));
  };
  return {
          $$default: $$default,
          getProps: getProps,
          mergeProps: mergeProps
        };
}

export {
  MakeTheme ,
}
/* No side effect */

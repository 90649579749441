// Generated by ReScript, PLEASE EDIT WITH CARE


var Implementation = {};

function toNamever(res) {
  var val;
  try {
    val = res();
  }
  catch (exn){
    return {
            name: "",
            version: ""
          };
  }
  var name = val.name;
  if (name !== undefined) {
    var version = val.version;
    if (version !== undefined) {
      return {
              name: name,
              version: version
            };
    } else {
      return {
              name: name,
              version: ""
            };
    }
  }
  var version$1 = val.version;
  if (version$1 !== undefined) {
    return {
            name: "",
            version: version$1
          };
  } else {
    return {
            name: "",
            version: ""
          };
  }
}

function getBrowser(ua) {
  return toNamever(function () {
              return ua.getBrowser();
            });
}

function getDevice(ua) {
  var val;
  try {
    val = ua.getDevice();
  }
  catch (exn){
    return {
            model: "",
            type: "",
            vendor: ""
          };
  }
  var model = val.model;
  if (model !== undefined) {
    var kind = val.type;
    if (kind !== undefined) {
      var vendor = val.vendor;
      if (vendor !== undefined) {
        return {
                model: model,
                type: kind,
                vendor: vendor
              };
      } else {
        return {
                model: model,
                type: kind,
                vendor: ""
              };
      }
    }
    var vendor$1 = val.vendor;
    if (vendor$1 !== undefined) {
      return {
              model: model,
              type: "",
              vendor: vendor$1
            };
    } else {
      return {
              model: model,
              type: "",
              vendor: ""
            };
    }
  }
  var kind$1 = val.type;
  if (kind$1 !== undefined) {
    var vendor$2 = val.vendor;
    if (vendor$2 !== undefined) {
      return {
              model: "",
              type: kind$1,
              vendor: vendor$2
            };
    } else {
      return {
              model: "",
              type: kind$1,
              vendor: ""
            };
    }
  }
  var vendor$3 = val.vendor;
  if (vendor$3 !== undefined) {
    return {
            model: "",
            type: "",
            vendor: vendor$3
          };
  } else {
    return {
            model: "",
            type: "",
            vendor: ""
          };
  }
}

function getEngine(ua) {
  return toNamever(function () {
              return ua.getEngine();
            });
}

function getOS(ua) {
  return toNamever(function () {
              return ua.getOS();
            });
}

function getCPU(ua) {
  var val;
  try {
    val = ua.getCPU();
  }
  catch (exn){
    return {
            architecture: ""
          };
  }
  var architecture = val.architecture;
  if (architecture !== undefined) {
    return {
            architecture: architecture
          };
  } else {
    return {
            architecture: ""
          };
  }
}

function getUA(ua) {
  try {
    return ua.getUA();
  }
  catch (exn){
    return "";
  }
}

export {
  Implementation ,
  getBrowser ,
  getDevice ,
  getEngine ,
  getOS ,
  getCPU ,
  getUA ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as Sire2LevelCompletion from "./Sire2LevelCompletion.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var filters = match.filters;
                var vesselId = match.vesselId;
                return match.levelStatus.children.map(function (subLevel) {
                            var url = URLs.vesselLevelByKind(vesselId, subLevel.id, "sire_chapter", filters.areas, filters.risks, filters.answered, filters.ranks);
                            return React.jsxsKeyed(KeepScrollLink.make, {
                                        href: url,
                                        className: "tw-rounded tw-shadow-kaiko-card tw-align-center tw-my-3 tw-p-3 tw-w-full tw-flex tw-flex-row tw-gap-2xo",
                                        children: [
                                          ReactDOM.jsx("div", {
                                                children: subLevel.name,
                                                className: "tw-truncate tw-text-[20px]/[24px] tw-font-medium"
                                              }),
                                          ReactDOM.jsx("div", {
                                                className: "tw-flex-grow"
                                              }),
                                          ReactDOM.jsx("div", {
                                                children: React.jsxKeyed(Sire2LevelCompletion.make, {
                                                      level: subLevel,
                                                      filters: filters
                                                    }, subLevel.id, undefined),
                                                className: "tw-max-w-[80px]"
                                              })
                                        ]
                                      }, subLevel.id, undefined);
                          });
              }));
}

export {
  make ,
}
/* URLs Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

var Queue = ActionQueue.MakeActionQueue({
      name: "SIRE 2.0 View Update Queue"
    });

var queue = Queue.make(true, 1, false);

function replace(action) {
  return Core__Option.getExn(Queue.replace(queue, action, undefined), undefined);
}

export {
  Queue ,
  replace ,
}
/* Queue Not a pure module */

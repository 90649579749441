// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UploadUtils from "../../upload/UploadUtils.js";
import * as UploadPayload from "../../upload/UploadPayload.js";

function summarize(payloads) {
  var size = UploadPayload.size(payloads);
  var attachmentsCount = UploadPayload.getFutureAttachments(payloads).length;
  var timestamps = Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keepMap, payloads, (function (r) {
              return UploadPayload.timestamp(r);
            })), (function (x) {
          return x;
        }), undefined);
  var start = Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(timestamps), (function (prim) {
          return Luxon.DateTime.fromJSDate(prim);
        }));
  var end = Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(timestamps), (function (prim) {
          return Luxon.DateTime.fromJSDate(prim);
        }));
  var inspections = Curry._2(Prelude.$$Array.keepMap, payloads, UploadPayload.getInspection);
  var answers = Curry._2(Prelude.$$Array.keepMap, payloads, UploadPayload.getAnswer);
  var walked = inspections.reduce((function (result, inspection) {
          return result + inspection.walked_steps | 0;
        }), 0);
  var walked$1 = answers.reduce((function (result, answer) {
          return result + answer.walked_steps | 0;
        }), walked);
  var walked$2 = UploadUtils.walkedDistanceInMeters(walked$1);
  var comments = Curry._2(Prelude.$$Array.keepMap, payloads, UploadPayload.getNewComment);
  var companyFormsSubmissions = Curry._2(Prelude.$$Array.keepMap, payloads, UploadPayload.getCompanyFormSubmission);
  var profiles = Curry._2(Prelude.$$Array.keepMap, payloads, UploadPayload.getUserProfile);
  var vessel = Prelude.$$Array.first(UploadPayload.getVessels(payloads));
  var compression = vessel !== undefined ? vessel.max_pic_resolution : "ORIGINAL";
  return {
          start: start,
          end: end,
          walked: walked$2,
          attachmentsCount: attachmentsCount,
          size: size,
          inspections: inspections,
          answers: answers,
          comments: comments,
          companyFormsSubmissions: companyFormsSubmissions,
          profiles: profiles,
          compression: compression
        };
}

function timeSpentFormatted(start, end) {
  return end.diff(start, [
                "hours",
                "minutes"
              ]).toHuman();
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var payloads = vnode.attrs.payloads;
                var failures = Prelude.default(vnode.attrs.failures, []);
                var onClickRetry = vnode.attrs.onClickRetry;
                var failures$1 = Belt_Set.toArray(Belt_Set.mergeMany(Belt_Set.make(UploadPayload.Comparator), failures));
                var partial = Prelude.$$Array.isNotEmpty(failures$1);
                var match = summarize(payloads);
                var size = match.size;
                var attachmentsCount = match.attachmentsCount;
                var end = match.end;
                var start = match.start;
                var items = match.inspections.length + match.answers.length | 0;
                var compressionMessage;
                switch (match.compression) {
                  case "FHD" :
                      compressionMessage = "using FHD compression";
                      break;
                  case "HD" :
                      compressionMessage = "using HD compression";
                      break;
                  case "SD" :
                      compressionMessage = "using SD compression";
                      break;
                  case "ORIGINAL" :
                      compressionMessage = "uncompressed";
                      break;
                  
                }
                var attachmentsMessage = attachmentsCount !== 0 ? (
                    attachmentsCount !== 1 ? String(attachmentsCount) + " images (" + UploadUtils.formatBytes(size) + ", " + compressionMessage + ")" : "An image (" + UploadUtils.formatBytes(size) + ", " + compressionMessage + ")"
                  ) : "No images were uploaded";
                var tmp;
                if (partial) {
                  var tmp$1;
                  if (onClickRetry !== undefined) {
                    var failed = failures$1.length;
                    var size$1 = UploadUtils.formatBytes(UploadPayload.size(failures$1));
                    tmp$1 = [
                      failed > 1 ? ReactDOM.jsx("p", {
                              children: "There are " + String(failed) + " item(s)\n                      (approx. " + size$1 + ") left to be sent to the server.  You\n                      can retry the upload now or do it later.",
                              className: "text-small"
                            }) : ReactDOM.jsx("p", {
                              children: "There is one item (approx. " + size$1 + ") left to be\n                      sent to the server.  You can retry the upload now or do\n                      it later.",
                              className: "text-small"
                            }),
                      ReactDOM.jsx("button", {
                            children: "Retry",
                            className: "btn p-centered",
                            onClick: (function (param) {
                                onClickRetry();
                              })
                          })
                    ];
                  } else {
                    tmp$1 = Mithril.empty;
                  }
                  tmp = [
                    ReactDOM.jsx("div", {
                          className: "divider blank xl"
                        }),
                    ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("h6", {
                                  children: "Not all attachments were uploaded",
                                  className: "text-bold"
                                }),
                            ReactDOM.jsx("p", {
                                  children: "Some attachments failed to upload completely.\n                 Your inspections and other items were uploaded and\n                 are now available in the Kaiko Dashboard.",
                                  className: "text-small"
                                }),
                            tmp$1
                          ],
                          style: {
                            margin: "0 1rem"
                          }
                        })
                  ];
                } else {
                  tmp = Mithril.empty;
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      partial ? ReactDOM.jsxs("h3", {
                                              children: [
                                                React.jsx(Feather.make, {
                                                      icon: "check_circle",
                                                      className: "p-centered",
                                                      size: 45
                                                    }),
                                                ReactDOM.jsx("div", {
                                                      className: "divider blank"
                                                    }),
                                                ReactDOM.jsx("span", {
                                                      children: "Upload partially done!"
                                                    })
                                              ],
                                              className: "text-warning"
                                            }) : ReactDOM.jsxs("h3", {
                                              children: [
                                                React.jsx(Feather.make, {
                                                      icon: "check_circle",
                                                      className: "p-centered",
                                                      size: 45
                                                    }),
                                                ReactDOM.jsx("div", {
                                                      className: "divider blank"
                                                    }),
                                                ReactDOM.jsx("span", {
                                                      children: "Upload successful!"
                                                    })
                                              ],
                                              className: "text-success"
                                            }),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank xl"
                                          }),
                                      ReactDOM.jsx("h4", {
                                            children: partial ? "Inspections were uploaded successfully." : "Everything was uploaded successfully.",
                                            className: "text-gray text-bold"
                                          })
                                    ],
                                    className: "text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("h5", {
                                                  children: "Upload Summary",
                                                  className: "text-center text-bold"
                                                }),
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    start !== undefined && end !== undefined ? ReactDOM.jsxs("p", {
                                                            children: [
                                                              ReactDOM.jsx("strong", {
                                                                    children: "Time spent on inspections: "
                                                                  }),
                                                              ReactDOM.jsx("span", {
                                                                    children: timeSpentFormatted(Caml_option.valFromOption(start), Caml_option.valFromOption(end)),
                                                                    className: "text-success column"
                                                                  })
                                                            ],
                                                            className: "columns mb-0"
                                                          }) : Mithril.empty,
                                                    ReactDOM.jsxs("p", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Overall items inspected: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: String(items) + " items",
                                                                  className: "text-success column"
                                                                })
                                                          ],
                                                          className: "columns mb-0"
                                                        }),
                                                    ReactDOM.jsxs("p", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Distance walked: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: String(match.walked) + " m",
                                                                  className: "text-success column"
                                                                })
                                                          ],
                                                          className: "columns mb-0"
                                                        }),
                                                    ReactDOM.jsxs("p", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Comments: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: match.comments.length,
                                                                  className: "text-success column"
                                                                })
                                                          ],
                                                          className: "columns mb-0"
                                                        }),
                                                    ReactDOM.jsxs("p", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Company forms completed: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: match.companyFormsSubmissions.length,
                                                                  className: "text-success column"
                                                                })
                                                          ],
                                                          className: "columns mb-0"
                                                        }),
                                                    ReactDOM.jsxs("p", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "User profiles: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: match.profiles.length,
                                                                  className: "text-success column"
                                                                })
                                                          ],
                                                          className: "columns mb-0"
                                                        }),
                                                    ReactDOM.jsxs("p", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Images: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: attachmentsMessage,
                                                                  className: "text-success column"
                                                                })
                                                          ],
                                                          className: "columns mb-0"
                                                        }),
                                                    Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, end, (function (date) {
                                                                return ReactDOM.jsxs("p", {
                                                                            children: [
                                                                              ReactDOM.jsx("strong", {
                                                                                    children: "Time Stamp: "
                                                                                  }),
                                                                              ReactDOM.jsx("span", {
                                                                                    children: date.setZone("UTC").toLocaleString(Luxon.DateTime.DATETIME_FULL),
                                                                                    className: "text-success column"
                                                                                  })
                                                                            ],
                                                                            className: "columns mb-0"
                                                                          });
                                                              })), Mithril.empty)
                                                  ],
                                                  className: "mt-3",
                                                  style: {
                                                    fontSize: "14px"
                                                  }
                                                })
                                          ],
                                          className: "m-3"
                                        }),
                                    className: "summary m-2",
                                    style: {
                                      border: "solid 1px"
                                    }
                                  }),
                              tmp
                            ]
                          });
              }));
}

export {
  summarize ,
  timeSpentFormatted ,
  make ,
}
/* React Not a pure module */

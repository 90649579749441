// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../accounts/User.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as State__Memory from "../state/State__Memory.js";

function init(vnode) {
  var match = vnode.state.data;
  switch (match) {
    case "Init" :
        if (Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, State__Memory.Current.get().user, User.isKaikoEmployee), false)) {
          return Mithril.setState(vnode, "Shown");
        } else {
          return Mithril.setState(vnode, "Hidden");
        }
    case "Shown" :
    case "Hidden" :
        return ;
    
  }
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("Init"), init), (function (vnode) {
                var match = vnode.state.data;
                switch (match) {
                  case "Shown" :
                      return vnode.children;
                  case "Init" :
                  case "Hidden" :
                      return Mithril.empty;
                  
                }
              }));
}

export {
  init ,
  make ,
}
/* User Not a pure module */

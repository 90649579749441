// Generated by ReScript, PLEASE EDIT WITH CARE


function get() {
  return window.location;
}

export {
  get ,
}
/* No side effect */

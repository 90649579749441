// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UploadPayload from "../UploadPayload.js";

async function next(requests) {
  if (!Prelude.$$Array.isNotEmpty(requests)) {
    return {
            TAG: "Done",
            _0: []
          };
  }
  var payloads = await Prelude.Promises.iter(requests.map(function (r) {
            return function () {
              return UploadPayload.make(r);
            };
          }));
  return {
          TAG: "Filtering",
          _0: Prelude.$$Array.flatMap(payloads, (function (p) {
                  return p;
                }))
        };
}

export {
  next ,
}
/* UploadPayload Not a pure module */

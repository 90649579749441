// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as UserProfile from "../UserProfile.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var profile = vnode.attrs.profile;
                var avatar = Prelude.$$Array.first(profile.avatars);
                return ReactDOM.jsxs("div", {
                            children: [
                              avatar !== undefined ? React.jsx(LocalImage.Resource.make, {
                                      resource: avatar.id,
                                      className: "m-auto d-block",
                                      style: {
                                        border: "1px solid black",
                                        height: "150px",
                                        width: "150px",
                                        borderRadius: "50%",
                                        objectFit: "cover"
                                      }
                                    }) : React.jsx(Feather.make, {
                                      icon: "user",
                                      className: "text-center avatar-icon",
                                      size: 150
                                    }),
                              ReactDOM.jsx("span", {
                                    children: UserProfile.nameWithRank(profile),
                                    className: "d-block text-center text-tiny py-1",
                                    style: {
                                      lineHeight: "18px"
                                    }
                                  })
                            ],
                            className: "p-2 text-center",
                            style: {
                              height: "200px"
                            }
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */

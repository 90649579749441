// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as ApiMaker from "../../../../libs/ApiMaker.js";
import * as Question from "../../../tree/Question.js";
import * as UserProfile from "../../../accounts/UserProfile.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "texts_labels",
      {
        TAG: "Array",
        _0: "String"
      }
    ],
    [
      "numbers_labels",
      {
        TAG: "Array",
        _0: "String"
      }
    ],
    [
      "multiline_texts_labels",
      {
        TAG: "Array",
        _0: "String"
      }
    ]
  ]
};

var OptDeserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var url = "/tree/questions/weight-info-questions";

var fields$1 = [
  [
    "id",
    "String"
  ],
  [
    "name",
    "String"
  ],
  [
    "vessel_id",
    "String"
  ],
  [
    "levels",
    {
      TAG: "Collection",
      _0: Question.LevelLink.Deserializer
    }
  ],
  [
    "options",
    {
      TAG: "Deserializer",
      _0: OptDeserializer
    }
  ],
  [
    "guidance_document_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "answer_visibility",
    {
      TAG: "DefaultWhenInvalid",
      _0: Question.AnswerVisibility.deserFromJS,
      _1: "AllAnswers"
    }
  ],
  [
    "area",
    {
      TAG: "DefaultWhenInvalid",
      _0: {
        TAG: "Optional",
        _0: Question.QuestionArea.deserFromJS
      },
      _1: undefined
    }
  ],
  [
    "ranks",
    {
      TAG: "Array",
      _0: UserProfile.Rank.deserFromJS
    }
  ]
];

var Definition = {
  url: url,
  fields: fields$1
};

var include = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields$1,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var get = include.get;

var options = include.options;

export {
  OptDeserializer ,
  Definition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  get ,
  options ,
}
/* OptDeserializer Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "./Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import WebcamEasy from "webcam-easy";

function snap(vnode, callback, _event) {
  var webcam = vnode.state.data;
  if (typeof webcam !== "object") {
    return ;
  }
  if (webcam.TAG !== "Loaded") {
    return ;
  }
  webcam._0.snap();
  var canvas = Mithril.$$Element.select(vnode, "#canvas");
  if (canvas !== undefined) {
    Caml_option.valFromOption(canvas).toBlob(callback, "image/jpeg", 0.85);
    return ;
  } else {
    return callback(undefined);
  }
}

function make(param) {
  return Mithril.view(Mithril.onremove(Mithril.oncreate(Mithril.component("Init"), (function (vnode) {
                        var video = Mithril.$$Element.select(vnode, "#webcam");
                        var canvas = Mithril.$$Element.select(vnode, "#canvas");
                        var match = vnode.state.data;
                        if (typeof match === "object") {
                          return ;
                        }
                        if (video === undefined) {
                          return ;
                        }
                        if (canvas === undefined) {
                          return ;
                        }
                        var webcam = new WebcamEasy(Caml_option.valFromOption(video), "environment", Caml_option.valFromOption(canvas), undefined);
                        Prelude.catchResolve(Prelude.thenResolve(webcam.start(), (function (param) {
                                    vnode.state.data = {
                                      TAG: "Loaded",
                                      _0: webcam
                                    };
                                  })), (function (error) {
                                console.error("Cannot start webcam.  Going back", error);
                                window.alert("Check your devices camera (or permissions) to proceed.");
                                window.history.back();
                              }));
                      })), (function (vnode) {
                    var webcam = vnode.state.data;
                    if (typeof webcam !== "object") {
                      return ;
                    }
                    if (webcam.TAG !== "Loaded") {
                      return ;
                    }
                    webcam._0.stop();
                  })), (function (vnode) {
                var className = "webcam " + Prelude.default(vnode.attrs.className, "");
                var snapCallback = Prelude.default(vnode.attrs.snapCallback, (function (param) {
                        
                      }));
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("video", {
                                    id: "webcam",
                                    height: "0"
                                  }),
                              ReactDOM.jsx("canvas", {
                                    id: "canvas"
                                  }),
                              ReactDOM.jsx("button", {
                                    children: React.jsx(Feather.make, {
                                          icon: "camera",
                                          size: 50
                                        }),
                                    className: "btn btn-block",
                                    id: "snap",
                                    onClick: (function (extra) {
                                        return snap(vnode, snapCallback, extra);
                                      })
                                  })
                            ],
                            className: className,
                            id: "#webcam-container"
                          });
              }));
}

export {
  snap ,
  make ,
}
/* React Not a pure module */

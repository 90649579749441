// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";

function message(param, param$1) {
  return "Installing new Levels options";
}

function $$process(level) {
  var match = level.parent_id;
  if (match !== undefined) {
    return {
            id: level.id,
            parent_id: level.parent_id,
            vessel_id: level.vessel_id,
            guidance_document_id: undefined,
            name: level.name,
            path: level.path,
            number: level.number,
            depth: 2
          };
  } else {
    return {
            id: level.id,
            parent_id: level.parent_id,
            vessel_id: level.vessel_id,
            guidance_document_id: undefined,
            name: level.name,
            path: level.path,
            number: level.number,
            depth: 1
          };
  }
}

var LevelRewriter = InitFeedback.MakeSimpleInfallibleRewriter({
      storeName: "levels",
      message: message,
      $$process: $$process
    });

function message$1() {
  return "Installing new Levels options";
}

function migrate(param, t) {
  return LevelRewriter.rewrite(t);
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* LevelRewriter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CompanyForm from "../CompanyForm.js";
import * as CompanyFormId from "../CompanyFormId.js";
import * as CompanyFormManager from "../CompanyFormManager.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";
import * as CompanyFormSubmission from "../CompanyFormSubmission.js";
import * as CompanyFormExpectedSubmissionTile from "./CompanyFormExpectedSubmissionTile.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var submission = vnode.attrs.submission;
                return React.jsx(TileLink.make, {
                            disabled: true,
                            title: submission.target_date.toDateString(),
                            subtitle: submission.target_date.toLocaleTimeString(),
                            action: Caml_option.some(React.jsx(Feather.make, {
                                      icon: "check_circle",
                                      className: "text-success"
                                    }))
                          });
              }));
}

var PreviousSubmissionTile = {
  make: make
};

function load(vnode, submission) {
  Prelude.thenDo(CompanyFormManager.getSubmissionCompletion(submission), (function (completion) {
          Mithril.setState(vnode, {
                TAG: "Loaded",
                _0: completion
              });
        }));
}

function CompanyFormSubmissions$DraftSubmissionTile(props) {
  var counterOverlayClassName = props.counterOverlayClassName;
  var submission = props.submission;
  var form = props.form;
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    load(vnode, submission);
                  })), (function (vnode) {
                var completion = vnode.state.data;
                if (typeof completion !== "object") {
                  return Mithril.empty;
                } else {
                  return React.jsx(TileLink.make, {
                              title: submission.name,
                              href: URLs.newCompanyFormSubmission(form.id, submission.id, undefined, undefined),
                              subtitle: submission.target_date.toUTCString(),
                              action: Caml_option.some(React.jsx(InspectionCompletion.make, {
                                        completion: completion._0,
                                        counterOverlayClassName: counterOverlayClassName
                                      }))
                            });
                }
              }));
}

var DraftSubmissionTile = {
  load: load,
  make: CompanyFormSubmissions$DraftSubmissionTile
};

async function load$1(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var formId = CompanyFormId.Route.param("formId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.draftCompanyFormSubmissions = CompanyFormSubmission.ofForm(formId), newrecord.newCompanyFormSubmissions = CompanyFormSubmission.ofForm(formId), newrecord.companyFormSubmissions = CompanyFormSubmission.ofForm(formId), newrecord.companyForms = {
          TAG: "Get",
          _0: formId
        }, newrecord));
  var draftCompanyFormSubmissions = match$1.draftCompanyFormSubmissions;
  var companyFormSubmissions = match$1.companyFormSubmissions;
  var form = Prelude.$$Array.first(match$1.companyForms);
  if (form !== undefined) {
    var recurrence = form.recurrence;
    if (recurrence !== undefined) {
      var expectedSubmissions = Curry._1(Prelude.$$Array.reverse, CompanyForm.expectedSubmissions(form, Prelude.$$Array.sort(Curry._1(Prelude.$$Array.concatMany, [
                        companyFormSubmissions,
                        match$1.newCompanyFormSubmissions,
                        draftCompanyFormSubmissions
                      ]), CompanyFormSubmission.byTargetDate, undefined), draftCompanyFormSubmissions.map(function (s) {
                    return s.id;
                  })));
      return Mithril.setState(vnode, {
                  TAG: "RecurrentForm",
                  recurrence: Caml_option.valFromOption(recurrence),
                  form: form,
                  expectedSubmissions: expectedSubmissions
                });
    }
    var submissions = Prelude.$$Array.sort(companyFormSubmissions, CompanyFormSubmission.byTargetDate, true);
    var draftSubmissions = Prelude.$$Array.sort(draftCompanyFormSubmissions, CompanyFormSubmission.byTargetDate, true);
    return Mithril.setState(vnode, {
                TAG: "NonRecurrentForm",
                form: form,
                submissions: submissions,
                draftSubmissions: draftSubmissions
              });
  }
  window.history.back();
}

function createDraftSubmission(vnode, targetDate, _event) {
  var match = vnode.state.data;
  var form;
  if (typeof match !== "object") {
    window.history.back();
    return ;
  }
  form = match.TAG === "RecurrentForm" ? match.form : match.form;
  Prelude.thenDo(CompanyFormManager.createDraftSubmission(targetDate, form), (function (draftSubmission) {
          Mithril$1.route.set(URLs.newCompanyFormSubmission(form.id, draftSubmission.id, undefined, undefined));
        }));
}

function title(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return "";
  } else if (match.TAG === "RecurrentForm") {
    return match.form.name;
  } else {
    return match.form.name;
  }
}

function CompanyFormSubmissions(props) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), load$1), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else if (match.TAG === "RecurrentForm") {
                  var form = match.form;
                  tmp = match.expectedSubmissions.map(function (expectedSubmission) {
                        return React.jsxKeyed(CompanyFormExpectedSubmissionTile.make, {
                                    form: form,
                                    expectedSubmission: expectedSubmission
                                  }, String(expectedSubmission.id), undefined);
                      });
                } else {
                  var draftSubmissions = match.draftSubmissions;
                  var submissions = match.submissions;
                  var form$1 = match.form;
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          draftSubmissions.length !== 0 ? ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("h5", {
                                          children: "New Submissions"
                                        }),
                                    draftSubmissions.map(function (submission) {
                                          return React.jsx(CompanyFormSubmissions$DraftSubmissionTile, {
                                                      form: form$1,
                                                      submission: submission
                                                    });
                                        })
                                  ]
                                }) : Mithril.empty,
                          submissions.length !== 0 ? ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("h5", {
                                          children: "Completed Submissions"
                                        }),
                                    submissions.map(function (submission) {
                                          return React.jsx(make, {
                                                      submission: submission
                                                    });
                                        })
                                  ]
                                }) : Mithril.empty
                        ]
                      });
                }
                var match$1 = vnode.state.data;
                var tmp$1;
                tmp$1 = typeof match$1 !== "object" || match$1.TAG === "RecurrentForm" ? Mithril.empty : ReactDOM.jsx("button", {
                        children: "New submission",
                        className: "btn btn-primary btn-block btn-lg",
                        onClick: (function (extra) {
                            return createDraftSubmission(vnode, undefined, extra);
                          })
                      });
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode),
                            bottom: Caml_option.some(tmp$1)
                          });
              }));
}

var make$1 = CompanyFormSubmissions;

export {
  PreviousSubmissionTile ,
  DraftSubmissionTile ,
  load$1 as load,
  createDraftSubmission ,
  title ,
  make$1 as make,
}
/* URLs Not a pure module */

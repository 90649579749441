// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";
import * as IDB__Migration__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Utils.js";

Utils.MakeOpaqueIdentifier({});

var InspectionId = Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

Utils.MakeOpaqueIdentifier({});

var InspectionReader = IDB__Migration__Utils.MakeReader({
      storeName: "inspections"
    });

function message(param, param$1) {
  return "Optimizing access to findings";
}

function $$process(finding, inspections) {
  var inspection = InspectionId.$$Map.get(inspections, finding.detected_with_id);
  if (inspection !== undefined) {
    return {
            TAG: "Update",
            _0: {
              id: finding.id,
              vessel_id: finding.vessel_id,
              detected_with_id: finding.detected_with_id,
              resolved_with_id: finding.resolved_with_id,
              status: finding.status,
              deadline: finding.deadline,
              comments: finding.comments,
              component_id: inspection.component_id,
              section_id: inspection.section_id,
              procedure_id: inspection.procedure_id
            }
          };
  } else {
    console.error("Removing invalid finding without an inspection", finding);
    return "Delete";
  }
}

var DeficiencyRewriter = InitFeedback.MakeRewriter({
      storeName: "deficiencies",
      message: message,
      $$process: $$process
    });

function message$1(param) {
  return "Optimizing access to findings";
}

function migrate(param, transaction) {
  IDB__Migration__Store.createManyIndexes(transaction.objectStore("deficiencies"), [
        "component_id",
        "procedure_id",
        "section_id"
      ]);
  return Prelude.then(InspectionReader.read(transaction), (function (inspections) {
                var map = InspectionId.$$Map.fromArray(inspections.map(function (i) {
                          return [
                                  i.id,
                                  i
                                ];
                        }));
                return DeficiencyRewriter.rewrite(transaction, map);
              }));
}

var include = InitFeedback.MakeMigration({
      message: message$1,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Procedure from "./Procedure.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as ProcedureId from "./ProcedureId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofComponent(componentId) {
  return {
          TAG: "Is",
          _0: "component_id",
          _1: componentId
        };
}

function ofComponents(componentIds) {
  return {
          TAG: "AnyOf",
          _0: "component_id",
          _1: componentIds
        };
}

function makeForProcedures(procedures) {
  return procedures.map(function (procedure) {
              return procedure;
            });
}

function ids(__x) {
  return __x.map(function (procedure) {
              return procedure.id;
            });
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

var getImages = Procedure.getImages;

export {
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  ofComponent ,
  ofComponents ,
  makeForProcedures ,
  ids ,
  getImages ,
}
/* include Not a pure module */
